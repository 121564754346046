import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../../components/constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { Stationary_Combustion, Fugitive, Business_Travel, Mobile_Combustion, Purchase_Goods_Services, BP8EQ2, BP2LQ3, BP2LQ5, Stationary_Combustion_, Fugitive_, Mobile_Combustion_, Purchase_Goods_Services_, GR2LQ1, GR2LQ2, GR2LQ3, Business_Travel_, HotelStay, Employee_Category, Diversity_of_Employees } from "../../../../src/pages/hardcodedforms";
import { Editor } from "primereact/editor";
import { hardcoded } from "../../../../src/pages/constants/hardcodedid";
import APIServices from "../../../service/APIService";
window.jQuery = $;
window.$ = $;

const DCFInputEntryPreview = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [data, setData] = useState([])
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const { id } = useParams();
    const params = useLocation()
    // const hardcodeddcf = ['10', '11', '36', '15', '16', '188', '195', '196','245']
    useEffect(async () => {


        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let site_url = API.LocationOne_UP(selector.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;

        APIServices.get(API.DCF_Edit(id)).then((res) => {
            // res.data.standard = params.state.standard


            setData(res.data)


        })



        APIServices.get(site_url).then((res) => {
            let site_list = []
            res.data.forEach((country) => {
                if (country.locationTwos !== undefined) {
                    country.locationTwos.forEach((city) => {
                        if (city.locationThrees !== undefined) {
                            city.locationThrees.forEach((site) => {

                                site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                            })
                        }
                    })
                }
            })
            setSiteList(site_list)
        })

    }, [selector]);

    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-formats" aria-label="Underline"></button>

            </span>
        );
    };
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        if (nan !== undefined) {
            if (isNaN(val)) {
                item.value = undefined
            } else {
                item.value = val;
            }

        } else {
            item.value = val;
        }

        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar className="col-5 fs-14 fw-4" value={item.value !== null ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText type='number' onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, parseFloat(e.target.value), 'as') }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}

                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <Editor className="text-area" value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5" >
                        <div style={{
                            background: '#f8f9fa',
                            border: '1px solid #ced4da',
                            borderRadius: '6px 6px 0px 0px',
                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} className="fs-14 clr-navy" style={{
                                marginRight: 10,
                                padding: '5px',

                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',

                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>
                        {item.value !== undefined && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()

            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }

    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }
                }
            } else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'file' && item.required) {
                if (item.value !== undefined && item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }

    const checkResponse_ = () => {
        console.log(data)
        if (data.data1.length === 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Data set is Empty, requires minimum 1 record to submit/draft",
                showConfirmButton: false,
                timer: 1500,
            });
            return false
        } else {

            return true
        }
    }

    const checkHardcoded = () => {
        if (hardcoded.dcf.includes(id) || hardcoded.dcf2.includes(id)) {
            return true
        } else {
            return false
        }

    }
    return (
        <div className="grid" style={{ margin: 10 }}>
            <div className="col-12" >
                {(selector.id !== undefined && data.length !== 0) ?
                    <div >

                        <div className="fs-20 fw-7 clr-gray-900">
                            <h4><span className="mr-2">{'DCF ' + id}</span> {data.title}</h4>
                        </div>


                        {data.comments.trim().length !== 0 &&
                            <div className="p-card fw-5 fs-16" style={{ padding: 10, marginBottom: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>Note :&nbsp;</span> {data.comments}
                            </div>}

                        {data.data1.length !== 0 && !checkHardcoded() ?
                            <div>
                                <div className="bg-white" style={{ borderRadius: 4 }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 24, borderBottom: '1px solid #E0E0E0' }} >
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className="flex fs-16" style={{ flexDirection: 'row' }}><span className="clr-gray-3 fw-4">Version :&nbsp;</span> <span className="clr-gray-900 fw-7">{moment(data.updated).local().format('DD MMM YYYY, hh:mm A')}</span>  </div>
                                        </div>

                                    </div>
                                    <div style={{ padding: 24 }}>


                                        {data.data1.map((item, index) => {

                                            return renderItems(item, index)
                                        })

                                        }
                                    </div>



                                </div>
                                <div style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 20 }}>
                                    <Button label='Close' className="ml-4" onClick={() => { window.close() }} text ></Button>
                                    <Button label='No Update' className="ml-4" outlined ></Button>
                                    <Button label='Save as Draft' className="ml-4" outlined disabled></Button>

                                    <Button label='Save and Submit' className="ml-4" disabled></Button>

                                </div>
                            </div>
                            :
                            (id === '10' && selector.clientId === 17) ?
                                <div>
                                    <Fugitive_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                    </div>
                                </div> : (id === '10' && selector.clientId !== 17) ?
                                    <div>
                                        <Fugitive data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                        </div>
                                    </div>
                                    :
                                    (id === '11' && selector.clientId === 17) ?
                                        <div>
                                            <Stationary_Combustion_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                            </div>
                                        </div> : (id === '11' && selector.clientId !== 17) ?
                                            <div>
                                                <Stationary_Combustion data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                </div>
                                            </div>
                                            :
                                            (id === '36' && selector.clientId === 17) ?
                                                <div>
                                                    < Business_Travel_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                    </div>
                                                </div>
                                                : (id === '36' && selector.clientId !== 17) ?
                                                    <div>
                                                        <Business_Travel data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                        </div>
                                                    </div>
                                                    :
                                                    (id === '15' && selector.clientId === 17) ?
                                                        <div>
                                                            <Mobile_Combustion_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                            </div>
                                                        </div>
                                                        : (id === '15' && selector.clientId !== 17) ?
                                                            <div>
                                                                <Mobile_Combustion data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                </div>
                                                            </div>
                                                            :
                                                            (id === '16' && selector.clientId === 17) ?
                                                                <div>
                                                                    <Purchase_Goods_Services_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                    </div>
                                                                </div> : (id === '16' && selector.clientId !== 17) ?
                                                                    <div>
                                                                        <Purchase_Goods_Services data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                        </div>
                                                                    </div>
                                                                    : id === '188' ?
                                                                        <div>
                                                                            <BP8EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                            </div>
                                                                        </div>
                                                                        : id === '195' ?
                                                                            <div>
                                                                                <BP2LQ3 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                                </div>
                                                                            </div>
                                                                            : id === '196' ?
                                                                                <div>
                                                                                    <BP2LQ5 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                                    </div>
                                                                                </div>
                                                                                : id === '245' ?
                                                                                    <div>
                                                                                        <GR2LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                                        </div>
                                                                                    </div>
                                                                                    : id === '246' ?
                                                                                        <div>
                                                                                            <GR2LQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                                            </div>
                                                                                        </div>
                                                                                        : id === '247' ?
                                                                                            <div>
                                                                                                <GR2LQ3 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                                                </div>
                                                                                            </div>
                                                                                            : id === '254' ?
                                                                                                <div>
                                                                                                    <HotelStay standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                                                    </div>
                                                                                                </div>
                                                                                                : id === '262' ?
                                                                                                    <div>
                                                                                                        <Employee_Category data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                                                        </div>
                                                                                                    </div> : id === '263' ?
                                                                                                        <div>
                                                                                                            <Diversity_of_Employees data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                                                            </div>
                                                                                                        </div> :
                                                                                                        'Contact admin'
                        }




                    </div>
                    :
                    <div className="col-12 card">Form not found</div>
                    // <div className="col-12 card">You have no rights to access this page </div>

                }
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DCFInputEntryPreview, comparisonFn);
