
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Navigate2Path from "../../components/Navigate2Path";
import { API } from "../../components/constants/api_url";
const initialState = {
    // profile:{id:'12',name:'Venkat',mobno:'0000000'}
    userdetail: {},
    config: []
}
export const fetchApi = createAsyncThunk('api/fetachApi', async (token) => {
    return axios.get(API.GetMe, { headers: { "Authorization": "Bearer" + " " + token } }).then((res) => res.data)
})

const setData = (state, action) => {

    if (action.payload.information.blocked === undefined || !action.payload.information.blocked) {
        let data = action.payload
        if (data.role !== 'eisqradmin') {
        if (data.role === 'clientadmin') {
            data.information['cid'] = data.id
            data.clientId = data.id
        }
        state.userdetail = data;
    }else{
        localStorage.removeItem("token");
        Swal.fire({
            icon: 'error',
            title: 'Incorrect URL',
            text: 'Try Login With New URL Provided',
            returnInputValueOnDeny: () => {
                console.log('deny')
            }
        })
    }
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Blocked',
            text: 'You have been blocked from platform, contact admin for futher details',
            returnInputValueOnDeny: () => {
                console.log('deny')
            }
        })

    }


}

const userProfile = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedUserDetail: (state, action) => { state.userdetail = action.payload },
        resetLoggedUserDetail: (state) => {   localStorage.clear();
            sessionStorage.clear(); state.userdetail = {} },

    },
    extraReducers: (builder) => {

        builder.addCase(fetchApi.fulfilled, (state, action) => { setData(state, action) })

    }



})

export default userProfile.reducer
export const { setLoggedUserDetail, resetLoggedUserDetail } = userProfile.actions
