import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from 'primereact/checkbox';
import $ from "jquery";
import { API } from "../components/constants/api_url";
import { RadioButton } from "primereact/radiobutton";
import LazyView from "../components/LazyView";
import { MdSecurityUpdate } from "react-icons/md";
import moment from "moment";
import APIServices from "../service/APIService";

window.jQuery = $;
window.$ = $;

const CSDCFAssignment = () => {
  
    const selector = useSelector((state) => state.user.userdetail);
    const [user, setUser] = useState([]);
    const [response, setResponse] = useState([])
    const [selDataPoint, setSelDataPoint] = useState([])
    const configtype = [{ name: 'Location' }, { name: 'Data Point' }]
    const forceUpdate = useForceUpdate();
    const [editmode, setEditMode] = useState(false)
    const [old, setOld] = useState([]);
    const [datapoint, setDataPoint] = useState([]);
    const [metriclist, setMetricList] = useState([]);
    const [metricbk, setMetricBk] = useState([])
    const [raw, setRaw] = useState([])
    const [rawdcf, setRawDCF] = useState([])
    const [dupdpiddialog, setDupDPIDDialog] = useState(false)
    const [dupdpid, setDupId] = useState([])
    const [selecteddcf, setSelectedDCF] = useState([]);
    const [selecteddcfbk, setSelectedDCFBK] = useState([]);
    const [selectedform, setSelectedForm] = useState([])
    const [prevdialog, setPrevDialog] = useState(false);
    const [search, setSearch] = useState({ metric: '', dcf: '' })
    const [location, setLocation] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userConfig, setUserConfig] = useState({
        name: "", type: "",
        location: ''
    });
    const [module, setModule] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
    });
    const [selected, setSelected] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
    });
    const [cascade, setCascade] = useState("");
    const [showSave, setShowSave] = useState(0);
    const [tier1, setTier1] = useState([]);
    const [tier2, setTier2] = useState([]);
    const [tier3, setTier3] = useState([]);
    const [moduleList, setModuleList] = useState({
        mod: [],
        title: [],
        topic: [],
    });

    useEffect(async () => {
        setOld(selector.information);

        APIServices.get(API.UserProfile).then((res) => {
            let locuser = [];
            setUserList(res.data);
            res.data.forEach((item) => {
                if (item.role === "clientadmin") {
                    locuser.push({
                        name: item.information.companyname,
                        id: item.id,
                    });
                }
            });

            setUser(locuser);
        });
       
        APIServices.get( API.DCF).then((res) => {
            setRawDCF(res.data)
        })
        let uriString = {
            "include": [{ "relation": "newDataPoints" }]

        }

        let url = API.Metric + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
        APIServices.get(url).then(res => {
            setRaw(res.data)
            setMetricBk(res.data.map(k => { return { title: k.title, id: k.id, selected: false } }))
            setMetricList(res.data.map(k => { return { title: k.title, id: k.id, selected: false } }))

        })

    }, [selector]);
    const updateUserConfig = (obj, val) => {
        let loc = userConfig;
        loc[obj] = val;
        let ser = search
        ser.dcf = ''
        ser.metric = ''
        setSearch(ser)
        if (obj === 'name') {
            APIServices.get(API.AssignDCFClient_UP(val.id)).then((response) => {
                setEditMode(false)

                if (response.data.length !== 0) {
                    setEditMode(true)
                    setResponse(response.data[0])
                    let loc = JSON.parse(JSON.stringify(raw)).map(k => { return { title: k.title, id: k.id, selected: false } })
                    setMetricBk(loc)

                    if (response.data[0].data1 !== null && response.data[0].data1.length > 0) {


                        response.data[0].data1.forEach((item) => {
                            item.metric_ids.forEach((item2) => {
                                let indx = raw.findIndex((i) => { return i.id === item2 })
                                if (indx !== -1) {
                                    loc[indx].selected = true
                                }





                            })
                        })

                        let data = []
                        let rejected = []
                        let selected = []

                        setTimeout(() => {
                            loc.forEach((items) => {


                                if (!items.selected) {
                                    let index = raw.findIndex((k) => { return k.id === items.id })
                                    if (raw[index].newDataPoints !== undefined) {
                                        raw[index].newDataPoints.forEach((dp) => {

                                            if (dp.data1 !== undefined && dp.data1 !== null && !Array.isArray(dp.data1[0].datasource)) {
                                                if (dp.data1[0].datasource !== null) {
                                                    let index2 = rejected.findIndex((j) => { return j.id === dp.data1[0].datasource })

                                                    if (index2 === -1) {
                                                        rejected.push(dp.data1[0].datasource)
                                                    }
                                                }
                                            }

                                        })
                                    }


                                } else if (items.selected) {

                                    let index = raw.findIndex((k) => { return k.id === items.id })
                                    if (raw[index].newDataPoints !== undefined) {

                                        raw[index].newDataPoints.forEach((dp) => {

                                            if (dp.data1 !== undefined && dp.data1.length !== 0 && !Array.isArray(dp.data1[0].datasource)) {

                                                if (dp.data1[0].datasource !== null) {
                                                    let index2 = selected.findIndex((j) => { return j.id === dp.data1[0].datasource })

                                                    if (index2 === -1) {
                                                        let dcfindex = rawdcf.findIndex((dcf) => { return dcf.id === dp.data1[0].datasource })
                                                        if (dcfindex !== -1) {
                                                            selected.push(rawdcf[dcfindex])
                                                        }

                                                    }
                                                }
                                            }
                                        })

                                    }
                                }
                            })
                            console.log(selected, rejected)

                            setSelectedDCFBK(selected)
                            setSelectedDCF(selected.filter((k) => { return k.title.trim().toLowerCase().includes(search.dcf.trim().toLowerCase()) }))

                            forceUpdate()
                        }, 100)

                        setMetricList(loc.filter((k) => { return k.title.trim().toLowerCase().includes(search.metric.trim().toLowerCase()) }))
                    } else {

                        [].forEach((item2) => {
                            let indx = raw.findIndex((i) => { return i.id === item2 })
                            if (indx !== -1) {
                                loc[indx].selected = true
                            }





                        })


                        let data = []
                        let rejected = []
                        let selected = []

                        setTimeout(() => {
                            loc.forEach((items) => {


                                if (!items.selected) {
                                    let index = raw.findIndex((k) => { return k.id === items.id })
                                    if (raw[index].newDataPoints !== undefined) {
                                        raw[index].newDataPoints.forEach((dp) => {

                                            if (dp.data1 !== undefined && dp.data1 !== null && !Array.isArray(dp.data1[0].datasource)) {
                                                if (dp.data1[0].datasource !== null) {
                                                    let index2 = rejected.findIndex((j) => { return j.id === dp.data1[0].datasource })

                                                    if (index2 === -1) {
                                                        rejected.push(dp.data1[0].datasource)
                                                    }
                                                }
                                            }

                                        })
                                    }


                                } else if (items.selected) {

                                    let index = raw.findIndex((k) => { return k.id === items.id })
                                    if (raw[index].newDataPoints !== undefined) {

                                        raw[index].newDataPoints.forEach((dp) => {

                                            if (dp.data1 !== undefined && dp.data1.length !== 0 && !Array.isArray(dp.data1[0].datasource)) {

                                                if (dp.data1[0].datasource !== null) {
                                                    let index2 = selected.findIndex((j) => { return j.id === dp.data1[0].datasource })

                                                    if (index2 === -1) {
                                                        let dcfindex = rawdcf.findIndex((dcf) => { return dcf.id === dp.data1[0].datasource })
                                                        if (dcfindex !== -1) {
                                                            selected.push(rawdcf[dcfindex])
                                                        }

                                                    }
                                                }
                                            }
                                        })

                                    }
                                }
                            })
                            console.log(selected, rejected)

                            setSelectedDCFBK(selected)
                            setSelectedDCF(selected.filter((k) => { return k.title.trim().toLowerCase().includes(search.dcf.trim().toLowerCase()) }))

                            forceUpdate()
                        }, 100)

                        setMetricList(loc.filter((k) => { return k.title.trim().toLowerCase().includes(search.metric.trim().toLowerCase()) }))


                    }
                    forceUpdate()
                } else {

                    metricbk.forEach((k) => {
                        k.selected = false
                    })


                    let data = []
                    let rejected = []
                    let selected = []

                    setTimeout(() => {
                        metricbk.forEach((items) => {


                            if (!items.selected) {
                                let index = raw.findIndex((k) => { return k.id === items.id })
                                if (raw[index].newDataPoints !== undefined) {
                                    raw[index].newDataPoints.forEach((dp) => {

                                        if (dp.data1 !== undefined && dp.data1 !== null && !Array.isArray(dp.data1[0].datasource)) {
                                            if (dp.data1[0].datasource !== null) {
                                                let index2 = rejected.findIndex((j) => { return j.id === dp.data1[0].datasource })

                                                if (index2 === -1) {
                                                    rejected.push(dp.data1[0].datasource)
                                                }
                                            }
                                        }

                                    })
                                }


                            } else if (items.selected) {

                                let index = raw.findIndex((k) => { return k.id === items.id })
                                if (raw[index].newDataPoints !== undefined) {

                                    raw[index].newDataPoints.forEach((dp) => {

                                        if (dp.data1 !== undefined && dp.data1.length !== 0 && !Array.isArray(dp.data1[0].datasource)) {

                                            if (dp.data1[0].datasource !== null) {
                                                let index2 = selected.findIndex((j) => { return j.id === dp.data1[0].datasource })

                                                if (index2 === -1) {

                                                    selected.push(dp.data1[0].datasource)
                                                }
                                            }
                                        }
                                    })

                                }
                            }
                        })


                        setSelectedDCFBK(selected)
                        setSelectedDCF(selected.filter((k) => { return k.title.trim().toLowerCase().includes(search.dcf.trim().toLowerCase()) }))

                        forceUpdate()
                    }, 100)

                    setMetricList(metricbk.filter((k) => { return k.title.trim().toLowerCase().includes(search.metric.trim().toLowerCase()) }))

                    forceUpdate()
                }
            })
        }
        setUserConfig(loc)


        forceUpdate();
    };
    const prevDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setPrevDialog(false) }} />
        </>
    );
    const dupdpidDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setDupDPIDDialog(false) }} />
        </>
    );
    const onCategoryChange = (e, item) => {
        item.selected = e.checked;

        let indx = metricbk.findIndex((i) => { return i.id === item.id })
        metricbk[indx].selected = e.checked
        let data = []
        let rejected = []
        let selected = []

        setTimeout(() => {
            metricbk.forEach((items) => {


                if (!items.selected) {
                    let index = raw.findIndex((k) => { return k.id === items.id })
                    if (raw[index].newDataPoints !== undefined) {
                        raw[index].newDataPoints.forEach((dp) => {

                            if (dp.data1 !== undefined && dp.data1 !== null && !Array.isArray(dp.data1[0].datasource)) {
                                if (dp.data1[0].datasource !== null) {
                                    let index2 = rejected.findIndex((j) => { return j.id === dp.data1[0].datasource })

                                    if (index2 === -1) {
                                        rejected.push(dp.data1[0].datasource)
                                    }
                                }
                            }

                        })
                    }


                } else if (items.selected) {

                    let index = raw.findIndex((k) => { return k.id === items.id })
                    if (raw[index].newDataPoints !== undefined) {

                        raw[index].newDataPoints.forEach((dp) => {

                            if (dp.data1 !== undefined && dp.data1.length !== 0 && !Array.isArray(dp.data1[0].datasource)) {

                                if (dp.data1[0].datasource !== null) {

                                    let index2 = selected.findIndex((j) => { return j.id === dp.data1[0].datasource })

                                    let dcfindex = rawdcf.findIndex((dcf) => { return dcf.id === dp.data1[0].datasource })
                                    if (dcfindex !== -1) {
                                        if (index2 === -1) {

                                            selected.push(rawdcf[dcfindex])
                                        }

                                    }
                                }
                            }
                        })

                    }
                }
            })
            console.log(selected, rejected)

            setSelectedDCFBK(selected)
            setSelectedDCF(selected.filter((k) => { return k.title.trim().toLowerCase().includes(search.dcf.trim().toLowerCase()) }))

            forceUpdate()
        }, 100)

        setMetricList(metricbk.filter((k) => { return k.title.trim().toLowerCase().includes(search.metric.trim().toLowerCase()) }))
        forceUpdate()
    }
    const searchMetric = (e) => {
        let loc = search;
        loc.metric = e.target.value.trim()
        let mtr = JSON.parse(JSON.stringify(metricbk))
        setMetricList(mtr.filter((k) => { return k.title.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) }))
        setSearch(loc)
    }
    const searchDCF = (e) => {
        let loc = search;
        loc.dcf = e.target.value.trim()
        let mtr = JSON.parse(JSON.stringify(selecteddcfbk))
        setSelectedDCF(mtr.filter((k) => { return k.title.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) }))

        setSearch(loc)
    }
    const removeHTMLTag = (html) => {
        return html.replace(/(<([^>]+)>)/gi, "")
            .replace(/\n/g, " ")
            .replace(/&nbsp;/g, " ")
    }
    const renderPreview = () => {


        if (prevdialog && selectedform.data1.length !== 0) {

            let data = selectedform.data1


            return data.map((field, ind) => {
                if (field.type === 'paragraph') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">

                            <p>{removeHTMLTag(field.label)}</p>
                        </div>
                    )
                } else if (field.type === 'date') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'date' + ind}>{removeHTMLTag(field.label)}</label>
                            <Calendar disabled showIcon ></Calendar>
                        </div>
                    )
                } else if (field.type === 'text') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'text' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'textarea') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textarea' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputTextarea disabled></InputTextarea>
                        </div>
                    )
                } else if (field.type === 'number') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textno' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText keyfilter={'num'} disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'select') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'select' + ind}>{removeHTMLTag(field.label)}</label>
                            <Dropdown options={field.values} ></Dropdown>
                        </div>
                    )
                } else if (field.type === 'radio-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'radio' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.label}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>
                    )
                } else if (field.type === 'checkbox-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'checkbox' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.value}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>

                    )
                }
            })
        }
    }
    const saveAssignedDCF = () => {
        let metrics = JSON.parse(JSON.stringify(metricbk));
        let dcf = JSON.parse(JSON.stringify(selecteddcfbk));
        let selectedMetric = metrics.filter((i) => { return i.selected }).map((j) => { return j.id })
        let selectedDataPoints = []
        raw.forEach((k) => {
            if (selectedMetric.findIndex((ind) => { return ind === k.id }) !== -1) {
                if (k.newDataPoints !== undefined) {
                    k.newDataPoints.forEach((k) => {
                        selectedDataPoints.push(k.id)
                    })

                }
            }
        })
        let selectedDCF = dcf.map((k) => { return k.id })
        if (selectedMetric.length !== 0 && selectedDCF.length !== 0 && checkDCFForDPDuplication_()) {
            if (editmode) {
                let data = response;

                data['modified'] = moment.utc()
            
                data.data1 = [{ dcf_ids: selectedDCF, dp_ids: selectedDataPoints, metric_ids: selectedMetric, user_id: selector.id, modified: moment.utc() }]
            data.data2 = data.data2 === null ? [] : data.data2
                APIServices.patch(API.AssignDCFClient_UP(userConfig.name.id), data).then((a) => {

                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: `Data updated successfully`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
            } else {
                let data = { created: moment.utc(), dcf_ids: [], dp_ids: [], metric_ids: [], user_id: selector.id, data1: [{ dcf_ids: selectedDCF, dp_ids: selectedDataPoints, metric_ids: selectedMetric, user_id: selector.id, created: moment.utc() }], data2: [] }

                APIServices.post(API.AssignDCFClient_UP(userConfig.name.id), data).then((a) => {
                    setEditMode(true)
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: `Data saved successfully`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
            }
        } else {
            if (!checkDCFForDPDuplication_()) {
                checkDCFForDPDuplication()
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `Unable to save, DCF not assigned / not empty `,
                    showConfirmButton: false,
                    timer: 1500,
                })
            }
        }


    }
    const checkDCFForDPDuplication = () => {
        let metrics = JSON.parse(JSON.stringify(metricbk));
        let dcf = JSON.parse(JSON.stringify(selecteddcfbk));
        let selectedMetric = metrics.filter((i) => { return i.selected }).map((j) => { return j.id })
        let selectedDataPoints = []
        raw.forEach((k) => {
            if (selectedMetric.findIndex((ind) => { return ind === k.id }) !== -1) {
                if (k.newDataPoints !== undefined) {
                    k.newDataPoints.forEach((k) => {
                        selectedDataPoints.push(k.suffix)
                    })

                }
            }
        })
        let dps = [], duplicatedids = []
        selectedDataPoints.forEach((i) => {
            if (!dps.includes(i)) {
                dps.push(i)
            } else {
                if (!duplicatedids.includes(i)) {
                    duplicatedids.push(i)
                }
            }
        })

        if (dps.length === selectedDataPoints.length) {
            Swal.fire({
                position: "center",
                icon: "success",
                title: `No Duplicates Found`,
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            setDupId(duplicatedids)
            setDupDPIDDialog(true)

        }

    }
    const checkDCFForDPDuplication_ = () => {
        let metrics = JSON.parse(JSON.stringify(metricbk));
        let dcf = JSON.parse(JSON.stringify(selecteddcfbk));
        let selectedMetric = metrics.filter((i) => { return i.selected }).map((j) => { return j.id })
        let selectedDataPoints = []
        raw.forEach((k) => {
            if (selectedMetric.findIndex((ind) => { return ind === k.id }) !== -1) {
                if (k.newDataPoints !== undefined) {
                    k.newDataPoints.forEach((k) => {
                        selectedDataPoints.push(k.suffix)
                    })

                }
            }
        })
        let dps = [], duplicatedids = []
        selectedDataPoints.forEach((i) => {
            if (!dps.includes(i)) {
                dps.push(i)
            } else {
                if (!duplicatedids.includes(i)) {
                    duplicatedids.push(i)
                }
            }
        })

        if (dps.length === selectedDataPoints.length) {
            return true
        } else {
            return false
        }

    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card" >
                    <div style={{
                        fontSize: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: '600', marginBottom: 10
                    }}>Supplier DCF Assignment</div>
                    {selector.role === "eisqradmin" ?
                        <>
                            {user.length !== 0 ?
                                <>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <div className="col-6">
                                            <div style={{ marginBottom: 20 }}>
                                                <label
                                                    style={{
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    Select Enterprise / Client
                                                </label>
                                                <Dropdown value={userConfig.name} options={user} onChange={(e) => updateUserConfig("name", e.value)} optionLabel="name" filter filterBy="name" placeholder="Select Client" />

                                            </div>
                                        </div>
                                        {userConfig.name != null && userConfig.name !== '' && <div className="col-6">
                                            <div style={{ marginBottom: 20, flexDirection: 'row', display: 'flex' }}>
                                                <div className="col-4">
                                                    <label
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                    >
                                                        Total Indicator : <span style={{ color: '#005284' }}>{raw.length}</span>
                                                    </label>
                                                </div>
                                                <div className="col-4">
                                                    <label
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                    >
                                                        Indicator Assigned : <span style={{ color: '#005284' }}>{metricbk.filter((i) => { return i.selected === true }).length}</span>
                                                    </label>
                                                </div>
                                                <div className="col-4">
                                                    <label
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                    >
                                                        DCF Assigned : <span style={{ color: '#005284' }}>{selecteddcfbk.length}</span>
                                                    </label>
                                                </div>


                                            </div>
                                        </div>
                                        }
                                    </div>

                                    {userConfig.name != null && userConfig.name !== '' &&
                                        <>
                                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                <div className="col-6" >
                                                    <span className="p-input-icon-left">
                                                        <i className="pi pi-search" />
                                                        <InputText value={search.metric} onChange={searchMetric} style={{ width: 300 }} placeholder="Search Indicator" />
                                                    </span>
                                                </div>
                                                <div className="col-6">
                                                    <span className="p-input-icon-left">
                                                        <i className="pi pi-search" />
                                                        <InputText value={search.dcf} onChange={searchDCF} style={{ width: 300 }} placeholder="Search DCF" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                <div className="col-6">


                                                    <div style={{ height: '45vh', overflow: 'scroll', border: '1px solid gray', borderRadius: '10px' }}>

                                                        {metriclist.map((category, j) => {
                                                            return (
                                                                <LazyView duration={0.5}>
                                                                    <div key={category.id} style={{ padding: 10 }} className="flex align-items-center">
                                                                        <Checkbox inputId={category.id} name="category" value={category.title} onChange={(e) => { onCategoryChange(e, category) }} checked={category.selected} />
                                                                        <label htmlFor={category.id} className="ml-2">{category.title}</label>
                                                                    </div>
                                                                </LazyView>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div style={{ height: '45vh', overflow: 'scroll', border: '1px solid gray', borderRadius: '10px' }}>
                                                        {selecteddcf.map((item, j) => {
                                                            return (
                                                                <LazyView duration={0.5}  >
                                                                    <div key={item.id} style={{
                                                                        padding: '10px',
                                                                        background: '#005284',
                                                                        borderRadius: '10px', padding: 10, justifyContent: 'space-between',
                                                                        margin: '10px'
                                                                    }} className="flex align-items-center">
                                                                        {/* <div style={{position:'absolute',left:-10}}> <label>{item.suffix}</label> </div>  */}
                                                                        <label htmlFor={item.id} style={{ color: 'white', fontWeight: 'bold', width: '90%' }} >{item.title + ' ( '+item.suffix+')'}</label>
                                                                        <i className="material-icons" style={{ color: 'white', cursor: 'pointer' }} onClick={() => { setSelectedForm(item); setPrevDialog(true) }} >visibility</i>
                                                                    </div>
                                                                </LazyView>
                                                            )
                                                        })

                                                        }
                                                    </div>
                                                </div>


                                            </div>
                                            <div style={{ margin: 10, display: 'flex', justifyContent: 'space-around' }} >
                                            <Button onClick={() => { checkDCFForDPDuplication() }} > Check DP Duplication </Button>

                                                <Button onClick={() => { saveAssignedDCF() }} >{editmode ? 'Update' : 'Save'} </Button>
                                            </div>
                                        </>
                                    }
                                </> :
                                <div className=" col-12">No suppliers found, reload page </div>
                            }

                        </>


                        :
                        <div className=" col-12">You have no rights to access this page</div>

                    }
                </div>
            </div>
            <Dialog
                visible={prevdialog}
                style={{
                    width: "450px",
                }}
                header={selectedform.title + " preview"}
                modal
                className="p-fluid"
                footer={prevDialogFooter}
                onHide={() => { setPrevDialog(false) }}
            >
                {renderPreview()}
            </Dialog>
            <Dialog
                visible={dupdpiddialog}
                style={{
                    width: "450px",
                }}
                header={"Duplicated ID List"}
                modal
                className="p-fluid"
                footer={dupdpidDialogFooter}
                onHide={() => { setDupDPIDDialog(false) }}
            >
                {dupdpid.map((id) => {
                    <div style={{ margin: 10 }}>
                        <label style={{ padding: 10 }}>{id}</label>
                    </div>
                })

                }
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CSDCFAssignment, comparisonFn);
