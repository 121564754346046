import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { canvasPreview } from './canvasPreview';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';

import moment from 'moment';
import axios from 'axios';
import IATACodes from '../assets/dummy/IATACodes'
import { AutoComplete as AC } from 'primereact/autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import readXlsxFile from 'read-excel-file'
import diesel from '../assets/dummy/diesel';
import { CascadeSelect } from 'primereact/cascadeselect';
import refrig from '../assets/dummy/refrig';
import owned from '../assets/dummy/owned';
import pgas from '../assets/dummy/pgas';
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver';
import { setRef } from '@fullcalendar/core';
import { SplitButton } from 'primereact/splitbutton';
import Swal from 'sweetalert2';
import 'react-image-crop/dist/ReactCrop.css'
import exportAsImage from './exportAsImage';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"

import cx from "classnames";

import { locationTreeConversion, moduleTreeConversion } from './helper';
import { useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import $ from 'jquery'
import { API } from './constants/api_url';
import APIServices from '../service/APIService';
const google = window.google;
// @ts-ignore
window.jQuery = $;
// @ts-ignore
window.$ = $;
// (0.79/1000)

const AddClientUser = () => {

    const [data, setData] = useState([])
    const [apidata, setAPiData] = useState([])

    const [selectedConfigIndex, setSelectedConfigIndex] = useState(null)
    const [adddialog, setAddDialog] = useState(false)
    const [cropdialog, setCropDialog] = useState(false)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [config, setConfig] = useState({ selectedLocationIds: [], selectedLocation: [], selectedModule: [], selectedModuleIds: [], admin: { approver: false, user: true } })
    const [selectedconfig, setSelectedConfig] = useState({ name: 'Assigned Locations' })
    const [configdialog, setConfigDialog] = useState(false)
    const imgRef = useRef(null)
    const selector = useSelector((state) => state.user.userdetail)
    const previewCanvasRef = useRef(null)
    const [employee, setEmployee] = useState({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false } })
    const [submitted, setSubmitted] = useState(false)
    const [filter, setFilter] = useState('')
    const forceUpdate = useForceUpdate()
    const dt = useRef(null);
    const [loctree, setLocTree] = useState([])
    const [modtree, setModTree] = useState({
        "name": "Module",
        "children": [
            { "name": "General", children: [{ name: 'General Information' }] },
            {
                "name": "Module", children: [
                    {
                        "name": "Scope 1",
                        "children": [{ "name": "Statutory Combustion  Emission" }
                            , { "name": "Fugitive Emissions" }, { "name": "Mobile Combustion Emissions" }
                        ]
                    },
                    {
                        "name": "Scope 2",
                        "children": [{ "name": "Emissions from Purchased Electricity" }]
                    },
                    {
                        "name": "Scope 3",
                        "children":
                            [{ "name": "Purchased goods and services" },
                            { "name": "Business travel" }, { "name": "Employee commuting" }
                            ]
                    }
                ]
            }

        ]
    })

    let configmenu = [{ name: 'Assigned Locations' }, { name: 'Admin' }]
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


    const locationtree = { "name": "", "children": [{ "name": "India", "children": [{ "name": "TamilNadu", parent: 100, "children": [{ "name": "Vellore" }, { "name": "Sholinghur" }] }, { "name": "AP", "children": [{ "name": "Chittoor" }, { "name": "Puttur" }] }] }, { "name": "Japan", "children": [{ "name": "Yuhan", "children": [{ "name": "Site1" }, { "name": "Site2" }] }, { "name": "Beijing", "children": [{ "name": "Site3" }, { "name": "Site4" }] }] }] }

    useEffect(() => {
        // let folder = {
        //     name: "", children: []
        // }
        // selector.information.config[0].location.countrylist.forEach((item, index1) => {
        //     folder.children.push({ name: item.country, children: [] })
        //     item.city.forEach((citem, index2) => {
        //         folder.children[index1].children.push({ name: citem.city, children: [] })
        //         item.city[index2]['location'].forEach((litem, index3) => {
        //             folder.children[index1].children[index2].children.push({ name: litem['location'] })

        //         })
        //     })

        // })
        // setLocTree(folder)
    }, [selector])
    useEffect(() => {

        renderData()

    }, [])

    const renderData = () => {
        let datas = []
        let promise = new Promise((resolve, error) => {
            APIServices.get(API.UserProfile)
                .then((res) => {
                    setAPiData(res.data)

                    res.data.forEach((item, index) => {
                        if (item.role === 'clientuser' && item.information.cid === selector.id) {

                            datas.push({ id: item.id, empname: item.information.empname, empdesgination: item.information.empdesgination, empid: item.information.empid, empmailid: item.email, config: item.information.config, information: item, emplocation: item.information.emplocation === undefined ? '' : item.information.emplocation })

                        }
                        if (index === res.data.length - 1) {
                            resolve(datas)
                        }
                    })
                })
        })
        promise.then((d) => { console.log(d); setData(d); forceUpdate() })


    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2" >
                    <Button label="New User" icon="pi pi-plus" className="p-button-success mr-2" style={{ width: 150 }} onClick={() => { setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false } }); setAddDialog(true) }} />

                </div>
            </React.Fragment>
            <span className="block mt-2 md:mt-0 p-input-icon-left" style={{ width: '40%' }}>
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => { setFilter(e.target.value) }} placeholder="Search..." />
            </span>
        </div>
    );


    const checkValidMailID = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    const addNewEmployee = () => {
        if (employee.id === undefined) {
            let loc = data
            setSubmitted(true)
            if (checkValidMailID(employee.empmailid.trim()) && employee.empname.trim().length !== 0 && checkValidMailID(employee.empdesgination.trim()) && (employee.role.reporter === true || employee.role.viewer === true)) {

                let obj = employee
                let newObj = { information: {} }
                Object.keys(obj).forEach((i) => {
                    if (i === 'empmailid') {
                        newObj['email'] = obj[i]
                    } else {

                        if (i === 'emplocation') {

                            newObj.information['emplocation'] = obj[i].trim()
                        } else {
                            newObj.information[i] = obj[i]
                        }


                    }
                })


                newObj.information['companyname'] = selector.information.companyname
                newObj.information['companyid'] = selector.userId
                newObj.information['blocked'] = false;
                newObj.information['cid'] = selector.id
                newObj['role'] = 'clientuser'
                newObj['clientId'] = selector.id
                if (newObj['clientId'] !== null) {
                    APIServices.post( API.NewUserProfile,  newObj )
                        .then((res) => {

                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: `User Added successfully`,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            setData(loc)
                            renderData()
                        }).catch((e) => {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: `Email Id exist / check internet connection `,
                                showConfirmButton: false,
                                timer: 1500
                            })

                        })
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Something went wrong kindly logout & try again.`,
                        showConfirmButton: false,
                        timer: 2500
                    })
                }
                setAddDialog(false)
                setSelectedConfig({ name: 'Assigned Locations' })
                setSubmitted(false)
                setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false } })

                forceUpdate()
            }
        } else {
            setSubmitted(true)

            if (checkValidMailID(employee.empmailid.trim()) && (employee.role.reporter === true || employee.role.viewer === true) && checkValidMailID(employee.empdesgination.trim()) && employee.empname.trim().length !== 0) {
                let obj = employee
                let newObj = { information: {} }
                Object.keys(obj).forEach((i) => {
                    if (i === 'empmailid') {

                    } else {

                        if (i === 'emplocation') {
                            console.log(obj[i])
                            newObj.information['emplocation'] = obj[i].trim()
                        }
                        if (i !== 'id' && i !== 'information' && i !== 'emplocation') {

                            newObj.information[i] = obj[i]
                        }

                    }
                })


                newObj.information['companyname'] = selector.information.companyname
                newObj.information['companyid'] = selector.userId
                newObj.information['cid'] = selector.id
                newObj['clientId'] = selector.id
                newObj.information['blocked'] = employee.information.blocked
                newObj['company'] = selector.information.companyname
                newObj['role'] = employee.information.role
                console.log(newObj)
                APIServices.patch(API.UserProfile_Edit(employee.id), newObj )
                    .then((res) => {
                        setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false } })

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `Updated successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                        renderData()
                    }).catch((e) => {
                        setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false } })

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })
                setAddDialog(false)
                setSelectedConfig({ name: 'Assigned Locations' })
                setSubmitted(false)

            }
        }
    }
    const addDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewEmployee() }} />

        </div>)
    }
    const updateDataConfig = (data2) => {
        let id = data[selectedConfigIndex].id
        let apidatalocal = apidata
        let apiIndex = apidata.findIndex((i) => { return i.id === id })

        let newObj = apidatalocal[apiIndex]
        delete newObj.username;
        delete newObj.email;

        newObj.information.config = data2
        newObj.company = selector.information.companyname

        let promise = new Promise((resolve, error) => {
            APIServices.patch(API.UserProfile_Edit(id), newObj ).then((res) => {
                if (res.status === 200) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        })
        promise.then((status) => {
            if (status) {
                renderData()
            }
        })
    }


    const cropandsave = async () => {

        let canvas = previewCanvasRef.current
        let URLs = canvas.toDataURL('image/png')
        let img = document.createElement('img')
        img.src = URLs
        img.style.height = canvas.height
        img.style.width = canvas.width

        setTimeout(() => {


            // atob to base64_decode the data-URI
            var image_data = atob(URLs.split(',')[1]);
            // Use typed arrays to convert the binary data to a Blob
            var arraybuffer = new ArrayBuffer(image_data.length);
            var view = new Uint8Array(arraybuffer);
            for (var i = 0; i < image_data.length; i++) {
                view[i] = image_data.charCodeAt(i) & 0xff;
            }
            try {
                // This is the recommended method:
                var blob = new Blob([arraybuffer], { type: 'application/octet-stream' });
            } catch (e) {
                // The BlobBuilder API has been deprecated in favour of Blob, but older
                // browsers don't know about the Blob constructor
                // IE10 also supports BlobBuilder, but since the `Blob` constructor
                //  also works, there's no need to add `MSBlobBuilder`.
                var bb = new (window.WebKitBlobBuilder || window.MozBlobBuilder);
                bb.append(arraybuffer);
                var blob = bb.getBlob('application/octet-stream'); // <-- Here's the Blob
            }

            // Use the URL object to create a temporary URL
            var url = (window.webkitURL || window.URL).createObjectURL(blob);
            let loc = employee
            loc.logo = url
            setEmployee(employee)
            setCropDialog(false)
        }, 1000)

    }
    useEffect(() => {

        if (completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current) {

            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                1,
                0,
            )
        }
    }, [completedCrop, crop])
    const editUser = (user) => {
        console.log(user.information.information.role)
        setAddDialog(true)
        setEmployee({ id: user.id, information: user.information, empname: user.empname, emplocation: user.information.information.emplocation, empdesgination: user.empdesgination, empmailid: user.information.email, emplocation: user.emplocation, role: user.information.information.role })

    }
    const deleteUser = (user) => {
        alert('Delete feature is disabled by tech team')

        // Swal.fire({
        //     title: 'Delete user ?' + user.empname,
        //     text: "You won't be able to reuse mail id " + user.empmailid,
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes, delete User!'
        // }).then(async (result) => {
        //     if (result.isConfirmed) {
        //         const { value: password } = await Swal.fire({
        //             title: 'Enter Master password',
        //             input: 'password',
        //             inputLabel: 'Password',
        //             inputPlaceholder: 'Enter Master password',
        //             inputAttributes: {
        //                 maxlength: 10,
        //                 autocapitalize: 'off',
        //                 autocorrect: 'off'
        //             }
        //         })

        //         if (password) {
        //             if (password === 'failletter') {
                        //  APIservices.delete(API.UserProfile_Edit(user.id)  )
        //                     .then((res) => {


        //                         Swal.fire({
        //                             position: 'center',
        //                             icon: 'success',
        //                             title: `User deleted successfully`,
        //                             showConfirmButton: false,
        //                             timer: 1500
        //                         })

        //                         renderData()
        //                     }).catch((e) => {

        //                         Swal.fire({
        //                             position: 'center',
        //                             icon: 'error',
        //                             title: `Something went wrong`,
        //                             showConfirmButton: false,
        //                             timer: 1500
        //                         })

        //                     })
        //             }
        //         }

        //     }
        // })
    }
    const blockUser = (user) => {
        let title = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked']) ? 'Block user ' + user.empname + ' ?' : 'Unblock user ' + user.empname + ' ?'
        let text = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked']) ? "Once Blocked user cannot access the platform " : "Once user unblocked, they can access the platform "
        let cnftxt = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked']) ? 'Yes, Block User!' : 'Yes, Unblock User!'

        Swal.fire({
            title,
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: cnftxt,
        }).then((result) => {
            if (result.isConfirmed) {
                let newObj = user.information;
                delete newObj.email
                delete newObj.username
                newObj.information['blocked'] = user.information.information['blocked'] === undefined ? true : !user.information.information['blocked'];
                console.log(newObj)
                APIServices.patch(API.UserProfile_Edit(user.id),  newObj )
                    .then((res) => {


                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `User details updated successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                        renderData()
                    }).catch((e) => {

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })
            }
        })
    }
    const nameTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empname}
            </>
        );
    }
    const logoTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <img src={rowData.logo} width='50' />
            </>
        );
    }
    const configTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <i onClick={() => { openConfig(rowData) }} className='material-icons'>settings</i>
            </>
        );
    }
    const roleTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <span>{rowData.config[0].admin.approver ? 'Approver' : 'User'}</span>
            </>
        );
    }
    const editTemplate = (rowData) => {

        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                <i onMouseOut={(e) => { e.currentTarget.style.color = '#495057' }} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'green' }} onClick={() => { editUser(rowData) }} className='material-icons'>edit</i>
                <i onMouseOut={(e) => { e.currentTarget.style.color = '#495057' }} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'red' }} onClick={() => { deleteUser(rowData) }} className='material-icons'>delete</i>
                <i onMouseOut={(e) => { e.currentTarget.style.color = rowData.information.information.blocked ? 'red' : '#495057' }} style={{ cursor: 'pointer', color: rowData.information.information.blocked ? 'red' : '#495057' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'red' }} onClick={() => { blockUser(rowData) }} className='material-icons'>block</i>

            </div>
        );
    }
    const updateTree = (treeobj, type) => {

        // console.log(selector,treeobj.selectedIds.size)
        if (treeobj.selectedIds.size !== 0) {

            let conf = config
            if (type === 1) {
                conf.selectedLocationIds = Array.from(treeobj.selectedIds)

                let s = locationTreeConversion(selector.information.config[0].location, treeobj.selectedIds)

                conf.selectedLocation = s

            } else {
                conf.selectedModuleIds = Array.from(treeobj.selectedIds)
                let s = moduleTreeConversion(modtree, treeobj.selectedIds)
                conf.selectedModule = s
            }

            setConfig(config)
        } else {

            let conf = config
            if (type === 1) {
                conf.selectedLocationIds = Array.from(treeobj.selectedIds)

            } else {
                conf.selectedModuleIds = Array.from(treeobj.selectedIds)

            }
            setConfig(conf)
        }


    }

    const addConfigCountry = () => {

        if (config.location.newcountry !== '') {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => { return i.country.trim().toLowerCase() === config.location.newcountry.trim().toLowerCase() })

            if (index === -1) {
                loc.location.countrylist.push({ country: config.location.newcountry, city: [] })
                loc.location.newcountry = ''
                setConfig(loc)
                forceUpdate()
            }
        }

    }
    const addConfigCity = () => {

        if (config.location.newcity !== '') {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => { return i.country.trim().toLowerCase() === config.location.selectedcountry.trim().toLowerCase() })

            let index2 = loc.location.countrylist[index].city.findIndex((i) => { return i.city.trim().toLowerCase() === config.location.newcity.trim().toLowerCase() })
            if (index2 === -1) {
                loc.location.countrylist[index].city.push({ city: config.location.newcity, location: [] })
                loc.location.newcity = ''
                setConfig(loc)
                forceUpdate()
            }

        }

    }
    const addConfigLocation = () => {
        if (config.location.newlocation !== '') {
            let loc = config;
            let index = loc.location.countrylist.findIndex((i) => { return i.country.trim().toLowerCase() === config.location.selectedcountry.trim().toLowerCase() })

            let index2 = loc.location.countrylist[index].city.findIndex((i) => { return i.city.trim().toLowerCase() === config.location.selectedcity.trim().toLowerCase() })

            let index3 = loc.location.countrylist[index].city[index2].location.findIndex((i) => { return i.location.trim().toLowerCase() === config.location.newlocation.trim().toLowerCase() })

            if (index3 === -1) {
                loc.location.countrylist[index].city[index2].location.push({ location: config.location.newlocation })
                loc.location.newlocation = ''
                setConfig(loc)
                forceUpdate()
            }

        }
    }
    const ArrowIcon = ({ isOpen, className }) => {
        const baseClass = "arrow";
        const classes = cx(
            baseClass,
            { [`${baseClass}--closed`]: !isOpen },
            { [`${baseClass}--open`]: isOpen },
            className
        );
        return <IoMdArrowDropright className={classes} />;
    };

    const CheckBoxIcon = ({ variant, ...rest }) => {
        switch (variant) {
            case "all":
                return <TbCheckbox style={{ fontSize: 19 }} {...rest} />;
            case "none":
                return <MdCheckBoxOutlineBlank style={{ fontSize: 19 }} {...rest} />;
            case "some":
                return <MdOutlineIndeterminateCheckBox style={{ fontSize: 19 }} {...rest} />;
            default:
                return null;
        }
    };
    const openConfig = (config) => {

        setSelectedConfigIndex(data.findIndex(((item) => { return item.id === config.id })))
        setSelectedConfig({ name: 'Location' })

        if (config.config.length === 0) {

            setConfig({ selectedLocationIds: [], selectedModuleIds: [], selectedLocation: [], selectedModule: [], admin: { approver: false, user: true } })
        } else {

            setConfig(config.config[0])
        }

        setConfigDialog(true)
    }
    const contactTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empid}
            </>
        );
    }
    const destTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empdesgination}
            </>
        );
    }
    const emailTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empmailid}
            </>
        );
    }
    const userIDTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.emplocation}
            </>
        );
    }
    const onRoleChange = (e, obj) => {
        let loc = employee
        loc.role[obj] = !e.value
        setEmployee(loc)
        console.log(loc)
        forceUpdate()
    }
    const updateEmployee = (obj, val) => {
        let loc = employee
        loc[obj] = val
        setEmployee(loc)
        forceUpdate()
    }
    const centerAspectCrop = (
        mediaWidth,
        mediaHeight,
        aspect
    ) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }
    const onImageLoad = (e) => {

        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, 1))

    }
    const getCountryIndex = () => {
        let index = config.location.countrylist.findIndex((i) => { return i.country === config.location.selectedcountry })

        return index
    }
    const getCityIndex = () => {
        let index = config.location.countrylist[getCountryIndex()].city.findIndex((i) => { return i.city === config.location.selectedcity })
        return index
    }
    return (
        <div className="grid p-fluid">
            {selector.role === 'clientadmin' ? <div className="col-12 md:col-12">
                <div className="card ">
                    <div className="col-12 md:col-12" style={{

                        textAlign: 'center',
                        textDecorationColor: 'green'
                    }}>
                        <h5> {`Users (${data.length})`} </h5>
                    </div>

                    <div>
                        <DataTable ref={dt} value={data}
                            scrollable
                            dataKey="id" paginator rows={20}  rowsPerPageOptions={[10,20,50,100]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            globalFilterFields={['empname']} globalFilter={filter} emptyMessage="No records found." header={header} responsiveLayout="scroll">
                            <Column field="empname" header="User Name" body={nameTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="empmailid" header="User Mail ID" body={emailTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Location" body={userIDTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            {/* <Column field="config" header="Config" sortable body={configTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="role" header="role" sortable body={roleTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                            <Column header="Action" body={editTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column>

                        </DataTable>
                        <Dialog visible={adddialog} style={{ width: '450px' }} header="Add User" modal className="p-fluid" footer={addDialogFooter} onHide={() => { setSubmitted(false); setAddDialog(false) }}>

                            <div className="field">
                                <label htmlFor="refdate">User Name <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} value={employee.empname} onChange={(e) => { updateEmployee('empname', e.target.value) }} placeholder="Name" />
                                {submitted && employee.empname.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Name is mandatory</small>}

                            </div>
                            {/* <div className="field">
                                    <label htmlFor="refdate">User ID</label>
                                    <InputText type={'text'} disabled={employee.id !== undefined} value={employee.empid} onChange={(e) => { updateEmployee('empid', e.target.value) }} placeholder="Unique ID for login" />
                                    {submitted && employee.empid.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>User Id is mandatory</small>}

                                </div> */}
                            <div className="field">
                                <label htmlFor="refdate">User E-Mail ID / Login ID <span style={{ color: 'red' }}>*</span></label>
                                <InputText disabled={employee.id !== undefined} type={'email'} value={employee.empmailid} onChange={(e) => { updateEmployee('empmailid', e.target.value) }} placeholder="Mail ID" />
                                {submitted && !checkValidMailID(employee.empmailid.trim()) && <small className="p-invalid" style={{ color: 'red' }}>Mail ID is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Location ( Physical location of user if neccessary )</label>
                                <InputText type={'text'} value={employee.emplocation} onChange={(e) => { updateEmployee('emplocation', e.target.value) }} placeholder="location name" />


                            </div>

                            <div className="field">
                                <label htmlFor="refdate">Supervisor <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'email'} value={employee.empdesgination} onChange={(e) => { updateEmployee('empdesgination', e.target.value) }} placeholder="Supervisor mail id" />
                                {submitted && !checkValidMailID(employee.empdesgination.trim()) && <small className="p-invalid" style={{ color: 'red' }}>Supervisor Mail ID is mandatory</small>}


                            </div>
                            <div >
                                <label htmlFor="refdate">Choose Role <span style={{ color: 'red' }}>*</span></label>
                                <div style={{ display: 'flex', marginTop: 5, marginLeft: 10 }}>
                                    {/* <div className="flex align-items-center">
                                        <Checkbox inputId="rep" disabled name="pizza" value={employee.role.reporter} onChange={(e)=>{onRoleChange(e,'reporter')}} checked={employee.role.reporter === true} />
                                        <label htmlFor="rep" className="ml-2">Reporter</label>
                                    </div> */}
                                    <div style={{ marginLeft: 10 }} className="flex align-items-center">
                                        <Checkbox inputId="view" name="pizza" value={employee.role.viewer} onChange={(e) => { onRoleChange(e, 'viewer') }} checked={employee.role.viewer === true} />
                                        <label htmlFor="view" className="ml-2">View Access</label>
                                    </div>
                                </div>
                                {submitted && employee.role.reporter === false && employee.role.viewer === false && <small className="p-invalid" style={{ color: 'red' }}>Select role</small>}

                            </div>



                        </Dialog>


                    </div>







                </div>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }
        </div>
    )
}

export default AddClientUser

