
import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import useForceUpdate from 'use-force-update';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { TabMenu } from 'primereact/tabmenu'
import { InputTextarea } from 'primereact/inputtextarea';

const UserCF = () => {
    const [test, setTest] = useState({ date: null, site: null })
    const [activeItem, setActiveItem] = useState(1);
    const [activeESGItem, setActiveESGItem] = useState(1);
    const items = [
        { label: 'ESG', command: () => setActiveItem(1) },
        { label: 'EHS', command: () => setActiveItem(2) },
    ];
    const items_ESG = [
        { label: 'Environment', command: () => setActiveESGItem(1) },
        { label: 'HR', command: () => setActiveESGItem(2) },
        { label: 'Procurement', command: () => setActiveESGItem(3) },

    ];
    const forceUpdate = useForceUpdate()
    useEffect(() => { activeItem === 1 && freezeFilter() }, [activeItem])
    const data = [
        { dataPoint: "Total quantity of water withdrawn from River", quantity: '', unit: '', category: '' },
        { dataPoint: "Total quantity of water withdrawn from Lake", quantity: '', unit: '', category: '' },
        { dataPoint: "Total quantity of rainwater harvested used in operations", quantity: '', unit: '', category: '' },
        { dataPoint: "Total quantity of water withdrawn from groundwater sources", quantity: '', unit: '', category: '' },
        { dataPoint: "Total quantity of water withdrawn from seawater sources", quantity: '', unit: '', category: '' },
        { dataPoint: "Total quantity of water withdrawn from produced water sources", quantity: '', unit: '', category: '' },
        { dataPoint: "Total quantity of Municipal water used in operations", quantity: '', unit: '', category: '' },
        { dataPoint: "Total Quantity of third party tanker water used", quantity: '', unit: '', category: '' }




    ];
    const data2 = [
        { dataPoint: "Total No of Male Workers under 30 years of age", number:null ,remarks:'' },
        { dataPoint: "Total No of Male Workers in 30-50 years of age", number:null,remarks:'' },
        { dataPoint: "Total No of Male Workers over 50 years of age", number:null,remarks:'' },
        { dataPoint: "Total No of Female Workers under 30 years of age", number:null,remarks:'' },
        { dataPoint: "Total No of Female Workers in 30-50 years of age", number:null,remarks:'' },
        { dataPoint: "Total No of Female Workers over 50 years of age", number:null,remarks:'' },

    ];
    const data3 = [
        { dataPoint: "Total Number of vendors", data:null ,unit:null },
        { dataPoint: "No.of  Indian Vendors  (Only Active )", data:null ,unit:null },
        { dataPoint: "No.of Vendors from other Nations  (Only Active )", data:null ,unit:null },
        { dataPoint: "Total Spend on Suppliers", data:null ,unit:null },
        { dataPoint: "Total Spend on Local Suppliers", data:null ,unit:null },
        { dataPoint: "Number of new Indian Suppliers added in the reporting year", data:null ,unit:null },
        { dataPoint: "Number of new suppliers from other countries added in the reporting year", data:null ,unit:null },
        { dataPoint: "Number of new suppliers that were screened using social criteria.", data:null ,unit:null },
        { dataPoint: "Number of new suppliers that were screened using environmental criteria.", data:null ,unit:null },
        { dataPoint: "Total Number of suppliers assessed for social impacts.", data:null ,unit:null },
        { dataPoint: "Total Number of suppliers assessed for Environmental impacts.", data:null ,unit:null },
        { dataPoint: "Number of suppliers identified as having significant actual and potential negative social impacts.", data:null ,unit:null },
        { dataPoint: "Number of suppliers identified as having significant actual and potential negative Environmental impacts.", data:null ,unit:null },


    ];
    const headerCenter = (text) => {
        return (
            <label style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                {text}
            </label>
        )
    }
    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;

        switch (field) {


            default:
                if(typeof newValue  === 'string'){
                    if (newValue.trim().length > 0) rowData[field] = newValue;
                    else event.preventDefault();
                }else if(typeof newValue  === 'number'){
                    if (newValue >= 0) rowData[field] = newValue;
                    else event.preventDefault();
                }
             
                break;
        }
    };
    const textEditor = (options) => {
        return <InputText style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const numberEditor = (options) => {
        return <InputText type='number' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const textCellEditor = (options) => {

        return textEditor(options);
    };
    const numberCellEditor = (options) => {

        return numberEditor(options);
    };
    const freezeFilter = () => {
        let stickyElem = document.querySelector(".sticky-div");
        if (stickyElem) {
            let currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
            window.onscroll = function () {
                /* Check if the current Y offset
                is greater than the position of
                the element */
                if (window.pageYOffset > currStickyPos) {
                    stickyElem.style.position = "fixed";
                    stickyElem.style.top = "80px";
                    stickyElem.style.right = "35px";

                    stickyElem.style.width =
                        document.getElementById("divsize").clientWidth + "px";

                    stickyElem.style.zIndex = 999;
                } else {
                    stickyElem.style.position = "relative";
                    stickyElem.style.top = "initial";
                    stickyElem.style.right = "initial";

                    stickyElem.style.width = "100%";
                    stickyElem.style.background = "white";
                    stickyElem.style.zIndex = 1;
                }
            };
        }
    }; 
    const categoryEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                style={{ width: '100%' }}
                options={['Fresh Water', 'Other Water']}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a category"

            />
        );
    };
    const unitEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                style={{ width: '100%' }}
                options={['NOS','INR', 'USD','AED','EUR','SGD']}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select unit"

            />
        );
    };
    const customTooltipBody = (rowData, column) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center', alignItems: 'center'
            }}>
                <Tooltip position="top" target={'.tooltips'} />
                <div >Category of water
                    <span><i style={{
                        fontSize: '18px', marginLeft: 5
                    }} className={`material-icons tooltips`} data-pr-tooltip={`Freshwater (≤1,000 mg/L Total Dissolved Solids)
                        Other water (>1,000 mg/L Total Dissolved Solids)
                        `}


                    > help</i></span>
                </div>

            </div>



        );
    };
    return (
        <Card style={{ margin: '80px 10px 10px 10px', height: 'calc(100vh - 9rem)', overflowX: 'scroll' }}>
            <TabMenu model={items} />
            {activeItem === 1 &&
                <div style={{ margin: '10px' }}>
                    <TabMenu model={items_ESG} />
                    {activeESGItem === 1 &&
                        <Card title="Site Data Collection Sheet" id='divsize'>
                            <div className='col-12 grid sticky-div'>
                                <div className='col-6'>
                                    <label>
                                        Select Unit :
                                    </label>
                                    {/* <Dropdown value={test.site} style={{ marginLeft: 10, width: '50%' }} options={[{ name: 'Chennai' }, { name: 'Bangalore' }]} optionLabel='name' placeholder="Select Unit" onChange={(e) => { let loc = test; loc.site = e.value; setTest(loc); forceUpdate() }} /> */}
                                    <label style={{ marginLeft: 10, fontWeight: 'bold' }}>Location A</label>
                                </div>
                                <div className='col-6 flex justify-content-end' >
                                    <label>
                                        Data Duration :
                                    </label>
                                    <label style={{ marginLeft: 10, fontWeight: 'bold' }}> OCT-2023 ( provide data from 01-Oct-2023 to 31-Oct-2023 ) </label>
                                    {/* <Calendar style={{ marginLeft: 10 }}  value={[
    "2022-12-31T18:30:00.000Z",
    "2023-02-28T18:30:00.000Z"
]} onChange={(e) => { let loc = test; loc.date = e.value; setTest(loc); console.log(loc); forceUpdate() }} selectionMode="range" dateFormat="mm/yy" view='month'  /> */}

                                </div>

                            </div>

                            <DataTable className='fullheight' paginator={false} rows={data.length} header='Environment - Water and Effluent' value={data} scrollable showGridlines>
                                <Column field="dataPoint" headerClassName='headercenter' header={headerCenter('Data Required')}></Column>
                                <Column field="quantity" headerClassName='headercenter' header={headerCenter('Quantity')} editor={(options) => textCellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
                                <Column field="unit" headerClassName='headercenter' header={headerCenter('Unit of Measure')} editor={(options) => textCellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
                                <Column field="category" body={(rowData) => { return <><Dropdown options={['Fresh Water', 'Other Water']} style={{ width: '100%' }} value={rowData.category} /> </> }} headerClassName='headercenter' editor={(options) => categoryEditor(options)} onCellEditComplete={onCellEditComplete} header={customTooltipBody}></Column>

                            </DataTable>
                            <div className='p-datatable' style={{ marginTop: 15 }}>
                                <div class="p-datatable-header">Environment - Reduction of energy consumption</div>
                            </div>

                            <div>
                                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                                    <label style={{ display: 'flex' }} className='col-12'>Energy Reduction Measures taken in the Manufacturing unit</label>
                                    <InputTextarea style={{ margin: 10, width: '100%' }} />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                                    <label style={{ display: 'flex' }} className='col-12'>Types of Energy covered in Reduction Measures:</label>
                                    <Dropdown style={{ margin: 10, width: 180 }} options={'Electricity,Fuel,Heat,Cooling'.split(',')} />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                                    <label style={{ display: 'flex' }} className='col-12'>Fuels included in reduction measure</label>
                                    <InputTextarea style={{ margin: 10, width: '100%' }} />
                                </div>
                            </div>

                            <div>
                                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                                    <label style={{ display: 'flex' }} className='col-12'>Base year for calculating the reduction:</label>
                                    <div className='col-12 grid'>
                                        <div className='col-6'>
                                            <label>Value :</label>
                                            <InputText type='number' style={{ margin: 10, marginLeft: 10 }} />
                                        </div>
                                        <div className='col-6'>
                                            <label>Unit :</label>
                                            <InputText type='text' style={{ margin: 10, marginLeft: 10 }} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                                    <label style={{ display: 'flex' }} className='col-12'>Total reductions in the reporting period: </label>
                                    <div className='col-12 grid'>
                                        <div className='col-6'>
                                            <label>Value :</label>
                                            <InputText type='number' style={{ margin: 10, marginLeft: 10 }} />
                                        </div>
                                        <div className='col-6'>
                                            <label>Unit :</label>
                                            <InputText type='text' style={{ margin: 10, marginLeft: 10 }} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                                    <label style={{ display: 'flex' }} className='col-12'>Methodology followed to calculate the reduction:</label>
                                    <InputTextarea style={{ margin: 10, width: '100%' }} />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                                    <label style={{ display: 'flex' }} className='col-12'>Calculation tools used for estimating the value (if any)</label>
                                    <InputTextarea style={{ margin: 10, width: '100%' }} />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                                    <label style={{ display: 'flex' }} className='col-12'>Assumptions (if any): </label>
                                    <InputTextarea style={{ margin: 10, width: '100%' }} />
                                </div>
                            </div>
                        </Card>
                    }
                      {activeESGItem === 2 &&
                        <Card title="Site Data Collection Sheet" id='divsize'>
                            <div className='col-12 grid sticky-div'>
                                <div className='col-6'>
                                    <label>
                                        Select Unit :
                                    </label>
                                    {/* <Dropdown value={test.site} style={{ marginLeft: 10, width: '50%' }} options={[{ name: 'Chennai' }, { name: 'Bangalore' }]} optionLabel='name' placeholder="Select Unit" onChange={(e) => { let loc = test; loc.site = e.value; setTest(loc); forceUpdate() }} /> */}
                                    <label style={{ marginLeft: 10, fontWeight: 'bold' }}>Location A</label>
                                </div>
                                <div className='col-6 flex justify-content-end' >
                                    <label>
                                        Data Duration :
                                    </label>
                                    <label style={{ marginLeft: 10, fontWeight: 'bold' }}> OCT-2023 ( provide data from 01-Oct-2023 to 31-Oct-2023 ) </label>
                                    {/* <Calendar style={{ marginLeft: 10 }}  value={[
    "2022-12-31T18:30:00.000Z",
    "2023-02-28T18:30:00.000Z"
]} onChange={(e) => { let loc = test; loc.date = e.value; setTest(loc); console.log(loc); forceUpdate() }} selectionMode="range" dateFormat="mm/yy" view='month'  /> */}

                                </div>

                            </div>

                            <DataTable className='fullheight' paginator={false} rows={data2.length} header='Social - Employment' value={data2} scrollable showGridlines>
                                <Column field="dataPoint" headerClassName='headercenter' header={headerCenter('Data Required')}></Column>
                                <Column field="number" headerClassName='headercenter' header={headerCenter('Number')} editor={(options) => numberCellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
                                <Column field="unit" headerClassName='headercenter' header={headerCenter('Remarks')} editor={(options) => textCellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
                            

                            </DataTable>
                     
                        </Card>
                    }
                     {activeESGItem === 3 &&
                        <Card title="Site Data Collection Sheet" id='divsize'>
                            <div className='col-12 grid sticky-div'>
                                <div className='col-6'>
                                    <label>
                                        Select Unit :
                                    </label>
                                    {/* <Dropdown value={test.site} style={{ marginLeft: 10, width: '50%' }} options={[{ name: 'Chennai' }, { name: 'Bangalore' }]} optionLabel='name' placeholder="Select Unit" onChange={(e) => { let loc = test; loc.site = e.value; setTest(loc); forceUpdate() }} /> */}
                                    <label style={{ marginLeft: 10, fontWeight: 'bold' }}>Location A</label>
                                </div>
                                <div className='col-6 flex justify-content-end' >
                                    <label>
                                        Data Duration :
                                    </label>
                                    <label style={{ marginLeft: 10, fontWeight: 'bold' }}> OCT-2023 ( provide data from 01-Oct-2023 to 31-Oct-2023 ) </label>
                                    {/* <Calendar style={{ marginLeft: 10 }}  value={[
    "2022-12-31T18:30:00.000Z",
    "2023-02-28T18:30:00.000Z"
]} onChange={(e) => { let loc = test; loc.date = e.value; setTest(loc); console.log(loc); forceUpdate() }} selectionMode="range" dateFormat="mm/yy" view='month'  /> */}

                                </div>

                            </div>

                            <DataTable className='fullheight' paginator={false} rows={data3.length} header='Sustainable Procurement' value={data3} scrollable showGridlines>
                                <Column field="dataPoint" headerClassName='headercenter' header={headerCenter('Data Required')}></Column>
                                <Column field="data" headerClassName='headercenter' header={headerCenter('Data')} editor={(options) => numberCellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
                                <Column field="unit" body={(rowData) => { return <><Dropdown options={['NOS', 'INR', 'USD','AED','EUR','SGD']} style={{ width: '100%' }} value={rowData.unit} /> </> }} headerClassName='headercenter' editor={(options) => unitEditor(options)} onCellEditComplete={onCellEditComplete} header={'Unit'}></Column>

                            </DataTable>
                           
                        </Card>
                    }
                </div>
            }
        </Card>
    );
}

export default UserCF;