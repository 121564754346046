import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../components/constants/api_url";
import { ContextMenu } from "primereact/contextmenu";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { Tag } from "primereact/tag";
import { SplitButton } from "primereact/splitbutton";
import Axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { ref } from "yup";
import * as XLSX from "xlsx";
import { InputNumber } from "primereact/inputnumber";
import FileSaver from "file-saver";
import { AutoComplete as AC } from "primereact/autocomplete";
import {
    GoogleMap,
    useJsApiLoader,
    Autocomplete,
} from "@react-google-maps/api";
import { gastype } from "../components/constants/EmissionFactor/Fugitive/gastype";
import { fuelcategory } from "../components/constants/EmissionFactor/Stationary Combustion/fuelcategory";
import { bt_road_category } from "../components/constants/EmissionFactor/Business Travel/road_category";
import IATACodes from "../assets/dummy/IATACodes";
import { BgEvent } from "@fullcalendar/core";
import { mobile_combustion } from "../components/constants/EmissionFactor/Mobile Combustion/mobile_combustion";
import { purchase_gs } from "../components/constants/EmissionFactor/Purchase Goods & Services/purchase_gs";
import { currency_codes } from "../components/constants/EmissionFactor/Purchase Goods & Services/currency_codes";
import APIServices from "../service/APIService";
import { DateTime } from "luxon";
import { TabMenu } from "primereact/tabmenu";
import { ListBox } from "primereact/listbox";
const google = window.google;

//Commonly used Function
const getUploadedFileSize = (files) => {
    let fileSize = 0;
    if (files !== null) {
        files.forEach((file) => {
            fileSize = fileSize + file.size;
        });
    }
    return fileSize;
};
const uploadFilesApi = async (file, filelist) => {
    let promise = new Promise((resolve, rej) => {
        if (getUploadedFileSize(filelist) + file.size <= 10000000) {
            let formData = new FormData();
            formData.append("file", file);
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
                mode: "no-cors",
            }).then((res) => {
                console.log(res);
                if (res.status === 200) {
                    resolve({
                        url: API.Docs + res.data.files[0].originalname,
                        name: res.data.files[0].originalname,
                        size: res.data.files[0].size,
                    });
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });

    return promise;
};
const dateFormatting = (rowData) => {
    return (
        <>
            <span>{moment(rowData.DPA0137).local().format("DD-MM-YYYY")}</span>
        </>
    );
};
const dateFormatting_sc = (rowData) => {

    return (
        <>
            <span>{moment(rowData.DPAN460).local().format("DD-MM-YYYY")}</span>
        </>
    );
};
const dateFormatting_bt = (rowData) => {
    return (
        <>
            <span>{moment(rowData.DPA0290).local().format("DD-MM-YYYY")}</span>
        </>
    );
};
const dateFormatting_pg = (rowData) => {
    return (
        <>
            <span>{moment(rowData.DPA0285).local().format("DD-MM-YYYY")}</span>
        </>
    );
};

const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};
const getID = () => {
    return (
        parseInt(Date.now() * Math.random()) +
        moment().unix() +
        randomIntFromInterval(1, 1000)
    );
};
const exportToCSV = (csvData, sheetname, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {
        Sheets: {
            [sheetname]: ws,
        },
        SheetNames: [sheetname],
    };
    const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
    });
    const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
};

const excelDateToJSDate = (excelDate) => {
    var date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
    var converted_date = date.toISOString().split("T")[0];
    return date;
};

//Stationary combustion
const Stationary_Combustion = ({ data, getData, setData, edit }) => {
    const [scdata, setSCData] = useState(data);
    const forceUpdate = useForceUpdate();
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [scfilter, setScFilter] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [fuelcatlist, setFuelCatList] = useState([]);
    const [fuellist, setFuelList] = useState([]);
    const [scVal, setSCVal] = useState({
        DPAN460: DateTime.utc().toJSDate(),
        DPA0130: null,
        DPA0131: null,
        DPA0132: null,
        DPA0133: "",
        DPA0134: "",
        DPA0336: "",
        attachment: null,
    });
    const [scdialog, setScDialog] = useState(false);
    useEffect(() => {
        let fuel_cat = [];
        fuelcategory.forEach((i) => {
            if (
                fuel_cat.findIndex((k) => {
                    return k.name === i.fuel_type;
                }) === -1
            ) {
                fuel_cat.push({ name: i.fuel_type });
            }
        });
        setFuelCatList(fuel_cat);
    }, []);
    useState(() => {
        setData();
    }, [scdata]);
    getData(scdata);
    const [unitlist, setUnitList] = useState([]);
    const scheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setSCVal({
                                DPAN460: DateTime.utc().toJSDate(),
                                DPA0130: null,
                                DPA0131: null,
                                DPA0132: null,
                                DPA0133: "",
                                DPA0134: "",
                                DPA0336: "",
                                attachment: null,
                            });
                            setSubmitted(false);

                            setScDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setScFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const saveStationary = () => {
        setSubmitted(true);
        console.log(
            scVal,
            scVal.DPA0131 !== null ||
            (scVal.DPA0130.name === "Solid Fuels" ||
                scVal.DPA0130.name === "Biomass"
                ? parseInt(scVal.DPA0133) > 0 && parseInt(scVal.DPA0134) > 0
                : false)
        );
        if (
            scVal.DPA0130 !== null && scVal.DPAN460 !== null &&
            (scVal.DPA0131 !== null ||
                (scVal.DPA0130.name === "Solid Fuels" ||
                    scVal.DPA0130.name === "Biomass"
                    ? parseInt(scVal.DPA0133) > 0 && parseInt(scVal.DPA0134) > 0
                    : false)) &&
            (scVal.DPA0132 !== null ||
                (scVal.DPA0130.name === "Solid Fuels" ||
                    scVal.DPA0130.name === "Biomass"
                    ? parseInt(scVal.DPA0133) > 0 && parseInt(scVal.DPA0134) > 0
                    : false)) &&
            scVal.attachment !== null &&
            parseInt(scVal.DPA0336) > 0 &&
            (scVal.DPA0130.name === "Solid Fuels" || scVal.DPA0130.name === "Biomass"
                ? parseInt(scVal.DPA0133) >= 0 && parseInt(scVal.DPA0134) >= 0
                : true)
        ) {
            if (scVal.id === undefined) {
                scVal["id"] = getID();
                setSCData((prev) => [scVal, ...prev]);
            } else {
                let loc = scdata;
                let index = loc.findIndex((k) => {
                    return k.id === scVal.id;
                });
                loc[index] = scVal;
                setSCData(loc);
            }

            forceUpdate();
            setSubmitted(false);
            setScDialog(false);
        }

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Stationary Combustion Emissions") {
                    let local = {};
                    let local2 = scdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_SC") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items['Fuel Category'] !== undefined &&
                                    items['Fuel Category'].trim().length !== 0 &&
                                    items['Fuel Type'] !== undefined &&
                                    items['Fuel Type'].trim().length !== 0 &&
                                    items.Unit !== undefined &&
                                    items.Unit.trim().length !== 0 &&
                                    (items['Fuel Category'] !== undefined &&
                                        (items['Fuel Category'].trim() === "Solid Fuels" ||
                                            items['Fuel Category'].trim() === "Biomass")
                                        ? items['Avg Net Calorific'] !== undefined &&
                                        typeof items['Avg Net Calorific'] === "number" &&
                                        items['Avg Net Calorific'] > 0 &&
                                        items['Avg carbon Content'] !== undefined &&
                                        typeof items['Avg carbon Content'] === "number" &&
                                        items['Avg carbon Content'] > 0
                                        : true) &&
                                    items['Quantity Used'] !== undefined &&
                                    typeof items['Quantity Used'] === "number" &&
                                    items['Quantity Used'] > 0
                                ) {
                                    let fuel_cat_ind = fuelcategory.findIndex((i) => {
                                        return (
                                            i.fuel_type.toLowerCase().trim() ===
                                            items['Fuel Category'].toLowerCase().trim()
                                        );
                                    });
                                    let fuel_ind = fuelcategory.findIndex((i) => {
                                        return (
                                            i.fuel.toLowerCase().trim() ===
                                            items['Fuel Type'].toLowerCase().trim()
                                        );
                                    });
                                    let unit_ind = fuelcategory.findIndex((i) => {
                                        return (
                                            i.unit.toLowerCase().trim() ===
                                            items.Unit.toLowerCase().trim()
                                        );
                                    });
                                    if (
                                        fuel_cat_ind !== -1 &&
                                        fuel_ind !== -1 &&
                                        unit_ind !== -1
                                    ) {
                                        local["DPA0130"] = {
                                            name: fuelcategory[fuel_cat_ind].fuel_type,
                                        };
                                        local["DPA0131"] = {
                                            name: fuelcategory[fuel_ind].fuel,
                                        };
                                        local["DPA0132"] = {
                                            name: fuelcategory[unit_ind].unit,
                                        };
                                        local['DPAN460'] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["attachment"] = null;
                                        local["DPA0336"] = items['Quantity Used'].toString();
                                        console.log(items['Fuel Category'].trim() === "Solid Fuels");
                                        if (
                                            items['Fuel Category'].trim() === "Solid Fuels" ||
                                            items['Fuel Category'].trim() === "Biomass"
                                        ) {
                                            local["DPA0133"] = items['Avg Net Calorific'].toString();
                                            local["DPA0134"] = items['Avg carbon Content'].toString();
                                        } else {
                                            local["DPA0133"] = "";
                                            local["DPA0134"] = "";
                                        }

                                        local2.push(local);
                                    }
                                }
                            });
                        }
                    });

                    setSCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const updateScVal = (obj, val) => {
        let local = scVal;

        local[obj] = val;
        if (obj === "DPA0130") {
            local["DPA0132"] = null;
            local["DPA0131"] = null;
            let fuel_type = [];
            fuelcategory.forEach((i) => {
                if (i.fuel_type === val.name) {
                    if (
                        fuel_type.findIndex((k) => {
                            return k.name === i.fuel;
                        }) === -1
                    ) {
                        fuel_type.push({ name: i.fuel });
                    }
                }
            });
            setFuelList(fuel_type);
        } else if (obj === "DPA0131") {
            local["DPA0132"] = null;

            let units = [];
            fuelcategory.forEach((i) => {
                if (i.fuel === val.name && i.fuel_type === local["DPA0130"].name) {
                    if (
                        units.findIndex((k) => {
                            return k.name === i.unit;
                        }) === -1
                    ) {
                        units.push({ name: i.unit });
                    }
                }
            });
            setUnitList(units);
        }
        if (obj === "DPA0132") {
            local.DPA0133 = "";
            local.DPA0134 = "";
            local.DPA0336 = "";
        }
        setSCVal(local);

        forceUpdate();
    };
    const editScVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPAN460 = DateTime.fromISO(items.DPAN460, { zone: 'utc' }).toJSDate()
        let fuel_type = [],
            units = [];
        if (item.DPA0131 !== null) {
            fuelcategory.forEach((i) => {
                if (i.fuel_type === item.DPA0130.name) {
                    if (
                        fuel_type.findIndex((k) => {
                            return k.name === i.fuel;
                        }) === -1
                    ) {
                        fuel_type.push({ name: i.fuel });
                    }
                }
            });
            setFuelList(fuel_type);
        }
        if (item.DPA0132 !== null) {
            fuelcategory.forEach((i) => {
                if (
                    i.fuel === item.DPA0131.name &&
                    i.fuel_type === item["DPA0130"].name
                ) {
                    if (
                        units.findIndex((k) => {
                            return k.name === i.unit;
                        }) === -1
                    ) {
                        units.push({ name: i.unit });
                    }
                }
            });
            setUnitList(units);
        }

        setSCVal(items);
        console.log(items);
        setScDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = scdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setSCData(loc);
        forceUpdate();
    };
    const scDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveStationary();
                }}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplateSC = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editScVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                "date": "",
                                                "Fuel Category": "",
                                                "Fuel Type": "",
                                                "Unit": "",
                                                "Quantity Used": "",
                                                "Avg Net Calorific": "",
                                                "Avg carbon Content": "",
                                            },
                                        ],
                                        "S1_SC",
                                        "Stationary Combustion Emissions"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Stationary Combustion Emissions");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={scdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0130.name", "DPA0131.name", "DPA0132.name"]}
                globalFilter={scfilter}
                emptyMessage="No records found."
                header={edit === 1 && scheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPAN460"
                    header="Date"
                    body={dateFormatting_sc}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0130.name"
                    header="Fuel Category"
                    sortable
                    headerStyle={{
                        width: "25%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0131.name"
                    header="Fuel Type"
                    sortable
                    headerStyle={{
                        width: "25%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0132.name"
                    header="Unit"
                    sortable
                    headerStyle={{
                        width: "12%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0336"
                    header="Quantity Used"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0133"
                    header="Avg Net Calorific"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0134"
                    header="Avg Carbon Content"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateSC}> </Column>}
            </DataTable>
            <Dialog
                visible={scdialog}
                style={{
                    width: "450px",
                }}
                header="Add New"
                modal
                className="p-fluid"
                footer={scDialogFooter}
                onHide={() => {
                    setScDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="refdate">Date </label>
                    <Calendar
                        showIcon dateFormat={'dd/mm/yy'}
                        value={scVal.DPAN460}
                        onChange={(e) => updateScVal("DPAN460", e.value)}
                    ></Calendar>
                    {submitted && scVal.DPAN460 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Date required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="DPA0130">Select Fuel Category</label>
                    <Dropdown
                        value={scVal.DPA0130}
                        onChange={(e) => {
                            updateScVal("DPA0130", e.value);
                        }}
                        options={fuelcatlist}
                        optionLabel="name"
                        placeholder="Select"
                    />
                    {submitted && scVal.DPA0130 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Fuel Category is required
                        </small>
                    )}
                </div>
                {scVal.DPA0130 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0131">Select Fuel Type</label>
                        <Dropdown
                            value={scVal.DPA0131}
                            onChange={(e) => {
                                updateScVal("DPA0131", e.value);
                            }}
                            options={fuellist}
                            optionLabel="name"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0131 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Fuel type is required
                            </small>
                        )}
                    </div>
                )}
                {scVal.DPA0131 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0132">Select Unit</label>
                        <Dropdown
                            value={scVal.DPA0132}
                            onChange={(e) => {
                                updateScVal("DPA0132", e.value);
                            }}
                            options={unitlist}
                            optionLabel="name"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0132 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Unit
                            </small>
                        )}
                    </div>
                )}
                {((scVal.DPA0130 !== null &&
                    scVal.DPA0131 !== null &&
                    scVal.DPA0132 !== null) ||
                    (scVal.DPA0130 !== null &&
                        (scVal.DPA0130.name === "Solid Fuels" ||
                            scVal.DPA0130.name === "Biomass"))) && (
                        <>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="gasrefill"> Quantity of fuel used </label>
                                    <div id="gasrefill" className="p-inputgroup">
                                        <InputText
                                            keyfilter={"pnum"}
                                            value={scVal.DPA0336}
                                            onChange={(e) => {
                                                updateScVal("DPA0336", e.target.value);
                                            }}
                                            placeholder="Quantity"
                                        />
                                        {scVal.DPA0132 !== null && (
                                            <span className="p-inputgroup-addon">
                                                {scVal.DPA0132.name}{" "}
                                            </span>
                                        )}
                                    </div>
                                    {submitted &&
                                        (scVal.DPA0336.trim() === "" ||
                                            parseInt(scVal.DPA0336) <= 0) && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Quantity is required
                                            </small>
                                        )}
                                </div>
                            </div>
                            {(scVal.DPA0130.name === "Solid Fuels" ||
                                scVal.DPA0130.name === "Biomass") && (
                                    <div>
                                        <div className="formgrid grid">
                                            <div className="field col">
                                                <label htmlFor="DPA0133">
                                                    {" "}
                                                    Average Net Calorific value of the fuel used{" "}
                                                </label>
                                                <div id="DPA0133" className="p-inputgroup">
                                                    <InputText
                                                        keyfilter={"pnum"}
                                                        value={scVal.DPA0133}
                                                        onChange={(e) => {
                                                            updateScVal("DPA0133", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {submitted &&
                                                    (scVal.DPA0133.trim() === "" ||
                                                        parseInt(scVal.DPA0133) < 0) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Calorific Value required
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="formgrid grid">
                                            <div className="field col">
                                                <label htmlFor="gasrefill">
                                                    Average Carbon Content of the fuel used
                                                </label>
                                                <div id="gasrefill" className="p-inputgroup">
                                                    <InputText
                                                        keyfilter={"pnum"}
                                                        value={scVal.DPA0134}
                                                        onChange={(e) => {
                                                            updateScVal("DPA0134", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {submitted &&
                                                    (scVal.DPA0134.trim() === "" ||
                                                        parseInt(scVal.DPA0134) < 0) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Carbon Content is required
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </>
                    )}

                <>
                    <FileUpload
                        mode="basic"
                        customUpload
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(scVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], scVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = scVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setSCVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(scVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && scVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {scVal.attachment !== null && (
                    <>
                        {scVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    scVal.attachment.splice(index, 1);
                                                    if (scVal.attachment.length === 0) {
                                                        scVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Stationary_Combustion_ = ({ data, getData, setData, edit, standard }) => {
    const [scdata, setSCData] = useState(data);
    const forceUpdate = useForceUpdate();

    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [scfilter, setScFilter] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [fuelcatlist, setFuelCatList] = useState([]);
    const [fuellist, setFuelList] = useState([]);
    const [category, setCategory] = useState([]);

    const [scVal, setSCVal] = useState({
        DPA0130: null,
        DPA0131: null,
        DPA0132: null,
        DPA0133: 0,
        DPA0134: 0,
        DPA0336: 0,
        attachment: null,
    });
    const [scdialog, setScDialog] = useState(false);
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard === 0 || standard === 1) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === 1
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 1);
                        setCategory(
                            JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories[
                                cat_index
                            ].newEfSubcategory1s
                        );
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setFuelCatList(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    useState(() => {
        setData();
    }, [scdata]);
    getData(scdata);
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    const [unitlist, setUnitList] = useState([]);
    const scheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setSCVal({
                                DPA0130: null,
                                DPA0131: null,
                                DPA0132: null,
                                DPA0133: 0,
                                DPA0134: 0,
                                DPA0336: 0,
                                attachment: null,
                            });
                            setSubmitted(false);

                            setScDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setScFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const saveStationary = () => {
        setSubmitted(true);

        if (
            scVal.DPA0130 !== null &&
            (scVal.DPA0131 !== null ||
                (scVal.DPA0130 === 5 || scVal.DPA0130 === 7
                    ? parseFloat(scVal.DPA0133) > 0 && parseFloat(scVal.DPA0134) > 0
                    : false)) &&
            (scVal.DPA0132 !== null ||
                (scVal.DPA0130 === 5 || scVal.DPA0130 === 7
                    ? parseFloat(scVal.DPA0133) > 0 && parseFloat(scVal.DPA0134) > 0
                    : false)) &&
            scVal.attachment !== null &&
            parseFloat(scVal.DPA0336) > 0 &&
            (scVal.DPA0130 === 5 || scVal.DPA0130 === 7
                ? parseFloat(scVal.DPA0133) >= 0 && parseFloat(scVal.DPA0134) >= 0
                : true)
        ) {
            if (scVal.id === undefined) {
                scVal["id"] = getID();
                setSCData((prev) => [scVal, ...prev]);
            } else {
                let loc = scdata;

                let index = loc.findIndex((k) => {
                    return k.id === scVal.id;
                });
                loc[index] = scVal;
                setSCData(loc);
            }
            console.log(scVal);
            forceUpdate();
            setSubmitted(false);
            setScDialog(false);
        }

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Stationary Combustion Emissions") {
                    let local = {};
                    let local2 = scdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_SC") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                let index = -1;
                                if (items.fuel_category !== undefined) {
                                    index = category.findIndex(
                                        (i) =>
                                            i.title.trim().toLowerCase() ===
                                            items.fuel_category.toString().trim().toLowerCase()
                                    );
                                }
                                if (
                                    items.fuel_category !== undefined &&
                                    items.fuel_category.trim().length !== 0 &&
                                    items.fuel_type !== undefined &&
                                    items.fuel_type.trim().length !== 0 &&
                                    items.unit !== undefined &&
                                    items.unit.trim().length !== 0 &&
                                    (index !== -1 &&
                                        (category[index].id === 5 || category[index].id === 7)
                                        ? items.calorific !== undefined &&
                                        parseFloat(items.calorific.toString()) > 0 &&
                                        items.carboncontent !== undefined &&
                                        parseFloat(items.carboncontent.toString()) > 0
                                        : true) &&
                                    items.quantity !== undefined &&
                                    parseFloat(items.quantity.toString()) > 0
                                ) {
                                    const { result, id } = findMatchingIds(
                                        category,
                                        items.fuel_category.toString(),
                                        items.fuel_type.toString(),
                                        items.unit.toString()
                                    );
                                    console.log(result, id, items);
                                    if (result) {
                                        local["DPA0130"] = id[0];
                                        local["DPA0131"] = id[1];
                                        local["DPA0132"] = id[2];

                                        local["id"] = getID();
                                        local["attachment"] = null;
                                        local["DPA0336"] = parseFloat(items.quantity.toString());
                                        console.log(items.fuel_category.trim() === "Solid Fuels");
                                        if (category[index].id === 5 || category[index].id === 7) {
                                            local["DPA0133"] = parseFloat(items.calorific.toString());
                                            local["DPA0134"] = parseFloat(
                                                items.carboncontent.toString()
                                            );
                                        } else {
                                            local["DPA0133"] = "";
                                            local["DPA0134"] = "";
                                        }
                                        local2.push(local);
                                    }
                                }
                            });
                        }
                    });

                    setSCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const updateScVal = (obj, val) => {
        let local = scVal;

        local[obj] = val;
        if (obj === "DPA0130") {
            local["DPA0132"] = null;
            local["DPA0131"] = null;
            let fuel_type = [];
            fuelcatlist.forEach((i) => {
                console.log(i, val);
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === val) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        } else if (obj === "DPA0131") {
            local["DPA0132"] = null;

            let units = [];
            let index = fuelcatlist.findIndex((i) => {
                return i.id === local["DPA0130"];
            });
            fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                console.log(i, val);
                if (i.newEfSubcategory3s !== undefined) {
                    if (i.id === val) {
                        units = i.newEfSubcategory3s;
                    }
                }
            });
            setUnitList(units);
            // fuelcategory.forEach((i) => {
            //     if (i.fuel === val.name && i.fuel_type === local['DPA0130'].name) {
            //         if (units.findIndex((k) => { return k.name === i.unit }) === -1) {
            //             units.push({ name: i.unit })
            //         }
            //     }

            // })
        }
        if (obj === "DPA0132") {
            local.DPA0133 = 0;
            local.DPA0134 = 0;
            local.DPA0336 = 0;
        }
        setSCVal(local);

        forceUpdate();
    };
    const editScVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        let fuel_type = [],
            units = [];
        if (item.DPA0131 !== null) {
            fuelcatlist.forEach((i) => {
                console.log(i, item.DPA0130);
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === item.DPA0130) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        }
        if (item.DPA0132 !== null) {
            let index = fuelcatlist.findIndex((i) => {
                return i.id === item.DPA0130;
            });
            if (index !== -1 && fuelcatlist[index].newEfSubcategory2s !== undefined) {
                fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                    console.log(i, item.DPA0131, item.DPA0132);
                    if (i.newEfSubcategory3s !== undefined) {
                        if (i.id === item.DPA0131) {
                            units = i.newEfSubcategory3s;
                        }
                    }
                });
            }

            setUnitList(units);
        }
        items.DPA0133 = parseFloat(items.DPA0133);
        items.DPA0134 = parseFloat(items.DPA0134);
        items.DPA0336 = parseFloat(items.DPA0336);
        setSCVal(items);
        console.log(items);
        setScDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = scdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setSCData(loc);
        forceUpdate();
    };
    const scDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveStationary();
                }}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const subcategory1template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0130;
        });
        if (index !== -1) {
            text = category[index].title;
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory2template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0130;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPA0131;
            });
            if (index2 !== -1) {
                text = category[index].newEfSubcategory2s[index2].title;
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory3template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0130;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPA0131;
            });
            if (
                index2 !== -1 &&
                category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                undefined
            ) {
                let index3 = category[index].newEfSubcategory2s[
                    index2
                ].newEfSubcategory3s.findIndex((i) => {
                    return i.id === rowData.DPA0132;
                });
                if (index3 !== -1) {
                    text =
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                            index3
                        ].title;
                }
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const actionBodyTemplateSC = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editScVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                fuel_category: "",
                                                fuel_type: "",
                                                unit: "",
                                                quantity: "",
                                                calorific: "",
                                                carboncontent: "",
                                            },
                                        ],
                                        "S1_SC",
                                        "Stationary Combustion Emissions"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Stationary Combustion Emissions");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={scdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0130.name", "DPA0131.name", "DPA0132.name"]}
                globalFilter={scfilter}
                emptyMessage="No records found."
                header={edit === 1 && scheader}
                responsiveLayout="scroll"
            >
                <Column
                    body={subcategory1template}
                    header="Fuel Category"
                    sortable
                    headerStyle={{
                        width: "25%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory2template}
                    header="Fuel Type"
                    sortable
                    headerStyle={{
                        width: "25%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory3template}
                    header="Unit"
                    sortable
                    headerStyle={{
                        width: "12%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0336"
                    header="Quantity Used"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0133"
                    header="Avg Net Calorific"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0134"
                    header="Avg Carbon Content"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateSC}> </Column>}
            </DataTable>
            <Dialog
                visible={scdialog}
                style={{
                    width: "450px",
                }}
                header="Add New"
                modal
                className="p-fluid"
                footer={scDialogFooter}
                onHide={() => {
                    setScDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="DPA0130">Select Fuel Category</label>
                    <Dropdown
                        value={scVal.DPA0130}
                        onChange={(e) => {
                            updateScVal("DPA0130", e.value);
                        }}
                        optionValue="id"
                        options={fuelcatlist}
                        optionLabel="title"
                        placeholder="Select"
                    />
                    {submitted && scVal.DPA0130 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Fuel Category is required
                        </small>
                    )}
                </div>
                {scVal.DPA0130 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0131">Select Fuel Type</label>
                        <Dropdown
                            value={scVal.DPA0131}
                            onChange={(e) => {
                                updateScVal("DPA0131", e.value);
                            }}
                            options={fuellist}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0131 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Fuel type is required
                            </small>
                        )}
                    </div>
                )}
                {scVal.DPA0131 !== null && (
                    <div className="field">
                        <label htmlFor="DPA0132">Select Unit</label>
                        <Dropdown
                            value={scVal.DPA0132}
                            onChange={(e) => {
                                updateScVal("DPA0132", e.value);
                            }}
                            options={unitlist}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPA0132 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Unit
                            </small>
                        )}
                    </div>
                )}
                {((scVal.DPA0130 !== null &&
                    scVal.DPA0131 !== null &&
                    scVal.DPA0132 !== null) ||
                    (scVal.DPA0130 !== null &&
                        (scVal.DPA0130 === 5 || scVal.DPA0130 === 7))) && (
                        <>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="gasrefill"> Quantity of fuel used </label>
                                    <div id="gasrefill" className="p-inputgroup">
                                        <InputNumber
                                            maxFractionDigits={4}
                                            useGrouping={false}
                                            value={scVal.DPA0336}
                                            onChange={(e) => {
                                                updateScVal("DPA0336", e.value);
                                            }}
                                            placeholder="Quantity"
                                        />
                                        {/* {scVal.DPA0132 !== null && <span className="p-inputgroup-addon">{scVal.DPA0132.name} </span>} */}
                                    </div>
                                    {submitted && parseFloat(scVal.DPA0336) <= 0 && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Quantity is required
                                        </small>
                                    )}
                                </div>
                            </div>
                            {(scVal.DPA0130 === 5 || scVal.DPA0130 === 7) && (
                                <div>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="DPA0133">
                                                {" "}
                                                Average Net Calorific value of the fuel used{" "}
                                            </label>
                                            <div id="DPA0133" className="p-inputgroup">
                                                <InputNumber
                                                    maxFractionDigits={4}
                                                    useGrouping={false}
                                                    value={scVal.DPA0133}
                                                    onChange={(e) => {
                                                        updateScVal("DPA0133", e.value);
                                                    }}
                                                />
                                            </div>
                                            {submitted && parseFloat(scVal.DPA0133) < 0 && (
                                                <small
                                                    className="p-invalid"
                                                    style={{
                                                        color: "red",
                                                    }}
                                                >
                                                    Calorific Value required
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="gasrefill">
                                                Average Carbon Content of the fuel used
                                            </label>
                                            <div id="gasrefill" className="p-inputgroup">
                                                <InputNumber
                                                    maxFractionDigits={4}
                                                    useGrouping={false}
                                                    value={scVal.DPA0134}
                                                    onChange={(e) => {
                                                        updateScVal("DPA0134", e.value);
                                                    }}
                                                />
                                            </div>
                                            {submitted && parseFloat(scVal.DPA0134) < 0 && (
                                                <small
                                                    className="p-invalid"
                                                    style={{
                                                        color: "red",
                                                    }}
                                                >
                                                    Carbon Content is required
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                <>
                    <FileUpload
                        mode="basic"
                        customUpload
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(scVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], scVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = scVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setSCVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(scVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && scVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {scVal.attachment !== null && (
                    <>
                        {scVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    scVal.attachment.splice(index, 1);
                                                    if (scVal.attachment.length === 0) {
                                                        scVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
// Fugitive

const Fugitive = ({ data, getData, setData, edit }) => {
    const [fujdata, setFujData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [fujdata]);
    getData(fujdata);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [selectedRef, setSelectedRef] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [refVal, setRefVal] = useState({
        DPA0137: moment(moment.utc()).toDate(),
        DPA0135: "",
        DPA0136: null,
        DPA0138: "",
        attachment: null,
    });

    const updateRefVal = (obj, val) => {
        let local = refVal;
        local[obj] = val;
        console.log(val);
        setRefVal(local);

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Fugitive Emissions") {
                    let local = {};
                    let local2 = fujdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_R") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items['Date of refilled'] !== undefined &&
                                    typeof items['Date of refilled'] === "number" &&
                                    items['AC Type'] !== undefined &&
                                    items['AC Type'].trim().length !== 0 &&
                                    items['Gas Type'] !== undefined &&
                                    items['Gas Type'].trim().length !== 0 &&
                                    items['Gas Refilled'] !== undefined &&
                                    typeof items['Gas Refilled'] === "number" &&
                                    items['Gas Refilled'] > 0
                                ) {
                                    local["DPA0135"] = items['AC Type'];
                                    local["DPA0136"] = {
                                        name: items['Gas Type'],
                                    };

                                    local["DPA0137"] = moment(excelDateToJSDate(items['Date of refilled']))._d;
                                    local["id"] = getID();
                                    local["attachment"] = null;
                                    local["DPA0138"] = items['Gas Refilled'].toString();

                                    local2.push(local);
                                }
                            });
                        }
                    });

                    setFujData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const refheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setRefVal({
                                DPA0137: moment(moment.utc()).toDate(),
                                DPA0135: "",
                                DPA0136: null,
                                DPA0138: "",
                                attachment: null,
                            });

                            setSubmitted(false);
                            setRefDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setRefFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const editRefVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPA0137 = moment.utc(item.DPA0137).toDate();
        console.log(typeof items.DPA0137, items);
        setRefVal(items);
        console.log(items);
        setRefDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = fujdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setFujData(loc);
        forceUpdate();
    };

    const saveRefrigerent = () => {
        setSubmitted(true);
        console.log(refVal);
        if (
            refVal.DPA0135.trim().length !== 0 &&
            refVal.DPA0137 !== null &&
            parseInt(refVal.DPA0138) > 0 &&
            refVal.DPA0136 !== null &&
            refVal.attachment !== null
        ) {
            if (refVal.id === undefined) {
                refVal["id"] = getID();
                setFujData((prev) => [refVal, ...prev]);
            } else {
                let loc = fujdata;
                let index = loc.findIndex((k) => {
                    return k.id === refVal.id;
                });
                loc[index] = refVal;
                setFujData(loc);
            }

            forceUpdate();
            setSubmitted(false);
            setRefDialog(false);
        }

        forceUpdate();
    };
    const refDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={saveRefrigerent}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplateRF = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editRefVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                "Date of refilled": "",
                                                "AC Type": "",
                                                "Gas Type": "",
                                                "Gas Refilled": "",
                                            },
                                        ],
                                        "S1_R",
                                        "Fugitive Emissions"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Fugitive Emissions");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={fujdata}
                scrollable
                onSelectionChange={(e) => setSelectedRef(e.value)}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={refFilter}
                emptyMessage="No records found."
                header={edit === 1 && refheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0137"
                    header="Date of refilled"
                    body={dateFormatting}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0135"
                    header="AC Type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0136.name"
                    header="Gas Type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPA0138"
                    header="Gas Refilled"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateRF}> </Column>}
            </DataTable>
            <Dialog
                visible={refDialog}
                style={{
                    width: "450px",
                }}
                header="Add Refrigerent"
                modal
                className="p-fluid"
                footer={refDialogFooter}
                onHide={() => {
                    setRefDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="refdate"> Date of Refill </label>
                    <Calendar
                        dateFormat={'dd/mm/yy'}
                        showIcon
                        value={refVal.DPA0137}
                        onChange={(e) => updateRefVal("DPA0137", e.value)}
                    ></Calendar>
                </div>
                <div className="field">
                    <label htmlFor="DPA0135">Type of AC/ Chiller / freezer used </label>
                    <InputText
                        value={refVal.DPA0135}
                        onChange={(e) => {
                            updateRefVal("DPA0135", e.target.value);
                        }}
                    />
                    {submitted && refVal.DPA0135.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            AC Type is required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="DPA0136">Type of gas refilled</label>
                    <Dropdown
                        value={refVal.DPA0136}
                        onChange={(e) => {
                            updateRefVal("DPA0136", e.value);
                        }}
                        options={gastype}
                        optionLabel="name"
                        placeholder="Select"
                    />
                    {submitted && refVal.DPA0136 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Gas Type is required
                        </small>
                    )}
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="gasrefill"> Gas Refilled </label>
                        <div id="gasrefill" className="p-inputgroup">
                            <InputText
                                keyfilter={"pnum"}
                                value={refVal.DPA0138}
                                onChange={(e) => {
                                    updateRefVal("DPA0138", e.target.value);
                                }}
                                placeholder="Gas Refilled"
                            />
                            <span className="p-inputgroup-addon">KG </span>
                        </div>
                        {submitted &&
                            (refVal.DPA0138.trim() === "" ||
                                parseInt(refVal.DPA0138) <= 0) && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Gas Refilled is required
                                </small>
                            )}
                    </div>
                </div>
                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(refVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], refVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = refVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setRefVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(refVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && refVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {refVal.attachment !== null && (
                    <>
                        {refVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    refVal.attachment.splice(index, 1);
                                                    if (refVal.attachment.length === 0) {
                                                        refVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Fugitive_ = ({ data, getData, setData, edit, standard }) => {
    const [fujdata, setFujData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [fujdata]);
    getData(fujdata);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [selectedRef, setSelectedRef] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [refVal, setRefVal] = useState({
        DPA0137: moment(moment.utc()).toDate(),
        DPA0135: "",
        DPA0136: null,
        DPA0138: 0,
        attachment: null,
    });
    const [category, setCategory] = useState([]);
    const updateRefVal = (obj, val) => {
        let local = refVal;
        local[obj] = val;
        console.log(val);
        setRefVal(local);

        forceUpdate();
    };
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard === 0 || standard === 1) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === 1
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 5);

                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Fugitive Emissions") {
                    let local = {};
                    let local2 = fujdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_R") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.actype !== undefined &&
                                    items.actype.trim().length !== 0 &&
                                    items.gastype !== undefined &&
                                    items.gastype.trim().length !== 0 &&
                                    items.gasrefilled !== undefined &&
                                    typeof items.gasrefilled === "number" &&
                                    items.gasrefilled > 0
                                ) {
                                    let index = category.findIndex(
                                        (i) =>
                                            i.title.trim().toLowerCase() ===
                                            items.gastype.toString().toLowerCase()
                                    );
                                    if (index !== -1) {
                                        local["DPA0135"] = items.actype;
                                        local["DPA0136"] = category[index].id;
                                        local["DPA0137"] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["attachment"] = null;
                                        local["DPA0138"] = items.gasrefilled;

                                        local2.push(local);
                                    }
                                }
                            });
                        }
                    });

                    setFujData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const refheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setRefVal({
                                DPA0137: moment(moment.utc()).toDate(),
                                DPA0135: "",
                                DPA0136: null,
                                DPA0138: 0,
                                attachment: null,
                            });

                            setSubmitted(false);
                            setRefDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setRefFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const editRefVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPA0137 = moment.utc(item.DPA0137).toDate();
        items.DPA0138 = parseFloat(items.DPA0138);
        console.log(typeof items.DPA0137, items);
        setRefVal(items);
        console.log(items);
        setRefDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = fujdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setFujData(loc);
        forceUpdate();
    };

    const saveRefrigerent = () => {
        setSubmitted(true);
        console.log(refVal);
        if (
            refVal.DPA0135.trim().length !== 0 &&
            refVal.DPA0137 !== null &&
            parseFloat(refVal.DPA0138) > 0 &&
            refVal.DPA0136 !== null &&
            refVal.attachment !== null
        ) {
            if (refVal.id === undefined) {
                refVal["id"] = getID();
                setFujData((prev) => [refVal, ...prev]);
            } else {
                let loc = fujdata;
                let index = loc.findIndex((k) => {
                    return k.id === refVal.id;
                });
                loc[index] = refVal;
                setFujData(loc);
            }
            console.log(refVal);
            forceUpdate();
            setSubmitted(false);
            setRefDialog(false);
        }

        forceUpdate();
    };
    const refDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={saveRefrigerent}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const subcategoryTemplate = (rowData) => {
        let index = category.findIndex((i) => {
            return i.id === rowData.DPA0136;
        });
        let text = "NA";
        if (index !== -1) {
            text = category[index].title;
        }
        return <span>{text} </span>;
    };
    const actionBodyTemplateRF = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editRefVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                date: "",
                                                actype: "",
                                                gastype: "",
                                                gasrefilled: "",
                                            },
                                        ],
                                        "S1_R",
                                        "Fugitive Emissions"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Fugitive Emissions");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={fujdata}
                scrollable
                onSelectionChange={(e) => setSelectedRef(e.value)}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={refFilter}
                emptyMessage="No records found."
                header={edit === 1 && refheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0137"
                    header="Date of refilled"
                    body={dateFormatting}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0135"
                    header="AC Type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0136.name"
                    header="Gas Type"
                    body={subcategoryTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPA0138"
                    header="Gas Refilled"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateRF}> </Column>}
            </DataTable>
            <Dialog
                visible={refDialog}
                style={{
                    width: "450px",
                }}
                header="Add Refrigerent"
                modal
                className="p-fluid"
                footer={refDialogFooter}
                onHide={() => {
                    setRefDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="refdate"> Date of Refill </label>
                    <Calendar
                        dateFormat={'dd/mm/yy'}
                        showIcon
                        value={refVal.DPA0137}
                        onChange={(e) => updateRefVal("DPA0137", e.value)}
                    ></Calendar>
                </div>
                <div className="field">
                    <label htmlFor="DPA0135">Type of AC/ Chiller / freezer used </label>
                    <InputText
                        value={refVal.DPA0135}
                        onChange={(e) => {
                            updateRefVal("DPA0135", e.target.value);
                        }}
                    />
                    {submitted && refVal.DPA0135.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            AC Type is required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="DPA0136">Type of gas refilled</label>
                    <Dropdown
                        value={refVal.DPA0136}
                        onChange={(e) => {
                            updateRefVal("DPA0136", e.value);
                        }}
                        options={category}
                        optionLabel="title"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && refVal.DPA0136 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Gas Type is required
                        </small>
                    )}
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="gasrefill"> Gas Refilled </label>
                        <div id="gasrefill" className="p-inputgroup">
                            <InputNumber
                                maxFractionDigits={4}
                                useGrouping={false}
                                value={refVal.DPA0138}
                                onChange={(e) => {
                                    updateRefVal("DPA0138", e.value);
                                }}
                                placeholder="Gas Refilled"
                            />
                            <span className="p-inputgroup-addon">KG </span>
                        </div>
                        {submitted && parseInt(refVal.DPA0138) <= 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Gas Refilled is required
                            </small>
                        )}
                    </div>
                </div>
                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(refVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], refVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = refVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setRefVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(refVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && refVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {refVal.attachment !== null && (
                    <>
                        {refVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    refVal.attachment.splice(index, 1);
                                                    if (refVal.attachment.length === 0) {
                                                        refVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
// Business Travel
const Business_Travel = ({ data, getData, setData, edit }) => {
    const [btdata, setBTData] = useState(data);
    const [btDialog, setBTDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [submitted, setSubmitted] = useState(false);
    const [btFilter, setBTFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [legData, setLegData] = useState({});
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [addLegDialog, setAddLegDialog] = useState(false);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoValue, setAutoValue] = useState(IATACodes);

    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        let listed_f = [
            { name: "Diesel" },
            { name: "Petrol" },
            { name: "Hybrid" },
            { name: "CNG" },
            { name: "LPG" },
            { name: "Plug-in Hybrid Electric Vehicle" },
            { name: "Battery Electric Vehicle" },
        ];
        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }
                let local2 = btdata;
                if (topic === "BT_Air") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Air") {
                            sname[Object.keys(sname)[0]].forEach((items, legind) => {
                                local = {};
                                let id = getID();
                                console.log(items);
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.from !== undefined &&
                                    items.from.trim().Length !== 0 &&
                                    IATACodes.findIndex((i) => {
                                        return (
                                            i.label.split("/")[2].trim().toLowerCase() ===
                                            items.from.trim().toLowerCase()
                                        );
                                    }) !== -1 &&
                                    items.to !== undefined &&
                                    items.to.trim().Length !== 0 &&
                                    IATACodes.findIndex((i) => {
                                        return (
                                            i.label.split("/")[2].trim().toLowerCase() ===
                                            items.to.trim().toLowerCase()
                                        );
                                    }) !== -1 &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.no_of_passenger.toString().trim()) >= 1 &&
                                    items.class !== undefined &&
                                    items.class.toString().trim().length !== 0 &&
                                    (items.class.toString().trim().toLowerCase() === "premium" ||
                                        items.class.toString().trim().toLowerCase() === "economy" ||
                                        items.class.toString().trim().toLowerCase() === "first")
                                ) {
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Air" };
                                    local["id"] = id;
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from.trim().toLowerCase();
                                    local["DPA0294"] = items.to.trim().toLowerCase();
                                    local["DPA0296"] = { name: items.class };
                                    local["attachment"] = null;
                                    local2.push(local);
                                    const encodedParams = new URLSearchParams();
                                    encodedParams.append(
                                        "iata_airport_from",
                                        items.from.toString().trim()
                                    );
                                    encodedParams.append(
                                        "iata_airport_to",
                                        items.to.toString().trim()
                                    );
                                    encodedParams.append(
                                        "number_of_passengers",
                                        parseInt(items.no_of_passenger)
                                    );
                                    encodedParams.append(
                                        "flight_class",
                                        items.class.toString().trim()
                                    );
                                    encodedParams.append("round_trip", "N");
                                    encodedParams.append("add_rf", "N");
                                    encodedParams.append("include_wtt", "N");
                                    const options = {
                                        method: "POST",
                                        url: "https://carbonsutra1.p.rapidapi.com/flight_estimate",
                                        headers: {
                                            "content-type": "application/x-www-form-urlencoded",
                                            "X-RapidAPI-Key":
                                                "0ed9792ad7msh5940a21cca82022p1ccd60jsn4240aef4a2e0",
                                            "X-RapidAPI-Host": "carbonsutra1.p.rapidapi.com",
                                        },
                                        data: encodedParams,
                                    };
                                    Axios.request(options)
                                        .then((res) => {
                                            if (res.status === 200) {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });

                                                if (index !== -1) {
                                                    local2[index]["DP_co2e_mt"] = res.data.data.co2e_mt;
                                                }

                                                setBTData(local2);

                                                forceUpdate();
                                            }
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                            let index = local2.findIndex((i) => {
                                                return i.id === id;
                                            });

                                            if (index !== -1) {
                                                local2.splice(index, 1);
                                                setBTData(local2);
                                                forceUpdate();
                                            }
                                        });
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Rail") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Rail") {
                            sname[Object.keys(sname)[0]].forEach((items, ind) => {
                                local = {};
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.passenger.toString().trim()) >= 1 &&
                                    items.from !== undefined &&
                                    items.from.toString().trim().length !== 0 &&
                                    items.to !== undefined &&
                                    items.to.toString().trim().length !== 0
                                ) {
                                    let from = items.from;
                                    let to = items.to;
                                    let id = getID();
                                    console.log(from, to);
                                    var directionService = new google.maps.DirectionsService();
                                    local["id"] = id;
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Rail" };
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from;
                                    local["DPA0294"] = items.to;
                                    local["attachment"] = null;
                                    local2.push(local);

                                    setBTData(local2);
                                    var request = {
                                        provideRouteAlternatives: true,
                                        waypoints: [],
                                        origin: from,
                                        destination: to,
                                        travelMode: google.maps.TravelMode.TRANSIT,
                                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                                    };

                                    directionService
                                        .route(request, (result, status) => {
                                            if (status === google.maps.DirectionsStatus.OK) {
                                                let distance =
                                                    result.routes[0].legs[0].distance.text.toString();
                                                let km =
                                                    1.60934 * parseFloat(distance.replace("mi", ""));

                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2[index]["DP_KM"] = km;

                                                    setBTData(local2);
                                                }

                                                forceUpdate();
                                            } else {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2.splice(index, 1);
                                                    setBTData(local2);
                                                    forceUpdate();
                                                }
                                            }
                                        })
                                        .catch((a) => console.log(a, "err"));
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Road") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Road") {
                            sname[Object.keys(sname)[0]].forEach((items, ind) => {
                                local = {};

                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.from !== undefined &&
                                    items.from.trim().Length !== 0 &&
                                    items.to !== undefined &&
                                    items.to.trim().Length !== 0 &&
                                    items.from !== undefined &&
                                    items.from.trim().Length !== 0 &&
                                    items.vehicle_type !== undefined &&
                                    items.vehicle_type.toString().trim().length !== 0 &&
                                    (items.vehicle_type.includes("Car") ||
                                        items.vehicle_type === "Motorbike" ||
                                        items.vehicle_type === "Taxis" ||
                                        items.vehicle_type === "Bus") &&
                                    (items.vehicle_type !== undefined &&
                                        items.vehicle_type.toString().trim().length !== 0 &&
                                        items.vehicle_type.includes("Car")
                                        ? items.vehicle_capacity !== undefined &&
                                        items.vehicle_capacity.toString().trim().length !== 0 &&
                                        bt_road_category.findIndex((i) => {
                                            return (
                                                i.subtype.trim().toLowerCase() ===
                                                items.vehicle_capacity.trim().toLowerCase()
                                            );
                                        }) !== -1 &&
                                        items.fuel_used !== undefined &&
                                        items.fuel_used.toString().trim().length !== 0 &&
                                        listed_f.findIndex((k) => {
                                            return (
                                                k.name.trim().toLowerCase() ===
                                                items.fuel_used.trim().toLowerCase()
                                            );
                                        }) !== -1
                                        : true) &&
                                    (items.vehicle_type !== undefined &&
                                        items.vehicle_type.toString().trim().length !== 0 &&
                                        items.vehicle_type === "Motorbike"
                                        ? items.vehicle_capacity !== undefined &&
                                        items.vehicle_capacity.toString().trim().length !== 0 &&
                                        bt_road_category.findIndex((i) => {
                                            return (
                                                i.subtype.trim().toLowerCase() ===
                                                items.vehicle_capacity.trim().toLowerCase()
                                            );
                                        }) !== -1
                                        : true) &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.no_of_passenger.toString().trim()) >= 1
                                ) {
                                    let from = items.from;
                                    let to = items.to;
                                    let btr = {};
                                    let id = getID();
                                    console.log(from, to);
                                    var directionService = new google.maps.DirectionsService();
                                    local["id"] = id;
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Road" };
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from;
                                    local["DPA0294"] = items.to;
                                    local["attachment"] = null;
                                    local["DPA0295"] = { name: items.vehicle_type };
                                    if (items.vehicle_type.includes("Car")) {
                                        local["DPA0337"] = { name: items.vehicle_capacity };
                                        local["DPA0338"] = { name: items.fuel_used };
                                    } else if (items.vehicle_type === "Motorbike") {
                                        local["DPA0337"] = items.vehicle_capacity;
                                        local["DPA0338"] = null;
                                    } else {
                                        local["DPA0337"] = null;
                                        local["DPA0338"] = null;
                                    }
                                    local2.push(local);
                                    var request = {
                                        provideRouteAlternatives: true,
                                        waypoints: [],
                                        origin: from,
                                        destination: to,
                                        travelMode: google.maps.TravelMode.DRIVING,
                                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                                    };

                                    directionService
                                        .route(request, (result, status) => {
                                            if (status === google.maps.DirectionsStatus.OK) {
                                                let distance =
                                                    result.routes[0].legs[0].distance.text.toString();
                                                let km =
                                                    1.60934 * parseFloat(distance.replace("mi", ""));

                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                console.log(index, local2);
                                                if (index !== -1) {
                                                    local2[index]["DP_KM"] = km;
                                                    setBTData(local2);

                                                    forceUpdate();
                                                }
                                            } else {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2.splice(index, 1);
                                                    setBTData(local2);
                                                    forceUpdate();
                                                }
                                            }
                                        })
                                        .catch((a) => {
                                            if (a.code === "NOT_FOUND") {
                                            }
                                        });
                                }
                            });
                        }
                    });
                }
                setBTData(local2);
                console.log(local2);
                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.value = null;
    };
    console.log(btdata, "testing");
    const [btVal, setBtVal] = useState({
        DPA0290: moment(moment.utc()).toDate(),
        legs: [],
        DPA0291: { name: "Air" },
        DPA0295: null,
        DPA0337: null,
        DPA0338: null,
        DPA0293: "",
        DPA0294: "",
        DPA0292: 1,
        attachment: null,
        DPA0296: null,
    });
    const [selectedAutoValue, setSelectedAutoValue] = useState({
        DPA0293: "",
        DPA0294: "",
    });
    useState(() => {
        setData();
    }, [btdata]);
    getData(btdata);
    useEffect(() => {
        let vehcat = [];
        bt_road_category.forEach((item) => {
            if (
                vehcat.findIndex((i) => {
                    return i.name === item.vehicle_type;
                }) === -1
            ) {
                vehcat.push({ name: item.vehicle_type });
            }
        });
        setVehCatList(vehcat);
    }, []);
    console.log(btdata);
    const deleteRow = (item) => {
        let loc = btdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBTData(loc);
        forceUpdate();
    };
    const bt_export = [
        {
            label: "Road",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                            vehicle_type: null,
                            vehicle_capacity: null,
                            fuel_used: null,
                        },
                    ],
                    "S3_BT_Road",
                    "BT_Road"
                );
            },
        },
        {
            label: "Rail",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                        },
                    ],
                    "S3_BT_Rail",
                    "BT_Rail"
                );
            },
        },
        {
            label: "Air",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                            class: null,
                        },
                    ],
                    "S3_BT_Air",
                    "BT_Air"
                );
            },
        },
    ];
    const bt_import = [
        {
            label: "Road",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    console.log(e.target.files[0]);
                    importFromExcel(e.target, "BT_Road");
                };
            },
        },
        {
            label: "Rail",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    importFromExcel(e.target, "BT_Rail");
                };
            },
        },
        {
            label: "Air",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    importFromExcel(e.target, "BT_Air");
                };
            },
        },
    ];
    const export2Excel = () => {
        let iata_code = [];
        IATACodes.forEach((item) => {
            iata_code.push({
                Code: item.label.split("/")[2],
                Country: item.label.split("/")[1],
                Airport: item.label.split("/")[0],
            });
        });

        const ws = XLSX.utils.json_to_sheet(iata_code);
        let sheet_name = "IATA Airline Code";
        const wb = {
            Sheets: { [sheet_name]: ws },
            SheetNames: [sheet_name],
        };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, sheet_name + ".xlsx");
    };
    const btheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setBtVal({
                                DPA0290: moment(moment.utc()).toDate(),
                                legs: [],
                                DPA0291: { name: "Air" },
                                DPA0295: null,
                                DPA0337: null,
                                DPA0338: null,
                                DPA0293: "",
                                DPA0294: "",
                                DPA0292: 1,
                                attachment: null,
                                DPA0296: null,
                            });
                            setSubmitted(false);
                            setBTDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBTFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const updateBtVal = (obj, val) => {
        let local = btVal;
        local[obj] = val;
        console.log(obj, val);
        if (
            obj === "DPA0295" &&
            (val.name === "Cars (by market segment)" ||
                val.name === "Cars (by size)" ||
                val.name === "Motorbike")
        ) {
            let sub = [];
            bt_road_category.forEach((i) => {
                if (i.vehicle_type === val.name) {
                    if (
                        sub.findIndex((j) => {
                            return j.name === i.subtype;
                        }) === -1
                    ) {
                        sub.push({ name: i.subtype });
                    }
                }
            });
            local.DPA0337 = null;
            setVehFuelList([]);
            setVehTypeList(sub);
        }
        if (obj === "DPA0337") {
            local.DPA0338 = null;

            let sub = [];
            bt_road_category.forEach((item) => {
                if (
                    item.subtype === val.name &&
                    item.vehicle_type === local.DPA0295.name
                ) {
                    sub = item.fuelcat;
                }
            });
            console.log(sub);
            setVehFuelList(sub);
        }
        if (obj === "mode") {
            setBtVal({
                DPA0290: moment(moment.utc()).toDate(),
                legs: [],
                DPA0291: { name: "Air" },
                DPA0295: null,
                DPA0337: null,
                DPA0338: null,
                DPA0293: "",
                DPA0294: "",
                DPA0292: 1,
                attachment: null,
                DPA0296: null,
            });
        } else {
            setBtVal(local);
        }

        forceUpdate();
    };
    const saveBusinessTravel = () => {
        setSubmitted(true);
        let local = btVal;
        let local2 = btdata;

        if (btVal.DPA0291 !== null) {
            if (
                btVal.DPA0291.name === "Road" &&
                parseInt(btVal.DPA0292) > 0 &&
                btVal.DPA0295 !== null &&
                btVal.attachment !== null &&
                btVal.DPA0293.trim().length !== 0 &&
                btVal.DPA0294.trim().length !== 0
            ) {
                if (
                    btVal.DPA0295.name === "Bus" ||
                    btVal.DPA0295.name === "Taxis" ||
                    (btVal.DPA0295.name === "Motorbike" &&
                        btVal.DPA0337 !== null &&
                        btVal.DPA0337.name !== undefined) ||
                    (btVal.DPA0295.name.includes("Cars") &&
                        btVal.DPA0337 !== null &&
                        btVal.DPA0338 !== null &&
                        btVal.DPA0337.name !== undefined &&
                        btVal.DPA0338.name !== undefined)
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }

                    setBtVal({
                        DPA0290: moment(moment.utc()).toDate(),
                        legs: [],
                        DPA0291: { name: "Air" },
                        DPA0295: null,
                        DPA0337: null,
                        DPA0338: null,
                        DPA0293: "",
                        DPA0294: "",
                        DPA0292: 1,
                        attachment: null,
                        DPA0296: null,
                    });
                    setBTData(local2);
                    setBTDialog(false);
                    forceUpdate();
                }
            } else if (
                btVal.DPA0291.name === "Air" &&
                parseInt(btVal.DPA0292) > 0 &&
                btVal.attachment !== null &&
                btVal.legs.length !== 0
            ) {
                let local2 = btdata;

                if (
                    btVal.legs.findIndex((i) => {
                        return i.DP_co2e_mt === undefined;
                    }) === -1
                ) {
                    btVal.legs.forEach((item) => {
                        local2.push({
                            id: getID(),
                            DPA0290: item.DPA0290,
                            legs: [item],
                            DPA0291: btVal.DPA0291,
                            DP_co2e_mt: item.DP_co2e_mt,
                            DPA0293: item.DPA0293,
                            DPA0294: item.DPA0294,
                            DPA0292: btVal.DPA0292,
                            attachment: btVal.attachment,
                        });
                    });

                    setBtVal({
                        DPA0290: moment(moment.utc()).toDate(),
                        legs: [],
                        DPA0291: { name: "Air" },
                        DPA0295: null,
                        DPA0337: null,
                        DPA0338: null,
                        DPA0293: "",
                        DPA0294: "",
                        DPA0292: 1,
                        attachment: null,
                        DPA0296: null,
                    });
                    setBTData(local2);
                    setBTDialog(false);
                    forceUpdate();
                } else {
                    alert("calculate emission factor before saving");
                }
            } else if (
                btVal.DPA0291.name === "Rail" &&
                btVal.attachment !== null &&
                btVal.DPA0293.trim().length !== 0 &&
                btVal.DPA0294.trim().length !== 0
            ) {
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }

                setBtVal({
                    DPA0290: moment(moment.utc()).toDate(),
                    legs: [],
                    DPA0291: { name: "Air" },
                    DPA0295: null,
                    DPA0337: null,
                    DPA0338: null,
                    DPA0293: "",
                    DPA0294: "",
                    DPA0292: 1,
                    attachment: null,
                    DPA0296: null,
                });
                setBTData(local2);
                setBTDialog(false);
                forceUpdate();
            }
        }
    };
    const btDialogFooter = (
        <>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setBTDialog(false);
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveBusinessTravel();
                }}
            />
        </>
    );
    const btAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplateBt = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const addLegDialogFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setAddLegDialog(false);
                    setLegData({});
                    setSelectedAutoValue({
                        DPA0293: "",
                        DPA0294: "",
                    });
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    setSubmitted(true);
                    if (
                        legData.DPA0290 !== undefined &&
                        legData.DPA0296 !== undefined &&
                        legData.DPA0293 !== undefined &&
                        legData.DPA0294 !== undefined
                    ) {
                        let local = btVal;
                        local.legs.push(legData);
                        setBtVal(local);
                        setLegData([]);
                        setSelectedAutoValue({
                            DPA0293: "",
                            DPA0294: "",
                        });
                        setSubmitted(false);
                        setAddLegDialog(false);
                    }
                }}
            />
        </>
    );
    const updateACValue = (obj, val) => {
        if (obj !== "DPA0290") {
            let value = selectedAutoValue;
            value[obj] = val;
            setSelectedAutoValue(value);
        }

        let leg = legData;
        if (obj === "DPA0290") {
            leg[obj] = val;
        } else if (obj === "DPA0296") {
            leg[obj] = val;
        } else {
            if (typeof val === "object" && val.value.includes("/")) {
                leg[obj] = val.value.split("/")[2];
            }
        }

        setLegData(leg);

        forceUpdate();
    };
    function roundValues(num) {
        var m = Number((Math.abs(num) * 100).toPrecision(6));
        return (Math.round(m) / 100) * Math.sign(num);
    }
    // RenderMap

    const renderMapRoute = () => {
        let check = 0;

        if (btVal.DPA0291.name === "Road") {
            if (btVal.DPA0295.name !== "Car" && btVal.DPA0295.name !== "Bike") {
                check = 1;
            } else if (btVal.DPA0295.name === "Car") {
                if (btVal.DPA0337.name !== "") {
                    if (btVal.DPA0338.name !== "") {
                        check = 1;
                    } else {
                        alert("Select Fuel Type");
                    }
                } else {
                    check = 0;
                    alert("Select Car Capacity");
                }
            } else if (btVal.DPA0295.name === "Bike") {
                if (btVal.DPA0337.name !== "") {
                    check = 1;
                } else {
                    check = 0;
                    alert("Select Bike Capacity");
                }
            }
        } else {
            check = 1;
        }

        if (check === 1) {
            //   if (document.getElementById('resultTabel') !== null) { document.getElementById('resultTabel').innerHTML = "" }
            let from = document.getElementById("fromplace").value.trim();
            let to = document.getElementById("toplace").value.trim();

            var directionService = new google.maps.DirectionsService();
            var directionDisplay = new google.maps.DirectionsRenderer({
                draggable: true,
            });

            let btr = btVal;
            var request = {
                provideRouteAlternatives: true,
                waypoints: [],
                origin: from,
                destination: to,
                travelMode:
                    btVal.DPA0291.name === "Road"
                        ? google.maps.TravelMode.DRIVING
                        : google.maps.TravelMode.TRANSIT,
                unitSystem: google.maps.UnitSystem.IMPERIAL,
            };

            if (from.split(",").length !== 1 && to.split(",").length !== 1) {
                directionService
                    .route(request, (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            let distance = result.routes[0].legs[0].distance.text.toString();
                            let km = 1.60934 * parseFloat(distance.replace("mi", ""));
                            btr["DP_KM"] = roundValues(km);
                            if (btVal.DPA0291.name === "Rail") {
                                btr.DPA0293 = document
                                    .getElementById("fromplace")
                                    .value.trim()
                                    .split(",")[0];
                                btr.DPA0294 = document
                                    .getElementById("toplace")
                                    .value.trim()
                                    .split(",")[0];
                            } else {
                                btr.DPA0293 = document
                                    .getElementById("fromplace")
                                    .value.trim()
                                    .split(",")[0];
                                btr.DPA0294 = document
                                    .getElementById("toplace")
                                    .value.trim()
                                    .split(",")[0];
                            }
                            setBtVal(btr);
                        } else {
                            btr.tco2e = 0;
                            btr.DPA0293 = "";
                            btr.DPA0294 = "";
                            btr["direction"] = "";
                            btr.km = 0;
                            alert("Route not found between" + from + " & " + to);
                        }
                    })
                    .catch((a) => console.log(a, "err"));
            }
        }
    };
    //   Map Dialog
    const onLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds({
            lat: 13.017092278167553,
            lng: 80.1987183651303,
        });
        map.fitBounds(bounds);
    };
    const calculateEmission = () => {
        let apiLegData = [];
        btVal.legs.forEach((item, i) => {
            console.log(item);
            apiLegData.push({
                iata_airport_from: item.DPA0293,
                iata_airport_to: item.DPA0294,
                number_of_passengers: parseInt(btVal.DPA0292),
                flight_class: item.DPA0296.name.toLowerCase(),
                round_trip: "N",
                add_rf: "N",
                include_wtt: "N",
            });
        });
        apiLegData.forEach((legs, legind) => {
            const encodedParams = new URLSearchParams();
            encodedParams.append("iata_airport_from", legs.iata_airport_from);
            encodedParams.append("iata_airport_to", legs.iata_airport_to);
            encodedParams.append("number_of_passengers", legs.number_of_passengers);
            encodedParams.append("flight_class", legs.flight_class);
            encodedParams.append("round_trip", legs.round_trip);
            encodedParams.append("add_rf", legs.add_rf);
            encodedParams.append("include_wtt", legs.include_wtt);

            const options = {
                method: "POST",
                url: "https://carbonsutra1.p.rapidapi.com/flight_estimate",
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    "X-RapidAPI-Key":
                        "0ed9792ad7msh5940a21cca82022p1ccd60jsn4240aef4a2e0",
                    "X-RapidAPI-Host": "carbonsutra1.p.rapidapi.com",
                },
                data: encodedParams,
            };
            // 'fd38be8d58msh9125eea580172f9p1dd1cbjsn67325a6ce234'
            Axios.request(options).then((res) => {
                if (res.status === 200) {
                    let local = btVal;
                    let total = 0;
                    local.legs[legind]["DP_co2e_mt"] = res.data.data.co2e_mt;

                    setBtVal(local);
                    forceUpdate();
                }
            });
        });

        forceUpdate();
    };
    const onUnmount = (map) => {
        console.log("unmount");
    };
    // Autocomplete Airport Code
    const searchAirCode = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValue([...autoValue]);
            } else {
                setAutoFilteredValue(
                    autoValue.filter((country) => {
                        return country.value
                            .toLowerCase()
                            .includes(event.query.toLowerCase());
                    })
                );
            }
        }, 250);
    };
    return (
        <div className="col-12 lg:col-12">
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-3">
                        <SplitButton
                            label="Template"
                            icon="pi pi-download"
                            model={bt_export}
                        ></SplitButton>
                    </div>
                    <div className="col-3">
                        <SplitButton
                            label="Import"
                            icon="pi pi-file-excel"
                            model={bt_import}
                        ></SplitButton>
                        {/* <FileUpload chooseOptions={{ label: 'Import', icon: 'pi pi-file-excel', className: 'navy-button' }} mode="basic" name="demo[]" auto customUpload
                                                                                                                                                                                                                                                                                                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="mr-2" uploadHandler={(e) => { importFromExcel(e, 'OwnedVehicle') }} /> */}
                    </div>
                    <div className="col-6">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    export2Excel();
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "IATA Airline Code",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                </div>
            )}
            <DataTable
                scrollable
                value={btdata}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehtype.name", "vehno"]}
                globalFilter={btFilter}
                emptyMessage="No records found."
                header={edit === 1 && btheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0290"
                    header="Date"
                    body={dateFormatting_bt}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0291.name"
                    header="Travel Mode"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0293"
                    header="From"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0294"
                    header="To"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0292"
                    header="Passenger"
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>

                <Column
                    field="attachment"
                    header="Attachment"
                    body={btAttachmentTemplate}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                {edit === 1 && <Column body={actionBodyTemplateBt}> </Column>}
            </DataTable>
            <Dialog
                visible={btDialog}
                style={{
                    width: "50%",
                }}
                header="Add Business Travel"
                modal
                className="p-fluid"
                footer={btDialogFooter}
                onHide={() => {
                    setBTDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ectype"> Mode </label>
                    <Dropdown
                        value={btVal.DPA0291}
                        onChange={(e) => {
                            updateBtVal("DPA0291", e.value);
                        }}
                        options={[{ name: "Air" }, { name: "Rail" }, { name: "Road" }]}
                        optionLabel="name"
                        placeholder="Select"
                    />
                    {submitted && btVal.DPA0291 === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select Mode
                        </small>
                    )}
                </div>
                {(btVal.DPA0291.name === "Rail" || btVal.DPA0291.name === "Road") && (
                    <div className="field">
                        <label htmlFor="ecdate"> Date </label>
                        <Calendar
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            value={btVal.DPA0290}
                            onChange={(e) => updateBtVal("DPA0290", e.value)}
                        ></Calendar>
                    </div>
                )}
                <div className="field">
                    <label htmlFor="ecpsngr"> Passenger Travelled </label>
                    <InputText
                        min={1}
                        type="number"
                        value={btVal.DPA0292}
                        onChange={(e) => {
                            updateBtVal("DPA0292", e.target.value);
                        }}
                        placeholder="Passenger Travelled"
                    />
                    {submitted &&
                        (parseInt(btVal.DPA0292) <= 0 || btVal.DPA0292 === "") && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Passenger is required.
                            </small>
                        )}
                </div>
                {btVal.DPA0291.name === "Road" && (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr"> Vehicle Type </label>
                            <Dropdown
                                value={btVal.DPA0295}
                                onChange={(e) => {
                                    updateBtVal("DPA0295", e.value);
                                }}
                                options={vehcatlist}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted && btVal.DPA0295 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Type
                                </small>
                            )}
                        </div>
                        {btVal.DPA0295 !== null &&
                            (btVal.DPA0295.name === "Cars (by market segment)" ||
                                btVal.DPA0295.name === "Cars (by size)" ||
                                btVal.DPA0295.name === "Motorbike") && (
                                <>
                                    <div className="field">
                                        <label htmlFor="ecpsngr"> Seating Capacity </label>
                                        <Dropdown
                                            value={btVal.DPA0337}
                                            onChange={(e) => {
                                                updateBtVal("DPA0337", e.value);
                                            }}
                                            options={vehtypelist}
                                            optionLabel="name"
                                            placeholder="Select"
                                        />
                                        {submitted && btVal.DPA0337 === null && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Select Capacity
                                            </small>
                                        )}
                                    </div>
                                    {btVal.DPA0337 !== null &&
                                        btVal.DPA0337.name !== null &&
                                        vehfuellist.length !== 0 && (
                                            <div className="field">
                                                <label htmlFor="ecpsngr"> Fuel </label>
                                                <Dropdown
                                                    value={btVal.DPA0338}
                                                    onChange={(e) => {
                                                        updateBtVal("DPA0338", e.value);
                                                    }}
                                                    options={vehfuellist}
                                                    optionLabel="name"
                                                    placeholder="Select"
                                                />
                                                {submitted && btVal.DPA0338 === null && (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Select Fuel Category
                                                    </small>
                                                )}
                                            </div>
                                        )}
                                </>
                            )}
                    </>
                )}
                {(btVal.DPA0291.name === "Rail" || btVal.DPA0291.name === "Road") && (
                    <>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="eckmdriven"> From </label>
                                <div id="eckmdriven" className="p-inputgroup">
                                    <Autocomplete
                                        className="autocomplete_width"
                                        onLoad={(place) => console.log("")}
                                        onPlaceChanged={() => {
                                            renderMapRoute("DPA0293");
                                        }}
                                    >
                                        <input
                                            id="fromplace"
                                            type="text"
                                            placeholder="From"
                                            style={{
                                                boxSizing: `border-box`,
                                                border: `1px solid transparent`,
                                                width: `100%`,
                                                height: `35px`,
                                                padding: `0 12px`,
                                                borderRadius: `3px`,
                                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                fontSize: `14px`,
                                                outline: `none`,
                                                textOverflow: `ellipses`,
                                            }}
                                        ></input>
                                    </Autocomplete>
                                </div>
                            </div>
                            <div className="field col">
                                <label htmlFor="ovGHG"> To </label>
                                <div id="ovGHG" className="p-inputgroup">
                                    <Autocomplete
                                        className="autocomplete_width"
                                        onLoad={(place) => console.log("")}
                                        onPlaceChanged={() => {
                                            renderMapRoute("DPA0294");
                                        }}
                                    >
                                        <input
                                            id="toplace"
                                            type="text"
                                            placeholder="To"
                                            style={{
                                                boxSizing: `border-box`,
                                                border: `1px solid transparent`,
                                                width: `100%`,
                                                height: `35px`,
                                                padding: `0 12px`,
                                                borderRadius: `3px`,
                                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                fontSize: `14px`,
                                                outline: `none`,
                                                textOverflow: `ellipses`,
                                            }}
                                        ></input>
                                    </Autocomplete>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {btVal.DPA0291.name === "Air" && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                style={{
                                    color: "green",
                                    textDecoration: "underline",
                                    marginBottom: 20,
                                }}
                                onClick={() => {
                                    setAddLegDialog(true);
                                }}
                            >
                                add Leg
                            </span>
                        </div>
                        {btVal.legs.length !== 0 && (
                            <div
                                style={{
                                    marginBottom: 20,
                                    overflow: "scroll",
                                    maxHeight: 250,
                                }}
                            >
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        width: "100%",
                                        background: "lightgrey",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            width: "40%",
                                        }}
                                    >
                                        Date
                                    </span>
                                    <span
                                        style={{
                                            width: "20%",
                                        }}
                                    >
                                        From
                                    </span>
                                    <span
                                        style={{
                                            width: "20%",
                                        }}
                                    >
                                        To
                                    </span>
                                </div>
                                {btVal.legs.map((i) => {
                                    return (
                                        <div
                                            style={{
                                                flexDirection: "row",
                                                display: "flex",
                                                width: "100%",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    width: "40%",
                                                }}
                                            >
                                                {dateFormatting_bt(i.DPA0290)}
                                            </span>
                                            <span
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                {i.DPA0293}
                                            </span>
                                            <span
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                {i.DPA0294}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {submitted && btVal.legs.length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Travel Legs required
                            </small>
                        )}
                        {btVal.legs.length !== 0 && (
                            <div>
                                <Button
                                    style={{ marginBottom: 10 }}
                                    label="Calculate Emission"
                                    className="p-button-text"
                                    onClick={(e) => {
                                        calculateEmission();
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(btVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], btVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = btVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setBtVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(btVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && btVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {btVal.attachment !== null && (
                    <>
                        {btVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    btVal.attachment.splice(index, 1);
                                                    if (btVal.attachment.length === 0) {
                                                        btVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>

            <Dialog
                visible={addLegDialog}
                style={{
                    width: "40%",
                }}
                header="Add Leg"
                modal
                footer={addLegDialogFooter}
                onHide={() => {
                    setAddLegDialog(false);
                }}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div className="field col-6">
                            <label htmlFor="ecdate"> Travel Date </label>
                            <Calendar
                                dateFormat={'dd/mm/yy'}
                                showIcon
                                style={{
                                    width: "100%",
                                }}
                                value={legData.DPA0290}
                                onChange={(e) => updateACValue("DPA0290", e.value)}
                            ></Calendar>
                            {submitted &&
                                (legData.DPA0290 === "" || legData.DPA0290 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Date
                                    </small>
                                )}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="ectype"> Class </label>
                            <Dropdown
                                style={{
                                    width: "100%",
                                }}
                                value={legData.DPA0296}
                                onChange={(e) => {
                                    updateACValue("DPA0296", e.value);
                                }}
                                options={[
                                    { name: "Economy" },
                                    { name: "Premium" },
                                    { name: "First" },
                                ]}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted &&
                                (legData.DPA0296 === "" || legData.DPA0296 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Class
                                    </small>
                                )}
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div className="field col-6">
                            <label htmlFor="aufrom"> From </label>
                            <AC
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search"
                                id="fromau"
                                dropdown
                                completeMethod={searchAirCode}
                                suggestions={autoFilteredValue}
                                onChange={(e) => updateACValue("DPA0293", e.value)}
                                value={selectedAutoValue.DPA0293}
                                field="value"
                            />
                            {submitted &&
                                (legData.DPA0293 === "" || legData.DPA0293 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select From
                                    </small>
                                )}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="auto"> To </label>
                            <AC
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search"
                                id="toau"
                                dropdown
                                completeMethod={searchAirCode}
                                suggestions={autoFilteredValue}
                                onChange={(e) => updateACValue("DPA0294", e.value)}
                                value={selectedAutoValue.DPA0294}
                                field="value"
                            />
                            {submitted &&
                                (legData.DPA0294 === "" || legData.DPA0294 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select To
                                    </small>
                                )}
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Business_Travel_ = ({ data, getData, setData, edit, standard }) => {
    const [btdata, setBTData] = useState(data);
    const [btDialog, setBTDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [submitted, setSubmitted] = useState(false);
    const [btFilter, setBTFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [unitlist, setUnitList] = useState([]);
    const [legData, setLegData] = useState({});
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [category, setCategory] = useState([]);
    const [unit_, setUnitList_] = useState([]);
    const [addLegDialog, setAddLegDialog] = useState(false);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoValue, setAutoValue] = useState(IATACodes);

    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        let listed_f = [
            { name: "Diesel" },
            { name: "Petrol" },
            { name: "Hybrid" },
            { name: "CNG" },
            { name: "LPG" },
            { name: "Plug-in Hybrid Electric Vehicle" },
            { name: "Battery Electric Vehicle" },
        ];
        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }
                let local2 = btdata;
                if (topic === "BT_Air") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Air") {
                            sname[Object.keys(sname)[0]].forEach((items, legind) => {
                                local = {};
                                let id = getID();
                                console.log(items);
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.from !== undefined &&
                                    items.from.trim().Length !== 0 &&
                                    IATACodes.findIndex((i) => {
                                        return (
                                            i.label.split("/")[2].trim().toLowerCase() ===
                                            items.from.trim().toLowerCase()
                                        );
                                    }) !== -1 &&
                                    items.to !== undefined &&
                                    items.to.trim().Length !== 0 &&
                                    IATACodes.findIndex((i) => {
                                        return (
                                            i.label.split("/")[2].trim().toLowerCase() ===
                                            items.to.trim().toLowerCase()
                                        );
                                    }) !== -1 &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.no_of_passenger.toString().trim()) >= 1 &&
                                    items.class !== undefined &&
                                    items.class.toString().trim().length !== 0 &&
                                    (items.class.toString().trim().toLowerCase() === "premium" ||
                                        items.class.toString().trim().toLowerCase() === "economy" ||
                                        items.class.toString().trim().toLowerCase() === "first")
                                ) {
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Air" };
                                    local["id"] = id;
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from.trim().toLowerCase();
                                    local["DPA0294"] = items.to.trim().toLowerCase();
                                    local["DPA0296"] = { name: items.class };
                                    local["attachment"] = null;
                                    local2.push(local);
                                    const encodedParams = new URLSearchParams();
                                    encodedParams.append(
                                        "iata_airport_from",
                                        items.from.toString().trim()
                                    );
                                    encodedParams.append(
                                        "iata_airport_to",
                                        items.to.toString().trim()
                                    );
                                    encodedParams.append(
                                        "number_of_passengers",
                                        parseInt(items.no_of_passenger)
                                    );
                                    encodedParams.append(
                                        "flight_class",
                                        items.class.toString().trim()
                                    );
                                    encodedParams.append("round_trip", "N");
                                    encodedParams.append("add_rf", "N");
                                    encodedParams.append("include_wtt", "N");
                                    const options = {
                                        method: "POST",
                                        url: "https://carbonsutra1.p.rapidapi.com/flight_estimate",
                                        headers: {
                                            "content-type": "application/x-www-form-urlencoded",
                                            "X-RapidAPI-Key":
                                                "0ed9792ad7msh5940a21cca82022p1ccd60jsn4240aef4a2e0",
                                            "X-RapidAPI-Host": "carbonsutra1.p.rapidapi.com",
                                        },
                                        data: encodedParams,
                                    };
                                    Axios.request(options)
                                        .then((res) => {
                                            if (res.status === 200) {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });

                                                if (index !== -1) {
                                                    local2[index]["DP_co2e_mt"] = res.data.data.co2e_mt;
                                                }

                                                setBTData(local2);

                                                forceUpdate();
                                            }
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                            let index = local2.findIndex((i) => {
                                                return i.id === id;
                                            });

                                            if (index !== -1) {
                                                local2.splice(index, 1);
                                                setBTData(local2);
                                                forceUpdate();
                                            }
                                        });
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Rail") {
                    let local = {};

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Rail") {
                            sname[Object.keys(sname)[0]].forEach((items, ind) => {
                                local = {};
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseInt(items.passenger.toString().trim()) >= 1 &&
                                    items.from !== undefined &&
                                    items.from.toString().trim().length !== 0 &&
                                    items.to !== undefined &&
                                    items.to.toString().trim().length !== 0
                                ) {
                                    let from = items.from;
                                    let to = items.to;
                                    let id = getID();
                                    console.log(from, to);
                                    var directionService = new google.maps.DirectionsService();
                                    local["id"] = id;
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Rail" };
                                    local["DPA0292"] = parseInt(items.no_of_passenger.toString());
                                    local["DPA0293"] = items.from;
                                    local["DPA0294"] = items.to;
                                    local["attachment"] = null;
                                    local2.push(local);

                                    setBTData(local2);
                                    var request = {
                                        provideRouteAlternatives: true,
                                        waypoints: [],
                                        origin: from,
                                        destination: to,
                                        travelMode: google.maps.TravelMode.TRANSIT,
                                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                                    };

                                    directionService
                                        .route(request, (result, status) => {
                                            if (status === google.maps.DirectionsStatus.OK) {
                                                let distance =
                                                    result.routes[0].legs[0].distance.text.toString();
                                                let km =
                                                    1.60934 * parseFloat(distance.replace("mi", ""));

                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2[index]["DP_KM"] = km;

                                                    setBTData(local2);
                                                }

                                                forceUpdate();
                                            } else {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2.splice(index, 1);
                                                    setBTData(local2);
                                                    forceUpdate();
                                                }
                                            }
                                        })
                                        .catch((a) => console.log(a, "err"));
                                }
                            });
                        }
                    });
                } else if (topic === "BT_Road") {
                    let local = {};
                    let unit_list = JSON.parse(JSON.stringify(unit_));

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_BT_Road") {
                            sname[Object.keys(sname)[0]].forEach((items, ind) => {
                                local = {};

                                if (
                                    items.date !== null &&
                                    typeof items.date === "number" &&
                                    items.from !== null &&
                                    items.from.trim().Length !== 0 &&
                                    items.to !== null &&
                                    items.to.trim().Length !== 0 &&
                                    items.vehicle_type !== null &&
                                    items.vehicle_capacity !== null &&
                                    items.fuel_used !== null &&
                                    items.unit !== null &&
                                    items.no_of_passenger !== undefined &&
                                    items.no_of_passenger.toString().trim().length !== 0 &&
                                    parseFloat(items.no_of_passenger.toString().trim()) >= 1
                                ) {
                                    const { result, id_ } = findMatchingIds(
                                        category,
                                        items.vehicle_type.toString(),
                                        items.vehicle_capacity.toString(),
                                        items.fuel_used.toString(),
                                        items.unit.toString()
                                    );
                                    if (result) {
                                    }
                                    let from = items.from;
                                    let to = items.to;
                                    let btr = {};
                                    let id = getID();
                                    console.log(from, to);
                                    var directionService = new google.maps.DirectionsService();
                                    local["id"] = id;
                                    local["DPA0290"] = moment(excelDateToJSDate(items.date))._d;
                                    local["DPA0291"] = { name: "Road" };
                                    local["DPA0292"] = parseFloat(
                                        items.no_of_passenger.toString()
                                    );
                                    local["DPA0293"] = items.from;
                                    local["DPA0294"] = items.to;
                                    local["attachment"] = null;
                                    local["DPA0295"] = id_[0];
                                    local["DPA0337"] = id_[1];
                                    local["DPA0338"] = id_[2];
                                    local["DPA0338G"] = id_[3];

                                    local2.push(local);
                                    var request = {
                                        provideRouteAlternatives: true,
                                        waypoints: [],
                                        origin: from,
                                        destination: to,
                                        travelMode: google.maps.TravelMode.DRIVING,
                                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                                    };

                                    directionService
                                        .route(request, (result, status) => {
                                            if (status === google.maps.DirectionsStatus.OK) {
                                                let distance =
                                                    result.routes[0].legs[0].distance.text.toString();
                                                let km =
                                                    1.60934 * parseFloat(distance.replace("mi", ""));

                                                let uindex = unit_list.findIndex((k) => {
                                                    return (
                                                        k.id === local["DPA0338G"] &&
                                                        k.newEfSubcategory3Id === local["DPA0338"]
                                                    );
                                                });

                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });

                                                if (index !== -1 && uindex !== -1) {
                                                    if (
                                                        unit_list[uindex].title.trim().toLowerCase() ===
                                                        "km" ||
                                                        unit_list[uindex].title.trim().toLowerCase() ===
                                                        ".km"
                                                    ) {
                                                        local2[index]["DP_KM"] = km;
                                                    } else {
                                                        local2[index]["DP_KM"] = parseFloat(
                                                            distance.replace("mi", "")
                                                        );
                                                    }

                                                    setBTData(local2);

                                                    forceUpdate();
                                                }
                                            } else {
                                                let index = local2.findIndex((i) => {
                                                    return i.id === id;
                                                });
                                                if (index !== -1) {
                                                    local2.splice(index, 1);
                                                    setBTData(local2);
                                                    forceUpdate();
                                                }
                                            }
                                        })
                                        .catch((a) => {
                                            if (a.code === "NOT_FOUND") {
                                            }
                                        });
                                }
                            });
                        }
                    });
                }
                setBTData(local2);
                console.log(local2);
                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.value = null;
    };
    console.log(btdata, "testing");
    const [btVal, setBtVal] = useState({
        DPA0290: moment(moment.utc()).toDate(),
        legs: [],
        DPA0291: { name: "Air" },
        DPA0295: null,
        DPA0337: null,
        DPA0338: null,
        DPA0338G: null,
        DPA0293: "",
        DPA0294: "",
        DPA0292: 1,
        attachment: null,
        DPA0296: null,
    });
    const [selectedAutoValue, setSelectedAutoValue] = useState({
        DPA0293: "",
        DPA0294: "",
    });
    useEffect(() => {
        let veh_cat = [];
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(API.EF_SC4).then((res) => {
            setUnitList_(res.data);
        });
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard === 0 || standard === 1) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === 1
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 6);
                        let cat_index2 = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 7);
                        console.log(cat_index, cat_index2);
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    useState(() => {
        setData();
    }, [btdata]);
    getData(btdata);

    console.log(btdata);
    const deleteRow = (item) => {
        let loc = btdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBTData(loc);
        forceUpdate();
    };

    const bt_export = [
        {
            label: "Road",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                            vehicle_type: null,
                            vehicle_capacity: null,
                            fuel_used: null,
                            unit: null,
                        },
                    ],
                    "S3_BT_Road",
                    "BT_Road"
                );
            },
        },
        {
            label: "Rail",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                        },
                    ],
                    "S3_BT_Rail",
                    "BT_Rail"
                );
            },
        },
        {
            label: "Air",

            command: (e) => {
                exportToCSV(
                    [
                        {
                            sno: null,
                            date: "",
                            from: null,
                            to: null,
                            no_of_passenger: null,
                            class: null,
                        },
                    ],
                    "S3_BT_Air",
                    "BT_Air"
                );
            },
        },
    ];
    const bt_import = [
        {
            label: "Road",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    console.log(e.target.files[0]);
                    importFromExcel(e.target, "BT_Road");
                };
            },
        },
        {
            label: "Rail",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    importFromExcel(e.target, "BT_Rail");
                };
            },
        },
        {
            label: "Air",

            command: (e) => {
                var input = document.createElement("input");
                input.type = "file";
                input.click();
                input.onchange = (e) => {
                    importFromExcel(e.target, "BT_Air");
                };
            },
        },
    ];
    const export2Excel = () => {
        let iata_code = [];
        IATACodes.forEach((item) => {
            iata_code.push({
                Code: item.label.split("/")[2],
                Country: item.label.split("/")[1],
                Airport: item.label.split("/")[0],
            });
        });

        const ws = XLSX.utils.json_to_sheet(iata_code);
        let sheet_name = "IATA Airline Code";
        const wb = {
            Sheets: { [sheet_name]: ws },
            SheetNames: [sheet_name],
        };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, sheet_name + ".xlsx");
    };
    const btheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setBtVal({
                                DPA0290: moment(moment.utc()).toDate(),
                                legs: [],
                                DPA0291: { name: "Air" },
                                DPA0295: null,
                                DPA0337: null,
                                DPA0338: null,
                                DPA0338G: null,
                                DPA0293: "",
                                DPA0294: "",
                                DPA0292: 1,
                                attachment: null,
                                DPA0296: null,
                            });
                            setSubmitted(false);
                            setBTDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBTFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const updateBtVal = (obj, val) => {
        let local = btVal;
        local[obj] = val;
        console.log(obj, val);

        if (obj === "DPA0295") {
            let vehtype = [];
            category.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory2s !== undefined) {
                        vehtype = item.newEfSubcategory2s;
                    }
                }
            });
            setVehTypeList(vehtype);
            local.DPA0337 = null;
            local.DPA0338 = null;
            local.DPA0338G = null;
        }
        if (obj === "DPA0337") {
            let fueltype = [];
            vehtypelist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory3s !== undefined) {
                        fueltype = item.newEfSubcategory3s;
                    }
                }
            });
            console.log(fueltype);

            setVehFuelList(fueltype);

            local.DPA0338 = null;
            local.DPA0338G = null;
        }
        if (obj === "DPA0338") {
            let units = [];
            vehfuellist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory4s !== undefined) {
                        units = item.newEfSubcategory4s;
                    }
                }
            });
            setUnitList(units);
            local.DPA0338G = null;
        }
        if (obj === "DPA0338G") {
            local.DPA0293 = "";
            local.DPA0294 = "";
            setTimeout(() => {
                document.getElementById("fromplace").value = "";
                document.getElementById("toplace").value = "";
            }, 1000);
        }

        if (obj === "mode") {
            setBtVal({
                DPA0290: moment(moment.utc()).toDate(),
                legs: [],
                DPA0291: { name: "Air" },
                DPA0295: null,
                DPA0337: null,
                DPA0338: null,
                DPA0338G: null,
                DPA0293: "",
                DPA0294: "",
                DPA0292: 1,

                attachment: null,
                DPA0296: null,
            });
        } else {
            setBtVal(local);
        }

        forceUpdate();
    };
    const saveBusinessTravel = () => {
        setSubmitted(true);
        let local = btVal;
        let local2 = btdata;

        if (btVal.DPA0291 !== null) {
            if (
                btVal.DPA0291.name === "Road" &&
                btVal.DP_KM !== undefined &&
                parseFloat(btVal.DP_KM) > 0 &&
                parseInt(btVal.DPA0292) > 0 &&
                btVal.DPA0295 !== null &&
                btVal.DPA0337 !== null &&
                btVal.DPA0338 !== null &&
                btVal.DPA0338G !== null &&
                btVal.attachment !== null &&
                btVal.DPA0293.trim().length !== 0 &&
                btVal.DPA0294.trim().length !== 0
            ) {
                if (true) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }

                    setBtVal({
                        DPA0290: moment(moment.utc()).toDate(),
                        legs: [],
                        DPA0291: { name: "Air" },
                        DPA0295: null,
                        DPA0337: null,
                        DPA0338: null,
                        DPA0338G: null,
                        DPA0293: "",
                        DPA0294: "",
                        DPA0292: 1,
                        attachment: null,
                        DPA0296: null,
                    });
                    setBTData(local2);
                    setBTDialog(false);
                    forceUpdate();
                }
            } else if (
                btVal.DPA0291.name === "Air" &&
                parseInt(btVal.DPA0292) > 0 &&
                btVal.attachment !== null &&
                btVal.legs.length !== 0
            ) {
                let local2 = btdata;

                if (
                    btVal.legs.findIndex((i) => {
                        return i.DP_co2e_mt === undefined;
                    }) === -1
                ) {
                    btVal.legs.forEach((item) => {
                        local2.push({
                            id: getID(),
                            DPA0290: item.DPA0290,
                            legs: [item],
                            DPA0291: btVal.DPA0291,
                            DP_co2e_mt: item.DP_co2e_mt,
                            DPA0293: item.DPA0293,
                            DPA0294: item.DPA0294,
                            DPA0292: btVal.DPA0292,
                            attachment: btVal.attachment,
                        });
                    });

                    setBtVal({
                        DPA0290: moment(moment.utc()).toDate(),
                        legs: [],
                        DPA0291: { name: "Air" },
                        DPA0295: null,
                        DPA0337: null,
                        DPA0338: null,
                        DPA0293: "",
                        DPA0294: "",
                        DPA0292: 1,
                        attachment: null,
                        DPA0296: null,
                    });
                    setBTData(local2);
                    setBTDialog(false);
                    forceUpdate();
                } else {
                    alert("calculate emission factor before saving");
                }
            } else if (
                btVal.DPA0291.name === "Rail" &&
                btVal.DP_KM !== undefined &&
                parseFloat(btVal.DP_KM) > 0 &&
                btVal.attachment !== null &&
                btVal.DPA0293.trim().length !== 0 &&
                btVal.DPA0294.trim().length !== 0
            ) {
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }

                setBtVal({
                    DPA0290: moment(moment.utc()).toDate(),
                    legs: [],
                    DPA0291: { name: "Air" },
                    DPA0295: null,
                    DPA0337: null,
                    DPA0338: null,
                    DPA0293: "",
                    DPA0294: "",
                    DPA0292: 1,
                    attachment: null,
                    DPA0296: null,
                });
                setBTData(local2);
                setBTDialog(false);
                forceUpdate();
            }
        }
    };
    const btDialogFooter = (
        <>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setBTDialog(false);
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveBusinessTravel();
                }}
            />
        </>
    );
    const btAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplateBt = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const addLegDialogFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setAddLegDialog(false);
                    setLegData({});
                    setSelectedAutoValue({
                        DPA0293: "",
                        DPA0294: "",
                    });
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    setSubmitted(true);
                    if (
                        legData.DPA0290 !== undefined &&
                        legData.DPA0296 !== undefined &&
                        legData.DPA0293 !== undefined &&
                        legData.DPA0294 !== undefined
                    ) {
                        let local = btVal;
                        local.legs.push(legData);
                        setBtVal(local);
                        setLegData([]);
                        setSelectedAutoValue({
                            DPA0293: "",
                            DPA0294: "",
                        });
                        setSubmitted(false);
                        setAddLegDialog(false);
                    }
                }}
            />
        </>
    );
    const updateACValue = (obj, val) => {
        if (obj !== "DPA0290") {
            let value = selectedAutoValue;
            value[obj] = val;
            setSelectedAutoValue(value);
        }

        let leg = legData;
        if (obj === "DPA0290") {
            leg[obj] = val;
        } else if (obj === "DPA0296") {
            leg[obj] = val;
        } else {
            if (typeof val === "object" && val.value.includes("/")) {
                leg[obj] = val.value.split("/")[2];
            }
        }

        setLegData(leg);

        forceUpdate();
    };
    function roundValues(num) {
        var m = Number((Math.abs(num) * 100).toPrecision(6));
        return (Math.round(m) / 100) * Math.sign(num);
    }
    // RenderMap

    const renderMapRoute = () => {
        let check = 0;

        if (btVal.DPA0291.name === "Road") {
            if (
                btVal.DPA0295 !== null &&
                btVal.DPA0337 !== null &&
                btVal.DPA0338 !== null &&
                btVal.DPA0338G !== null
            ) {
                check = 1;
            }
        } else {
            check = 1;
        }

        if (check === 1) {
            //   if (document.getElementById('resultTabel') !== null) { document.getElementById('resultTabel').innerHTML = "" }
            let from = document.getElementById("fromplace").value.trim();
            let to = document.getElementById("toplace").value.trim();

            var directionService = new google.maps.DirectionsService();
            var directionDisplay = new google.maps.DirectionsRenderer({
                draggable: true,
            });

            let btr = btVal;
            var request = {
                provideRouteAlternatives: true,
                waypoints: [],
                origin: from,
                destination: to,
                travelMode:
                    btVal.DPA0291.name === "Road"
                        ? google.maps.TravelMode.DRIVING
                        : google.maps.TravelMode.TRANSIT,
                unitSystem: google.maps.UnitSystem.IMPERIAL,
            };

            if (from.split(",").length !== 1 && to.split(",").length !== 1) {
                directionService
                    .route(request, (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            let distance = result.routes[0].legs[0].distance.text.toString();

                            if (btVal.DPA0291.name === "Rail") {
                                let km = 1.60934 * parseFloat(distance.replace("mi", ""));
                                btr["DP_KM"] = roundValues(km);
                                btr.DPA0293 = document
                                    .getElementById("fromplace")
                                    .value.trim()
                                    .split(",")[0];
                                btr.DPA0294 = document
                                    .getElementById("toplace")
                                    .value.trim()
                                    .split(",")[0];
                            } else {
                                let unit_ = unitlist.find((i) => i.id === btVal.DPA0338G).title;

                                if (unit_.trim().toLowerCase() === "km") {
                                    let km = 1.60934 * parseFloat(distance.replace("mi", ""));
                                    console.log(km);
                                    btr["DP_KM"] = roundValues(km);
                                } else {
                                    let km = parseFloat(distance.replace("mi", ""));
                                    btr["DP_KM"] = roundValues(km);
                                }

                                btr.DPA0293 = document
                                    .getElementById("fromplace")
                                    .value.trim()
                                    .split(",")[0];
                                btr.DPA0294 = document
                                    .getElementById("toplace")
                                    .value.trim()
                                    .split(",")[0];
                            }
                            setBtVal(btr);
                        } else {
                            btr.tco2e = 0;
                            btr.DPA0293 = "";
                            btr.DPA0294 = "";
                            btr["direction"] = "";
                            btr.km = 0;
                            alert("Route not found between" + from + " & " + to);
                        }
                    })
                    .catch((a) => console.log(a, "err"));
            }
        }
    };
    //   Map Dialog
    const onLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds({
            lat: 13.017092278167553,
            lng: 80.1987183651303,
        });
        map.fitBounds(bounds);
    };
    const calculateEmission = () => {
        let apiLegData = [];
        btVal.legs.forEach((item, i) => {
            console.log(item);
            apiLegData.push({
                iata_airport_from: item.DPA0293,
                iata_airport_to: item.DPA0294,
                number_of_passengers: parseInt(btVal.DPA0292),
                flight_class: item.DPA0296.name.toLowerCase(),
                round_trip: "N",
                add_rf: "N",
                include_wtt: "N",
            });
        });
        apiLegData.forEach((legs, legind) => {
            const encodedParams = new URLSearchParams();
            encodedParams.append("iata_airport_from", legs.iata_airport_from);
            encodedParams.append("iata_airport_to", legs.iata_airport_to);
            encodedParams.append("number_of_passengers", legs.number_of_passengers);
            encodedParams.append("flight_class", legs.flight_class);
            encodedParams.append("round_trip", legs.round_trip);
            encodedParams.append("add_rf", legs.add_rf);
            encodedParams.append("include_wtt", legs.include_wtt);

            const options = {
                method: "POST",
                url: "https://carbonsutra1.p.rapidapi.com/flight_estimate",
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    "X-RapidAPI-Key":
                        "0ed9792ad7msh5940a21cca82022p1ccd60jsn4240aef4a2e0",
                    "X-RapidAPI-Host": "carbonsutra1.p.rapidapi.com",
                },
                data: encodedParams,
            };
            // 'fd38be8d58msh9125eea580172f9p1dd1cbjsn67325a6ce234'
            Axios.request(options).then((res) => {
                if (res.status === 200) {
                    let local = btVal;
                    let total = 0;
                    local.legs[legind]["DP_co2e_mt"] = res.data.data.co2e_mt;

                    setBtVal(local);
                    forceUpdate();
                }
            });
        });

        forceUpdate();
    };
    const onUnmount = (map) => {
        console.log("unmount");
    };
    // Autocomplete Airport Code
    const searchAirCode = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValue([...autoValue]);
            } else {
                setAutoFilteredValue(
                    autoValue.filter((country) => {
                        return country.value
                            .toLowerCase()
                            .includes(event.query.toLowerCase());
                    })
                );
            }
        }, 250);
    };
    return (
        <div className="col-12 lg:col-12">
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-3">
                        <SplitButton
                            label="Template"
                            icon="pi pi-download"
                            model={bt_export}
                        ></SplitButton>
                    </div>
                    <div className="col-3">
                        <SplitButton
                            label="Import"
                            icon="pi pi-file-excel"
                            model={bt_import}
                        ></SplitButton>
                        {/* <FileUpload chooseOptions={{ label: 'Import', icon: 'pi pi-file-excel', className: 'navy-button' }} mode="basic" name="demo[]" auto customUpload
                                                                                                                                                                                                                                                                                                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="mr-2" uploadHandler={(e) => { importFromExcel(e, 'OwnedVehicle') }} /> */}
                    </div>
                    <div className="col-6">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    export2Excel();
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "IATA Airline Code",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                </div>
            )}
            <DataTable
                scrollable
                value={btdata}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehtype.name", "vehno"]}
                globalFilter={btFilter}
                emptyMessage="No records found."
                header={edit === 1 && btheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0290"
                    header="Date"
                    body={dateFormatting_bt}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0291.name"
                    header="Travel Mode"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0293"
                    header="From"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0294"
                    header="To"
                    sortable
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                <Column
                    field="DPA0292"
                    header="Passenger"
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>

                <Column
                    field="attachment"
                    header="Attachment"
                    body={btAttachmentTemplate}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                {edit === 1 && <Column body={actionBodyTemplateBt}> </Column>}
            </DataTable>
            <Dialog
                visible={btDialog}
                style={{
                    width: "50%",
                }}
                header="Add Business Travel"
                modal
                className="p-fluid"
                footer={btDialogFooter}
                onHide={() => {
                    setBTDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ectype"> Mode </label>
                    <Dropdown
                        value={btVal.DPA0291}
                        onChange={(e) => {
                            updateBtVal("DPA0291", e.value);
                        }}
                        options={[{ name: "Air" }, { name: "Rail" }, { name: "Road" }]}
                        optionLabel="name"
                        placeholder="Select"
                    />
                    {submitted && btVal.DPA0291 === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select Mode
                        </small>
                    )}
                </div>
                {(btVal.DPA0291.name === "Rail" || btVal.DPA0291.name === "Road") && (
                    <div className="field">
                        <label htmlFor="ecdate"> Date </label>
                        <Calendar
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            value={btVal.DPA0290}
                            onChange={(e) => updateBtVal("DPA0290", e.value)}
                        ></Calendar>
                    </div>
                )}
                <div className="field">
                    <label htmlFor="ecpsngr"> Passenger Travelled </label>
                    <InputNumber
                        min={1}
                        value={btVal.DPA0292}
                        onChange={(e) => {
                            updateBtVal("DPA0292", e.value);
                        }}
                        placeholder="Passenger Travelled"
                    />
                    {submitted && parseInt(btVal.DPA0292) <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Passenger is required.
                        </small>
                    )}
                </div>
                {btVal.DPA0291.name === "Road" && (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr"> Vehicle Type </label>
                            <Dropdown
                                value={btVal.DPA0295}
                                onChange={(e) => {
                                    updateBtVal("DPA0295", e.value);
                                }}
                                options={category}
                                optionValue="id"
                                optionLabel="title"
                                placeholder="Select"
                            />
                            {submitted && btVal.DPA0295 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Type
                                </small>
                            )}
                        </div>
                        {btVal.DPA0295 !== null && (
                            <>
                                <div className="field">
                                    <label htmlFor="ecpsngr"> Seating Capacity </label>
                                    <Dropdown
                                        value={btVal.DPA0337}
                                        onChange={(e) => {
                                            updateBtVal("DPA0337", e.value);
                                        }}
                                        options={vehtypelist}
                                        optionValue="id"
                                        optionLabel="title"
                                        placeholder="Select"
                                    />
                                    {submitted && btVal.DPA0337 === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Select Capacity
                                        </small>
                                    )}
                                </div>
                                {btVal.DPA0337 !== null && (
                                    <div className="field">
                                        <label htmlFor="ecpsngr"> Fuel </label>
                                        <Dropdown
                                            value={btVal.DPA0338}
                                            onChange={(e) => {
                                                updateBtVal("DPA0338", e.value);
                                            }}
                                            options={vehfuellist}
                                            optionValue="id"
                                            optionLabel="title"
                                            placeholder="Select"
                                        />
                                        {submitted && btVal.DPA0338 === null && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Select Fuel Category
                                            </small>
                                        )}
                                    </div>
                                )}
                                {btVal.DPA0338 !== null && (
                                    <div className="field">
                                        <label htmlFor="ecpsngr"> Unit of Measurement </label>
                                        <Dropdown
                                            value={btVal.DPA0338G}
                                            onChange={(e) => {
                                                updateBtVal("DPA0338G", e.value);
                                            }}
                                            options={unitlist}
                                            optionValue="id"
                                            optionLabel="title"
                                            placeholder="Select"
                                        />
                                        {submitted && btVal.DPA0338G === null && (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Select unit
                                            </small>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
                {(btVal.DPA0291.name === "Rail" ||
                    (btVal.DPA0291.name === "Road" &&
                        btVal.DPA0338 !== null &&
                        btVal.DPA0338G !== null)) && (
                        <>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="eckmdriven"> From </label>
                                    <div id="eckmdriven" className="p-inputgroup">
                                        <Autocomplete
                                            className="autocomplete_width"
                                            onLoad={(place) => console.log("")}
                                            onPlaceChanged={() => {
                                                renderMapRoute("DPA0293");
                                            }}
                                        >
                                            <input
                                                id="fromplace"
                                                type="text"
                                                placeholder="From"
                                                style={{
                                                    boxSizing: `border-box`,
                                                    border: `1px solid transparent`,
                                                    width: `100%`,
                                                    height: `35px`,
                                                    padding: `0 12px`,
                                                    borderRadius: `3px`,
                                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    fontSize: `14px`,
                                                    outline: `none`,
                                                    textOverflow: `ellipses`,
                                                }}
                                            ></input>
                                        </Autocomplete>
                                    </div>
                                </div>
                                <div className="field col">
                                    <label htmlFor="ovGHG"> To </label>
                                    <div id="ovGHG" className="p-inputgroup">
                                        <Autocomplete
                                            className="autocomplete_width"
                                            onLoad={(place) => console.log("")}
                                            onPlaceChanged={() => {
                                                renderMapRoute("DPA0294");
                                            }}
                                        >
                                            <input
                                                id="toplace"
                                                type="text"
                                                placeholder="To"
                                                style={{
                                                    boxSizing: `border-box`,
                                                    border: `1px solid transparent`,
                                                    width: `100%`,
                                                    height: `35px`,
                                                    padding: `0 12px`,
                                                    borderRadius: `3px`,
                                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    fontSize: `14px`,
                                                    outline: `none`,
                                                    textOverflow: `ellipses`,
                                                }}
                                            ></input>
                                        </Autocomplete>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                {btVal.DPA0291.name === "Air" && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                style={{
                                    color: "green",
                                    textDecoration: "underline",
                                    marginBottom: 20,
                                }}
                                onClick={() => {
                                    setAddLegDialog(true);
                                }}
                            >
                                add Leg
                            </span>
                        </div>
                        {btVal.legs.length !== 0 && (
                            <div
                                style={{
                                    marginBottom: 20,
                                    overflow: "scroll",
                                    maxHeight: 250,
                                }}
                            >
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        width: "100%",
                                        background: "lightgrey",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            width: "40%",
                                        }}
                                    >
                                        Date
                                    </span>
                                    <span
                                        style={{
                                            width: "20%",
                                        }}
                                    >
                                        From
                                    </span>
                                    <span
                                        style={{
                                            width: "20%",
                                        }}
                                    >
                                        To
                                    </span>
                                </div>
                                {btVal.legs.map((i) => {
                                    return (
                                        <div
                                            style={{
                                                flexDirection: "row",
                                                display: "flex",
                                                width: "100%",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    width: "40%",
                                                }}
                                            >
                                                {dateFormatting_bt(i.DPA0290)}
                                            </span>
                                            <span
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                {i.DPA0293}
                                            </span>
                                            <span
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                {i.DPA0294}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {submitted && btVal.legs.length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Travel Legs required
                            </small>
                        )}
                        {btVal.legs.length !== 0 && (
                            <div>
                                <Button
                                    style={{ marginBottom: 10 }}
                                    label="Calculate Emission"
                                    className="p-button-text"
                                    onClick={(e) => {
                                        calculateEmission();
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        className="mt-5"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(btVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], btVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = btVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setBtVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(btVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && btVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {btVal.attachment !== null && (
                    <>
                        {btVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    btVal.attachment.splice(index, 1);
                                                    if (btVal.attachment.length === 0) {
                                                        btVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>

            <Dialog
                visible={addLegDialog}
                style={{
                    width: "40%",
                }}
                header="Add Leg"
                modal
                footer={addLegDialogFooter}
                onHide={() => {
                    setAddLegDialog(false);
                }}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div className="field col-6">
                            <label htmlFor="ecdate"> Travel Date </label>
                            <Calendar
                                dateFormat={'dd/mm/yy'}
                                showIcon
                                style={{
                                    width: "100%",
                                }}
                                value={legData.DPA0290}
                                onChange={(e) => updateACValue("DPA0290", e.value)}
                            ></Calendar>
                            {submitted &&
                                (legData.DPA0290 === "" || legData.DPA0290 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Date
                                    </small>
                                )}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="ectype"> Class </label>
                            <Dropdown
                                style={{
                                    width: "100%",
                                }}
                                value={legData.DPA0296}
                                onChange={(e) => {
                                    updateACValue("DPA0296", e.value);
                                }}
                                options={[
                                    { name: "Economy" },
                                    { name: "Premium" },
                                    { name: "First" },
                                ]}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted &&
                                (legData.DPA0296 === "" || legData.DPA0296 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Class
                                    </small>
                                )}
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div className="field col-6">
                            <label htmlFor="aufrom"> From </label>
                            <AC
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search"
                                id="fromau"
                                dropdown
                                completeMethod={searchAirCode}
                                suggestions={autoFilteredValue}
                                onChange={(e) => updateACValue("DPA0293", e.value)}
                                value={selectedAutoValue.DPA0293}
                                field="value"
                            />
                            {submitted &&
                                (legData.DPA0293 === "" || legData.DPA0293 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select From
                                    </small>
                                )}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="auto"> To </label>
                            <AC
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search"
                                id="toau"
                                dropdown
                                completeMethod={searchAirCode}
                                suggestions={autoFilteredValue}
                                onChange={(e) => updateACValue("DPA0294", e.value)}
                                value={selectedAutoValue.DPA0294}
                                field="value"
                            />
                            {submitted &&
                                (legData.DPA0294 === "" || legData.DPA0294 === undefined) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select To
                                    </small>
                                )}
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
// Mobile Combustion
const Mobile_Combustion = ({ data, getData, setData, edit }) => {
    const [mcdata, setMCData] = useState(data);
    const [mcDialog, setMCDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [attachment, setAttachment] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [mcFilter, setMCFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [mcVal, setMcVal] = useState({
        DPA0290: moment(moment.utc()).toDate(),
        DP_MODE: true,
        DPA0341: "",
        DPA0140: null,
        DPA0139: null,
        DPA0339: null,
        DPA0141: null,
        DPA0340: null,
        DPA0144: "",
        DPA0143: "",
        DPA0142: null,
        attachment: null,
    });

    useEffect(() => {
        let veh_cat = [];
        mobile_combustion.forEach((i) => {
            if (
                veh_cat.findIndex((j) => {
                    return j.name === i.vehicle_category;
                }) === -1
            ) {
                veh_cat.push({ name: i.vehicle_category });
            }
        });
        setVehCatList(veh_cat);
    }, []);
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Mobile Combustion Emissions") {
                    let local = {};
                    let local2 = mcdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_OV") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                if (
                                    items['Data Captured By'] !== null &&
                                    items['Vehicle No'] !== null && items['Fuel Type'] !== null &&
                                    items['Vehicle No'].toString().trim().length !== 0 &&
                                    items['Data Captured By'].toString().trim().length !== 0 &&
                                    (items['Data Captured By'].toString().toLowerCase() === "fuel" ||
                                        items['Data Captured By'].toString().toLowerCase() === "distance")
                                ) {
                                    if (items['Data Captured By'].toString().toLowerCase() === "fuel") {
                                        let ft = items['Fuel Type'].toString().trim().toLowerCase()
                                        console.log(ft)
                                        if (items['Fuel Refill Date'] !== null && typeof items['Fuel Refill Date'] === 'number' && (ft === 'diesel' || ft === 'petrol' || ft === 'cng')) {
                                            local["attachment"] = null;
                                            local["DP_MODE"] = false;
                                            local["DPA0341"] = items['Vehicle No'];
                                            local["DPA0140"] = null
                                            local["DPA0139"] = null
                                            local["DPA0141"] = { name: ft === 'diesel' ? 'Diesel' : ft === 'petrol' ? 'Petrol' : 'CNG' };
                                            local["DPA0142"] = moment(
                                                excelDateToJSDate(items['Fuel Refill Date'])
                                            )._d;
                                            local["DPA0143"] = items['Fuel Refilled'].toString();
                                            local["DPA0340"] = null
                                            local["id"] = getID();
                                            local2.push(local);
                                        }
                                    } else {
                                        let categoryind = mobile_combustion.findIndex((i) => {
                                            return (
                                                i.vehicle_category.trim().toLowerCase() ===
                                                items['Vehicle Category'].trim().toString().toLowerCase()
                                            );
                                        });
                                        console.log(categoryind)
                                        if (
                                            items['Vehicle Category'] !== null &&
                                            items['Vehicle Category'].toString().trim().length !== 0 &&
                                            categoryind !== -1
                                        ) {
                                            let typeind = mobile_combustion.findIndex((i) => {
                                                return (
                                                    i.vehicle_category === items['Vehicle Category'] &&
                                                    i.vehicle_type.trim().toLowerCase() ===
                                                    items['Vehicle Type'].trim().toString().toLowerCase()
                                                );
                                            });
                                            console.log(typeind)
                                            if (
                                                items['Vehicle Type'] !== null &&
                                                items['Vehicle Type'].toString().trim().length !== 0 &&
                                                typeind !== -1
                                            ) {
                                                let fuelind = mobile_combustion[
                                                    typeind
                                                ].fuel_type.findIndex((i) => {
                                                    return (
                                                        i.name.toString().trim().toLowerCase() ===
                                                        items['Fuel Type'].trim().toString().toLowerCase()
                                                    );
                                                });
                                                console.log(fuelind)
                                                if (
                                                    items['Fuel Type'] !== null &&
                                                    items['Fuel Type'].toString().trim().length !== 0 &&
                                                    fuelind !== -1
                                                ) {
                                                    if (items['Travelled Distance'] !== null && items['Distance Measurement Unit'] !== null && (items['Distance Measurement Unit']
                                                        .toString()
                                                        .trim() === "km" ||
                                                        items['Distance Measurement Unit']
                                                            .toString()
                                                            .trim() === "mi") &&
                                                        parseInt(items['Travelled Distance'].toString()) > 0) {

                                                    }

                                                    local["DPA0341"] = items['Vehicle No'];
                                                    local["DPA0140"] = {
                                                        name: mobile_combustion[categoryind]
                                                            .vehicle_category,
                                                    };
                                                    local["DPA0139"] = {
                                                        name: mobile_combustion[typeind].vehicle_type,
                                                    };
                                                    local["DPA0141"] = {
                                                        name: mobile_combustion[typeind].fuel_type[
                                                            fuelind
                                                        ].name,
                                                    };
                                                    local["DPA0144"] =
                                                        items['Travelled Distance'].toString();
                                                    local["DPA0339"] = {
                                                        name: items['Distance Measurement Unit'].toString().trim().toLowerCase()
                                                    };
                                                    local["DPA0340"] = null
                                                    local["attachment"] = null;
                                                    local["DP_MODE"] = true;
                                                    local["id"] = getID();
                                                    local2.push(local);

                                                }
                                            }
                                        }
                                    }

                                }
                            });
                        }
                    });

                    setMCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });

        e.options.clear();
    };
    const updateMcVal = (obj, val) => {
        let local = mcVal;
        let vehcat = [],
            vehtype = [],
            fueltype = [];
        if (obj === "DPA0140") {
            mobile_combustion.forEach((item) => {
                if (item.vehicle_category === val.name) {
                    console.log(item);
                    if (
                        vehtype.findIndex((i) => {
                            return i.name === item.vehicle_type;
                        }) === -1
                    ) {
                        vehtype.push({ name: item.vehicle_type });
                    }
                    console.log(val);
                }
            });
            setVehTypeList(vehtype);
            local["DPA0139"] = null;
            local["DPA0141"] = null;
        }
        if (obj === "DPA0139") {
            mobile_combustion.forEach((item) => {
                if (
                    item.vehicle_category === mcVal.DPA0140.name &&
                    item.vehicle_type === val.name
                ) {
                    if (
                        fueltype.findIndex((i) => {
                            return i.name === item.fuel_type;
                        }) === -1
                    ) {
                        fueltype = item.fuel_type;
                    }
                }
            });
            console.log(fueltype);
            setVehFuelList(fueltype);

            local["DPA0141"] = null;
        }
        if (obj === "DPA0141") {
            local.DPA0340 = null;
        }
        if (obj === "DP_MODE") {
            local["DPA0140"] = null;
            local["DPA0141"] = null;
            local["DPA0139"] = null;
            local["DPA0339"] = null;
            local["DPA0144"] = "";
            local["DPA0143"] = "";
        }
        local[obj] = val;
        setMcVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setMcVal({
            DPA0290: moment(moment.utc()).toDate(),
            DP_MODE: true,
            DPA0341: "",
            DPA0140: null,
            DPA0139: null,
            DPA0339: null,
            DPA0141: null,
            DPA0340: null,
            DPA0144: "",
            DPA0143: "",
            DPA0142: null,
            attachment: null,
        });
        setMCDialog(true);
        setSubmitted(false);
    };
    const saveMobileCombustion = () => {
        setSubmitted(true);
        let local = mcVal;
        let local2 = mcdata;
        if (
            mcVal.DPA0341.trim().length !== 0 &&
            ((mcVal.DP_MODE && mcVal.DPA0140 !== null && mcVal.DPA0139 !== null) ||
                (!mcVal.DP_MODE && mcVal.DPA0141 !== null)) &&
            mcVal.DPA0141 !== null &&
            mcVal.attachment !== null
        ) {
            if (
                mcVal.DP_MODE &&
                mcVal.DPA0339 !== null &&
                mcVal.DPA0144.trim().length !== 0 &&
                parseInt(mcVal.DPA0144) > 0 &&
                (mcVal.DPA0140.name.includes("HGV") ? mcVal.DPA0340 !== null : true)
            ) {
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }
                setMcVal({
                    DPA0290: moment(moment.utc()).toDate(),
                    DP_MODE: true,
                    DPA0341: "",
                    DPA0140: null,
                    DPA0139: null,
                    DPA0339: null,
                    DPA0141: null,
                    DPA0340: null,
                    DPA0144: "",
                    DPA0143: "",
                    DPA0142: null,
                    attachment: null,
                });
                setMCData(local2);
                forceUpdate();
                setMCDialog(false);
            } else if (
                !mcVal.DP_MODE &&
                mcVal.DPA0142 !== null &&
                mcVal.DPA0143.trim().length !== 0 &&
                parseInt(mcVal.DPA0143) > 0
            ) {
                local.DPA0139 = null;
                local.DPA0140 = null;
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }
                setMcVal({
                    DPA0290: moment(moment.utc()).toDate(),
                    DP_MODE: true,
                    DPA0341: "",
                    DPA0140: null,
                    DPA0139: null,
                    DPA0339: null,
                    DPA0141: null,
                    DPA0340: null,
                    DPA0144: "",
                    DPA0143: "",
                    DPA0142: null,
                    attachment: null,
                });
                setMCData(local2);
                forceUpdate();
                setMCDialog(false);
            }
        }
        console.log(local2);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setMCFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const mcDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveMobileCombustion();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        if (!items.DP_MODE) {
            items.DPA0142 = moment.utc(item.DPA0142).toDate();
        } else {
            items.DPA0142 = null;
        }
        let vehtype = [],
            fueltype = [];
        if (items.DP_MODE) {
            mobile_combustion.forEach((item_) => {
                if (item_.vehicle_category === items.DPA0140.name) {
                    console.log(item_);
                    if (
                        vehtype.findIndex((i) => {
                            return i.name === item_.vehicle_type;
                        }) === -1
                    ) {
                        vehtype.push({ name: item_.vehicle_type });
                    }
                }
            });
            setVehTypeList(vehtype);

            mobile_combustion.forEach((item_) => {
                if (
                    item_.vehicle_category === items.DPA0140.name &&
                    item_.vehicle_type === items.DPA0139.name
                ) {
                    if (
                        fueltype.findIndex((i) => {
                            return i.name === item_.fuel_type;
                        }) === -1
                    ) {
                        fueltype = item_.fuel_type;
                    }
                }
            });

            setVehFuelList(fueltype);
        }

        setMcVal(items);

        setMCDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = mcdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setMCData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const mcAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                'Data Captured By': null,
                                                "Fuel Refill Date": null,
                                                'Vehicle No': null,
                                                'Vehicle Category': null,
                                                'Vehicle Type': null,
                                                'Fuel Type': null,
                                                'Laden Percentage': null,
                                                'Distance Measurement Unit': null,
                                                'Fuel Refilled': null,
                                                "Travelled Distance": null,
                                            },
                                        ],
                                        "S1_OV",
                                        "Mobile Combustion Emissions"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Mobile Combustion Emissions");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={mcdata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehno", "vehtype.name"]}
                globalFilter={mcFilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Data Captured by"
                    body={(rowData) => {
                        return <span>{rowData.DP_MODE ? "by Distance" : "by Fuel"}</span>;
                    }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0140.name"
                    header="Vehicle Category"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0139.name"
                    header="Vehicle Type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0141.name"
                    header="Fuel type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={mcAttachmentTemplate}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={mcDialog}
                style={{
                    width: "450px",
                }}
                header="Add Mobile Combustion"
                modal
                className="p-fluid"
                footer={mcDialogFooter}
                onHide={() => {
                    setMCDialog(false);
                }}
            >
                <div
                    className="flex flex-wrap gap-3"
                    style={{ justifyContent: "space-between", marginBottom: 10 }}
                >
                    <label>Data captured ?</label>
                    <div className="flex align-items-center">
                        <RadioButton
                            inputId="ingredient1"
                            name="pizza"
                            value={true}
                            onChange={(e) => updateMcVal("DP_MODE", e.value)}
                            checked={mcVal.DP_MODE === true}
                        />
                        <label htmlFor="ingredient1" className="ml-2">
                            by Distance
                        </label>
                    </div>
                    <div className="flex align-items-center">
                        <RadioButton
                            inputId="ingredient2"
                            name="pizza"
                            value={false}
                            onChange={(e) => updateMcVal("DP_MODE", e.value)}
                            checked={mcVal.DP_MODE === false}
                        />
                        <label htmlFor="ingredient2" className="ml-2">
                            by Fuel
                        </label>
                    </div>
                </div>

                {!mcVal.DP_MODE ? (
                    <div className="field">
                        <label htmlFor="ecpsngr"> Fuel Type </label>
                        <Dropdown
                            value={mcVal.DPA0141}
                            onChange={(e) => {
                                updateMcVal("DPA0141", e.value);
                            }}
                            options={[
                                { name: "Diesel" },
                                { name: "Petrol" },
                                { name: "CNG" },
                            ]}
                            optionLabel="name"
                            placeholder="Select"
                        />
                        {submitted && mcVal.DPA0141 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Fuel Type
                            </small>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr"> Vehicle Category </label>
                            <Dropdown
                                value={mcVal.DPA0140}
                                onChange={(e) => {
                                    updateMcVal("DPA0140", e.value);
                                }}
                                options={vehcatlist}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPA0140 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Category
                                </small>
                            )}
                        </div>
                        {mcVal.DPA0140 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Vehicle Type </label>
                                <Dropdown
                                    value={mcVal.DPA0139}
                                    onChange={(e) => {
                                        updateMcVal("DPA0139", e.value);
                                    }}
                                    options={vehtypelist}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0139 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Vehicle Type
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPA0139 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Fuel Type </label>
                                <Dropdown
                                    value={mcVal.DPA0141}
                                    onChange={(e) => {
                                        updateMcVal("DPA0141", e.value);
                                    }}
                                    options={vehfuellist}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0141 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Fuel Type
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPA0141 !== null && mcVal.DPA0140.name.includes("HGV") && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> % of Laden </label>
                                <Dropdown
                                    value={mcVal.DPA0340}
                                    onChange={(e) => {
                                        updateMcVal("DPA0340", e.value);
                                    }}
                                    options={[
                                        { name: "0%" },
                                        { name: "50%" },
                                        { name: "100%" },
                                        { name: "Average" },
                                    ]}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0340 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select % of Laden
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                )}
                {mcVal.DPA0141 !== null &&
                    mcVal.DP_MODE &&
                    (mcVal.DPA0140.name.includes("HGV")
                        ? mcVal.DPA0340 !== null
                        : mcVal.DPA0141 !== null) && (
                        <div className="field">
                            <label htmlFor="ecpsngr"> Select Unit of Measurement </label>
                            <Dropdown
                                value={mcVal.DPA0339}
                                onChange={(e) => {
                                    updateMcVal("DPA0339", e.value);
                                }}
                                options={[{ name: "km" }, { name: "mi" }]}
                                optionLabel="name"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPA0339 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Unit of distance measurement
                                </small>
                            )}
                        </div>
                    )}
                <div className="field">
                    <label htmlFor="vehno"> Vehicle Registration Number </label>
                    <InputText
                        value={mcVal.DPA0341}
                        onChange={(e) => {
                            updateMcVal("DPA0341", e.target.value);
                        }}
                        placeholder="Vehicle Number"
                    />
                    {submitted && mcVal.DPA0341.trim() === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Vehicle Number is required
                        </small>
                    )}
                </div>
                {mcVal.DPA0339 !== null && mcVal.DP_MODE !== null && mcVal.DP_MODE && (
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="gasrefill"> Total Distance Traveled </label>
                            <div id="gasrefill" className="p-inputgroup">
                                <InputText
                                    keyfilter={"pnum"}
                                    value={mcVal.DPA0144}
                                    onChange={(e) => {
                                        updateMcVal("DPA0144", e.target.value);
                                    }}
                                    placeholder="distance travelled"
                                />
                                <span className="p-inputgroup-addon">{mcVal.DPA0339.name}</span>
                            </div>
                            {submitted &&
                                (mcVal.DPA0144.trim() === "" ||
                                    parseInt(mcVal.DPA0144) <= 0) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Distance travelled is required
                                    </small>
                                )}
                        </div>
                    </div>
                )}
                {mcVal.DP_MODE !== null && !mcVal.DP_MODE && (
                    <div className="formgrid grid">
                        <div>
                            <div className="field">
                                <label htmlFor="refdate"> Date of Refill </label>
                                <Calendar
                                    dateFormat={'dd/mm/yy'}
                                    showIcon
                                    value={mcVal.DPA0142}
                                    onChange={(e) => updateMcVal("DPA0142", e.value)}
                                ></Calendar>
                            </div>
                            {submitted && mcVal.DPA0142 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select date of fuel refilled
                                </small>
                            )}
                            <div className="field">
                                <label htmlFor="gasrefill">Total Fuel Filled </label>
                                <div id="gasrefill" className="p-inputgroup">
                                    <InputText
                                        keyfilter={"pnum"}
                                        value={mcVal.DPA0143}
                                        onChange={(e) => {
                                            updateMcVal("DPA0143", e.target.value);
                                        }}
                                        placeholder="Fuel Refilled"
                                    />
                                </div>
                                {submitted &&
                                    (mcVal.DPA0143.trim() === "" ||
                                        parseInt(mcVal.DPA0143) <= 0) && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Fuel is required
                                        </small>
                                    )}
                            </div>
                        </div>
                    </div>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(mcVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], mcVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = mcVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setMcVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(mcVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={10000000}
                    />
                    {submitted && mcVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {mcVal.attachment !== null && (
                    <>
                        {mcVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    mcVal.attachment.splice(index, 1);
                                                    if (mcVal.attachment.length === 0) {
                                                        mcVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Mobile_Combustion_ = ({ data, getData, setData, edit, standard }) => {
    const [mcdata, setMCData] = useState(data);
    const [mcDialog, setMCDialog] = useState(false);
    const forceUpdate = useForceUpdate();
    const [attachment, setAttachment] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [mcFilter, setMCFilter] = useState(null);
    const [vehcatlist, setVehCatList] = useState([]);
    const [category, setCategory] = useState([]);
    const [category2, setCategory2] = useState([]);

    const [vehtypelist, setVehTypeList] = useState([]);
    const [vehfuellist, setVehFuelList] = useState([]);
    const [unitlist, setUnitList] = useState([]);

    const [mcVal, setMcVal] = useState({
        DPA0290: moment(moment.utc()).toDate(),
        DPGMode: null,
        DP_MODE: true,
        DPA0341: "",
        DPA0140: null,
        DPA0139: null,
        DPA0339: null,
        DPA0141: null,

        DPA0144: 0,
        DPA0143: 0,
        DPA0142: null,
        attachment: null,
    });

    useEffect(() => {
        let veh_cat = [];
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard === 0 || standard === 1) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === 1
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 6);
                        let cat_index2 = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 7);
                        console.log(cat_index, cat_index2);
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                        if (cat_index2 !== -1) {
                            let subcat2 = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index2].newEfSubcategory1s;
                            if (subcat2 !== undefined) {
                                setCategory2(subcat2);
                                console.log(subcat2);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }

    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Mobile Combustion Emissions") {
                    let local = {};
                    let local2 = mcdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S1_OV") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                console.log(items);
                                if (
                                    items.fuel_type !== undefined &&
                                    items.fuel_type.toString().length !== 0 &&
                                    items.category !== undefined &&
                                    items.vehicle_mode !== undefined &&
                                    (items.vehicle_mode.toString().trim().toLowerCase() ===
                                        "passenger" ||
                                        items.vehicle_mode.toString().trim().toLowerCase() ===
                                        "delivery") &&
                                    items.vehicle_no !== undefined &&
                                    items.vehicle_no.toString().trim().length !== 0 &&
                                    items.category.toString().trim().length !== 0 &&
                                    (items.category.toString().toLowerCase() === "fuel" ||
                                        items.category.toString().toLowerCase() === "distance")
                                ) {
                                    if (items.category.toString().toLowerCase() === "distance") {
                                        console.log(items);
                                        if (
                                            items.unit !== undefined &&
                                            items.unit.toString().trim().length !== 0 &&
                                            items.km_travelled !== undefined &&
                                            parseFloat(items.km_travelled.toString()) > 0 &&
                                            items.vehicle_type !== undefined &&
                                            items.vehicle_type.toString().length !== 0 &&
                                            items.vehicle_category !== undefined &&
                                            items.vehicle_category.toString().length !== 0
                                        ) {
                                            if (
                                                items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "passenger"
                                            ) {
                                                const { result, id } = findMatchingIds(
                                                    category,
                                                    items.vehicle_category.toString(),
                                                    items.vehicle_type.toString(),
                                                    items.fuel_type.toString(),
                                                    items.unit.toString()
                                                );
                                                console.log(id);
                                                if (result) {
                                                    local["DPA0341"] = items.vehicle_no;
                                                    local["DPA0140"] = id[0];
                                                    local["DPA0139"] = id[1];
                                                    local["DPA0141"] = id[2];
                                                    local["DPA0144"] = parseFloat(
                                                        items.km_travelled.toString()
                                                    );
                                                    local["DPA0339"] = id[3];
                                                    local["DPGMode"] = 6;
                                                    local["attachment"] = null;
                                                    local["DP_MODE"] = true;
                                                    local["id"] = getID();
                                                    console.log(local);
                                                    local2.push(local);
                                                }
                                            } else if (
                                                items.vehicle_mode.toString().trim().toLowerCase() ===
                                                "delivery"
                                            ) {
                                                const { result, id } = findMatchingIds(
                                                    category2,
                                                    items.vehicle_category.toString(),
                                                    items.vehicle_type.toString(),
                                                    items.fuel_type.toString(),
                                                    items.unit.toString()
                                                );

                                                if (result) {
                                                    local["DPA0341"] = items.vehicle_no;
                                                    local["DPA0140"] = id[0];
                                                    local["DPA0139"] = id[1];
                                                    local["DPA0141"] = id[2];
                                                    local["DPA0144"] = parseFloat(
                                                        items.km_travelled.toString()
                                                    );
                                                    local["DPA0339"] = id[3];
                                                    local["DPGMode"] = 7;
                                                    local["attachment"] = null;
                                                    local["DP_MODE"] = true;
                                                    local["id"] = getID();
                                                    local2.push(local);
                                                }
                                            }
                                        }
                                    } else if (
                                        items.category.toString().toLowerCase() === "fuel"
                                    ) {
                                        console.log(typeof items.fuel_refill_date);
                                        let fuel_type_f = [
                                            { title: "Diesel", id: 13 },
                                            { title: "Petrol", id: 19 },
                                            { title: "CNG", id: 2 },
                                        ];
                                        let findex = fuel_type_f.findIndex(
                                            (x) =>
                                                x.title.trim().toLowerCase() ===
                                                items.fuel_type.toString().trim().toLowerCase()
                                        );
                                        if (
                                            findex !== -1 &&
                                            items.fuel_refill_date !== undefined &&
                                            typeof items.fuel_refill_date === "number" &&
                                            items.fuel_refilled !== undefined &&
                                            parseFloat(items.fuel_refilled) > 0
                                        ) {
                                            local["attachment"] = null;
                                            local["DP_MODE"] = false;
                                            local["DPA0341"] = items.vehicle_no;
                                            local["DPA0140"] = null;
                                            local["DPA0139"] = null;
                                            local["DPA0141"] = fuel_type_f[findex].id;
                                            local["DPA0142"] = moment(
                                                excelDateToJSDate(items.fuel_refill_date)
                                            )._d;
                                            local["DPA0143"] = parseFloat(
                                                items.fuel_refilled.toString()
                                            );
                                            local["DPA0339"] = null;
                                            local["id"] = getID();
                                            local2.push(local);
                                        }
                                    }

                                    // if (items.vehicle_category !== null && items.vehicle_category.toString().trim().length !== 0) {
                                    //     let typeind = mobile_combustion.findIndex((i) => { return i.vehicle_category === items.vehicle_category && i.vehicle_type.trim().toLowerCase() === items.vehicle_type.trim().toString().toLowerCase() })
                                    //     if (items.vehicle_type !== null && items.vehicle_type.toString().trim().length !== 0 && typeind !== -1) {
                                    //         let fuelind = mobile_combustion[typeind].fuel_type.findIndex((i) => { return i.name.toString().trim().toLowerCase() === items.fuel_type.trim().toString().toLowerCase() })
                                    //         if (items.fuel_type !== null && items.fuel_type.toString().trim().length !== 0 && fuelind !== -1) {
                                    //             if (items.vehicle_category.toString().toLowerCase().includes('hgv')) {
                                    //                 if (items.laden_percentage !== null && (items.laden_percentage.toString() === '0%' || items.laden_percentage.toString() === '50%' || items.laden_percentage.toString() === '100%' || items.laden_percentage.toString() === 'Average')) {
                                    //                     if (items.category === 'fuel' && (items.fuel_refilled !== null && parseInt(items.fuel_refilled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                         local['attachment'] = null
                                    //                         local['DP_MODE'] = false
                                    //                         local['DPA0341'] = items.vehicle_no
                                    //                         local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                         local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                         local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                         local['DPA0142'] = moment(excelDateToJSDate(items.fuel_refill_date))._d
                                    //                         local['DPA0143'] = items.fuel_refilled
                                    //                         local['DPA0340'] = { name: items.laden_percentage }
                                    //                         local['id'] = getID()
                                    //                         local2.push(local)

                                    //                     } else if (items.category === 'distance' && (items.km_travelled !== null && parseInt(items.km_travelled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                         if (items.distance_measurement_unit !== null && (items.distance_measurement_unit.toString().trim() === 'km' || items.distance_measurement_unit.toString().trim() === 'mi')) {
                                    //                             local['DPA0341'] = items.vehicle_no
                                    //                             local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                             local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                             local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                             local['DPA0144'] = items.km_travelled.toString()
                                    //                             local['DPA0339'] = { name: items.distance_measurement_unit }
                                    //                             local['DPA0340'] = { name: items.laden_percentage }
                                    //                             local['attachment'] = null
                                    //                             local['DP_MODE'] = true
                                    //                             local['id'] = getID()
                                    //                             local2.push(local)
                                    //                         }
                                    //                     }
                                    //                 }

                                    //             } else {
                                    //                 if (items.category === 'fuel' && (items.fuel_refilled !== null && parseInt(items.fuel_refilled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                     local['DPA0341'] = items.vehicle_no
                                    //                     local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                     local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                     local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                     local['DPA0142'] = moment(excelDateToJSDate(items.fuel_refill_date))._d
                                    //                     local['DPA0143'] = items.fuel_refilled.toString()
                                    //                     local['DPA0340'] = null
                                    //                     local['attachment'] = null
                                    //                     local['DP_MODE'] = false
                                    //                     local['id'] = getID()
                                    //                     local2.push(local)

                                    //                 } else if (items.category === 'distance' && (items.km_travelled !== null && parseInt(items.km_travelled.toString()) > 0) && (items.fuel_refill_date !== null && typeof items.fuel_refill_date === 'number')) {
                                    //                     if (items.distance_measurement_unit !== null && (items.distance_measurement_unit.toString().trim() === 'km' || items.distance_measurement_unit.toString().trim() === 'mi')) {
                                    //                         local['DPA0341'] = items.vehicle_no
                                    //                         local['DPA0140'] = { name: mobile_combustion[categoryind].vehicle_category }
                                    //                         local['DPA0139'] = { name: mobile_combustion[typeind].vehicle_type }
                                    //                         local['DPA0141'] = { name: mobile_combustion[typeind].fuel_type[fuelind].name }
                                    //                         local['DPA0144'] = items.km_travelled
                                    //                         local['DPA0339'] = { name: items.distance_measurement_unit }
                                    //                         local['DPA0340'] = null
                                    //                         local['attachment'] = null
                                    //                         local['DP_MODE'] = true
                                    //                         local['id'] = getID()
                                    //                         local2.push(local)

                                    //                     }
                                    //                 }
                                    //             }

                                    //         }

                                    //     }

                                    // }
                                }
                            });
                        }
                    });

                    setMCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });

        e.options.clear();
    };
    const updateMcVal = (obj, val) => {
        let local = mcVal;
        let vehcat = [],
            vehtype = [],
            fueltype = [];
        if (obj === "DPGMode") {
            local["DPA0140"] = null;
            local["DPA0139"] = null;
            local["DPA0141"] = null;
            local["DPA0339"] = null;

            if (val === 6) {
                setVehCatList(category);
            } else if (val === 7) {
                setVehCatList(category2);
            }
        }

        if (obj === "DPA0140") {
            vehcatlist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory2s !== undefined) {
                        vehtype = item.newEfSubcategory2s;
                    }
                }
            });
            setVehTypeList(vehtype);
            local["DPA0139"] = null;
            local["DPA0141"] = null;
            local["DPA0339"] = null;
        }
        if (obj === "DPA0139") {
            vehtypelist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory3s !== undefined) {
                        fueltype = item.newEfSubcategory3s;
                    }
                }
            });
            console.log(fueltype);

            setVehFuelList(fueltype);

            local["DPA0141"] = null;
            local["DPA0339"] = null;
        }
        if (obj === "DPA0141") {
            let units = [];
            vehfuellist.forEach((item) => {
                if (item.id === val) {
                    console.log(item);
                    if (item.newEfSubcategory4s !== undefined) {
                        units = item.newEfSubcategory4s;
                    }
                }
            });
            setUnitList(units);
            local["DPA0339"] = null;
        }
        if (obj === "DP_MODE") {
            local["DPGMode"] = null;
            local["DPA0140"] = null;
            local["DPA0141"] = null;
            local["DPA0139"] = null;
            local["DPA0339"] = null;
            local["DPA0144"] = 0;
            local["DPA0143"] = 0;
        }
        local[obj] = val;
        setMcVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setMcVal({
            DPGMode: null,
            DPA0290: moment(moment.utc()).toDate(),
            DP_MODE: true,
            DPA0341: "",
            DPA0140: null,
            DPA0139: null,
            DPA0339: null,
            DPA0141: null,

            DPA0144: 0,
            DPA0143: 0,
            DPA0142: null,
            attachment: null,
        });
        setMCDialog(true);
        setSubmitted(false);
    };
    const saveMobileCombustion = () => {
        setSubmitted(true);
        let local = mcVal;
        let local2 = mcdata;
        if (
            mcVal.DPA0341.trim().length !== 0 &&
            mcVal.DPA0141 !== null &&
            mcVal.attachment !== null
        ) {
            if (
                mcVal.DP_MODE &&
                mcVal.DPA0339 !== null &&
                parseFloat(mcVal.DPA0144) > 0 &&
                mcVal.DPA0140 &&
                mcVal.DPA0139
            ) {
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }
                setMcVal({
                    DPGMode: null,
                    DPA0290: moment(moment.utc()).toDate(),
                    DP_MODE: true,
                    DPA0341: "",
                    DPA0140: null,
                    DPA0139: null,
                    DPA0339: null,
                    DPA0141: null,

                    DPA0144: "",
                    DPA0143: "",
                    DPA0142: null,
                    attachment: null,
                });
                setMCData(local2);
                forceUpdate();
                setMCDialog(false);
            } else if (
                !mcVal.DP_MODE &&
                mcVal.DPA0142 !== null &&
                parseFloat(mcVal.DPA0143) > 0
            ) {
                local.DPA0139 = null;
                local.DPA0140 = null;
                if (local.id) {
                    let index = local2.findIndex((i) => {
                        return i.id === local.id;
                    });
                    local2[index] = local;
                } else {
                    local.id = getID();
                    local2.push(local);
                }
                setMcVal({
                    DPGMode: null,
                    DPA0290: moment(moment.utc()).toDate(),
                    DP_MODE: true,
                    DPA0341: "",
                    DPA0140: null,
                    DPA0139: null,
                    DPA0339: null,
                    DPA0141: null,

                    DPA0144: 0,
                    DPA0143: 0,
                    DPA0142: null,
                    attachment: null,
                });
                setMCData(local2);
                forceUpdate();
                setMCDialog(false);
            }
        }
        console.log(mcdata);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setMCFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const mcDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveMobileCombustion();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        if (!items.DP_MODE) {
            items.DPA0142 = moment.utc(item.DPA0142).toDate();
        } else {
            items.DPA0142 = null;
        }
        items.DPA0144 = parseFloat(items.DPA0144);
        items.DPA0143 = parseFloat(items.DPA0143);
        console.log(items);
        let vehtype = [],
            fueltype = [];
        if (items.DP_MODE === true) {
            if (items.DPGMode === 6) {
                let index = category.findIndex((i) => i.id === items.DPA0140);
                setVehCatList(category);
                if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
                    setVehTypeList(category[index].newEfSubcategory2s);
                    let index2 = category[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === items.DPA0139
                    );
                    console.log(index2);
                    if (
                        index2 !== -1 &&
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        setVehFuelList(
                            category[index].newEfSubcategory2s[index2].newEfSubcategory3s
                        );
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === items.DPA0141);
                        console.log(index3);

                        if (
                            index3 !== -1 &&
                            category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                index3
                            ].newEfSubcategory4s !== undefined
                        ) {
                            setUnitList(
                                category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                    index3
                                ].newEfSubcategory4s
                            );
                        }
                    }
                }
            } else if (items.DPGMode === 7) {
                let index = category2.findIndex((i) => i.id === items.DPA0140);
                setVehCatList(category);
                if (index !== -1 && category2[index].newEfSubcategory2s !== undefined) {
                    setVehTypeList(category2[index].newEfSubcategory2s);
                    let index2 = category2[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === items.DPA0139
                    );
                    if (
                        index2 !== -1 &&
                        category2[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        setVehFuelList(
                            category2[index].newEfSubcategory2s[index2].newEfSubcategory3s
                        );
                        let index3 = category2[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === items.DPA0141);
                        if (
                            index3 !== -1 &&
                            category2[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                index3
                            ].newEfSubcategory4s !== undefined
                        ) {
                            setUnitList(
                                category2[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                    index3
                                ].newEfSubcategory4s
                            );
                        }
                    }
                }
            }
        }

        setMcVal(items);
        console.log(vehcatlist, vehfuellist, unitlist);
        setMCDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = mcdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setMCData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const mcAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const vehicleCatTemplate = (rowData) => {
        let text = "NA";
        if (rowData.DP_MODE) {
            console.log(rowData, category);
            if (rowData.DPGMode === 6) {
                let index = category.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1) {
                    text = category[index].title;
                }
            } else if (rowData.DPGMode === 7) {
                let index = category2.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1) {
                    text = category2[index].title;
                }
            }
        }
        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleTypeTemplate = (rowData) => {
        let text = "NA";
        if (rowData.DP_MODE) {
            if (rowData.DPGMode === 6) {
                let index = category.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
                    let index2 = category[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (index2 !== -1) {
                        text = category[index].newEfSubcategory2s[index2].title;
                    }
                }
            } else if (rowData.DPGMode === 7) {
                let index = category2.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category2[index].newEfSubcategory2s !== undefined) {
                    let index2 = category2[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (index2 !== -1) {
                        text = category2[index].newEfSubcategory2s[index2].title;
                    }
                }
            }
        }
        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    const vehicleFuelTemplate = (rowData) => {
        let text = "NA";
        if (rowData.DP_MODE) {
            if (rowData.DPGMode === 6) {
                let index = category.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
                    let index2 = category[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (
                        index2 !== -1 &&
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        let index3 = category[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPA0141);
                        if (index3 !== -1) {
                            text =
                                category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                    index3
                                ].title;
                        }
                    }
                }
            } else if (rowData.DPGMode === 7) {
                let index = category2.findIndex((i) => i.id === rowData.DPA0140);
                if (index !== -1 && category2[index].newEfSubcategory2s !== undefined) {
                    let index2 = category2[index].newEfSubcategory2s.findIndex(
                        (i) => i.id === rowData.DPA0139
                    );
                    if (
                        index2 !== -1 &&
                        category2[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                        undefined
                    ) {
                        let index3 = category2[index].newEfSubcategory2s[
                            index2
                        ].newEfSubcategory3s.findIndex((i) => i.id === rowData.DPA0141);
                        if (index3 !== -1) {
                            text =
                                category2[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                                    index3
                                ].title;
                        }
                    }
                }
            }
        } else {
            let index = [
                { title: "Diesel", id: 13 },
                { title: "Petrol", id: 19 },
                { title: "CNG", id: 2 },
            ].findIndex((i) => i.id === rowData.DPA0141);
            if (index !== -1) {
                text = [
                    { title: "Diesel", id: 13 },
                    { title: "Petrol", id: 19 },
                    { title: "CNG", id: 2 },
                ][index].title;
            }
        }

        return (
            <>
                <span className="p-column-title"> Vehicle Category </span>
                {text}
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                category: null,
                                                fuel_refill_date: null,
                                                vehicle_mode: null,
                                                vehicle_no: null,
                                                vehicle_category: null,
                                                vehicle_type: null,
                                                fuel_type: null,
                                                unit: null,
                                                fuel_refilled: null,
                                                km_travelled: null,
                                            },
                                        ],
                                        "S1_OV",
                                        "Mobile Combustion Emissions"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Mobile Combustion Emissions");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={mcdata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehno", "vehtype.name"]}
                globalFilter={mcFilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Data Captured by"
                    body={(rowData) => {
                        return <span>{rowData.DP_MODE ? "by Distance" : "by Fuel"}</span>;
                    }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0140.name"
                    header="Vehicle Category"
                    body={vehicleCatTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0139.name"
                    header="Vehicle Type"
                    body={vehicleTypeTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0141.name"
                    header="Fuel type"
                    body={vehicleFuelTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={mcAttachmentTemplate}
                    headerStyle={{
                        width: "14%",
                        minWidth: "10rem",
                    }}
                ></Column>
                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={mcDialog}
                style={{
                    width: "450px",
                }}
                header="Add Mobile Combustion"
                modal
                className="p-fluid"
                footer={mcDialogFooter}
                onHide={() => {
                    setMCDialog(false);
                }}
            >
                <div
                    className="flex flex-wrap gap-3"
                    style={{ justifyContent: "space-between", marginBottom: 10 }}
                >
                    <label>Data captured ?</label>
                    <div className="flex align-items-center">
                        <RadioButton
                            inputId="ingredient1"
                            name="pizza"
                            value={true}
                            onChange={(e) => updateMcVal("DP_MODE", e.value)}
                            checked={mcVal.DP_MODE === true}
                        />
                        <label htmlFor="ingredient1" className="ml-2">
                            by Distance
                        </label>
                    </div>
                    <div className="flex align-items-center">
                        <RadioButton
                            inputId="ingredient2"
                            name="pizza"
                            value={false}
                            onChange={(e) => updateMcVal("DP_MODE", e.value)}
                            checked={mcVal.DP_MODE === false}
                        />
                        <label htmlFor="ingredient2" className="ml-2">
                            by Fuel
                        </label>
                    </div>
                </div>

                {!mcVal.DP_MODE ? (
                    <div className="field">
                        <label htmlFor="ecpsngr"> Fuel Type </label>
                        <Dropdown
                            value={mcVal.DPA0141}
                            onChange={(e) => {
                                updateMcVal("DPA0141", e.value);
                            }}
                            options={[
                                { title: "Diesel", id: 13 },
                                { title: "Petrol", id: 19 },
                                { title: "CNG", id: 2 },
                            ]}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && mcVal.DPA0141 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Fuel Type
                            </small>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="field">
                            <label> Vehicle Mode </label>
                            <Dropdown
                                value={mcVal.DPGMode}
                                onChange={(e) => {
                                    updateMcVal("DPGMode", e.value);
                                }}
                                options={[
                                    { name: "Passenger", id: 6 },
                                    { name: "Delivery", id: 7 },
                                ]}
                                optionLabel="name"
                                optionValue="id"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPGMode === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Vehicle Category
                                </small>
                            )}
                        </div>
                        {mcVal.DPGMode !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Vehicle Category </label>
                                <Dropdown
                                    value={mcVal.DPA0140}
                                    onChange={(e) => {
                                        updateMcVal("DPA0140", e.value);
                                    }}
                                    options={vehcatlist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0140 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Vehicle Category
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPA0140 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Vehicle Type </label>
                                <Dropdown
                                    value={mcVal.DPA0139}
                                    onChange={(e) => {
                                        updateMcVal("DPA0139", e.value);
                                    }}
                                    options={vehtypelist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0139 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Vehicle Type
                                    </small>
                                )}
                            </div>
                        )}
                        {mcVal.DPA0139 !== null && (
                            <div className="field">
                                <label htmlFor="ecpsngr"> Fuel Type </label>
                                <Dropdown
                                    value={mcVal.DPA0141}
                                    onChange={(e) => {
                                        updateMcVal("DPA0141", e.value);
                                    }}
                                    options={vehfuellist}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && mcVal.DPA0141 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Select Fuel Type
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                )}
                {mcVal.DPA0141 !== null &&
                    mcVal.DPA0140 !== null &&
                    mcVal.DPA0139 !== null && (
                        <div className="field">
                            <label htmlFor="ecpsngr"> Select Unit of Measurement </label>
                            <Dropdown
                                value={mcVal.DPA0339}
                                onChange={(e) => {
                                    updateMcVal("DPA0339", e.value);
                                }}
                                options={unitlist}
                                optionLabel="title"
                                optionValue="id"
                                placeholder="Select"
                            />
                            {submitted && mcVal.DPA0339 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select Unit of distance measurement
                                </small>
                            )}
                        </div>
                    )}
                <div className="field">
                    <label htmlFor="vehno"> Vehicle Registration Number </label>
                    <InputText
                        value={mcVal.DPA0341}
                        onChange={(e) => {
                            updateMcVal("DPA0341", e.target.value);
                        }}
                        placeholder="Vehicle Number"
                    />
                    {submitted && mcVal.DPA0341.trim() === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Vehicle Number is required
                        </small>
                    )}
                </div>
                {mcVal.DPA0339 !== null && mcVal.DP_MODE !== null && mcVal.DP_MODE && (
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="gasrefill">
                                {" "}
                                Total Distance Traveled ( in km ){" "}
                            </label>
                            <div id="gasrefill" className="p-inputgroup">
                                <InputNumber
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    value={mcVal.DPA0144}
                                    onChange={(e) => {
                                        updateMcVal("DPA0144", e.value);
                                    }}
                                    placeholder="distance travelled"
                                />
                                {/* <span className="p-inputgroup-addon">{mcVal.DPA0339.name}</span> */}
                            </div>
                            {submitted && parseInt(mcVal.DPA0144) <= 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Distance travelled is required
                                </small>
                            )}
                        </div>
                    </div>
                )}
                {mcVal.DP_MODE !== null && !mcVal.DP_MODE && (
                    <div className="formgrid grid">
                        <div>
                            <div className="field">
                                <label htmlFor="refdate"> Date of Refill </label>
                                <Calendar
                                    dateFormat={'dd/mm/yy'}
                                    showIcon
                                    value={mcVal.DPA0142}
                                    onChange={(e) => updateMcVal("DPA0142", e.value)}
                                ></Calendar>
                            </div>
                            {submitted && mcVal.DPA0142 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select date of fuel refilled
                                </small>
                            )}
                            <div className="field">
                                <label htmlFor="gasrefill">Total Fuel Filled </label>
                                <div id="gasrefill" className="p-inputgroup">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={mcVal.DPA0143}
                                        onChange={(e) => {
                                            updateMcVal("DPA0143", e.value);
                                        }}
                                        placeholder="Fuel Refilled"
                                    />
                                </div>
                                {submitted && parseInt(mcVal.DPA0143) <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Fuel is required
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(mcVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], mcVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = mcVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setMcVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(mcVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={10000000}
                    />
                    {submitted && mcVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {mcVal.attachment !== null && (
                    <>
                        {mcVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    mcVal.attachment.splice(index, 1);
                                                    if (mcVal.attachment.length === 0) {
                                                        mcVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};

//Purchase Goods & Services
const Purchase_Goods_Services = ({ data, getData, setData, edit }) => {
    const [pgdata, setPGData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [pgdata]);
    getData(pgdata);
    const [priceconvert, setPriceConvert] = useState({
        amount: 0,
        from: null,
        to: "USD",
    });
    const [currencysugesstion, setCurrencySugesstion] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [pgFilter, setPgFilter] = useState(null);
    const [conversiondialog, setConversionDialog] = useState(false);
    const [pgDialog, setPgDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [pgVal, setPgVal] = useState({
        DPA0285: moment(moment.utc()).toDate(),
        DPA0286: "",
        DPA0288: "",
        DPA0289: "",
        DPA0287: null,
        attachment: null,
    });
    const updatePgVal = (obj, val) => {
        let local = pgVal;
        local[obj] = val;
        console.log(val);
        setPgVal(local);

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Purchased_goods_and_services") {
                    let local = {};
                    let local2 = pgdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_PG") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items.date !== null &&
                                    typeof items.date === "number" &&
                                    items.item_name !== null &&
                                    items.item_name.toString().trim().length !== 0 &&
                                    items.qty_purchased !== null &&
                                    parseInt(items.qty_purchased.toString()) > 0 &&
                                    items.price_per_item !== null &&
                                    parseInt(items.price_per_item.toString()) > 0 &&
                                    items.currency_code !== null &&
                                    (currency_codes.findIndex((i) => {
                                        return (
                                            i.name.split("-")[0].toLowerCase() ===
                                            items.currency_code.toString().toLowerCase()
                                        );
                                    }) !== -1 ||
                                        items.currency_code.toString().toLowerCase() === "usd") &&
                                    items.item_class !== null &&
                                    purchase_gs.findIndex((i) => {
                                        return (
                                            i.name.toLowerCase() ===
                                            items.item_class.toString().toLowerCase()
                                        );
                                    }) !== -1
                                ) {
                                    console.log(typeof items.date === "number");
                                    if (items.currency_code.toString().toLowerCase() === "usd") {
                                        local["DPA0286"] = items.item_name;
                                        local["DPA0287"] = purchase_gs.filter((i) => {
                                            return (
                                                i.name.toLowerCase() ===
                                                items.item_class.toString().toLowerCase()
                                            );
                                        })[0];
                                        local["DPA0285"] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["DPA0288"] = items.qty_purchased.toString();
                                        local["attachment"] = null;
                                        local["DPA0289"] = items.price_per_item.toString();
                                        local2.push(local);
                                    } else {
                                        console.log(items.qty_purchased);
                                        let obj = {
                                            from: items.currency_code,
                                            to: "USD",
                                            amount: parseInt(items.price_per_item.toString()),
                                            date: moment(excelDateToJSDate(items.date)).format(
                                                "YYYY-MM-DD"
                                            ),
                                        };
                                        const options = {
                                            method: "GET",
                                            url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                                            params: obj,
                                            headers: {
                                                "X-RapidAPI-Key":
                                                    "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                                                "X-RapidAPI-Host":
                                                    "currency-conversion-and-exchange-rates.p.rapidapi.com",
                                            },
                                        };
                                        Axios.request(options)
                                            .then(function (response) {
                                                local["DPA0286"] = items.item_name;
                                                local["DPA0287"] = purchase_gs.filter((i) => {
                                                    return (
                                                        i.name.toLowerCase() ===
                                                        items.item_class.toString().toLowerCase()
                                                    );
                                                })[0];
                                                local["DPA0285"] = moment(
                                                    excelDateToJSDate(items.date)
                                                )._d;
                                                local["id"] = getID();
                                                local["DPA0288"] = items.qty_purchased.toString();
                                                local["attachment"] = null;
                                                local["DPA0289"] = response.data.result
                                                    .toFixed(2)
                                                    .toString();

                                                local2.push(local);

                                                setPGData(local2);
                                                forceUpdate();
                                            })
                                            .catch(function (error) {
                                                console.error(error);
                                            });
                                    }
                                }
                            });
                        }
                    });

                    setPGData(local2);
                    forceUpdate();
                    console.log(local2);
                }
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const pgheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setPgVal({
                                DPA0285: moment(moment.utc()).toDate(),
                                DPA0286: "",
                                DPA0288: "",
                                DPA0289: "",
                                DPA0287: null,
                                attachment: null,
                            });

                            setSubmitted(false);
                            setPgDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setPgFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const editPgVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPA0285 = moment.utc(item.DPA0285).toDate();

        setPgVal(items);
        console.log(items);
        setPgDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = pgdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setPGData(loc);
        forceUpdate();
    };
    const savePurchaseGoodsServices = () => {
        setSubmitted(true);

        if (
            pgVal.DPA0286.trim().length !== 0 &&
            pgVal.DPA0285 !== null &&
            parseInt(pgVal.DPA0289) > 0 &&
            parseInt(pgVal.DPA0288) > 0 &&
            pgVal.DPA0287 !== null &&
            pgVal.attachment !== null
        ) {
            if (pgVal.id === undefined) {
                pgVal["id"] = getID();
                setPGData((prev) => [pgVal, ...prev]);
            } else {
                let loc = pgdata;
                let index = loc.findIndex((k) => {
                    return k.id === pgVal.id;
                });
                loc[index] = pgVal;
                setPGData(loc);
            }

            forceUpdate();
            setSubmitted(false);
            setPgDialog(false);
        }

        forceUpdate();
    };
    const convertCurrency = () => {
        if (
            priceconvert.from !== null &&
            parseInt(priceconvert.amount) > 0 &&
            pgVal.DPA0285 !== null
        ) {
            let obj = {
                from: priceconvert.from.name.split("-")[0],
                to: "USD",
                amount: parseInt(priceconvert.amount),
                date: moment(pgVal.DPA0285).format("YYYY-MM-DD"),
            };
            const options = {
                method: "GET",
                url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                params: obj,
                headers: {
                    "X-RapidAPI-Key": "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                    "X-RapidAPI-Host":
                        "currency-conversion-and-exchange-rates.p.rapidapi.com",
                },
            };
            Axios.request(options)
                .then(function (response) {
                    let loc = pgVal;
                    loc.DPA0289 = response.data.result.toFixed(2).toString();
                    setPgVal(loc);
                    forceUpdate();
                    setConversionDialog(false);
                    setPriceConvert({ from: "", to: "USD", amount: 0 });
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    };
    const pgDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={savePurchaseGoodsServices}
            />
        </>
    );
    const pgAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplatePG = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editPgVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...currency_codes];
            } else {
                _filteredCountries = currency_codes.filter((country) => {
                    console.log(country);
                    return country.name
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }

            setCurrencySugesstion(_filteredCountries);
        }, 100);
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                date: "",
                                                item_name: "",
                                                item_class: "",
                                                qty_purchased: "",
                                                price_per_item: "",
                                                currency_code: "",
                                            },
                                        ],
                                        "S3_PG",
                                        "Purchased_goods_and_services"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Purchased_goods_and_services");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={pgdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={pgFilter}
                emptyMessage="No records found."
                header={edit === 1 && pgheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0285"
                    header="Date of Purchase"
                    body={dateFormatting_pg}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0286"
                    header="Item Name"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0287.name"
                    header="Item Classification"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPA0288"
                    header="Quantity Purchased"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0289"
                    header="Cost per Item "
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="attachment"
                    header="Attachment"
                    body={pgAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplatePG}> </Column>}
            </DataTable>
            <Dialog
                visible={pgDialog}
                style={{
                    width: "450px",
                }}
                header="Add Purchase Item"
                modal
                className="p-fluid"
                footer={pgDialogFooter}
                onHide={() => {
                    setPgDialog(false);
                }}
            >
                <div>
                    <div className="field">
                        <label htmlFor="refdate"> Date of purchase </label>
                        <Calendar
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            value={pgVal.DPA0285}
                            onChange={(e) => updatePgVal("DPA0285", e.value)}
                        ></Calendar>
                    </div>

                    {pgVal.DPA0285 !== null && (
                        <>
                            <div className="field">
                                <label htmlFor="DPA0286">Name of the Item Purchased</label>
                                <InputText
                                    value={pgVal.DPA0286}
                                    onChange={(e) => {
                                        updatePgVal("DPA0286", e.target.value);
                                    }}
                                />
                                {submitted && pgVal.DPA0286.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Item name is required
                                    </small>
                                )}
                            </div>
                            <div className="field">
                                <label htmlFor="DPA0287">
                                    Classification of Item Purcahsed
                                </label>
                                <Dropdown
                                    value={pgVal.DPA0287}
                                    onChange={(e) => {
                                        updatePgVal("DPA0287", e.value);
                                    }}
                                    options={purchase_gs}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                                {submitted && pgVal.DPA0287 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Classification is required
                                    </small>
                                )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Quantity Purchased </label>

                                <InputText
                                    keyfilter={"num"}
                                    value={pgVal.DPA0288}
                                    onChange={(e) => {
                                        updatePgVal("DPA0288", e.target.value);
                                    }}
                                    placeholder="Quantity purchased"
                                />

                                {submitted &&
                                    (pgVal.DPA0288.trim() === "" ||
                                        parseInt(pgVal.DPA0288) <= 0) && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Quantity is required
                                        </small>
                                    )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Price per item ( in USD ) </label>

                                <InputText
                                    keyfilter={"num"}
                                    value={pgVal.DPA0289}
                                    onChange={(e) => {
                                        updatePgVal("DPA0289", e.target.value);
                                    }}
                                    placeholder="Price per item in USD"
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "green",
                                            textDecoration: "underline",
                                        }}
                                        onClick={() => {
                                            setConversionDialog(true);
                                        }}
                                    >
                                        convert to USD
                                    </span>
                                </div>

                                {submitted && parseInt(pgVal.DPA0289) < 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        price is required
                                    </small>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(pgVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], pgVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = pgVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setPgVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(pgVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && pgVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {pgVal.attachment !== null && (
                    <>
                        {pgVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    pgVal.attachment.splice(index, 1);
                                                    if (pgVal.attachment.length === 0) {
                                                        pgVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
            <Dialog
                visible={conversiondialog}
                style={{
                    width: "50%",
                }}
                header="Currency conversion"
                modal
                className="p-fluid"
                onHide={() => {
                    setConversionDialog(false);
                }}
            >
                <div>
                    <div className="field ">
                        <label htmlFor="gasrefill"> Enter price for conversion </label>

                        <InputText
                            keyfilter={"num"}
                            value={priceconvert.amount}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.amount = e.target.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                            placeholder="Price per item in USD"
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="gasrefill">select your currency code</label>
                        <AutoComplete
                            field="name"
                            forceSelection
                            value={priceconvert.from}
                            suggestions={currencysugesstion}
                            completeMethod={search}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.from = e.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                        />
                    </div>
                    <Button
                        label="Convert & Save"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={convertCurrency}
                    />
                </div>
            </Dialog>
        </div>
    );
};
const Purchase_Goods_Services_ = ({
    standard,
    data,
    getData,
    setData,
    edit,
}) => {
    const [pgdata, setPGData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [pgdata]);
    getData(pgdata);
    const [priceconvert, setPriceConvert] = useState({
        amount: 0,
        from: null,
        to: "USD",
    });
    const [currencysugesstion, setCurrencySugesstion] = useState([]);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [pgFilter, setPgFilter] = useState(null);
    const [conversiondialog, setConversionDialog] = useState(false);
    const [pgDialog, setPgDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [category, setCategory] = useState([]);
    const [pgVal, setPgVal] = useState({
        DPA0285: moment(moment.utc()).toDate(),
        DPA0286: "",
        DPA0288: 0,
        DPA0289: 0,
        DPA0287: null,
        attachment: null,
    });
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };

        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard === 0 || standard === 1) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === 12
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 11);

                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    const updatePgVal = (obj, val) => {
        let local = pgVal;
        local[obj] = val;
        console.log(val);
        setPgVal(local);

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Purchased_goods_and_services") {
                    let local = {};
                    let local2 = pgdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S3_PG") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                let index = category.findIndex((i) => {
                                    return (
                                        i.title.trim().toLowerCase() ===
                                        items.item_class.toString().trim().toLowerCase()
                                    );
                                });
                                if (
                                    items.date !== undefined &&
                                    typeof items.date === "number" &&
                                    items.item_name !== undefined &&
                                    items.item_name.toString().trim().length !== 0 &&
                                    items.qty_purchased !== undefined &&
                                    parseFloat(items.qty_purchased.toString()) > 0 &&
                                    items.price_per_item !== undefined &&
                                    parseFloat(items.price_per_item.toString()) > 0 &&
                                    items.currency_code !== undefined &&
                                    (currency_codes.findIndex((i) => {
                                        return (
                                            i.name.split("-")[0].toLowerCase() ===
                                            items.currency_code.toString().toLowerCase()
                                        );
                                    }) !== -1 ||
                                        items.currency_code.toString().toLowerCase() === "usd") &&
                                    items.item_class !== undefined &&
                                    index !== -1
                                ) {
                                    console.log(typeof items.date === "number");
                                    if (items.currency_code.toString().toLowerCase() === "usd") {
                                        local["DPA0286"] = items.item_name;
                                        local["DPA0287"] = category[index].id;
                                        local["DPA0285"] = moment(excelDateToJSDate(items.date))._d;
                                        local["id"] = getID();
                                        local["DPA0288"] = parseFloat(
                                            items.qty_purchased.toString()
                                        );
                                        local["attachment"] = null;
                                        local["DPA0289"] = parseFloat(
                                            items.price_per_item.toString()
                                        );
                                        local2.push(local);
                                    } else {
                                        let obj = {
                                            from: items.currency_code,
                                            to: "USD",
                                            amount: parseInt(items.price_per_item.toString()),
                                            date: moment(excelDateToJSDate(items.date)).format(
                                                "YYYY-MM-DD"
                                            ),
                                        };
                                        const options = {
                                            method: "GET",
                                            url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                                            params: obj,
                                            headers: {
                                                "X-RapidAPI-Key":
                                                    "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                                                "X-RapidAPI-Host":
                                                    "currency-conversion-and-exchange-rates.p.rapidapi.com",
                                            },
                                        };
                                        Axios.request(options)
                                            .then(function (response) {
                                                local["DPA0286"] = items.item_name;
                                                local["DPA0287"] = category[index].id;
                                                local["DPA0285"] = moment(
                                                    excelDateToJSDate(items.date)
                                                )._d;
                                                local["id"] = getID();
                                                local["DPA0288"] = items.qty_purchased.toString();
                                                local["attachment"] = null;
                                                local["DPA0289"] = response.data.result
                                                    .toFixed(2)
                                                    .toString();

                                                local2.push(local);

                                                setPGData(local2);
                                                forceUpdate();
                                            })
                                            .catch(function (error) {
                                                console.error(error);
                                            });
                                    }
                                }
                            });
                        }
                    });

                    setPGData(local2);
                    forceUpdate();
                    console.log(local2);
                }
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const pgheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setPgVal({
                                DPA0285: moment(moment.utc()).toDate(),
                                DPA0286: "",
                                DPA0288: "",
                                DPA0289: "",
                                DPA0287: null,
                                attachment: null,
                            });

                            setSubmitted(false);
                            setPgDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setPgFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const editPgVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        items.DPA0285 = moment.utc(item.DPA0285).toDate();
        items.DPA0288 = parseFloat(items.DPA0288);
        items.DPA0289 = parseFloat(items.DPA0289);
        setPgVal(items);
        console.log(items);
        setPgDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = pgdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setPGData(loc);
        forceUpdate();
    };
    const savePurchaseGoodsServices = () => {
        setSubmitted(true);
        console.log(pgVal);
        if (
            pgVal.DPA0286.trim().length !== 0 &&
            pgVal.DPA0285 !== null &&
            parseFloat(pgVal.DPA0289) > 0 &&
            parseFloat(pgVal.DPA0288) > 0 &&
            pgVal.DPA0287 !== null &&
            pgVal.attachment !== null
        ) {
            if (pgVal.id === undefined) {
                pgVal["id"] = getID();
                setPGData((prev) => [pgVal, ...prev]);
            } else {
                let loc = pgdata;
                let index = loc.findIndex((k) => {
                    return k.id === pgVal.id;
                });
                loc[index] = pgVal;
                setPGData(loc);
            }

            forceUpdate();
            setSubmitted(false);
            setPgDialog(false);
        }

        forceUpdate();
    };
    const convertCurrency = () => {
        if (
            priceconvert.from !== null &&
            parseInt(priceconvert.amount) > 0 &&
            pgVal.DPA0285 !== null
        ) {
            let obj = {
                from: priceconvert.from.name.split("-")[0],
                to: "USD",
                amount: parseInt(priceconvert.amount),
                date: moment(pgVal.DPA0285).format("YYYY-MM-DD"),
            };
            const options = {
                method: "GET",
                url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
                params: obj,
                headers: {
                    "X-RapidAPI-Key": "397efccd57msh3d332f8531e8e1fp1b3799jsncf5ee8e1439",
                    "X-RapidAPI-Host":
                        "currency-conversion-and-exchange-rates.p.rapidapi.com",
                },
            };
            Axios.request(options)
                .then(function (response) {
                    let loc = pgVal;
                    loc.DPA0289 = response.data.result.toFixed(2).toString();
                    setPgVal(loc);
                    forceUpdate();
                    setConversionDialog(false);
                    setPriceConvert({ from: "", to: "USD", amount: 0 });
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    };
    const pgDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={savePurchaseGoodsServices}
            />
        </>
    );
    const pgAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const actionBodyTemplatePG = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editPgVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const itemClassificationTemplate = (rowData) => {
        let index = category.findIndex((i) => i.id === rowData.DPA0287);
        let text = "Not Found";
        if (index !== -1) {
            text = category[index].title;
        }
        return <>{text}</>;
    };
    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...currency_codes];
            } else {
                _filteredCountries = currency_codes.filter((country) => {
                    console.log(country);
                    return country.name
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }

            setCurrencySugesstion(_filteredCountries);
        }, 100);
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                date: "",
                                                item_name: "",
                                                item_class: "",
                                                qty_purchased: "",
                                                price_per_item: "",
                                                currency_code: "",
                                            },
                                        ],
                                        "S3_PG",
                                        "Purchased_goods_and_services"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Purchased_goods_and_services");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={pgdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={pgFilter}
                emptyMessage="No records found."
                header={edit === 1 && pgheader}
                responsiveLayout="scroll"
            >
                <Column
                    field="DPA0285"
                    header="Date of Purchase"
                    body={dateFormatting_pg}
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0286"
                    header="Item Name"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0287.name"
                    header="Item Classification"
                    body={itemClassificationTemplate}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPA0288"
                    header="Quantity Purchased"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0289"
                    header="Cost per Item "
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="attachment"
                    header="Attachment"
                    body={pgAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplatePG}> </Column>}
            </DataTable>
            <Dialog
                visible={pgDialog}
                style={{
                    width: "450px",
                }}
                header="Add Purchase Item"
                modal
                className="p-fluid"
                footer={pgDialogFooter}
                onHide={() => {
                    setPgDialog(false);
                }}
            >
                <div>
                    <div className="field">
                        <label htmlFor="refdate"> Date of purchase </label>
                        <Calendar
                            dateFormat={'dd/mm/yy'}
                            showIcon
                            value={pgVal.DPA0285}
                            onChange={(e) => updatePgVal("DPA0285", e.value)}
                        ></Calendar>
                    </div>

                    {pgVal.DPA0285 !== null && (
                        <>
                            <div className="field">
                                <label htmlFor="DPA0286">Name of the Item Purchased</label>
                                <InputText
                                    value={pgVal.DPA0286}
                                    onChange={(e) => {
                                        updatePgVal("DPA0286", e.target.value);
                                    }}
                                />
                                {submitted && pgVal.DPA0286.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Item name is required
                                    </small>
                                )}
                            </div>
                            <div className="field">
                                <label htmlFor="DPA0287">
                                    Classification of Item Purcahsed
                                </label>
                                <Dropdown
                                    value={pgVal.DPA0287}
                                    onChange={(e) => {
                                        updatePgVal("DPA0287", e.value);
                                    }}
                                    options={category}
                                    optionLabel="title"
                                    optionValue="id"
                                    placeholder="Select"
                                />
                                {submitted && pgVal.DPA0287 === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Classification is required
                                    </small>
                                )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Quantity Purchased </label>

                                <InputNumber
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    value={pgVal.DPA0288}
                                    onChange={(e) => {
                                        updatePgVal("DPA0288", e.value);
                                    }}
                                    placeholder="Quantity purchased"
                                />

                                {submitted && parseInt(pgVal.DPA0288) <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Quantity is required
                                    </small>
                                )}
                            </div>

                            <div className="field ">
                                <label htmlFor="gasrefill"> Price per item ( in USD ) </label>

                                <InputNumber
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    value={pgVal.DPA0289}
                                    onChange={(e) => {
                                        updatePgVal("DPA0289", e.value);
                                    }}
                                    placeholder="Price per item in USD"
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "green",
                                            textDecoration: "underline",
                                        }}
                                        onClick={() => {
                                            setConversionDialog(true);
                                        }}
                                    >
                                        convert to USD
                                    </span>
                                </div>

                                {submitted && parseInt(pgVal.DPA0289) < 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        price is required
                                    </small>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(pgVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], pgVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = pgVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setPgVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(pgVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && pgVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {pgVal.attachment !== null && (
                    <>
                        {pgVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    pgVal.attachment.splice(index, 1);
                                                    if (pgVal.attachment.length === 0) {
                                                        pgVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
            <Dialog
                visible={conversiondialog}
                style={{
                    width: "50%",
                }}
                header="Currency conversion"
                modal
                className="p-fluid"
                onHide={() => {
                    setConversionDialog(false);
                }}
            >
                <div>
                    <div className="field ">
                        <label htmlFor="gasrefill"> Enter price for conversion </label>

                        <InputText
                            keyfilter={"num"}
                            value={priceconvert.amount}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.amount = e.target.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                            placeholder="Price per item in USD"
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="gasrefill">select your currency code</label>
                        <AutoComplete
                            field="name"
                            forceSelection
                            value={priceconvert.from}
                            suggestions={currencysugesstion}
                            completeMethod={search}
                            onChange={(e) => {
                                let loc = priceconvert;
                                loc.from = e.value;
                                setPriceConvert(loc);
                                forceUpdate();
                            }}
                        />
                    </div>
                    <Button
                        label="Convert & Save"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={convertCurrency}
                    />
                </div>
            </Dialog>
        </div>
    );
};
const BP8EQ2 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const QLBP7L1D4Options = [
        { name: "Annually", id: 1 },
        { name: "Half yearly", id: 2 },
        { name: "Quarterly", id: 3 },
        { name: "Others", id: 4 },
    ];

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPS0184: "",
        DPS0185: "",
        DPS0186: "",
        DPS0187: 0,
        DPK0183: 0,
        DPS0190: 0,
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPS0184: "",
            DPS0185: "",
            DPS0186: "",
            DPS0187: 0,
            DPK0183: 0,
            DPS0190: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPS0184.trim().length !== 0 &&
            dataVal.DPS0185.trim().length !== 0 &&
            dataVal.DPS0186.trim().length !== 0 &&
            dataVal.DPS0187 >= 0 &&
            dataVal.DPK0183 >= 0 &&
            dataVal.DPS0190 >= 0
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPS0184: "",
                DPS0185: "",
                DPS0186: "",
                DPS0187: 0,
                DPK0183: 0,
                DPS0190: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPS0184", "DPS0185", "DPS0186"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Name of Project"
                    field="DPS0184"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="State"
                    field="DPS0185"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="District"
                    field="DPS0186"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="No of PAFs"
                    field="DPS0187"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="% of PAFs covered by R&R"
                    field="DPK0183"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPS0190"
                    header="Amounts paid to PAFs in the FY (In INR)"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Name of Project for which Rehabilitation and Resettlement (R&R) is
                        ongoing
                    </label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPS0184}
                        onChange={(e) => {
                            updateDataVal("DPS0184", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPS0184.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter project name
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Name of the state</label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPS0185}
                        onChange={(e) => {
                            updateDataVal("DPS0185", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPS0185.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter state name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Name of the district</label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPS0186}
                        onChange={(e) => {
                            updateDataVal("DPS0186", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPS0186.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter district name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">
                        No. of Project Affected Families (PAFs)
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPS0187}
                        onChange={(e) => {
                            updateDataVal("DPS0187", e.value);
                        }}
                    />
                    {submitted && dataVal.DPS0187 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            No. of Project Affected Families (PAFs) should be greater / equal
                            than zero
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">% of PAFs covered by R&R</label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPK0183}
                        onChange={(e) => {
                            updateDataVal("DPK0183", e.value);
                        }}
                    />
                    {submitted && dataVal.DPK0183 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            % of PAFs covered by R&R is required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Amounts paid to PAFs in the FY (In INR)
                    </label>

                    <InputNumber
                        maxFractionDigits={2}
                        value={dataVal.DPS0190}
                        onChange={(e) => {
                            updateDataVal("DPS0190", e.value);
                        }}
                        locale="en-IN"
                        minFractionDigits={2}
                    />

                    {submitted && dataVal.DPS0190 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            amount paid should not be lesser than
                        </small>
                    )}
                </div>
            </Dialog>
        </div>
    );
};
const BP2LQ3 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPBP2LQ3D1: "",
        DPBP2LQ3D2: 0,
        DPBP2LQ3D3: 0,
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPBP2LQ3D1: "",
            DPBP2LQ3D2: 0,
            DPBP2LQ3D3: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPBP2LQ3D1.trim().length !== 0 &&
            dataVal.DPBP2LQ3D2 >= 0 &&
            dataVal.DPBP2LQ3D3 >= 0
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPBP2LQ3D1: "",
                DPBP2LQ3D2: 0,
                DPBP2LQ3D3: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ3D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Indicate Input Material"
                    field="DPBP2LQ3D1"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total Quantity of raw material used"
                    field="DPBP2LQ3D2"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total Quantity of recycled or reused raw material used"
                    field="DPBP2LQ3D3"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Indicate Input Material</label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPBP2LQ3D1}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ3D1", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ3D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter inout material
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Total Quantity of raw material used</label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPBP2LQ3D2}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ3D2", e.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ3D2 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Total Quantity of recycled or reused raw material used
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPBP2LQ3D3}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ3D3", e.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ3D3 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
            </Dialog>
        </div>
    );
};
const BP2LQ5 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPBP2LQ5D1: "",
        DPBP2LQ5D2: 0,
        DPBP2LQ5D3: 0,
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPBP2LQ5D1: "",
            DPBP2LQ5D2: 0,
            DPBP2LQ5D3: 0,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPBP2LQ5D1.trim().length !== 0 &&
            dataVal.DPBP2LQ5D2 >= 0 &&
            dataVal.DPBP2LQ5D3 >= 0
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPBP2LQ5D1: "",
                DPBP2LQ5D2: 0,
                DPBP2LQ5D3: 0,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setBAFilter(e.target.value)}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Category of Product/packaging material "
                    field="DPBP2LQ5D1"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total quantity of product / packaging material sold"
                    field="DPBP2LQ5D2"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Total quantity of product / packaging material reclaimed"
                    field="DPBP2LQ5D3"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Category of Product/packaging material{" "}
                    </label>
                    <InputText
                        type={"text"}
                        value={dataVal.DPBP2LQ5D1}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ5D1", e.target.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ5D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Enter category
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Total quantity of product / packaging material sold
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPBP2LQ5D2}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ5D2", e.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ5D2 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Total quantity of product / packaging material reclaimed
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPBP2LQ5D3}
                        onChange={(e) => {
                            updateDataVal("DPBP2LQ5D3", e.value);
                        }}
                    />
                    {submitted && dataVal.DPBP2LQ5D3 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
            </Dialog>
        </div>
    );
};
const GR2LQ1 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    let DPAN0040Options = [
        { name: "Hazardous Waste", id: 1 },
        { name: "Non- Hazardous waste", id: 2 },
    ];
    let DPAN0042Options = [
        { name: "Paper - all types", id: 1 },
        { name: "Plastic - all types", id: 2 },
        { name: "Metals - all types", id: 3 },
        { name: "Electrical items - WEEE - (ex. printers)", id: 4 },
        { name: "Electrical items - Batteries", id: 5 },
        { name: "General Waste - Commecial and industrial waste", id: 6 },
        { name: "General Waste - Organic: mixed food and garden waste", id: 7 },
        { name: "General Waste - Organic: garden waste", id: 8 },
        { name: "General Waste - Organic: food and drink waste", id: 9 },
        { name: "General Waste - Household residual waste", id: 10 },
        { name: "Glass - all types", id: 11 },
        { name: "Others", id: 12 },
    ];
    let DPAN0044Options = [
        { name: "Recovery of waste", id: 1 },
        { name: "Disposal of waste", id: 2 },
    ];

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPAN0040: null,
        DPAN0041: "",
        DPAN0042A: "",
        DPAN0042: null,
        DPAN0043: 0,
        DPAN0044: null,
        DPA0068A: false,
        DPA0069A: false,
        DPA0070A: false,
        DPA0068: 0,
        DPA0069: 0,
        DPA0070: [
            { mode: "", qty: 0 },
            { mode: "", qty: 0 },
        ],
        DPA0087A: false,
        DPA0088A: false,
        DPA0089A: false,
        DPA0087: 0,
        DPA0088: 0,
        DPA0089: 0,
        DPA0090A: false,
        DPA0090: [
            { mode: "", qty: 0 },
            { mode: "", qty: 0 },
        ],
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        if (obj === "DPAN0040") {
            local["DPAN0041"] = "";
            local["DPAN0043"] = 0;
            local["DPAN0042"] = null;
            local["DPAN0044"] = null;
        }
        if (obj === "DPAN0044") {
            local["DPA0068A"] = false;
            local["DPA0069A"] = false;
            local["DPGRWAD7"] = false;
            local["DPGRWAD8"] = false;
            local["DPGRWAD9"] = false;
            local["DPGRWAD10"] = false;
            local["DPGRWAD11"] = false;

            local["DPA0068"] = 0;
            local["DPA0069"] = 0;
            local["DPA0087"] = 0;
            local["DPA0088"] = 0;
            local["DPA0089"] = 0;
            local["DPA0070"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
            local["DPA0090"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        if (obj === "DPA0068A") {
            local["DPA0068"] = 0;
        }
        if (obj === "DPA0069A") {
            local["DPA0069"] = 0;
        }
        if (obj === "DPA0087A") {
            local["DPA0087"] = 0;
        }
        if (obj === "DPA0088A") {
            local["DPA0088"] = 0;
        }
        if (obj === "DPA0089A") {
            local["DPA0089"] = 0;
        }
        if (obj === "DPA0070A") {
            local["DPA0070"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        if (obj === "DPA0090A") {
            local["DPA0090"] = [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ];
        }
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPAN0040: null,
            DPAN0041: "",
            DPAN0042A: "",
            DPAN0042: null,
            DPAN0043: 0,
            DPAN0044: null,
            DPA0068A: false,
            DPA0069A: false,
            DPA0070A: false,
            DPA0068: 0,
            DPA0069: 0,
            DPA0070: [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ],
            DPA0087A: false,
            DPA0088A: false,
            DPA0089A: false,
            DPA0087: 0,
            DPA0088: 0,
            DPA0089: 0,
            DPA0090A: false,
            DPA0090: [
                { mode: "", qty: 0 },
                { mode: "", qty: 0 },
            ],
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPAN0040 !== null &&
            dataVal.DPAN0044 !== null &&
            dataVal.DPAN0043 > 0 &&
            (dataVal.DPAN0040 === 1
                ? dataVal.DPAN0041.trim().length !== 0
                : dataVal.DPAN0042 !== null) &&
            (dataVal.DPAN0040 === 2
                ? dataVal.DPAN0042 === 12
                    ? dataVal.DPAN0042A.trim().length !== 0
                    : true
                : true)
        ) {
            if (dataVal.DPAN0044 === 1) {
                if (
                    (dataVal.DPA0068A === true && dataVal.DPA0068 > 0) ||
                    (dataVal.DPA0069A === true && dataVal.DPA0069 > 0) ||
                    (dataVal.DPA0070A === true &&
                        dataVal.DPA0070.some(
                            (item) => item.mode.trim().length !== 0 && item.qty > 0
                        ))
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }
                    setBAData(local2);
                    forceUpdate();
                    setBADialog(false);
                }
            } else {
                if (
                    (dataVal.DPA0087A === true && dataVal.DPA0087 > 0) ||
                    (dataVal.DPA0088A === true && dataVal.DPA0088 > 0) ||
                    (dataVal.DPA0089A === true && dataVal.DPA0089 > 0) ||
                    (dataVal.DPA0090A === true &&
                        dataVal.DPA0090.some(
                            (item) => item.mode.trim().length !== 0 && item.qty > 0
                        ))
                ) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getID();
                        local2.push(local);
                    }
                    setBAData(local2);
                    forceUpdate();
                    setBADialog(false);
                }
            }
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const categoryTemplate = (rowData) => {
        let txt = "Not Found";

        let index = DPAN0040Options.findIndex((i) => i.id === rowData.DPAN0040);
        if (index !== -1) {
            txt = DPAN0040Options[index].name;
        }
        console.log(rowData.DPAN0040, index);
        return <> {txt} </>;
    };
    const typeTemplate = (rowData) => {
        let txt = "Not Found";
        if (rowData.DPAN0040 === 1) {
            txt = rowData.DPAN0041;
        } else {
            let index = DPAN0042Options.findIndex((i) => i.id === rowData.DPAN0042);
            if (index !== -1) {
                txt = DPAN0042Options[index].name;
            }
        }

        return <> {txt} </>;
    };
    const managementTemplate = (rowData) => {
        let txt = "Not Found";

        let index = DPAN0044Options.findIndex((i) => i.id === rowData.DPAN0044);
        if (index !== -1) {
            txt = DPAN0044Options[index].name;
        }
        return <> {txt} </>;
    };

    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div>
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Category of Waste Generated"
                    body={(rowData) => {
                        return categoryTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Type of Hazardous waste generated"
                    body={(rowData) => {
                        return typeTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Quantity Generated"
                    field="DPAN0043"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Management of waste"
                    body={(rowData) => {
                        return managementTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Category of Waste Generated</label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        value={dataVal.DPAN0040}
                        options={DPAN0040Options}
                        onChange={(e) => {
                            updateDataVal("DPAN0040", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0040 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select category
                        </small>
                    )}
                </div>
                {dataVal.DPAN0040 === 1 ? (
                    <div className="field">
                        <label htmlFor="ecpsngr">Type of Hazardous waste generated</label>
                        <InputText
                            type={"text"}
                            value={dataVal.DPAN0041}
                            onChange={(e) => {
                                updateDataVal("DPAN0041", e.target.value);
                            }}
                        />
                        {submitted && dataVal.DPAN0041.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Enter type of hazardous
                            </small>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr">
                                Type of Non-Hazardous waste generated
                            </label>
                            <Dropdown
                                optionLabel="name"
                                optionValue="id"
                                value={dataVal.DPAN0042}
                                options={DPAN0042Options}
                                onChange={(e) => {
                                    updateDataVal("DPAN0042", e.value);
                                }}
                            />
                            {submitted && dataVal.DPAN0042 === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select category
                                </small>
                            )}
                        </div>
                        {dataVal.DPAN0042 === 12 && (
                            <div className="field">
                                <label htmlFor="ecpsngr">Please mention type</label>
                                <InputText
                                    type={"text"}
                                    value={dataVal.DPAN0042A}
                                    onChange={(e) => {
                                        updateDataVal("DPAN0042A", e.target.value);
                                    }}
                                />
                                {submitted && dataVal.DPAN0042A.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Enter type of hazardous
                                    </small>
                                )}
                            </div>
                        )}
                    </>
                )}

                <div className="field">
                    <label htmlFor="ecpsngr">Quantity Generated (In Metric Tonnes)</label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPAN0043}
                        onChange={(e) => {
                            updateDataVal("DPAN0043", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0043 <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Management of waste</label>
                    <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        value={dataVal.DPAN0044}
                        options={DPAN0044Options}
                        onChange={(e) => {
                            updateDataVal("DPAN0044", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0044 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select type
                        </small>
                    )}
                </div>
                {dataVal.DPAN0044 === 1 ? (
                    <div className="flex grid justify-content-center gap-3">
                        <div className="flex align-items-center col-12">
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient1"
                                    name="pizza"
                                    value="Cheese"
                                    onChange={(e) => {
                                        updateDataVal("DPA0068A", e.checked);
                                    }}
                                    checked={dataVal.DPA0068A === true}
                                />
                                <label htmlFor="ingredient1" className="ml-2">
                                    Preparation for reuse
                                </label>
                            </div>
                            <div className="col-4">
                                <InputNumber
                                    value={dataVal.DPA0068}
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    disabled={dataVal.DPA0068A === false}
                                    onValueChange={(e) => {
                                        updateDataVal("DPA0068", e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex align-items-center col-12">
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient2"
                                    name="pizza"
                                    value="Mushroom"
                                    onChange={(e) => {
                                        updateDataVal("DPA0069A", e.checked);
                                    }}
                                    checked={dataVal.DPA0069A === true}
                                />
                                <label htmlFor="ingredient2" className="ml-2">
                                    Recycling
                                </label>
                            </div>
                            <div className="col-4">
                                <InputNumber
                                    value={dataVal.DPA0069}
                                    maxFractionDigits={4}
                                    useGrouping={false}
                                    disabled={dataVal.DPA0069A === false}
                                    onValueChange={(e) => {
                                        updateDataVal("DPA0069", e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex  col-12" style={{ flexDirection: "column" }}>
                            <div className="col-8">
                                <Checkbox
                                    inputId="ingredient3"
                                    name="pizza"
                                    value="Pepper"
                                    onChange={(e) => {
                                        updateDataVal("DPA0070A", e.checked);
                                    }}
                                    checked={dataVal.DPA0070A === true}
                                />
                                <label htmlFor="ingredient3" className="ml-2">
                                    Other recovery operations
                                </label>
                            </div>
                            {dataVal.DPA0070A === true && (
                                <div className="col-12">
                                    <div className="col-12 grid">
                                        <div className="col-8">Mode</div>
                                        <div className="col-4">Quantity</div>
                                    </div>
                                    {dataVal.DPA0070.map((item) => {
                                        return (
                                            <div className="col-12 grid">
                                                <div className="col-8">
                                                    <InputText
                                                        type={"text"}
                                                        value={item.mode}
                                                        onChange={(e) => {
                                                            item.mode = e.target.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <InputNumber
                                                        maxFractionDigits={4}
                                                        useGrouping={false}
                                                        value={item.qty}
                                                        onChange={(e) => {
                                                            item.qty = e.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    dataVal.DPAN0044 === 2 && (
                        <div className="flex grid justify-content-center gap-3">
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="pizza"
                                        value="Cheese"
                                        onChange={(e) => {
                                            updateDataVal("DPA0087A", e.checked);
                                        }}
                                        checked={dataVal.DPA0087A === true}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">
                                        Incineration (with energy recovery)
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0087}
                                        disabled={dataVal.DPA0087A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0087", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="pizza"
                                        value="Cheese"
                                        onChange={(e) => {
                                            updateDataVal("DPA0088A", e.checked);
                                        }}
                                        checked={dataVal.DPA0088A === true}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">
                                        Incineration (without energy recovery)
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0088}
                                        disabled={dataVal.DPA0088A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0088", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center col-12">
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient2"
                                        name="pizza"
                                        value="Mushroom"
                                        onChange={(e) => {
                                            updateDataVal("DPA0089A", e.checked);
                                        }}
                                        checked={dataVal.DPA0089A === true}
                                    />
                                    <label htmlFor="ingredient2" className="ml-2">
                                        Landfilling
                                    </label>
                                </div>
                                <div className="col-4">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={dataVal.DPA0089}
                                        disabled={dataVal.DPA0089A === false}
                                        onValueChange={(e) => {
                                            updateDataVal("DPA0089", e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex  col-12" style={{ flexDirection: "column" }}>
                                <div className="col-8">
                                    <Checkbox
                                        inputId="ingredient3"
                                        name="pizza"
                                        value="Pepper"
                                        onChange={(e) => {
                                            updateDataVal("DPA0090A", e.checked);
                                        }}
                                        checked={dataVal.DPA0090A === true}
                                    />
                                    <label htmlFor="ingredient3" className="ml-2">
                                        Other disposal operations
                                    </label>
                                </div>
                                {dataVal.DPA0090A === true && (
                                    <div className="col-12">
                                        <div className="col-12 grid">
                                            <div className="col-8">Mode</div>
                                            <div className="col-4">Quantity</div>
                                        </div>
                                        {dataVal.DPA0090.map((item) => {
                                            return (
                                                <div className="col-12 grid">
                                                    <div className="col-8">
                                                        <InputText
                                                            type={"text"}
                                                            value={item.mode}
                                                            onChange={(e) => {
                                                                item.mode = e.target.value;
                                                                forceUpdate();
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <InputNumber
                                                            maxFractionDigits={4}
                                                            useGrouping={false}
                                                            value={item.qty}
                                                            onChange={(e) => {
                                                                item.qty = e.value;
                                                                forceUpdate();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                )}
            </Dialog>
        </div>
    );
};
const GR2LQ2 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const DPAN0048Options = [
        { name: "Surface water", id: 1 },
        { name: "Ground Water", id: 2 },
        { name: "Sea Water", id: 3 },
        { name: "Produced Water", id: 4 },
        { name: "Third-Party Water", id: 5 },
    ];
    const DPAN0050Options = [
        { name: "(≤1,000 mg/L Total Dissolved Solids)", id: 1 },
        { name: "(>1,000 mg/L Total Dissolved Solids)", id: 2 },
    ];

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPAN0048: null,
        DPAN0049: 0,
        DPAN0050: null,
        DPAN0051: false,
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === "DPAN0048") {
            local["DPAN0049"] = 0;
            local["DPAN0050"] = null;
        }

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPAN0048: null,
            DPAN0049: 0,
            DPAN0050: null,
            DPAN0051: false,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPAN0048 !== null &&
            dataVal.DPAN0049 > 0 &&
            dataVal.DPAN0050 !== null
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPAN0048: null,
                DPAN0049: 0,
                DPAN0050: null,
                DPAN0051: false,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    const sourceTemplate = (rowData) => {
        let txt = "Not Found";
        let index = DPAN0048Options.findIndex((i) => i.id === rowData.DPAN0048);
        if (index !== -1) {
            txt = DPAN0048Options[index].name;
        }
        return <>{txt}</>;
    };

    const withdrawlTemplate = (rowData) => {
        let txt = "Not Found";
        let index = DPAN0050Options.findIndex((i) => i.id === rowData.DPAN0050);
        if (index !== -1) {
            txt = DPAN0050Options[index].name;
        }
        return <>{txt}</>;
    };
    return (
        <div>
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Source of Water Withdrawal"
                    body={(rowData) => {
                        return sourceTemplate(rowData);
                    }}
                    field="DPBP2LQ5D1"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Quantity of Water withdrawn"
                    field="DPAN0049"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="TDS of Water Withdrawal"
                    body={(rowData) => {
                        return withdrawlTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                <Column
                    header="Come under water-stressed area "
                    body={(rowData) => {
                        <>{rowData.DPAN0051 ? "Yes" : "No"}</>;
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Source of Water Withdrawal </label>
                    <Dropdown
                        optionLabel="name"
                        onChange={(e) => {
                            updateDataVal("DPAN0048", e.value);
                        }}
                        optionValue="id"
                        options={DPAN0048Options}
                        value={dataVal.DPAN0048}
                    />

                    {submitted && dataVal.DPAN0048 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select source
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Quantity of Water withdrawn (in Megaliters)
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPAN0049}
                        onChange={(e) => {
                            updateDataVal("DPAN0049", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0049 <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">TDS of Water Withdrawal </label>
                    <Dropdown
                        optionLabel="name"
                        onChange={(e) => {
                            updateDataVal("DPAN0050", e.value);
                        }}
                        optionValue="id"
                        options={DPAN0050Options}
                        value={dataVal.DPAN0050}
                    />

                    {submitted && dataVal.DPAN0050 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select TDS
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Does the area of water withdrawal come under water-stressed area{" "}
                    </label>

                    <div className="flex flex-wrap gap-3">
                        <div className="flex align-items-center">
                            <RadioButton
                                inputId="ingredient1"
                                name="pizza"
                                value={dataVal.DPAN0051}
                                onChange={(e) => updateDataVal("DPAN0051", true)}
                                checked={dataVal.DPAN0051 === true}
                            />
                            <label htmlFor="ingredient1" className="ml-2">
                                Yes
                            </label>
                        </div>
                        <div className="flex align-items-center ml-5">
                            <RadioButton
                                inputId="ingredient2"
                                name="pizza"
                                value={dataVal.DPAN0051}
                                onChange={(e) => updateDataVal("DPAN0051", false)}
                                checked={dataVal.DPAN0051 === false}
                            />
                            <label htmlFor="ingredient2" className="ml-2">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const GR2LQ3 = ({ data, getData, setData, edit }) => {
    const [badata, setBAData] = useState(data);
    const [baDialog, setBADialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [bafilter, setBAFilter] = useState(null);

    const DPAN0052Options = [
        { name: "Surface water", id: 1 },
        { name: "Ground Water", id: 2 },
        { name: "Sea Water", id: 3 },
        { name: "Produced Water", id: 4 },
        { name: "Third-Party Water", id: 5 },
    ];
    const DPAN0054Options = [
        { name: "(≤1,000 mg/L Total Dissolved Solids)", id: 1 },
        { name: "(>1,000 mg/L Total Dissolved Solids)", id: 2 },
    ];

    const forceUpdate = useForceUpdate();

    const [dataVal, setDataVal] = useState({
        DPAN0052: null,
        DPAN0053: 0,
        DPAN0054: null,
        DPAN0055: false,
    });

    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === "DPAN0052") {
            local["DPAN0053"] = 0;
            local["DPAN0054"] = null;
        }

        local[obj] = val;
        console.log(obj, val);
        setDataVal(local);
        forceUpdate();
    };

    const openNewMc = () => {
        setDataVal({
            DPAN0052: null,
            DPAN0053: 0,
            DPAN0054: null,
            DPAN0055: false,
        });
        setBADialog(true);
        setSubmitted(false);
    };
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (
            dataVal.DPAN0052 !== null &&
            dataVal.DPAN0053 > 0 &&
            dataVal.DPAN0054 !== null
        ) {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                DPAN0052: null,
                DPAN0053: 0,
                DPAN0054: null,
                DPAN0055: false,
            });
            setBAData(local2);
            forceUpdate();
            setBADialog(false);
        }
        console.log(dataVal);
    };
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));

        setDataVal(items);

        setBADialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = badata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setBAData(loc);
        forceUpdate();
    };
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editMcVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };

    const sourceTemplate = (rowData) => {
        let txt = "Not Found";
        let index = DPAN0052Options.findIndex((i) => i.id === rowData.DPAN0052);
        if (index !== -1) {
            txt = DPAN0052Options[index].name;
        }
        return <>{txt}</>;
    };

    const withdrawlTemplate = (rowData) => {
        let txt = "Not Found";
        let index = DPAN0054Options.findIndex((i) => i.id === rowData.DPAN0054);
        if (index !== -1) {
            txt = DPAN0054Options[index].name;
        }
        return <>{txt}</>;
    };
    return (
        <div>
            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPBP2LQ5D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Destination of Water discharge"
                    body={(rowData) => {
                        return sourceTemplate(rowData);
                    }}
                    field="DPBP2LQ5D1"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Quantity of water discharged"
                    field="DPAN0049"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="TDS of the destination of disposal"
                    body={(rowData) => {
                        return withdrawlTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                <Column
                    header="Disposal area come under water stress"
                    body={(rowData) => {
                        <>{rowData.DPAN0055 ? "Yes" : "No"}</>;
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}
            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => {
                    setBADialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="ecpsngr">Destination of Water discharge </label>
                    <Dropdown
                        optionLabel="name"
                        onChange={(e) => {
                            updateDataVal("DPAN0052", e.value);
                        }}
                        optionValue="id"
                        options={DPAN0052Options}
                        value={dataVal.DPAN0052}
                    />

                    {submitted && dataVal.DPAN0052 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select source
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">
                        Quantity of water discharged
                    </label>
                    <InputNumber
                        maxFractionDigits={4}
                        useGrouping={false}
                        value={dataVal.DPAN0053}
                        onChange={(e) => {
                            updateDataVal("DPAN0053", e.value);
                        }}
                    />
                    {submitted && dataVal.DPAN0053 <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">TDS of the destination of disposal </label>
                    <Dropdown
                        optionLabel="name"
                        onChange={(e) => {
                            updateDataVal("DPAN0054", e.value);
                        }}
                        optionValue="id"
                        options={DPAN0054Options}
                        value={dataVal.DPAN0054}
                    />

                    {submitted && dataVal.DPAN0054 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select TDS
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">
                        Does the disposal area come under water stress
                    </label>

                    <div className="flex flex-wrap gap-3">
                        <div className="flex align-items-center">
                            <RadioButton
                                inputId="ingredient1"
                                name="pizza"
                                value={dataVal.DPAN0051}
                                onChange={(e) => updateDataVal("DPAN0055", true)}
                                checked={dataVal.DPAN0055 === true}
                            />
                            <label htmlFor="ingredient1" className="ml-2">
                                Yes
                            </label>
                        </div>
                        <div className="flex align-items-center ml-5">
                            <RadioButton
                                inputId="ingredient2"
                                name="pizza"
                                value={dataVal.DPAN0051}
                                onChange={(e) => updateDataVal("DPAN0055", false)}
                                checked={dataVal.DPAN0055 === false}
                            />
                            <label htmlFor="ingredient2" className="ml-2">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const HotelStay = ({ data, getData, setData, edit, standard }) => {
    const [hsdata, setHSData] = useState(data);
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [hsdata]);
    getData(hsdata);
    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [selectedRef, setSelectedRef] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [hsVal, setHsVal] = useState({
        DPAN091: [null, null],
        DPAN090: null,
        DPAN090A: null,
        DPAN092: 0,
        attachment: null,
    });
    const [category, setCategory] = useState([]);
    const [unitlist, setUnitList] = useState([]);

    const updateHsVal = (obj, val) => {
        let local = hsVal;
        local[obj] = val;
        if (obj === "DPAN090") {
            local["DPAN090A"] = "";
            local["DPAN092"] = 0;
        }
        setHsVal(local);

        forceUpdate();
    };
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard === 0 || standard === 1) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === 1
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 13);

                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setCategory(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Hotel Stay") {
                    let local = {};
                    let local2 = hsdata;

                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "BT_HS") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};

                                if (
                                    items.from_date !== undefined &&
                                    typeof items.from_date === "number" &&
                                    items.to_date !== undefined &&
                                    typeof items.to_date === "number" &&
                                    items.country !== undefined &&
                                    items.country.trim().length !== 0 &&
                                    items.no_of_night_stay !== undefined &&
                                    parseFloat(items.no_of_night_stay.toString()) > 0
                                ) {
                                    let index = category.findIndex(
                                        (i) =>
                                            i.title.trim().toLowerCase() ===
                                            items.country.toString().toLowerCase()
                                    );

                                    local["DPAN091"] = [
                                        moment(excelDateToJSDate(items.from_date))._d,
                                        moment(excelDateToJSDate(items.to_date))._d,
                                    ];
                                    if (index === -1) {
                                        local["DPAN090"] = 248;
                                        local["DPAN090A"] = items.country.toString();
                                    } else {
                                        local["DPAN090"] = category[index].id;
                                        local["DPAN090A"] = "";
                                    }

                                    local["DPAN092"] = parseFloat(items.no_of_night_stay);

                                    local["id"] = getID();
                                    local["attachment"] = null;

                                    local2.push(local);
                                }
                            });
                        }
                    });

                    setHSData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const refheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setHsVal({
                                DPAN091: null,
                                DPAN090: null,
                                DPAN090A: null,
                                DPAN092: 0,
                                attachment: null,
                            });

                            setSubmitted(false);
                            setRefDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
        </div>
    );
    const edithsVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        let date1 = DateTime.fromFormat(
            items.DPAN091[0],
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
            { zone: "UTC" }
        ).toJSDate();
        let date2 = DateTime.fromFormat(
            items.DPAN091[1],
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
            { zone: "UTC" }
        ).toJSDate();

        items.DPAN091 = [date1, date2];

        setHsVal(items);
        console.log(items);
        setRefDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = hsdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setHSData(loc);
        forceUpdate();
    };

    const saveHotelstay = () => {
        setSubmitted(true);
        console.log(hsVal);
        if (
            hsVal.DPAN091 !== null &&
            hsVal.DPAN091.filter((i) => i !== null).length === 2 &&
            hsVal.DPAN090 !== null &&
            (hsVal.DPAN090 === 248 ? hsVal.DPAN090A.trim().length !== 0 : true) &&
            hsVal.DPAN092 > 0 &&
            hsVal.attachment !== null
        ) {
            if (hsVal.id === undefined) {
                hsVal["id"] = getID();
                setHSData((prev) => [hsVal, ...prev]);
            } else {
                let loc = hsdata;
                let index = loc.findIndex((k) => {
                    return k.id === hsVal.id;
                });
                loc[index] = hsVal;
                setHSData(loc);
            }
            console.log(hsVal);
            forceUpdate();
            setSubmitted(false);
            setRefDialog(false);
        }

        forceUpdate();
    };
    const refDialogFooter = (
        <>
            <Button
                label="save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={saveHotelstay}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const countryTemplate = (rowData) => {
        let index = category.findIndex((i) => {
            return i.id === rowData.DPAN090;
        });
        let text = "NA";
        if (index !== -1) {
            text = category[index].title;
        }
        return <span>{text} </span>;
    };
    const HSStayTemplate = (rowData) => {
        let date = JSON.parse(JSON.stringify(rowData.DPAN091));
        let from = DateTime.fromISO(date[0], { zone: "UTC" })
            .toLocal()
            .toFormat("dd/MM/yyyy");
        let to = DateTime.fromISO(date[1], { zone: "UTC" })
            .toLocal()
            .toFormat("dd/MM/yyyy");
        return (
            <span>
                {from}-{to}{" "}
            </span>
        );
    };
    const actionBodyTemplateRF = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            edithsVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                from_date: "",
                                                to_date: "",
                                                country: "",
                                                no_of_night_stay: "",
                                            },
                                        ],
                                        "BT_HS",
                                        "Hotel Stay"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Hotel Stay");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={hsdata}
                scrollable
                onSelectionChange={(e) => setSelectedRef(e.value)}
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0135", "DPA0136.name"]}
                globalFilter={refFilter}
                emptyMessage="No records found."
                header={edit === 1 && refheader}
                responsiveLayout="scroll"
            >
                <Column
                    header="Date of Stay"
                    body={(rowData) => {
                        return HSStayTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="Country"
                    body={(rowData) => {
                        return countryTemplate(rowData);
                    }}
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    header="No of night stay"
                    field="DPAN092"
                    headerStyle={{
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{
                        width: "8%",
                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column body={actionBodyTemplateRF}> </Column>}
            </DataTable>
            <Dialog
                visible={refDialog}
                style={{
                    width: "450px",
                }}
                header="Add Hotel Stay"
                modal
                className="p-fluid"
                footer={refDialogFooter}
                onHide={() => {
                    setRefDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="refdate">Hotel Stay Date (From - To)</label>
                    <Calendar
                        dateFormat={'dd/mm/yy'}
                        value={hsVal.DPAN091}
                        onChange={(e) => updateHsVal("DPAN091", e.value)}
                        selectionMode="range"
                    />
                </div>
                {submitted &&
                    (hsVal.DPAN091 === null ||
                        hsVal.DPAN091.filter((i) => i !== null).length !== 2) && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select stay date
                        </small>
                    )}
                <div className="field">
                    <label htmlFor="DPAN090">Select Country</label>
                    <Dropdown
                        value={hsVal.DPAN090}
                        onChange={(e) => {
                            updateHsVal("DPAN090", e.value);
                        }}
                        options={category}
                        optionLabel="title"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && hsVal.DPAN090 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Select country
                        </small>
                    )}
                </div>
                {hsVal.DPAN090 !== null && hsVal.DPAN090 === 248 && (
                    <div className="field">
                        <label htmlFor="DPAN090A">Specify country name </label>
                        <InputText
                            value={hsVal.DPAN090A}
                            onChange={(e) => {
                                updateHsVal("DPAN090A", e.target.value);
                            }}
                        />
                        {submitted && hsVal.DPAN090A.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                mention country name
                            </small>
                        )}
                    </div>
                )}

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="gasrefill">No of night stay </label>
                        <div id="gasrefill" className="p-inputgroup">
                            <InputNumber
                                maxFractionDigits={4}
                                useGrouping={false}
                                value={hsVal.DPAN092}
                                onChange={(e) => {
                                    updateHsVal("DPAN092", e.value);
                                }}
                            />
                            <span className="p-inputgroup-addon">Room per night</span>
                        </div>
                        {submitted && parseInt(hsVal.DPAN092) <= 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                days required
                            </small>
                        )}
                    </div>
                </div>
                <>
                    <FileUpload
                        mode="basic"
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(hsVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        customUpload
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], hsVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = hsVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setHsVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(hsVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && hsVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {hsVal.attachment !== null && (
                    <>
                        {hsVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    hsVal.attachment.splice(index, 1);
                                                    if (hsVal.attachment.length === 0) {
                                                        hsVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Electricity = ({ data, getData, setData, edit, standard }) => {
    const [scdata, setSCData] = useState([]);
    const forceUpdate = useForceUpdate();

    const [attdialog, setAttDialog] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [refFilter, setRefFilter] = useState(null);
    const [scfilter, setScFilter] = useState(null);
    const [refDialog, setRefDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [fuelcatlist, setFuelCatList] = useState([]);
    const [fuellist, setFuelList] = useState([]);
    const [category, setCategory] = useState([]);

    const [scVal, setSCVal] = useState({
        DPAN095: null,
        DPAN096: null,
        DPAN099: null,
        DPAN097: "",
        DPAN098: 0,
        attachment: null,
    });
    const [scdialog, setScDialog] = useState(false);
    useEffect(() => {
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        ).then((res) => {
            if (standard === 0 || standard === 1) {
                let stdindex = JSON.parse(JSON.stringify(res.data)).findIndex(
                    (i) => i.id === 14
                );
                console.log(stdindex);
                if (stdindex !== -1) {
                    if (
                        JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories !==
                        undefined
                    ) {
                        let cat_index = JSON.parse(JSON.stringify(res.data))[
                            stdindex
                        ].newEfCategories.findIndex((i) => i.id === 31);
                        setCategory(
                            JSON.parse(JSON.stringify(res.data))[stdindex].newEfCategories[
                                cat_index
                            ].newEfSubcategory1s
                        );
                        if (cat_index !== -1) {
                            let subcat = JSON.parse(JSON.stringify(res.data))[stdindex]
                                .newEfCategories[cat_index].newEfSubcategory1s;
                            if (subcat !== undefined) {
                                setFuelCatList(subcat);
                                console.log(subcat);
                            } else {
                                console.log("missing3");
                            }
                        } else {
                            console.log("missing2");
                        }
                    } else {
                        console.log("missing1");
                    }
                } else {
                    console.log("missing0");
                }
            }
        });
    }, []);
    useState(() => {
        setData();
    }, [scdata]);
    getData(scdata);
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];

        for (let i = 0; i < titles.length; i++) {
            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    const [unitlist, setUnitList] = useState([]);
    const scheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={() => {
                            setSCVal({
                                DPAN095: null,
                                DPAN096: null,
                                DPAN099: null,
                                DPAN097: "",
                                DPAN098: 0,
                                attachment: null,
                            });
                            setSubmitted(false);

                            setScDialog(true);
                        }}
                    />
                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setScFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span> */}
        </div>
    );
    const saveStationary = () => {
        setSubmitted(true);

        if (
            scVal.DPAN095 !== null &&
            scVal.DPAN096 !== null &&
            scVal.DPAN099 !== null &&
            scVal.DPAN097.trim().length !== 0 &&
            scVal.DPAN098 > 0
        ) {
            if (scVal.id === undefined) {
                scVal["id"] = getID();
                setSCData((prev) => [scVal, ...prev]);
            } else {
                let loc = scdata;

                let index = loc.findIndex((k) => {
                    return k.id === scVal.id;
                });
                loc[index] = scVal;
                setSCData(loc);
            }
            console.log(scVal);
            forceUpdate();
            setSubmitted(false);
            setScDialog(false);
        }

        forceUpdate();
    };
    const importFromExcel = (e, topic) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = [];
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0
                    ) {
                        json.push({
                            [sheet]: XLSX.utils.sheet_to_json(worksheet, {
                                blankrows: false,
                            }),
                        });
                    }
                }

                if (topic === "Electricity") {
                    let local = {};
                    let local2 = scdata;
                    console.log("Electricity");
                    json.forEach((sname, i) => {
                        if (Object.keys(sname)[0] === "S2_EL") {
                            sname[Object.keys(sname)[0]].forEach((items) => {
                                local = {};
                                let index = -1;
                                if (items.type_of_energy !== undefined) {
                                    index = category.findIndex(
                                        (i) =>
                                            i.title.trim().toLowerCase() ===
                                            items.type_of_energy.toString().trim().toLowerCase()
                                    );
                                }
                                console.log(items);
                                if (
                                    items.type_of_energy !== undefined &&
                                    items.type_of_energy.trim().length !== 0 &&
                                    items.source_of_energy !== undefined &&
                                    items.source_of_energy.trim().length !== 0 &&
                                    items.unit !== undefined &&
                                    items.unit.trim().length !== 0 &&
                                    items.energy_procured_from !== undefined &&
                                    items.energy_procured_from.toString().length !== 0 &&
                                    items.qty_used !== undefined &&
                                    parseFloat(items.qty_used.toString()) > 0
                                ) {
                                    const { result, id } = findMatchingIds(
                                        category,
                                        items.type_of_energy.toString(),
                                        items.source_of_energy.toString(),
                                        items.unit.toString()
                                    );
                                    console.log(result, id, items);
                                    if (result) {
                                        local["DPAN095"] = id[0];
                                        local["DPAN096"] = id[1];
                                        local["DPAN099"] = id[2];

                                        local["id"] = getID();
                                        local["attachment"] = null;
                                        local["DPAN098"] = parseFloat(items.qty_used.toString());
                                        local["DPAN097"] = items.energy_procured_from.toString();

                                        local2.push(local);
                                    }
                                }
                            });
                        }
                    });

                    setSCData(local2);
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });
        e.options.clear();
    };
    const updateScVal = (obj, val) => {
        let local = scVal;

        local[obj] = val;
        if (obj === "DPAN095") {
            local["DPAN096"] = null;
            local["DPAN099"] = null;
            let fuel_type = [];
            fuelcatlist.forEach((i) => {
                console.log(i, val);
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === val) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        } else if (obj === "DPAN096") {
            local["DPAN099"] = null;

            let units = [];
            let index = fuelcatlist.findIndex((i) => {
                return i.id === local["DPAN095"];
            });
            fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                if (i.newEfSubcategory3s !== undefined) {
                    if (i.id === val) {
                        units = i.newEfSubcategory3s;
                    }
                }
            });
            setUnitList(units);
            // fuelcategory.forEach((i) => {
            //     if (i.fuel === val.name && i.fuel_type === local['DPA0130'].name) {
            //         if (units.findIndex((k) => { return k.name === i.unit }) === -1) {
            //             units.push({ name: i.unit })
            //         }
            //     }

            // })
        }
        if (obj === "DPAN099") {
            local.DPAN098 = 0;
            local.DPAN097 = "";
        }
        setSCVal(local);

        forceUpdate();
    };
    const editScVal = (item) => {
        let items = JSON.parse(JSON.stringify(item));
        let fuel_type = [],
            units = [];
        if (item.DPAN096 !== null) {
            fuelcatlist.forEach((i) => {
                if (i.newEfSubcategory2s !== undefined) {
                    if (i.id === item.DPAN095) {
                        fuel_type = i.newEfSubcategory2s;
                    }
                }
            });
            setFuelList(fuel_type);
        }
        if (item.DPAN099 !== null) {
            let index = fuelcatlist.findIndex((i) => {
                return i.id === item.DPAN095;
            });
            if (index !== -1 && fuelcatlist[index].newEfSubcategory2s !== undefined) {
                fuelcatlist[index].newEfSubcategory2s.forEach((i) => {
                    if (i.newEfSubcategory3s !== undefined) {
                        if (i.id === item.DPAN096) {
                            units = i.newEfSubcategory3s;
                        }
                    }
                });
            }

            setUnitList(units);
        }
        items.DPAN098 = parseFloat(items.DPAN098);
        items.DPAN097 = items.DPAN097;

        setSCVal(items);
        console.log(items);
        setScDialog(true);
        forceUpdate();
    };
    const deleteRow = (item) => {
        let loc = scdata;
        let index = loc.findIndex((k) => {
            return k.id === item.id;
        });
        loc.splice(index, 1);
        setSCData(loc);
        forceUpdate();
    };
    const scDialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveStationary();
                }}
            />
        </>
    );
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : typeof rowData.attachment === "object" ? (
                    <a
                        onClick={() => {
                            setAttachment(rowData.attachment);
                            setAttDialog(true);
                        }}
                    >
                        View
                    </a>
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >
                        View
                    </a>
                )}
            </>
        );
    };
    const subcategory1template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPAN095;
        });
        if (index !== -1) {
            text = category[index].title;
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory2template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPAN095;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPAN096;
            });
            if (index2 !== -1) {
                text = category[index].newEfSubcategory2s[index2].title;
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const subcategory3template = (rowData) => {
        let text = "Not Found";
        console.log(category);

        let index = category.findIndex((i) => {
            return i.id === rowData.DPAN095;
        });
        if (index !== -1 && category[index].newEfSubcategory2s !== undefined) {
            let index2 = category[index].newEfSubcategory2s.findIndex((i) => {
                return i.id === rowData.DPAN096;
            });
            if (
                index2 !== -1 &&
                category[index].newEfSubcategory2s[index2].newEfSubcategory3s !==
                undefined
            ) {
                let index3 = category[index].newEfSubcategory2s[
                    index2
                ].newEfSubcategory3s.findIndex((i) => {
                    return i.id === rowData.DPAN099;
                });
                if (index3 !== -1) {
                    text =
                        category[index].newEfSubcategory2s[index2].newEfSubcategory3s[
                            index3
                        ].title;
                }
            } else {
                text = "NA";
            }
        } else {
            text = "NA";
        }

        return <>{text}</>;
    };
    const actionBodyTemplateSC = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded hover-navy mr-2"
                        onClick={() => {
                            editScVal(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <div>
            {edit === 1 && (
                <div
                    className="col-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="col-2">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                if (e.target.tagName === "SPAN") {
                                    exportToCSV(
                                        [
                                            {
                                                sno: null,
                                                type_of_energy: "",
                                                source_of_energy: "",
                                                unit: "",
                                                qty_used: 0,
                                                energy_procured_from: "",
                                            },
                                        ],
                                        "S2_EL",
                                        "Electricity"
                                    );
                                }
                            }}
                        >
                            <FileUpload
                                chooseOptions={{
                                    label: "Template",
                                    icon: "pi pi-download",
                                    className: "navy-button",
                                }}
                                mode="basic"
                                name="demo[]"
                                auto
                                customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="mr-2"
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <FileUpload
                            chooseOptions={{
                                label: "Import",
                                icon: "pi pi-file-excel",
                                className: "navy-button",
                            }}
                            mode="basic"
                            name="demo[]"
                            auto
                            customUpload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="mr-2"
                            uploadHandler={(e) => {
                                importFromExcel(e, "Electricity");
                            }}
                        />
                    </div>
                </div>
            )}
            <DataTable
                value={scdata}
                scrollable
                dataKey="id"
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["DPA0130.name", "DPA0131.name", "DPA0132.name"]}
                globalFilter={scfilter}
                emptyMessage="No records found."
                header={edit === 1 && scheader}
                responsiveLayout="scroll"
            >
                <Column
                    body={subcategory1template}
                    header="Type of Energy used"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory2template}
                    header="Source of Energy"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={subcategory3template}
                    header="Unit"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN098"
                    header="Quantity Used"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN097"
                    header="Source from where the energy is procured"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="attachment"
                    header="Attachment"
                    body={refAttachmentTemplate}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                {edit === 1 && <Column header='Action' body={actionBodyTemplateSC}> </Column>}
            </DataTable>
            <Dialog
                visible={scdialog}
                style={{
                    width: "450px",
                }}
                header="Add New"
                modal
                className="p-fluid"
                footer={scDialogFooter}
                onHide={() => {
                    setScDialog(false);
                }}
            >
                <div className="field">
                    <label htmlFor="DPAN095">Type of Energy used</label>
                    <Dropdown
                        value={scVal.DPAN095}
                        onChange={(e) => {
                            updateScVal("DPAN095", e.value);
                        }}
                        optionValue="id"
                        options={fuelcatlist}
                        optionLabel="title"
                        placeholder="Select"
                    />
                    {submitted && scVal.DPAN095 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Type of energy is required
                        </small>
                    )}
                </div>
                {scVal.DPAN095 !== null && (
                    <div className="field">
                        <label htmlFor="DPAN096">Select Source of Energy</label>
                        <Dropdown
                            value={scVal.DPAN096}
                            onChange={(e) => {
                                updateScVal("DPAN096", e.value);
                            }}
                            options={fuellist}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPAN096 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Source of Energy is required
                            </small>
                        )}
                    </div>
                )}
                {scVal.DPAN096 !== null && (
                    <div className="field">
                        <label htmlFor="DPAN099">Select Unit</label>
                        <Dropdown
                            value={scVal.DPAN099}
                            onChange={(e) => {
                                updateScVal("DPAN099", e.value);
                            }}
                            options={unitlist}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && scVal.DPAN099 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >
                                Select Unit
                            </small>
                        )}
                    </div>
                )}
                {scVal.DPAN095 !== null && scVal.DPAN099 !== null && (
                    <>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="gasrefill"> Quantity of Energy used</label>
                                <div id="gasrefill" className="p-inputgroup">
                                    <InputNumber
                                        maxFractionDigits={4}
                                        useGrouping={false}
                                        value={scVal.DPAN098}
                                        onChange={(e) => {
                                            updateScVal("DPAN098", e.value);
                                        }}
                                        placeholder="Quantity"
                                    />
                                </div>
                                {submitted && parseFloat(scVal.DPAN098) <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Quantity is required
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="DPAN097">
                                    Source from where the energy is procured
                                </label>
                                <div id="DPAN097" className="p-inputgroup">
                                    <InputText
                                        type="text"
                                        value={scVal.DPAN097}
                                        onChange={(e) => {
                                            updateScVal("DPAN097", e.target.value);
                                        }}
                                    />
                                </div>
                                {submitted && scVal.DPAN097.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Source from where the energy is required
                                    </small>
                                )}
                            </div>
                        </div>
                    </>
                )}

                <>
                    <FileUpload
                        mode="basic"
                        customUpload
                        chooseLabel={
                            "Add supporting document " +
                            (getUploadedFileSize(scVal.attachment) / 1000000).toFixed(2) +
                            " mb/10mb"
                        }
                        uploadHandler={(files) => {
                            uploadFilesApi(files.files[0], scVal.attachment).then((res) => {
                                if (res !== null) {
                                    let local = scVal;
                                    if (local.attachment === null) {
                                        local.attachment = [res];
                                    } else {
                                        local.attachment.push(res);
                                    }

                                    setSCVal(local);
                                    forceUpdate();
                                } else {
                                    let remainingSize = (
                                        getUploadedFileSize(scVal.attachment) / 1000000
                                    ).toFixed(2);
                                    alert(
                                        remainingSize + " mb only  remaining out of 10 mb limit"
                                    );
                                }
                                files.options.clear();
                            });
                        }}
                        name="demo[]"
                        auto={true}
                        accept="image/*,.pdf"
                        maxFileSize={1000000}
                    />
                    {submitted && scVal.attachment === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >
                            Attachment Required
                        </small>
                    )}
                </>
                {scVal.attachment !== null && (
                    <>
                        {scVal.attachment.map((i, index) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 20,
                                            }}
                                        >
                                            <label style={{ width: "50%" }}>{i.name}</label>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "green",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => {
                                                    window.open(i.url);
                                                }}
                                            >
                                                <i className="material-icons"> visibility </i>
                                            </span>
                                            <span
                                                style={{
                                                    display: "flex",
                                                    width: "25%",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    color: "red",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    scVal.attachment.splice(index, 1);
                                                    if (scVal.attachment.length === 0) {
                                                        scVal.attachment = null;
                                                    }
                                                    forceUpdate();
                                                }}
                                            >
                                                <i className="material-icons"> close </i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}
            </Dialog>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"
                onHide={() => {
                    setAttDialog(false);
                }}
            >
                <div>
                    {attachment.map((i, index) => {
                        return (
                            <>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <label style={{ width: "80%" }}>{i.name}</label>
                                        <span
                                            style={{
                                                display: "flex",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                color: "green",
                                                cursor: "pointer",
                                                alignItems: "center",
                                            }}
                                            onClick={(e) => {
                                                window.open(i.url);
                                            }}
                                        >
                                            <i className="material-icons"> visibility </i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};
const Employee_Category = ({ data, getData, setData, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState([]);
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [
        {
            id: 1,
            title: "Permanent",
            type2: [
                {
                    id: 3,
                    title: "Full Time",
                    type3: [
                        {
                            id: 7,
                            title: "Male",
                            data: [{ DPAN400: null }, { DPAN401: null }, { DPAN402: null }],
                        },
                        {
                            id: 8,
                            title: "Female",
                            data: [{ DPAN403: null }, { DPAN404: null }, { DPAN405: null }],
                        },
                        {
                            id: 9,
                            title: "LGBTQ +",
                            data: [{ DPAN406: null }, { DPAN407: null }, { DPAN408: null }],
                        },
                    ],
                },
                {
                    id: 4,
                    title: "Part-Time",
                    type3: [
                        {
                            id: 10,
                            title: "Male",
                            data: [{ DPAN409: null }, { DPAN410: null }, { DPAN411: null }],
                        },
                        {
                            id: 11,
                            title: "Female",
                            data: [{ DPAN412: null }, { DPAN413: null }, { DPAN414: null }],
                        },
                        {
                            id: 12,
                            title: "LGBTQ +",
                            data: [{ DPAN415: null }, { DPAN416: null }, { DPAN417: null }],
                        },
                    ],
                },
            ],
        },
        {
            title: "Other than Permanent",
            id: 2,
            type2: [
                {
                    id: 5,
                    title: "Temporary",
                    type3: [
                        {
                            id: 13,
                            title: "Male",
                            data: [{ DPAN418: null }, { DPAN419: null }, { DPAN420: null }],
                        },
                        {
                            id: 14,
                            title: "Female",
                            data: [{ DPAN421: null }, { DPAN422: null }, { DPAN423: null }],
                        },
                        {
                            id: 15,
                            title: "LGBTQ +",
                            data: [{ DPAN424: null }, { DPAN425: null }, { DPAN426: null }],
                        },
                    ],
                },
                {
                    id: 6,
                    title: "Non-Guaranteed hours",
                    type3: [
                        {
                            id: 16,
                            title: "Male",
                            data: [{ DPAN427: null }, { DPAN428: null }, { DPAN429: null }],
                        },
                        {
                            id: 17,
                            title: "Female",
                            data: [{ DPAN430: null }, { DPAN431: null }, { DPAN432: null }],
                        },
                        {
                            id: 18,
                            title: "LGBTQ +",
                            data: [{ DPAN433: null }, { DPAN434: null }, { DPAN435: null }],
                        },
                    ],
                },
            ],
        },
    ];

    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [emp]);
    getData(emp);
    useEffect(() => {
        updateVal("type", 1)
    }, [])
    const updateVal = (obj, val_) => {
        console.log(emp);
        let loc = val;
        loc[obj] = val_;
        if (obj === "type") {
            loc.type2 = null;
            loc.type3 = null;
            setObj(null);
            let a = [];
            temp.forEach((i) => {
                if (i.id === val_) {
                    a = i.type2;
                }
            });
            setType(a);
        }
        if (obj === "type2") {
            loc.type3 = null;
            setObj(null);
            let a = [];
            temp.forEach((i) => {
                if (i.id === loc["type"]) {
                    i.type2.forEach((j) => {
                        if (j.id === val_) {
                            a = j.type3;
                        }
                    });
                }
            });
            setType2(a);
        }
        if (obj === "type3") {
            let a = [];
            emp.forEach((i) => {
                if (i.id === loc["type"]) {
                    i.type2.forEach((j) => {

                        j.type3.forEach((k) => {
                            if (k.id === val_) {
                                setObj(k.data);
                                forceUpdate();
                            }
                        });

                    });
                }
            });
        }
        setVal(loc);
        forceUpdate();
    };
    const updateNumbers = (index, value) => {
        console.log(value);
        let loc = JSON.parse(JSON.stringify(emp));
        let loc2 = JSON.parse(JSON.stringify(obj));
        loc.forEach((i) => {
            if (i.id === val.type) {
                i.type2.forEach((j) => {

                    j.type3.forEach((k) => {
                        if (k.id === val.type3) {
                            const key = Object.keys(k.data[index])[0];
                            k.data[index][key] = value;
                        }
                    });

                });
            }
        });
        const key = Object.keys(loc2[index])[0];

        loc2[index][key] = value;
        setObj(loc2);
        setEmp(loc);
        forceUpdate();
    };

    const groupTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <div>{option.title}</div>
            </div>
        );
    };
    return (
        <div>
            {edit === 1 ? (
                <div>
                    <TabMenu model={[{ label: 'Permanent', id: 1 }, { label: 'Other Than Permanent', id: 2 }]} activeIndex={activeindex} onTabChange={(e) => { updateVal("type", e.value.id); setActiveIndex(e.index) }} />
                    {activeindex === 0 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox optionValue="id" value={val.type3} onChange={(e, a) => { console.log(e, a); updateVal("type3", e.value) }} options={type} optionLabel="title"
                                    optionGroupLabel="title" optionGroupChildren="type3" optionGroupTemplate={groupTemplate} className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type3 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>

                    }
                    {activeindex === 1 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox optionValue="id" value={val.type3} onChange={(e, a) => { console.log(e, a); updateVal("type3", e.value) }} options={type} optionLabel="title"
                                    optionGroupLabel="title" optionGroupChildren="type3" optionGroupTemplate={groupTemplate} className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type3 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>

                    }


                    {/* <div className="field">
                        <label className="col-5" htmlFor="DPAN096">
                            Select Employee Category
                        </label>
                        <Dropdown
                            value={val.type}
                            className="col-5"
                            onChange={(e) => {
                                updateVal("type", e.value);
                            }}
                            options={[
                                { title: "Permanent", id: 1 },
                                { title: "Other than Permanent", id: 2 },
                            ]}
                            optionLabel="title"
                            optionValue="id"
                            placeholder="Select"
                        />
                    </div>
                    {val.type !== null && (
                        <div className="field">
                            <label className="col-5" htmlFor="DPAN096">
                                Select Employement Type
                            </label>
                            <Dropdown
                                className="col-5"
                                value={val.type2}
                                onChange={(e) => {
                                    updateVal("type2", e.value);
                                }}
                                options={type}
                                optionLabel="title"
                                optionValue="id"
                                placeholder="Select"
                            />
                        </div>
                    )}
                    {val.type !== null && val.type2 !== null && (
                        <div className="field">
                            <label className="col-5" htmlFor="DPAN096">
                                Select Gender
                            </label>
                            <Dropdown
                                className="col-5"
                                value={val.type3}
                                onChange={(e) => {
                                    updateVal("type3", e.value);
                                }}
                                options={type2}
                                optionLabel="title"
                                optionValue="id"
                                placeholder="Select"
                            />
                        </div>
                    )}
                    {val.type !== null &&
                        val.type2 !== null &&
                        val.type3 !== null &&
                        obj !== null &&
                        obj.map((i, index) => {
                            return (
                                <div>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="gasrefill">
                                                {index === 0
                                                    ? "Under 30 Years"
                                                    : index === 1
                                                        ? "30-50 Years"
                                                        : "Above 50 Years"}
                                            </label>
                                            <div id="gasrefill" className="p-inputgroup col-10">
                                                <InputNumber
                                                    useGrouping={false}
                                                    value={i[Object.keys(i)[0]]}
                                                    onChange={(e) => {
                                                        updateNumbers(index, e.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                         */}
                </div>
            ) : (
                <div className="gridlines-container">
                    <table className="gridlines">
                        <tbody>
                            <tr>
                                <td className="table-h1 text-center" colSpan="18">Permanent</td>

                            </tr>

                            <tr>
                                <td colSpan="9" className="table-h2 text-center">Full Time</td>
                                <td colSpan="9" className="table-h2 text-center">Part Time</td>

                            </tr>
                            <tr>
                                <td colSpan="3" className="table-h3 text-center">Male</td>
                                <td colSpan="3" className="table-h3 text-center">Female</td>
                                <td colSpan="3" className="table-h3 text-center">LGBTQ+</td>
                                <td colSpan="3" className="table-h3 text-center">Male</td>
                                <td colSpan="3" className="table-h3 text-center">Female</td>
                                <td colSpan="3" className="table-h3 text-center">LGBTQ+</td>

                            </tr>
                            <tr>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>


                            </tr>
                            <tr>
                                <td className="table-no">{emp[0].type2[0].type3[0].data[0].DPAN400}</td>
                                <td className="table-no">{emp[0].type2[0].type3[0].data[1].DPAN401}</td>
                                <td className="table-no">{emp[0].type2[0].type3[0].data[2].DPAN402}</td>
                                <td className="table-no">{emp[0].type2[0].type3[1].data[0].DPAN403}</td>
                                <td className="table-no">{emp[0].type2[0].type3[1].data[1].DPAN404}</td>
                                <td className="table-no">{emp[0].type2[0].type3[1].data[2].DPAN405}</td>
                                <td className="table-no">{emp[0].type2[0].type3[2].data[0].DPAN406}</td>
                                <td className="table-no">{emp[0].type2[0].type3[2].data[1].DPAN407}</td>
                                <td className="table-no">{emp[0].type2[0].type3[2].data[2].DPAN408}</td>

                                <td className="table-no">{emp[0].type2[1].type3[0].data[0].DPAN409}</td>
                                <td className="table-no">{emp[0].type2[1].type3[0].data[1].DPAN410}</td>
                                <td className="table-no">{emp[0].type2[1].type3[0].data[2].DPAN411}</td>
                                <td className="table-no">{emp[0].type2[1].type3[1].data[0].DPAN412}</td>
                                <td className="table-no">{emp[0].type2[1].type3[1].data[1].DPAN413}</td>
                                <td className="table-no">{emp[0].type2[1].type3[1].data[2].DPAN414}</td>
                                <td className="table-no">{emp[0].type2[1].type3[2].data[0].DPAN415}</td>
                                <td className="table-no">{emp[0].type2[1].type3[2].data[1].DPAN416}</td>
                                <td className="table-no">{emp[0].type2[1].type3[2].data[2].DPAN417}</td>


                            </tr>
                        </tbody>
                    </table>
                    <table className="gridlines">
                        <tbody>
                            <tr>

                                <td colSpan="18" className="table-h1 text-center">Other than Permanent </td>
                            </tr>

                            <tr>

                                <td colSpan="9" className="table-h2 text-center">Temporary</td>
                                <td colSpan="9" className="table-h2 text-center">Non-Guaranteed hours </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="table-h3 text-center">Male</td>
                                <td colSpan="3" className="table-h3 text-center">Female</td>
                                <td colSpan="3" className="table-h3 text-center">LGBTQ+</td>
                                <td colSpan="3" className="table-h3 text-center">Male</td>
                                <td colSpan="3" className="table-h3 text-center">Female</td>
                                <td colSpan="3" className="table-h3 text-center">LGBTQ+</td>

                            </tr>
                            <tr>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>
                                <td colSpan="1" className="text-center">Under 30 years</td>
                                <td colSpan="1" className="text-center">30-50 Years</td>
                                <td colSpan="1" className="text-center">Above 50 Years</td>


                            </tr>
                            <tr>


                                <td className="table-no">{emp[1].type2[0].type3[0].data[0].DPAN418}</td>
                                <td className="table-no">{emp[1].type2[0].type3[0].data[1].DPAN419}</td>
                                <td className="table-no">{emp[1].type2[0].type3[0].data[2].DPAN420}</td>
                                <td className="table-no">{emp[1].type2[0].type3[1].data[0].DPAN421}</td>
                                <td className="table-no">{emp[1].type2[0].type3[1].data[1].DPAN422}</td>
                                <td className="table-no">{emp[1].type2[0].type3[1].data[2].DPAN423}</td>
                                <td className="table-no">{emp[1].type2[0].type3[2].data[0].DPAN424}</td>
                                <td className="table-no">{emp[1].type2[0].type3[2].data[1].DPAN425}</td>
                                <td className="table-no">{emp[1].type2[0].type3[2].data[2].DPAN426}</td>

                                <td className="table-no">{emp[1].type2[1].type3[0].data[0].DPAN427}</td>
                                <td className="table-no">{emp[1].type2[1].type3[0].data[1].DPAN428}</td>
                                <td className="table-no">{emp[1].type2[1].type3[0].data[2].DPAN429}</td>
                                <td className="table-no">{emp[1].type2[1].type3[1].data[0].DPAN430}</td>
                                <td className="table-no">{emp[1].type2[1].type3[1].data[1].DPAN431}</td>
                                <td className="table-no">{emp[1].type2[1].type3[1].data[2].DPAN432}</td>
                                <td className="table-no">{emp[1].type2[1].type3[2].data[0].DPAN433}</td>
                                <td className="table-no">{emp[1].type2[1].type3[2].data[1].DPAN434}</td>
                                <td className="table-no">{emp[1].type2[1].type3[2].data[2].DPAN435}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
const Diversity_of_Employees = ({ data, getData, setData, edit, standard }) => {
    const [val, setVal] = useState({ type: null, type2: null, type3: null });
    const [type, setType] = useState();
    const [type2, setType2] = useState([]);
    const [obj, setObj] = useState([]);
    const [activeindex, setActiveIndex] = useState(null)
    let temp = [
        {
            id: 1,
            title: "Senior Management",
            type2: [
                { id: 5, title: "Male", data: [{ DPK0032: null }, { DPK0033: null }, { DPK0034: null }] },
                {
                    id: 6,
                    title: "Female",
                    data: [{ DPK0028: null }, { DPK0029: null }, { DPK0030: null }],
                },
                {
                    id: 7,
                    title: "LGBTQ +",
                    data: [{ DPAN436: null }, { DPAN437: null }, { DPAN438: null }],
                },
            ],
        },
        {
            id: 2,
            title: "Middle Management",
            type2: [
                { id: 8, title: "Male", data: [{ DPKN030: null }, { DPKN031: null }, { DPKN032: null }] },
                {
                    id: 9,
                    title: "Female",
                    data: [{ DPK0036: null }, { DPK0037: null }, { DPK0038: null }],
                },
                {
                    id: 10,
                    title: "LGBTQ +",
                    data: [{ DPAN439: null }, { DPAN440: null }, { DPAN441: null }],
                },
            ],
        },
        {
            id: 3,
            title: "Other Management category",
            type2: [
                {
                    id: 11,
                    title: "Male",
                    data: [{ DPKN033: null }, { DPKN034: null }, { DPKN035: null }],
                },
                {
                    id: 12,
                    title: "Female",
                    data: [{ DPK0039: null }, { DPK0040: null }, { DPK0041: null }],
                },
                {
                    id: 13,
                    title: "LGBTQ +",
                    data: [{ DPAN442: null }, { DPAN443: null }, { DPAN444: null }],
                },
            ],
        },
        {
            id: 4,
            title: "Staff",
            type2: [
                {
                    id: 14,
                    title: "Male",
                    data: [{ DPAN445: null }, { DPAN446: null }, { DPAN447: null }],
                },
                {
                    id: 15,
                    title: "Female",
                    data: [{ DPAN448: null }, { DPAN449: null }, { DPAN450: null }],
                },
                {
                    id: 16,
                    title: "LGBTQ +",
                    data: [{ DPAN451: null }, { DPAN452: null }, { DPAN453: null }],
                },
            ],
        },
    ];
    console.log(data)
    const [emp, setEmp] = useState(
        edit === 1
            ? data.length === 0
                ? temp
                : data
            : edit === 0
                ? data.length !== 0
                    ? data
                    : temp
                : temp
    );
    const forceUpdate = useForceUpdate();
    useState(() => {
        setData();
    }, [emp]);
    getData(emp);
    const updateVal = (obj_, val_) => {
        console.log(val_);
        let loc = val;
        loc[obj_] = val_;
        if (obj_ === "type") {
            loc.type2 = null;
            setObj(null);
            let a = [];
            temp.forEach((i) => {
                if (i.id === val_) {
                    a = i.type2;
                }
            });
            setType(a);
        }
        if (obj_ === "type2") {
            let a = [];
            emp.forEach((i) => {
                if (i.id === loc["type"]) {
                    i.type2.forEach((j) => {

                        if (j.id === val_) {
                            console.log(j)
                            setObj(j.data);
                            forceUpdate();
                        }
                    });
                }
            });

        }
        console.log(obj, loc)
        setVal(loc);
        forceUpdate();
    };
    const updateNumbers = (index, value) => {
        console.log(value);
        let loc = JSON.parse(JSON.stringify(emp));
        let loc2 = JSON.parse(JSON.stringify(obj));
        loc.forEach((i) => {
            if (i.id === val.type) {
                i.type2.forEach((j) => {

                    if (j.id === val.type2) {
                        const key = Object.keys(j.data[index])[0];
                        j.data[index][key] = value;
                    }


                });
            }
        });
        const key = Object.keys(loc2[index])[0];

        loc2[index][key] = value;
        setObj(loc2);
        setEmp(loc);
        forceUpdate();
    };
    const groupTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <div>{option.title}</div>
            </div>
        );
    };
    return (
        <div>
            {edit === 1 ? (
                <div>
                    <TabMenu model={[{ label: 'Senior Management', id: 1 }, { label: 'Middle Management', id: 2 }, { label: 'Other Management category', id: 3 }, { label: 'Staff', id: 4 }]} activeIndex={activeindex} onTabChange={(e) => { updateVal("type", e.value.id); setActiveIndex(e.index) }} />
                    {activeindex === 0 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox value={val.type2} onChange={(e) => updateVal("type2", e.value)} options={type} optionLabel="title" optionValue="id" className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type2 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>
                    }
                    {activeindex === 1 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox value={val.type2} onChange={(e) => updateVal("type2", e.value)} optionValue="id" options={type} optionLabel="title" className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type2 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>
                    }
                    {activeindex === 2 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox value={val.type2} onChange={(e) => updateVal("type2", e.value)} optionValue="id" options={type} optionLabel="title" className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type2 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>
                    }
                    {activeindex === 3 &&
                        <div className="col-12 grid">
                            <div className="col-4">
                                <ListBox value={val.type2} onChange={(e) => updateVal("type2", e.value)} optionValue="id" options={type} optionLabel="title" className="w-full md:w-14rem" listStyle={{ maxHeight: '250px' }} />
                            </div>
                            <div className="col-7">
                                {
                                    val.type2 !== null &&
                                    obj !== null &&
                                    obj.map((i, index) => {
                                        return (
                                            <div>
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="gasrefill">
                                                            {index === 0
                                                                ? "Under 30 Years"
                                                                : index === 1
                                                                    ? "30-50 Years"
                                                                    : "Above 50 Years"}
                                                        </label>
                                                        <div id="gasrefill" className="p-inputgroup col-10">
                                                            <InputNumber
                                                                useGrouping={false}
                                                                value={i[Object.keys(i)[0]]}
                                                                onChange={(e) => {
                                                                    updateNumbers(index, e.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        </div>
                    }
                </div>
            ) : (
                <div>
                    <div className="gridlines-container">
                        <table className="gridlines">
                            <tbody>
                                <td className="table-h1 text-center" colSpan="9">Senior Management</td>
                                <td className="table-h1 text-center" colSpan="9"> Middle Management</td>

                                <tr>
                                    <td colSpan="3" className=" text-center">Male</td>
                                    <td colSpan="3" className=" text-center">Female</td>
                                    <td colSpan="3" className=" text-center"> LGBTQ+</td>
                                    <td colSpan="3" className=" text-center">Male</td>
                                    <td colSpan="3" className=" text-center">Female</td>
                                    <td colSpan="3" className=" text-center"> LGBTQ+</td>

                                </tr>

                                <tr>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>


                                </tr>
                                <tr>
                                    <td className="table-no">{emp[0].type2[0].data[0].DPK0032}</td>
                                    <td className="table-no">{emp[0].type2[0].data[1].DPK0033}</td>
                                    <td className="table-no">{emp[0].type2[0].data[2].DPK0034}</td>
                                    <td className="table-no">{emp[0].type2[1].data[0].DPK0028}</td>
                                    <td className="table-no">{emp[0].type2[1].data[1].DPK0029}</td>
                                    <td className="table-no">{emp[0].type2[1].data[2].DPK0030}</td>
                                    <td className="table-no">{emp[0].type2[2].data[0].DPAN436}</td>
                                    <td className="table-no">{emp[0].type2[2].data[1].DPAN437}</td>
                                    <td className="table-no">{emp[0].type2[2].data[2].DPAN438}</td>

                                    <td className="table-no">{emp[1].type2[0].data[0].DPKN030}</td>
                                    <td className="table-no">{emp[1].type2[0].data[1].DPKN031}</td>
                                    <td className="table-no">{emp[1].type2[0].data[2].DPKN032}</td>
                                    <td className="table-no">{emp[1].type2[1].data[0].DPK0036}</td>
                                    <td className="table-no">{emp[1].type2[1].data[1].DPK0037}</td>
                                    <td className="table-no">{emp[1].type2[1].data[2].DPK0038}</td>
                                    <td className="table-no">{emp[1].type2[2].data[0].DPAN439}</td>
                                    <td className="table-no">{emp[1].type2[2].data[1].DPAN440}</td>
                                    <td className="table-no">{emp[1].type2[2].data[2].DPAN441}</td>


                                </tr>
                            </tbody>
                        </table>
                        <table className="gridlines">
                            <tbody>
                                <tr>
                                    <td className="table-h1 text-center" colSpan="9">Other Management category</td>
                                    <td className="table-h1 text-center" colSpan="9"> Staff</td>
                                </tr>

                                <tr>
                                    <td colSpan="3" className=" text-center">Male</td>
                                    <td colSpan="3" className=" text-center">Female</td>
                                    <td colSpan="3" className=" text-center"> LGBTQ+</td>
                                    <td colSpan="3" className=" text-center">Male</td>
                                    <td colSpan="3" className=" text-center">Female</td>
                                    <td colSpan="3" className=" text-center"> LGBTQ+</td>

                                </tr>

                                <tr>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>
                                    <td colSpan="1" className="text-center">Under 30 years</td>
                                    <td colSpan="1" className="text-center">30-50 Years</td>
                                    <td colSpan="1" className="text-center">Above 50 Years</td>


                                </tr>
                                <tr>
                                    <td className="table-no">{emp[2].type2[0].data[0].DPKN033}</td>
                                    <td className="table-no">{emp[2].type2[0].data[1].DPKN034}</td>
                                    <td className="table-no">{emp[2].type2[0].data[2].DPKN035}</td>
                                    <td className="table-no">{emp[2].type2[1].data[0].DPK0039}</td>
                                    <td className="table-no">{emp[2].type2[1].data[1].DPK0040}</td>
                                    <td className="table-no">{emp[2].type2[1].data[2].DPK0041}</td>
                                    <td className="table-no">{emp[2].type2[2].data[0].DPAN442}</td>
                                    <td className="table-no">{emp[2].type2[2].data[1].DPAN443}</td>
                                    <td className="table-no">{emp[2].type2[2].data[2].DPAN444}</td>

                                    <td className="table-no">{emp[3].type2[0].data[0].DPAN445}</td>
                                    <td className="table-no">{emp[3].type2[0].data[1].DPAN446}</td>
                                    <td className="table-no">{emp[3].type2[0].data[2].DPAN447}</td>
                                    <td className="table-no">{emp[3].type2[1].data[0].DPAN448}</td>
                                    <td className="table-no">{emp[3].type2[1].data[1].DPAN449}</td>
                                    <td className="table-no">{emp[3].type2[1].data[2].DPAN450}</td>
                                    <td className="table-no">{emp[3].type2[2].data[0].DPAN451}</td>
                                    <td className="table-no">{emp[3].type2[2].data[1].DPAN452}</td>
                                    <td className="table-no">{emp[3].type2[2].data[2].DPAN453}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};
export {
    Stationary_Combustion,
    Stationary_Combustion_,
    Fugitive,
    Fugitive_,
    Business_Travel,
    Business_Travel_,
    Mobile_Combustion,
    Mobile_Combustion_,
    Purchase_Goods_Services,
    Purchase_Goods_Services_,
    BP8EQ2,
    BP2LQ3,
    BP2LQ5,
    GR2LQ1,
    GR2LQ2,
    GR2LQ3,
    HotelStay,
    Electricity,
    Employee_Category,
    Diversity_of_Employees
};
