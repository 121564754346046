import React, { useEffect, useState, useRef, createRef } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import FormBuilder from "../components/FormBuilder";

import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"
import { IoMdArrowDropright, IoMdCheckboxOutline } from "react-icons/io";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { Button } from "primereact/button";
import { Route, useHistory, useLocation } from "react-router-dom";

import { TabView, TabPanel } from "primereact/tabview";
import { OrderList } from "primereact/orderlist";
import useForceUpdate from "use-force-update";
import { ListBox } from "primereact/listbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import $ from 'jquery'
import axios from "axios";
import IATACodes from "../assets/dummy/IATACodes";
import { AutoComplete as AC } from "primereact/autocomplete";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import readXlsxFile from "read-excel-file";
import diesel from "../assets/dummy/diesel";
import { CascadeSelect } from "primereact/cascadeselect";
import refrig from "../assets/dummy/refrig";
import owned from "../assets/dummy/owned";
import pgas from "../assets/dummy/pgas";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { setRef } from "@fullcalendar/core";
import { SplitButton } from "primereact/splitbutton";
import Swal from "sweetalert2";
import "react-image-crop/dist/ReactCrop.css";
import { API } from "../components/constants/api_url";
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";

import { useSelector } from "react-redux";

import cx from "classnames";

import { render } from "react-dom";
import Axios from "axios";
import { hardcoded } from "./constants/hardcodedid";
import APIServices from "../service/APIService";
import { DateTime } from "luxon";
const google = window.google;

window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");
// (0.79/1000)

const DataCollectionLibrary = () => {
    let dtref = useRef(null)
    // const hardcodeddcf = [10, 11, 36, 15, 16]
    const selector = useSelector((state) => state.user.userdetail)
    const forceUpdate = useForceUpdate()
    const his = useHistory()
    const [formfilter, setFormFilter] = useState('')
    const [formbk, setFormbk] = useState([])
    const [selectedform, setSelectedForm] = useState([])
    const [prevdialog, setPrevDialog] = useState(false);
    const [items, setItems] = useState([])
    const [searchstr, setSearch] = useState('')

    const [adddialog, setAddDialog] = useState(false)
    const [title, setTitle] = useState('')

    const [form, setForm] = useState([])
    const [submitted, setSubmitted] = useState(false)
    useEffect(() => {

        APIServices.get(API.DCF + '?filter=%7B%22where%22%3A%7B%22or%22%3A%5B%7B%22type%22%3Anull%7D,%7B%22type%22%3A1%7D%5D%7D%7D').then((res) => {
            console.log(res.data.reverse());

            setForm(res.data.reverse());
            setFormbk(res.data.reverse())
        })

    }, []);
    const removeHTMLTag = (html) => {
        return html.replace(/(<([^>]+)>)/gi, "")
            .replace(/\n/g, " ")
            .replace(/&nbsp;/g, " ")
    }
    const renderPreview = () => {


        if (prevdialog && selectedform.data1.length !== 0) {

            let data = selectedform.data1


            return data.map((field, ind) => {
                if (field.type === 'paragraph') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">

                            <p>{removeHTMLTag(field.label)}</p>
                        </div>
                    )
                } else if (field.type === 'date') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'date' + ind}>{removeHTMLTag(field.label)}</label>
                            <Calendar disabled showIcon ></Calendar>
                        </div>
                    )
                } else if (field.type === 'text') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'text' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'textarea') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textarea' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputTextarea disabled></InputTextarea>
                        </div>
                    )
                } else if (field.type === 'number') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textno' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText keyfilter={'num'} disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'select') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'select' + ind}>{removeHTMLTag(field.label)}</label>
                            <Dropdown options={field.values} ></Dropdown>
                        </div>
                    )
                } else if (field.type === 'radio-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'radio' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.label}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>
                    )
                } else if (field.type === 'checkbox-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'checkbox' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.value}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>

                    )
                }
            })
        }
    }
    const checkDPStatus = (data) => {
        if (data === null || data === undefined) {
            return { result: false, data: '0/0' }
        } else if (data.length === 0) {
            return { result: false, data: '0/0' }
        } else if (data.length !== 0) {
            let total = 0, count = 0

            data.forEach((item) => {

                if (item.type !== 'paragraph' && item.type !== 'file' && item.type !== 'date') {
                    total = total + 1
                    if (item.name.startsWith('DP')) {
                        count = count + 1
                    }

                }
            })
            return { result: true, data: count + '/' + total }
        } else {
            return { result: false, data: '0/0' }
        }
    }
    const nameTemplate = (rowData) => {
        console.log(rowData.id)
        return (
            <>
                <span style={{ color: !checkDPStatus(rowData.data1).result && 'red' }}> {rowData.title}</span>
            </>
        )
    }

    const dpidStatus = (rowData) => {

        return (
            <>
                <span > {checkDPStatus(rowData.data1).data}</span>
            </>
        )
    }
    const formIDTemplate = (rowData) => {
        return (
            <>
                <span> DCF{rowData.id}</span>
            </>
        )
    }
    const editDCF = (item) => {
        his.push({ pathname: 'edit_dcf', state: item })
    }
    const cloneDCF = async (item) => {
        let newObj = JSON.parse(JSON.stringify(item))
        delete newObj.updated
        delete newObj.id
        newObj.data2 = []
        delete newObj.modifier_id
        newObj.comments = ''
        newObj.suffix = 'RF'
        newObj.created = moment.utc()
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to clone "${item.title}" from DCF to RF Library
  </div>`,

            confirmButtonColor: 'green',
            showCancelButton: true,

            confirmButtonText:
                'Clone',

        })
        if (accept) {
            APIServices.post(API.RF, newObj).then((res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Data Cloned Successfully",
                            showConfirmButton: false,
                            timer: 1000,
                        });
                    })



                }
            })
        }
    }
    const search = (event) => {
        setSearch(event)
        let _items = formbk.filter((k) => { return (k.title.trim().toLowerCase().includes(event.trim().toLowerCase()) || k.id.toString().includes(event.trim())) })
        setForm(_items);
    }
    const deleteDCF = async (item) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete "${item.title}" from DCF Library
          </div>`,
            input: 'password',
            inputLabel: 'Password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,
            inputAttributes: {
                maxlength: 10,
                autocapitalize: 'off',
                autocorrect: 'off'
            }
        })

        if (password === 'vadapooche') {
            APIServices.delete(API.DCF + '/' + item.id).then((a) => {
                let loc = form;
                loc.splice(form.findIndex((k) => { return k.id === item.id }), 1);
                setFormbk(loc)
                setForm(loc)
                forceUpdate()
            })
        }

    }
    const actionBodyTemplate = (rowData) => {
        console.log(rowData.data1 === null && rowData)
        return (
            (!hardcoded.dcf.includes(rowData.id.toString()) && !hardcoded.dcf2.includes(rowData.id.toString()))  ?
                <>
                    <div className="actions">
                        <Button
                            icon="pi pi-pencil"
                            className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'dodgerblue' }}
                            onClick={() => {
                                editDCF(rowData)
                            }}
                        />
                        {rowData.data1.length !== 0 && <>
                            <Button
                                icon="pi pi-copy"
                                className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'gray' }}
                                onClick={() => {
                                    cloneDCF(rowData)
                                }}
                            />
                            <Button
                                icon="pi pi-eye"
                                className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'mediumseagreen' }}
                                onClick={() => {
                                    window.open(window.location.origin + '/#/dcf_preview/' + rowData.id)
                                }}
                            />
                        </>}
                        <Button
                            icon="pi pi-trash"
                            className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'palevioletred' }}
                            onClick={() => {
                                deleteDCF(rowData)
                            }}
                        />
                    </div>
                </>
                :
                <>
                    Disabled for Hardcoded Forms
                </>
        )
    }
    const prevDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setPrevDialog(false) }} />
        </>
    );
    const submitFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setAddDialog(false);
                }}
            />
            <Button
                label="Save & Exit"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    if (title.trim().length !== 0) {
                        addNewDCF()
                    }
                }}
            />
        </>
    );
    const addNewDCF = () => {
        APIServices.post(API.DCF, { title: title.trim(), type: 1, data1: [], suffix: "DCF", curator_id: selector.id, comments: "", created: moment.utc() }).then((res) => {
            let loc = JSON.parse(JSON.stringify(form))
            loc.unshift(res.data)
            setAddDialog(false)
            setForm(loc)
            setFormbk(loc)
            forceUpdate()

        })
    }
    return (
        <div className="grid p-fluid">
            {selector.role === "eisqradmin" ? (
                <div className="col-12 md:col-12">
                    <div className="card ">
                        <div
                            className="col-12 md:col-12"
                            style={{
                                textDecoration: "underline",
                                textAlign: "center",
                                textDecorationColor: "green",
                            }}
                        >
                            <h5>Data Collection Form Library</h5>

                        </div>
                        <div className="flex grid col-12 justify-content-between" style={{ marginBottom: 20, marginTop: 20 }}>
                            <div style={{ width: 400 }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search DCF" value={searchstr} onChange={(e) => { search(e.target.value) }} />
                                </span>
                            </div>
                            <Button
                                icon="pi pi-plus"
                                className="mr-2 "
                                onClick={() => {
                                    setTitle('')
                                    setAddDialog(true)
                                }}
                            ></Button>
                        </div>
                        <DataTable

                            ref={dtref}
                            value={form}
                            scrollable
                            paginator
                            rows={20}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} DCF"

                            emptyMessage="No DCF found."

                            responsiveLayout="scroll"
                        >


                            <Column
                                field="title"
                                header="Form Name"
                                body={nameTemplate}
                                sortable
                                headerStyle={{
                                    width: "55%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >

                            </Column>

                            <Column
                                field="id"
                                header="ID"
                                body={formIDTemplate}
                                sortable
                                headerStyle={{
                                    width: "10%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column
                                field="id"
                                header="DP ID Status"
                                body={dpidStatus}
                                sortable
                                headerStyle={{
                                    width: "5%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column
                                field="created"
                                header="Created On"
                                body={(rowData) => { return (<>{DateTime.fromISO(rowData.created, { zone: 'utc' }).toLocal().toFormat('yyyy LLL dd HH mm')}</>) }}
                                headerStyle={{
                                    width: "5%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >
                            </Column>
                            <Column
                                field="updated"
                                header="Last Modified"
                                body={(rowData) => { return (<>{moment(rowData.updated).local().format('DD MMM YYYY, hh:mm A')}</>) }}
                                headerStyle={{
                                    width: "5%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column header="Action" headerStyle={{
                                background: '#EEF2FF', width: '15%'
                            }} body={actionBodyTemplate}> </Column>
                        </DataTable>
                        <Dialog
                            visible={adddialog}
                            header={'Add New DCF'}
                            modal
                            style={{ width: '60%' }}
                            footer={submitFooter}
                            onHide={() => {
                                setAddDialog(false);
                            }}
                        >
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label className="m-2" htmlFor="username">DCF Title</label>
                                    <InputText id="username" className="m-2" aria-describedby="username-help" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                                    <small id="username-help" className="m-2" style={{ color: 'red' }}>
                                        {title.trim().length === 0 && 'please enter title '}
                                    </small>
                                </div>
                            </div>

                        </Dialog>
                    </div>
                </div>

            ) :
                <div className="card col-12">You have no rights to access this page</div>

            }
            <Dialog
                visible={prevdialog}
                style={{
                    width: "450px",
                }}
                header={selectedform.title + " preview"}
                modal
                className="p-fluid"
                footer={prevDialogFooter}
                onHide={() => { setPrevDialog(false) }}
            >
                {renderPreview()}
            </Dialog>
        </div>)
}
export default DataCollectionLibrary