import axios from 'axios';


let indicators = [
    {
        id: 'E1.1', action: 'Switch to renewable energy in VLR3 locations by 2023', status: 'Panels installed. Work underway. All on target', indicator: 3
    }, {
        id: 'E1.2', action: 'Refrigerants with higher GWP are to be changed to refrigerants with lower GWP in all units', status: 'In progress. Delays expected', indicator: 1
    }, {
        id: 'E1.3', action: 'Replacing CFLs with energy-efficient LED lights in Indian units.', status: 'Partially implemented', indicator: 2
    }
]

export class ProductService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }
    getIndicator() {
        return indicators
    }
    getProducts() {
        return axios.get('assets/demo/data/products.json').then(res => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}



