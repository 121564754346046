import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../components/constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { Stationary_Combustion, Fugitive, Business_Travel, Mobile_Combustion, Purchase_Goods_Services, BP8EQ2, BP2LQ3, BP2LQ5, Stationary_Combustion_, Fugitive_, Mobile_Combustion_, Purchase_Goods_Services_, GR2LQ1, GR2LQ2, GR2LQ3, Business_Travel_, HotelStay, Electricity } from "./hardcodedforms";
import { Editor } from "primereact/editor";
import { hardcoded } from "./constants/hardcodedid";
import APIServices from "../service/APIService";
window.jQuery = $;
window.$ = $;

const DCFInputEntry = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [data, setData] = useState([])
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const { id } = useParams();
    const params = useLocation()
    // const hardcodeddcf = ['10', '11', '36', '15', '16', '188', '195', '196','245']
    useEffect(async () => {


        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let site_url = API.LocationOne_UP(params.state.company_id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;

        APIServices.get(API.DCF_Edit(id)).then((res) => {
            // res.data.standard = params.state.standard
            if (params.state.id === undefined) {
                console.log(res.data)
                setData(res.data)
            } else {
                console.log(res.data)

                res.data.data1 = params.state.response
                console.log(params.state.response)
                setData(res.data)

            }

        })



        APIServices.get(site_url).then((res) => {
            let site_list = []
            res.data.forEach((country) => {
                if (country.locationTwos !== undefined) {
                    country.locationTwos.forEach((city) => {
                        if (city.locationThrees !== undefined) {
                            city.locationThrees.forEach((site) => {

                                site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                            })
                        }
                    })
                }
            })
            setSiteList(site_list)
        })

    }, [selector]);

    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        if (nan !== undefined) {
            if (isNaN(val)) {
                item.value = undefined
            } else {
                item.value = val;
            }

        } else {
            item.value = val;
        }

        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ flexDirection: 'column', marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    {item.values.map((cb, cbind) => {
                        return (
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                            </div>
                        )
                    })

                    }


                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar style={{ margin: 10 }} value={item.value !== null ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <InputText type='number' keyfilter="num" style={{ margin: 10 }} value={item.value} onChange={(e) => { onNumberChange(item, parseFloat(e.target.value), 'as') }} />
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                    <label style={{ margin: 10 }}>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    {item.values.map((cb, cbind) => {
                        return (
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <RadioButton inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                            </div>
                        )
                    })

                    }


                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ flexDirection: 'column', marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Dropdown options={item.values} style={{ width: '25%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <InputText style={{ margin: 10 }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div style={{ width: '100%', margin: 10 }}>
                        <Editor value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 'auto', overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-12" style={{
                        border: '1px solid #6366F1',
                        borderRadius: '10px'
                    }}>
                        <div style={{
                            background: '#6366F110',

                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} style={{
                                marginRight: 10,
                                padding: '5px',
                                fontSize: '15px',
                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',
                                color: 'cornflowerblue'
                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>
                        {item.value !== undefined && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: '300px',
                                overflow: 'scroll'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {
                                        console.log(file)
                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 10
                                                }} >
                                                    <div className="flex align-items-center" style={{ width: '50%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '25%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()

            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }

    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }
                }
            } else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'file' && item.required) {
                if (item.value !== undefined && item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            } else if (item.type === 'file') {
                item['value'] = []
            }


        })


        return data_
    }

    const saveDCF = () => {
        let newObj = {}
        if (checkResponse()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site[0]
            newObj['reporting_period'] = getRP(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 1
            newObj['edit'] = 0
            newObj['form_type'] = 1
            newObj['data1'] = []
            newObj['reject'] = 0
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id
            if (params.state.id === undefined) {
                newObj['created_on'] = moment.utc()

                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = moment.utc()
                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const checkResponse_ = () => {
        console.log(data)
        if (data.data1.length === 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Data set is Empty, requires minimum 1 record to submit/draft",
                showConfirmButton: false,
                timer: 1500,
            });
            return false
        } else {

            return true
        }
    }
    const saveDCF_ = () => {
        let newObj = {}
        if (checkResponse_()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site[0]
            newObj['reporting_period'] = getRP(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 1
            newObj['form_type'] = 1
            newObj['edit'] = 0
            newObj['data1'] = []
            newObj['reject'] = 0
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['created_on'] = moment.utc()

                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = moment.utc()
                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const draftDCF = () => {
        let newObj = {}
        if (checkResponse()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site[0]
            newObj['reporting_period'] = getRP(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['edit'] = 0
            newObj['form_type'] = 1
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['reject'] = 0

            newObj['submitted_by'] = selector.id
            if (params.state.id === undefined) {
                newObj['created_on'] = moment.utc()

                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = moment.utc()
                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const draftDCF_HC = () => {
        let newObj = {}
        if (checkResponse_()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site[0]
            newObj['reporting_period'] = getRP(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['edit'] = 0
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['form_type'] = 1
            newObj['reject'] = 0
            newObj['submitted_by'] = selector.id
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['created_on'] = moment.utc()

                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = moment.utc()
                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const emptyDCF = async () => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to submit data as null/empty</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Nothing to report',

        })
        if (accept) {
            let newObj = {}
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site[0]
            newObj['reporting_period'] = getRP(params.state.rp)
            newObj['response'] = makeEmpty()
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 1
            newObj['edit'] = 1
            newObj['data1'] = []
            newObj['form_type'] = 1
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id
            if (params.state.id === undefined) {
                newObj['created_on'] = moment.utc()

                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data submitted as null report",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = moment.utc()
                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }
        }
    }
    const emptyDCF_ = async () => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to submit data as null/empty</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Nothing to report',

        })
        if (accept) {
            let newObj = {}
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site[0]
            newObj['reporting_period'] = getRP(params.state.rp)
            newObj['response'] = []
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 1
            newObj['edit'] = 1
            newObj['data1'] = []
            newObj['form_type'] = 1
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id
            if (params.state.id === undefined) {
                newObj['created_on'] = moment.utc()

                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data submitted as null report",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = moment.utc()
                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }
        }
    }
    const checkHardcoded = () => {
        if (hardcoded.dcf.includes(id)) {
            return true
        } else {
            return false
        }

    }
    return (
        <div className="grid" style={{ margin: 20, marginTop: 90 }}>
            <div className="col-12">
                {(selector.id !== undefined && data.length !== 0) ?
                    <div className="card">

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <h4>{data.title}</h4>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>    <span style={{ fontWeight: 'bold' }}>Reporting Period :&nbsp;</span> {params.state.rp}   </div>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>        <span style={{ fontWeight: 'bold' }}>Site :&nbsp;</span> {params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site[0] })[0].name}  </div>

                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>          <span style={{ fontWeight: 'bold' }}>Version :&nbsp;</span> {moment(data.updated).local().format('DD MMM YYYY, hh:mm A')}  </div>
                            </div>

                        </div>
                        {data.comments.trim().length !== 0 &&
                            <div className="p-card" style={{ padding: 10, marginBottom: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>Note :&nbsp;</span> {data.comments}
                            </div>}

                        {data.data1.length !== 0 && !checkHardcoded() ?
                            <div>
                                <div>
                                    {data.data1.map((item, index) => {

                                        return renderItems(item, index)
                                    })

                                    }
                                </div>
                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                    {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF() }} ></Button>}

                                    <Button label='Save as draft' onClick={() => { draftDCF() }} ></Button>
                                    {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF() }}></Button>}

                                </div>


                            </div>
                            :
                            (id === '10' && selector.clientId === 17) ?
                                <div>
                                    <Fugitive_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                        {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                        <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                        {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                    </div>
                                </div> : (id === '10' && selector.clientId !== 17) ?
                                    <div>
                                        <Fugitive data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                            {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                            <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                            {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                        </div>
                                    </div>
                                    :
                                    (id === '11' && selector.clientId === 17) ?
                                        <div>
                                            <Stationary_Combustion_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                            </div>
                                        </div> : (id === '11' && selector.clientId !== 17) ?
                                            <div>
                                                <Stationary_Combustion data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                    {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                    <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                    {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                </div>
                                            </div>
                                            :
                                            (id === '36' && selector.clientId === 17) ?
                                                <div>
                                                    < Business_Travel_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                        {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                        <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                        {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                    </div>
                                                </div>
                                                : (id === '36' && selector.clientId !== 17) ?
                                                    <div>
                                                        <Business_Travel data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                            {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                            <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                            {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                        </div>
                                                    </div>
                                                    :
                                                    (id === '15' && selector.clientId === 17) ?
                                                        <div>
                                                            <Mobile_Combustion_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                            </div>
                                                        </div>
                                                        : (id === '15' && selector.clientId !== 17) ?
                                                            <div>
                                                                <Mobile_Combustion data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                    {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                    <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                    {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                </div>
                                                            </div>
                                                            :
                                                            (id === '16' && selector.clientId === 17) ?
                                                                <div>
                                                                    <Purchase_Goods_Services_ standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                        {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                        <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                        {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                    </div>
                                                                </div> : (id === '16' && selector.clientId !== 17) ?
                                                                    <div>
                                                                        <Purchase_Goods_Services data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                            {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                            <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                            {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                        </div>
                                                                    </div>
                                                                    : id === '188' ?
                                                                        <div>
                                                                            <BP8EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                                {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                                <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                                {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                            </div>
                                                                        </div>
                                                                        : id === '195' ?
                                                                            <div>
                                                                                <BP2LQ3 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                                    {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                                    <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                                    {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                                </div>
                                                                            </div>
                                                                            : id === '196' ?
                                                                                <div>
                                                                                    <BP2LQ5 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                                        {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                                        <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                                        {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                                    </div>
                                                                                </div>
                                                                                : id === '245' ?
                                                                                    <div>
                                                                                        <GR2LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                                            {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                                            <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                                            {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                                        </div>
                                                                                    </div>
                                                                                    : id === '246' ?
                                                                                        <div>
                                                                                            <GR2LQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                                                {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                                                <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                                                {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                                            </div>
                                                                                        </div>
                                                                                        : id === '247' ?
                                                                                            <div>
                                                                                                <GR2LQ3 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                                                    {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                                                    <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                                                    {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                                                </div>
                                                                                            </div>
                                                                                            : id === '254' ?
                                                                                                <div>
                                                                                                    <HotelStay standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                                                        {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                                                        <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                                                        {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                                                    </div>
                                                                                                </div>
                                                                                                : id === '257' ?
                                                                                                    <div>
                                                                                                        <Electricity standard={1} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                                                                            {params.state.future === undefined && <Button label='Nothing to report' onClick={() => { emptyDCF_() }} ></Button>}

                                                                                                            <Button label='Save as draft' onClick={() => { draftDCF_HC() }} ></Button>
                                                                                                            {params.state.future === undefined && <Button label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    'Contact admin'
                        }




                    </div>
                    :
                    <div className="col-12 card">Form not found</div>
                    // <div className="col-12 card">You have no rights to access this page </div>

                }
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DCFInputEntry, comparisonFn);
