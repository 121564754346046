import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import Axios from "axios";
import { API } from "../../../../components/constants/api_url";
import APIServices from "../../../../service/APIService";
import '../../../reports/toc_style.css';
import { useHeadsObserver } from "../../hooks";
const { DateTime } = require('luxon');

const BRSR_23 = (props) => {
    const [headings, setHeadings] = useState([]);
    const { activeId } = useHeadsObserver();
    const [tableData, setTableData] = useState([]);
    const [workbook, setWorkbook] = useState(null);
    const [firstSheet, setFirstSheet] = useState(null);
    const [year, setYear] = useState(props.year);
    const [rfData, setRFData] = useState({});
    const selector = useSelector((state) => state.user.userdetail);
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    const locationList = useSelector(state => state.sitelist.locationList)
    const siteList = useSelector((state) => state.sitelist.siteList)
    const clientlogo = useSelector((state) => state.user.companylogo)
    const rflibrary = useSelector((state) => state.library.rf)
    const [dcflist, setDcfList] = useState([])
    const [response, setResponse] = useState([])
    const [report, setReport] = useState({})
    const [dpreport, setDpReport] = useState([])
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const forceUpdate = useForceUpdate();
    // RF Dropdowns
    const QLBP1E2D1Options = [{ name: 'Monetary', id: 1 }, { name: 'Non- Monetary', id: 2 }]
    const QLBP1E2D2Options = [{ name: 'Penalty or Fine', id: 1 }, { name: 'Settlement', id: 2 }, { name: 'Compounding fee', id: 3 }]
    const QLBP1E2D8Options = [{ name: 'Imprisonment', id: 1 }, { name: 'Punishment', id: 2 }]
    const QLBP1E2D4Options = [{ name: 'Regulatory', id: 1 }, { name: 'Enforcement agencies', id: 2 }, { name: 'Judicial institutions', id: 3 }, { name: 'NA', id: 4 }]
    // 111
    const QLBP4E2D3Options = [{ name: 'Email', id: 1 }, { name: 'SMS', id: 2 }, { name: 'Newspaper', id: 3 }, { name: 'Pamphlets', id: 4 }, { name: 'Advertisement', id: 5 }, { name: 'Community Meetings', id: 6 }, { name: 'Notice Board', id: 7 }, { name: 'Website', id: 8 }, { name: 'Other', id: 9 }]
    const QLBP4E2D4Options = [{ name: 'Annually', id: 1 }, { name: 'Half yearly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Others', id: 4 }]
    // 121
    const QLBP7E1D1AOptions = [{ name: 'Chambers', id: 1 }, { name: 'Associations', id: 2 }]
    // 113
    const QLBP7L1D4Options = [{ name: 'Annually', id: 1 }, { name: 'Half yearly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Others', id: 4 }]

    const handleNaNAndInfinity = (value) => {
        if (typeof value === 'number' && isFinite(value)) {
            return value.toFixed(0); // Return the original value if it's a valid number
        } else {
            return 0; // Return 0 for NaN or Infinity
        }
    }

    function transformArrayToObject(data, keyParam, valueParam) {
        const result = {};

        for (const item of data) {
            const key = item[keyParam];
            const value = item[valueParam];

            result[key] = typeof value === 'object' ? { ...value } : value;
        }

        return result;
    }
    const getManualDCF188 = (id, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let empty = [{
            "DPS0184": 'NA',
            "DPS0185": 'NA',
            "DPS0186": 'NA',
            "DPS0187": '-',
            "DPK0183": '-',
            "DPS0190": '-'
        }]
        let response_ = []
        let groupedDP = groupArrayObject(dpreport_.filter((i) => { return i.dcfId === id && checkYear(i.rp, yr) !== 0 }), 'submitId')
        if (Object.keys(groupedDP).length !== 0) {



            Object.keys(groupedDP).forEach((key) => {
                let formgroup = groupArrayObject(groupedDP[key], 'form_id')
                Object.keys(formgroup).forEach((key2) => {

                    let loc = transformArrayToObject(formgroup[key2], 'dpId', 'value')
                    response_.push(loc)
                })
            })

        }

        return response_.length === 0 ? empty : response_
    }
    const getManualDCF195 = (id, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let empty = [{
            DPBP2LQ3D1: 'NA',
            "a": 'NA',
            "b": 'NA'
        }]
        let response_ = []
        let groupedDP = groupArrayObject(dpreport_.filter((i) => { return i.dcfId === id && checkYear(i.rp, yr) !== 0 }), 'submitId')
        if (Object.keys(groupedDP).length !== 0) {



            Object.keys(groupedDP).forEach((key) => {
                let formgroup = groupArrayObject(groupedDP[key], 'form_id')
                Object.keys(formgroup).forEach((key2) => {

                    let loc = transformArrayToObject(formgroup[key2], 'dpId', 'value')
                    response_.push(loc)
                })
            })

        }
        let response2_ = []
        let groupedDP2 = groupArrayObject(dpreport_.filter((i) => { return i.dcfId === id && checkYear(i.rp, yr - 1) !== 0 }), 'submitId')
        if (Object.keys(groupedDP2).length !== 0) {



            Object.keys(groupedDP2).forEach((key) => {
                let formgroup = groupArrayObject(groupedDP2[key], 'form_id')
                Object.keys(formgroup).forEach((key2) => {

                    let loc = transformArrayToObject(formgroup[key2], 'dpId', 'value')
                    response2_.push(loc)
                })
            })

        }

        let result = mergeArrays(response_, response2_, "DPBP2LQ3D1", "DPBP2LQ3D2", "DPBP2LQ3D3")
        console.log(response_, response2_)
        return result.length === 0 ? empty : result
    }
    const getManualDCF196 = (id, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let empty = [{
            DPBP2LQ5D1: 'NA',
            "a": 'NA',
            "b": 'NA'
        }]
        let response_ = []
        let groupedDP = groupArrayObject(dpreport_.filter((i) => { return i.dcfId === id && checkYear(i.rp, yr) !== 0 }), 'submitId')
        if (Object.keys(groupedDP).length !== 0) {



            Object.keys(groupedDP).forEach((key) => {
                let formgroup = groupArrayObject(groupedDP[key], 'form_id')
                Object.keys(formgroup).forEach((key2) => {

                    let loc = transformArrayToObject(formgroup[key2], 'dpId', 'value')
                    response_.push(loc)
                })
            })

        }
        let response2_ = []


        let result = mergeArrays(response_, response2_, "DPBP2LQ5D1", "DPBP2LQ5D2", "DPBP2LQ5D3")

        return result.length === 0 ? empty : result
    }
    function mergeArrays(array1, array2, key1, key2, key3) {
        // Calculate "a" for array1
        const updatedArray1 = array1.map(item => ({
            [key1]: item[key1],
            a: item[key2] !== 0 ? ((item[key3] / item[key2]) * 100).toFixed(2) || 0 : 0
        }));

        // Calculate "b" for array2
        const updatedArray2 = array2.map(item => ({
            [key1]: item[key1],
            b: item[key2] !== 0 ? ((item[key3] / item[key2]) * 100).toFixed(2) || 0 : 0
        }));

        // Merge arrays based on key1
        const mergedArray = updatedArray1.map(item1 => {
            const matchingItem2 = updatedArray2.find(item2 => item2[key1] === item1[key1]) || { b: 0 };
            return { ...item1, ...matchingItem2 };
        });

        // Add missing items from array2 with "a" as 0
        const missingItems = updatedArray2.filter(item2 => !mergedArray.some(item => item[key1] === item2[key1]));
        const updatedMergedArray = [...mergedArray, ...missingItems.map(item2 => ({ [key1]: item2[key1], a: 0, ...item2 }))];

        return updatedMergedArray;
    }
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const getLastResponseRFID104 = () => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 104);
        let empty_1 = [{
            "QLBP1E2D1": 'NA',
            "type": 1,
            "QLBP1E2D2": 1,
            "option": 'NA',
            "QLBP1E2D3": "NA",
            "QLBP1E2D4": 'NA',
            "QLBP1E2D5": "NA",
            "QLBP1E2D6": 'NA',
            "QLBP1E2D7": "NA",
            "QLBP1E2D9": null,
            "QLBP1E3D1": "NA"
        }, {
            "QLBP1E2D1": 'NA',
            "type": 1,
            "QLBP1E2D2": 2,
            "option": 'NA',
            "QLBP1E2D3": "NA",
            "QLBP1E2D4": 'NA',
            "QLBP1E2D5": "NA",
            "QLBP1E2D6": 'NA',
            "QLBP1E2D7": "NA",
            "QLBP1E2D9": null,
            "QLBP1E3D1": "NA"
        }, {
            "QLBP1E2D1": 'NA',
            "type": 1,
            "QLBP1E2D2": 3,
            "option": 'NA',
            "QLBP1E2D3": "NA",
            "QLBP1E2D4": 'NA',
            "QLBP1E2D5": "NA",
            "QLBP1E2D6": 'NA',
            "QLBP1E2D7": "NA",
            "QLBP1E2D9": null,
            "QLBP1E3D1": "NA"
        }]
        let empty_2 = [
            {
                "QLBP1E2D1": 'NA',
                "option": 'NA',
                "type": 2,
                "QLBP1E2D8": 1,
                "QLBP1E2D3": "NA",
                "QLBP1E2D4": 'NA',
                "QLBP1E2D5": "NA",
                "QLBP1E2D6": 'NA',
                "QLBP1E2D7": "NA",
                "QLBP1E2D9": null,
                "QLBP1E3D1": "NA"
            },
            {
                "QLBP1E2D1": 'NA',
                "option": 'NA',
                "type": 2,
                "QLBP1E2D8": 2,
                "QLBP1E2D3": "NA",
                "QLBP1E2D4": 'NA',
                "QLBP1E2D5": "NA",
                "QLBP1E2D6": 'NA',
                "QLBP1E2D7": "NA",
                "QLBP1E2D9": null,
                "QLBP1E3D1": "NA"
            }
        ]
        if (result) {
            let response_ = result[0].response.sort((a, b) => { return a.QLBP1E2D1 - b.QLBP1E2D1 })
            response_.forEach((i) => {
                if (i.QLBP1E2D1 === 1) {
                    i.type = 1
                    i.option = QLBP1E2D2Options.find((k) => { return k.id === i.QLBP1E2D2 }).name
                } else {
                    i.type = 2
                    i.option = QLBP1E2D8Options.find((k) => { return k.id === i.QLBP1E2D8 }).name
                }
                i.QLBP1E2D4 = QLBP1E2D4Options.find((k) => { return k.id === i.QLBP1E2D4 }).name
                i.QLBP1E2D1 = QLBP1E2D1Options.find((k) => { return k.id === i.QLBP1E2D1 }).name

            })
            if (response_.findIndex((l) => { return l.type === 1 }) === -1 && response_.findIndex((l) => { return l.type === 2 }) === -1) {
                return [...empty_1, ...empty_2];
            } else if (response_.findIndex((l) => { return l.type === 1 }) === -1) {
                response_.splice(0, 0, ...empty_1)
            } else if (response_.findIndex((l) => { return l.type === 2 }) === -1) {
                response_.splice(response_.length, 0, ...empty_2)
            }

            return response_
        }
        return [...empty_1, ...empty_2];
    }
    const getLastResponseRF85 = () => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 85);
        let empty_1 = [{
            QL14DP1: 'NA',
            QL14DP2: 'NA',
            QL14DP3: 'NA',
        }
        ]

        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF111 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 111);
        let empty_1 = [
            {
                QLBP4E2D1: 'NA',
                QLBP4E2D2: 'NA',
                QLBP4E2D3: 'NA',
                QLBP4E2D4: 'NA',
                QLBP4E2D5: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            console.log(response_)
            response_.forEach((k) => {
                k.QLBP4E2D2 = k.QLBP4E2D2 === true ? 'Yes' : 'No'
                k.QLBP4E2D3 = QLBP4E2D3Options.find((l) => { return l.id === k.QLBP4E2D3 }).name
                k.QLBP4E2D4 = k.QLBP4E2D4 === 4 ? k.QLBP4E2D4B : QLBP4E2D4Options.find((l) => { return l.id === k.QLBP4E2D4 }).name
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF121 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 121);
        let empty_1 = [
            {
                QLBP7E1D1A: 'NA',
                QLBP7E1D1B: 'NA',
                QLBP7E1D2: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP7E1D1A = QLBP7E1D1AOptions.find((l) => { return l.id === k.QLBP7E1D1A }).name
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF113 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 113);
        let empty_1 = [
            {
                QLBP7L1D1: 'NA',
                QLBP7L1D2: 'NA',
                QLBP7L1D3: 'NA',
                QLBP7L1D4B: 'NA',
                QLBP7L1D4: 'NA',
                QLBP7L1D5: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP7L1D3 = k.QLBP7L1D3 === true ? 'Yes' : 'No'
                k.QLBP7L1D4 = k.QLBP7L1D4 === 4 ? k.QLBP7L1D4B : QLBP7L1D4Options.find((l) => { return l.id === k.QLBP7L1D4B }).name
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF102 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 102);
        let empty_1 = [
            {
                QL21DP1A: 'NA',
                QL21DP1B: 'NA',
                QL21DP2: 'NA',
                QL21DP3: 'NA',


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QL21DP3 = k.QL21DP3 === true ? 'Yes' : 'No'
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF112 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 112);
        let empty_1 = [
            {
                QLBP7E2D1: 'NA',
                QLBP7E2D2: 'NA',
                QLBP7E2D3: 'NA',


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF89 = () => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 89);
        let empty_1 = [{
            QL15DP1A: 'NA',
            QL15DP1B: 'NA',
            QL15DP2: 'NA',
            QL15DP3: 'NA',
        }
        ]

        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF110 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 110);
        const QLBG24D2Options = [{ name: 'Risk', id: 1 }, { name: 'Opportunity', id: 2 }]
        const QLBG24D5Options = [{ name: 'Positive', id: 1 }, { name: 'Negative', id: 2 }]

        let empty_1 = [
            {
                QLBG24D1: 'NA',
                QLBG24D2: 'NA',
                QLBG24D3: 'NA',
                QLBG24D4: 'NA',
                QLBG24D5: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {

                k.QLBG24D2 = QLBG24D2Options.find((l) => { return l.id === k.QLBG24D2 }).name

                k.QLBG24D5 = QLBG24D5Options.find((l) => { return l.id === k.QLBG24D5 }).name


            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF114 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 114);
        let empty_1 = [
            {
                QLBP8L1D1: 'NA',
                QLBP8L1D2: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF115 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 115);
        let empty_1 = [
            {
                QLBP8L2D1: 'NA',
                QLBP8L2D2: 'NA',
                QLBP8L2D3: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF116 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 116);
        let empty_1 = [
            {
                QLBP8L4D1: 'NA',
                QLBP8L4D2: 'NA',
                QLBP8L4D3: 'NA',
                QLBP8L4D4: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {

                k.QLBP8L4D2 = k.QLBP8L4D2 === true ? 'Yes' : 'No'
                k.QLBP8L4D3 = k.QLBP8L4D3 === true ? 'Yes' : 'No'


            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF117 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 117);
        let empty_1 = [
            {
                QLBP8L5D1: 'NA',
                QLBP8L5D2: 'NA',
                QLBP8L5D3: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF118 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 118);
        let empty_1 = [
            {
                QLBP8L6D1: 'NA',
                QLBP8L6D2: 'NA',
                QLBP8L6D3: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF133 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 133);
        let empty_1 = [
            {
                QLBP8E1D1A: 'NA',
                QLBP8E1D1B: 'NA',
                QLBP8E1D1C: 'NA',
                QLBP8E1D1D: 'NA',
                QLBP8E1D1E: 'NA',
                QLBP8E1D1F: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP8E1D1C = formatDate(k.QLBP8E1D1C, 'dd-MM-yyyy')
                k.QLBP8E1D1D = k.QLBP8E1D1D === true ? 'Yes' : 'No'
                k.QLBP8E1D1E = k.QLBP8E1D1E === true ? 'Yes' : 'No'


            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF139 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 139);
        let empty_1 = [
            {
                QLBP2LQ1D1: 'NA',
                QLBP2LQ1D2: 'NA',

                QLBP2LQ1D3B: 'NA',
                QLBP2LQ1D4: 'NA',
                QLBP2LQ1D5: 'NA',
                QLBP2LQ1D6A: 'NA',
                QLBP2LQ1D6B: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {

                k.QLBP2LQ1D1 = k.QLBP2LQ1D1 === true ? 'Yes' : 'No'
                if (k.QLBP2LQ1D1 === 'No') {
                    k.QLBP2LQ1D2 = 'NA'
                    k.QLBP2LQ1D3B = 'NA'
                    k.QLBP2LQ1D4 = 'NA'
                    k.QLBP2LQ1D5 = 'NA'
                    k.QLBP2LQ1D6A = 'NA'
                    k.QLBP2LQ1D6B = 'NA'
                } else {
                    k.QLBP2LQ1D5 = k.QLBP2LQ1D5 === true ? 'Yes' : 'No'
                    k.QLBP2LQ1D6A = k.QLBP2LQ1D6A === true ? 'Yes, ' + k.QLBP2LQ1D6B : 'No'
                }



            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF140 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 140);
        let empty_1 = [
            {

                QLBP2LQ2D1B: 'NA',
                QLBP2LQ2D2: 'NA',
                QLBP2LQ2D3: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF134 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 134);
        let empty_1 = [
            {
                QLBP9L1D1: 'NA',
                QLBP9L1D2: 'NA',
                QLBP9L1D3: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP9L1D2 = k.QLBP9L1D2 === true ? 'Yes' + k.QLBP9L1D3 : 'No'
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF149 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 149);
        let empty_1 = [
            {
                QLBP6EQ11D1: 'NA',
                QLBP6EQ11D2: 'NA',
                QLBP6EQ11D3: 'NA',
                QLBP6EQ11D4: 'NA',
                QLBP6EQ11D5: 'NA',
                QLBP6EQ11D6: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP6EQ11D3 = formatDate(k.QLBP6EQ11D3, 'dd-MM-yyyy')
                k.QLBP6EQ11D4 = k.QLBP6EQ11D4 === true ? 'Yes' : 'No'
                k.QLBP6EQ11D5 = k.QLBP6EQ11D5 === true ? 'Yes' : 'No'


            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF148 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 148);
        let empty_1 = [
            {
                QLBP6EQ10D1: 'NA',
                QLBP6EQ10D2: 'NA',
                QLBP6EQ10D3A: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP6EQ10D3A = k.QLBP6EQ10D3A === true ? 'Yes' + k.QLBP6EQ10D3B : 'No'
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF150 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 150);
        let empty_1 = [
            {
                QLBP6EQ12D1: 'NA',
                QLBP6EQ12D2: 'NA',
                QLBP6EQ12D3: 'NA',
                QLBP6EQ12D4: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF151 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 151);
        let empty_1 = [
            {
                QLBP6LQ6D1: 'NA',
                QLBP6LQ6D2: 'NA',
                QLBP6LQ6D3: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF148B = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 148);
        let empty_1 = [
            {
                QLBP6EQ10D4: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }



    function haveSameSubarrayLengths(data) {
        if (data.length < 2) {
            // If there are fewer than 2 subarrays, they are considered to have the same length.
            return true;
        }

        const firstSubarrayLength = data[0].length;

        for (let i = 1; i < data.length; i++) {
            if (data[i].length !== firstSubarrayLength) {
                return false;
            }
        }

        return true;
    }
    function generatePdfMakeContentFromTable(table) {
        console.log(table)
        if (!table) {
            console.error(`Table  not found.`);
            return [];
        }

        let contentArray = [], maxCol = 0
        for (let i = 0; i < table.rows.length; i++) {
            if (maxCol <= table.rows[i].cells.length) {
                maxCol = table.rows[i].cells.length
            }
        }

        for (let i = 0; i < table.rows.length; i++) {
            const rowArray = [];

            for (let j = 0; j < table.rows[i].cells.length; j++) {
                const cell = table.rows[i].cells[j];
                const colSpan = cell.getAttribute('colspan');
                const rowSpan = cell.getAttribute('rowspan');
                const cellText = cell.textContent.trim();

                const cellObject = { text: cellText };
                cellObject.colSpan = parseInt(colSpan)
                cellObject.rowSpan = parseInt(rowSpan)
                cellObject.style = cell.getAttribute('class');
                rowArray.push(cellObject);
                if (parseInt(colSpan) > 1) {
                    console.log(rowArray)
                    for (let j = 0; j < parseInt(colSpan) - 1; j++) {
                        rowArray.push({});
                    }

                }

            }

            contentArray.push(rowArray);


        }
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                console.log(i)
                if (contentArray[index - 1]) {
                    contentArray[index - 1].forEach((k, ind) => {
                        if (k.rowSpan) {
                            if (k.rowSpan > 1) {
                                if (k.colSpan === 1) {
                                    i.splice(ind, 0, { text: '', colSpan: k.colSpan, rowSpan: k.rowSpan - 1 })
                                } else {
                                    let newind = ind
                                    for (let j = 0; j < parseInt(k.colSpan); j++) {
                                        i.splice(newind, 0, {})
                                        newind++
                                    }
                                }
                            }
                        } else {
                            for (let j = 0; j < (maxCol - Object.keys(i).length); j++) {
                                console.log(i)
                                // i.push({id:1});
                            }
                        }
                    })
                }
            }
        })
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                let len = Object.keys(i).length
                for (let j = 0; j < (maxCol - len); j++) {

                    i.push({});
                }

            }
        })
        return contentArray;
    }

    const getSum = (subset) => {
        let i = 0
        subset.forEach((item) => {
            i = i + item[1]
        })
        return i
    }
    const checkReportingPeriod = (rp, filter) => {

        let count = 0, rps = []
        filter.forEach((item) => {

            if (rp.includes(item)) {
                count = count + 1
                rps.push(item)
            }
        })
        return { result: count === rp.length, count: count, rps }
    }
    function getObjectsWithEmptyString(arr) {
        return arr
            .map((obj) => {
                const updatedObj = { ...obj };
                Object.keys(updatedObj).forEach((key) => {
                    if (typeof updatedObj[key] === "string" && updatedObj[key].trim() === "") {
                        delete updatedObj[key];
                    }
                });
                return updatedObj;
            })
            .filter((obj) => Object.values(obj).some((value) => value !== ""));
    }
    const getCellColSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.c - range.s.c + 1;
            }
        }
        return 1;
    };

    const getCellRowSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.r - range.s.r + 1;
            }
        }
        return 1;
    };
    function findValueByKey(object, key) {
        if (key in object) {
            return object[key];
        } else {
            return null; // or any other default value you want to return if the key is not found
        }
    }
    const getLastResponseByRFID = (key, uid) => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, key);
        console.log(key, result)
        if (result) {
            if (result[0].type === 0) {
                if (typeof uid === "string") {
                    let index = result[0].response.findIndex((k) => {
                        return k.name === uid;
                    });
                    if (index !== -1) {
                        if (result[0].response[index].type === 2) {
                            let value_2 = result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
                            return value_2 === null ? '' : result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
 } else if (result[0].response[index].type === 6 || result[0].response[index].type === 4) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return rflibrary[rflib].data1[field].values[result[0].response[index].value[0]].label
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value[0] })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    } else {
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        } else if (result[0].response[index].type === 3) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return null
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    } else {
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        }
                        else if (result[0].response[index].type === 9) {
                            return DateTime.fromISO(result[0].response[index].value, { zone: 'utc' }).toFormat('dd-MM-yyyy')
                        } else {
                            return result[0].response[index].value;
                        }

                    } else {
                        return 'NA';
                    }
                } else {
                    let str = "";
                    uid.forEach((id) => {
                        let index = result[0].response.findIndex((k) => {
                            return k.name === id;
                        });
                        if (index !== -1) {

                            str = str + " " + result[0].response[index].value;
                        }
                    });
                    if (str.trim().length !== 0) {
                        return str;
                    } else {
                        return 'NA';
                    }
                }
            } else {
                return result[0].data2.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
            }
        }
        return "";
    };


    useEffect(() => {
        const elements = Array.from(document.querySelectorAll("sectionheader,sectionheader1,sectionheader2")).map((elem) => ({
            id: elem.id,
            text: elem.childNodes[0].textContent.trim(),
            level: Number(elem.nodeName.charAt(13)),
        }));

        let as = [],
            indx = 0;
        elements.forEach((item, ind) => {
            if (item.level === 0) {
                as[indx] = item;
                indx = indx + 1;
            } else if (elements[ind - 1].level === 0) {
                as[indx] = { item: [item], level: 1 };
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            } else {
                as[indx].item.push(item);
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            }
        });

        setHeadings(as);
    }, []);
    useEffect(() => {
        if(props.year !== year){
            setYear(props.year)
        }
        
    }, [[props]])
    const groupArrayObject = (array, obj) => {
        return array.reduce(
            (group, arr) => {
                let key = arr[obj];

                group[key] = group[key] ?? [];

                group[key].push(arr);

                return group;
            },

            {}
        );
    };
    useEffect(() => {
        let gtaString = {
            "include": ["newTargetsTwos", "newIndicatorTwos", "newInitiatives"]
        }
        let dcf_list = [], dcf_submitted = [], locloc = []
        let category_string = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }

        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(API.DCF_Submit_UP(selector.id))
        const promise3 = APIServices.get(API.DP_report_UP(selector.id))
        const promise4 = APIServices.get(API.RF_Submit_UP(selector.id))
        Promise.all([promise1, promise2, promise3, promise4]).then(function (values) {
            dcf_list = values[0].data; setDcfList(values[0].data)
            dcf_submitted = values[1].data;
            setDpReport(values[2].data)
            let val = [], filterarr = groupArrayObject(values[2].data, 'submitId')
            Object.keys(filterarr).forEach((item) => {

                val.push({ id: filterarr[item][0].submitId, rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], 'form_id')) })

            })
            console.log(val, 'val')
            setResponse(val)
            let rfid_group = groupArrayObject(values[3].data, "rfid");
            Object.keys(rfid_group).forEach((key) => {
                rfid_group[key].sort((a, b) => {
                    return b.id - a.id;
                });
            });

            setRFData(rfid_group);

            console.log(rfid_group)
            forceUpdate()

        })

    }, []);
    useEffect(() => {
        if (response.length !== 0) {

            let report_ = {}
            report_[`${year}`] = renderData({ location: { a: { name: 'All', id: 0 }, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: { name: 'All', id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(3, year), to: getDateObjectByMonth_Year(2, year + 1) }, 1)
            report_[`${year - 1}`] = renderData({ location: { a: { name: 'All', id: 0 }, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: { name: 'All', id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(3, year - 1), to: getDateObjectByMonth_Year(2, year) }, 1)
            setReport(report_)

            forceUpdate()
        }
    }, [response])

    const getScopeData = (year, area) => {
        if (report[year]) {
            return report[year].filter((k) => { return k.scope === area }).map((j) => { return j.ghg }).reduce((a, b) => { return a + b }, 0)

        }
        return 0
    }
    const checkYear = (rp, yr) => {
        let betweenMonths = []



        let endDate = moment.utc(getDateObjectByMonth_Year(3, yr + 1)).local()
        let startDate = moment.utc(getDateObjectByMonth_Year(4, yr)).local()
        while (startDate.startOf('month') <= endDate.startOf('month')) {

            betweenMonths.push(startDate.format('MM-YYYY'));
            startDate.add(1, 'month');

        }

        return betweenMonths.filter((i) => { return rp.includes(i) }).length === rp.length ? 1 : betweenMonths.filter((i) => { return rp.includes(i) }).length

    }
    const getDataByDP = (dpid, yr) => {
        console.log(yr)
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = 0

        dpreport_.filter((i) => { return i.dpId === dpid }).forEach((k) => {


            if (checkYear(k.rp, yr) !== 0) {
                console.log(dpid, k.value)
                // let val = k.value.trim().length === 0 ? 0 : k.value
                d = d + k.value
            } else {

            }

        })

        return isNaN(d) ? 0 : d.toFixed(0)
    }
    const getDataByDPStr = (dpid, yr) => {
        console.log(yr)
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = ''

        dpreport_.filter((i) => { return i.dpId === dpid }).forEach((k) => {


            if (checkYear(k.rp, yr) !== 0) {
                console.log(dpid, k.value)
                // let val = k.value.trim().length === 0 ? 0 : k.value
                d = d + k.value
            } else {

            }

        })

        return d
    }
    const getDataByDPArray = (dparr, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = 0
        dparr.forEach((dpid) => {
            dpreport_.filter((i) => { return i.dpId === dpid }).forEach((k) => {


                if (checkYear(k.rp, yr) !== 0) {
                    d = d + k.value
                }

            })
        })

        return isNaN(d) ? 0 : d.toFixed(0)
    }


    function getDateObjectByMonth_Year(month, year) {

        if (isNaN(month) || isNaN(year)) {
            throw new Error('Invalid month or year');
        }

        const normalizedMonth = Math.max(1, Math.min(12, month));

        const date = DateTime.fromObject({ year, month: normalizedMonth, day: 1 });

        return date.toJSDate();
    }
    const getRP_ = (rp) => {
        if (rp.length === 1) {

            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2)
        } else {
            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2) + "to" + months_[parseInt(rp[rp.length - 1].split('-')[0]) - 1] + "-" + rp[rp.length - 1].split('-')[1].slice(-2)
        }
    }
    const getClassName = (level) => {
        switch (level) {
            case 1:
                return "head1";
            case 2:
                return "head2";
            case 3:
                return "head3";
            default:
                return null;
        }
    };

    const checkSite = (id, filter) => {
        let idlist = []

        siteList.forEach((country) => {

            if (filter.a.id === 0 || filter.a.id === country.id) {

                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id)
                            }
                        })
                    }
                })
            }
        })

        return idlist.includes(id)
    }
    const renderData = (search) => {
        let betweenMonths = [], betweenYears = []
        let monthly_fg = []


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')


        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))

            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total, co2, ch4, n2o })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                        sc_data[fuel_cat_ind].co2 += co2
                                        sc_data[fuel_cat_ind].ch4 += ch4
                                        sc_data[fuel_cat_ind].n2o += n2o
                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total, co2, ch4, n2o })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                        sc_data[fuel_cat_ind].co2 += co2
                                        sc_data[fuel_cat_ind].ch4 += ch4
                                        sc_data[fuel_cat_ind].n2o += n2o

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total, co2, ch4, n2o })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                    sc_data[fuel_cat_ind].co2 += co2
                                    sc_data[fuel_cat_ind].ch4 += ch4
                                    sc_data[fuel_cat_ind].n2o += n2o

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat, co2: item.co2, n2o: item.n2o, ch4: item.ch4 })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                    monthly_fg[updateind].c2o += (item.c2o / addedMonth.count)
                                    monthly_fg[updateind].ch4 += (item.ch4 / addedMonth.count)
                                    monthly_fg[updateind].n20 += (item.n20 / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = (ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg'] / 1000) * km
                                co2 = (ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2_in_kg'] / 1000) * km
                                ch4 = (ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['ch4_in_kg'] / 1000) * km
                                n2o = (ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['n2o_in_kg'] / 1000) * km
                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total, co2, ch4, n2o })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total
                                    mc_data[fuel_cat_ind].co2 += co2
                                    mc_data[fuel_cat_ind].n2o += n2o
                                    mc_data[fuel_cat_ind].ch4 += ch4

                                }
                            } else {
                                total = (ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg'] / 1000) * fuel_filled
                                co2 = (ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2_in_kg'] / 1000) * fuel_filled
                                ch4 = (ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['ch4_in_kg'] / 1000) * fuel_filled
                                n2o = (ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['n2o_in_kg'] / 1000) * fuel_filled

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total, co2, ch4, n2o })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total
                                    mc_data[fuel_cat_ind].co2 += co2
                                    mc_data[fuel_cat_ind].n2o += n2o
                                    mc_data[fuel_cat_ind].ch4 += ch4

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                        monthly_fg[updateind2].c2o += (item.c2o / addedMonth.count)
                                        monthly_fg[updateind2].ch4 += (item.ch4 / addedMonth.count)
                                        monthly_fg[updateind2].n20 += (item.n20 / addedMonth.count)
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode, co2: item.co2, n2o: item.n2o, ch4: item.ch4 })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                    monthly_fg[updateind].c2o += (item.c2o / addedMonth.count)
                                    monthly_fg[updateind].ch4 += (item.ch4 / addedMonth.count)
                                    monthly_fg[updateind].n20 += (item.n20 / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })





            let scope12_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1 || i.scope === 2) }), 'month')
            let scope3_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 3) }), 'month')
            let scope1_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1) }), 'month')
            let scope2_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 2) }), 'month')
            let pie = []
            monthly_fg.filter((i) => { return (i.scope === 1) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 2) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 3) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })

            let index1 = pie.findIndex((j) => { return j.name === 'Scope 1' })
            let index2 = pie.findIndex((j) => { return j.name === 'Scope 2' })
            let index3 = pie.findIndex((j) => { return j.name === 'Scope 3' })

            if (index1 === -1) {
                pie.push({ name: 'Scope 1', y: 0 })
            }
            if (index2 === -1) {
                pie.push({ name: 'Scope 2', y: 0 })
            }
            if (index3 === -1) {
                pie.push({ name: 'Scope 3', y: 0 })
            }


            if (scope1_array.length !== 0) {
                let scope1_tier2 = [], final = []
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {

                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })

                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })


                    final.push(dataset)
                })

            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [], final = []
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })


            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [], final = []
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })

            }



        }
        return monthly_fg


    }
//     const renderData = (search) => {
//         let betweenMonths = [], betweenYears = []
//         let monthly_fg = []


//         let endDate = moment.utc(search.to).local()
//         let startDate = moment.utc(search.from).local()
//         let year = moment(moment.utc()).format('YYYY')


//         if (search.to !== null && search.from !== null) {
//             while (startDate.startOf('month') <= endDate.startOf('month')) {

//                 betweenMonths.push(startDate.format('MM-YYYY'));
//                 !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

//                 startDate.add(1, 'month');

//             }


//             let res = JSON.parse(JSON.stringify(response))

//             res.forEach((report, rind) => {
//                 let sc_total = 0, fg_total = 0

//                 if (checkSite(report.site, search.location)) {
//                     report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
//                     if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


//                         report.response.forEach((fg, ind) => {

//                             let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
//                             let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
//                             let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
//                             let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
//                             let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


//                             if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


//                                 let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
//                                 let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
//                                 let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

//                                 let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

//                                 fg_total = total + fg_total


//                                 let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

//                                 if (updateind === -1) {

//                                     monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

//                                 } else {

//                                     monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
//                                 }

//                             }
//                         })

//                     }
//                     if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


//                         report.response.forEach((fg, ind) => {
// console.log(fg)
//                             let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
//                             let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
//                             let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
//                             let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
//                             let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


//                             if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




//                                 let total = 0


//                                 let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
//                                 let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

//                                 if (travel_mode.toLowerCase() === 'air') {

//                                     total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

//                                     let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

//                                     if (updateind === -1) {

//                                         monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



//                                     } else {

//                                         monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
//                                     }

//                                     fg_total = total + fg_total
//                                 } else if (travel_mode.toLowerCase() === 'road') {

//                                     let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
//                                     let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
//                                     let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
//                                     let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
//                                     if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
//                                         if (veh_cat.includes('Cars')) {
//                                             total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

//                                         } else {
//                                             total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

//                                         }

//                                     } else {


//                                         total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


//                                     }


//                                     let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

//                                     if (updateind === -1) {

//                                         monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



//                                     } else {

//                                         monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
//                                     }

//                                     fg_total = total + fg_total


//                                 } else if (travel_mode.toLowerCase() === 'rail') {



//                                     total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

//                                     let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

//                                     if (updateind === -1) {

//                                         monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



//                                     } else {

//                                         monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
//                                     }

//                                     fg_total = total + fg_total
//                                 }





//                             }
//                         })

//                     }

//                     if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

//                         let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

//                         let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
//                         report.response.forEach((fg, ind) => {

//                             let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
//                             let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
//                             let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
//                             let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
//                             let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

//                             if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
//                                 let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
//                                 let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
//                                 if (carbon > 0 && cv > 0) {
//                                     let gj = (carbon * 3.664 * 1000) / cv
//                                     total = (gj * 0.000004184 * consumed) / 1000
//                                     fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
//                                     co2 = 0
//                                     ch4 = 0
//                                     n2o = 0
//                                     if (fuel_cat_ind === -1) {
//                                         sc_data.push({ fuel_cat: fuel_cat, ghg: total })
//                                     } else {
//                                         sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

//                                     }

//                                 } else {
//                                     total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
//                                     co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
//                                     ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
//                                     n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
//                                     fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
//                                     if (fuel_cat_ind === -1) {
//                                         sc_data.push({ fuel_cat: fuel_cat, ghg: total })
//                                     } else {
//                                         sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

//                                     }
//                                 }

//                             } else {
//                                 total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
//                                 co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
//                                 ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
//                                 n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
//                                 fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
//                                 if (fuel_cat_ind === -1) {
//                                     sc_data.push({ fuel_cat: fuel_cat, ghg: total })
//                                 } else {
//                                     sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

//                                 }
//                             }


//                         })
//                         addedMonth.rps.forEach((fm) => {
//                             let date = moment(fm, 'MM-YYYY').toDate()
//                             let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
//                             let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
//                             let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
//                             let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

//                             sc_data.forEach((item) => {
//                                 let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

//                                 if (updateind === -1) {

//                                     monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat })


//                                 } else {

//                                     monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
//                                 }
//                             })


//                         })




//                     }
//                     if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
//                         let add = []

//                         report.response.forEach((fg, ind) => {

//                             let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
//                             let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
//                             let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
//                             let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
//                             let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


//                             if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






//                                 let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
//                                 let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
//                                 let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

//                                 fg_total = total + fg_total

//                                 fg['gas'] = gastype
//                                 fg['gasfilled'] = gasrefilled
//                                 fg['value'] = ['EmissionFactor-', total / gasrefilled]
//                                 let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

//                                 if (updateind === -1) {

//                                     monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



//                                 } else {

//                                     monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
//                                 }

//                             }
//                         })

//                     }
//                     if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

//                         report.rp.forEach((i) => {
//                             if (checkReportingPeriod([i], betweenMonths).result) {
//                                 let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
//                                 let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
//                                 let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
//                                 let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

//                                 let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

//                                 let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
//                                 let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

//                                 if (yearind === -1) {

//                                     monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

//                                 } else if (monthind === -1) {

//                                     monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
//                                 } else if (dcfind === -1) {

//                                     monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

//                                 } else if (siteind === -1) {

//                                     monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

//                                 }
//                             }
//                         })

//                     }
//                     if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

//                         let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


//                         let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
//                         let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

//                         let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

//                         report.response.forEach((fg, ind) => {
//                             let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
//                             let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
//                             let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
//                             let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
//                             let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
//                             let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
//                             let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
//                             let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
//                             if (mode === 'by distance') {

//                                 total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

//                                 if (fuel_cat_ind === -1) {

//                                     mc_data.push({ mode: mode, ghg: total })
//                                 } else {
//                                     mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

//                                 }
//                             } else {
//                                 total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

//                                 if (fuel_cat_ind === -1) {

//                                     mc_data.push({ mode: mode, ghg: total })
//                                 } else {
//                                     mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

//                                 }
//                             }

//                         })
//                         addedMonth.rps.forEach((fm) => {
//                             let date = moment(fm, 'MM-YYYY').toDate()

//                             mc_data.forEach((item) => {
//                                 let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

//                                 if (updateind === -1) {
//                                     let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
//                                     if (updateind2 !== -1) {
//                                         monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
//                                     } else {
//                                         monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode })
//                                     }




//                                 } else {

//                                     monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
//                                 }
//                             })


//                         })




//                     }

//                 }


//             })





//             let scope12_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1 || i.scope === 2) }), 'month')
//             let scope3_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 3) }), 'month')
//             let scope1_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1) }), 'month')
//             let scope2_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 2) }), 'month')
//             let pie = []
//             monthly_fg.filter((i) => { return (i.scope === 1) }).forEach((item) => {
//                 let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
//                 if (index === -1) {
//                     pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
//                 } else {
//                     pie[index].y = pie[index].y + item.ghg
//                 }
//             })
//             monthly_fg.filter((i) => { return (i.scope === 2) }).forEach((item) => {
//                 let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
//                 if (index === -1) {
//                     pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
//                 } else {
//                     pie[index].y = pie[index].y + item.ghg
//                 }
//             })
//             monthly_fg.filter((i) => { return (i.scope === 3) }).forEach((item) => {
//                 let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
//                 if (index === -1) {
//                     pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
//                 } else {
//                     pie[index].y = pie[index].y + item.ghg
//                 }
//             })

//             let index1 = pie.findIndex((j) => { return j.name === 'Scope 1' })
//             let index2 = pie.findIndex((j) => { return j.name === 'Scope 2' })
//             let index3 = pie.findIndex((j) => { return j.name === 'Scope 3' })

//             if (index1 === -1) {
//                 pie.push({ name: 'Scope 1', y: 0 })
//             }
//             if (index2 === -1) {
//                 pie.push({ name: 'Scope 2', y: 0 })
//             }
//             if (index3 === -1) {
//                 pie.push({ name: 'Scope 3', y: 0 })
//             }


//             if (scope1_array.length !== 0) {
//                 let scope1_tier2 = [], final = []
//                 Object.keys(scope1_array).forEach((key) => {
//                     scope1_array[key].forEach((item) => {
//                         item.dcfId = item.dcf
//                         item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title

//                         if (
//                             scope1_tier2.findIndex((i) => {
//                                 return i.name === item.dcf;
//                             }) === -1
//                         ) {

//                             scope1_tier2.push({
//                                 name: item.dcf,
//                                 drilldown: true,
//                                 color: 'red',
//                                 y: item.ghg,

//                                 subset: [[key, item.ghg]],
//                             });
//                         } else {
//                             let ind = scope1_tier2.findIndex((i) => {
//                                 return i.name === item.dcf;
//                             });
//                             let subind = scope1_tier2[ind].subset.findIndex((j) => {
//                                 return j[0] === key;
//                             });
//                             scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
//                             if (subind === -1) {
//                                 scope1_tier2[ind].subset.push([key, item.ghg]);
//                             } else {
//                                 scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
//                             }
//                         }
//                     })
//                 })

//                 betweenYears.forEach((j) => {

//                     let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })


//                     final.push(dataset)
//                 })

//             }
//             if (scope2_array.length !== 0) {
//                 let scope2_tier2 = [], final = []
//                 Object.keys(scope2_array).forEach((key) => {
//                     scope2_array[key].forEach((item) => {
//                         item.dcfId = item.dcf
//                         item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
//                         if (
//                             scope2_tier2.findIndex((i) => {
//                                 return i.name === item.dcf;
//                             }) === -1
//                         ) {
//                             scope2_tier2.push({
//                                 name: item.dcf,
//                                 drilldown: true,
//                                 color: 'red',
//                                 y: item.ghg,
//                                 subset: [[key, item.ghg]],
//                             });
//                         } else {
//                             let ind = scope2_tier2.findIndex((i) => {
//                                 return i.name === item.dcf;
//                             });
//                             let subind = scope2_tier2[ind].subset.findIndex((j) => {
//                                 return j[0] === key;
//                             });
//                             scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
//                             if (subind === -1) {
//                                 scope2_tier2[ind].subset.push([key, item.ghg]);
//                             } else {
//                                 scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
//                             }
//                         }
//                     })
//                 })
//                 betweenYears.forEach((j) => {

//                     let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

//                     final.push(dataset)
//                 })


//             }
//             if (scope3_array.length !== 0) {
//                 let scope3_tier2 = [], final = []
//                 Object.keys(scope3_array).forEach((key) => {
//                     scope3_array[key].forEach((item) => {
//                         item.dcfId = item.dcf
//                         item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
//                         if (
//                             scope3_tier2.findIndex((i) => {
//                                 return i.name === item.dcf;
//                             }) === -1
//                         ) {
//                             scope3_tier2.push({
//                                 name: item.dcf,
//                                 drilldown: true,
//                                 color: 'red',
//                                 y: item.ghg,
//                                 subset: [[key, item.ghg]],
//                             });
//                         } else {
//                             let ind = scope3_tier2.findIndex((i) => {
//                                 return i.name === item.dcf;
//                             });
//                             let subind = scope3_tier2[ind].subset.findIndex((j) => {
//                                 return j[0] === key;
//                             });
//                             scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
//                             if (subind === -1) {
//                                 scope3_tier2[ind].subset.push([key, item.ghg]);
//                             } else {
//                                 scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
//                             }
//                         }
//                     })
//                 })
//                 betweenYears.forEach((j) => {

//                     let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

//                     final.push(dataset)
//                 })

//             }



//         }
//         return monthly_fg


//     }
    return (
        <div className="col-12" style={{ display: "flex",height:'calc(100vh - 190px)', flexDirection: "row", justifyContent: "space-between" }}>
            <div className="col-3 p-card" style={{ margin: 5, overflow: "scroll" }}>
                <nav>
                    {headings.map((heading, ind) => {
                        let indexes = [];
                        return (
                            <>
                                {heading.level === 0 ? (
                                    <label key={heading.id} style={{ display: "flex", margin: 5, fontWeight: activeId === heading.id ? "bold" : "normal", textDecoration: heading.text.includes("SECTION") && "underline" }} className={getClassName(heading.level)}>
                                        <a
                                            href={`#${heading.id}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                document.querySelector(`#${heading.id}`).scrollIntoView({
                                                    behavior: "smooth",
                                                    block: "start",
                                                    inline: "nearest",
                                                });
                                            }}
                                            style={{
                                                fontWeight: activeId === heading.id ? "bold" : "normal",
                                            }}
                                        >
                                            {heading.text}
                                        </a>
                                    </label>
                                ) : (
                                    <ul>
                                        {heading.item.map((item, ind2) => {
                                            return (
                                                <li key={item.id} className={getClassName(item.level)}>
                                                    <a
                                                        href={`#${item.id}`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            document.querySelector(`#${item.id}`).scrollIntoView({
                                                                behavior: "smooth",
                                                            });
                                                        }}
                                                        style={{
                                                            fontWeight: activeId === item.id ? "bold" : "normal",
                                                        }}
                                                    >
                                                        {item.text}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </>
                        );
                    })}
                </nav>
            </div>
            <div className="col-9 p-card" style={{ margin: 5, overflow: "scroll", color: "white" }}>

                <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* <img id='ass' src='https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/hazards/1690426362525Pushing%20and%20Pulling.png' width={'100%'} /> */}
                    <div>

                        {/* <input type="file" onChange={handleFileChange} /> */}
                        {tableData.length > 0 && (
                            <div className="gridlines-container">
                                <table className="gridlines">
                                    {/* <thead>
                                    <tr>
                                        {Object.keys(tableData[0]).map((field, index) => (
                                            <th key={index} colSpan={getCellColSpan(0, index)}>
                                                {field}
                                            </th>
                                        ))}
                                    </tr>
                                </thead> */}
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {Object.keys(row).map((cellRef, colIndex) => {
                                                    const cellValue = row[cellRef];
                                                    const colSpan = getCellColSpan(rowIndex + 1, colIndex); // Increment rowIndex to exclude header
                                                    const rowSpan = getCellRowSpan(rowIndex + 1, colIndex); // Increment rowIndex to exclude header
                                                    return (
                                                        <td key={colIndex} colSpan={colSpan} rowSpan={rowSpan} className={cellValue === "" && workbook.Sheets[firstSheet][cellRef]?.s?.b ? "empty-cell" : ""}>
                                                            {cellValue}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div id='main' style={{ flexDirection: 'column', display: 'flex' }}>
                        <sectionheader id={"sectiona"} className="secHead">
                            SECTION A: GENERAL DISCLOSURES
                        </sectionheader>

                        <sectionheader1 id={"secai"}>
                            Details of Listed Entity
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                {` 1) Details of the listed entity`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                1
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Corporate Identity Number (CIN)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988117899-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Name of the entity
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988188471-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                3
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Year of incorporation
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988206220-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                4
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Registered office address
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988245316-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                5
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Corporate address
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988280427-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                6
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                E-mail
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988332354-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                7
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Telephone
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988375298-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                8
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Website
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988397295-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                9
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Financial year
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {year}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                10
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Name of the Stock Exchange(s)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988397295-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                11
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Paid-up Capital
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "number-1690988475842-0")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                12
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Name and contact details of the person who may be contacted in case of any queries on report
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, ["text-1690988527849-0", "number-1690988547025-0"])}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                13
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Reporting boundary - standalone basis or a consolidated basis *
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(41, "text-1690988587120-0")}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </sectionheader1>
                        <textonly>
                            <label style={{ color: 'black' }}>
                                * &nbsp; Standalone basis - only for the entity
                                Consolidated Basis - for the entity and all the entities which form a part of its consolidated
                                financial statements
                            </label>
                        </textonly>

                        <sectionheader1 id={"secaii"}>
                            Product/Services
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                II. Products/services
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`14) Details of business activities (accounting for 90% of the turnover):`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                S.No
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Description of Main Activity
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Description of Business Activity
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % Turnover
                                            </td>
                                        </tr>
                                        {getLastResponseRF85().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" class="">
                                                        {j + 1}
                                                    </td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL14DP1}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL14DP2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL14DP3}</td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`15) Products/Services sold by the entity (accounting for 90% of the entity’s Turnover):`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                S.No
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Product / Service
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Name of Product / Service
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                NIC code
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % Turnover contributed
                                            </td>
                                        </tr>
                                        {getLastResponseRF89().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" class="">
                                                        {j + 1}
                                                    </td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL15DP1A === 1 ? 'Product' : item.QL15DP1A === 2 ? 'Service' : '-'}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL15DP1B}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL15DP2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL15DP3}</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>

                        <sectionheader1 id={"secaiii"}>
                            Operations
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`III. Operations`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`16. Number of locations where plants and/or operations/offices of the entity are situated:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Location
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Number of plants
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Number of offices
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                National
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0139', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0140', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPK0139', 'DPK0140'], year)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                International
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0141', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0142', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPK0141', 'DPK0142'], year)}</td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`17) Markets served by the entity`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`a) Number of locations`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Location
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Number
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                National (no of states)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0143', year)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                International (No of counties)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0144', year)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`b. What is the contribution of exports as a percentage of the total turnover of the entity?`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0145', year) / getDataByDP('DPK0203', year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`c. A brief on types of customers `}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="text-center">{getLastResponseByRFID(119, 'text-1695059539196-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secaiv"}>
                            Employees
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                IV. Employees
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                18. Details as at the end of Financial Year:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                a. Employees and workers (including differently abled):
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                S.No
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Particulars
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total (A)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Male
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Female
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (B)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Percentage (B/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (C)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Percentage (C/A)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" rowspan="1" className="tdHead">
                                                EMPLOYEES
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                1
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Permanent (D)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0072', 'DPS0073'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0072', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPS0072', year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0073', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPS0073', year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Other than Permanent (E)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0075', 'DPS0076'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0075', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPS0075', year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0076', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPS0076', year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                3
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Total Employees (D+E)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0072', 'DPS0075'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0072', 'DPS0075'], year) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0073', 'DPS0076'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0073', 'DPS0076'], year) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="7" rowspan="1" className="tdHead">
                                                WORKERS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                1
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Permanent (F)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0078', 'DPS0079'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0078', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPS0078', year) / getDataByDPArray(['DPS0078', 'DPS0079'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0079', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPS0079', year) / getDataByDPArray(['DPS0078', 'DPS0079'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Other than Permanent (G)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0081', 'DPS0082'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0081', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPS0081', year) / getDataByDPArray(['DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0082', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPS0082', year) / getDataByDPArray(['DPS0081', 'DPS0082'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                3
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Total (F+G)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0078', 'DPS0079'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0078', 'DPS0079'], year) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0081', 'DPS0082'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0081', 'DPS0082'], year) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)) * 100)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                b. Differently abled Employees and workers:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                S.No
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Particulars
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total (A)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Male
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Female
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (B)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Percentage (B/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (C)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Percentage (C/A)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" rowspan="1" className="tdHead">
                                                DIFFERENTLY ABLED EMPLOYEES
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                1
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Permanent (D)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0200', 'DPR0201'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0200', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0200', year) / getDataByDPArray(['DPR0200', 'DPR0201'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0201', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0201', year) / getDataByDPArray(['DPR0200', 'DPR0201'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Other than Permanent (E)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0202', 'DPR0203'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0202', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0202', year) / getDataByDPArray(['DPR0202', 'DPR0203'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0203', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0203', year) / getDataByDPArray(['DPR0202', 'DPR0203'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                3
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Total differently abled persons (D+E)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0200', 'DPR0201', 'DPR0202', 'DPR0203'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0200', 'DPR0201'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0200', 'DPR0201'], year) / getDataByDPArray(['DPR0200', 'DPR0201', 'DPR0202', 'DPR0203'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0202', 'DPR0203'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0202', 'DPR0203'], year) / getDataByDPArray(['DPR0200', 'DPR0201', 'DPR0202', 'DPR0203'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="7" rowspan="1" className="tdHead">
                                                DIFFERENTLY ABLED WORKERS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                1
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Permanent (F)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0204', 'DPR0205'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0204', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0204', year) / getDataByDPArray(['DPR0204', 'DPR0205'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0205', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0205', year) / getDataByDPArray(['DPR0204', 'DPR0205'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Other than Permanent (G)
                                            </td>

                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0206', 'DPR0207'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0206', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0206', year) / getDataByDPArray(['DPR0206', 'DPR0207'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0207', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0207', year) / getDataByDPArray(['DPR0206', 'DPR0207'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                3
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Total differently abled persons (F+G)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0204', 'DPR0205', 'DPR0206', 'DPR0207'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0204', 'DPR0205'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0204', 'DPR0205'], year) / getDataByDPArray(['DPR0204', 'DPR0205', 'DPR0206', 'DPR0207'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPR0206', 'DPR0207'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0206', 'DPR0207'], year) / getDataByDPArray(['DPR0204', 'DPR0205', 'DPR0206', 'DPR0207'], year)) * 100)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`19)  Participation/Inclusion/Representation of women`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="2" class=""></td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total (A)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                No. & percentage of Females
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No(B)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">

                                                Percentage (B/A)%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Board of Directors
                                            </td>
                                            <td colspan="1" rowspan="1" class="tex-center">{getDataByDPArray(['DPS0252', 'DPS0254'], year)}</td>
                                            <td colspan="1" rowspan="1" class="tex-center">{getDataByDP('DPS0254', year)} </td>
                                            <td colspan="1" rowspan="1" class="tex-center">{handleNaNAndInfinity((getDataByDP('DPS0254', year) / getDataByDPArray(['DPS0252', 'DPS0254'], year)) * 100)} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Key Management Personnel
                                            </td>
                                            <td colspan="1" rowspan="1" class="tex-center">{getDataByDPArray(['DPS0256', 'DPS0258'], year)}</td>
                                            <td colspan="1" rowspan="1" class="tex-center">{getDataByDP('DPS0258', year)} </td>
                                            <td colspan="1" rowspan="1" class="tex-center">{handleNaNAndInfinity((getDataByDP('DPS0258', year) / getDataByDPArray(['DPS0256', 'DPS0258'], year)) * 100)} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="10" rowspan="1" class="">
                                                20) Turnover rate for permanent employees and workers
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" rowspan="1" class="">
                                                (Disclose trends for the past 3 years)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)} (Turnover rate -previous FY)
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)} (Turnover rate -Year prior to previous FY)
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)} (Turnover rate -Current FY)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0284', 'DPS0288', 'DPS0292'], year) / getDataByDPArray(['DPS0072'], year))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0286', 'DPS0290', 'DPS0294'], year) / getDataByDPArray(['DPS0073'], year))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0284', 'DPS0288', 'DPS0292', 'DPS0286', 'DPS0290', 'DPS0294'], year) / getDataByDPArray(['DPS0072', 'DPS0073'], year))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0284', 'DPS0288', 'DPS0292'], year - 1) / getDataByDPArray(['DPS0072'], year - 1))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0286', 'DPS0290', 'DPS0294'], year - 1) / getDataByDPArray(['DPS0073'], year - 1))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0284', 'DPS0288', 'DPS0292', 'DPS0286', 'DPS0290', 'DPS0294'], year - 1) / getDataByDPArray(['DPS0072', 'DPS0073'], year - 1))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0284', 'DPS0288', 'DPS0292'], year - 2) / getDataByDPArray(['DPS0072'], year - 2))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0286', 'DPS0290', 'DPS0294'], year - 2) / getDataByDPArray(['DPS0073'], year - 2))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0284', 'DPS0288', 'DPS0292', 'DPS0286', 'DPS0290', 'DPS0294'], year - 2) / getDataByDPArray(['DPS0072', 'DPS0073'], year - 2))}</td>


                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Workers
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDP('DPS0296', year) / getDataByDP('DPS0078', year))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDP('DPS0297', year) / getDataByDP('DPS0079', year))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0296', ' DPS0297'], year) / getDataByDPArray(['DPS0078', 'DPS0079'], year))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDP('DPS0296', year - 1) / getDataByDP('DPS0078', year - 1))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDP('DPS0297', year - 1) / getDataByDP('DPS0079', year - 1))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0296', ' DPS0297'], year - 1) / getDataByDPArray(['DPS0078', 'DPS0079'], year - 1))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDP('DPS0296', year - 2) / getDataByDP('DPS0078', year - 2))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDP('DPS0297', year - 2) / getDataByDP('DPS0079', year - 2))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0296', ' DPS0297'], year - 2) / getDataByDPArray(['DPS0078', 'DPS0079'], year - 2))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secav"}>
                            Holding, Subsidiary and Associate Companies
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`V. Holding, Subsidiary and Associate Companies (including joint ventures)`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`21. (a) Names of holding / subsidiary / associate companies / joint ventures`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                S.No
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Name of the holding / subsidiary / associate companies / joint ventures
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Indicate – Holding/ Subsidiary / Associates/ Joint Venture
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % of shares held by listed entities
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Does it partcipates in Business responsibility of listed entity (Yes/No)
                                            </td>
                                        </tr>
                                        {getLastResponseRF102().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{j + 1}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL21DP1A}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL21DP1B}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL21DP2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QL21DP3}</td>



                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secavi"}>
                            CSR Details
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`VI. CSR Details`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (i) Whether CSR is applicable as per section 135 of Companies Act, 2013: (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(159, 'radio-group-1695799157344-0')}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (ii) Turnover (in Rs.)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center"> {getDataByDP('DPK0203', year)} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iii) Net worth (in Rs.)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center"> {getDataByDP('DPK0211', year)} </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secavii"}>
                            Transparency and Disclosures Compliances
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="8" rowspan="1" class="">
                                                {`VII. Transparency and Disclosures Compliances`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" rowspan="1" class="">
                                                {`23. Complaints/Grievances on any of the principles (Principles 1 to 9) under the National Guidelines on Responsible Business Conduct:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                Stakeholder group from whom complaint is received
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Grievance Redressal Mechanism in Place (Yes/No)
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="3" rowspan="2" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)} Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                IF YES PROVIDE WEB LINK
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead"></td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. of complaints filed during the year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. of complaints pending resolution at close of the year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Remarks
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. of complaints filed during the year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. of complaints pending resolution at close of the year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Remarks
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Communities
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(162, 'radio-group-1695805491921-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0212', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0213', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0214', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0212', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0213', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0214', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Investors(other than shareholders)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(162, 'radio-group-1695805535082')} </td>

                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0215', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0216', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0217', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0215', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0216', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0217', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Shareholders
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(162, 'radio-group-1695805534613')} </td>

                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0218', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0219', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0220', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0218', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0219', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0220', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees and workers
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(162, 'radio-group-1695805534072')} </td>

                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0221', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0222', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0223', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0221', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0222', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0223', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Customers
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(162, 'radio-group-1695805603821')} </td>

                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0224', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0225', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0226', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0224', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0225', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0226', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Value Chain Partners
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(162, 'radio-group-1695805608927')} </td>

                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0227', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0228', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0229', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0227', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0228', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0229', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other specify
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(162, 'radio-group-1695805656150')} </td>

                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0230', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0231', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0232', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0230', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0231', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0232', year - 1)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                {`24. Overview of the entity’s material responsible business conduct issues`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                {`Please indicate material responsible business conduct and sustainability issues pertaining to environmental and social matters that present a risk or an opportunity to your business, rationale for identifying the same, approach to adapt or mitigate the risk along-with its financial implications, as per the following format`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                S. No.
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Material issue identified
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Indicate whether risk or opportunity
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Rationale for identifying risk / opportunity
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                In case of risk, approach to adapt or mitigate
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Financial implications of the risk or opportunity (Indicate positive / negative implications)
                                            </td>
                                        </tr>
                                        {getLastResponseRF110().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{j + 1} </td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBG24D1} </td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBG24D2} </td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBG24D3} </td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBG24D4} </td>
                                                    <td colspan="1" rowspan="1" className="text-center"> {item.QLBG24D5}</td>


                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader id={"sectionb"} className="secHead">
                            SECTION B: MANAGEMENT AND PROCESS DISCLOSURES
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`This section is aimed at helping businesses demonstrate the structures, policies and processes put in place towards adopting the NGRBC Principles and Core Elements.`}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader>
                        <sectionheader1 id={"secbi"}>
                            Details of Listed Entities
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Disclosure Questions
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 1
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 2
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 3
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 4
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 5
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 6
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 7
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 8
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 9
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" rowspan="1" className="tdHead">
                                                {`Policy and management processes`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                1. a. Entity’s policy/policies cover each principle and its core elements of NGRBCs. (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'radio-group-1695728561655-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'radio-group-1695735743251-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'radio-group-1695740205102-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'radio-group-1695741315322-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'radio-group-1695742622208-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'radio-group-1695744740902-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'radio-group-1695750539651-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'radio-group-1695799565806-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'radio-group-1695802768041-0')} </td>


                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                b. Has the policy been approved by the Board? (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'radio-group-1695728772953')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'radio-group-1695735771556')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'radio-group-1695740234411')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'radio-group-1695741565697')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'radio-group-1695742945004')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'radio-group-1695744768967')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'radio-group-1695750680053')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'radio-group-1695799795136')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'radio-group-1695802787649')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                c. Web Link of the Policies, if available
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'textarea-1695728903497-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'textarea-1695735789398-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'textarea-1695740247685-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'textarea-1695741615314-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'textarea-1695742958998-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'textarea-1695744783818-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'textarea-1695750690849-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'textarea-1695801104618-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'textarea-1695802808657-0')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2. The entity has translated the policy into procedures. (Yes / No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'radio-group-1695733384893')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'radio-group-1695738371187')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'radio-group-1695740350597')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'radio-group-1695741668228')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'radio-group-1695743145194')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'radio-group-1695744794499')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'radio-group-1695750706562')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'radio-group-1695801125529')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'radio-group-1695802821320')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                3. Do the enlisted policies extend to your value chain partners? (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'radio-group-1695733351752')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'radio-group-1695738667953')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'radio-group-1695740364886')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'radio-group-1695741683539')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'radio-group-1695743176564')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'radio-group-1695744808292')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'radio-group-1695750719861')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'radio-group-1695801140012')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'radio-group-1695803017265')} </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                4. Name of the national and international codes/certifications/labels/ standards
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'textarea-1695733634870-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'textarea-1695738690723-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'textarea-1695740376647-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'textarea-1695741696513-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'textarea-1695743201458-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'textarea-1695744821317-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'textarea-1695750730797-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'textarea-1695801161048-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'textarea-1695803034270')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                5. Specific commitments, goals and targets set with defined timelines
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'textarea-1695733653108')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'textarea-1695738710795')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'textarea-1695740388386')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'textarea-1695741703864')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'textarea-1695743211920')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'textarea-1695744829584')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'textarea-1695750735519')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'textarea-1695801167159')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'textarea-1695803044910')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                6. Performance against the specific commitments, goals and targets along-with reasons in case the same are not met.
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'textarea-1695733662089')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'textarea-1695738749986')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'textarea-1695740401909')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'textarea-1695741716313-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'textarea-1695743224490')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'textarea-1695744851430')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'textarea-1695750753010')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'textarea-1695801188707')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'textarea-1695803187485')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secbii"}>
                            Governance, leadership and oversight
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="10" rowspan="1" className="tdHead">
                                                {`Governance, leadership and oversight`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                7. Statement by director responsible for business responsibility report, highlighting ESG related challenges, targets & achievements
                                            </td>
                                            <td colspan="5" rowspan="1" class="text-center">{getLastResponseByRFID(163, 'textarea-1695829787427-0')} </td>

                                        </tr>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                8. Details of the highest authority responsible for implementation and oversight of the Business Responsibility policy
                                            </td>
                                            <td colspan="5" rowspan="1" class="text-center">{getLastResponseByRFID(163, 'textarea-1695829809466')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                9.Does have a specified Committee of the Board/ Director responsible for decision making on sustainability related issues (Yes / No). If yes, provide details.
                                            </td>
                                            <td colspan="5" rowspan="1" class="text-center">{getLastResponseByRFID(163, 'radio-group-1695829845891-0') + ' ' + getLastResponseByRFID(163, 'textarea-1695829878858-0')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secbiii"}>
                            Details of Review of NGRBCs
                            <div class="gridlines-container">
                                <table id='mass' class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="19" rowspan="1" class="">
                                                {`10. Details of Review of NGRBCs by the Company:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                {`Subject for Review`}
                                            </td>
                                            <td colspan="9" rowspan="2" className="tdHead">
                                                {`Indicate review was undertaken by Director / Committee of the Board/ Any other Committee`}
                                            </td>
                                            <td colspan="9" rowspan="1" className="tdHead">
                                                {`Frequency`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" rowspan="1" className="tdHead">
                                                {`(Annually/ Half yearly/ Quarterly/ Any other –specify)`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 1
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 2
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 3
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 4
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 5
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 6
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 7
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 8
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 9
                                            </td>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 1
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 2
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 3
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 4
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 5
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 6
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 7
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 8
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 9
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Performance against policies and follow up action
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'textarea-1695734366941')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'textarea-1695739021082-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'textarea-1695740655107-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'textarea-1695742089972-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'textarea-1695743990222-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'textarea-1695744930181-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'textarea-1695750940623')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'textarea-1695801644071-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'textarea-1695803651336-0')} </td>

                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'textarea-1695734339973')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'textarea-1695739025761')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'textarea-1695740672613')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'textarea-1695831152784')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'textarea-1695743996427')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'textarea-1695744943252')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'textarea-1695750941280')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'textarea-1695801659365')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'textarea-1695803688099')} </td>


                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Compliance with statutory requirement of relevance to principles &rectification of any non-compliances
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'textarea-1695734169690-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'textarea-1695739040812')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'textarea-1695740701181')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'textarea-1695831151613')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'textarea-1695744011595')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'textarea-1695744984733')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'textarea-1695750911126-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'textarea-1695801733867')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'textarea-1695803685344')} </td>

                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'textarea-1695734369589')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'textarea-1695739041929')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'textarea-1695740701997')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'textarea-1695742098554')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'textarea-1695744012734')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'textarea-1695744987433')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'textarea-1695750920737')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'textarea-1695801731855')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'textarea-1695803657089')} </td>



                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="2" class="">
                                                11. Has carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 1
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 2
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 3
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 4
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 5
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 6
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 7
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 8
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 9
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'radio-group-1695734075973') + ' ' + getLastResponseByRFID(152, 'textarea-1695734099312-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'radio-group-1695738898568') + ' ' + getLastResponseByRFID(153, 'textarea-1695739855789')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'radio-group-1695740733447') + ' ' + getLastResponseByRFID(154, 'textarea-1695740772925')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'radio-group-1695742125711') + ' ' + getLastResponseByRFID(155, 'textarea-1695742320698-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'radio-group-1695744027552') + ' ' + getLastResponseByRFID(156, 'text-1695744045376')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'radio-group-1695744995271') + ' ' + getLastResponseByRFID(157, 'textarea-1695745014578-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'radio-group-1695750951419') + ' ' + getLastResponseByRFID(158, 'textarea-1695750970379')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'radio-group-1695801749551') + ' ' + getLastResponseByRFID(160, 'textarea-1695801787134-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'radio-group-1695803703412') + ' ' + getLastResponseByRFID(161, 'textarea-1695803717691')} </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table i class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="10" rowspan="1" class="">
                                                12. If answer to question (1) above is “No” i.e. not all Principles are covered by a policy, reasons to be stated:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Questions
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 1
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 2
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 3
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 4
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 5
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 6
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 7
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 8
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                P 9
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                The entity does not consider the Principles material to its business (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'radio-group-1695734490115')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'radio-group-1695739892015')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'radio-group-1695740819258')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'radio-group-1695742341862')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'radio-group-1695744068000')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'radio-group-1695745038190')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'radio-group-1695751003316')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'radio-group-1695802283254')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'radio-group-1695803996380')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'radio-group-1695734509653')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'radio-group-1695739908429')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'radio-group-1695740842438')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'radio-group-1695742357104')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'radio-group-1695744077795')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'radio-group-1695745047297')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'radio-group-1695751020186')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'radio-group-1695802293359')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'radio-group-1695804056464')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                The entity does not have the financial or/human and technical resources available for the task (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'radio-group-1695734510493')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'radio-group-1695739908941')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'radio-group-1695740865564')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'radio-group-1695742357692')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'radio-group-1695744074098')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'radio-group-1695745047833')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'radio-group-1695751030380 ')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'radio-group-1695802294629')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'radio-group-1695804091899')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                It is planned to be done in the next financial year (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'radio-group-1695734549690')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'radio-group-1695739946725')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'radio-group-1695740879265')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'radio-group-1695742381466')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'radio-group-1695744073665')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'radio-group-1695745048290')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'radio-group-1695751040779')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'radio-group-1695802292391')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'radio-group-1695804097806')} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Any other reason (please specify)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(152, 'textarea-1695734560234-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(153, 'textarea-1695739961159-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(154, 'textarea-1695740890651-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(155, 'textarea-1695742388047-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(156, 'textarea-1695744135635-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(157, 'textarea-1695745104412-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(158, 'textarea-1695751050486-0')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(160, 'textarea-1695802333531')} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(161, 'textarea-1695804118293')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader id={"sectionc"} className="secHead">
                            SECTION C: PRINCIPLE WISE PERFORMANCE DISCLOSURE
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`This section is aimed at helping entities demonstrate their performance in integrating the 
Principles and Core Elements with key processes and decisions. The information sought is 
categorized as “Essential” and “Leadership”. While the essential indicators are expected to be 
disclosed by every entity that is mandated to file this report, the leadership indicators may be 
voluntarily disclosed by entities which aspire to progress to a higher level in their quest to be 
socially, environmentally and ethically responsible`}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader>
                        <sectionheader id={"secprin1"} className="secHead">
                            {"PRINCIPLE 1 Businesses should conduct and govern themselves with integrity, and in a manner that is Ethical, Transparent and Accountable"}
                        </sectionheader>
                        <sectionheader1 id={"secprin1i"}>
                            Essential Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" className="tdHead">
                                                {`Essential Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`1) Percentage coverage by training & awareness programmes on any of Principles during the financial year`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Segment
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total no of training and awareness programmes held
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Topics / principles covered under the training and its impact
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % age of persons in respective category covered by the awareness programmes
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Board of Directors (BOD)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0204', year)} </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(96, "text-1693738940818-0")}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0212', year) / getDataByDPArray(['DPS0254', 'DPS0252'], year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Key Managerial Personnel (KMP)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0205', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(96, "text-1693738947167")}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0213', year) / getDataByDPArray(['DPS0256', 'DPS0258'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees other than BOD and KMPs
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0206', year)}</td>
                                            <td colspan="1" rowspan="1" cclassName="text-center">{getLastResponseByRFID(96, "text-1693738946763")}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0214', year) / getDataByDP(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0207', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(96, "text-1693738946113")}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0215', year) / getDataByDP(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)) * 100)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                {`2) Details of fines / penalties /punishment/ award/ compounding fees/ settlement amount paid in proceedings (by the entity or by directors / KMPs) with regulators/ law enforcement agencies/ judicial institutions, in the financial year, in the following format (Note: the entity shall make disclosures on the basis of materiality as specified in Regulation 30 of SEBI (Listing Obligations and Disclosure Obligations) Regulations, 2015 and as disclosed on the entity’s website):`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" rowspan="1" className="tdHead">
                                                {`MONETARY`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead"></td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                NGRBC Principle
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Name of the regulatory/ enforcement agencies/ judicial institutions
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Amount (In INR)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Brief of Case
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Has appeal been preferred (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Case Details
                                            </td>
                                        </tr>
                                        {getLastResponseRFID104().map((item) => {
                                            if (item.type === 1 || item.type === 3) {
                                                return (
                                                    <>

                                                        {item.QLBP1E2D2 === 1 ?
                                                            <tr>
                                                                <td colspan="1" rowspan="1" class="">
                                                                    Penalty/ Fine
                                                                </td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D3}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D4}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D6}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D7}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D9 === true ? 'Yes' : item.QLBP1E2D9 === false ? 'No' : 'NA'}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E3D1}</td>

                                                            </tr> : item.QLBP1E2D2 === 2 ?
                                                                <tr>
                                                                    <td colspan="1" rowspan="1" class="">
                                                                        Settlement
                                                                    </td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D3}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D4}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D6}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D7}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D9 === true ? 'Yes' : item.QLBP1E2D9 === false ? 'No' : 'NA'}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E3D1}</td>
                                                                </tr> :
                                                                <tr>
                                                                    <td colspan="1" rowspan="1" class="">
                                                                        Compounding fee
                                                                    </td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D3}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D4}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D6}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D7}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D9 === true ? 'Yes' : item.QLBP1E2D9 === false ? 'No' : 'NA'}</td>
                                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E3D1}</td>
                                                                </tr>
                                                        }
                                                    </>
                                                )
                                            }

                                        })}

                                        <tr>
                                            <td colspan="7" rowspan="1" className="tdHead">
                                                {`NON – MONETARY`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead"></td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                NGRBC Principle
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Name of the regulatory/ enforcement agencies/ judicial institutions
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Amount (In INR)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Brief of Case
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Has appeal been preferred (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Case Details
                                            </td>
                                        </tr>
                                        {getLastResponseRFID104().map((item) => {
                                            if (item.type === 2 || item.type === 3) {
                                                console.log(item, 'asss')
                                                return (
                                                    <>
                                                        {item.QLBP1E2D8 === 1 ?
                                                            <tr>
                                                                <td colspan="1" rowspan="1" class="">
                                                                    Imprisonment
                                                                </td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D3}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D4}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D6}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D7}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D9 === true ? 'Yes' : item.QLBP1E2D9 === false ? 'No' : 'NA'}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E3D1}</td>

                                                            </tr>
                                                            :
                                                            <tr>
                                                                <td colspan="1" rowspan="1" class="">
                                                                    Punishment
                                                                </td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D3}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D4}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D6}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D7}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E2D9 === true ? 'Yes' : item.QLBP1E2D9 === false ? 'No' : 'NA'}</td>
                                                                <td colspan="1" rowspan="1" className="text-center">{item.QLBP1E3D1}</td>

                                                            </tr>
                                                        }
                                                    </>
                                                )
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <div class="gridlines-container">
                            <table class="gridlines">
                                <tbody>
                                    <tr>
                                        <td colspan="2" rowspan="1" class="">
                                            {`3) Of the instances disclosed in Question 2 above, details of the Appeal/ Revision preferred in cases where monetary or non-monetary action has been appealed`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" rowspan="1" className="tdHead">
                                            Case Details
                                        </td>
                                        <td colspan="1" rowspan="1" className="tdHead">
                                            Name of regulatory/ enforcement agencies/ judicial institutions
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" rowspan="1" class=""></td>
                                        <td colspan="1" rowspan="1" class=""></td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" rowspan="1" class=""></td>
                                        <td colspan="1" rowspan="1" class=""></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`4) Does the entity have an anti-corruption or anti-bribery policy? If yes, provide details in 
brief and if available, provide a web-link to the policy. `}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="2" rowspan="1" className="text-center"> {getLastResponseByRFID(91, "radio-group-1693741779255-0")}, Details - {getLastResponseByRFID(91, "textarea-1693741769835")} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">Link</td>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(91, "textarea-1694588993031")} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`5) Number of Directors/KMPs/employees/workers against whom disciplinary action was taken by any law enforcement agency for the charges of bribery/ corruption:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="2" class=""></td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Directors
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0018', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0018', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                KMPs
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0019', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0019', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0020', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0020', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0021', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0021', year - 1)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`6) Details of complaints with regard to conflict of interest:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="3" class=""></td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="2" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Number
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Remarks
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Number
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Remarks
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                No. of complaints received in relation to issues of Conflict of Interest of Directors
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0061', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPStr('DPK0124', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0061', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPStr('DPK0124', year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                No. of complaints received in relation to issues of Conflict of Interest of KMP
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0062', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPStr('DPK0125', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0062', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPStr('DPK0125', year - 1)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`7) Provide details of any corrective action taken or underway on issues related to fines / penalties / action taken by regulators/ law enforcement agencies/ judicial institutions, on cases of corruption and conflicts of interest. `}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(97, "text-1693751408550-0")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secprin1ii"}>
                            Leadership Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                {`Leadership Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`1) Awareness programmes conducted for value chain partners on any of the Principles `}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total no. of awareness programmes held
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Topics / principles covered in training
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                %age of value chain partners covered (by value of business done with such partners) under the awareness programmes
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">{getDataByDP('DPK0123', year)}</td>
                                            <td colspan="1" rowspan="1" class="">{getLastResponseByRFID(98, "text-1693752604883-0")}</td>
                                            <td colspan="1" rowspan="1" class="">{handleNaNAndInfinity((getDataByDP('DPK0121', year) / getDataByDP('DPK0122', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                2) Does the entity have processes in place to avoid/ manage conflict of interests involving
                                                members of the Board? (Yes/No) If Yes, provide details of the same
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">{getLastResponseByRFID(68, "radio-group-1693753001306-0")},{getLastResponseByRFID(68, "textarea-1691517712288-0")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>

                        <sectionheader id={"secprin2"} className="secHead">
                            {"PRINCIPLE 2 Businesses should provide goods and services in a manner that is sustainable and safe"}
                        </sectionheader>
                        <sectionheader1 id={"secprin2i"}>
                            Essential Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" className="tdHead">
                                                {`Essential Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`1) Percentage of R&D and capital expenditure (capex) investments in specific technologies 
to improve the environmental and social impacts of product and processes to total R&D
and capex investments made by the entity, respectively.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead"></td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Details of improvements in environmental & social impacts
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                R&D
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(((getDataByDP('DPAN029', year) - (getDataByDP('DPAN029', year) - getDataByDP('DPAN031', year))) / getDataByDP('DPAN029', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(((getDataByDP('DPAN029', year - 1) - (getDataByDP('DPAN029', year - 1) - getDataByDP('DPAN031', year - 1))) / getDataByDP('DPAN029', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN033', year)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Capex
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(((getDataByDP('DPAN030', year) - (getDataByDP('DPAN030', year) - getDataByDP('DPAN032', year))) / getDataByDP('DPAN030', year)) * 100)}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(((getDataByDP('DPAN030', year - 1) - (getDataByDP('DPAN030', year - 1) - getDataByDP('DPAN032', year - 1))) / getDataByDP('DPAN030', year - 1)) * 100)}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN034', year)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2a.Does the entity have procedures in place for sustainable sourcing? (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN035', year)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2b. If yes, what percentage of inputs were sourced sustainably?
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(((getDataByDPArray(['DPAN020', 'DPAN021', 'DPAN022', 'DPAN023'], year) - getDataByDP('DPAN036', year)) / getDataByDPArray(['DPAN020', 'DPAN021', 'DPAN022', 'DPAN023'], year)) * 100)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                3. Describe the processes in place to safely reclaim your products for reusing, recycling and disposing at the end of life, for
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (a) Plastics (including packaging)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center"> {getLastResponseByRFID(135, 'textarea-1695165346829-0')}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (b) E-waste
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(135, 'textarea-1695165486397-0')}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (c) Hazardous waste
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(135, 'textarea-1695165510332-0')}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (d) other waste
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(135, 'textarea-1695165533533-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                4. Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities (Yes / No). If yes, whether the waste collection plan is in line with the Extended Producer Responsibility (EPR) plan submitted to Pollution Control Boards? If
                                                not, provide steps taken to address the same.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(136, 'select-1695167038263-0')}-{getLastResponseByRFID(136, 'text-1695167121629-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secprin2ii"}>
                            Leadership Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                {`Leadership Indicators`}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                1) Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for manufacturing industry) or for its services (for service industry)? If yes, provide details in the following format?
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                NIC code
                                            </td>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                Name of Product /Service
                                            </td>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                % of total Turnover contributed
                                            </td>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                Boundary Life Cycle Perspective / Assessment was conducted
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Whether conducted by independent external agency
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Results communicated in public domain
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                (Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                (Yes/No)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead"></td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                If yes, provide the web-link
                                            </td>
                                        </tr>
                                        {getLastResponseRF139().map((item) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP2LQ1D2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP2LQ1D3B}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP2LQ1D4}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">-</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP2LQ1D5}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP2LQ1D6}</td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                2) If there are any significant social or environmental concerns and/or risks arising from production or disposal of your products / services, as identified in the Life Cycle Perspective / Assessments (LCA) or through any other means, briefly describe
                                                the same along-with action taken to mitigate the same
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Name of Product / Service
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Description of the risk / concern
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Action Taken
                                            </td>
                                        </tr>
                                        {getLastResponseRF140().map((item) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP2LQ2D1B}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP2LQ2D2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP2LQ2D3}</td>


                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                3) Percentage of recycled or reused input material to total material (by value) used in production (for manufacturing industry) or providing services (for service industry).
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                Indicate Input Material
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Recycled or re-used input material to total material
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        {getManualDCF195(195, year).map((item) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.DPBP2LQ3D1} </td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.a} </td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.b} </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                4) Of the products and packaging reclaimed at end of life of products, amount (in metric tonnes) reused, recycled, and safely disposed, as per the following format:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead"></td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)} Current Financial Year
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)} Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Re-Used
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Recycled
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Safely Disposed
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Re-Used
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Recycled
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Safely Disposed
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Plastics (including packaging)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN048', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN040', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN044', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN048', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN040', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN044', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                E-waste
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN037', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN041', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN045', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN037', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN041', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN045', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Hazardous waste
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN038', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN042', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN046', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN038', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN042', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN046', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other waste
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN039', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN043', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN047', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN039', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN043', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPAN047', year - 1)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                5) Reclaimed products and their packaging materials (as percentage of products sold) for each product category.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Indicate product category
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Reclaimed products & packaging materials as % of total products sold
                                            </td>
                                        </tr>

                                        {getManualDCF196(196, year).map((item) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.DPBP2LQ5D1} </td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.a} </td>


                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader id={"secprin3"} className="secHead">
                            PRINCIPLE 3 Businesses should respect and promote the well-being of all employees, including those in their value chains
                        </sectionheader>
                        <sectionheader1 id={"secprin3i"}>
                            Essential Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="12" rowspan="1" className="tdHead">
                                                {`Essential Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="12" rowspan="1" class="">
                                                {`1. a. Details of measures for the well-being of employees:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                Category
                                            </td>
                                            <td colspan="11" rowspan="1" className="tdHead">
                                                % of employees covered
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total (A)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Health insurance
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Accident insurance
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Maternity benefits
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Paternity Benefits
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Day Care facilities
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No  (B)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (B/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (C)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (C/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (D)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (D/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (E)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (E/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (F)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (F/A)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="12" rowspan="1" className="tdHead">
                                                Permanent employees
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> {getDataByDP('DPS0072', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0072', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0084', year) / getDataByDP('DPS0072', year)) * 100)}  </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0096', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0096', year) / getDataByDP('DPS0072', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0149', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0149', year) / getDataByDP('DPS0072', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0157', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0157', year) / getDataByDP('DPS0072', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0116', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0116', year) / getDataByDP('DPS0072', year)) * 100)}</td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0073', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0085', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0085', year) / getDataByDP('DPS0073', year)) * 100)} </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0097', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0097', year) / getDataByDP('DPS0073', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0150', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0150', year) / getDataByDP('DPS0073', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0158', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0158', year) / getDataByDP('DPS0073', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0117', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0117', year) / getDataByDP('DPS0073', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0072', 'DPS0073'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0084', 'DPS0085'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0084', 'DPS0085'], year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0096', 'DPS0097'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0096', 'DPS0097'], year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0149', 'DPS0150'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0149', 'DPS0150'], year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0157', 'DPS0158'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0157', 'DPS0158'], year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0116', 'DPS0117'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0116', 'DPS0117'], year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="12" rowspan="1" className="tdHead">
                                                Other than Permanent employees
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0075', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0087', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0087', year) / getDataByDP('DPS0075', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0099', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0099', year) / getDataByDP('DPS0075', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0151', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0151', year) / getDataByDP('DPS0075', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0159', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0159', year) / getDataByDP('DPS0075', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0119', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0119', year) / getDataByDP('DPS0075', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0076', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0088', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0088', year) / getDataByDP('DPS0076', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0100', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0100', year) / getDataByDP('DPS0076', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0152', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0152', year) / getDataByDP('DPS0076', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0160', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0160', year) / getDataByDP('DPS0076', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0120', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0120', year) / getDataByDP('DPS0076', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0075', 'DPS0076'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0087', 'DPS0088'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0087', 'DPS0088'], year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0099', 'DPS0100'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0099', 'DPS0100'], year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0151', 'DPS0152'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0151', 'DPS0152'], year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0159', 'DPS0160'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0159', 'DPS0160'], year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0119', 'DPS0120'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0119', 'DPS0120'], year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="12" rowspan="1" class="">
                                                {`b. Details of measures for the well-being of workers:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                Category
                                            </td>
                                            <td colspan="11" rowspan="1" className="tdHead">
                                                % of employees covered
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total (A)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Health insurance
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Accident insurance
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Maternity benefits
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Paternity Benefits
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Day Care facilities
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No  (B)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (B/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (C)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (C/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (D)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (D/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (E)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (E/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (F)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (F/A)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="12" rowspan="1" className="tdHead">
                                                Permanent workers
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> {getDataByDP('DPS0078', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0090', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0090', year) / getDataByDP('DPS0078', year)) * 100)} </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0102', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0102', year) / getDataByDP('DPS0078', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0153', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0153', year) / getDataByDP('DPS0078', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0161', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0161', year) / getDataByDP('DPS0078', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0122', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0122', year) / getDataByDP('DPS0078', year)) * 100)}</td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0079', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0091', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0091', year) / getDataByDP('DPS0079', year)) * 100)} </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0103', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0103', year) / getDataByDP('DPS0079', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0154', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0154', year) / getDataByDP('DPS0079', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0162', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0162', year) / getDataByDP('DPS0079', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0123', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0123', year) / getDataByDP('DPS0079', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0078', 'DPS0079'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0090', 'DPS0091'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0090', 'DPS0091'], year) / getDataByDPArray(['DPS0078', 'DPS0079'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0102', 'DPS0103'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0102', 'DPS0103'], year) / getDataByDPArray(['DPS0078', 'DPS0079'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0153', 'DPS0154'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0153', 'DPS0154'], year) / getDataByDPArray(['DPS0078', 'DPS0079'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0161', 'DPS0162'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0161', 'DPS0162'], year) / getDataByDPArray(['DPS0078', 'DPS0079'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0122', 'DPS0123'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0122', 'DPS0123'], year) / getDataByDPArray(['DPS0078', 'DPS0079'], year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="12" rowspan="1" className="tdHead">
                                                Other than Permanent workers
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0081', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0093', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0093', year) / getDataByDP('DPS0081', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0105', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0105', year) / getDataByDP('DPS0081', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0155', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0155', year) / getDataByDP('DPS0081', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0163', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0163', year) / getDataByDP('DPS0081', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0125', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0125', year) / getDataByDP('DPS0081', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0082', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0094', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0094', year) / getDataByDP('DPS0082', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0106', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0106', year) / getDataByDP('DPS0082', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0156', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0156', year) / getDataByDP('DPS0082', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0164', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0164', year) / getDataByDP('DPS0082', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0126', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0126', year) / getDataByDP('DPS0082', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0081', 'DPS0082'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0093', 'DPS0094'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0093', 'DPS0094'], year) / getDataByDPArray(['DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0105', 'DPS0106'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0105', 'DPS0106'], year) / getDataByDPArray(['DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0155', 'DPS0156'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0155', 'DPS0156'], year) / getDataByDPArray(['DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0163', 'DPS0164'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0163', 'DPS0164'], year) / getDataByDPArray(['DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0125', 'DPS0126'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0125', 'DPS0126'], year) / getDataByDPArray(['DPS0081', 'DPS0082'], year)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                {`2) Details of retirement benefits, for Current FY and Previous Financial Year.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                Benefits
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. of employees covered as % of total employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. of workers covered as % of total employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Deducted deposited with the authority (Y/N/N.A.)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. of employees covered as % of total employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. of workers covered as % of total employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Deducted deposited with the authority (Y/N/N.A.)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                PF
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0073', year) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0081', year) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(109, 'radio-group-1694580464762-0')}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0073', year - 1) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0081', year - 1) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(109, 'radio-group-1694580464762-0')}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Gratuity
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0074', year) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0082', year) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(109, 'radio-group-1694580511145')}</td>

                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0074', year - 1) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0082', year - 1) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(109, 'radio-group-1694580511145')}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                ESI
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0075', year) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0083', year) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(109, 'radio-group-1694580510459')}</td>

                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0075', year - 1) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0083', year - 1) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(109, 'radio-group-1694580510459')}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Others – specify
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0076', year) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0084', year) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(109, 'radio-group-1694580557317')}</td>

                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0076', year - 1) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0084', year - 1) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(109, 'radio-group-1694580557317')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`3) Accessibility of workplaces
Are the premises / offices of the entity accessible to differently abled employees and workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016? If not, whether any steps are being taken by the entity in this regard.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">{getLastResponseByRFID(105, 'radio-group-1694418679993-0')}-{getLastResponseByRFID(105, 'text-1694512774579-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`4) Does the entity have an equal opportunity policy as per the Rights of Persons with Disabilities Act, 2016? If so, provide a web-link to the policy`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">{getLastResponseByRFID(107, 'radio-group-1694423303602-0')},{getLastResponseByRFID(107, 'text-1694423299836-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`5)  Return to work and Retention rates of permanent employees and workers that took parental leave.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Gender
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Permanent employees
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Permanent workers
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Return to work rate
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Retention rate
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Return to work rate
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Retention rate
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0138', year) / getDataByDP('DPS0140', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0142', year) / getDataByDP('DPS0144', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0245', year) / getDataByDP('DPS0247', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0249', year) / getDataByDP('DPS0251', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0137', year) / getDataByDP('DPS0139', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0141', year) / getDataByDP('DPS0143', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0244', year) / getDataByDP('DPS0246', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0248', year) / getDataByDP('DPS0250', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0138', 'DPS0137'], year) / getDataByDPArray(['DPS0139', 'DPS0140'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0141', 'DPS0142'], year) / getDataByDPArray(['DPS0143', 'DPS0144'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0244', 'DPS0245'], year) / getDataByDPArray(['DPS0246', 'DPS0247'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0249', 'DPS0248'], year) / getDataByDPArray(['DPS0250', 'DPS0251'], year)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`6) Is there a mechanism available to receive and redress grievances for the following categories of employees and worker? If yes, give details of the mechanism in brief.						`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead"></td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Yes/No
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                (If Yes, give details of the mechanism)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                Permanent Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(99, 'radio-group-1693819813884-0') + ' - ' + getLastResponseByRFID(99, 'text-1693819834932-0')}</td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                Other than Permanent Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(99, 'radio-group-1693819813884-0') + ' - ' + getLastResponseByRFID(99, 'text-1693819858027')}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                Permanent Employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(99, 'radio-group-1693819813884-0') + ' - ' + getLastResponseByRFID(99, 'text-1693819871668')}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                Other than Permanent Employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(99, 'radio-group-1693819813884-0') + ' - ' + getLastResponseByRFID(99, 'text-1693819889490')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                {`7) Membership of employees and worker in association(s) or Unions recognised by the 
listed entity:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="4" className="tdHead">
                                                Category
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total employees / workers (A)
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                No. of employees / workers part of association(s) or Union (B)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % of
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total employees / workers (C)
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                No. of employees / workers part of association(s) or Union (D)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % of
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                employees / workers part of association(s) or Union (B/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                employees / workers part of association(s) or Union (D/C)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total Permanent Employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0072', 'DPS0073'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPR0024', 'DPR0025'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0024', 'DPR0025'], year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0072', 'DPS0073'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPR0024', 'DPR0025'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0024', 'DPR0025'], year - 1) / getDataByDPArray(['DPS0072', 'DPS0073'], year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0072', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0025', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0025', year) / getDataByDP('DPS0072', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0072', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0025', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0025', year - 1) / getDataByDP('DPS0072', year - 1)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0073', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0024', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0024', year) / getDataByDP('DPS0073', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0073', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0024', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0024', year - 1) / getDataByDP('DPS0073', year - 1)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total Permanent Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0075', 'DPS0076'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPR0026', 'DPR0027'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0026', 'DPR0027'], year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0075', 'DPS0076'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPR0026', 'DPR0027'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0026', 'DPR0027'], year - 1) / getDataByDPArray(['DPS0075', 'DPS0076'], year - 1)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0075', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0027', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0027', year) / getDataByDP('DPS0075', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0075', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0027', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0027', year - 1) / getDataByDP('DPS0075', year - 1)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0076', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0026', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0026', year) / getDataByDP('DPS0076', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0076', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0026', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0026', year - 1) / getDataByDP('DPS0076', year - 1)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="11" rowspan="1" class="">
                                                {`8) Details of training given to employees and workers:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="4" className="tdHead">
                                                Category
                                            </td>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total (A)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                On Health and safety measures
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                On Skill upgradation
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total (D)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                On Health and safety measures
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                On Skill upgradation
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No (B)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (B/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. (C)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (C/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. (E)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (E/D)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. (F)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (F/D)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="11" rowspan="1" className="tdHead">
                                                EMPLOYESS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0012', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0013', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0013', year) / getDataByDP('DPS0012', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0014', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0014', year) / getDataByDP('DPS0012', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0012', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0013', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0013', year - 1) / getDataByDP('DPS0012', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0014', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0014', year - 1) / getDataByDP('DPS0012', year - 1)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0015', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0016', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0016', year) / getDataByDP('DPS0015', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0017', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0017', year) / getDataByDP('DPS0015', year)) * 100)}</td>

                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0015', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0016', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0016', year - 1) / getDataByDP('DPS0015', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0017', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0017', year - 1) / getDataByDP('DPS0015', year - 1)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0012', 'DPS0015'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0013', 'DPS0016'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0013', 'DPS0016'], year) / getDataByDPArray(['DPS0012', 'DPS0015'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0014', 'DPS0017'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0014', 'DPS0017'], year) / getDataByDPArray(['DPS0012', 'DPS0015'], year)) * 100)}</td>

                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0012', 'DPS0015'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0013', 'DPS0016'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0013', 'DPS0016'], year - 1) / getDataByDPArray(['DPS0012', 'DPS0015'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0014', 'DPS0017'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0014', 'DPS0017'], year - 1) / getDataByDPArray(['DPS0012', 'DPS0015'], year - 1)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="11" rowspan="1" className="tdHead">
                                                WORKERS
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0018', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0019', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0019', year) / getDataByDP('DPS0018', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0020', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0020', year) / getDataByDP('DPS0018', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0018', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0019', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0019', year - 1) / getDataByDP('DPS0018', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0020', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0020', year - 1) / getDataByDP('DPS0018', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0021', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0022', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0022', year) / getDataByDP('DPS0021', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0023', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0023', year) / getDataByDP('DPS0021', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0021', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0022', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0022', year - 1) / getDataByDP('DPS0021', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0023', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0023', year - 1) / getDataByDP('DPS0021', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0018', 'DPS0021'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0019', 'DPS0022'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0019', 'DPS0022'], year) / getDataByDPArray(['DPS0018', 'DPS0021'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0020', 'DPS0023'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0020', 'DPS0023'], year) / getDataByDPArray(['DPS0018', 'DPS0021'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0018', 'DPS0021'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0019', 'DPS0022'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0019', 'DPS0022'], year - 1) / getDataByDPArray(['DPS0018', 'DPS0021'], year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0020', 'DPS0023'], year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPS0020', 'DPS0023'], year - 1) / getDataByDPArray(['DPS0018', 'DPS0021'], year - 1)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                {`9) Details of performance and career development reviews of employees and worker:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                Category
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total (A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. (B)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Percentage (B/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total (C)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. (D)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Percentage (D/C)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="7" rowspan="1" className="tdHead">
                                                EMPLOYESS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>

                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0072', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0138', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0138', year) / getDataByDP('DPS0072', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0072', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0138', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0138', year - 1) / getDataByDP('DPS0072', year - 1)) * 100)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0073', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0139', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0139', year) / getDataByDP('DPS0073', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0073', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0139', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0139', year - 1) / getDataByDP('DPS0073', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{(getDataByDPArray(['DPS0072', 'DPS0073'], year))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{(getDataByDPArray(['DPR0138', 'DPR0139'], year))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0138', 'DPR0139'], year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{(getDataByDPArray(['DPS0072', 'DPS0073'], year - 1))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{(getDataByDPArray(['DPR0138', 'DPR0139'], year - 1))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0138', 'DPR0139'], year - 1) / getDataByDPArray(['DPS0072', 'DPS0073'], year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" rowspan="1" className="tdHead">
                                                WORKERS
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0075', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0140', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0140', year) / getDataByDP('DPS0075', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0075', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0140', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0140', year - 1) / getDataByDP('DPS0075', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0076', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0141', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0141', year) / getDataByDP('DPS0076', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0076', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPR0141', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPR0141', year - 1) / getDataByDP('DPS0076', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{(getDataByDPArray(['DPS0075', 'DPS0076'], year))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{(getDataByDPArray(['DPR0140', 'DPR0141'], year))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0140', 'DPR0141'], year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{(getDataByDPArray(['DPS0075', 'DPS0076'], year - 1))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{(getDataByDPArray(['DPR0140', 'DPR0141'], year - 1))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0140', 'DPR0141'], year - 1) / getDataByDPArray(['DPS0075', 'DPS0076'], year - 1)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`10) Health and safety management system:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                a. Whether an occupational health and safety management system has been implemented by the entity? (Yes/ No). If yes, the coverage such system?
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(37, 'textarea-1691513896018')}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                b. What are the processes used to identify work-related hazards and assess risks on a routine and non-routine basis by the entity?
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(37, 'textarea-1691513935698')}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                c. Whether you have processes for workers to report the work related hazards and to remove themselves from such risks. (Y/N)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(37, 'radio-group-1691514038444-0')}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                d. Do the employees/ worker of the entity have access to non-occupational medical and healthcare services? (Yes/ No)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(37, 'radio-group-1691514111138')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`11) Details of safety related incidents, in the following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Safety Incident/ Number
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Category
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                Current Financial Year
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" class="">
                                                Lost Time Injury
                                                Frequency Rate
                                                (LTIFR) (per one
                                                million-person hours
                                                worked)
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0036', year) * 1000000) / getDataByDP('DPS0035', year))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0036', year - 1) * 1000000) / getDataByDP('DPS0035', year - 1))}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0043', year) * 1000000) / getDataByDP('DPS0042', year))}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPS0043', year - 1) * 1000000) / getDataByDP('DPS0042', year - 1))}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" class="">
                                                Total recordable work-related injuries
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0034', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0034', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0041', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPS0041', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" class="">
                                                No. of fatalities
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0032', 'DPS0050'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0032', 'DPS0050'], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0039', 'DPS0053'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0039', 'DPS0053'], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" class="">
                                                High consequence work-related injury or ill-health (excluding fatalities
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0033', 'DPS0051'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0033', 'DPS0051'], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0040', 'DPS0054'], year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPArray(['DPS0040', 'DPS0054'], year - 1)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`12) Describe the measures taken by the entity to ensure a safe and healthy work place.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(35, 'textarea-1694269976295-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                {`13) Number of Complaints on the following made by employees and workers:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead"></td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Filed during the year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Pending resolution at the end of year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Remarks
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Filed during the year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Pending resolution at the end of year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Remarks
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Working Conditions
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0126', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0127', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPStr('DPK0128', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0126', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0127', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPStr('DPK0128', year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Health & Safety
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0129', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0130', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPStr('DPK0131', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0129', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0130', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDPStr('DPK0131', year - 1)}</td>


                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Assessments
                                            </td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`14) Assessments for the year:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class="">
                                                % of plants & offices were assessed (by entity / statutory authorities / third parties)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Health and safety practices
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPK0132', year) / getDataByDP('DPK0210', year)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Working Conditions
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPK0133', year) / getDataByDP('DPK0210', year)) * 100)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`15) Provide details of any corrective action taken or underway to address safety-related incidents (if any) and on significant risks / concerns arising from assessments of health & safety practices and working conditions`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(100, 'text-1693821807464-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secprin3ii"}>
                            Leadership Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="secHead">
                                                {`Leadership Indicators `}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`1) Does the entity extend any life insurance or any compensatory package in the event of 
death of `}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (A) Employees (Y/N)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(108, 'radio-group-1694532215758-0')}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (B) Workers (Y/N)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(108, 'radio-group-1694532257644')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                2) Provide the measures undertaken by the entity to ensure that statutory dues have been deducted and deposited by the value chain partners.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(106, 'text-1694422669719-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                3) Provide the number of employees / workers having suffered high consequence work-related injury / ill-health / fatalities (as reported in Q11 of Essential Indicators above), who have been are rehabilitated and placed in suitable employment or whose
                                                family members have been placed in suitable employment:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead"></td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Total no. of affected employees/ workers
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                No. of employees/workers that are rehabilitated & placed in suitable employment or whose family members have been placed in suitable employment
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0136', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0136', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0136', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0136', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Workers
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0137', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0137', year - 1)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0137', year)}</td>
                                            <td colspan="1" rowspan="1" className="text-center">{getDataByDP('DPK0137', year - 1)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                4) Does the entity provide transition assistance programs to facilitate continued employability and the management of career endings resulting from retirement or termination of employment? (Yes/ No)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(43, 'radio-group-1694510530253-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`5) Details on assessment of value chain partners:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class="">
                                                % of value chain partners (by value of business done with such partners) were assessed
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Health and safety practices
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPK0082', year) / getDataByDP('DPK0190', year) * 100))}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Working Conditions
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPK0138', year) / getDataByDP('DPK0190', year) * 100))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                6) Provide details of any corrective actions taken or underway to address significant risks / concerns arising from assessments of health and safety practices and working conditions of value chain partners.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(101, 'text-1693822027670-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader id={"secprin4"} className="secHead">
                            PRINCIPLE 4:  Businesses should respect the interests of and be responsive to all its stakeholders
                        </sectionheader>
                        <sectionheader1 id={"secprin4i"}>
                            Essential Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                {`Essential Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`1)  Describe the processes for identifying key stakeholder groups of the entity`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(54, 'text-1691433092058-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`2) List stakeholder groups identified as key for your entity and the frequency of engagement with each stakeholder group.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Stakeholder Group
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Identified as Vulnerable & Marginalized Group(Yes/No)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                "Channels of communication (Email, SMS, Newspaper, Pamphlets, Advertisement, Community Meetings, Notice Board, Website), Other"
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                "Frequency of engagement (Annually/ Half yearly/ Quarterly / others – please specify)"
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Purpose and scope of engagement include key topics and concerns raised during such engagement
                                            </td>
                                        </tr>
                                        {getLastResponseRF111().map((item) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP4E2D1}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP4E2D2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP4E2D3}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP4E2D4}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP4E2D5}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secprin4ii"}>
                            Leadership Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                {`Leadership Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`1) Provide the processes for consultation between stakeholders and the Board on 
economic, environmental, and social topics or if consultation is delegated, how is 
feedback from such consultations provided to the Board.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(55, 'text-1691435259253-0')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`2) Whether stakeholder consultation is used to support the identification and 
management of environmental, and social topics (Yes / No). If so, provide details of 
instances as to how the inputs received from stakeholders on these topics were 
incorporated into policies and activities of the entity.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center"> {getLastResponseByRFID(55, 'text-1691435333363')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`3) Provide details of instances of engagement with, and actions taken to, address the 
concerns of vulnerable/ marginalized stakeholder groups. `}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">{getLastResponseByRFID(56, 'text-1691435570995-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader id={"secprin5"} className="secHead">
                            PRINCIPLE 5 Businesses should respect and promote human rights
                        </sectionheader>
                        <sectionheader1 id={"secprin5i"}>
                            Essential Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="11" rowspan="1" className="tdHead">
                                                {`Essential_ Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="11" rowspan="1" class="">
                                                {`1) Employees and workers who have been provided training on human rights issues and policy(ies) of the entity, in the following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead">
                                                Category
                                            </td>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total (A)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                No. of employees / workers covered (B)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Percentage (B/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Total (C)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                No. of employees / workers covered (D)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Percentage (D/C)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="11" rowspan="1" className="tdHead">
                                                EMPLOYESS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Permanent
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0072', 'DPS0073'], year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDP('DPR0126', year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0126', year) / getDataByDPArray(['DPS0072', 'DPS0073'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0072', 'DPS0073'], year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDP('DPR0126', year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0126', year - 1) / getDataByDPArray(['DPS0072', 'DPS0073'], year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other than Permanent
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0075', 'DPS0076'], year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDP('DPR0127', year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0127', year) / getDataByDPArray(['DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0075', 'DPS0076'], year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDP('DPR0127', year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0127', year - 1) / getDataByDPArray(['DPS0075', 'DPS0076'], year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total Employees
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDPArray(['DPR0126', 'DPR0127'], year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0126', 'DPR0127'], year) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDPArray(['DPR0126', 'DPR0127'], year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0126', 'DPR0127'], year - 1) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="11" rowspan="1" className="tdHead">
                                                WORKERS
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Permanent
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0078', 'DPS0079'], year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDP('DPR0132', year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0132', year) / getDataByDPArray(['DPS0078', 'DPS0079'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0078', 'DPS0079'], year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDP('DPR0132', year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0132', year - 1) / getDataByDPArray(['DPS0078', 'DPS0079'], year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other than Permanent
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0081', 'DPS0082'], year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDP('DPR0133', year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0133', year) / getDataByDPArray(['DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0081', 'DPS0082'], year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDP('DPR0133', year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0133', year - 1) / getDataByDPArray(['DPS0081', 'DPS0082'], year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total Workers
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDPArray(['DPR0132', 'DPR0133'], year)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0132', 'DPR0133'], year) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{getDataByDPArray(['DPR0132', 'DPR0133'], year - 1)}</td>
                                            <td colspan="2" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDPArray(['DPR0132', 'DPR0133'], year - 1) / getDataByDPArray(['DPS0078', 'DPS0079', 'DPS0081', 'DPS0082'], year - 1)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="11" rowspan="1" class="">
                                                {`2) Details of minimum wages paid to employees and workers, in the following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="4" className="tdHead">
                                                Category
                                            </td>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="5" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total (A)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Equal to Minimum Wage
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                More than Minimum Wage
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Total (D)
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                Equal to Minimum Wage
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                More than Minimum Wage
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. (B)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (B/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. (C)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (C/A)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. (E)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (E/D)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                No. (F)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                % (F/D)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="11" rowspan="1" className="tdHead">
                                                EMPLOYEES
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="11" rowspan="1" className="text-center">
                                                Permanent
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0072', year)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0209', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0209', year) / getDataByDP('DPS0072', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0219', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0219', year) / getDataByDP('DPS0072', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0072', year - 1)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0209', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0209', year - 1) / getDataByDP('DPS0072', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0219', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0219', year - 1) / getDataByDP('DPS0072', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0073', year)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0210', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0210', year) / getDataByDP('DPS0073', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0220', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0220', year) / getDataByDP('DPS0073', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0073', year - 1)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0210', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0210', year - 1) / getDataByDP('DPS0073', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0220', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0220', year - 1) / getDataByDP('DPS0073', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="11" rowspan="1" className="text-center">
                                                Other than Permanent
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0075', year)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0212', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0212', year) / getDataByDP('DPS0075', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0222', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0222', year) / getDataByDP('DPS0075', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0075', year - 1)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0212', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0212', year - 1) / getDataByDP('DPS0075', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0222', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0222', year - 1) / getDataByDP('DPS0075', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0076', year)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0213', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0213', year) / getDataByDP('DPS0076', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0223', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0223', year) / getDataByDP('DPS0076', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0076', year - 1)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0213', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0213', year - 1) / getDataByDP('DPS0076', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0223', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0223', year - 1) / getDataByDP('DPS0076', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="11" rowspan="1" className="tdHead">
                                                WORKERS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="11" rowspan="1" className="text-center">
                                                Permanent
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0078', year)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0214', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0214', year) / getDataByDP('DPS0078', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0224', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0224', year) / getDataByDP('DPS0078', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0078', year - 1)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0214', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0214', year - 1) / getDataByDP('DPS0078', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0224', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0224', year - 1) / getDataByDP('DPS0078', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0079', year)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0215', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0215', year) / getDataByDP('DPS0079', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0225', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0225', year) / getDataByDP('DPS0079', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0079', year - 1)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0215', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0215', year - 1) / getDataByDP('DPS0079', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0225', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0225', year - 1) / getDataByDP('DPS0079', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="11" rowspan="1" className="text-center">
                                                Other than Permanent
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Male
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0081', year)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0217', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0217', year) / getDataByDP('DPS0081', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0227', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0227', year) / getDataByDP('DPS0081', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0081', year - 1)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0217', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0217', year - 1) / getDataByDP('DPS0081', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0227', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0227', year - 1) / getDataByDP('DPS0081', year - 1)) * 100)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Female
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0082', year)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0218', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0218', year) / getDataByDP('DPS0082', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0228', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0228', year) / getDataByDP('DPS0082', year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0082', year - 1)} </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0218', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0218', year - 1) / getDataByDP('DPS0082', year - 1)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPR0228', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPR0228', year - 1) / getDataByDP('DPS0082', year - 1)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`3) Details of remuneration/salary/wages, in the following format:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="2" class=""></td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                MALE
                                            </td>
                                            <td colspan="2" rowspan="1" className="tdHead">
                                                FEMALE
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Number
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Median remuneration/ salary/ wages
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Number
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Median remuneration/ salary/ wages
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Board of Directors (BoD)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0252', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0253', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0254', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0255', year)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Key Managerial Personnel
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0256', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0257', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0258', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0259', year)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Employees other than BoD and KMP
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPS0214', year) / getDataByDPArray(['DPS0072', 'DPS0073', 'DPS0075', 'DPS0076'], year)) * 100)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0261', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPS0073', 'DPS0076'], year) - (getDataByDPArray(['DPS0254', 'DPS0258'], year)))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0263', year)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Workers
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0078', 'DPS0081'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0265', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPS0079', 'DPS0082'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPS0267', year)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`4) Do you have a focal point (Individual/ Committee) responsible for addressing human 
rights impacts or issues caused or contributed to by the business? (Yes/No)`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(128, 'radio-group-1695136082807-0')} {getLastResponseByRFID(128, 'text-1695136067924-0')}  </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`5)  Describe the internal mechanisms in place to redress grievances related to human rights 
issues. `}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(127, 'text-1695136036827-0')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                {`6) Number of Complaints on the following made by employees and workers:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" className="tdHead"></td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Current Financial Year
                                            </td>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Filed during the year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Pending resolution at the end of year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Remarks
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Filed during the year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Pending resolution at the end of year
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Remarks
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Sexual Harassment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0165', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0171', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0177', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0165', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0171', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0177', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Discrimination at workplace
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0166', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0172', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0178', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0166', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0172', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0178', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Child Labour
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0167', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0173', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0179', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0167', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0173', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0179', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Forced Labour/Involuntary Labour
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0168', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0174', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0180', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0168', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0174', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0180', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Wages
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0169', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0175', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0181', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0169', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0175', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0181', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other human rights related issues
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0170', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0176', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0182', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0170', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPK0176', year - 1)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPStr('DPK0182', year - 1)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`7)  Mechanisms to prevent adverse consequences to the complainant in discrimination and 
harassment cases.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(130, 'text-1695139065862-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`8) Do human rights requirements form part of your business agreements and contracts? (Yes/No)`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(129, 'radio-group-1695136650409-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`9)  Assessments for the year:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class="">
                                                % of your plants and offices were assessed (by entity or statutory authorities or third parties)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Child labour
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0184', year) / getDataByDP('DPK0210', year)) * 100)} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Forced/ involuntary labour
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0185', year) / getDataByDP('DPK0210', year)) * 100)} </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Sexual harassment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0186', year) / getDataByDP('DPK0210', year)) * 100)} </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Discrimination at workplace
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0187', year) / getDataByDP('DPK0210', year)) * 100)} </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Wages
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0188', year) / getDataByDP('DPK0210', year)) * 100)} </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Others – specify
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0189', year) / getDataByDP('DPK0210', year)) * 100)} </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`10) Provide details of any corrective actions taken or underway to address significant risks / 
concerns arising from the assessments at Question 9 above.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(142, 'text-1695198293055-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secprin5ii"}>
                            Leadership Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                {`Leadership Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`1) Details of a business process being modified / introduced as a result of addressing human rights grievances/complaints.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(131, 'text-1695139606895-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`2)  Details of the scope and coverage of any Human rights due-diligence conducted.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(132, 'text-1695139902158-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`3) Is the premise/office of the entity accessible to differently abled visitors, as per the 
requirements of the Rights of Persons with Disabilities Act, 2016?`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(120, 'radio-group-1695116360449-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`4) Is the premise/office of the entity accessible to differently abled visitors, as per the 
requirements of the Rights of Persons with Disabilities Act, 2016?`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class="">
                                                % of value chain partners (by value of business done with such partners) were assessed
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Child labour
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0191', year) / getDataByDP('DPK0190', year)) * 100)} </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Forced/ involuntary labour
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0192', year) / getDataByDP('DPK0190', year)) * 100)} </td>


                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Sexual harassment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0193', year) / getDataByDP('DPK0190', year)) * 100)} </td>


                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Discrimination at workplace
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0194', year) / getDataByDP('DPK0190', year)) * 100)} </td>


                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Wages
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0195', year) / getDataByDP('DPK0190', year)) * 100)} </td>


                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Others – specify
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getDataByDP('DPK0196', year) / getDataByDP('DPK0190', year)) * 100)} </td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`5) Provide details of any corrective actions taken or underway to address significant risks / 
concerns arising from the assessments at Question 4 above.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(141, 'text-1695198158660-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader id={"secprin6"} className="secHead">
                            PRINCIPLE 6: Businesses should respect and make efforts to protect and restore the
                            environment
                        </sectionheader>
                        <sectionheader1 id={"secprin6i"}>
                            Essential Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                {`Essential Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`1) Details of total energy consumption (in Joules or multiples) and energy intensity, in the 
following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total electricity consumption (A)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center"> {((getDataByDP('DPA0156', year) + getDataByDP('DPA0157', year)) * 0.0000036)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{((getDataByDP('DPA0156', year - 1) + getDataByDP('DPA0157', year - 1)) * 0.0000036)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total fuel consumption(B)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0336', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0336', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Energy consumption through other sources (C)
                                            </td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total energy consumption(A+B+C+D)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center"> {handleNaNAndInfinity(((getDataByDPArray(['DPA0156', 'DPA0157'], year)) * 0.0000036) + getDataByDP('DPA0336', year))}</td>

                                            <td colspan="1" rowspan="1" class="text-center"> {handleNaNAndInfinity(((getDataByDPArray(['DPA0156', 'DPA0157'], year - 1)) * 0.0000036) + getDataByDP('DPA0336', year - 1))}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Energy intensity per rupee of turnover
                                                (Total energy consumption/ turnover in rupees)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(handleNaNAndInfinity(((getDataByDPArray(['DPA0156', 'DPA0157'], year)) * 0.0000036) + getDataByDP('DPA0336', year)) / getDataByDP('DPK0039', year))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(handleNaNAndInfinity(((getDataByDPArray(['DPA0156', 'DPA0157'], year - 1)) * 0.0000036) + getDataByDP('DPA0336', year - 1)) / getDataByDP('DPK0039', year - 1))}</td>


                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Energy intensity (optional) – the relevant metric may be selected by the entity
                                            </td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`2) Does the entity have any sites / facilities identified as designated consumers (DCs) under 
the Performance, Achieve and Trade (PAT) Scheme of the Government of India? (Y/N) `}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(145, 'select-1695208883968-0')}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`If 
yes, disclose whether targets set under the PAT scheme have been achieved. In case targets 
have not been achieved, provide the remedial action taken, if any`}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(145, 'textarea-1695208932000-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`3) Provide details of the following disclosures related to water, in the following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external 
agency? (Y/N) If yes, name of the external agency.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center">
                                                Water withdrawal by source (in kilolitres)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (i) Surface water
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0010', 'DPA0011'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0010', 'DPA0011'], year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (ii) Groundwater
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0013', 'DPA0014'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0013', 'DPA0014'], year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iii) Third party water
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0022', 'DPA0023'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0022', 'DPA0023'], year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iv) Seawater / desalinated water
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0016', 'DPA0017'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0016', 'DPA0017'], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (v) Others
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0026', 'DPA0027'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0026', 'DPA0027'], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total volume of water withdrawal (in kilolitres) (i + ii + iii + iv + v)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0023', 'DPA0016', 'DPA0017', 'DPA0026', 'DPA0027'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0023', 'DPA0016', 'DPA0017', 'DPA0026', 'DPA0027'], year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total volume of water consumption (in kilolitres)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0023', 'DPA0016', 'DPA0017', 'DPA0026', 'DPA0028'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0023', 'DPA0016', 'DPA0017', 'DPA0026', 'DPA0028'], year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Water intensity per rupee of turnover (Water consumed / turnover)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0023', 'DPA0016', 'DPA0017', 'DPA0026', 'DPA0027'], year) / getDataByDP('DPK0039', year))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getDataByDPArray(['DPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0023', 'DPA0016', 'DPA0017', 'DPA0026', 'DPA0027'], year - 1) / getDataByDP('DPK0039', year - 1))}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Water intensity (optional) – the relevant metric may be selected by the entity
                                            </td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`4) Has the entity implemented a mechanism for Zero Liquid Discharge? If yes, provide details 
of its coverage and implementation.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">{getLastResponseByRFID(146, 'radio-group-1695224816950-0')}{getLastResponseByRFID(146, 'textarea-1695224871100-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`5) Please provide details of air emissions (other than GHG emissions) by the entity, in the 
following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Nox
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0328', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0328', year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Sox
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0329', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0329', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Particulate matter (PM)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0330', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0330', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Persistent organic pollutants (POP)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0331', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0331', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Volatile organic compounds (VOC)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0332', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0332', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Hazardous air pollutants (HAP)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0333', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0333', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Others – specify
                                            </td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`6) Provide details of greenhouse gas emissions (Scope 1 and Scope 2 emissions) & its 
intensity, in the following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total Scope 1 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Metric tonnes of CO2 equivalent
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getScopeData(year, 1))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getScopeData(year - 1, 1))}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total Scope 2 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Metric tonnes of CO2 equivalent
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getScopeData(year, 2))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getScopeData(year - 1, 2))}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total Scope 1 and Scope 2 emissions per rupee of turnover
                                            </td>
                                            <td colspan="1" rowspan="1" class="">
                                                Metric tonnes of CO2 equivalent per rupee turnover
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getScopeData(year, 1) + getScopeData(year, 2)) / getDataByDP('DPK0039', year))}</td>

                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity((getScopeData(year - 1, 1) + getScopeData(year - 1, 2)) / getDataByDP('DPK0039', year))}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total Scope 1 and Scope 2 emission intensity (optional) – the relevant metric may be selected by the entity
                                            </td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`7) Does the entity have any project related to reducing Green House Gas emission? If Yes, 
then provide details. `}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`8) Provide details related to waste management by the entity, in the following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency.`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center">
                                                Total Waste generated (in metric tonnes)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Plastic waste (A)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0059', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0059', year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                E-waste (B)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0060', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0060', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Bio-medical waste (C)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0061', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0061', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Construction and demolition waste (D)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0062', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0062', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Battery waste (E)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0063', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0063', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Radioactive waste (F)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0064', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0064', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other Hazardous waste specify (G)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0065', year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDP('DPA0065', year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Other Non-hazardous waste generated (H). Please specify
                                            </td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total (A+B + C + D + E + F + G +H)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0059', 'DPA0060', 'DPA0061', 'DPA0062', 'DPA0063', 'DPA0064', 'DPA0065'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0059', 'DPA0060', 'DPA0061', 'DPA0062', 'DPA0063', 'DPA0064', 'DPA0065'], year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center fb5">
                                                Each category of waste generated, total waste recovered through recycling, re-using or other recovery operations (in metric tonnes)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center ">
                                                Category of waste
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (i) Recycled
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0069', 'DPA0072', 'DPA0075', 'DPA0078'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0069', 'DPA0072', 'DPA0075', 'DPA0078'], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (ii) Re-used
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0068', 'DPA0071', 'DPA0074', 'DPA0077'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0068', 'DPA0071', 'DPA0074', 'DPA0077'], year - 1)}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iii) Other recovery operations
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0070', 'DPA0073', 'DPA0076', 'DPA0079'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0070', 'DPA0073', 'DPA0076', 'DPA0079'], year - 1)}</td>



                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray([
                                                'DPA0069', 'DPA0072',
                                                'DPA0075', 'DPA0078',
                                                'DPA0068', 'DPA0071',
                                                'DPA0074', 'DPA0077',
                                                'DPA0070', 'DPA0073',
                                                'DPA0076', 'DPA0079'
                                            ], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray([
                                                'DPA0069', 'DPA0072',
                                                'DPA0075', 'DPA0078',
                                                'DPA0068', 'DPA0071',
                                                'DPA0074', 'DPA0077',
                                                'DPA0070', 'DPA0073',
                                                'DPA0076', 'DPA0079'
                                            ], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center fb5">
                                                For each category of waste generated, total waste disposed by nature of disposal method (in metric tonnes)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center ">
                                                Category of waste
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (i) Incineration
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray([
                                                'DPA0087', 'DPA0088',
                                                'DPA0091', 'DPA0092',
                                                'DPA0095', 'DPA0096',
                                                'DPA0099', 'DPA0100',
                                                'DPA0107', 'DPA0108'
                                            ], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray([
                                                'DPA0087', 'DPA0088',
                                                'DPA0091', 'DPA0092',
                                                'DPA0095', 'DPA0096',
                                                'DPA0099', 'DPA0100',
                                                'DPA0107', 'DPA0108'
                                            ], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (ii) Landfilling
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0089', 'DPA0093', 'DPA0097', 'DPA0101', 'DPA0109'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0089', 'DPA0093', 'DPA0097', 'DPA0101', 'DPA0109'], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iii) Other disposal operations
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0090', 'DPA0094', 'DPA0098', 'DPA0102', 'DPA0110'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0090', 'DPA0094', 'DPA0098', 'DPA0102', 'DPA0110'], year - 1)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0087', 'DPA0088',
                                                'DPA0091', 'DPA0092',
                                                'DPA0095', 'DPA0096',
                                                'DPA0099', 'DPA0100',
                                                'DPA0107', 'DPA0108', 'DPA0089', 'DPA0093', 'DPA0097', 'DPA0101', 'DPA0109', 'DPA0090', 'DPA0094', 'DPA0098', 'DPA0102', 'DPA0110'], year)}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{getDataByDPArray(['DPA0087', 'DPA0088',
                                                'DPA0091', 'DPA0092',
                                                'DPA0095', 'DPA0096',
                                                'DPA0099', 'DPA0100',
                                                'DPA0107', 'DPA0108', 'DPA0089', 'DPA0093', 'DPA0097', 'DPA0101', 'DPA0109', 'DPA0090', 'DPA0094', 'DPA0098', 'DPA0102', 'DPA0110'], year - 1)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`9) Briefly describe the waste management practices adopted in your establishments. Describe the strategy adopted by your company to reduce usage of hazardous and toxic chemicals in your products and processes and the practices adopted to manage such wastes`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">{getLastResponseByRFID(143, 'textarea-1695207409483-0')}{getLastResponseByRFID(143, 'textarea-1695207452999-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`10)  If the entity has operations/offices in/around ecologically sensitive areas (such as national 
parks, wildlife sanctuaries, biosphere reserves, wetlands, biodiversity hotspots, forests, 
coastal regulation zones etc.) where environmental approvals / clearances are required, 
please specify details in the following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                S. No.
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Location of operations/ offices
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Type of operations
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Whether the conditions of environmental approval / clearance are complied (Y/N) (If no, the reasons thereof and corrective action taken)
                                            </td>
                                        </tr>
                                        {getLastResponseRF148().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{j + 1}</td>

                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ10D1}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ10D2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ10D3A}</td>


                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                {`11) Details of environmental impact assessments of projects undertaken by the entity based on applicable laws, in the current financial year:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Name & brief details of project
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                EIA Notification No.
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Date
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Whether conducted by independent external agency (Yes / No)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Results communicated in public domain (Yes / No)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Relevant Web link
                                            </td>
                                        </tr>
                                        {getLastResponseRF149().map((item) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ11D1}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ11D2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ11D3}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ11D4}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ11D5}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ11D6}</td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`12) Is the entity compliant with the applicable environmental law/ regulations/ guidelines in India; such as the Water (Prevention and Control of Pollution) Act, Air (Prevention and Control of Pollution) Act, Environment protection act and rules thereunder (Y/N). If not, provide details of all such non-compliances, in the following format:`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                S. No.
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Specify the law / regulation / guidelines was not complied with
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Provide details of the non-compliance
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Any fines / penalties / action taken by regulatory agencies such as pollution control boards / by courts
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Corrective action taken
                                            </td>
                                        </tr>
                                        {getLastResponseRF150().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{j + 1}</td>

                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ12D1}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ12D2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ12D3}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ12D4}</td>


                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secprin6ii"}>
                            Leadership Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="tdHead">
                                                {`Leadership Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`1) Provide break-up of the total energy consumed (in Joules or multiples) from renewable 
and non-renewable sources, in the following format:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center fb5">
                                                From Renewable Resources
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total electricity consumption (A)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0156', year) * 0.0000036)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0156', year - 1) * 0.0000036)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total fuel consumption (B)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPA0336', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPA0336', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Energy consumption through other sources (C)
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total energy consumed from renewable sources (A+B+C)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0156', year) * 0.0000036) + getDataByDP('DPA0336', year)}

                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0156', year - 1) * 0.0000036) + getDataByDP('DPA0336', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center fb5">
                                                From non-renewable sources
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total electricity consumption (D)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0157', year) * 0.0000036)}

                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0157', year - 1) * 0.0000036)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total fuel consumption (E)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPA0336', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPA0336', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Energy consumption through other sources (F)
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total energy consumed from non-renewable sources (D+E+F)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0157', year) * 0.0000036) + getDataByDP('DPA0336', year)}

                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0157', year - 1) * 0.0000036) + getDataByDP('DPA0336', year - 1)}

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`2) Provide the following details related to water discharged:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center fb5">
                                                Water discharge by destination and level of treatment (in kilolitres)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (i) To Surface water
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN007', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN007', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN004', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN004', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (ii) To Groundwater
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN008', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN008', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN005', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN005', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iii) To Seawater
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN009', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN009', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN004', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN004', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iv) Sent to third-parties
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN010', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN010', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN006', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN006', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (v) Others
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                Total water discharged (in kilolitres)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDPArray([
                                                    'DPAN007', 'DPAN004',
                                                    'DPAN008', 'DPAN005',
                                                    'DPAN009', 'DPAN004',
                                                    'DPAN010', 'DPAN006'
                                                ], year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDPArray([
                                                    'DPAN007', 'DPAN004',
                                                    'DPAN008', 'DPAN005',
                                                    'DPAN009', 'DPAN004',
                                                    'DPAN010', 'DPAN006'
                                                ], year - 1)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`3) Water withdrawal, consumption and discharge in areas of water stress (in kilolitres):
For each facility / plant located in areas of water stress, provide the following information: 
(i) Name of the area
(ii) Nature of operations
(iii) Water withdrawal, consumption and discharge in the following format:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center fb5">
                                                Water withdrawal by source (in kilolitres)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                (i) Surface water
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0010', 'DPA0011'], year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0010', 'DPA0011'], year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                (ii) Groundwater
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0013', 'DPA0014'], year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0013', 'DPA0014'], year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iii) Third party water
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0022', 'DPA0023'], year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0022', 'DPA0023'], year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                (iv) Seawater / desalinated water
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0016', 'DPA0017'], year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0016', 'DPA0017'], year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                (v) Others
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0026', 'DPA0027'], year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP(['DPA0026', 'DPA0027'], year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                Total volume of water withdrawal (in kilolitres)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDPArray([
                                                    'DPA0010 ', 'DPA0011',
                                                    'DPA0013', 'DPA0014',
                                                    'DPA0022', 'DPA0023',
                                                    'DPA0016', 'DPA0017',
                                                    'DPA0026', 'DPA0027'
                                                ], year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDPArray([
                                                    'DPA0010 ', 'DPA0011',
                                                    'DPA0013', 'DPA0014',
                                                    'DPA0022', 'DPA0023',
                                                    'DPA0016', 'DPA0017',
                                                    'DPA0026', 'DPA0027'
                                                ], year - 1)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total volume of water consumption (in kilolitres)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDPArray([
                                                    'DPA0010 ', 'DPA0011',
                                                    'DPA0013', 'DPA0014',
                                                    'DPA0022', 'DPA0023',
                                                    'DPA0016', 'DPA0017',
                                                    'DPA0026', 'DPA0028'
                                                ], year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDPArray([
                                                    'DPA0010 ', 'DPA0011',
                                                    'DPA0013', 'DPA0014',
                                                    'DPA0022', 'DPA0023',
                                                    'DPA0016', 'DPA0017',
                                                    'DPA0026', 'DPA0028'
                                                ], year - 1)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                Water intensity per rupee of turnover (Water consumed / turnover)
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                Water intensity – the relevant metric may be selected by the entity
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" rowspan="1" className="text-center fb5">
                                                Water discharge by destination and level of treatment (in kilolitres)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (i) To Surface water
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN007', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN007', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN004', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN004', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (ii) To Groundwater
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN008', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN008', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN005', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN005', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iii) To Seawater
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN009', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN009', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN004', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN004', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (iv) Sent to third-parties
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN010', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN010', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN006', year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPAN006', year - 1)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                (v) Others
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - No treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                - With treatment specify level of treatment
                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                            <td colspan="1" rowspan="1" class="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">

                                                Total water discharged (in kilolitres)
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDPArray([
                                                    'DPAN007', 'DPAN004',
                                                    'DPAN008', 'DPAN005',
                                                    'DPAN009', 'DPAN004',
                                                    'DPAN010', 'DPAN006'
                                                ], year)}
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">
                                                {getDataByDPArray([
                                                    'DPAN007', 'DPAN004',
                                                    'DPAN008', 'DPAN005',
                                                    'DPAN009', 'DPAN004',
                                                    'DPAN010', 'DPAN006'
                                                ], year - 1)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`4) Please provide details of total Scope 3 emissions & its intensity, in the following format:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="2" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">{`Total Scope 3 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)	
`}</td>
                                            <td colspan="1" rowspan="1" class="">

                                                Metric tonnes of CO2 equivalent
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getScopeData(year, 3))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getScopeData(year - 1, 3))}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total Scope 3 emissions per rupee of turnover
                                            </td>
                                            <td colspan="1" rowspan="1" class="text-center"></td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getScopeData(year, 3) / getDataByDP('DPK0039', year))}</td>
                                            <td colspan="1" rowspan="1" class="text-center">{handleNaNAndInfinity(getScopeData(year - 1, 3) / getDataByDP('DPK0039', year - 1))}</td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                Total Scope 3 emission intensity (optional) – the relevant metric may be selected by the entity
                                            </td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                            <td colspan="1" rowspan="1" class=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`5) With respect to the ecologically sensitive areas reported at Question 10 of Essential 
Indicators above, provide details of significant direct & indirect impact of the entity on 
biodiversity in such areas along-with prevention and remediation activities.`}
                                            </td>
                                        </tr>
                                        {getLastResponseRF148B().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{j + 1}</td>

                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6EQ10D4}</td>



                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`6)  If the entity has undertaken any specific initiatives or used innovative technology or 
solutions to improve resource efficiency, or reduce impact due to emissions / effluent 
discharge / waste generated, please provide details of the same as well as outcome of such 
initiatives, as per the following format:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Sr. No
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Initiative undertaken
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Details of the initiative (Web-link, may be provided along-with summary)
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Outcome of the initiative
                                            </td>
                                        </tr>
                                        {getLastResponseRF151().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colspan="1" rowspan="1" className="text-center">{j + 1}</td>

                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6LQ6D1}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6LQ6D2}</td>
                                                    <td colspan="1" rowspan="1" className="text-center">{item.QLBP6LQ6D3}</td>



                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`7)Does the entity have a business continuity and disaster management plan? Give details in 
100 words/ web link.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(138, 'textarea-1695180609290-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`8) Disclose any significant adverse impact to the environment, arising from the value chain of 
the entity. What mitigation or adaptation measures have been taken by the entity in this 
regard`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class="text-center">{getLastResponseByRFID(137, 'textarea-1695180205624-0')}{getLastResponseByRFID(137, 'textarea-1695180247390-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`9) Percentage of value chain partners (by value of business done with such partners) that 
were assessed for environmental impacts.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" class=""> {handleNaNAndInfinity(((getDataByDP('DPAN049', year) - (getDataByDP('DPAN049', year) - getDataByDP('DPAN050', year))) / getDataByDP('DPAN049', year)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader id={"secprin7"} className="secHead">
                            {"PRINCIPLE 7 Businesses, when engaging in influencing public and regulatory policy, should do so in a manner that is responsible and transparent"}
                        </sectionheader>
                        <sectionheader1 id={"secprin7i"}>
                            Essential Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="secHead">
                                                {`Essential Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`1. a. Number of affiliations with trade and industry chambers/ associations.`}
                                            </td>
                                            <td colspan="2" rowspan="1" className="text-center">{getDataByDP('DPK0150', year)} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`b. List the top 10 trade and industry chambers/ associations (determined based on the 
total members of such body) the entity is a member of/ affiliated to.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                S. No.
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Type of the trade and industry chambers/ associations
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Name of the trade and industry chambers/ associations
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Reach of trade & industry chambers/ associations (State/National)
                                            </td>
                                        </tr>
                                        {getLastResponseRF121().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colSpan="1" rowspan="1" className="text-center">{j + 1}</td>

                                                    <td colSpan="1" rowspan="1" className="text-center">{item.QLBP7E1D1A}</td>
                                                    <td colSpan="1" rowspan="1" className="text-center">{item.QLBP7E1D1B}</td>

                                                    <td colSpan="1" rowspan="1" className="text-center">{item.QLBP7E1D2}</td>

                                                </tr>
                                            )
                                        })}



                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" rowspan="1" class="">
                                                2) Provide details of corrective action taken or underway on any issues related to anti competitive conduct by the entity, based on adverse orders from regulatory authorities
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="2" rowspan="1" className="tdHead">
                                                Name of authority
                                            </td>
                                            <td colSpan="2" rowspan="1" className="tdHead">
                                                Brief of the case
                                            </td>
                                            <td colSpan="2" rowspan="1" className="tdHead">
                                                Corrective action taken
                                            </td>
                                        </tr>
                                        {getLastResponseRF112().map((item) => {
                                            return (
                                                <tr>
                                                    <td colSpan="2" rowspan="1" className="text-center">{item.QLBP7E2D1}</td>
                                                    <td colSpan="2" rowspan="1" className="text-center">{item.QLBP7E2D2}</td>
                                                    <td colSpan="2" rowspan="1" className="text-center">{item.QLBP7E2D3}</td>

                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secprin7ii"}>
                            Leadership Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="secHead">
                                                {`Leadership Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" rowspan="1">
                                                1) Details of public policy positions advocated by the entity:
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                S. No.
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                Public policy advocated
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                Method resorted for such advocacy
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                Whether information available in public domain (Yes/No)
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                Frequency of Review by Board (Annually/ Half yearly/ Quarterly / Others specify)
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                Web Link, if available
                                            </td>
                                        </tr>

                                        {getLastResponseRF113().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colSpan="1" rowspan="1" className="text-center">{j + 1}</td>
                                                    <td colSpan="1" rowspan="1" className="text-center">{item.QLBP7L1D1}</td>
                                                    <td colSpan="1" rowspan="1" className="text-center">{item.QLBP7L1D2}</td>
                                                    <td colSpan="1" rowspan="1" className="text-center">{item.QLBP7L1D3}</td>
                                                    <td colSpan="1" rowspan="1" className="text-center">{item.QLBP7L1D4}</td>
                                                    <td colSpan="1" rowspan="1" className="text-center">{item.QLBP7L1D5}</td>



                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader id={"secprin8"} className="secHead">
                            PRINCIPLE 8 Businesses should promote inclusive growth and equitable development
                        </sectionheader>
                        <sectionheader1 id={"secprin8i"}>
                            Essential Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="secHead">
                                                {`Essential Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                {`1) Details of Social Impact Assessments (SIA) of projects undertaken by the entity based 
on applicable laws, in the current financial year.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Name & brief details of project
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                SIA Notification No.
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Date of notification
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Whether conducted by independent external agency (Yes / No)
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Results communicated in public domain (Yes / No)
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Relevant Web link
                                            </td>
                                        </tr>

                                        {getLastResponseRF133().map((item) => {
                                            return (
                                                <tr>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8E1D1A}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8E1D1B}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8E1D1C}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8E1D1D}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8E1D1E}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8E1D1F}</td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="7" rowspan="1" class="">
                                                {`2) Provide information on project(s) for which ongoing Rehabilitation and Resettlement
(R&R) is being undertaken by your entity, in the following format:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                S. No.
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Name of Project for which R&R is ongoing
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                State
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                District
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                No. of Project Affected Families (PAFs)
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                % of PAFs covered by R&R
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Amounts paid to PAFs in the FY (In INR)
                                            </td>
                                        </tr>

                                        {getManualDCF188(188, year).map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colSpan="1" rowspan="1" class="text-center">{j + 1}</td>

                                                    <td colSpan="1" rowspan="1" class="text-center">{item.DPS0184}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.DPS0185}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.DPS0186}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.DPS0187}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.DPK0183}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.DPS0190}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" class="">
                                                {`3) Describe the mechanisms to receive and redress grievances of the community. `}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(122, 'text-1695122257730-0')}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`4) Percentage of input material (inputs to total inputs by value) sourced from suppliers:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="2" class=""></td>
                                            <td colSpan="1" rowspan="1" className="tdHead">

                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colSpan="1" rowspan="1" className="tdHead">

                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" className="text-center">

                                                Current Financial Year
                                            </td>
                                            <td colSpan="1" rowspan="1" className="text-center">

                                                Previous Financial Year
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="1" rowspan="1" class="">

                                                Directly sourced from MSMEs/ small producers
                                            </td>
                                            <td colSpan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPK0097', year)}
                                            </td>
                                            <td colSpan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPK0097', year - 1)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="1" rowspan="1" class="">

                                                Sourced directly from within the district and neighbouring districts
                                            </td>
                                            <td colSpan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPK0098', year)}

                                            </td>
                                            <td colSpan="1" rowspan="1" class="text-center">
                                                {getDataByDP('DPK0098', year)}

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secprin8ii"}>
                            Leadership Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="secHead">
                                                {`Leadership Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`1) . Provide details of actions taken to mitigate any negative social impacts identified in the 
Social Impact Assessments (Reference: Question 1 of Essential Indicators above):`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Details of negative social mpact identified
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Corrective action taken
                                            </td>
                                        </tr>
                                        {getLastResponseRF114().map((item) => {
                                            return (
                                                <tr>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L1D1}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L1D2}</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`2) Provide the following information on CSR projects undertaken by your entity in 
designated aspirational districts as identified by government bodies:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                S. No.
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                State
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Aspirational District
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Amount spent (In INR)
                                            </td>
                                        </tr>

                                        {getLastResponseRF115().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colSpan="1" rowspan="1" class="text-center">{j + 1}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L2D1}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L2D2}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L2D3}</td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1" class="">
                                                {`3) (a) Do you have a preferential procurement policy where you give preference to 
purchase from suppliers comprising marginalized /vulnerable groups? (Yes/No) 
(b) From which marginalized /vulnerable groups do you procure? 
(c) What percentage of total procurement (by value) does it constitute? `}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" className="text-center">

                                                a)
                                            </td>

                                            <td colSpan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(123, 'radio-group-1695124257811-0')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="1" rowspan="1" className="text-center">

                                                b)
                                            </td>

                                            <td colSpan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(123, 'text-1695124310373-0')}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="1" rowspan="1" className="text-center">

                                                c)
                                            </td>

                                            <td colSpan="1" rowspan="1" className="text-center">
                                                {getLastResponseByRFID(123, 'text-1695124341068')}

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="5" rowspan="1" class="">
                                                {`4) Details of the benefits derived and shared from the intellectual properties owned or 
acquired by your entity (in the current financial year), based on traditional knowledge:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                S. No.
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Intellectual Property based on traditional knowledge
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Owned/ Acquired (Yes/No)
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Benefit shared (Yes / No)
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Basis of calculating benefit share
                                            </td>
                                        </tr>

                                        {getLastResponseRF116().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colSpan="1" rowspan="1" class="text-center">{j + 1}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L4D1}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L4D2}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L4D3}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L4D4}</td>


                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" rowspan="1" class="">
                                                {`5) Details of corrective actions taken or underway, based on any adverse order in 
intellectual property related disputes wherein usage of traditional knowledge is involved.`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Name of authority
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Brief of the Case
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                Corrective action taken
                                            </td>
                                        </tr>


                                        {getLastResponseRF117().map((item, j) => {
                                            return (
                                                <tr>

                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L5D1}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L5D2}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L5D3}</td>



                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" rowspan="1" class="">
                                                {`6)  Details of beneficiaries of CSR Projects:`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                S. No.
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                CSR Project
                                            </td>

                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                No. of persons benefitted from CSR Projects
                                            </td>
                                            <td colSpan="1" rowspan="1" class="tdHead">
                                                % of beneficiaries from vulnerable and marginalized groups
                                            </td>
                                        </tr>

                                        {getLastResponseRF118().map((item, j) => {
                                            return (
                                                <tr>
                                                    <td colSpan="1" rowspan="1" class="text-center">{j + 1}</td>

                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L6D1}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L6D2}</td>
                                                    <td colSpan="1" rowspan="1" class="text-center">{item.QLBP8L6D3}</td>



                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader id={"secprin9"} className="secHead">
                            PRINCIPLE 9 Businesses should engage with and provide value to their consumers in responsible manner
                        </sectionheader>
                        <sectionheader1 id={"secprin9i"}>
                            Essential Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="secHead">
                                                {`Essential Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                1) Describe the mechanisms in place to receive and respond to consumer complaints and feedback.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="6" rowSpan="1">{getLastResponseByRFID(87, 'ext-1693293264464-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="">
                                                2) Turnover of products and/ services as a percentage of turnover from all products/service that carry information about
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="3" rowSpan="1"></td>
                                            <td colSpan="3" rowSpan="1" className="tdHead">
                                                As a percentage to total turnover
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="3" rowSpan="1">
                                                Environmental and social parameters relevant to the product
                                            </td>
                                            <td colSpan="3" rowSpan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPK0104', year) / getDataByDP('DPK0203', year)) * 100)}</td>
                                        </tr>

                                        <tr>
                                            <td colSpan="3" rowSpan="1">
                                                Safe and responsible usage
                                            </td>
                                            <td colSpan="3" rowSpan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPK0201', year) / getDataByDP('DPK0203', year)) * 100)}</td>


                                        </tr>

                                        <tr>
                                            <td colSpan="3" rowSpan="1">
                                                Recycling and/or safe disposal
                                            </td>
                                            <td colSpan="3" rowSpan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPK0202', year) / getDataByDP('DPK0203', year)) * 100)}</td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="7" rowSpan="1">
                                                3) Number of consumer complaints in respect of the following:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="1" rowSpan="3"></td>
                                            <td colSpan="2" rowSpan="1" className="tdHead">

                                                FY{year.toString().substr(2, 3)}
                                            </td>
                                            <td colSpan="1" rowSpan="3" className="tdHead">
                                                Remarks
                                            </td>
                                            <td colSpan="2" rowSpan="1" className="tdHead">
                                                FY{(year - 1).toString().substr(2, 3)}
                                            </td>
                                            <td colSpan="1" rowSpan="3" className="tdHead">
                                                Remarks
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="2" rowSpan="1" className="text-center">
                                                (Current Financial Year)
                                            </td>

                                            <td colSpan="2" rowSpan="1" className="text-center">
                                                (Previous Financial Year)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                Received during the year
                                            </td>

                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                Pending resolution at end of year
                                            </td>

                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                Received during the year
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="tdHead">
                                                Pending resolution at end of year
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowSpan="1">

                                                Data privacy
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0065', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0151', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0158', year)}

                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center" >
                                                {getDataByDP('DPK0065', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0151', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0158', year - 1)}

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowSpan="1">

                                                Advertising
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0066', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0152', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0159', year)}

                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0066', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0152', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0159', year - 1)}

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowSpan="1">

                                                Cyber-security
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0067', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0153', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0160', year)}

                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0067', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0153', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0160', year - 1)}

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowSpan="1">

                                                Delivery of essential services
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center" >
                                                {getDataByDP('DPK0068', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0154', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0161', year)}

                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0068', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0154', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0161', year - 1)}

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowSpan="1">

                                                Restrictive Trade Practices
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0069', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0155', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0162', year)}

                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0069', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0155', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0162', year - 1)}

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowSpan="1">

                                                Unfair Trade Practices
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0070', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0156', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0163', year)}

                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0070', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0156', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0163', year - 1)}

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" rowSpan="1">

                                                Other
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0071', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0157', year)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0164', year)}

                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0071', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDP('DPK0157', year - 1)}
                                            </td>
                                            <td colSpan="1" rowSpan="1" className="text-center">
                                                {getDataByDPStr('DPK0164', year - 1)}

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" rowSpan="1">

                                                4) Details of instances of product recalls on account of safety issues:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" rowSpan="1"></td>
                                            <td colSpan="2" rowSpan="1" className="tdHead">
                                                Number
                                            </td>
                                            <td colSpan="2" rowSpan="1" className="tdHead">
                                                Reasons for recall
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="2" rowSpan="1">
                                                Voluntary recalls
                                            </td>
                                            <td colSpan="2" rowSpan="1" className="text-center">{getLastResponseByRFID(16, 'DPS0280')} </td>
                                            <td colSpan="2" rowSpan="1" className="text-center">{getLastResponseByRFID(16, 'DPS0281')} </td>



                                        </tr>

                                        <tr>
                                            <td colSpan="2" rowSpan="1">
                                                Forced recalls
                                            </td>
                                            <td colSpan="2" rowSpan="1" className="text-center">{getLastResponseByRFID(16, 'DPS0282')} </td>
                                            <td colSpan="2" rowSpan="1" className="text-center">{getLastResponseByRFID(16, 'DPS0283')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" rowSpan="1">

                                                5) Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If available, provide a web-link of the policy.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="3" rowSpan="1" className="text-center">Does the entity have a framework/ policy on cyber security and risks related to data privacy? </td>

                                            <td colSpan="3" rowSpan="1" className="text-center">{getLastResponseByRFID(124, 'radio-group-1695130434045-0')} </td>


                                        </tr>
                                        <tr>
                                            <td colSpan="3" rowSpan="1" className="text-center">Details </td>

                                            <td colSpan="3" rowSpan="1" className="text-center">{getLastResponseByRFID(124, 'text-1695134687478-0')} </td>


                                        </tr>
                                        <tr>
                                            <td colSpan="3" rowSpan="1" className="text-center">Weblink </td>

                                            <td colSpan="3" rowSpan="1" className="text-center">{getLastResponseByRFID(124, 'text-1695134695944')} </td>


                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" rowSpan="1">

                                                6) Provide details of any corrective actions taken or underway on issues relating to advertising, and delivery of essential services; cyber security and data privacy of customers; re-occurrence of instances of product recalls; penalty / action taken by
                                                regulatory authorities on safety of products / services
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="6" rowSpan="1" >{getLastResponseByRFID(30, 'text-1690979889969-0')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                        <sectionheader1 id={"secprin9ii"}>
                            Leadership Indicators
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1" class="secHead">
                                                {`Leadership Indicators`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" rowspan="1">
                                                1) Channels / platforms where information on products and services of the entity can be accessed (provide web link, if available).
                                            </td>
                                        </tr>
                                        {getLastResponseRF134().map((item) => {
                                            return (
                                                <tr>
                                                    <td colspan="6" rowspan="1" className="text-center">{item.QLBP9L1D1} - {item.QLBP9L1D2} </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1">
                                                2) Steps taken to inform and educate consumers about safe and responsible usage of products and/or services.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" rowspan="1">{getLastResponseByRFID(58, 'text-1691439498197-0')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1">
                                                3) Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential services.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" rowspan="1">{getLastResponseByRFID(88, 'text-1693295024633-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="6" rowspan="1">
                                                4) Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable) If yes, provide details in brief. Did your entity carry out any survey with regard to consumer satisfaction
                                                relating to the major products / services of the entity, significant locations of operation of the entity or the entity as a whole? (Yes/No)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" rowspan="1">{getLastResponseByRFID(125, 'radio-group-1695131998186-0')},{getLastResponseByRFID(125, 'text-1695132046683-0')}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" rowspan="1">
                                                5) Provide the following information relating to data breaches: a. Number of instances of data breaches along-with impact b. Percentage of data breaches involving personally identifiable information of customers
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1">
                                                a.
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center"> {getDataByDP('DPK0116', year)} , Impact - {getDataByDP('DPK0199', year)} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1">
                                                b.
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">{handleNaNAndInfinity((getDataByDP('DPK0198', year) / getDataByDP('DPK0076', year)) * 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader1>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BRSR_23