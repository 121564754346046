import { Tag } from "primereact/tag";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../components/constants/api_url";
import APIServices from "../service/APIService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AdminHomeScreen = () => {
    const selector = useSelector(state => state.user.userdetail)
    const userList = useSelector(state => state.userlist.userList)
    const supplierList = useSelector(state => state.userlist.supplierList)
    const navigate = useHistory()

    const [data, setData] = useState({ goal: 0, submitted: 0, topic: 0, user: 0, report: 0, supplier: 0 })

    useEffect(() => {
        let gtaString = {
            "include": ["newTargetsTwos", "newIndicatorTwos", "newInitiatives"]


        }

        let category_string = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


        }

        const promise1 = APIServices.get(API.DCF_Submit_UP(selector.id))


        const promise2 = APIServices.get(API.Goal_UP(selector.id))
        const promise3 = APIServices.get(API.AssignDCFClient_UP(selector.id))


        Promise.all([promise1, promise2, promise3]).then(function (values) {
            let data_ = JSON.parse(JSON.stringify(data))
            data_.user = userList.length
            data_.topic = values[2].data[0].metric_ids.length
            data_.submitted = values[0].data.filter(i => (i.type === 1 || i.type === 3)).length
            data_.goal = values[1].data.length
            data_.supplier = supplierList.length
            data_.report = selector.information.report === undefined ? [] : selector.information.report.length
            setData(data_)
        })
    }, [])
    return (
        <div className="grid font-lato bg-smoke">
            <div className="col-12">
            
                <div className="col-12 flex align-items-center" style={{padding: '0px 20px'}}>
                    <span className="text-big-one"> Welcome {selector.information.contactperson} !  </span>   <Tag className="ml-3 p-tag-blue" > Super Admin</Tag>
                </div>

                <div className="col-12" style={{padding: '0px 20px'}} >
                    <label className="text-big-one text-navy flex fs-16"> One place to access all features.
                    </label>
                    <label className="text-micro text-navy flex">Get started, access powerful features to manage your product and drive positive environmental, social, and governance impact with ease.</label>

                </div>
                <div className="col-12 flex justify-content-evenly">
                    <div className="col-12 grid">
                        <div className="col-3 flex justify-content-center" >
                            <div className="card flex justify-content-between admin-card" onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = 'card flex justify-content-between admin-card'; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = 'card flex justify-content-between admin-card box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/client_performance' }) }} >
                                <div className="justify-content-between grid">
                                    <div className="big-number-navy fs-36">{data.goal}</div>
                                    <div className="icon-layout" style={{ background: 'rgba(128, 234, 213, 0.20)' }}>
                                    <img src={require('../assets/images/newui/admin/barchart.png').default } width={24} height={23}  />

                                    </div>

                                </div>
                                <div style={{ marginLeft: -5 }}>
                                    <div className="text-bold clr-navy fs-16">Performance Dashboard</div>
                                    <div className="clr-navy fs-12">Detailed progress of monthly KPIs</div>
                                </div>
                                <div className="justify-content-between grid">
                                    <div className="clr-navy fs-12 text-bold">PERFORMANCE DASHBOARD</div>
                                    <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 flex justify-content-center" >
                            <div className="card flex justify-content-between admin-card" onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = 'card flex justify-content-between admin-card'; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = 'card flex justify-content-between admin-card box-shadow-one' }} >
                                <div className="justify-content-between grid">
                                    <div className="big-number-navy fs-36">0</div>
                                    <div className="icon-layout" style={{ background: 'rgba(238, 87, 36, 0.20)' }}>
                                    <img src={require('../assets/images/newui/admin/presentation.png').default } width={24} height={23}  />


                                    </div>

                                </div>
                                <div style={{ marginLeft: -5 }}>
                                    <div className="text-bold clr-navy fs-16">Leaders Dashboard</div>
                                    <div className="clr-navy fs-12">Visual summary of key leadership metrics and insights</div>
                                </div>
                                <div className="justify-content-between grid">
                                    <div className="clr-navy fs-12 text-bold">LEADERS DASHBOARD</div>
                                    <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 flex justify-content-center" >
                            <div className="card flex justify-content-between admin-card" onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = 'card flex justify-content-between admin-card'; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = 'card flex justify-content-between admin-card box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/report_list' }) }} >
                                <div className="justify-content-between grid">
                                    <div className="big-number-navy fs-36">{data.report}</div>
                                    <div className="icon-layout" style={{ background: 'rgba(245, 195, 123, 0.20)' }}>
                                    <img src={require('../assets/images/newui/admin/file.png').default } width={24} height={23}  />


                                    </div>

                                </div>
                                <div style={{ marginLeft: -5 }}>
                                    <div className="text-bold clr-navy fs-16">Reports and Disclosure</div>
                                    <div className="clr-navy fs-12">Presentation of information and transparent communication practices</div>
                                </div>
                                <div className="justify-content-between grid">
                                    <div className="clr-navy fs-12 text-bold">REPORTS AND DISCLOSURE</div>
                                    <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 flex justify-content-center" >
                            <div className="card flex justify-content-between admin-card" onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = 'card flex justify-content-between admin-card'; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = 'card flex justify-content-between admin-card box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/client/add_supplier' }) }} >
                                <div className="justify-content-between grid">
                                    <div className="big-number-navy fs-36">{data.supplier}</div>
                                    <div className="icon-layout" style={{ background: 'rgba(39, 174, 96, 0.20)' }}>
                                    <img src={require('../assets/images/newui/admin/barline.png').default } width={24} height={23}  />




                                    </div>

                                </div>
                                <div style={{ marginLeft: -5 }}>
                                    <div className="text-bold clr-navy fs-16">Supply Chain</div>
                                    <div className="clr-navy fs-12">Track suppliers key ESG metrics</div>
                                </div>
                                <div className="justify-content-between grid">
                                    <div className="clr-navy fs-12 text-bold">SUPPLY CHAIN</div>
                                    <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 flex justify-content-evenly">
                    <div className="col-12 grid">
                        <div className="col-3 flex justify-content-center" >
                            <div className="card flex justify-content-between admin-card" onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = 'card flex justify-content-between admin-card'; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = 'card flex justify-content-between admin-card box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/client_indicators' }) }} >
                                <div className="justify-content-between grid">
                                    <div className="big-number-navy fs-36">{data.topic}</div>
                                    <div className="icon-layout" style={{ background: '#9B51E020' }}>
                                    <img src={require('../assets/images/newui/admin/overlap.png').default } width={24} height={23}  />

                                    </div>

                                </div>
                                <div style={{ marginLeft: -5 }}>
                                    <div className="text-bold clr-navy fs-16">Material Topics</div>
                                    <div className="clr-navy fs-12">Get all insights of materiality assessment</div>
                                </div>
                                <div className="justify-content-between grid">
                                    <div className="clr-navy fs-12 text-bold">MATERIAL TOPICS</div>
                                    <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 flex justify-content-center" >
                            <div className="card flex justify-content-between admin-card" onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = 'card flex justify-content-between admin-card'; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = 'card flex justify-content-between admin-card box-shadow-one' }} >
                                <div className="justify-content-between grid">
                                    <div className="big-number-navy fs-36">0</div>
                                    <div className="icon-layout" style={{ background: '#5B8FF720' }}>
                                    <img src={require('../assets/images/newui/admin/folder.png').default } width={24} height={23}  />



                                    </div>

                                </div>
                                <div style={{ marginLeft: -5 }}>
                                    <div className="text-bold clr-navy fs-16">Emission Library</div>
                                    <div className="clr-navy fs-12">Comprehensive repository of emission data complying with global database</div>
                                </div>
                                <div className="justify-content-between grid">
                                    <div className="clr-navy fs-12 text-bold">EMISSION LIBRARY</div>
                                    <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 flex justify-content-center" >
                            <div className="card flex justify-content-between admin-card" onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = 'card flex justify-content-between admin-card'; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = 'card flex justify-content-between admin-card box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/client/add_user' }) }} >
                                <div className="justify-content-between grid">
                                    <div className="big-number-navy fs-36">{data.user}</div>
                                    <div className="icon-layout" style={{ background: '#FFD22E20' }}>
                                    <img src={require('../assets/images/newui/admin/users.png').default } width={24} height={23}  />



                                    </div>

                                </div>
                                <div style={{ marginLeft: -5 }}>
                                    <div className="text-bold clr-navy fs-16">Reporters</div>
                                    <div className="clr-navy fs-12">Get all insights on Key Performance Indicators and Action Trackers</div>
                                </div>
                                <div className="justify-content-between grid">
                                    <div className="clr-navy fs-12 text-bold">REPORTERS</div>
                                    <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 flex justify-content-center" >
                            <div className="card flex justify-content-between  admin-card" onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = 'card flex justify-content-between admin-card'; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = 'card flex justify-content-between admin-card box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/approved_logs' }) }} >
                                <div className="justify-content-between grid">
                                    <div className="big-number-navy fs-36">{data.submitted}</div>
                                    <div className="icon-layout" style={{ background: 'rgba(49, 89, 117, 0.20)' }}>
                                    <img src={require('../assets/images/newui/admin/success.png').default } width={24} height={23}  />




                                    </div>

                                </div>
                                <div style={{ marginLeft: -5 }}>
                                    <div className="text-bold clr-navy fs-16">DCF Status</div>
                                    <div className="clr-navy fs-12">Status on data collection</div>
                                </div>
                                <div className="justify-content-between grid">
                                    <div className="clr-navy fs-12 text-bold">DCF STATUS</div>
                                    <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AdminHomeScreen, comparisonFn);
