import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { EditText } from "react-edit-text";
import EdiText from 'react-editext'
import { AutoComplete } from "primereact/autocomplete";
import Axios from "axios";
import { SplitButton } from "primereact/splitbutton";
import { MultiSelect } from 'primereact/multiselect';
import Swal from "sweetalert2";
import { Chips } from 'primereact/chips';
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { ContextMenu } from 'primereact/contextmenu';
import { Dropdown } from "primereact/dropdown";
import { API } from "../components/constants/api_url";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { Checkbox } from "primereact/checkbox";
import APIServices from "../service/APIService";
const { DateTime } = require('luxon')
const EFCategoryConfig = () => {
    const [module, setModule] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
        tier0: "",
        tier4: ""
    });
    const tier4ref = useRef([]);
    const tier3ref = useRef([]);
    const tier2ref = useRef([]);
    const tier1ref = useRef([]);
    const [efcat, setEFCat] = useState({ title: '' })
    const [efcatmodal, setEFCatModal] = useState(false)
    const [efstdmodal, setEFStdModal] = useState(false)

    const [efstd, setEFStd] = useState({ title: '', public: true, client_ids: [] })
    const [submitted, setSubmitted] = useState(false)
    const clientList = useSelector(state => state.userlist.clientList)


    const [selected, setSelected] = useState({
        tier: '',
        tier1: "",
        tier2: "",
        tier3: "",
        tier0: "",
        tier4: ""
    });


    const [draggedItem, setDraggedItem] = React.useState(null);
    const selector = useSelector((state) => state.user.userdetail);
    const [tier, setTier] = useState([]);
    const [tier0, setTier0] = useState([]);
    const [tier1, setTier1] = useState([]);
    const [tier2, setTier2] = useState([]);
    const [tier3, setTier3] = useState([]);
    const [tier4, setTier4] = useState([]);
    const [orderlist, setOrderList] = useState([])



    const forceUpdate = useForceUpdate();
    useEffect(() => {
        APIServices.get(API.EF_Category).then((res) => {
            setTier0(res.data)
        })
        APIServices.get(API.EF_Std).then((res) => {
            setTier(res.data)
        })

    }, []);
    const saveEFCategory = () => {
        let index = tier0.findIndex((l) => { return l.title.trim().toLowerCase() === efcat.title.trim().toLowerCase() })
        setSubmitted(true)
        if (efcat.title.trim().length !== 0 && index === -1) {
            let data_ = JSON.parse(JSON.stringify(tier0))
            if (efcat.id) {
                let loc = JSON.parse(JSON.stringify(efcat))
                delete loc.id
                delete loc.extra

                APIServices.patch(API.EF_Category_edit(efcat.id), loc).then((res) => {
                    let index = data_.findIndex((k) => { return k.id === efcat.id })
                    data_[index] = efcat
                    setSubmitted(false)
                })


            } else {
                let loc = JSON.parse(JSON.stringify(efcat))
                loc.created_on = DateTime.utc()
                APIServices.post(API.EF_Category_Std(selected.tier), loc).then((res) => {
                    delete res.data.extra
                    data_.push(res.data)
                    setSubmitted(false)
                })

            }

            setTier0(data_)
            setEFCatModal(false)
        } else if (index === -1) {
            if (efcat.title.trim().length !== 0) {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Category already existing",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }

        }
    }
    const saveEFStandard = () => {
        setSubmitted(true)
        console.log(efstd)
        if (efstd.title.trim().length !== 0 && (efstd.public === true || (efstd.public === false && efstd.client_ids.length !== 0))) {
            if (efstd.id !== undefined) {
                let obj = { ...efstd, created_on: DateTime.utc() }
                APIServices.patch(API.EF_Std_Edit(efstd.id), obj
                ).then((a) => {
                    let loc = JSON.parse(JSON.stringify(tier))
                    let index = loc.findIndex(x => x.id === efstd.id);
                    if (index !== -1) {
                        loc[index] = { ...loc[index], ...obj }
                    }
                    setTier(loc)
                    setEFStdModal(false)
                    setSubmitted(false)
                    console.log(a)
                })
            } else {
                let obj = { ...efstd, created_on: DateTime.utc() }
                APIServices.post(API.EF_Std, obj).then((a) => {
                    let loc = JSON.parse(JSON.stringify(tier))

                    loc.push(obj)
                    setTier(loc)
                    setEFStdModal(false)
                    setSubmitted(false)
                    console.log(a)
                })
            }

        }
    }
    const addFooter3 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setEFCatModal(false); setSubmitted(false); }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => { saveEFCategory() }} />
        </>
    );
    const addFooter7 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setEFStdModal(false); setSubmitted(false); }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => { saveEFStandard() }} />
        </>
    );
    const deleteTier4 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier4.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.EF_SC4_Edit(item.id),
                ).then((res) => {
                    let loc = tier4
                    loc.splice(index, 1);
                    setTier4(loc);
                    forceUpdate();

                });
            }
        })

    }
    const setEditModeTier4 = (cyindex) => {
        tier4.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })
        console.log(tier4)
        forceUpdate()
    }
    const setEditModeOffTier4 = (cyindex) => {
        tier4.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier3 = (cyindex) => {
        tier3.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const setEditModeOffTier3 = (cyindex) => {
        tier3.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier2 = (cyindex) => {
        tier2.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const setEditModeOffTier2 = (cyindex) => {
        tier2.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier1 = (cyindex) => {
        tier1.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })
        console.log(tier4)
        forceUpdate()
    }
    const setEditModeOffTier1 = (cyindex) => {
        tier1.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const deleteTier3 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier3.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.EF_SC3_Edit(item.id),
                ).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''
                    sel.tier4 = ''
                    setSelected(sel);
                    setTier4([])
                    let loc = tier3
                    loc.splice(index, 1);

                    setTier3(loc);
                    forceUpdate();

                });
            }
        })

    }
    const deleteTier2 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier2.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.EF_SC2_Edit(item.id)).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''
                    sel.tier2 = ''

                    sel.tier4 = ''
                    setSelected(sel);
                    setTier4([])
                    let loc = tier2
                    loc.splice(index, 1);

                    setTier2(loc);
                    forceUpdate();

                });
            }
        })

    }
    const deleteTier1 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier1.findIndex((i) => { return i.id === item.id })
                APIServices.delete( API.EF_SC1_Edit(item.id),
                ).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''
                    sel.tier1 = ''
                    sel.tier2 = ''
                    sel.tier4 = ''
                    setSelected(sel);
                    let loc = tier1
                    loc.splice(index, 1);

                    setTier1(loc);
                    forceUpdate();

                });
            }
        })

    }






    const addNewTier1 = () => {

        let index = tier1.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier1.trim().length !== 0) {
            APIServices.post(API.EF_SC1_Cat(selected.tier0),
                 {
                    title: module.tier1.trim()
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier1;
                        tier.push(res.data);
                        setTier1(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier0: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier2 = () => {

        let index = tier2.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier2.trim().length !== 0) {
            APIServices.post(API.EF_SC2_Sc1(selected.tier1),
                 {
                    title: module.tier2.trim()
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        let tier = tier2;
                        tier.push(res.data);
                        setTier2(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier3 = () => {

        let index = tier3.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier3.trim().length !== 0) {
            APIServices.post( API.EF_SC3_Sc2(selected.tier2),
                 {
                    title: module.tier3.trim()
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier3;

                        tier.push(res.data);

                        setTier3(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier4 = () => {

        let index = tier4.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier4.trim().length !== 0) {
            APIServices.post( API.EF_SC4_Sc3(selected.tier3),
                 {
                    title: module.tier4.trim()
                }
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {

                        let tier = tier4;
                        res.data.data1 = null
                        tier.push(res.data);
                        setTier4(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const updateTier1 = (e, id) => {
        console.log(e)
        let index = tier1.findIndex((i) => {
            return i.id === id;
        });
        if (index !== -1) {
            APIServices.patch(API.EF_SC1_Edit(tier1[index].id),
              {
                    title: e.trim(),
                },
            ).then((res) => {
                let loc = tier1
                loc[index].title = e.trim();
                setTier1(loc);
                forceUpdate()
            });
        }
    };

    const getTierData = (id) => {
        let sel = selected;
        sel.tier = id
        sel.tier0 = ''
        sel.tier1 = '';
        sel.tier2 = "";
        sel.tier3 = "";
        sel.tier4 = ''
        setSelected(sel);

        APIServices.get( API.EF_Category_Std(id),
        ).then((res) => {
            setTier0(res.data);
        });
    };
    const getTier1Data = (id) => {
        let sel = selected;
        sel.tier0 = id;
        sel.tier1 = '';
        sel.tier2 = "";
        sel.tier3 = "";
        sel.tier4 = ''
        setSelected(sel);

        APIServices.get( API.EF_SC1_Cat(id),
        ).then((res) => {
            setTier1(res.data);
        });
    };
    const getTier2Data = (id) => {
        let sel = selected;
        sel.tier1 = id;
        sel.tier2 = "";
        sel.tier3 = "";
        sel.tier4 = ''
        setSelected(sel);

        APIServices.get(API.EF_SC2_Sc1(id)
        ).then((res) => {
            setTier2(res.data);
        });
    };
    const getTier3Data = (id) => {
        let sel = selected;
        sel.tier2 = id;
        sel.tier3 = "";
        sel.tier4 = ''
        setSelected(sel);
        forceUpdate();
        APIServices.get( API.EF_SC3_Sc2(selected.tier2)
        ).then((res) => {
            setTier3(res.data);
        });
    };

    const getTier4Data = (id) => {
        let sel = selected;
        sel.tier3 = id;

        sel.tier4 = ''
        setSelected(sel);
        forceUpdate();
        APIServices.get(API.EF_SC4_Sc3(selected.tier3)
        ).then((res) => {
            setTier4(res.data);
        });
    };
    const getTier5Data = (id) => {
        let sel = selected;
        sel.tier4 = id;
        setSelected(sel);
        forceUpdate();
    };
    const updateTier2 = (e, id) => {
        let index = tier2.findIndex((i) => {
            return i.id === id;
        });

        APIServices.patch( API.EF_SC2_Edit(tier2[index].id),
             {
                title: e.trim(),
            }
        ).then((res) => {
            let loc = tier2
            loc[index].title = e.trim();
            setTier2(loc);
            forceUpdate()

        });
    };
    const updateTier3 = (e, id) => {
        let index = tier3.findIndex((i) => {
            return i.id === id;
        });

        APIServices.patch(API.EF_SC3_Edit(tier3[index].id),
             {
                title: e.trim(),
            },
        ).then((res) => {
            let loc = tier3

            loc[index].title = e.trim();
            setTier3(loc);
            forceUpdate()

        });
    };
    const showTier4CM = (e, ind) => {
        console.log(tier4ref.current)
        tier4ref.current.forEach((i, j) => {
            if (j === ind) {
                tier4ref.current[ind].show(e)
            } else {
                if (tier4ref.current[j] !== null) {
                    tier4ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)

            }
        })
    }
    const showTier3CM = (e, ind) => {

        tier3ref.current.forEach((i, j) => {
            if (j === ind) {
                tier3ref.current[ind].show(e)
            } else {
                if (tier3ref.current[j] !== null) {
                    tier3ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })
    }
    const showTier2CM = (e, ind) => {

        tier2ref.current.forEach((i, j) => {
            if (j === ind) {
                tier2ref.current[ind].show(e)
            } else {
                if (tier2ref.current[j] !== null) {
                    tier2ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)

            }
        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })
    }
    const showTier1CM = (e, ind) => {

        tier1ref.current.forEach((i, j) => {
            if (j === ind) {
                tier1ref.current[ind].show(e)
            } else {
                if (tier1ref.current[j] !== null) {
                    tier1ref.current[j].hide(e)
                }
            }
        })
        tier2ref.current.forEach((i, j) => {
            console.log(tier2ref.current[j])
            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)
            }

        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })

    }
    const updateTier4 = (e, id) => {
        let index = tier4.findIndex((i) => {
            return i.id === id;
        });

        APIServices.patch( API.EF_SC4_Edit(tier4[index].id),
             {
                title: e.trim(),
            },
        ).then((res) => {
            let loc = tier4
            loc[index].title = e.trim();
            setTier4(loc);
            forceUpdate()

        });
        console.log(tier4)
    };
    const updateTierValue = (obj, e) => {

        let mod = module;
        mod[obj] = e.target.value;
        setModule(mod);
        forceUpdate();
    };



    const deleteItem = async () => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to delete</div>`,
            input: 'password',

            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,

            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off',
                autocomplete: 'off',
            },
            didOpen: () => {



            }
        })
        return password === 'wastehalftime'
    }



    const getRFStatus = (item) => {
        if (Array.isArray(item.data1) && item.data1.length !== 0) {
            if (Object.keys(item.data1[0]).length > 2) {
                if (item.data1[0].type === 1) {
                    if (item.data1[0].rf === null) {
                        return false
                    } else {
                        return true
                    }
                }
            }
        }
        return true
    }



    const categoryOptionTemplate = (rowData) => {
        return (
            <div className="grid justify-content-between col-12" style={{ padding: 0 }} >
                <div className="col-10">
                    {rowData.title}
                </div>

                <div className="col-2" style={{ zIndex: 10 }}>
                    <i className="material-icons" style={{ color: 'green' }} onClick={(e) => {
                        e.stopPropagation();

                        setEFCat(JSON.parse(JSON.stringify(rowData))); setEFCatModal(true)
                    }}>edit</i>
                </div>
            </div>
        )
    }
    const standardOptionTemplate = (rowData) => {
        return (
            <div className="grid justify-content-between col-12" style={{ padding: 0 }} >
                <div className="col-10">
                    {rowData.title}
                </div>

                <div className="col-2" style={{ zIndex: 10 }}>
                    <i className="material-icons" style={{ color: 'green' }} onClick={(e) => {
                        e.stopPropagation();

                        setEFStd(JSON.parse(JSON.stringify(rowData))); setEFStdModal(true)
                    }}>edit</i>
                </div>
            </div>
        )
    }
    const export2Excel = (type, metrics, dps) => {
        let loc = [], loc2 = []
        let obj = {}
        let efcat = {

            "include": [{ "relation": "newEfCategories", "scope": { "include": [{ "relation": "newEfSubcategory1s", "scope": { "include": [{ "relation": "newEfSubcategory2s", "scope": { "include": [{ "relation": "newEfSubcategory3s", "scope": { "include": [{ "relation": "newEfSubcategory4s" }] } }] } }] } }] } }]

        }
        Swal.fire({
            title: 'Please Wait !',
            html: 'Exporting data ...',// add html attribute if you want or remove
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        APIServices.get(API.EF_Std_Edit(selected.tier) + `?filter=${encodeURIComponent(JSON.stringify(efcat))}`).then((res) => {
            let res_data =[]
            res_data.push(JSON.parse(JSON.stringify(res.data)))
            console.log([res_data])
            res_data.forEach((i) => {
                if (i.newEfCategories !== undefined && i.newEfCategories.length !== 0) {
                    i.newEfCategories.forEach((j) => {
                        if (j.newEfSubcategory1s !== undefined && j.newEfSubcategory1s.length !== 0) {
                            j.newEfSubcategory1s.forEach((k) => {
                                if (k.newEfSubcategory2s && k.newEfSubcategory2s.length !== 0) {
                                    k.newEfSubcategory2s.forEach((l) => {
                                        if (l.newEfSubcategory3s !== undefined && l.newEfSubcategory3s.length !== 0) {
                                            l.newEfSubcategory3s.forEach((m) => {
                                                if (m.newEfSubcategory4s !== undefined && m.newEfSubcategory4s.length !== 0) {
                                                    m.newEfSubcategory4s.forEach((n) => {
                                                        loc.push({ standard: i.title, category: j.title, subcategory1_id: k.id, subcategory1: k.title, subcategory2_id: l.id, subcategory2: l.title, subcategory3_id: m.id, subcategory3: m.title, subcategory4_id: n.id, subcategory4: n.title })

                                                    })
                                                } else {
                                                    loc.push({ standard: i.title, category: j.title, subcategory1_id: k.id, subcategory1: k.title, subcategory2_id: l.id, subcategory2: l.title, subcategory3_id: m.id, subcategory3: m.title })

                                                }
                                            })
                                        } else {
                                            loc.push({ standard: i.title, category: j.title, subcategory1_id: k.id, subcategory1: k.title, subcategory2_id: l.id, subcategory2: l.title })

                                        }
                                    })

                                } else {
                                    loc.push({ standard: i.title, category: j.title, subcategory1_id: k.id, subcategory1: k.title })
                                }
                            })
                        } else {
                            loc.push({ standard: i.title, category: j.title })
                        }
                    })
                } else {
                    loc.push({ standard: i.title })
                }
            })
            if (loc.length !== 0) {
                const ws = XLSX.utils.json_to_sheet(loc)
                let sheet_name = tier.find(i=> i.id === selected.tier).title
                const wb = {
                    Sheets: { [sheet_name]: ws },
                    SheetNames: [sheet_name],
                };

                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

                const data = new Blob([excelBuffer], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                });
                FileSaver.saveAs(data, sheet_name + ".xlsx")
                Swal.close()
                
            } else {
                Swal.close()
                alert('Nothing to Export')

            }
        })



    }
    return (
        <div className="grid">

            {selector.role === "eisqradmin" ? (
                <div className="col-12">
                    <div className="card">
                        <h3> EF Category Configuration </h3>
                        <div>
                            <div className="col-12 grid align-items-center justify-content-between">
                                <div className="col-10 grid align-items-center">
                                    <label className="col-2 ">
                                        Standard <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <div className="col-5">
                                        <Dropdown options={tier} style={{ width: '100%' }} itemTemplate={standardOptionTemplate} filter optionLabel="title" optionValue="id" value={selected.tier} onChange={(e) => { getTierData(e.value) }} />
                                        {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                                    </div>
                                    <div className="col-1"
                                        onClick={() => { setSubmitted(false); setEFStd({ title: '', public: true, client_ids: [] }); setEFStdModal(true) }}
                                        style={{
                                            background: '#005284',
                                            borderRadius: '10px',
                                            color: 'white',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            cursor: 'pointer'
                                        }}>
                                        <i className="material-icons" >add</i>
                                        {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                                    </div>
                                </div>
                                {(selected.tier !== '' && selected.tier !== null) && <div className="col-2 justify-content-end"
                                    
                                    style={{
                                        background: 'white',
                                        borderRadius: '10px',
                                        color: 'white',
                                        alignItems: 'center',
                                        display: 'flex',
                                        cursor: 'pointer'
                                    }}>
                                    <Button icon='pi pi-cloud-download' text raised aria-label="Filter" onClick={() => { export2Excel() }}>&nbsp; Export </Button>

                                    {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                                </div>}
                            </div>
                            {selected.tier !== '' && <div className="col-10 grid align-items-center">
                                <label className="col-2">
                                    Category <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-5">
                                    <Dropdown options={tier0} style={{ width: '100%' }} itemTemplate={categoryOptionTemplate} filter optionLabel="title" optionValue="id" value={selected.tier0} onChange={(e) => { getTier1Data(e.value) }} />
                                    {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                                </div>
                                <div className="col-1"
                                    onClick={() => { setSubmitted(false); setEFCat({ title: '' }); setEFCatModal(true) }}
                                    style={{
                                        background: '#005284',
                                        borderRadius: '10px',
                                        color: 'white',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        cursor: 'pointer'
                                    }}>
                                    <i className="material-icons" >add</i>
                                    {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                                </div>
                            </div>}

                            {selected.tier0 !== '' && <div className="field">
                                <div>
                                    <div className="col-12">
                                        <div className="grid">

                                            <div className="col-3">
                                                <div className="grid">
                                                    <div className="col-10">
                                                        <InputText
                                                            type={"text"}
                                                            value={module.tier1}
                                                            onChange={(e) => {
                                                                updateTierValue("tier1", e);
                                                            }}
                                                            placeholder="Enter SubCategory1"
                                                        />
                                                    </div>
                                                    <div className="col-2" style={{ marginLeft: -10 }}>
                                                        <Button
                                                            icon="pi pi-plus"

                                                            style={{ cursor: module.tier1.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier1.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier1.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                            className="p-button-success mr-2"
                                                            onClick={() => {
                                                                addNewTier1();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-12"
                                                    style={{
                                                        border: "1px solid grey",
                                                        height: "46vh",
                                                        overflow: "auto",
                                                        borderRadius: 5
                                                    }}
                                                >
                                                    {tier1.length === 0 ? (
                                                        <text
                                                            style={{
                                                                padding: 5,
                                                            }}
                                                        >

                                                            {"Add SubCategory1"}
                                                        </text>
                                                    ) : (
                                                        tier1.sort((a, b) => { return a.order - b.order }).map((item, cyindex) => {
                                                            return (
                                                                <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>

                                                                    <div className="col-11"
                                                                        onClick={() => {
                                                                            getTier2Data(item.id);
                                                                        }}
                                                                        style={{
                                                                            color: selected.tier1 === item.id && "white",
                                                                            background: selected.tier1 === item.id && "grey",
                                                                            borderBottom: "0.5px ridge grey", padding: 0
                                                                        }}
                                                                    >
                                                                        <div  >
                                                                            <label style={{
                                                                                padding: '5px',
                                                                                background: 'royalblue', width: 38,
                                                                                borderRadius: '5px', display: 'flex', justifyContent: 'center',
                                                                                color: 'white'
                                                                            }}>{item.id}</label>

                                                                        </div>
                                                                        <EdiText

                                                                            viewContainerClassName={selected.tier1 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                            type='text'
                                                                            value={item.title}
                                                                            onSave={(e) => { updateTier1(e, item.id); setEditModeOffTier1() }}
                                                                            editing={item.edit}
                                                                            onCancel={() => { setEditModeOffTier1() }}
                                                                            hideIcons={true}
                                                                            validationMessage="Title should not exist / not empty "
                                                                            validation={val => { console.log(val); return val.trim().length !== 0 && tier1.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                            saveButtonContent="✔"
                                                                            cancelOnUnfocus
                                                                            cancelButtonContent={<strong>X</strong>}

                                                                            editButtonClassName="custom-edit-button"
                                                                        />
                                                                        {/* <EditText
                                                                            onSave={(e) => {
                                                                                if (e.value.trim().length !== 0) {
                                                                                    updateTier1(e);
                                                                                }
                                                                            }}
                                                                            name="textbox3"
                                                                            // value={item.country}
                                                                            defaultValue={item.title}
                                                                            editButtonProps={{
                                                                                style: {
                                                                                    marginLeft: "5px",
                                                                                    width: 16,
                                                                                },
                                                                            }}
                                                                            showEditButton
                                                                        /> */}
                                                                    </div>
                                                                    <ContextMenu model={[
                                                                        {
                                                                            label: 'Edit',
                                                                            icon: 'pi pi-fw pi-pencil',
                                                                            command: () => { setEditModeTier1(cyindex) }
                                                                        },

                                                                        // {
                                                                        //     label: 'Delete',
                                                                        //     icon: 'pi pi-fw pi-trash', command: () => { deleteItem().then((result) => { if (result) { deleteTier1(item) } }) }
                                                                        // }
                                                                    ]} ref={(r) => { tier1ref.current[parseInt(`1${item.id}`)] = r }}></ContextMenu>
                                                                    <div className="col-1" onContextMenu={(e) => { showTier1CM(e, parseInt(`1${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                                        <i className="material-icons" >settings</i>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                            {selected.tier1 !== "" && (
                                                <div className="col-3">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}
                                                                value={module.tier2}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier2", e);
                                                                }}
                                                                placeholder="Enter SubCategory2"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier2.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier2.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier2.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {
                                                                    addNewTier2();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "46vh",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier2.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add SubCategory2"}
                                                            </text>
                                                        ) : (
                                                            tier2.sort((a, b) => { return a.order - b.order }).map((item, cyindex) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                            {/* <Tooltip target={".topic" + item.id} position={'top'} autoHide={true}> TP{item.id + ' ' + item.title} </Tooltip> */}
                                                                            <div className={"col-11 topic" + item.id}
                                                                                onClick={() => {
                                                                                    getTier3Data(item.id);
                                                                                }}
                                                                                style={{
                                                                                    color: selected.tier2 === item.id && "white",
                                                                                    background: selected.tier2 === item.id && "grey",
                                                                                    borderBottom: "0.5px ridge grey", padding: 0
                                                                                }}
                                                                            >
                                                                                <div  >
                                                                                    <label style={{
                                                                                        padding: '5px',
                                                                                        background: 'royalblue', width: 38,
                                                                                        borderRadius: '5px', display: 'flex', justifyContent: 'center',
                                                                                        color: 'white'
                                                                                    }}>{item.id}</label>

                                                                                </div>
                                                                                <EdiText

                                                                                    viewContainerClassName={selected.tier2 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                                    type='text'
                                                                                    value={item.title}
                                                                                    onSave={(e) => { updateTier2(e, item.id); setEditModeOffTier2() }}
                                                                                    editing={item.edit}
                                                                                    onCancel={() => { setEditModeOffTier2() }}
                                                                                    hideIcons={true}
                                                                                    validationMessage="Title should not exist / not empty "
                                                                                    validation={val => { console.log(val); return val.trim().length !== 0 && tier2.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                                    saveButtonContent="✔"
                                                                                    cancelOnUnfocus
                                                                                    cancelButtonContent={<strong>X</strong>}

                                                                                    editButtonClassName="custom-edit-button"
                                                                                />
                                                                                {/* <EditText
                                                                                    onSave={(e) => {
                                                                                        if (e.value.trim().length !== 0) {
                                                                                            updateTier2(e);
                                                                                        }
                                                                                    }}
                                                                                    name="textbox3"
                                                                                    // value={item.country}
                                                                                    defaultValue={item.title}
                                                                                    editButtonProps={{
                                                                                        style: {
                                                                                            marginLeft: "5px",
                                                                                            width: 16,
                                                                                        },
                                                                                    }}
                                                                                    showEditButton
                                                                                /> */}
                                                                            </div>
                                                                            <ContextMenu model={[
                                                                                {
                                                                                    label: 'Edit',
                                                                                    icon: 'pi pi-fw pi-pencil',
                                                                                    command: () => { setEditModeTier2(cyindex) }
                                                                                },

                                                                                (selected.tier2 === item.id && tier3.length === 0) ? {
                                                                                    label: 'Delete',
                                                                                    icon: 'pi pi-fw pi-trash', command: () => { deleteItem().then((result) => { if (result) { deleteTier2(item) } }) }
                                                                                } : null
                                                                            ].filter((i) => { return i !== null })} ref={(r) => { tier2ref.current[parseInt(`2${item.id}`)] = r }}></ContextMenu>
                                                                            <div className="col-1" onContextMenu={(e) => { showTier2CM(e, parseInt(`2${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                                                <i className="material-icons" >settings</i>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {selected.tier2 !== "" && (
                                                <div className="col-3">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}
                                                                value={module.tier3}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier3", e);
                                                                }}
                                                                placeholder="Enter SubCategory3"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier3.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier3.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier3.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {
                                                                    addNewTier3();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "46vh",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier3.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add SubCategory3"}
                                                            </text>
                                                        ) : (
                                                            tier3.sort((a, b) => { return a.order - b.order }).map((item, cyindex) => {
                                                                return (
                                                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                        {/* <Tooltip target={".metric" + item.id} position={'top'} autoHide={true}> MT{item.id}    </Tooltip> */}
                                                                        <div className={"col-11 " + " metric" + item.id}

                                                                            onClick={() => {

                                                                                getTier4Data(item.id);


                                                                                forceUpdate()
                                                                            }}
                                                                            style={{
                                                                                color: selected.tier3 === item.id && "white",
                                                                                background: selected.tier3 === item.id && "grey",
                                                                                borderBottom: "0.5px ridge grey", padding: 0,
                                                                            }}
                                                                        >
                                                                            <div  >
                                                                                <label style={{
                                                                                    padding: '5px',
                                                                                    background: 'royalblue', width: 38,
                                                                                    borderRadius: '5px', display: 'flex', justifyContent: 'center',
                                                                                    color: 'white'
                                                                                }}>{item.id}</label>

                                                                            </div>
                                                                            <EdiText

                                                                                viewContainerClassName={selected.tier3 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                                type='text'
                                                                                value={item.title}
                                                                                onSave={(e) => { updateTier3(e, item.id); setEditModeOffTier3() }}
                                                                                editing={item.edit}
                                                                                onCancel={() => { setEditModeOffTier3() }}
                                                                                hideIcons={true}
                                                                                validationMessage="Title should not exist / not empty "
                                                                                validation={val => { console.log(val); return val.trim().length !== 0 && tier3.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                                saveButtonContent="✔"
                                                                                cancelOnUnfocus
                                                                                cancelButtonContent={<strong>X</strong>}

                                                                                editButtonClassName="custom-edit-button"
                                                                            />
                                                                            {/* <EditText
                                                                                onSave={(e) => {
                                                                                    if (e.value.trim().length !== 0) {
                                                                                        updateTier3(e);
                                                                                    }
                                                                                }}
                                                                                name="textbox3"
                                                                                // value={item.country}
                                                                                defaultValue={item.title}
                                                                                editButtonProps={{
                                                                                    style: {
                                                                                        marginLeft: "5px",
                                                                                        width: 16,
                                                                                    },
                                                                                }}
                                                                                showEditButton
                                                                            /> */}
                                                                        </div>
                                                                        <ContextMenu model={[
                                                                            {
                                                                                label: 'Edit',
                                                                                icon: 'pi pi-fw pi-pencil',
                                                                                command: () => { setEditModeTier3(cyindex) }
                                                                            },

                                                                            (selected.tier3 === item.id && tier4.length === 0) ? {
                                                                                label: 'Delete',
                                                                                icon: 'pi pi-fw pi-trash', command: () => { deleteItem().then((result) => { if (result) { deleteTier3(item) } }) }
                                                                            } : null
                                                                        ].filter((i) => { return i !== null })
                                                                        } ref={(r) => { tier3ref.current[parseInt(`3${item.id}`)] = r }}></ContextMenu>
                                                                        <div className="col-1" onContextMenu={(e) => { showTier3CM(e, parseInt(`3${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} onClick={() => { }}>

                                                                            <i className="material-icons" style={{ color: !getRFStatus(item) && 'red' }} >settings</i>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {selected.tier3 !== "" && (
                                                <div className="col-3">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}

                                                                value={module.tier4}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier4", e);
                                                                }}
                                                                placeholder="Enter SubCategory4"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier4.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier4.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier4.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {

                                                                    addNewTier4();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "46vh",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier4.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add SubCategory4"}
                                                            </text>
                                                        ) : (
                                                            tier4.sort((a, b) => { return a.order - b.order }).map((item, cyindex) => {
                                                                return (
                                                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                        {/* <Tooltip target={".dp" + item.id} position={'top'} autoHide={true}> {item.title} </Tooltip> */}

                                                                        <div className={"col-11 " + " dp" + item.id}
                                                                            onClick={() => {

                                                                                getTier5Data(item.id);
                                                                            }}
                                                                            style={{
                                                                                borderBottom: "0.5px ridge grey", padding: 0
                                                                            }}

                                                                        >
                                                                            <div  >
                                                                                <label style={{
                                                                                    padding: '5px',
                                                                                    background: 'royalblue', width: 38,
                                                                                    borderRadius: '5px', display: 'flex', justifyContent: 'center',
                                                                                    color: 'white'
                                                                                }}>{item.id}</label>

                                                                            </div>
                                                                            <EdiText

                                                                                viewContainerClassName='my-custom-view-wrapper'

                                                                                type='text'
                                                                                value={item.title}
                                                                                onSave={(e) => { updateTier4(e, item.id); setEditModeOffTier4() }}
                                                                                editing={item.edit}
                                                                                onCancel={() => { setEditModeOffTier4() }}
                                                                                hideIcons={true}
                                                                                validationMessage="Title should not exist / not empty "
                                                                                validation={val => { return val.trim().length !== 0 && tier4.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                                saveButtonContent="✔"
                                                                                cancelOnUnfocus
                                                                                cancelButtonContent={<strong>X</strong>}

                                                                                editButtonClassName="custom-edit-button"
                                                                            />
                                                                            {/* <EditText
                                                                                onSave={(e) => {
                                                                                    if (e.value.trim().length !== 0) {
                                                                                        updateTier4(e);
                                                                                    }
                                                                                }}
                                                                                name="textbox3"

                                                                                // value={item.country}
                                                                                defaultValue={item.title}
                                                                                editButtonProps={{
                                                                                    style: {
                                                                                        marginLeft: "5px",
                                                                                        width: 16,
                                                                                    },
                                                                                }}
                                                                                showEditButton

                                                                            /> */}

                                                                        </div>
                                                                        <ContextMenu model={[
                                                                            {
                                                                                label: 'Edit',
                                                                                icon: 'pi pi-fw pi-pencil',
                                                                                command: () => { setEditModeTier4(cyindex) }
                                                                            },

                                                                            {
                                                                                label: 'Delete',
                                                                                icon: 'pi pi-fw pi-trash', command: () => { deleteItem().then((result) => { if (result) { deleteTier4(item) } }) }
                                                                            },
                                                                        ].filter((i) => { return i !== null })} ref={(r) => { tier4ref.current[parseInt(`4${item.id}`)] = r }}></ContextMenu>


                                                                        <div className="col-1" onContextMenu={(e) => { showTier4CM(e, parseInt(`4${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} onClick={() => { }}>

                                                                            <i className="material-icons"  >settings</i>
                                                                        </div>

                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <Dialog
                        visible={efcatmodal}
                        style={{
                            width: "60%",
                        }}
                        header={efcat.id === undefined ? 'Add Category' : 'Edit Category'}
                        modal
                        footer={addFooter3}
                        onHide={() => {
                            setEFCatModal(false);
                        }}
                    >
                        <div>
                            <div className="col-12 lg:col-12">
                                <div className="col-12 grid">

                                    <div className="col-6 grid align-items-center">
                                        <label className="col-4">
                                            Category Name
                                        </label>
                                        <div className="col-8">
                                            <InputText style={{ width: '100%' }} className={(submitted && efcat.title.trim().length === 0) ? 'p-invalid' : ''} value={efcat.title} onChange={(e) => { let loc = efcat; loc.title = e.target.value; setEFCat(loc); forceUpdate() }} />
                                        </div>

                                    </div>
                                </div>

                            </div>




                        </div>
                    </Dialog>
                    <Dialog
                        visible={efstdmodal}
                        style={{
                            width: "60%",
                        }}
                        header={'Add/Edit Standard'}
                        modal
                        footer={addFooter7}
                        onHide={() => {
                            setEFStdModal(false);
                        }}
                    >
                        <div>
                            <div className="col-12 lg:col-12">
                                <div className="col-12 ">

                                    <div className="col-6 grid align-items-center">
                                        <label className="col-4">
                                            Standard Name
                                        </label>
                                        <div className="col-8">
                                            <InputText style={{ width: '100%' }} className={(submitted && efstd.title.trim().length === 0) ? 'p-invalid' : ''} value={efstd.title} onChange={(e) => { let loc = efstd; loc.title = e.target.value; setEFStd(loc); forceUpdate() }} />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12 flex align-items-center" >
                                    <Checkbox inputId={"cb"} name={'public'} value={efstd.efstd} onChange={(e) => { let loc = efstd; loc.public = e.checked; loc.client_ids = []; setEFStd(loc); forceUpdate() }} checked={efstd.public === true} />
                                    <label htmlFor={"cb"} className="ml-2">{'Public'}</label>
                                </div>
                                {!efstd.public &&
                                    <div className="col-12 ">

                                        <div className="col-10 grid align-items-center">
                                            <label className="col-4">
                                                Select Client
                                            </label>
                                            <div className="col-8">
                                                <MultiSelect className={"w-full " + (((submitted && efstd.client_ids === null) || (submitted && efstd.client_ids.length === 0)) && 'p-invalid')} panelClassName={'hidefilter'} display="chip" value={efstd.client_ids} style={{ width: '100%' }} optionValue="id" optionLabel="information.companyname" filter options={clientList} onChange={(e) => { let loc = efstd; loc.client_ids = e.value; setEFStd(loc); forceUpdate() }} />

                                            </div>

                                        </div>
                                    </div>

                                }
                            </div>




                        </div>

                    </Dialog>
                </div>
            ) : (
                <div className="col-12 card">You have no rights to access this page </div>
            )}

        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EFCategoryConfig, comparisonFn);
