import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../components/constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { BP2LQ3, BP2LQ5, BP8EQ2, Business_Travel, Fugitive, Mobile_Combustion, Purchase_Goods_Services, Stationary_Combustion } from "./hardcodedforms";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Editor } from "primereact/editor";
import APIServices from "../service/APIService";
window.jQuery = $;
window.$ = $;
const { DateTime } = require('luxon')
const CFInputEntrySubmitted = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [form, setForm] = useState({ data1: [], title: 'Invalid Form' })
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const { id } = useParams();
    const params = useLocation()
    const hardcodeddcf = ['10', '11', '36', '15', '16', '188','195','196']

    useEffect(async () => {
        console.log(params.state)

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let site_url = API.LocationOne_UP(selector.clientId) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;

        APIServices.get(API.DCF_Edit(id)).then((res) => {

            res.data.data1 = params.state.response
            setForm(res.data)


        })


        APIServices.get(site_url).then((res) => {
            let site_list = []
            res.data.forEach((country) => {
                if (country.locationTwos !== undefined) {
                    country.locationTwos.forEach((city) => {
                        if (city.locationThrees !== undefined) {
                            city.locationThrees.forEach((site) => {

                                site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                            })
                        }
                    })
                }
            })
            console.log(site_list, params.state.site)
            setSiteList(site_list)
        })

    }, [selector]);

    const getRP = (months) => {
        console.log(months)
        if (months.includes('to')) {

            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val) => {
        item.value = val;
        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ flexDirection: 'column', marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    {item.values.map((cb, cbind) => {
                        return (
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox disabled inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                            </div>
                        )
                    })

                    }


                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar disabled style={{ margin: 10 }} value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <InputText disabled keyfilter="num" style={{ margin: 10 }} value={item.value} onChange={(e) => { onNumberChange(item, parseFloat(e.target.value), isNaN(e.target.value)) }} />
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                    <label style={{ margin: 10 }}>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    {item.values.map((cb, cbind) => {
                        return (
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <RadioButton disabled inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                            </div>
                        )
                    })

                    }


                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ flexDirection: 'column', marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Dropdown disabled options={item.values} style={{ width: '25%' }} optionLabel='label' optionValue="label" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <InputText disabled style={{ margin: 10 }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <InputTextarea disabled style={{ margin: 10, width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                </div>
            )
        }
        else if (item.type === 'htmleditor') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div style={{ width: '100%', margin: 10 }}>
                    <div dangerouslySetInnerHTML={{__html:item.label}}></div>
                        {/* <Editor disabled value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 'auto', overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} /> */}

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-12" style={{
                        border: '1px solid #6366F1',
                        borderRadius: '10px'
                    }}>

                        {item.value !== undefined && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: '300px',
                                overflow: 'scroll'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {
                                        console.log(file)
                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 10
                                                }} >
                                                    <div className="flex align-items-center" style={{ width: '50%' }}>
                                                        {file.extension === '.pdf' ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '25%' }} severity="warning" className="px-3 py-2" />

                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        } else if (item.type === 'table') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <DataTable rowClassName={'p-disabled'} className="fullheight" style={{ width: '100%' }} value={item.data}  >


                        {item.value.map((h) => {

                            return <Column bodyClassName={(a) => { return a[h].type === 5 ? 'p-disabled' : '' }} header={h} body={(rowData) => { return renderTableData(rowData[h]); }} editor={(options) => { return renderEditor(options) }} onCellEditComplete={(e) => { onCellEditComplete(e, index) }} />;
                        })}


                    </DataTable>

                </div>
            )
        }
        else if (item.type === 'tableadd') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
              
                    <DataTable rowClassName={'p-disabled'} scrollable showGridlines className="fullheight" style={{ width: '100%', maxHeight: 300 }} value={item.data}  >


                        {item.value.map((h) => {

                            return <Column bodyClassName={(a) => { return a[h].type === 5 ? 'p-disabled' : '' }} header={h} body={(rowData) => { return renderTableData(rowData[h]); }} editor={(options) => { return renderEditor(options) }} onCellEditComplete={(e) => { onCellEditComplete(e, index) }} />;
                        })}
                        

                    </DataTable>

                </div>
            )
        }
        else if (item.type === 'checkpoint') {
            console.log(item)
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        margin: '10px'
                    }}>
                        <div className="grid">
                            {item.values.map((i) => {
                                return <label  className="col-2" style={{ justifyContent: 'center', borderRadius: 5, display: 'flex', width: 110, color: item.value === i.value ? 'white' : 'black', background: item.value === i.value ? item.value === '1' ? 'green' : item.value === '2' ? 'red' : 'orange' : 'white', border: '1px solid darkgray' }}>{i.label}</label>
                            })}

                        </div>

                    </div>

                </div>
            )
        }

    }
  
    const renderEditor = (options) => {
        let item = getObjectAtIndex(options.rowData, parseInt(options.field.split('_')[1]))

        switch (item.type) {

            case 1:
                return (<InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />)
            case 2:
                return (<InputTextarea value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />)
            case 3:
                return (<InputText type="number" value={options.value} onChange={(e) => options.editorCallback(parseFloat(e.target.value))} />)
            case 4:

                return (<Dropdown optionLabel="label" optionValue="value" value={options.value} options={item.data.values} onChange={(e) => options.editorCallback(e.value)} />)
            case 6:

                return (<Calendar dateFormat="dd/mm/yy" value={options.value} onChange={(e) => options.editorCallback(e.value)} />)

            case 5:
                return null
        }
    }
    const renderTableData = (rowData) => {

        switch (rowData.type) {
            case 5:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.label}</div>);
            case 1:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.value === undefined ? 'click here' : rowData.data.value}</div>);
            case 2:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.value === undefined ? 'click here' : rowData.data.value}</div>);
            case 3:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.value === undefined ? 'click here' : rowData.data.value}</div>);
            case 4:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{(rowData.data.value === null || rowData.data.value === undefined) ? 'Select option' : rowData.data.values.find((i) => { return i.value === rowData.data.value }).label}</div>);
            case 6:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}> {(rowData.data.value === undefined || rowData.data.value == null) ? 'click here' : formatDate(rowData.data.value, 'dd-MM-yyyy')}</div>);
        }

    }
    const onCellEditComplete = (e, dataind) => {
        let { rowData, newValue, cellIndex, field, rowIndex, originalEvent: event } = e;

        let loc = JSON.parse(JSON.stringify(form))
        setTimeout(() => {
            let item = getObjectAtIndex(loc.data1[dataind].data[rowIndex], cellIndex)
            item.data['value'] = rowData[field];
            loc.data1[dataind].data[rowIndex][loc.data1[dataind].value[cellIndex]].data['value'] = rowData[field];
            delete loc.data1[dataind].data[rowIndex][field]
            setForm(loc)
            console.log(loc)
            forceUpdate()
        }, 100)

    };
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const getObjectAtIndex = (data, index) => {
        const keys = Object.keys(data);
        const key = keys[index];
        return data[key];
    };
    const getRPMonth = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                console.log(item)
                return moment(item[0], 'MM-YYYY').format('MMM-YYYY') + ' to ' + moment(item[item.length - 1], 'MM-YYYY').format('MMM-YYYY')
            } else {
                return moment(item[0], 'MM-YYYY').format('MMM-YYYY')
            }
        }
    }
    return (
        <div className="grid" style={{ margin: 20, marginTop: 90 }}>
            <div className="col-12">
                {(selector.id !== undefined &&  form.data1.length !== 0) ?
                    <div className="card">

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <h4>{form.title}</h4>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>    <span style={{ fontWeight: 'bold' }}>Reporting Period :&nbsp;</span> {getRPMonth(params.state.reporting_period)}</div>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>        <span style={{ fontWeight: 'bold' }}>Site :&nbsp;</span> {params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name}  </div>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>    <span style={{ fontWeight: 'bold' }}>Submitted on :&nbsp;</span> {moment(params.state.modified_on === null ? params.state.created_on : params.state.modified_on).local().format('DD MMM YYYY, hh:mm A')}  </div>

                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>          <span style={{ fontWeight: 'bold' }}>Version :&nbsp;</span> {moment(form.updated).local().format('DD MMM YYYY, hh:mm A')}  </div>
                            </div>

                        </div>
                        {form.comments.trim().length !== 0 &&
                            <div className="p-card" style={{ padding: 10, marginBottom: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>Note :&nbsp;</span> {form.comments}
                            </div>}
                        {form.data1.length !== 0 ?
                            <div>
                                <div>
                                    {form.data1.map((item, index) => {

                                        return renderItems(item, index)
                                    })

                                    }
                                </div>
                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>


                                </div>


                            </div>
                            

                                                            : 'Contact Admin'
                        }




                    </div>
                    :
                    <div className="col-12 card">Form not found</div>
                    // <div className="col-12 card">You have no rights to access this page </div>

                }
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CFInputEntrySubmitted, comparisonFn);
