import React, { useEffect, useState, useRef } from 'react';
import Axios from 'axios';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';
import axios from 'axios';
import IATACodes from '../assets/dummy/IATACodes'
import { AutoComplete as AC } from 'primereact/autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import readXlsxFile from 'read-excel-file'
import { Toast } from 'primereact/toast';
import * as XLSX from 'xlsx'
import diesel from '../assets/dummy/diesel';
import APIServices from '../service/APIService';
const google = window.google;

const EFInputEntryRespo = () => {
    const [apiData, setApiData] = useState([])
    const [year, setYear] = useState([{ name: '2019', code: 'SP' }, { name: '2020', code: 'DUC' }, { name: '2021', code: 'CUIN' }, { name: '2022', code: 'PAC' },{name:'2023',code:'2T23'} ]);
    const [scopeList, setScopeList] = useState([{ name: 'Scope1', code: 'SP' }, { name: 'Scope2', code: 'DUC' }, { name: 'Scope3', code: 'CUIN' }]);
    const [countryList, setCountryList] = useState([{ name: 'India'},{ name: 'Singapore'},{ name: 'Malasiya'},{ name: 'UK'},{ name: 'Japan'},{ name: 'South Korea'},{ name: 'Thailand'},{ name: 'London'}]);
   
    const [standards, setStandards] = useState([])
    const [selected, setSelected] = useState({country:'', year: '', standards: '', scope: '', list: [] })
    const [selectedRow, setSelectedRow] = useState([]);
    const [addStd, setAddStd] = useState(false)
    const [newStd, setNewStd] = useState('')
    const [editDialog, setEditDialog] = useState(false)
    const forceUpdate = useForceUpdate()
    const toast = useRef(null);
    const fileRef = useRef(null)



    const updateSelected = (obj, val) => {
        let local = selected;
        //  console.log(obj === 'standards' ? val.name : selected.standards.name,'hi')
        //  console.log(obj === 'year' ? val.name : selected.year.name,'hi')
        //  console.log(obj === 'scope' ? val.name : selected.scope.name,'hi')

        if (obj === 'scope') {
            let std = []
            apiData.forEach((item) => {
               
                if (std.findIndex((i) => { return i.name === item.standards }) === -1 && selected.year.name === item.year && val.name === item.scope) {
                    std.push({ name: item.standards })

                }
            })
            setStandards(std)
        }
        if (obj === 'year') {
            local.standards = ''
            let std = []
            apiData.forEach((item) => {
              
                if (std.findIndex((i) => { return i.name === item.standards }) === -1 && val.name === item.year && selected.scope.name === item.scope) {
                    std.push({ name: item.standards })

                }
            })
            setStandards(std)
        }
        let check = 0
        apiData.forEach((item) => {
            if ((item.year === (obj === 'year' ? val.name : selected.year.name))) {
               
                if ((item.scope === (obj === 'scope' ? val.name : selected.scope.name))) {
                
                    if ((item.standards === (obj === 'standards' ? val.name : selected.standards.name))) {
                      
                        check = 1
                        local.list = item.list
                    }
                }


            }
        })
      
        if (check === 0) {
            local.list = []
        }


        local[obj] = val

        setSelected(local)
        forceUpdate()
    }
    //randomNumberGeneartor
    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }
    const importExcel = (files) => {


        if (files.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                let json = []
                for (const sheet in workbook.Sheets) {
                    const worksheet = workbook.Sheets[sheet];
                    json.push({ [sheet]: XLSX.utils.sheet_to_json(worksheet) });
                }
                if (json.length !== 0) {
                    let local = selected
                    local.list = json
                    setSelected(local)
                    forceUpdate()
                }
             
            };

            reader.readAsArrayBuffer(files.files[0]);
        }

    }
  

    const getTopic = (name) => {
        console.log(selected.scope.name.includes('Scope3'))
        if (selected.scope.name.includes('Scope1')) {
            if (name === 'S1_COF') {
                return 'Combustion of Fuel'
            } else if (name === 'S1_R') {
                return 'Refrigerent'
            } else if (name === 'S1_OV') {
                return 'Owned Vehicle'
            } else {
                return null
            }
        } else if (selected.scope.name.includes('Scope3')) {
            if (name === 'S3_PG') {
                return 'Purchased goods and services'
            } else if (name === 'S3_BTR') {
                return 'Business Travel - Road'
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const importExcel_ = (e) => {

        const file = e.files[0];

        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = []
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false }).length !== 0) {
                        json.push({ [sheet]: XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false }) });
                    }

                }
                let local = selected
                local.list = []
                json.forEach((a, i) => {

                    if (getTopic(Object.keys(a)[0]) !== null) {
                       
                        a[Object.keys(a)[0]][0].unshift('scope')
                        const cols = a[Object.keys(a)[0]][0];
                        a[Object.keys(a)[0]].shift();

                        let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
                        let _importedData = a[Object.keys(a)[0]].map(d => {
                           
                            d.unshift(getTopic(Object.keys(a)[0]))
                            return cols.reduce((obj, c, i) => {



                                obj[c] = d[i];
                                return obj;


                            }, {});
                        });

                        local.list.push({ title: getTopic(Object.keys(a)[0]), importedCells: _importedCols, importedData: _importedData })


                    }
                })

                setSelected(local)
                forceUpdate()
                // Prepare DataTable
                // const cols = data[0];
                // data.shift();

                // let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
                // let _importedData = data.map(d => {
                //     return cols.reduce((obj, c, i) => {

                //         if(obj !== {}){

                //             obj[c] = d[i];
                //             return obj;
                //         }

                //     }, {});
                // });

                // setImportedCols(_importedCols);
                // setImportedData(_importedData);
            };

            reader.readAsArrayBuffer(file);
        });
    }

    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const clear = () => {
        if (fileRef !== null) {
            if (fileRef.current.state.files.length > 0) {
                fileRef.current.clear()
            }
        }
        let local = selected
        local.list = []
        setSelectedRow([])
        setSelected(local)
        forceUpdate()
    }
    const updateEditedCells = (e) => {

        // let local = selected
        // local.list.forEach((item) => {
        //     if (item.title === selectedRow[0].scope) {
        //         let index = item.importedData.findIndex((i) => { return i.id === selectedRow[0].id })
        //         console.log(item.importedData[index])
        //     }
        // })
        //  setSelected(local)

    }
    const deleteSelected = (e) => {

        let local = selected
        local.list.forEach((item) => {
            if (item.title === selectedRow[0].scope) {
                let index = item.importedData.findIndex((i) => { return i.id === selectedRow[0].id })
                if (index !== -1) {
                    item.importedData.splice(index, 1)
                }
            }
        })
        setSelected(local)
        setEditDialog(false)
    }
    const onImportSelectionChange = (e) => {
       
        setEditDialog(true)
        setSelectedRow(e.value)

    }


    const hideEditDialog = () => {
        setEditDialog(false)
    }
    const editValueUpdate = (e, obj, row) => {

        row[obj] = e.target.value
        setSelectedRow([row])


    }
    const editDialogFooter = (
        <>

            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideEditDialog} />
            <Button label="Delete" style={{ color: 'red' }} icon="pi pi-times" className="p-button-text" onClick={deleteSelected} />
            {/* <Button label="Save & Exit" icon="pi pi-check" className="p-button-text" onClick={(e) => { updateEditedCells(e) }} /> */}
        </>
    );
    const renderList = (data) => {

        return (<>
            <div className="card">
                <h5>{data.title}</h5>



                <DataTable value={data.importedData} emptyMessage="No data" selection={selectedRow} onSelectionChange={(e) => { onImportSelectionChange(e) }} paginator rows={10} alwaysShowPaginator={false} responsiveLayout="scroll"
                    selectionMode="multiple" >
                    {
                        data.importedCells.map((col, index) => {
                            if ( col.field !== 'scope') {
                                return <Column key={index} field={col.field} header={col.header} />
                            }
                        })
                    }
                </DataTable>
                <Dialog visible={editDialog} style={{ width: '450px' }} header={`Update selected values`} modal footer={editDialogFooter} onHide={hideEditDialog}>
                    <div className="flex align-items-center justify-content-center">
                        {selectedRow.map((row) => {

                            return (<div style={{ display: 'flex', flexDirection: 'column' }}>
                                {
                                    Object.keys(row).map((item) => {
                                        if (item !== 'id' && item !== 'scope') {
                                            return (

                                                <div className='col-12' style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h5 style={{ width: '50%' }}>{item}</h5>
                                                    <input style={{ width: '50%', borderRadius: 5 }} disabled={row[item] === undefined ? true : false} value={row[item]} onChange={(e) => { editValueUpdate(e, item, row) }} />

                                                </div>
                                            )
                                        }
                                    })
                                } </div>)
                        })


                        }
                    </div>
                </Dialog>
            </div>
        </>)

        // return selected.list.map((item) => {

        //     if (Object.keys(item)[0] === 'S1_COF') {

        //         return (<div>
        //             {Object.keys(item)[0]}
        //         </div>)

        //     }

        // })

    }

    const addNewStandards = (val) => {
        if (val.trim().length !== 0) {
            setNewStd(val)
        }
    }
    const addStandards = () => {
        let local = standards;
        let select = selected
        let check = standards.findIndex((i) => i.name === newStd)
        if (check === -1) {
            local.push({ name: newStd });
            select.list = []
            select.standards = { name: newStd }
            setSelected(select)
            setStandards(local)
            setAddStd(false)

        } else {
            setAddStd(false)

        }
    }
    const addStandardsDialogFooter = (
        <>

            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setAddStd(false) }} />
            <Button label="Add" icon="pi pi-check" className="p-button-text" onClick={addStandards} />
        </>
    );


    return (
        <div className="grid p-fluid">
            <div className="col-12 md:col-12">
                <div className="card " >

                    Configuration
                    <div className="col-12 md:col-12" style={{ flexDirection: 'row', display: 'flex' }}>
                    <div className='col-4'>
                                <h5>Choose Country</h5>
                                <Dropdown value={selected.country} onChange={(e) => updateSelected('country', e.value)} options={countryList} optionLabel="name" placeholder="Select" />

                            </div>
                       {selected.country !== '' && 
                       <div className='col-4'>
                            <h5>Choose Year</h5>
                            <Dropdown value={selected.year} onChange={(e) => updateSelected('year', e.value)} options={year} optionLabel="name" placeholder="Select" />

                        </div>}
                        {selected.year !== '' &&
                            <div className='col-4'>
                                <h5>Choose Scope</h5>
                                <Dropdown value={selected.scope} onChange={(e) => updateSelected('scope', e.value)} options={scopeList} optionLabel="name" placeholder="Select" />

                            </div>

                        }
                      
                    </div>
                    {selected.scope !== '' &&
                            <>

                                <div className='col-4'>
                                    <h5>Choose standards</h5>
                                    <div style={{ display: 'flex' }}>
                                        <Dropdown value={selected.standards} style={{ width: '100%' }} onChange={(e) => updateSelected('standards', e.value)} options={standards} optionLabel="name" placeholder="Select" />
                                        <Button icon="pi pi-plus" className="ml-2" onClick={() => { setAddStd(true) }}></Button>
                                    </div>

                                </div>


                                <Dialog visible={addStd} style={{ width: '450px' }} header="Add New Standards" modal footer={addStandardsDialogFooter} onHide={() => { setAddStd(false) }}>
                                    <div className="flex align-items-center justify-content-center">
                                        <h5>Enter standard name</h5>
                                        <InputText type="search" onInput={(e) => { addNewStandards(e.target.value) }} placeholder="Standards" />
                                    </div>
                                </Dialog>
                            </>


                        }
                    {selected.standards !== '' &&

                        <div className='col-4'>
                            <h5>Import File</h5>

                            {/* <FileUpload mode="basic" chooseLabel='Upload' customUpload uploadHandler={files => { importExcel(files) }} name="demo[]" auto={true} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" maxFileSize={1000000} /> */}
                            <Toast ref={toast} />

                            <div className="flex align-items-center py-2">

                                <FileUpload ref={fileRef} chooseOptions={{ label: 'Excel', icon: 'pi pi-file-excel', className: 'p-button-success' }} mode="basic" name="demo[]" auto customUpload
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="mr-2" uploadHandler={importExcel_} />
                                <Button type="button" label="Reset" style={{ width: 'auto' }} icon="pi pi-times" onClick={clear} className="p-button-info" />
                            </div>
                        </div>

                    }


                    <div className="col-12 lg:col-12">

                        {selected.list !== undefined && selected.list.length !== 0 &&
                            selected.list.map((a) => {
                                return renderList(a)
                            })



                        }
                       
                    </div>



                </div>
            </div>
        </div>
    )
}

export default EFInputEntryRespo