import Axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../components/constants/api_url";
import { Dropdown } from "primereact/dropdown";
import useForceUpdate from "use-force-update";
import { Button } from "primereact/button";
import Swal from "sweetalert2";
import APIServices from "../service/APIService";


const NewDataPointTransfer = () => {
    const [metricList, setMetricList] = useState([])
    const [selected, setSelected] = useState({ old: null, new: null })
    const [dpList, setDPList] = useState({ old: [], new: [] })
    const forceUpdate = useForceUpdate()

    useEffect(() => {

        let uriString = {
            "include": [{ "relation": "newDataPoints" }]

        }


        let site_url = API.Metric + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
        if (metricList.length == 0) {
            APIServices.get(site_url).then((a) => { setMetricList(a.data); forceUpdate() })
        }

    }, [])
    const updateMetric = (obj, val) => {
        let loc = selected
        let loc2 = dpList
        loc[obj] = val
        let index = metricList.findIndex((k) => { return k.id === val })
        if (index !== -1) {
            loc2[obj] = metricList[index].newDataPoints !== undefined ? metricList[index].newDataPoints : []

        }
        setDPList(loc2)
        setSelected(loc)
        forceUpdate()
    }
    const transferDataPoints = async () => {
        let index = metricList.findIndex((k) => { return k.id === selected.old })
        let index2 = metricList.findIndex((k) => { return k.id === selected.new })
        let title1 = metricList[index].title
        let title2 = metricList[index2].title
        console.log(metricList[index2])
        if (dpList.old.length !== 0 && (selected.old !== selected.new) && (metricList[index2].newDataPoints === undefined || metricList[index2].newDataPoints.length === 0)) {
            const { value: accept } = await Swal.fire({
                title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Data Transfer</div>`,
                html: `<div style="overflow:auto;max-height:200px" >Are you sure want to transfer data points from  <h4 >${title1}</h4> to <h4>${title2}</h4>
          </div>`,
                confirmButtonColor: 'green',
                showCancelButton: true,

                confirmButtonText:
                    'Transfer',

            })
            if (accept) {
                let req = []
                dpList.old.forEach((i) => {
                    delete i.id
                    delete i.newMetricId
                    if (i.created === null) {
                        delete i.created
                    }
                    if (i.data1 === null) {
                        delete i.data1
                    }
                    if (i.data2 === null) {
                        delete i.data2
                    }
                    if (i.extra === null) {
                        delete i.extra
                    }
                    req.push(i)
                })
                req.reverse().forEach(async (data, i) => {
                    try {
                        const response = await APIServices.post(API.DataPoint_Metric(selected.new), data)
                        if (response.status === 200) {
                            if (i === req.length - 1) {
                                let loc = selected
                                loc.new = null
                                loc.old = null
                                setSelected(loc)
                                let loc2 = dpList
                                loc2.new = []
                                loc2.old = []
                                setDPList(loc2)
                                forceUpdate()
                            }
                            if (metricList[index2].newDataPoints === undefined) {
                                metricList[index2]['newDataPoints'] = []
                                metricList[index2].newDataPoints.push(response.data)
                            } else {
                                metricList[index2].newDataPoints.push(response.data)
                            }

                        }

                    } catch (error) {
                        if (i === req.length - 1) {
                            let loc = selected
                            loc.new = null
                            loc.old = null
                            setSelected(loc)
                            let loc2 = dpList
                            loc2.new = []
                            loc2.old = []
                            setDPList(loc2)
                            forceUpdate()
                        }
                        console.error(`Error for data set ${selected.new}:`, error);
                    }

                })

            }
        }
    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card grid" >
                    <div className="col-6">
                        <label className="col-2">Select Old Indicator</label>
                        <Dropdown options={metricList} style={{ width: '100%' }} optionLabel="title" filter filterBy="title" optionValue="id" value={selected.old} onChange={(e) => { updateMetric('old', e.value) }} />

                    </div>
                    <div className="col-6">
                        <label className="col-2">Select New Indicator </label>
                        <Dropdown options={metricList} style={{ width: '100%' }} optionLabel="title" filter filterBy="title" optionValue="id" value={selected.new} onChange={(e) => { updateMetric('new', e.value) }} />
                    </div>
                    <div className="col-12" style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
                        <Button label="Transfer" onClick={() => { transferDataPoints() }} />
                    </div>
                </div>
                <div className="col-12 card" style={{ overflow: 'scroll' }}>
                    <label >Data Points from Old Indicators ( Total:  {dpList.old.length} )</label>
                    <div style={{ marginTop: 20 }}>
                        {dpList.old.map((item) => {
                            return (
                                <div style={{ margin: 10, marginTop: 30, whiteSpace: 'nowrap', display: 'flex', position: 'relative' }}>

                                    <label style={{ padding: 5, background: '#005284', color: 'white', borderRadius: 10, zIndex: 2 }} >{item.title}</label>
                                    {item.suffix !== null && <span style={{
                                        background: 'cadetblue',
                                        padding: '5px',
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        position: 'absolute',
                                        color: 'white',
                                        top: '-24px',
                                        left: '8px'
                                    }}>{item.suffix}</span>}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(NewDataPointTransfer, comparisonFn);
