import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import APIServices from "../service/APIService";
import { API } from "../components/constants/api_url";

const ClientApproverConfig = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [user, setUser] = useState([]);
    const forceUpdate = useForceUpdate();
    const [old, setOld] = useState([]);
    const [location, setLocation] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userConfig, setUserConfig] = useState({
        name: "",
        location: {
            countrylist: [],
            newcountry: "",
            newcity: "",
            newlocation: "",
            selectedcountry: "",
            citylist: [],
            selectedcity: "",
            locationlist: [],
            selectedlocation: "",
        },
    });
    const [cascade, setCascade] = useState("");
    const [moduleList, setModuleList] = useState({
        mod: [],
        title: [],
        topic: [],
    });
    useEffect(async () => {
        let locloc = [];
        setOld(selector.information);



        APIServices.get(API.UserProfile).then((res) => {
            let locuser = [];
            setUserList(res.data);
            res.data.forEach((item) => {
                console.log(item ,selector) 
                if (item.information.companyid === selector.userId && item.role !== "clientadmin" && item.information.config[0].admin.approver) {
                    locuser.push({
                        name: item.information.empname + " " + `(${item.information.empid})`,
                        id: item.id,
                    });
                }
            });
            let locloc = userConfig;
            userConfig.location.countrylist = selector.information.config[0].location.countrylist;
            setUser(locuser);
        });
    }, [selector]);
    const updateUserConfig = (obj, val) => {
        console.log(val);
        let loc = userConfig;
        loc[obj] = val;
        if (val !== undefined) {
            APIServices.get(API.UserProfile_Edit(val.id)
            ).then((res) => {
                let locloc = [];
                console.log(res.data);
                res.data.information.config[0].selectedLocation.forEach((i) => {
                    i.name = i.country;
                    i.city.map((k) => {
                        k.name = k.city;
                        k.location.map((j) => {
                            if (j.module2 === undefined) {
                                j.module2 = [];
                            }

                            j.label = {
                                country: i.country,
                                city: k.city,
                                location: j.location,
                            };
                        });
                    });
                    locloc.push(i);
                });
                setLocation(locloc);
                setUserConfig(loc);
            });
        }

        setCascade("");
        forceUpdate();
    };
    const addConfigCountry = () => {
        if (userConfig.location.newcountry !== "") {
            let loc = userConfig;
            let index = loc.location.countrylist.findIndex((i) => {
                return i.country.trim().toLowerCase() === userConfig.location.newcountry.trim().toLowerCase();
            });

            if (index === -1) {
                loc.location.countrylist.push({
                    country: userConfig.location.newcountry,
                    city: [],
                });
                loc.location.newcountry = "";
                setUserConfig(loc);
                forceUpdate();
            }
        }
    };
    const addConfigCity = () => {
        if (userConfig.location.newcity !== "") {
            let loc = userConfig;
            let index = loc.location.countrylist.findIndex((i) => {
                return i.country.trim().toLowerCase() === userConfig.location.selectedcountry.trim().toLowerCase();
            });

            let index2 = loc.location.countrylist[index].city.findIndex((i) => {
                return i.city.trim().toLowerCase() === userConfig.location.newcity.trim().toLowerCase();
            });
            if (index2 === -1) {
                loc.location.countrylist[index].city.push({
                    city: userConfig.location.newcity,
                    location: [],
                });
                loc.location.newcity = "";
                setUserConfig(loc);
                forceUpdate();
            }
        }
    };
    const addConfigLocation = () => {
        if (userConfig.location.newlocation !== "") {
            let loc = userConfig;
            let index = loc.location.countrylist.findIndex((i) => {
                return i.country.trim().toLowerCase() === userConfig.location.selectedcountry.trim().toLowerCase();
            });

            let index2 = loc.location.countrylist[index].city.findIndex((i) => {
                return i.city.trim().toLowerCase() === userConfig.location.selectedcity.trim().toLowerCase();
            });

            let index3 = loc.location.countrylist[index].city[index2].location.findIndex((i) => {
                return i.location.trim().toLowerCase() === userConfig.location.newlocation.trim().toLowerCase();
            });

            if (index3 === -1) {
                loc.location.countrylist[index].city[index2].location.push({
                    location: userConfig.location.newlocation,
                });
                loc.location.newlocation = "";
                setUserConfig(loc);
                forceUpdate();
            }
        }
    };
    const getCountryIndex = () => {
        let index = userConfig.location.countrylist.findIndex((i) => {
            return i.country === userConfig.location.selectedcountry;
        });

        return index;
    };
    const getCityIndex = () => {
        let index = userConfig.location.countrylist[getCountryIndex()].city.findIndex((i) => {
            return i.city === userConfig.location.selectedcity;
        });
        return index;
    };
    const updateCascade = (event) => {
        let data = event.value;

        if (data.module2.length === 0) {
            let d = [];
            let mod = JSON.parse(JSON.stringify(moduleList.topic[0]));

            data.module2 = mod;
        }

        setCascade(data);
        forceUpdate();
    };
    const showSaveButton = () => {
        if (cascade.module === undefined || cascade.module.length === 0) {
            return true;
        } else {
            let count = cascade.module.filter((j) => {
                return j.selected === 1;
            });
            if (count.length === 8) {
                return false;
            } else {
                return true;
            }
        }
    };
    const saveUserConfig = () => {
        let index = userList.findIndex((o) => {
            return o.id === userConfig.name.id;
        });
        userList[index].company = selector.information.companyname;
        userList[index].information.config[0].selectedLocation = location;
        delete userList[index].username;
        delete userList[index].email;
        console.log(userList[index]);
        APIServices.patch(API.UserProfile_Edit(userConfig.name.id),
         userList[index])
            .then((res) => {
                console.log(res);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <label
                        style={{
                            marginRight: 10,
                        }}
                    >
                        
                        Select Approver
                    </label>
                    <Dropdown value={userConfig.name} options={user} onChange={(e) => updateUserConfig("name", e.value)} optionLabel="name" filter showClear filterBy="name" placeholder="Select name" />
                    {userConfig.name !== undefined && userConfig.name !== "" && (
                        <div
                            style={{
                                marginRight: 10,
                                marginTop: 10,
                            }}
                        >
                            <label
                                style={{
                                    marginRight: 10,
                                }}
                            >
                                
                                Select Site / Location
                            </label>
                            <CascadeSelect
                                value={cascade}
                                options={location}
                                optionLabel={"location"}
                                optionGroupLabel={"name"}
                                optionGroupChildren={["city", "location"]}
                                style={{
                                    minWidth: "14rem",
                                }}
                                placeholder={"Select a Location"}
                                onChange={(event) => {
                                    updateCascade(event);
                                }}
                            />
                        </div>
                    )}
                    {cascade !== "" && (
                        <div>
                            
                            {cascade.module2.length !== 0 && (
                                <div>
                                    
                                    {cascade.module2.map((i, ind) => {
                                        let topicindex = moduleList.topic[0].findIndex((j) => {
                                            return j.id === i.id;
                                        });
                                        if (topicindex !== -1) {
                                            if (cascade.module !== undefined && cascade.module.length !== 0) {
                                                if (cascade.module[ind].selected === 1) {
                                                    i.reject = 0;
                                                }
                                            }
                                            return (
                                                <div
                                                    style={{
                                                        marginTop: 20,
                                                        marginBottom: 20,
                                                    }}
                                                >
                                                    
                                                    {i.reject === 0 ? (
                                                        ""
                                                    ) : (
                                                        <div
                                                            style={{
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                display: "flex",
                                                            }}
                                                            onClick={() => {
                                                                i.selected = i.selected === 1 ? 0 : 1;
                                                                forceUpdate();
                                                            }}
                                                        >
                                                            
                                                            {i.selected == 1 ? <i className="material-icons"> check_box </i> : <i className="material-icons">check_box_outline_blank</i>} <label> {moduleList.topic[0][topicindex].title} </label>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    {cascade !== "" && showSaveButton() && (
                        <Button
                            label="Save"
                            onClick={() => {
                                saveUserConfig();
                            }}
                        />
                    )}
                    {userConfig.name === "hidden" && (
                        <div>
                            <div>
                                <div className="field">
                                    <div>
                                        <div className="col-12">
                                            <div className="grid">
                                                <div className="col-4">
                                                    <div className="grid">
                                                        <div className="col-12">
                                                            <h6
                                                                style={{
                                                                    marginTop: "1.5rem ",
                                                                }}
                                                            >
                                                                
                                                                Country
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "25vh",
                                                            overflow: "scroll",
                                                        }}
                                                    >
                                                        {userConfig.location.countrylist.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >
                                                                
                                                                {"Add Country"}
                                                            </text>
                                                        ) : (
                                                            userConfig.location.countrylist.map((item, cyindex) => {
                                                                return (
                                                                    <div
                                                                        onClick={() => {
                                                                            let loc = userConfig;
                                                                            loc.location.selectedcountry = item.country;
                                                                            loc.location.selectedcity = "";
                                                                            loc.location.selectedlocation = "";
                                                                            setUserConfig(loc);
                                                                            forceUpdate();
                                                                        }}
                                                                        style={{
                                                                            color: item.country === userConfig.location.selectedcountry && "white",
                                                                            background: item.country === userConfig.location.selectedcountry && "grey",
                                                                            borderBottom: "0.5px ridge grey",
                                                                        }}
                                                                    >
                                                                        <text
                                                                            style={{
                                                                                padding: 5,
                                                                                margin: "3px 0",
                                                                                lineHeight: "28px",
                                                                            }}
                                                                        >
                                                                            
                                                                            {item.country}
                                                                        </text>
                                                                        {/* <EditText
                                                                                                                            onSave={(e) => { if (e.value.trim().length !== 0) { let loc = userConfig; loc.location.selectedcountry = e.value; loc.location.countrylist[cyindex].country = e.value;  setUserConfig(loc) } }}
                                                                                                                            name='textbox3'
                                                                                                   
                                                                                                                            // value={item.country}
                                                                                                                            defaultValue={item.country}
                                                                                                                            editButtonProps={{ style: { marginLeft: '5px', width: 16 } }}
                                                                                                                            
                                                                                                                        /> */}
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                                {userConfig.location.selectedcountry !== "" && (
                                                    <div className="col-4">
                                                        <div className="grid">
                                                            <div className="col-12">
                                                                <h6
                                                                    style={{
                                                                        marginTop: "1.5rem ",
                                                                    }}
                                                                >
                                                                    
                                                                    City
                                                                </h6>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="col-12"
                                                            style={{
                                                                border: "1px solid grey",
                                                                height: "25vh",
                                                                overflow: "scroll",
                                                            }}
                                                        >
                                                            {userConfig.location.countrylist[
                                                                userConfig.location.countrylist.findIndex((i) => {
                                                                    return i.country === userConfig.location.selectedcountry;
                                                                })
                                                            ].length === 0 ? (
                                                                <text
                                                                    style={{
                                                                        padding: 5,
                                                                    }}
                                                                >
                                                                    
                                                                    {"Add City"}
                                                                </text>
                                                            ) : (
                                                                userConfig.location.countrylist[
                                                                    userConfig.location.countrylist.findIndex((i) => {
                                                                        return i.country === userConfig.location.selectedcountry;
                                                                    })
                                                                ]["city"].map((item, ctindex) => {
                                                                    return (
                                                                        <div
                                                                            onClick={() => {
                                                                                let loc = userConfig;
                                                                                loc.location.selectedcity = item.city;
                                                                                loc.location.selectedlocation = "";
                                                                                setUserConfig(loc);
                                                                                forceUpdate();
                                                                            }}
                                                                            style={{
                                                                                color: item.city === userConfig.location.selectedcity && "white",
                                                                                background: item.city === userConfig.location.selectedcity && "grey",
                                                                                borderBottom: "0.5px ridge grey",
                                                                            }}
                                                                        >
                                                                            <text
                                                                                style={{
                                                                                    padding: 5,
                                                                                    margin: "3px 0",
                                                                                    lineHeight: "28px",
                                                                                }}
                                                                            >
                                                                                
                                                                                {item.city}
                                                                            </text>
                                                                        </div>
                                                                    );
                                                                })
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {userConfig.location.selectedcity !== "" && userConfig.location.selectedcountry !== "" && userConfig.location.countrylist[getCountryIndex()].city.length !== 0 && (
                                                    <div className="col-4">
                                                        <div className="grid">
                                                            <div className="col-12">
                                                                <h6
                                                                    style={{
                                                                        marginTop: "1.5rem ",
                                                                    }}
                                                                >
                                                                    
                                                                    Location
                                                                </h6>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="col-12"
                                                            style={{
                                                                border: "1px solid grey",
                                                                height: "25vh",
                                                                overflow: "scroll",
                                                            }}
                                                        >
                                                            {userConfig.location.countrylist[
                                                                userConfig.location.countrylist.findIndex((i) => {
                                                                    return i.country === userConfig.location.selectedcountry;
                                                                })
                                                            ].city[getCityIndex()] === undefined ||
                                                            userConfig.location.countrylist[
                                                                userConfig.location.countrylist.findIndex((i) => {
                                                                    return i.country === userConfig.location.selectedcountry;
                                                                })
                                                            ].city[getCityIndex()].length === 0 ? (
                                                                <text
                                                                    style={{
                                                                        padding: 5,
                                                                    }}
                                                                >
                                                                    
                                                                    {"Add Site/Location"}
                                                                </text>
                                                            ) : (
                                                                userConfig.location.countrylist[
                                                                    userConfig.location.countrylist.findIndex((i) => {
                                                                        return i.country === userConfig.location.selectedcountry;
                                                                    })
                                                                ].city[getCityIndex()]["location"].map((item, locindex) => {
                                                                    return (
                                                                        <div
                                                                            onClick={() => {
                                                                                let loc = userConfig;
                                                                                loc.location.selectedlocation = item.location;
                                                                                setUserConfig(loc);
                                                                                forceUpdate();
                                                                            }}
                                                                            style={{
                                                                                color: item.location === userConfig.location.selectedlocation && "white",
                                                                                background: item.location === userConfig.location.selectedlocation && "grey",
                                                                                borderBottom: "0.5px ridge grey",
                                                                            }}
                                                                        >
                                                                            <text
                                                                                style={{
                                                                                    padding: 5,
                                                                                    margin: "3px 0",
                                                                                    lineHeight: "28px",
                                                                                }}
                                                                            >
                                                                                
                                                                                {item.location}
                                                                            </text>
                                                                        </div>
                                                                    );
                                                                })
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ClientApproverConfig, comparisonFn);
