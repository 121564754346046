import React, { useEffect, useState } from "react";

import moment from "moment";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import Axios from "axios";
import { API } from "../../../../components/constants/api_url";
import APIServices from "../../../../service/APIService";
import '../../../reports/toc_style.css';
import { useHeadsObserver } from "../../hooks";
const { DateTime } = require('luxon');

const BRSR_Core_24 = (props) => {
    const [headings, setHeadings] = useState([]);
    const { activeId } = useHeadsObserver();
    const [tableData, setTableData] = useState([]);
    const [workbook, setWorkbook] = useState(null);
    const [firstSheet, setFirstSheet] = useState(null);
    const [year, setYear] = useState(props.year);
    const [rfData, setRFData] = useState({});
    const selector = useSelector((state) => state.user.userdetail);
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    const locationList = useSelector(state => state.sitelist.locationList)
    const siteList = useSelector((state) => state.sitelist.siteList)
    const clientlogo = useSelector((state) => state.user.companylogo)
    const rflibrary = useSelector((state) => state.library.rf)
    const [dcflist, setDcfList] = useState([])
    const [response, setResponse] = useState([])
    const [report, setReport] = useState({})
    const [dpreport, setDpReport] = useState([])
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const forceUpdate = useForceUpdate();

    // RF Dropdowns
    const QLBP1E2D1Options = [{ name: 'Monetary', id: 1 }, { name: 'Non- Monetary', id: 2 }]
    const QLBP1E2D2Options = [{ name: 'Penalty or Fine', id: 1 }, { name: 'Settlement', id: 2 }, { name: 'Compounding fee', id: 3 }]
    const QLBP1E2D8Options = [{ name: 'Imprisonment', id: 1 }, { name: 'Punishment', id: 2 }]
    const QLBP1E2D4Options = [{ name: 'Regulatory', id: 1 }, { name: 'Enforcement agencies', id: 2 }, { name: 'Judicial institutions', id: 3 }, { name: 'NA', id: 4 }]
    // 111
    const QLBP4E2D3Options = [{ name: 'Email', id: 1 }, { name: 'SMS', id: 2 }, { name: 'Newspaper', id: 3 }, { name: 'Pamphlets', id: 4 }, { name: 'Advertisement', id: 5 }, { name: 'Community Meetings', id: 6 }, { name: 'Notice Board', id: 7 }, { name: 'Website', id: 8 }, { name: 'Other', id: 9 }]
    const QLBP4E2D4Options = [{ name: 'Annually', id: 1 }, { name: 'Half yearly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Others', id: 4 }]
    // 121
    const QLBP7E1D1AOptions = [{ name: 'Chambers', id: 1 }, { name: 'Associations', id: 2 }]
    // 113
    const QLBP7L1D4Options = [{ name: 'Annually', id: 1 }, { name: 'Half yearly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Others', id: 4 }]

    const handleNaNAndInfinity = (value) => {
        if (typeof value === 'number' && isFinite(value)) {
            return value.toFixed(0); // Return the original value if it's a valid number
        } else {
            return 0; // Return 0 for NaN or Infinity
        }
    }

    function transformArrayToObject(data, keyParam, valueParam) {
        const result = {};

        for (const item of data) {
            const key = item[keyParam];
            const value = item[valueParam];

            result[key] = typeof value === 'object' ? { ...value } : value;
        }

        return result;
    }
    const getManualDCF188 = (id, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let empty = [{
            "DPS0184": 'NA',
            "DPS0185": 'NA',
            "DPS0186": 'NA',
            "DPS0187": '-',
            "DPK0183": '-',
            "DPS0190": '-'
        }]
        let response_ = []
        let groupedDP = groupArrayObject(dpreport_.filter((i) => { return i.dcfId === id && checkYear(i.rp, yr) !== 0 }), 'submitId')
        if (Object.keys(groupedDP).length !== 0) {



            Object.keys(groupedDP).forEach((key) => {
                let formgroup = groupArrayObject(groupedDP[key], 'form_id')
                Object.keys(formgroup).forEach((key2) => {

                    let loc = transformArrayToObject(formgroup[key2], 'dpId', 'value')
                    response_.push(loc)
                })
            })

        }

        return response_.length === 0 ? empty : response_
    }
    const getManualDCF195 = (id, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let empty = [{
            DPBP2LQ3D1: 'NA',
            "a": 'NA',
            "b": 'NA'
        }]
        let response_ = []
        let groupedDP = groupArrayObject(dpreport_.filter((i) => { return i.dcfId === id && checkYear(i.rp, yr) !== 0 }), 'submitId')
        if (Object.keys(groupedDP).length !== 0) {



            Object.keys(groupedDP).forEach((key) => {
                let formgroup = groupArrayObject(groupedDP[key], 'form_id')
                Object.keys(formgroup).forEach((key2) => {

                    let loc = transformArrayToObject(formgroup[key2], 'dpId', 'value')
                    response_.push(loc)
                })
            })

        }
        let response2_ = []
        let groupedDP2 = groupArrayObject(dpreport_.filter((i) => { return i.dcfId === id && checkYear(i.rp, yr - 1) !== 0 }), 'submitId')
        if (Object.keys(groupedDP2).length !== 0) {



            Object.keys(groupedDP2).forEach((key) => {
                let formgroup = groupArrayObject(groupedDP2[key], 'form_id')
                Object.keys(formgroup).forEach((key2) => {

                    let loc = transformArrayToObject(formgroup[key2], 'dpId', 'value')
                    response2_.push(loc)
                })
            })

        }

        let result = mergeArrays(response_, response2_, "DPBP2LQ3D1", "DPBP2LQ3D2", "DPBP2LQ3D3")
        console.log(response_, response2_)
        return result.length === 0 ? empty : result
    }
    const getManualDCF196 = (id, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let empty = [{
            DPBP2LQ5D1: 'NA',
            "a": 'NA',
            "b": 'NA'
        }]
        let response_ = []
        let groupedDP = groupArrayObject(dpreport_.filter((i) => { return i.dcfId === id && checkYear(i.rp, yr) !== 0 }), 'submitId')
        if (Object.keys(groupedDP).length !== 0) {



            Object.keys(groupedDP).forEach((key) => {
                let formgroup = groupArrayObject(groupedDP[key], 'form_id')
                Object.keys(formgroup).forEach((key2) => {

                    let loc = transformArrayToObject(formgroup[key2], 'dpId', 'value')
                    response_.push(loc)
                })
            })

        }
        let response2_ = []


        let result = mergeArrays(response_, response2_, "DPBP2LQ5D1", "DPBP2LQ5D2", "DPBP2LQ5D3")

        return result.length === 0 ? empty : result
    }
    function mergeArrays(array1, array2, key1, key2, key3) {
        // Calculate "a" for array1
        const updatedArray1 = array1.map(item => ({
            [key1]: item[key1],
            a: item[key2] !== 0 ? ((item[key3] / item[key2]) * 100).toFixed(2) || 0 : 0
        }));

        // Calculate "b" for array2
        const updatedArray2 = array2.map(item => ({
            [key1]: item[key1],
            b: item[key2] !== 0 ? ((item[key3] / item[key2]) * 100).toFixed(2) || 0 : 0
        }));

        // Merge arrays based on key1
        const mergedArray = updatedArray1.map(item1 => {
            const matchingItem2 = updatedArray2.find(item2 => item2[key1] === item1[key1]) || { b: 0 };
            return { ...item1, ...matchingItem2 };
        });

        // Add missing items from array2 with "a" as 0
        const missingItems = updatedArray2.filter(item2 => !mergedArray.some(item => item[key1] === item2[key1]));
        const updatedMergedArray = [...mergedArray, ...missingItems.map(item2 => ({ [key1]: item2[key1], a: 0, ...item2 }))];

        return updatedMergedArray;
    }
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const getLastResponseRFID104 = () => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 104);
        let empty_1 = [{
            "QLBP1E2D1": 'NA',
            "type": 1,
            "QLBP1E2D2": 1,
            "option": 'NA',
            "QLBP1E2D3": "NA",
            "QLBP1E2D4": 'NA',
            "QLBP1E2D5": "NA",
            "QLBP1E2D6": 'NA',
            "QLBP1E2D7": "NA",
            "QLBP1E2D9": null,
            "QLBP1E3D1": "NA"
        }, {
            "QLBP1E2D1": 'NA',
            "type": 1,
            "QLBP1E2D2": 2,
            "option": 'NA',
            "QLBP1E2D3": "NA",
            "QLBP1E2D4": 'NA',
            "QLBP1E2D5": "NA",
            "QLBP1E2D6": 'NA',
            "QLBP1E2D7": "NA",
            "QLBP1E2D9": null,
            "QLBP1E3D1": "NA"
        }, {
            "QLBP1E2D1": 'NA',
            "type": 1,
            "QLBP1E2D2": 3,
            "option": 'NA',
            "QLBP1E2D3": "NA",
            "QLBP1E2D4": 'NA',
            "QLBP1E2D5": "NA",
            "QLBP1E2D6": 'NA',
            "QLBP1E2D7": "NA",
            "QLBP1E2D9": null,
            "QLBP1E3D1": "NA"
        }]
        let empty_2 = [
            {
                "QLBP1E2D1": 'NA',
                "option": 'NA',
                "type": 2,
                "QLBP1E2D8": 1,
                "QLBP1E2D3": "NA",
                "QLBP1E2D4": 'NA',
                "QLBP1E2D5": "NA",
                "QLBP1E2D6": 'NA',
                "QLBP1E2D7": "NA",
                "QLBP1E2D9": null,
                "QLBP1E3D1": "NA"
            },
            {
                "QLBP1E2D1": 'NA',
                "option": 'NA',
                "type": 2,
                "QLBP1E2D8": 2,
                "QLBP1E2D3": "NA",
                "QLBP1E2D4": 'NA',
                "QLBP1E2D5": "NA",
                "QLBP1E2D6": 'NA',
                "QLBP1E2D7": "NA",
                "QLBP1E2D9": null,
                "QLBP1E3D1": "NA"
            }
        ]
        if (result) {
            let response_ = result[0].response.sort((a, b) => { return a.QLBP1E2D1 - b.QLBP1E2D1 })
            response_.forEach((i) => {
                if (i.QLBP1E2D1 === 1) {
                    i.type = 1
                    i.option = QLBP1E2D2Options.find((k) => { return k.id === i.QLBP1E2D2 }).name
                } else {
                    i.type = 2
                    i.option = QLBP1E2D8Options.find((k) => { return k.id === i.QLBP1E2D8 }).name
                }
                i.QLBP1E2D4 = QLBP1E2D4Options.find((k) => { return k.id === i.QLBP1E2D4 }).name
                i.QLBP1E2D1 = QLBP1E2D1Options.find((k) => { return k.id === i.QLBP1E2D1 }).name

            })
            if (response_.findIndex((l) => { return l.type === 1 }) === -1 && response_.findIndex((l) => { return l.type === 2 }) === -1) {
                return [...empty_1, ...empty_2];
            } else if (response_.findIndex((l) => { return l.type === 1 }) === -1) {
                response_.splice(0, 0, ...empty_1)
            } else if (response_.findIndex((l) => { return l.type === 2 }) === -1) {
                response_.splice(response_.length, 0, ...empty_2)
            }

            return response_
        }
        return [...empty_1, ...empty_2];
    }
    const getLastResponseRF85 = () => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 85);
        let empty_1 = [{
            QL14DP1: 'NA',
            QL14DP2: 'NA',
            QL14DP3: 'NA',
        }
        ]

        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF111 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 111);
        let empty_1 = [
            {
                QLBP4E2D1: 'NA',
                QLBP4E2D2: 'NA',
                QLBP4E2D3: 'NA',
                QLBP4E2D4: 'NA',
                QLBP4E2D5: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            console.log(response_)
            response_.forEach((k) => {
                k.QLBP4E2D2 = k.QLBP4E2D2 === true ? 'Yes' : 'No'
                k.QLBP4E2D3 = QLBP4E2D3Options.find((l) => { return l.id === k.QLBP4E2D3 }).name
                k.QLBP4E2D4 = k.QLBP4E2D4 === 4 ? k.QLBP4E2D4B : QLBP4E2D4Options.find((l) => { return l.id === k.QLBP4E2D4 }).name
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF121 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 121);
        let empty_1 = [
            {
                QLBP7E1D1A: 'NA',
                QLBP7E1D1B: 'NA',
                QLBP7E1D2: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP7E1D1A = QLBP7E1D1AOptions.find((l) => { return l.id === k.QLBP7E1D1A }).name
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF113 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 113);
        let empty_1 = [
            {
                QLBP7L1D1: 'NA',
                QLBP7L1D2: 'NA',
                QLBP7L1D3: 'NA',
                QLBP7L1D4B: 'NA',
                QLBP7L1D4: 'NA',
                QLBP7L1D5: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP7L1D3 = k.QLBP7L1D3 === true ? 'Yes' : 'No'
                k.QLBP7L1D4 = k.QLBP7L1D4 === 4 ? k.QLBP7L1D4B : QLBP7L1D4Options.find((l) => { return l.id === k.QLBP7L1D4B }).name
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF102 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 102);
        let empty_1 = [
            {
                QL21DP1A: 'NA',
                QL21DP1B: 'NA',
                QL21DP2: 'NA',
                QL21DP3: 'NA',


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QL21DP3 = k.QL21DP3 === true ? 'Yes' : 'No'
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF112 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 112);
        let empty_1 = [
            {
                QLBP7E2D1: 'NA',
                QLBP7E2D2: 'NA',
                QLBP7E2D3: 'NA',


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF89 = () => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 89);
        let empty_1 = [{
            QL15DP1A: 'NA',
            QL15DP1B: 'NA',
            QL15DP2: 'NA',
            QL15DP3: 'NA',
        }
        ]

        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF110 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 110);
        const QLBG24D2Options = [{ name: 'Risk', id: 1 }, { name: 'Opportunity', id: 2 }]
        const QLBG24D5Options = [{ name: 'Positive', id: 1 }, { name: 'Negative', id: 2 }]

        let empty_1 = [
            {
                QLBG24D1: 'NA',
                QLBG24D2: 'NA',
                QLBG24D3: 'NA',
                QLBG24D4: 'NA',
                QLBG24D5: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {

                k.QLBG24D2 = QLBG24D2Options.find((l) => { return l.id === k.QLBG24D2 }).name

                k.QLBG24D5 = QLBG24D5Options.find((l) => { return l.id === k.QLBG24D5 }).name


            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF114 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 114);
        let empty_1 = [
            {
                QLBP8L1D1: 'NA',
                QLBP8L1D2: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF115 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 115);
        let empty_1 = [
            {
                QLBP8L2D1: 'NA',
                QLBP8L2D2: 'NA',
                QLBP8L2D3: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF116 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 116);
        let empty_1 = [
            {
                QLBP8L4D1: 'NA',
                QLBP8L4D2: 'NA',
                QLBP8L4D3: 'NA',
                QLBP8L4D4: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {

                k.QLBP8L4D2 = k.QLBP8L4D2 === true ? 'Yes' : 'No'
                k.QLBP8L4D3 = k.QLBP8L4D3 === true ? 'Yes' : 'No'


            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF117 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 117);
        let empty_1 = [
            {
                QLBP8L5D1: 'NA',
                QLBP8L5D2: 'NA',
                QLBP8L5D3: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF118 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 118);
        let empty_1 = [
            {
                QLBP8L6D1: 'NA',
                QLBP8L6D2: 'NA',
                QLBP8L6D3: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF133 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 133);
        let empty_1 = [
            {
                QLBP8E1D1A: 'NA',
                QLBP8E1D1B: 'NA',
                QLBP8E1D1C: 'NA',
                QLBP8E1D1D: 'NA',
                QLBP8E1D1E: 'NA',
                QLBP8E1D1F: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP8E1D1C = formatDate(k.QLBP8E1D1C, 'dd-MM-yyyy')
                k.QLBP8E1D1D = k.QLBP8E1D1D === true ? 'Yes' : 'No'
                k.QLBP8E1D1E = k.QLBP8E1D1E === true ? 'Yes' : 'No'


            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF139 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 139);
        let empty_1 = [
            {
                QLBP2LQ1D1: 'NA',
                QLBP2LQ1D2: 'NA',

                QLBP2LQ1D3B: 'NA',
                QLBP2LQ1D4: 'NA',
                QLBP2LQ1D5: 'NA',
                QLBP2LQ1D6A: 'NA',
                QLBP2LQ1D6B: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {

                k.QLBP2LQ1D1 = k.QLBP2LQ1D1 === true ? 'Yes' : 'No'
                if (k.QLBP2LQ1D1 === 'No') {
                    k.QLBP2LQ1D2 = 'NA'
                    k.QLBP2LQ1D3B = 'NA'
                    k.QLBP2LQ1D4 = 'NA'
                    k.QLBP2LQ1D5 = 'NA'
                    k.QLBP2LQ1D6A = 'NA'
                    k.QLBP2LQ1D6B = 'NA'
                } else {
                    k.QLBP2LQ1D5 = k.QLBP2LQ1D5 === true ? 'Yes' : 'No'
                    k.QLBP2LQ1D6A = k.QLBP2LQ1D6A === true ? 'Yes, ' + k.QLBP2LQ1D6B : 'No'
                }



            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF140 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 140);
        let empty_1 = [
            {

                QLBP2LQ2D1B: 'NA',
                QLBP2LQ2D2: 'NA',
                QLBP2LQ2D3: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF134 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 134);
        let empty_1 = [
            {
                QLBP9L1D1: 'NA',
                QLBP9L1D2: 'NA',
                QLBP9L1D3: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP9L1D2 = k.QLBP9L1D2 === true ? 'Yes' + k.QLBP9L1D3 : 'No'
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF149 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 149);
        let empty_1 = [
            {
                QLBP6EQ11D1: 'NA',
                QLBP6EQ11D2: 'NA',
                QLBP6EQ11D3: 'NA',
                QLBP6EQ11D4: 'NA',
                QLBP6EQ11D5: 'NA',
                QLBP6EQ11D6: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP6EQ11D3 = formatDate(k.QLBP6EQ11D3, 'dd-MM-yyyy')
                k.QLBP6EQ11D4 = k.QLBP6EQ11D4 === true ? 'Yes' : 'No'
                k.QLBP6EQ11D5 = k.QLBP6EQ11D5 === true ? 'Yes' : 'No'


            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF148 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 148);
        let empty_1 = [
            {
                QLBP6EQ10D1: 'NA',
                QLBP6EQ10D2: 'NA',
                QLBP6EQ10D3A: 'NA'

            }

        ]
        if (result) {
            let response_ = result[0].response
            response_.forEach((k) => {
                k.QLBP6EQ10D3A = k.QLBP6EQ10D3A === true ? 'Yes' + k.QLBP6EQ10D3B : 'No'
            })
            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF150 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 150);
        let empty_1 = [
            {
                QLBP6EQ12D1: 'NA',
                QLBP6EQ12D2: 'NA',
                QLBP6EQ12D3: 'NA',
                QLBP6EQ12D4: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF151 = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 151);
        let empty_1 = [
            {
                QLBP6LQ6D1: 'NA',
                QLBP6LQ6D2: 'NA',
                QLBP6LQ6D3: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }
    const getLastResponseRF148B = () => {

        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, 148);
        let empty_1 = [
            {
                QLBP6EQ10D4: 'NA'


            }

        ]
        if (result) {
            let response_ = result[0].response

            return response_.length === 0 ? empty_1 : response_
        }
        return empty_1;
    }



    function haveSameSubarrayLengths(data) {
        if (data.length < 2) {
            // If there are fewer than 2 subarrays, they are considered to have the same length.
            return true;
        }

        const firstSubarrayLength = data[0].length;

        for (let i = 1; i < data.length; i++) {
            if (data[i].length !== firstSubarrayLength) {
                return false;
            }
        }

        return true;
    }
    function generatePdfMakeContentFromTable(table) {
        console.log(table)
        if (!table) {
            console.error(`Table  not found.`);
            return [];
        }

        let contentArray = [], maxCol = 0
        for (let i = 0; i < table.rows.length; i++) {
            if (maxCol <= table.rows[i].cells.length) {
                maxCol = table.rows[i].cells.length
            }
        }

        for (let i = 0; i < table.rows.length; i++) {
            const rowArray = [];

            for (let j = 0; j < table.rows[i].cells.length; j++) {
                const cell = table.rows[i].cells[j];
                const colSpan = cell.getAttribute('colspan');
                const rowSpan = cell.getAttribute('rowspan');
                const cellText = cell.textContent.trim();

                const cellObject = { text: cellText };
                cellObject.colSpan = parseInt(colSpan)
                cellObject.rowSpan = parseInt(rowSpan)
                cellObject.style = cell.getAttribute('class');
                rowArray.push(cellObject);
                if (parseInt(colSpan) > 1) {
                    console.log(rowArray)
                    for (let j = 0; j < parseInt(colSpan) - 1; j++) {
                        rowArray.push({});
                    }

                }

            }

            contentArray.push(rowArray);


        }
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                console.log(i)
                if (contentArray[index - 1]) {
                    contentArray[index - 1].forEach((k, ind) => {
                        if (k.rowSpan) {
                            if (k.rowSpan > 1) {
                                if (k.colSpan === 1) {
                                    i.splice(ind, 0, { text: '', colSpan: k.colSpan, rowSpan: k.rowSpan - 1 })
                                } else {
                                    let newind = ind
                                    for (let j = 0; j < parseInt(k.colSpan); j++) {
                                        i.splice(newind, 0, {})
                                        newind++
                                    }
                                }
                            }
                        } else {
                            for (let j = 0; j < (maxCol - Object.keys(i).length); j++) {
                                console.log(i)
                                // i.push({id:1});
                            }
                        }
                    })
                }
            }
        })
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                let len = Object.keys(i).length
                for (let j = 0; j < (maxCol - len); j++) {

                    i.push({});
                }

            }
        })
        return contentArray;
    }

    const getSum = (subset) => {
        let i = 0
        subset.forEach((item) => {
            i = i + item[1]
        })
        return i
    }
    const checkReportingPeriod = (rp, filter) => {

        let count = 0, rps = []
        filter.forEach((item) => {

            if (rp.includes(item)) {
                count = count + 1
                rps.push(item)
            }
        })
        return { result: count === rp.length, count: count, rps }
    }
    function getObjectsWithEmptyString(arr) {
        return arr
            .map((obj) => {
                const updatedObj = { ...obj };
                Object.keys(updatedObj).forEach((key) => {
                    if (typeof updatedObj[key] === "string" && updatedObj[key].trim() === "") {
                        delete updatedObj[key];
                    }
                });
                return updatedObj;
            })
            .filter((obj) => Object.values(obj).some((value) => value !== ""));
    }
    const getCellColSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.c - range.s.c + 1;
            }
        }
        return 1;
    };

    const getCellRowSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.r - range.s.r + 1;
            }
        }
        return 1;
    };
    function findValueByKey(object, key) {
        if (key in object) {
            return object[key];
        } else {
            return null; // or any other default value you want to return if the key is not found
        }
    }
    const getLastResponseByRFID = (key, uid) => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, key);
        console.log(key, result)
        if (result) {
            if (result[0].type === 0) {
                if (typeof uid === "string") {
                    let index = result[0].response.findIndex((k) => {
                        return k.name === uid;
                    });
                    if (index !== -1) {
                        if (result[0].response[index].type === 2) {

                            let value_2 = result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
                            return value_2 === null ? '' : result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')

                        } else if (result[0].response[index].type === 6 || result[0].response[index].type === 4) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return rflibrary[rflib].data1[field].values[result[0].response[index].value[0]].label
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value[0] })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    } else {
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        } else if (result[0].response[index].type === 3) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return null
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    } else {
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        }
                        else if (result[0].response[index].type === 9) {
                            return DateTime.fromISO(result[0].response[index].value, { zone: 'utc' }).toFormat('dd-MM-yyyy')
                        } else {
                            return result[0].response[index].value;
                        }

                    } else {
                        return 'NA';
                    }
                } else {
                    let str = "";
                    uid.forEach((id) => {
                        let index = result[0].response.findIndex((k) => {
                            return k.name === id;
                        });
                        if (index !== -1) {

                            str = str + " " + result[0].response[index].value;
                        }
                    });
                    if (str.trim().length !== 0) {
                        return str;
                    } else {
                        return 'NA';
                    }
                }
            } else {
                return result[0].data2.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
            }
        }
        return "";
    };


    useEffect(() => {
        const elements = Array.from(document.querySelectorAll("sectionheader,sectionheader1,sectionheader2")).map((elem) => ({
            id: elem.id,
            text: elem.childNodes[0].textContent.trim(),
            level: Number(elem.nodeName.charAt(13)),
        }));

        let as = [],
            indx = 0;
        elements.forEach((item, ind) => {
            if (item.level === 0) {
                as[indx] = item;
                indx = indx + 1;
            } else if (elements[ind - 1].level === 0) {
                as[indx] = { item: [item], level: 1 };
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            } else {
                as[indx].item.push(item);
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            }
        });

        setHeadings(as);
    }, []);
    const groupArrayObject = (array, obj) => {
        return array.reduce(
            (group, arr) => {
                let key = arr[obj];

                group[key] = group[key] ?? [];

                group[key].push(arr);

                return group;
            },

            {}
        );
    };
    useEffect(() => {
        let gtaString = {
            "include": ["newTargetsTwos", "newIndicatorTwos", "newInitiatives"]
        }
        let dcf_list = [], dcf_submitted = [], locloc = []
        let category_string = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }

        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(API.DCF_Submit_UP(selector.id))
        const promise3 = APIServices.get(API.DP_report_UP(selector.id))
        const promise4 = APIServices.get(API.RF_Submit_UP(selector.id))
        Promise.all([promise1, promise2, promise3, promise4]).then(function (values) {
            dcf_list = values[0].data; setDcfList(values[0].data)
            dcf_submitted = values[1].data;
            setDpReport(values[2].data)
            let val = [], filterarr = groupArrayObject(values[2].data, 'submitId')
            Object.keys(filterarr).forEach((item) => {

                val.push({ id: filterarr[item][0].submitId, rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], 'form_id')) })

            })
            console.log(val, 'val')
            setResponse(val)
            let rfid_group = groupArrayObject(values[3].data, "rfid");
            Object.keys(rfid_group).forEach((key) => {
                rfid_group[key].sort((a, b) => {
                    return b.id - a.id;
                });
            });

            setRFData(rfid_group);

            console.log(rfid_group)
            forceUpdate()

        })

    }, []);
    useEffect(() => {
        if (response.length !== 0) {

            let report_ = {}
            report_[`${year}`] = renderData({ location: { a: { name: 'All', id: 0 }, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: { name: 'All', id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(3, year), to: getDateObjectByMonth_Year(2, year + 1) }, 1)
            report_[`${year - 1}`] = renderData({ location: { a: { name: 'All', id: 0 }, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: { name: 'All', id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(3, year - 1), to: getDateObjectByMonth_Year(2, year) }, 1)
            setReport(report_)
            console.log(report_)
            forceUpdate()
        }
    }, [response])
    useEffect(() => {
        if (props.year !== year) {
            setYear(props.year)
        }

    }, [[props]])
    const getScopeData = (year, area) => {
        if (report[year]) {
            return report[year].filter((k) => { return k.scope === area }).map((j) => { return j.ghg }).reduce((a, b) => { return a + b }, 0)

        }
        return 0
    }
    const getScopeDataByArray = (year, area) => {
        if (report[year]) {
            return report[year].filter((k) => { return area.includes(k.scope) }).map((j) => { return j.ghg }).reduce((a, b) => { return a + b }, 0)

        }
        return 0
    }
    const getScopeDataByObject = (year, dcf, area, obj) => {
        if (report[year]) {
            console.log(report[year].filter((k) => { return k.scope === area && k.dcfId === dcf }).map((j) => { return j[obj] }).reduce((a, b) => { return a + b }, 0))
            return report[year].filter((k) => { return k.scope === area && k.dcfId === dcf }).map((j) => { return j[obj] }).reduce((a, b) => { return a + b }, 0).toFixed(3)

        }
        return 0
    }
    const checkYear = (rp, yr) => {
        let betweenMonths = []
        let endDate = moment.utc(getDateObjectByMonth_Year(3, yr + 1)).local()
        let startDate = moment.utc(getDateObjectByMonth_Year(4, yr)).local()
        while (startDate.startOf('month') <= endDate.startOf('month')) {

            betweenMonths.push(startDate.format('MM-YYYY'));
            startDate.add(1, 'month');

        }

        return betweenMonths.filter((i) => { return rp.includes(i) }).length === rp.length ? 1 : betweenMonths.filter((i) => { return rp.includes(i) }).length

    }
    const getDataByDP = (dpid, yr) => {
        console.log(yr)
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = 0

        dpreport_.filter((i) => { return i.dpId === dpid }).forEach((k) => {


            if (checkYear(k.rp, yr) !== 0) {
                console.log(dpid, k.value)
                // let val = k.value.trim().length === 0 ? 0 : k.value
                d = d + k.value
            } else {

            }

        })

        return isNaN(d) ? 0 : d.toFixed(0)
    }
    const getDataByDPStr = (dpid, yr) => {
        console.log(yr)
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = ''

        dpreport_.filter((i) => { return i.dpId === dpid }).forEach((k) => {


            if (checkYear(k.rp, yr) !== 0) {
                console.log(dpid, k.value)
                // let val = k.value.trim().length === 0 ? 0 : k.value
                d = d + k.value
            } else {

            }

        })

        return d
    }
    const getDataByDPArray = (dparr, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = 0
        dparr.forEach((dpid) => {
            dpreport_.filter((i) => { return i.dpId === dpid }).forEach((k) => {


                if (checkYear(k.rp, yr) !== 0) {
                    d = d + k.value
                }

            })
        })

        return isNaN(d) ? 0 : d.toFixed(0)
    }


    function getDateObjectByMonth_Year(month, year) {

        if (isNaN(month) || isNaN(year)) {
            throw new Error('Invalid month or year');
        }

        const normalizedMonth = Math.max(1, Math.min(12, month));

        const date = DateTime.fromObject({ year, month: normalizedMonth, day: 1 });

        return date.toJSDate();
    }
    const getRP_ = (rp) => {
        if (rp.length === 1) {

            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2)
        } else {
            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2) + "to" + months_[parseInt(rp[rp.length - 1].split('-')[0]) - 1] + "-" + rp[rp.length - 1].split('-')[1].slice(-2)
        }
    }
    const getClassName = (level) => {
        switch (level) {
            case 1:
                return "head1";
            case 2:
                return "head2";
            case 3:
                return "head3";
            default:
                return null;
        }
    };

    const checkSite = (id, filter) => {
        let idlist = []

        siteList.forEach((country) => {

            if (filter.a.id === 0 || filter.a.id === country.id) {

                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id)
                            }
                        })
                    }
                })
            }
        })

        return idlist.includes(id)
    }

    const renderData = (search) => {
        let betweenMonths = [], betweenYears = []
        let monthly_fg = []


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')


        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))

            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total, co2, ch4, n2o })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                        sc_data[fuel_cat_ind].co2 += co2
                                        sc_data[fuel_cat_ind].ch4 += ch4
                                        sc_data[fuel_cat_ind].n2o += n2o
                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total, co2, ch4, n2o })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                        sc_data[fuel_cat_ind].co2 += co2
                                        sc_data[fuel_cat_ind].ch4 += ch4
                                        sc_data[fuel_cat_ind].n2o += n2o

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total, co2, ch4, n2o })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total
                                    sc_data[fuel_cat_ind].co2 += co2
                                    sc_data[fuel_cat_ind].ch4 += ch4
                                    sc_data[fuel_cat_ind].n2o += n2o

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat, co2: item.co2, n2o: item.n2o, ch4: item.ch4 })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                    monthly_fg[updateind].c2o += (item.c2o / addedMonth.count)
                                    monthly_fg[updateind].ch4 += (item.ch4 / addedMonth.count)
                                    monthly_fg[updateind].n20 += (item.n20 / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = (ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg'] / 1000) * km
                                co2 = (ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2_in_kg'] / 1000) * km
                                ch4 = (ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['ch4_in_kg'] / 1000) * km
                                n2o = (ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['n2o_in_kg'] / 1000) * km
                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total, co2, ch4, n2o })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total
                                    mc_data[fuel_cat_ind].co2 += co2
                                    mc_data[fuel_cat_ind].n2o += n2o
                                    mc_data[fuel_cat_ind].ch4 += ch4

                                }
                            } else {
                                total = (ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg'] / 1000) * fuel_filled
                                co2 = (ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2_in_kg'] / 1000) * fuel_filled
                                ch4 = (ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['ch4_in_kg'] / 1000) * fuel_filled
                                n2o = (ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['n2o_in_kg'] / 1000) * fuel_filled

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total, co2, ch4, n2o })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total
                                    mc_data[fuel_cat_ind].co2 += co2
                                    mc_data[fuel_cat_ind].n2o += n2o
                                    mc_data[fuel_cat_ind].ch4 += ch4

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                        monthly_fg[updateind2].c2o += (item.c2o / addedMonth.count)
                                        monthly_fg[updateind2].ch4 += (item.ch4 / addedMonth.count)
                                        monthly_fg[updateind2].n20 += (item.n20 / addedMonth.count)
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode, co2: item.co2, n2o: item.n2o, ch4: item.ch4 })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                    monthly_fg[updateind].c2o += (item.c2o / addedMonth.count)
                                    monthly_fg[updateind].ch4 += (item.ch4 / addedMonth.count)
                                    monthly_fg[updateind].n20 += (item.n20 / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })





            let scope12_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1 || i.scope === 2) }), 'month')
            let scope3_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 3) }), 'month')
            let scope1_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1) }), 'month')
            let scope2_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 2) }), 'month')
            let pie = []
            monthly_fg.filter((i) => { return (i.scope === 1) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 2) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 3) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })

            let index1 = pie.findIndex((j) => { return j.name === 'Scope 1' })
            let index2 = pie.findIndex((j) => { return j.name === 'Scope 2' })
            let index3 = pie.findIndex((j) => { return j.name === 'Scope 3' })

            if (index1 === -1) {
                pie.push({ name: 'Scope 1', y: 0 })
            }
            if (index2 === -1) {
                pie.push({ name: 'Scope 2', y: 0 })
            }
            if (index3 === -1) {
                pie.push({ name: 'Scope 3', y: 0 })
            }


            if (scope1_array.length !== 0) {
                let scope1_tier2 = [], final = []
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {

                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })

                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })


                    final.push(dataset)
                })

            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [], final = []
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })


            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [], final = []
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = false; return i })

                    final.push(dataset)
                })

            }



        }
        return monthly_fg


    }
    return (
        <div className="col-12" style={{ display: "flex", height: 'calc(100vh - 190px)', flexDirection: "row", justifyContent: "space-between" }}>
            <div className="col-3 p-card" style={{ margin: 5, overflow: "scroll" }}>
                <nav>
                    {headings.map((heading, ind) => {
                        let indexes = [];
                        return (
                            <>
                                {heading.level === 0 ? (
                                    <label key={heading.id} style={{ display: "flex", margin: 5, fontWeight: activeId === heading.id ? "bold" : "normal", textDecoration: heading.text.includes("SECTION") && "underline" }} className={getClassName(heading.level)}>
                                        <a
                                            href={`#${heading.id}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                document.querySelector(`#${heading.id}`).scrollIntoView({
                                                    behavior: "smooth",
                                                    block: "start",
                                                    inline: "nearest",
                                                });
                                            }}
                                            style={{
                                                fontWeight: activeId === heading.id ? "bold" : "normal",
                                            }}
                                        >
                                            {heading.text}
                                        </a>
                                    </label>
                                ) : (
                                    <ul>
                                        {heading.item.map((item, ind2) => {
                                            return (
                                                <li key={item.id} className={getClassName(item.level)}>
                                                    <a
                                                        href={`#${item.id}`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            document.querySelector(`#${item.id}`).scrollIntoView({
                                                                behavior: "smooth",
                                                            });
                                                        }}
                                                        style={{
                                                            fontWeight: activeId === item.id ? "bold" : "normal",
                                                        }}
                                                    >
                                                        {item.text}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </>
                        );
                    })}
                </nav>
            </div>
            <div className="col-9 p-card" style={{ margin: 5, overflow: "scroll", color: "white" }}>

                <div style={{ display: "flex", flexDirection: "column" }}>

                    <div id='main' style={{ flexDirection: 'column', display: 'flex' }}>
                        <sectionheader id={"h"} className="secHead">
                            Change in GHG footprint
                        </sectionheader>

                        <sectionheader0 >
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Attribute
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                value
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="24" >
                                                Change in GHG footprint
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Total Scope 1 emissions
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getScopeData(year, 1))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                i) CO2
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getScopeDataByObject(year, 11, 1, 'co2')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                ii) CH4
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getScopeDataByObject(year, 11, 1, 'ch4')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                iii) N2O
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getScopeDataByObject(year, 11, 1, 'n2o')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                iv) HFCs
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                v) PFCS
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                vi) SF6
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                vii) NF3
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                a)Total use of soild fuels (in kg)/  liquid fuels (in L)
                                            </td>
                                            <td colspan="1" rowspan="1" >


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                   
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Total Scope 2 emissions

                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e



                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getScopeData(year, 2))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                i) CO2
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                              
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                ii) CH4
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                             
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                iii) N2O
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                             
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                iv) HFCs
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                v) PFCS
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                vi) SF6
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                vii) NF3
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tCO2e


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                a)Total use of soild fuels (in kg)/  liquid fuels (in L)
                                            </td>
                                            <td colspan="1" rowspan="1" >


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                b)Total Grid energy consumed/ Total renewable energy consumed from other sources like solar, turbine

                                            </td>
                                            <td colspan="1" rowspan="1" >


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                GHG Emission Intensity (Scope 1 +2)

                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                tco2e

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getScopeDataByArray(year, [1, 2]))}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                a) Total Scope 1 and Scope 2 emissions (MT) / Total Revenue from Operations adjusted for PPP

                                            </td>
                                            <td colspan="1" rowspan="1" >


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                b) Total Scope 1 and Scope 2 emissions (MT) / Total Output of Product or Services

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getScopeDataByArray(year, [1, 2]) / getDataByDP('DPK0203', year))}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                c) Total Scope 1 and Scope 2 emissions (MT) / Revenue from Operations (Rs. Cr) / Unit (Adjusted for Price Volume Parity)

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getScopeDataByArray(year, [1, 2]) / getDataByDP('DPK0264', year))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader0>
                        <sectionheader id={"a"} className="secHead">
                            Change in water footprint
                        </sectionheader>

                        <sectionheader0 >
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Attribute
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Value
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="14" >
                                                Change in GHG footprint
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Water consumption from
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                ML
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                (i) Surface water
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                ML
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0010', 'DPA0011'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                (ii) Groundwater
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                ML
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0013', 'DPA0014'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                (iii) Third party water
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                ML
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0022'], year))}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                (iv) Seawater / desalinated water
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                ML
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0016'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                (v) Others

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                ML
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0026'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Total volume of water consumption
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                ML
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['SDPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0016', 'DPA0026'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" >
                                                Water consumption intensity
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                ML/ Rupee adjusted for PPP
                                            </td>

                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0016', 'DPA0026'], year))}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                ML/ Product or Service

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0016', 'DPA0026'], year))}
                                                /
                                                {handleNaNAndInfinity(getDataByDP('DPK0203', year))}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Water Consumption / Revenue / Unit of Product or Services
                                            </td>

                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0010', 'DPA0011', 'DPA0013', 'DPA0014', 'DPA0022', 'DPA0016', 'DPA0026'], year))}
                                                /
                                                {handleNaNAndInfinity(getDataByDP('DPK0264', year))}
                                                /
                                                {handleNaNAndInfinity(getDataByDP('DPK0203', year))}

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="3" >
                                                Water Discharge by levels of Treatment
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                ML
                                            </td>

                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPSN0031', year))}


                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                ML / Rupee adjusted for PPP

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPSN0032', year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                ML / Product or Service
                                            </td>

                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPSN0033', year))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader0>
                        <sectionheader id={"c"} className="secHead">
                            Investing in reducing its environmental footprint
                        </sectionheader>

                        <sectionheader0 >
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Attribute
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Value
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="5" >
                                                Investing in reducing its environmental footprint
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                R&D and capital expenditure (capex) investments in specific technologies to improve the environmental and social impacts of product and processes
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                (Represented in %)
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPAN029', 'DPAN030', 'DPAN031', 'DPAN032', 'DPAN033', 'DPAN034'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                R&D for Env & Social Impact
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPAN031'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Total R&D Capex for Env & Social Impact
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPAN032'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Capex Investment
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPAN030'], year))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader0>
                        <sectionheader id={"b"} className="secHead">
                            Embracing circularity - details related to waste management by the entity
                        </sectionheader>

                        <sectionheader0 >
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Attribute
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Value
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="15" >
                                                Embracing circularity - details related to waste management by the entity
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Plastic waste (A)
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0059', year))}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                E-waste (B)
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0060', year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Bio-medical waste (C)
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0061', year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Construction and demolition waste (D)
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0062', year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Battery waste (E)
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0063', year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Radioactive waste (F)
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPA0064', year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Other Hazardous waste. Please specify, if any. (G)
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPAN0043', year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Total waste generated (G + H)
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['SDPA0060', 'DPA0061', 'DPA0062', 'DPA0063', 'DPA0064', 'DPAN0043', 'DPA0059'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" >
                                                Waste intensity
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT / Rupee adjusted for PPP
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0060', 'DPA0061', 'DPA0062', 'DPA0063', 'DPA0064', 'DPAN0043'], year))}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                MT / Unit of Product or Service
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0060', 'DPA0061', 'DPA0062', 'DPA0063', 'DPA0064', 'DPAN0043'], year) / getDataByDP('DPK0203', year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" >
                                                Each category of waste generated, total waste recovered through recycling, re-using or other recovery operations
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT Absolute quantity
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0068', 'DPA0069', 'DPA0070', 'DPA0071', 'DPA0072', 'DPA0073', 'DPA0074', 'DPA0075', 'DPA0076', 'DPA0077', 'DPA0078', 'DPA0079', 'DPA0080', 'DPA0081', 'DPA0082', 'DPA0083', 'DPA0084'], year))}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                Intensity Kg of Waste Recycled Recovered /Total Waste
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0068', 'DPA0069', 'DPA0070', 'DPA0071', 'DPA0072', 'DPA0073', 'DPA0074', 'DPA0075', 'DPA0076', 'DPA0077', 'DPA0078', 'DPA0079', 'DPA0080', 'DPA0081', 'DPA0082', 'DPA0083', 'DPA0084', 'DPAN048', 'DPAN037', 'DPAN038', 'DPAN039'], year) / getDataByDPArray(['DPA0060', 'DPA0061', 'DPA0062', 'DPA0063', 'DPA0064', 'DPAN0043'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="2" >
                                                For each category of waste generated, total waste disposed by nature of disposal method
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                MT
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0087', 'DPA0088', 'DPA0089', 'DPA0089', 'DPA0089', 'DPA0090', 'DPA0091', 'DPA0092', 'DPA0093', 'DPA0094', 'DPA0095', 'DPA0096', 'DPA0097', 'DPA0098', 'DPA0099', 'DPA0100', 'DPA0101', 'DPA0102', 'DPA0103', 'DPA0104', 'DPA0105', 'DPA0106', 'DPA0107', 'DPA0108', 'DPA0109', 'DPA0110'], year))}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                Intensity Kg of Waste Recycled disposed /Total Waste
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPA0068', 'DPA0069', 'DPA0070', 'DPA0071', 'DPA0072', 'DPA0073', 'DPA0074', 'DPA0075', 'DPA0076', 'DPA0077', 'DPA0078', 'DPA0079', 'DPA0080', 'DPA0081', 'DPA0082', 'DPA0083', 'DPA0084', 'DPAN048', 'DPAN037', 'DPAN038', 'DPAN039'], year))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader0>

                        <sectionheader id={"d"} className="secHead">
                            Enhancing Employee Wellbeing and Safety
                        </sectionheader>

                        <sectionheader0 >
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Attribute
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Value
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="13" >
                                                Enhancing Employee Wellbeing and Safety
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Spending on measures towards well-being of employees and workers – cost incurred as a % of total revenue of the company
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                In % terms
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPAN029', 'DPAN030', 'DPAN031', 'DPAN032', 'DPAN033', 'DPAN034'], year))}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="1" rowspan="1" >

                                                Details of safety related incidents for employees and workers
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                        
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >

                                            Accident insurance
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPS0096', 'DPS0097', 'DPS0098', 'DPS0099', 'DPS0100', 'DPS0101', 'DPS0102', 'DPS0103', 'DPS0104', 'DPS0105', 'DPS0106', 'DPS0107'], year))} 
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >

                                            Health insurance
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                            {handleNaNAndInfinity(getDataByDPArray(['DPS0084', 'DPS0085', 'DPS0086', 'DPS0087', 'DPS0088', 'DPS0089', 'DPS0090', 'DPS0091', 'DPS0092', 'DPS0093', 'DPS0094', 'DPS0095'], year))}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >

                                            Retirement provisions
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                            {handleNaNAndInfinity(getDataByDPArray(['DPR0073', 'DPR0074', 'DPR0075', 'DPR0076', 'DPR0077', 'DPR0078', 'DPR0079', 'DPR0080', 'DPR0081', 'DPR0082', 'DPR0083', 'DPR0084', 'DPSR0129', 'DPSF0129'], year))}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >

                                            Day care facilities 
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                            {handleNaNAndInfinity(getDataByDPArray(['DPS0116', 'DPS0117', 'DPS0118', 'DPS0119', 'DPS0120', 'DPS0121', 'DPS0122', 'DPS0123', 'DPS0124', 'DPS0125', 'DPS0126', 'DPS0127'], year))}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >

                                            Other benefits 
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                            {handleNaNAndInfinity(getDataByDPArray(['DPKN059', 'DPKN060'], year))}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >

                                            Life insurance 
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                            {handleNaNAndInfinity(getDataByDPArray(['DPS0243', 'DPKN054'], year))}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >

                                            Healthcare 
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                            {handleNaNAndInfinity(getDataByDPArray(['DPKN077', 'DPKN078'], year))}
                                            </td>

                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                Number of Permanent Disabilities
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPSN0034', year))}
                                            </td>
                                        </tr>

                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                1) {handleNaNAndInfinity(getDataByDPArray(['DPS0036', 'DPS0043'], year))}
                                                <br /> 2) {handleNaNAndInfinity(getDataByDPArray(['DPS0035', 'DPS0042'], year))}
                                                <br />   3) {handleNaNAndInfinity((getDataByDPArray(['DPS0036', 'DPS0043'], year) * 1000000) / getDataByDPArray(['DPS0035', 'DPS0042'], year))}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                No. of fatalities
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPS0032', year))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader0>
                        <sectionheader id={"e"} className="secHead">
                            Enabling Gender Diversity in Business
                        </sectionheader>

                        <sectionheader0 >
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Attribute
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Value
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="6" >
                                                Enabling Gender Diversity in Business
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Gross wages paid to females as % of wages paid
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                In % terms
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPS0267', year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Complaints on POSH
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Total Complaints on Sexual Harassment (POSH) reported
                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPSN0035', year))}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                Complaints on POSH as a % of female employees / workers Complaints on POSH upheld

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                Complaints on POSH upheld

                                            </td>
                                            <td colspan="1" rowspan="1" >

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDP('DPSN0036', year))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader0>
                        <sectionheader id={"f"} className="secHead">
                            Enabling Inclusive Development
                        </sectionheader>

                        <sectionheader0 >
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Attribute
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Value
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3" >
                                                Enabling Inclusive Development
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Input material sourced from following sources as % of total purchases,– Directly sourced from MSMEs/ small producers, and Sourced directly from within the district and neighbouring and / or aspirational districts
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                n % terms – As % of total purchases by value
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity(getDataByDPArray(['DPK0097', 'DPK0098'], year))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Job creation in smaller towns – Wages paid to people employed in smaller towns (permanent or nonpermanent /on contract) as % of total wage cost

                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                In % terms – As % of total wage cost

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader0>
                        <sectionheader id={"g"} className="secHead">
                            Fairness in Engaging with Customers and Suppliers
                        </sectionheader>

                        <sectionheader0 >
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Attribute
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Value
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="3">
                                                Fairness in Engaging with Customers and Suppliers
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                % of negative media sentiment
                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                In % terms
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="1" >
                                                Number of days of accounts payable

                                            </td>
                                            <td colspan="1" rowspan="1" >
                                                (Accounts payable *365) / Cost of goods/services procured

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </sectionheader0>

                        <sectionheader id={"i"} className="secHead">
                            Open-ness of business
                        </sectionheader>

                        <sectionheader0 >
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>

                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Attribute
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Parameter
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Unit
                                            </td>
                                            <td colspan="1" rowspan="1" className="tdHead">
                                                Value
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="15" >
                                                Open-ness of business
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="1" rowspan="14" >
                                                Concentration of purchases & sales done with trading houses, dealers, and related parties Loans and advances & investments with related parties
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                Purchases from trading houses as % of total purchases

                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                a)      Purchases from trading houses as % of total purchases
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity((getDataByDP('DPSN0039') / getDataByDP('DPSN0040')) * 100)}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                b)     Number of trading houses where purchases are made from
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getDataByDP('DPSN0038')}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                c)      Purchases from top 10 trading houses as % of total purchases from trading houses


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity((getDataByDP('DPSN0041') / getDataByDP('DPSN0042')) * 100)}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                Concentration of sales done with trading houses, dealers


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                a)      Sales to dealers/distributors as % of total sales
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity((getDataByDP('DPSN0043') / getDataByDP('DPSN0044')) * 100)}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                b)     Number of dealers / distributors to whom sales are made


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {getDataByDP('DPSN0045')}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                c)      Sales to top 10 dealers / distributors as % of total sales to dealers / distributors
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">
                                                {handleNaNAndInfinity((getDataByDP('DPSN0046') / getDataByDP('DPSN0047')) * 100)}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                Share of RPTs (as respective %age) in –


                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                a)      Purchase
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                b)     Sales
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                c)      Loans & advances
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="1" rowspan="1" >
                                                d)     Investments
                                            </td>
                                            <td colspan="1" rowspan="1" className="text-center">

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </sectionheader0>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BRSR_Core_24