import React, { useState, useEffect, useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import $, { isNumeric } from "jquery";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "../service/ProductService";
import { Chart } from "chart.js/dist/chart";
import { Dropdown } from "primereact/dropdown";
import { registerables } from "chart.js";

import ChartDL from "../assets/js/chartjsplugin";

import axios from "axios";
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Dialog } from "primereact/dialog";
import { Popover } from "react-tiny-popover";
import reference from "../../src/assets/dummy/reference.json";

import { Rating } from "primereact/rating";
Chart.register(...registerables);

const lineData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
        },
        {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
        },
    ],
};
const colors = ["#8DDDD0", "#FFA056", "#CA472F", "#9DD866", "#6F4E7C", "#F6C85F", "#0B84A5", "#4198D7", "#E55759", "#46D39A", "#75659A", "#3CC677"];
const colorsBR = ["#8DDDD020", "#FFA05620", "#CA472F20", "#9DD86620", "#6F4E7C20", "#F6C85F20", "#0B84A520", "#4198D720", "#E5575920", "#46D39A20", "#75659A20", "#3CC67720"];
const colorsBlue = ["#0010d9", "#0020ff", "#0040ff", "#1D53BF", "#0060ff", "#0080ff", "#009fff", "#00bfff", "#00ffff", "#22a7f0", "#a7d5ed", "#63bff0"];
const colorsBlueBR = ["#0010d920", "#0020ff20", "#0040ff20", "#1D53BF20", "#0060ff20", "#0080ff20", "#009fff20", "#00bfff20", "#00ffff20", "#22a7f020", , "#a7d5ed20", "#63bff020"];
const pieColor = ["#1c4e80", "#0091d5", "#4cb5f5", "#92D7FA"];
const pieColorBR = ["#1c4e8020", "#0091d520", "#4cb5f520", "#92D7FA20"];
const targetBarColor = ["#776bcd", "#968EE5", "#9080ff", "#54bebe", "#76c8c8", "#98d1d1", "#badbdb", "#dedad2", "#e4bcad", "#df979e", "#c80064", "#76c8c8"];
const targetBarColorBR = ["#76c8c820", "#968EE520", "#98d1d120", "#badbdb20", "#dedad220", "#e4bcad20", "#df979e20", "#E5BAE320", "#d7658b20", "#F983F520", "#c8006420"];

const showBarTargetChart = (chartdata, id, custom) => {
    let chartref = document.getElementById(id);

    if (Chart.getChart(id) !== undefined) {
        //  chartref.removeEventListener('click', (e) => mouseClickHandler(chartref,e))
        // chartref.removeEventListener('click', clickHandler )
        //  chartref.removeEventListener('mousemove',(e) =>  mouseMoveHandler(chartref,e))
        Chart.getChart(id).destroy();
    }
    const toolTipTitleRender = (items) => {
        return "";
    };
    const toolTipLabelRender = (items) => {
        return items.label + " - " + items.formattedValue + " t CO2e";
    };

    let dummy = chartdata;
    let labelList = ["2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"];

    let scope1 = chartref.getContext("2d");

    let scope1c = new Chart(scope1, {
        type: "line",
        data: {
            //Bring in data
            labels: labelList,
            datasets: dummy,
        },
        options: {
            aspectRatio: 2,

            scales: {
                y: {
                    title: {
                        display: true,
                        text: " t CO2e",
                    },
                },
            },
            plugins: {
                labels: {},
                legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                        color: "black",
                        boxWidth: 2,

                        font: {
                            size: 12,
                            family: `"Roboto", sans-serif`,
                            weight: "normal",
                        },
                    },
                },
                tooltip: {
                    yAlign: "bottom",
                    displayColor: false,
                    callbacks: {
                        title: toolTipTitleRender,
                        label: toolTipLabelRender,
                    },
                },
            },
        },
    });
};
const showBarChart = (chartdata, id, custom) => {
    let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);

    let count = 1;
    let chartref = document.getElementById(id);

    if (Chart.getChart(id) !== undefined) {
        Chart.getChart(id).destroy();
    }

    let coords = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    };
    let dummy = chartdata;
    let scope1 = chartref.getContext("2d");

    const resetChart = {
        id: "resetchart",
        beforeDraw(chart, args, options) {
            if (scope1c.config.data.datasets[0].label !== custom.name) {
                count = 0;
                const {
                    ctx,
                    chartArea: { top, left, right, bottom },
                } = chart;

                ctx.save();
                const text = "Back";
                const thickness = 3;
                const textwidth = ctx.measureText(text).width;

                ctx.fillStyle = "rgba(255,26,104,0.2)";
                ctx.fillRect(right - (textwidth + 11), 5, textwidth + 10, 20);

                ctx.fillStyle = "#666";
                ctx.font = "12px Arial";
                ctx.fillText(text, right - (textwidth + 6), 15);

                ctx.lineWidth = thickness + "px";
                ctx.strokeStyle = "rgba(255,26,104,1)";
                ctx.strokeRect(right - (textwidth + 11), 5, textwidth + 10, 20);
                coords.top = 5;
                coords.left = right - (textwidth + 11);
                coords.right = right;
                coords.bottom = 25;

                ctx.restore();
            }
        },
    };
    const toolTipTitleRender = (items) => {
        return "";
    };
    const toolTipLabelRender = (items) => {
        return items.label + " - " + items.formattedValue + custom.measure;
    };
    let scope1c = new Chart(scope1, {
        type: "bar",
        data: {
            //Bring in data

            datasets: [
                {
                    data: dummy,
                    label: custom.name,
                    backgroundColor: [colorsBlue[0], colorsBlue[1], colorsBlue[2], colorsBlue[3], colorsBlue[4], colorsBlue[5], colorsBlue[6], colorsBlue[7], colorsBlue[8], colorsBlue[9], colorsBlue[10], colorsBlue[11]],
                    borderColor: [colorsBlueBR[0], colorsBlueBR[1], colorsBlueBR[2], colorsBlueBR[3], colorsBlueBR[4], colorsBlueBR[5], colorsBlueBR[6], colorsBlueBR[7], colorsBlueBR[8], colorsBlueBR[9], colorsBlueBR[10], colorsBlueBR[11]],
                },
            ],
        },
        options: {
            onHover: (event, charElement) => {
                if (scope1c.config.data.datasets[0].label === custom.name) {
                    event.native.target.style.cursor = charElement[0] ? "pointer" : "default";
                } else {
                    event.native.target.style.cursor = "default";
                }
            },
            scales: {
                y: {
                    title: {
                        display: true,
                        text: custom.measure,
                    },
                    // scaleLabel: {display:true,labelString:'Something'},
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                            return value;
                        },
                    },
                },
            },
            legend: {
                display: false,
            },
            parsing: {
                xAxisKey: "name",
                yAxisKey: "y",
            },
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        color: "black",
                        boxWidth: 0,
                        font: {
                            size: 16,
                            family: `"Roboto", sans-serif`,
                            weight: "normal",
                        },
                    },
                },
                labels: {
                    render: (args) => {
                        return "";
                    },
                },
                tooltip: {
                    yAlign: "bottom",
                    displayColor: false,
                    callbacks: {
                        title: toolTipTitleRender,
                        label: toolTipLabelRender,
                    },
                },
            },
        },
        plugins: [resetChart, ChartDL],
    });

    function clickHandler(click) {
        if (scope1c.config.data.datasets[0].label === custom.name) {
            const bar = scope1c.getElementsAtEventForMode(
                click,
                "nearest",
                {
                    intersect: true,
                },
                true
            );
            if (bar.length) {
                changeChart(bar[0].index);
            }
        }
    }

    function changeChart(ind) {
        scope1c.config.options.parsing.xAxisKey = "subset.x";
        scope1c.config.options.parsing.yAxisKey = "subset.xy";
        const users = [];
        const vlabels = dummy[ind].subset.map((labels) => {
            users.push(labels[1]);

            return labels[0];
        });

        scope1c.config.data.datasets[0].data = users;
        scope1c.config.data.labels = vlabels;
        scope1c.config.options.plugins.legend.display = true;
        scope1c.config.data.datasets[0].label = dummy[ind].name;
        scope1c.update();
    }

    function mouseMoveHandler(canvas, mousemove) {
        const x = mousemove.offsetX;
        const y = mousemove.offsetY;
        if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
            canvas.style.cursor = "pointer";
        } else {
            canvas.style.cursor = "default";
        }
    }

    function handleBackClick() {
        scope1c.config.options.parsing.xAxisKey = "name";
        scope1c.config.options.parsing.yAxisKey = "y";
        const users = [];
        const vlabels = dummy.map((labels) => {
            users.push(labels.y);
            return labels.name;
        });
        scope1c.config.options.plugins.legend.display = true;
        scope1c.config.data.datasets[0].data = users;
        scope1c.config.data.labels = vlabels;

        scope1c.config.data.datasets[0].label = custom.name;

        scope1c.update();
    }

    function mouseClickHandler(click) {
        const x = click.offsetX;
        const y = click.offsetY;

        if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
            handleBackClick();
        }
    }

    chartref.addEventListener("click", (e) => {
        if (count === 0) {
            count = 1;
            mouseClickHandler(e);
        }
    });
    chartref.addEventListener("mousemove", (e) => mouseMoveHandler(chartref, e));
    chartref.onclick = clickHandler;
};
const showNoDataFound = (id, txt) => {
    if (Chart.getChart(id) !== undefined) {
        Chart.getChart(id).destroy();
    }
    const ctx = document.getElementById(id).getContext("2d");

    // Set the font-size and font-family to mimic our paragraph
    ctx.font = "20px serif";

    // Draw the text
    ctx.fillText(txt, 0, 20);
};
const showGroupBarChart = (chartdata, id, custom) => {
    let chartref = document.getElementById(id);

    if (Chart.getChart(id) !== undefined) {
        Chart.getChart(id).destroy();
    }

    let dummy = chartdata;
    let scope1 = chartref.getContext("2d");

    const toolTipTitleRender = (items) => {
        return "";
    };
    const toolTipLabelRender = (items) => {
        return items.label + " - " + items.formattedValue + custom.measure;
    };
    let scope1c = new Chart(scope1, {
        type: "bar",
        data: {
            //Bring in data
            labels: custom.label1,
            datasets: dummy,
        },
        options: {
            aspectRatio: 2.75,
            scales: {
                y: {
                    title: {
                        display: true,
                        text: custom.measure,
                    },
                    // scaleLabel: {display:true,labelString:'Something'},
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                            return value;
                        },
                    },
                },
            },
            legend: {
                display: true,
            },

            plugins: {
                legend: {
                    display: true,
                    labels: {
                        color: "black",
                        boxWidth: custom.display ? 5 : 0,
                        font: {
                            size: 16,
                            family: `"Roboto", sans-serif`,
                            weight: "normal",
                        },
                    },
                },
                labels: {
                    render: (args) => {
                        return "";
                    },
                },
                tooltip: {
                    yAlign: "bottom",
                    displayColor: false,
                    callbacks: {
                        title: toolTipTitleRender,
                        label: toolTipLabelRender,
                    },
                },
            },
        },
        plugins: [ChartDL],
    });
};
const ClimateChangeDB = (props) => {
    const forceUpdate = useForceUpdate();
    const [products, setProducts] = useState(null);
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const [lineOptions, setLineOptions] = useState(null);
    const [data, setData] = useState([]);
    const [apidata, setApiData] = useState([]);
    const [dbdata, setDBData] = useState([]);
    const [year, setYear] = useState([]);
    const [month, setMonth] = useState([]);
    const [scope111PO, setScope111PO] = useState(false);
    const [unitshow, setUnitShow] = useState(false);
    const [scope211PO, setScope211PO] = useState(false);
    const [scope212PO, setScope212PO] = useState(false);
    const [description, setDescription] = useState({});
    const [scope113PO, setScope113PO] = useState(false);
    const [scope122PO, setScope122PO] = useState(false);
    const [scope132PO, setScope132PO] = useState(false);

    const selector = useSelector((state) => state.user.userdetail);
    const [filter, setFilter] = useState({
        country: {
            name: "All",
        },
        city: {
            name: "All",
        },
        location: {
            name: "All",
        },
        month: {
            name: "All",
        },
        year: {
            name: "All",
        },
        scope: {
            name: "All",
        },
    });
    const [city, setCity] = useState(null);
    const [rawcity, setRawCity] = useState([
        {
            India: [
                {
                    name: "Ahmedabad",
                },
                {
                    name: "Bengaluru",
                },
                {
                    name: "Delhi",
                },
                {
                    name: "Hyderabad",
                },
                {
                    name: "Kolkata",
                },
                {
                    name: "Mumbai",
                },
                {
                    name: "Noida",
                },
                {
                    name: "Pune",
                },
                {
                    name: "Chennai",
                },
            ],
            Singapore: [
                {
                    name: "Singapore",
                },
            ],
            Phillinpines: [
                {
                    name: "Metro Manila",
                },
                {
                    name: "Calabarzon",
                },
                {
                    name: "Davao",
                },
            ],
            Indonesia: [
                {
                    name: "Bekasi",
                },
            ],
            Japan: [
                {
                    name: "Tokyo",
                },
            ],
            "South Korea": [
                {
                    name: "Seoul",
                },
            ],
            Thailand: [
                {
                    name: "Bangkok",
                },
            ],
            UK: [
                {
                    name: "London",
                },
            ],
            China: [
                {
                    name: "Shanghai",
                },
                {
                    name: "Kunshan",
                },
                {
                    name: "Changshu",
                },
                {
                    name: "Nantong",
                },
                {
                    name: "Wuhan",
                },
                {
                    name: "Chengdu",
                },
                {
                    name: "Ulanqab",
                },
                {
                    name: "Beijing",
                },
                {
                    name: "Langfang",
                },
                {
                    name: "Shenzhen",
                },
                {
                    name: "Hongkong",
                },
                {
                    name: "Heyuan",
                },
            ],
        },
    ]);

    const [location, setLocation] = useState(null);
    const [rawlocation, setRawLocation] = useState([]);
    const [rawcountry, setRawCountry] = useState([]);
    const [country, setCountry] = useState([
        {
            name: "India",
        },
        {
            name: "Singapore",
        },
        {
            name: "Phillinpines",
        },
        {
            name: "Indonesia",
        },
        {
            name: "Japan",
        },
        {
            name: "South Korea",
        },
        {
            name: "Thailand",
        },
        {
            name: "UK",
        },
        {
            name: "China",
        },
    ]);
    let dummyComp = [
        {
            2019: [
                {
                    month: "January",
                    value: 136,
                },
                {
                    month: "February",
                    value: 276,
                },
                {
                    month: "March",
                    value: 186,
                },
                {
                    month: "April",
                    value: 206,
                },
                {
                    month: "May",
                    value: 176,
                },
                {
                    month: "June",
                    value: 226,
                },
                {
                    month: "July",
                    value: 216,
                },
                {
                    month: "August",
                    value: 246,
                },
                {
                    month: "September",
                    value: 156,
                },
                {
                    month: "October",
                    value: 186,
                },
                {
                    month: "November",
                    value: 206,
                },
                {
                    month: "December",
                    value: 176,
                },
            ],
        },
        {
            2020: [
                {
                    month: "January",
                    value: 156,
                },
                {
                    month: "February",
                    value: 186,
                },
                {
                    month: "March",
                    value: 176,
                },
                {
                    month: "April",
                    value: 196,
                },
                {
                    month: "May",
                    value: 200,
                },
                {
                    month: "June",
                    value: 236,
                },
                {
                    month: "July",
                    value: 206,
                },
                {
                    month: "August",
                    value: 256,
                },
                {
                    month: "September",
                    value: 150,
                },
                {
                    month: "October",
                    value: 196,
                },
                {
                    month: "November",
                    value: 197,
                },
                {
                    month: "December",
                    value: 186,
                },
            ],
        },
    ];
    const [FY, setFY] = useState({
        a: "FY19",
        b: "select",
    });
    const [comp, setComp] = useState([206, 176, 226]);
    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };
    const setCompByFY = (val, type) => {
        if (type === "a") {
            let fy = FY;
            fy.a = val;
            setFY(fy);
        } else {
            let fy = FY;
            fy.b = val;
            setFY(fy);
        }

        if (FY.a === "select" || FY.b === "select") {
            let label1 = [];
            let dataset1 = [];
            if (FY.a !== "select") {
                let dc = dummyComp;
                let c = [];
                let n = [];

                let btns = document.getElementsByClassName("buttons")[0];
                for (var h = 0; h < btns.childElementCount; h++) {
                    if (btns.childNodes[h].className === "active") {
                        n = btns.childNodes[h].textContent;
                    }
                }

                dc.forEach((a) => {
                    for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                        if (Object.getOwnPropertyNames(a)[k] === "20" + FY.a.slice(-2)) {
                            a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                if (n === "Q1") {
                                    // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                    if (ind === 3 || ind === 4 || ind === 5) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q2") {
                                    if (ind === 6 || ind === 7 || ind === 8) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q3") {
                                    if (ind === 9 || ind === 10 || ind === 11) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q4") {
                                    if (ind === 0 || ind === 1 || ind === 2) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "FY") {
                                    label1.push(b.month);
                                    dataset1.push(b.value);
                                    c.push({
                                        year: Object.getOwnPropertyNames(a)[k],
                                        month: b.month,
                                        value: b.value,
                                    });
                                }
                            });
                        }
                    }
                });
                let data = [
                    {
                        data: dataset1,
                        label: FY.a,
                        backgroundColor: [targetBarColor[0], targetBarColor[1], targetBarColor[2], targetBarColor[3], targetBarColor[4], targetBarColor[5], targetBarColor[6], targetBarColor[7], targetBarColor[8], targetBarColor[9], targetBarColor[10], targetBarColor[11]],
                        borderColor: [targetBarColorBR[0], targetBarColorBR[1], targetBarColorBR[2], targetBarColorBR[3], targetBarColorBR[4], targetBarColorBR[5], targetBarColorBR[6], targetBarColorBR[7], targetBarColorBR[8], targetBarColorBR[9], targetBarColorBR[10], targetBarColorBR[11]],
                    },
                ];
                showGroupBarChart(data, "compbarchart", {
                    label1,
                    measure: " t CO2e",
                    display: false,
                });
                setComp(c);
            } else if (FY.b !== "select") {
                let dc = dummyComp;
                let c = [];
                let n = [];

                let btns = document.getElementsByClassName("buttons")[0];
                for (var h = 0; h < btns.childElementCount; h++) {
                    if (btns.childNodes[h].className === "active") {
                        n = btns.childNodes[h].textContent;
                    }
                }

                dc.forEach((a) => {
                    for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                        if (Object.getOwnPropertyNames(a)[k] === "20" + FY.b.slice(-2)) {
                            a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                if (n === "Q1") {
                                    // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                    if (ind === 3 || ind === 4 || ind === 5) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q2") {
                                    if (ind === 6 || ind === 7 || ind === 8) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q3") {
                                    if (ind === 9 || ind === 10 || ind === 11) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q4") {
                                    if (ind === 0 || ind === 1 || ind === 2) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "FY") {
                                    label1.push(b.month);
                                    dataset1.push(b.value);
                                    c.push({
                                        year: Object.getOwnPropertyNames(a)[k],
                                        month: b.month,
                                        value: b.value,
                                    });
                                }
                            });
                        }
                    }
                });
                let data = [
                    {
                        data: dataset1,
                        label: FY.b,
                        backgroundColor: [targetBarColor[0], targetBarColor[1], targetBarColor[2], targetBarColor[3], targetBarColor[4], targetBarColor[5], targetBarColor[6], targetBarColor[7], targetBarColor[8], targetBarColor[9], targetBarColor[10], targetBarColor[11]],
                        borderColor: [targetBarColorBR[0], targetBarColorBR[1], targetBarColorBR[2], targetBarColorBR[3], targetBarColorBR[4], targetBarColorBR[5], targetBarColorBR[6], targetBarColorBR[7], targetBarColorBR[8], targetBarColorBR[9], targetBarColorBR[10], targetBarColorBR[11]],
                    },
                ];
                showGroupBarChart(data, "compbarchart", {
                    label1,
                    measure: " t CO2e",
                    display: false,
                });
                setComp(c);
            } else if (FY.a === FY.b) {
                let dc = dummyComp;
                let c = [];
                let n = [];

                let btns = document.getElementsByClassName("buttons")[0];
                for (var h = 0; h < btns.childElementCount; h++) {
                    if (btns.childNodes[h].className === "active") {
                        n = btns.childNodes[h].textContent;
                    }
                }

                dc.forEach((a) => {
                    for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                        if (Object.getOwnPropertyNames(a)[k] === "2019") {
                            a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                if (n === "Q1") {
                                    // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                    if (ind === 3 || ind === 4 || ind === 5) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q2") {
                                    if (ind === 6 || ind === 7 || ind === 8) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q3") {
                                    if (ind === 9 || ind === 10 || ind === 11) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q4") {
                                    if (ind === 0 || ind === 1 || ind === 2) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "FY") {
                                    label1.push(b.month);
                                    dataset1.push(b.value);
                                    c.push({
                                        year: Object.getOwnPropertyNames(a)[k],
                                        month: b.month,
                                        value: b.value,
                                    });
                                }
                            });
                        }
                    }
                });
                let data = [
                    {
                        data: dataset1,
                        label: FY.a === "select" ? "FY19" : FY.a,
                        backgroundColor: [targetBarColor[0], targetBarColor[1], targetBarColor[2], targetBarColor[3], targetBarColor[4], targetBarColor[5], targetBarColor[6], targetBarColor[7], targetBarColor[8], targetBarColor[9], targetBarColor[10], targetBarColor[11]],
                        borderColor: [targetBarColorBR[0], targetBarColorBR[1], targetBarColorBR[2], targetBarColorBR[3], targetBarColorBR[4], targetBarColorBR[5], targetBarColorBR[6], targetBarColorBR[7], targetBarColorBR[8], targetBarColorBR[9], targetBarColorBR[10], targetBarColorBR[11]],
                    },
                ];
                showGroupBarChart(data, "compbarchart", {
                    label1,
                    measure: " t CO2e",
                    display: false,
                });
                setComp(c);
            }
        } else {
            let label1 = [];
            let dataset1 = [];

            let dataset2 = [];
            if (FY.a !== FY.b) {
                let dc = dummyComp;
                let c = [];
                let n = [];

                let btns = document.getElementsByClassName("buttons")[0];
                for (var h = 0; h < btns.childElementCount; h++) {
                    if (btns.childNodes[h].className === "active") {
                        n = btns.childNodes[h].textContent;
                    }
                }

                dc.forEach((a) => {
                    for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                        if (Object.getOwnPropertyNames(a)[k] === "20" + FY.a.slice(-2)) {
                            a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                if (n === "Q1") {
                                    // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                    if (ind === 3 || ind === 4 || ind === 5) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q2") {
                                    if (ind === 6 || ind === 7 || ind === 8) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q3") {
                                    if (ind === 9 || ind === 10 || ind === 11) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q4") {
                                    if (ind === 0 || ind === 1 || ind === 2) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "FY") {
                                    label1.push(b.month);
                                    dataset1.push(b.value);
                                    c.push({
                                        year: Object.getOwnPropertyNames(a)[k],
                                        month: b.month,
                                        value: b.value,
                                    });
                                }
                            });
                        } else if (Object.getOwnPropertyNames(a)[k] === "20" + FY.b.slice(-2)) {
                            a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                if (n === "Q1") {
                                    // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                    if (ind === 3 || ind === 4 || ind === 5) {
                                        dataset2.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q2") {
                                    if (ind === 6 || ind === 7 || ind === 8) {
                                        dataset2.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q3") {
                                    if (ind === 9 || ind === 10 || ind === 11) {
                                        dataset2.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q4") {
                                    if (ind === 0 || ind === 1 || ind === 2) {
                                        dataset2.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "FY") {
                                    dataset2.push(b.value);
                                    c.push({
                                        year: Object.getOwnPropertyNames(a)[k],
                                        month: b.month,
                                        value: b.value,
                                    });
                                }
                            });
                        }
                    }
                });
                let data = [
                    {
                        data: dataset1,
                        label: FY.a,
                        backgroundColor: [pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0]],
                        borderColor: [colorsBR[0], colorsBR[1], colorsBR[2], colorsBR[3], colorsBR[4], colorsBR[5], colorsBR[6], colorsBR[7], colorsBR[8], colorsBR[9], colorsBR[10], colorsBR[11]],
                    },
                    {
                        data: dataset2,
                        label: FY.b,
                        backgroundColor: [pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1]],
                        borderColor: [colorsBR[0], colorsBR[1], colorsBR[2], colorsBR[3], colorsBR[4], colorsBR[5], colorsBR[6], colorsBR[7], colorsBR[8], colorsBR[9], colorsBR[10], colorsBR[11]],
                    },
                ];
                showGroupBarChart(data, "compbarchart", {
                    label1,
                    measure: " t CO2e",
                    display: true,
                });
                setComp(c);
            } else {
                let dc = dummyComp;
                let c = [];
                let n = [];

                let btns = document.getElementsByClassName("buttons")[0];
                for (var h = 0; h < btns.childElementCount; h++) {
                    if (btns.childNodes[h].className === "active") {
                        n = btns.childNodes[h].textContent;
                    }
                }

                dc.forEach((a) => {
                    for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                        if (Object.getOwnPropertyNames(a)[k] === "2019") {
                            a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                if (n === "Q1") {
                                    // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                    if (ind === 3 || ind === 4 || ind === 5) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q2") {
                                    if (ind === 6 || ind === 7 || ind === 8) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q3") {
                                    if (ind === 9 || ind === 10 || ind === 11) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "Q4") {
                                    if (ind === 0 || ind === 1 || ind === 2) {
                                        label1.push(b.month);
                                        dataset1.push(b.value);
                                        c.push({
                                            year: Object.getOwnPropertyNames(a)[k],
                                            month: b.month,
                                            value: b.value,
                                        });
                                    }
                                } else if (n === "FY") {
                                    label1.push(b.month);
                                    dataset1.push(b.value);
                                    c.push({
                                        year: Object.getOwnPropertyNames(a)[k],
                                        month: b.month,
                                        value: b.value,
                                    });
                                }
                            });
                        }
                    }
                });
                let data = [
                    {
                        data: dataset1,
                        label: FY.a,
                        backgroundColor: [targetBarColor[0], targetBarColor[1], targetBarColor[2], targetBarColor[3], targetBarColor[4], targetBarColor[5], targetBarColor[6], targetBarColor[7], targetBarColor[8], targetBarColor[9], targetBarColor[10], targetBarColor[11]],
                        borderColor: [targetBarColorBR[0], targetBarColorBR[1], targetBarColorBR[2], targetBarColorBR[3], targetBarColorBR[4], targetBarColorBR[5], targetBarColorBR[6], targetBarColorBR[7], targetBarColorBR[8], targetBarColorBR[9], targetBarColorBR[10], targetBarColorBR[11]],
                    },
                ];
                showGroupBarChart(data, "compbarchart", {
                    label1,
                    measure: " t CO2e",
                    display: false,
                });
                setComp(c);
            }
        }
    };
    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#ebedef",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    useEffect(() => {
        const productService = new ProductService();
        btnsLoaded();
        let locdata = [
            {
                data: comp,
                label: "FY19",
                backgroundColor: [targetBarColor[0], targetBarColor[1], targetBarColor[2], targetBarColor[3], targetBarColor[4], targetBarColor[5], colors[6], colors[7], colors[8], colors[9], colors[10], colors[11]],
                borderColor: [colorsBR[0], colorsBR[1], colorsBR[2], colorsBR[3], colorsBR[4], colorsBR[5], colorsBR[6], colorsBR[7], colorsBR[8], colorsBR[9], colorsBR[10], colorsBR[11]],
            },
        ];
        showGroupBarChart(locdata, "compbarchart", {
            label1: ["April", "May", "June"],
            measure: " t CO2e",
            display: false,
        });

        let country = [
            {
                name: "All",
            },
        ],
            city = [],
            locationz = [];
        selector.information.config[0].location.countrylist.forEach((item, ind) => {
            country.push({
                name: item.country,
            });

            if (
                city.findIndex((k) => {
                    return k === item.country;
                }) === -1
            ) {
                city.push({
                    [item.country]: [],
                });
            }

            item.city.forEach((item2, ind2) => {
                city[ind][item.country].push({
                    name: item2.city,
                });
                if (
                    locationz.findIndex((k) => {
                        return k === item2.city;
                    }) === -1
                ) {
                    locationz.push({
                        [item2.city]: [],
                    });
                }
                item2.location.forEach((item3) => {
                    if (locationz[ind2][item2.city] !== undefined) {
                        locationz[ind2][item2.city].push({
                            name: item3.location,
                        });
                    }
                });
            });
        });
        setRawCity(city);
        setRawCountry(country);
        setCountry(country);
        setRawLocation(locationz);

  

        loadInitialChart();
        setProducts(productService.getIndicator());
    }, []);

    useEffect(() => {
        if (props.colorMode === "light") {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
        loadInitialChart();
    }, [props.colorMode]);
    useEffect(() => {
        freezeFilter();
    }, [data]);
    const updateYearMonthFilter = (data) => {
        let locyear = [
            {
                name: "All",
            },
        ];

        data.forEach((item) => {
            if (
                locyear.findIndex((i) => {
                    return i.name === item.data[0].year;
                }) === -1
            ) {
                locyear.push({
                    name: item.data[0].year,
                });
            }
        });

        setYear(locyear);
    };
    const showPieChartNoAnimation = (chartdata, id, custom) => {
        console.log(chartdata, 'pie')
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            //  chartref.removeEventListener('click', (e) => mouseClickHandler(chartref,e))
            // chartref.removeEventListener('click', clickHandler )
            //  chartref.removeEventListener('mousemove',(e) =>  mouseMoveHandler(chartref,e))
            Chart.getChart(id).destroy();
        }
        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + " t CO2e";
        };
        let dummy = chartdata;
        let labelList = [];
        chartdata.forEach((a) => {
            labelList.push([a.name]);
        });
        let scope1 = chartref.getContext("2d");

        let shadow = {
            beforeDraw: (chart) => {
                var ctx = chart.ctx;
                ctx.save();
                ctx.beginPath();
                ctx.fillStyle = "white";
                ctx.shadowColor = "black";
                ctx.shadowBlur = 20;
                ctx.shadowOffsetX = -10;
                ctx.shadowOffsetY = 0;
                const x = chart.width / 2;
                const y = chart.height / 2 + 15;
                ctx.arc(x, y, 95, 0, Math.PI * 2, false);
                ctx.fill();
                ctx.restore();
            },
        };
        let scope1c = new Chart(scope1, {
            type: "pie",
            data: {
                //Bring in data
                labels: labelList,
                datasets: [
                    {
                        data: dummy,
                        hoverOffset: 5,
                        label: custom.name,
                        backgroundColor: [pieColor[3], pieColor[1], pieColor[2], colors[3], colors[4], colors[5], colors[6], colors[7], colors[8], colors[9], colors[10], colors[11]],
                        borderColor: [pieColorBR[3], pieColorBR[1], pieColorBR[2], colorsBR[3], colorsBR[4], colorsBR[5], colorsBR[6], colorsBR[7], colorsBR[8], colorsBR[9], colorsBR[10], colorsBR[11]],
                    },
                ],
            },
            options: {
                aspectRatio: 1,
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: custom.name === "scope 2" ? 23 : 0,
                    },
                },

                plugins: {
                    labels: {
                        render: (args) => {
                            let ad = 0,
                                count = 0;
                            args.dataset.data.forEach((a, i) => {
                                ad = ad + a.y;
                                count++;
                            });
                            let div = ad / 100;
                            return Math.round(args.value.y / div) + "%";
                        },
                    },
                    legend: {
                        display: true,
                        position: "bottom",
                        labels: {
                            color: "black",
                            boxWidth: 2,

                            font: {
                                size: 12,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    tooltip: {
                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },
                },
                parsing: {
                    key: "y",
                },
            },
            plugins: [ChartDL],
        });
    };
    const loadInitialChart = () => {
        showBarTargetChart(
            [
                {
                    data: [860, 900, 800, 800, 700, 650, 520, 420, 310, 200, 150, 0],
                    label: "Target",
                    borderColor: "red",
                    fill: false,
                },
                {
                    data: [950, 930, 850, 750],
                    borderColor: "green",
                    label: "progress",
                    fill: false,
                },
            ],
            "targetchart1",
            {
                label1: "",
                measure: " nil",
            }
        );
        showBarTargetChart(
            [
                {
                    data: [1060, 1100, 1000, 1000, 950, 830, 770, 680, 650, 630, 580, 530],
                    label: "Target",
                    borderColor: "red",
                    fill: false,
                },
                {
                    data: [1250, 1200, 1220, 1180],
                    borderColor: "green",
                    label: "progress",
                    fill: false,
                },
            ],
            "targetchart2",
            {
                label1: "",
                measure: " nil",
            }
        );
        // showBarChart([], 'scope1unit',{name:'Combustion of Fuels (Diesel)',measure:' kwh'})
    };

    const showPieChart = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);

        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
           
            Chart.getChart(id).destroy();
        }

        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let labelList = [];
        chartdata.forEach((a) => {
            labelList.push([a.name]);
        });

        let scope1 = chartref.getContext("2d");
        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            let suffix = custom.measure === " t CO2e" ? " t CO2e" : " % of passenger";

            return items.label + " - " + items.formattedValue + suffix;
        };
        const resetChart = {
            id: "resetchart",
            beforeDraw(chart, args, options) {
                if (scope1c.config.data.datasets[0].label !== custom.name) {
                    count = 0;
                    const {
                        ctx,
                        chartArea: { top, left, right, bottom },
                    } = chart;

                    ctx.save();
                    const text = "Back";
                    const thickness = 3;
                    const textwidth = ctx.measureText(text).width;

                    ctx.fillStyle = "rgba(255,26,104,0.2)";
                    ctx.fillRect(right - (textwidth + 11), 5, textwidth + 10, 20);

                    ctx.fillStyle = "#666";
                    ctx.font = "12px Arial";
                    ctx.fillText(text, right - (textwidth + 6), 15);

                    ctx.lineWidth = thickness + "px";
                    ctx.strokeStyle = "rgba(255,26,104,1)";
                    ctx.strokeRect(right - (textwidth + 11), 5, textwidth + 10, 20);
                    coords.top = 5;
                    coords.left = right - (textwidth + 11);
                    coords.right = right;
                    coords.bottom = 25;

                    ctx.restore();
                }
            },
        };
        let scope1c = new Chart(scope1, {
            type: "pie",

            data: {
                //Bring in data
                labels: labelList,
                datasets: [
                    {
                        data: dummy,
                        hoverOffset: 10,
                        label: custom.name,
                        backgroundColor: [pieColor[0], pieColor[1], pieColor[2], colors[3], colors[4], colors[5], colors[6], colors[7], colors[8], colors[9], colors[10], colors[11]],
                        borderColor: [pieColorBR[0], pieColorBR[1], pieColorBR[2], colorsBR[3], colorsBR[4], colorsBR[5], colorsBR[6], colorsBR[7], colorsBR[8], colorsBR[9], colorsBR[10], colorsBR[11]],
                    },
                ],
            },
            options: {
                onHover: (event, charElement) => {
                    if (scope1c.config.data.datasets[0].label === custom.name) {
                        event.native.target.style.cursor = charElement[0] ? "pointer" : "default";
                    } else {
                        event.native.target.style.cursor = "default";
                    }
                },

                // scales: {

                //   y: {
                //     title:{
                //       display:true,
                //       text:custom.measure
                //     },
                //     // scaleLabel: {display:true,labelString:'Something'},
                //     ticks: {
                //         // Include a dollar sign in the ticks
                //         callback: function(value, index, ticks) {
                //             return   value;
                //         }
                //     } }
                // },
                legend: {
                    display: false,
                },
                plugins: {
                    labels: {
                        render: (args) => {
                            if (!isNumeric(args.percentage)) {
                                let ad = 0,
                                    count = 0;

                                args.dataset.data.forEach((a, i) => {
                                    ad = ad + a.y;
                                    count++;
                                });
                                let div = ad / 100;
                                return Math.round(args.value.y / div) + " %";
                            } else {
                                return args.percentage + " %";
                            }
                        },
                        fontColor: "white",
                    },
                    legend: {
                        display: true,
                        position: "bottom",
                        labels: {
                            color: "black",
                            boxWidth: 4,

                            font: {
                                size: 12,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    tooltip: {
                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },
                },
                parsing: {
                    key: "y",
                },

                aspectRatio: custom.name === "Emissions Breakdown" ? 2 : 2,
            },
            plugins: [resetChart, ChartDL],
        });

        function clickHandler(click) {
            if (scope1c.config.data.datasets[0].label === custom.name) {
                const bar = scope1c.getElementsAtEventForMode(
                    click,
                    "nearest",
                    {
                        intersect: true,
                    },
                    true
                );
                if (bar.length) {
                    changeChart(bar[0].index);
                }
            }
        }

        function changeChart(ind) {
            scope1c.config.options.parsing.xAxisKey = "subset.x";
            scope1c.config.options.parsing.yAxisKey = "subset.xy";
            const users = [];
            const vlabels = dummy[ind].subset.map((labels) => {
                users.push(labels[1]);

                return labels[0];
            });

            scope1c.config.data.datasets[0].data = users;
            scope1c.config.data.labels = vlabels;

            scope1c.config.data.datasets[0].label = dummy[ind].name;
            scope1c.update();
        }

        function mouseMoveHandler(canvas, mousemove) {
            const x = mousemove.offsetX;
            const y = mousemove.offsetY;

            if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
                canvas.style.cursor = "pointer";
            } else {
                canvas.style.cursor = "default";
            }
        }

        function handleBackClick() {
            scope1c.config.options.parsing.xAxisKey = "name";
            scope1c.config.options.parsing.yAxisKey = "y";
            const users = [];
            const vlabels = dummy.map((labels) => {
                users.push(labels.y);
                return labels.name;
            });

            scope1c.config.data.datasets[0].data = users;
            scope1c.config.data.labels = vlabels;

            scope1c.config.data.datasets[0].label = custom.name;

            scope1c.update();
        }

        function mouseClickHandler(click) {
            const x = click.offsetX;
            const y = click.offsetY;

            if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
                handleBackClick();
            }
        }

        chartref.addEventListener("click", (e) => {
            if (count === 0) {
                count = 1;
                mouseClickHandler(e);
            }
        });
        chartref.addEventListener("mousemove", (e) => mouseMoveHandler(chartref, e));
        chartref.onclick = clickHandler;
    };
    const updateChart = (data, show) => {
        let scopevalue = {
            "Scope 1": 0,
            "Scope 2": 0,
            "Scope 3": 0,
        };
        let total = 0;
        let scopes = [];
        let scope1Aunit = [],
            scope1Atco2 = [],
            scope1Bunit = [],
            scope1Btco2 = [],
            scope1Cunit = [],
            scope1Ctco2 = [],
            scope2Aunit = [],
            scope2Atco2 = [];
        let scope3Cunit = [],
            scope3Ctco2 = [],
            scope3Aunit = [],
            scope3Atco2 = [],
            scope3Aprocure = [],
            scope3Bunit = [],
            scope3Btco2 = [];
        data.forEach((top, topind) => {
            top.data.forEach((item, j) => {
                if (!item.scope.includes("General")) {
                    if (item.topic === "Stationary Combustion  Emission" || item.topic === "Emissions from Purchased Electricity") {
                        if (item.topic === "Stationary Combustion  Emission") {
                            if (
                                scope1Atco2.findIndex((i) => {
                                    return i.name === item.city;
                                }) === -1
                            ) {
                                scope1Atco2.push({
                                    name: item.city,
                                    drilldown: true,
                                    color: colors[0],
                                    y: item.data[0].tco2e,
                                    subset: [[item.month, item.data[0].tco2e]],
                                });
                            } else {
                                let ind = scope1Atco2.findIndex((i) => {
                                    return i.name === item.city;
                                });
                                let subind = scope1Atco2[ind].subset.findIndex((j) => {
                                    return j[0] === item.month;
                                });
                                scope1Atco2[ind].y = scope1Atco2[ind].y + item.data[0].tco2e;
                                if (subind === -1) {
                                    scope1Atco2[ind].subset.push([item.month, item.data[0].tco2e]);
                                } else {
                                    scope1Atco2[ind].subset[subind][1] = scope1Atco2[ind].subset[subind][1] + item.data[0].tco2e;
                                }
                            }

                            if (
                                scope1Aunit.findIndex((i) => {
                                    return i.name === item.city;
                                }) === -1
                            ) {
                                scope1Aunit.push({
                                    name: item.city,
                                    drilldown: true,
                                    color: colors[0],
                                    y: item.data[0].dgunit,
                                    subset: [[item.month, item.data[0].dgunit]],
                                });
                            } else {
                                let ind = scope1Aunit.findIndex((i) => {
                                    return i.name === item.city;
                                });
                                let subind = scope1Aunit[ind].subset.findIndex((j) => {
                                    return j[0] === item.month;
                                });
                                scope1Aunit[ind].y = scope1Aunit[ind].y + item.data[0].dgunit;
                                if (subind === -1) {
                                    scope1Aunit[ind].subset.push([item.month, item.data[0].dgunit]);
                                } else {
                                    scope1Aunit[ind].subset[subind][1] = scope1Aunit[ind].subset[subind][1] + item.data[0].dgunit;
                                }
                            }
                        } else {
                            if (
                                scope2Atco2.findIndex((i) => {
                                    return i.name === item.city;
                                }) === -1
                            ) {
                                scope2Atco2.push({
                                    name: item.city,
                                    drilldown: true,
                                    color: colors[0],
                                    y: parseFloat(item.data[0].tco2e),
                                    subset: [[item.month, item.data[0].tco2e]],
                                });
                            } else {
                                let ind = scope2Atco2.findIndex((i) => {
                                    return i.name === item.city;
                                });
                                let subind = scope2Atco2[ind].subset.findIndex((j) => {
                                    return j[0] === item.month;
                                });
                                scope2Atco2[ind].y = scope2Atco2[ind].y + parseFloat(item.data[0].tco2e);
                                if (subind === -1) {
                                    scope2Atco2[ind].subset.push([item.month, item.data[0].tco2e]);
                                } else {
                                    scope2Atco2[ind].subset[subind][1] = scope2Atco2[ind].subset[subind][1] + item.data[0].tco2e;
                                }
                            }

                            if (
                                scope2Aunit.findIndex((i) => {
                                    return i.name === item.city;
                                }) === -1
                            ) {
                                scope2Aunit.push({
                                    name: item.city,
                                    drilldown: true,
                                    color: colors[0],
                                    y: parseFloat(item.data[0].unit),
                                    subset: [[item.month, parseFloat(item.data[0].unit)]],
                                });
                            } else {
                                let ind = scope2Aunit.findIndex((i) => {
                                    return i.name === item.city;
                                });
                                let subind = scope2Aunit[ind].subset.findIndex((j) => {
                                    return j[0] === item.month;
                                });
                                scope2Aunit[ind].y = scope2Aunit[ind].y + parseFloat(item.data[0].unit);
                                if (subind === -1) {
                                    scope2Aunit[ind].subset.push([item.month, parseFloat(item.data[0].unit)]);
                                } else {
                                    scope2Aunit[ind].subset[subind][1] = parseFloat(scope2Aunit[ind].subset[subind][1]) + parseFloat(item.data[0].unit);
                                }
                            }
                        }
                        scopevalue[item.scope] = scopevalue[item.scope] + item.data[0].tco2e;
                        total = total + item.data[0].tco2e;
                    } else {
                        item.data.forEach((subitem) => {
                            if (item.topic.includes("Fugi")) {
                                if (
                                    scope1Btco2.findIndex((i) => {
                                        return i.name === item.city;
                                    }) === -1
                                ) {
                                    scope1Btco2.push({
                                        name: item.city,
                                        drilldown: true,
                                        color: colors[0],
                                        y: subitem.tco2e,
                                        subset: [[item.month, subitem.tco2e]],
                                    });
                                } else {
                                    let ind = scope1Btco2.findIndex((i) => {
                                        return i.name === item.city;
                                    });
                                    let subind = scope1Btco2[ind].subset.findIndex((j) => {
                                        return j[0] === item.month;
                                    });
                                    scope1Btco2[ind].y = scope1Btco2[ind].y + subitem.tco2e;
                                    if (subind === -1) {
                                        scope1Btco2[ind].subset.push([item.month, subitem.tco2e]);
                                    } else {
                                        scope1Btco2[ind].subset[subind][1] = scope1Btco2[ind].subset[subind][1] + subitem.tco2e;
                                    }
                                }

                                if (
                                    scope1Bunit.findIndex((i) => {
                                        return i.name === item.city;
                                    }) === -1
                                ) {
                                    scope1Bunit.push({
                                        name: item.city,
                                        drilldown: true,
                                        color: colors[0],
                                        y: subitem.gasrefilled,
                                        subset: [[subitem.gastype.name, subitem.gasrefilled]],
                                    });
                                } else {
                                    let ind = scope1Bunit.findIndex((i) => {
                                        return i.name === item.city;
                                    });

                                    let subind = scope1Bunit[ind].subset.findIndex((j) => {
                                        return j[0] === subitem.gastype.name;
                                    });
                                    scope1Bunit[ind].y = scope1Bunit[ind].y + subitem.gasrefilled;
                                    if (subind === -1) {
                                        scope1Bunit[ind].subset.push([subitem.gastype.name, subitem.gasrefilled]);
                                    } else {
                                        scope1Bunit[ind].subset[subind][1] = scope1Bunit[ind].subset[subind][1] + subitem.gasrefilled;
                                    }
                                }
                            } else if (item.topic.includes("Mobile")) {
                                if (
                                    scope1Ctco2.findIndex((i) => {
                                        return i.name === item.city;
                                    }) === -1
                                ) {
                                    scope1Ctco2.push({
                                        name: item.city,
                                        drilldown: true,
                                        color: colors[0],
                                        y: subitem.tco2e,
                                        subset: [[item.month, subitem.tco2e]],
                                    });
                                } else {
                                    let ind = scope1Ctco2.findIndex((i) => {
                                        return i.name === item.city;
                                    });
                                    let subind = scope1Ctco2[ind].subset.findIndex((j) => {
                                        return j[0] === item.month;
                                    });
                                    scope1Ctco2[ind].y = scope1Ctco2[ind].y + subitem.tco2e;
                                    if (subind === -1) {
                                        scope1Ctco2[ind].subset.push([item.month, subitem.tco2e]);
                                    } else {
                                        scope1Ctco2[ind].subset[subind][1] = scope1Ctco2[ind].subset[subind][1] + subitem.tco2e;
                                    }
                                }

                                if (
                                    scope1Cunit.findIndex((i) => {
                                        return i.name === item.country;
                                    }) === -1
                                ) {
                                    scope1Cunit.push({
                                        name: item.country,
                                        drilldown: true,
                                        color: colors[0],
                                        y: subitem.km,
                                        subset: [[item.month, subitem.km]],
                                    });
                                } else {
                                    let ind = scope1Cunit.findIndex((i) => {
                                        return i.name === item.country;
                                    });

                                    let subind = scope1Cunit[ind].subset.findIndex((j) => {
                                        return j[0] === item.month;
                                    });
                                    scope1Cunit[ind].y = scope1Cunit[ind].y + subitem.km;
                                    if (subind === -1) {
                                        scope1Cunit[ind].subset.push([subitem.gastype.name, subitem.km]);
                                    } else {
                                        scope1Cunit[ind].subset[subind][1] = scope1Cunit[ind].subset[subind][1] + subitem.km;
                                    }
                                }
                            } else if (item.topic.includes("Employee")) {
                                if (
                                    scope3Ctco2.findIndex((i) => {
                                        return i.name === item.city;
                                    }) === -1
                                ) {
                                    scope3Ctco2.push({
                                        name: item.city,
                                        drilldown: true,
                                        color: colors[0],
                                        y: subitem.tco2e,
                                        subset: [[item.month, subitem.tco2e]],
                                    });
                                } else {
                                    let ind = scope3Ctco2.findIndex((i) => {
                                        return i.name === item.city;
                                    });
                                    let subind = scope3Ctco2[ind].subset.findIndex((j) => {
                                        return j[0] === item.month;
                                    });
                                    scope3Ctco2[ind].y = scope3Ctco2[ind].y + subitem.tco2e;
                                    if (subind === -1) {
                                        scope3Ctco2[ind].subset.push([item.month, subitem.tco2e]);
                                    } else {
                                        scope3Ctco2[ind].subset[subind][1] = scope3Ctco2[ind].subset[subind][1] + subitem.tco2e;
                                    }
                                }

                                if (
                                    scope3Cunit.findIndex((i) => {
                                        return i.name === item.city;
                                    }) === -1
                                ) {
                                    scope3Cunit.push({
                                        name: item.city,
                                        drilldown: true,
                                        color: colors[0],
                                        y: subitem.km,
                                        subset: [[item.month, subitem.km]],
                                    });
                                } else {
                                    let ind = scope3Cunit.findIndex((i) => {
                                        return i.name === item.city;
                                    });

                                    let subind = scope3Cunit[ind].subset.findIndex((j) => {
                                        return j[0] === item.month;
                                    });
                                    scope3Cunit[ind].y = scope3Cunit[ind].y + subitem.km;
                                    if (subind === -1) {
                                        scope3Cunit[ind].subset.push([subitem.gastype.name, subitem.km]);
                                    } else {
                                        scope3Cunit[ind].subset[subind][1] = scope3Cunit[ind].subset[subind][1] + subitem.km;
                                    }
                                }
                            } else if (item.topic.includes("Purchase")) {
                                console.log(item);
                                if (
                                    scope3Atco2.findIndex((i) => {
                                        return i.name === item.city;
                                    }) === -1
                                ) {
                                    scope3Atco2.push({
                                        name: item.city,
                                        drilldown: true,
                                        color: colors[0],
                                        y: subitem.tco2e,
                                        subset: [[item.month, subitem.tco2e]],
                                    });
                                } else {
                                    let ind = scope3Atco2.findIndex((i) => {
                                        return i.name === item.city;
                                    });
                                    let subind = scope3Atco2[ind].subset.findIndex((j) => {
                                        return j[0] === item.month;
                                    });
                                    scope3Atco2[ind].y = scope3Atco2[ind].y + subitem.tco2e;
                                    if (subind === -1) {
                                        scope3Atco2[ind].subset.push([item.month, subitem.tco2e]);
                                    } else {
                                        scope3Atco2[ind].subset[subind][1] = scope3Atco2[ind].subset[subind][1] + subitem.tco2e;
                                    }
                                }

                                if (
                                    scope3Aunit.findIndex((i) => {
                                        return i.name === item.city;
                                    }) === -1
                                ) {
                                    scope3Aunit.push({
                                        name: item.city,
                                        drilldown: true,
                                        color: colors[0],
                                        y: parseFloat(subitem.spent),
                                        subset: [[item.month, parseFloat(subitem.spent)]],
                                    });
                                } else {
                                    let ind = scope3Aunit.findIndex((i) => {
                                        return i.name === item.city;
                                    });

                                    let subind = scope3Aunit[ind].subset.findIndex((j) => {
                                        return j[0] === item.month;
                                    });
                                    scope3Aunit[ind].y = scope3Aunit[ind].y + parseFloat(subitem.spent);
                                    if (subind === -1) {
                                        scope3Aunit[ind].subset.push([item.month, parseFloat(subitem.spent)]);
                                    } else {
                                        scope3Aunit[ind].subset[subind][1] = scope3Aunit[ind].subset[subind][1] + parseFloat(subitem.spent);
                                    }
                                }
                                if (
                                    scope3Aprocure.findIndex((i) => {
                                        return i.name === item.city;
                                    }) === -1
                                ) {
                                    console.log(subitem);
                                    scope3Aprocure.push({
                                        name: item.city,
                                        drilldown: true,
                                        color: colors[0],
                                        y: parseFloat(subitem.spent),
                                        subset: [[subitem.itemclass.name, parseFloat(subitem.spent)]],
                                    });
                                } else {
                                    let ind = scope3Aprocure.findIndex((i) => {
                                        return i.name === item.city;
                                    });

                                    let subind = scope3Aprocure[ind].subset.findIndex((j) => {
                                        return j[0] === subitem.itemclass.name;
                                    });
                                    scope3Aprocure[ind].y = scope3Aprocure[ind].y + parseFloat(subitem.spent);
                                    if (subind === -1) {
                                        scope3Aprocure[ind].subset.push([subitem.itemclass.name, parseFloat(subitem.spent)]);
                                    } else {
                                        scope3Aprocure[ind].subset[subind][1] = scope3Aprocure[ind].subset[subind][1] + parseFloat(subitem.spent);
                                    }
                                }
                            } else if (item.topic.includes("Busi")) {
                                console.log(subitem);
                                if (
                                    scope3Btco2.findIndex((i) => {
                                        return i.name === item.city;
                                    }) === -1
                                ) {
                                    scope3Btco2.push({
                                        name: item.city,
                                        drilldown: true,
                                        color: colors[0],
                                        y: subitem.tco2e,
                                        subset: [[item.month, subitem.tco2e]],
                                    });
                                } else {
                                    let ind = scope3Btco2.findIndex((i) => {
                                        return i.name === item.city;
                                    });
                                    let subind = scope3Btco2[ind].subset.findIndex((j) => {
                                        return j[0] === item.month;
                                    });
                                    scope3Btco2[ind].y = scope3Btco2[ind].y + subitem.tco2e;
                                    if (subind === -1) {
                                        scope3Btco2[ind].subset.push([item.month, subitem.tco2e]);
                                    } else {
                                        scope3Btco2[ind].subset[subind][1] = scope3Btco2[ind].subset[subind][1] + subitem.tco2e;
                                    }
                                }

                                if (
                                    scope3Bunit.findIndex((i) => {
                                        return i.name === subitem.mode.name;
                                    }) === -1
                                ) {
                                    scope3Bunit.push({
                                        name: subitem.mode.name,
                                        drilldown: true,
                                        color: colors[0],
                                        y: parseFloat(subitem.tco2e),
                                        subset: [[subitem.mode.name, parseFloat(subitem.passenger)]],
                                    });
                                } else {
                                    let ind = scope3Bunit.findIndex((i) => {
                                        return i.name === subitem.mode.name;
                                    });

                                    let subind = scope3Bunit[ind].subset.findIndex((j) => {
                                        return j[0] === subitem.mode.name;
                                    });
                                    scope3Bunit[ind].y = scope3Bunit[ind].y + parseFloat(subitem.tco2e);
                                    if (subind === -1) {
                                        scope3Bunit[ind].subset.push([subitem.mode.name, parseFloat(subitem.passenger)]);
                                    } else {
                                        scope3Bunit[ind].subset[subind][1] = scope3Bunit[ind].subset[subind][1] + parseFloat(subitem.passenger);
                                    }
                                }
                            }

                            scopevalue[item.scope] = scopevalue[item.scope] + subitem.tco2e;
                            total = total + subitem.tco2e;
                        });
                    }

                    if (item.topic === "Stationary Combustion  Emission" || item.topic === "Emissions from Purchased Electricity") {
                        let sindex = scopes.findIndex((i) => {
                            return i.name === item.scope;
                        });

                        if (sindex === -1) {
                            let ss = [];
                            ss.push([item.topic, item.data[0].tco2e]);

                            scopes.push({
                                name: item.scope,
                                drilldown: item.scope.substring(0, item.scope.length - 1) + " " + item.scope.substring(item.scope.length - 1),
                                subset: ss,
                            });
                        } else {
                            let ssindex = -1;
                            scopes[sindex].subset.forEach((i, inn) => {
                                i.forEach((j) => {
                                    if (j === item.topic) {
                                        ssindex = inn;
                                    }
                                });
                            });

                            if (ssindex === -1) {
                                scopes[sindex].subset.push([item.topic, item.data[0].tco2e]);
                            } else {
                                scopes[sindex].subset[ssindex][1] = scopes[sindex].subset[ssindex][1] + item.data[0].tco2e;
                            }
                        }
                    } else {
                        let sindex = scopes.findIndex((i) => {
                            return i.name === item.scope;
                        });

                        if (sindex === -1) {
                            let total2 = 0;
                            item.data.forEach((a) => {
                                total2 = total2 + a.tco2e;
                            });

                            let ss = [];
                            ss.push([item.topic, total2]);
                            scopes.push({
                                name: item.scope,
                                drilldown: item.scope.substring(0, item.scope.length - 1) + " " + item.scope.substring(item.scope.length - 1),
                                subset: ss,
                            });
                        } else {
                            let ssindex = -1;
                            scopes[sindex].subset.forEach((i, inn) => {
                                i.forEach((j) => {
                                    if (j === item.topic) {
                                        ssindex = inn;
                                    }
                                });
                            });
                            if (ssindex === -1) {
                                let total2 = 0;

                                item.data.forEach((a) => {
                                    total2 = total2 + a.tco2e;
                                });
                                scopes[sindex].subset.push([item.topic, total2]);
                            } else {
                                let total2 = 0;
                                item.data.forEach((a) => {
                                    total2 = total2 + a.tco2e;
                                });
                                scopes[sindex].subset[ssindex][1] = scopes[sindex].subset[ssindex][1] + total2;
                            }
                        }
                    }
                }
            });
        });

        scopes[0]["y"] = scopevalue["Scope 1"];
        scopes[2]["y"] = scopevalue["Scope 2"];
        scopes[1]["y"] = scopevalue["Scope 3"];
        let overallbyscope = [];
        scopes.forEach((i) => {
            i.subset.forEach((j) => {
                overallbyscope.push({
                    name: j[0],
                    y: j[1],
                    drilldown: j[0],
                });
            });
        });
        console.log(overallbyscope);
        let obj = {
            overall: scopes,
            scope1: overallbyscope.slice(0, 3),
            scope2: overallbyscope.slice(3, 4),
            scope3: overallbyscope.slice(4, 7),
        };
        if (show) {
            showPieChart(scopes, "overallscope", {
                name: "Emissions Breakdown",
                measure: " t CO2e",
            });
        }
        showPieChartNoAnimation(overallbyscope.slice(0, 3), "overallscope1", {
            name: "Scope 1",
            measure: " %",
        });
        showPieChartNoAnimation(overallbyscope.slice(3, 4), "overallscope2", {
            name: "Scope 2",
            measure: " %",
        });
        showPieChartNoAnimation(overallbyscope.slice(4, 7), "overallscope3", {
            name: "Scope 3",
            measure: " %",
        });
        showBarChart(scope1Aunit, "scope111", {
            name: "Combustion of Fuels (Diesel)",
            measure: " kwh",
        });
        showBarChart(scope1Atco2, "scope112", {
            name: "GHG Emission",
            measure: " tco2e",
        });
        showBarChart(scope1Bunit, "scope121", {
            name: "Refrigerant Refilled",
            measure: " kg",
        });
        showBarChart(scope1Btco2, "scope122", {
            name: "GHG Emission",
            measure: " tco2e",
        });
        showBarChart(scope1Cunit, "scope131", {
            name: "KM Driven",
            measure: " km",
        });
        showBarChart(scope1Ctco2, "scope132", {
            name: "GHG Emission",
            measure: " tco2e",
        });
        showBarChart(scope2Aunit, "scope211", {
            name: "Electricity from Grid",
            measure: " KWH",
        });
        showBarChart(scope2Atco2, "scope212", {
            name: "GHG Emission",
            measure: " tco2e",
        });
        showBarChart(scope3Cunit, "scope331", {
            name: "Distance travelled by employees",
            measure: " KWH",
        });
        showBarChart(scope3Ctco2, "scope332", {
            name: "GHG Emission)",
            measure: " tco2e",
        });
        showBarChart(scope3Aunit, "scope311", {
            name: "Procurement spent",
            measure: " USD",
        });
        showBarChart(scope3Aprocure, "scope312", {
            name: "Procurement Classification",
            measure: " USD",
        });
        showBarChart(scope3Atco2, "scope313", {
            name: "GHG Emission",
            measure: " tco2e",
        });
        showPieChart(scope3Bunit, "scope321", {
            name: "Travel by each mode",
            measure: " t CO2e",
        });
        showBarChart(scope3Btco2, "scope322", {
            name: "GHG Emission",
            measure: " tco2e",
        });
    };
    const localLocation = [
        {
            Ahmedabad: [
                {
                    name: "Gandhinagar",
                },
            ],
            Bengaluru: [
                {
                    name: "Sanjay Nagar",
                },
                {
                    name: "Whitefield",
                },
            ],
            Delhi: [
                {
                    name: "Greater Kailash",
                },
                {
                    name: "DC2",
                },
            ],
            Hyderabad: [
                {
                    name: "DC1",
                },
            ],
            Kolkata: [
                {
                    name: "DC1",
                },
            ],
            Mumbai: [
                {
                    name: "Prabhadevi",
                },
                {
                    name: "Andheri",
                },
                {
                    name: "Mahape",
                },
                {
                    name: "BKC",
                },
            ],
            Noida: [
                {
                    name: "Uttar Pradesh",
                },
            ],
            Pune: [
                {
                    name: "Dighi",
                },
            ],
            Chennai: [
                {
                    name: "Ambattur",
                },
                {
                    name: "DC2",
                },
            ],
            Singapore: [
                {
                    name: "Defu",
                },
                {
                    name: "Loyang",
                },
                {
                    name: "One-North",
                },
                {
                    name: "Tai seng",
                },
                {
                    name: "Jurong",
                },
            ],
            "Metro Manila": [
                {
                    name: "Makati",
                },
                {
                    name: "Mandaluyong",
                },
                {
                    name: "Quezon City",
                },
            ],
            Calabarzon: [
                {
                    name: "General Trias",
                },
            ],
            Davao: [
                {
                    name: "Davao DC1",
                },
            ],
            Bekasi: [
                {
                    name: "Cikaranag DC1",
                },
                {
                    name: "Cikaranag DC2",
                },
                {
                    name: "Cikaranag DC3",
                },
            ],
            Tokyo: [
                {
                    name: "Chiba",
                },
            ],
            Seoul: [
                {
                    name: "Gasan-dong",
                },
                {
                    name: "Anyang",
                },
            ],
            Bangkok: [
                {
                    name: "Hua mak",
                },
            ],
            London: [
                {
                    name: "Slough",
                },
                {
                    name: "Stockley Park",
                },
                {
                    name: "Hayes",
                },
                {
                    name: "Enfield",
                },
            ],
            Shanghai: [
                {
                    name: "DC1",
                },
            ],
            Kunshan: [
                {
                    name: "DC1",
                },
            ],
            Changshu: [
                {
                    name: "DC1",
                },
            ],
            Nantong: [
                {
                    name: "DC1",
                },
            ],
            Wuhan: [
                {
                    name: "DC1",
                },
            ],
            Chengdu: [
                {
                    name: "DC1",
                },
            ],
            Ulanqab: [
                {
                    name: "DC1",
                },
            ],
            Beijing: [
                {
                    name: "DC1",
                },
            ],
            Langfang: [
                {
                    name: "DC1",
                },
            ],
            Hongkong: [
                {
                    name: "DC1",
                },
            ],
            Heyuan: [
                {
                    name: "DC1",
                },
            ],
        },
    ];
    const renderFilter = () => {
        let filters = [];
        data.forEach((i) => {
            let item = i.data[0];
            if (
                (item.year === filter.year.name || filter.year.name === "All") &&
                (item.month === filter.month.name || filter.month.name === "All") &&
                (item.scope === filter.scope.name || filter.scope.name === "All") &&
                (item.city === filter.city.name || filter.city.name === "All") &&
                (item.location === filter.location.name || filter.location.name === "All")
            ) {
                filters.push(i);
            }
        });
        if (filters.length !== 0) {
            updateChart(filters, false);
        } else {
            showNoDataFound("overallscope1", "No Data to show");
            showNoDataFound("overallscope2", "No Data to show");
            showNoDataFound("overallscope3", "No Data to show");
            showNoDataFound("scope111", "No Data to show");
            showNoDataFound("scope112", "No Data to show");
            showNoDataFound("scope121", "No Data to show");
            showNoDataFound("scope122", "No Data to show");
            showNoDataFound("scope131", "No Data to show");
            showNoDataFound("scope132", "No Data to show");
            showNoDataFound("scope211", "No Data to show");
            showNoDataFound("scope212", "No Data to show");
            showNoDataFound("scope311", "No Data to show");
            showNoDataFound("scope312", "No Data to show");
            showNoDataFound("scope313", "No Data to show");
            showNoDataFound("scope321", "No Data to show");
            showNoDataFound("scope322", "No Data to show");
            showNoDataFound("scope331", "No Data to show");
            showNoDataFound("scope332", "No Data to show");
        }
    };
    const btnsLoaded = () => {
        const yearsplit = ["Q1", "Q2", "Q3", "Q4", "FY"];
        yearsplit.forEach((y) => {
            const btn = document.getElementById(y);

            btn.addEventListener("click", (e) => {
                let n = btn.textContent;
                document.querySelectorAll(".buttons button.active").forEach((active) => {
                    active.className = "";
                });
                btn.className = "active";
                if (FY.a === "select" || FY.b === "select") {
                    let label1 = [];
                    let dataset1 = [];
                    if (FY.a !== "select") {
                        let dc = dummyComp;
                        let c = [];
                        let n = [];

                        let btns = document.getElementsByClassName("buttons")[0];
                        for (var h = 0; h < btns.childElementCount; h++) {
                            if (btns.childNodes[h].className === "active") {
                                n = btns.childNodes[h].textContent;
                            }
                        }

                        dc.forEach((a) => {
                            for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                                if (Object.getOwnPropertyNames(a)[k] === "20" + FY.a.slice(-2)) {
                                    a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                        if (n === "Q1") {
                                            // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                            if (ind === 3 || ind === 4 || ind === 5) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q2") {
                                            if (ind === 6 || ind === 7 || ind === 8) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q3") {
                                            if (ind === 9 || ind === 10 || ind === 11) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q4") {
                                            if (ind === 0 || ind === 1 || ind === 2) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "FY") {
                                            label1.push(b.month);
                                            dataset1.push(b.value);
                                            c.push({
                                                year: Object.getOwnPropertyNames(a)[k],
                                                month: b.month,
                                                value: b.value,
                                            });
                                        }
                                    });
                                }
                            }
                        });
                        let data = [
                            {
                                data: dataset1,
                                label: FY.a,
                                backgroundColor: [targetBarColor[0], targetBarColor[1], targetBarColor[2], targetBarColor[3], targetBarColor[4], targetBarColor[5], targetBarColor[6], targetBarColor[7], targetBarColor[8], targetBarColor[9], targetBarColor[10], targetBarColor[11]],
                                borderColor: [targetBarColorBR[0], targetBarColorBR[1], targetBarColorBR[2], targetBarColorBR[3], targetBarColorBR[4], targetBarColorBR[5], targetBarColorBR[6], targetBarColorBR[7], targetBarColorBR[8], targetBarColorBR[9], targetBarColorBR[10], targetBarColorBR[11]],
                            },
                        ];
                        showGroupBarChart(data, "compbarchart", {
                            label1,
                            measure: " t CO2e",
                            display: false,
                        });
                        setComp(c);
                    } else if (FY.b !== "select") {
                        let dc = dummyComp;
                        let c = [];
                        let n = [];

                        let btns = document.getElementsByClassName("buttons")[0];
                        for (var h = 0; h < btns.childElementCount; h++) {
                            if (btns.childNodes[h].className === "active") {
                                n = btns.childNodes[h].textContent;
                            }
                        }

                        dc.forEach((a) => {
                            for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                                if (Object.getOwnPropertyNames(a)[k] === "20" + FY.b.slice(-2)) {
                                    a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                        if (n === "Q1") {
                                            // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                            if (ind === 3 || ind === 4 || ind === 5) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q2") {
                                            if (ind === 6 || ind === 7 || ind === 8) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q3") {
                                            if (ind === 9 || ind === 10 || ind === 11) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q4") {
                                            if (ind === 0 || ind === 1 || ind === 2) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "FY") {
                                            label1.push(b.month);
                                            dataset1.push(b.value);
                                            c.push({
                                                year: Object.getOwnPropertyNames(a)[k],
                                                month: b.month,
                                                value: b.value,
                                            });
                                        }
                                    });
                                }
                            }
                        });
                        let data = [
                            {
                                data: dataset1,
                                label: FY.b,
                                backgroundColor: [targetBarColor[0], targetBarColor[1], targetBarColor[2], targetBarColor[3], targetBarColor[4], targetBarColor[5], targetBarColor[6], targetBarColor[7], targetBarColor[8], targetBarColor[9], targetBarColor[10], targetBarColor[11]],
                                borderColor: [targetBarColorBR[0], targetBarColorBR[1], targetBarColorBR[2], targetBarColorBR[3], targetBarColorBR[4], targetBarColorBR[5], targetBarColorBR[6], targetBarColorBR[7], targetBarColorBR[8], targetBarColorBR[9], targetBarColorBR[10], targetBarColorBR[11]],
                            },
                        ];
                        showGroupBarChart(data, "compbarchart", {
                            label1,
                            measure: " t CO2e",
                            display: false,
                        });
                        setComp(c);
                    } else if (FY.a === FY.b) {
                        let dc = dummyComp;
                        let c = [];
                        let n = [];

                        let btns = document.getElementsByClassName("buttons")[0];
                        for (var h = 0; h < btns.childElementCount; h++) {
                            if (btns.childNodes[h].className === "active") {
                                n = btns.childNodes[h].textContent;
                            }
                        }

                        dc.forEach((a) => {
                            for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                                if (Object.getOwnPropertyNames(a)[k] === "2019") {
                                    a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                        if (n === "Q1") {
                                            // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                            if (ind === 3 || ind === 4 || ind === 5) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q2") {
                                            if (ind === 6 || ind === 7 || ind === 8) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q3") {
                                            if (ind === 9 || ind === 10 || ind === 11) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q4") {
                                            if (ind === 0 || ind === 1 || ind === 2) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "FY") {
                                            label1.push(b.month);
                                            dataset1.push(b.value);
                                            c.push({
                                                year: Object.getOwnPropertyNames(a)[k],
                                                month: b.month,
                                                value: b.value,
                                            });
                                        }
                                    });
                                }
                            }
                        });
                        let data = [
                            {
                                data: dataset1,
                                label: FY.a === "select" ? "FY19" : FY.a,
                                backgroundColor: [targetBarColor[0], targetBarColor[1], targetBarColor[2], targetBarColor[3], targetBarColor[4], targetBarColor[5], targetBarColor[6], targetBarColor[7], targetBarColor[8], targetBarColor[9], targetBarColor[10], targetBarColor[11]],
                                borderColor: [targetBarColorBR[0], targetBarColorBR[1], targetBarColorBR[2], targetBarColorBR[3], targetBarColorBR[4], targetBarColorBR[5], targetBarColorBR[6], targetBarColorBR[7], targetBarColorBR[8], targetBarColorBR[9], targetBarColorBR[10], targetBarColorBR[11]],
                            },
                        ];
                        showGroupBarChart(data, "compbarchart", {
                            label1,
                            measure: " t CO2e",
                            display: false,
                        });
                        setComp(c);
                    }
                } else {
                    let label1 = [];
                    let dataset1 = [];

                    let dataset2 = [];
                    if (FY.a !== FY.b) {
                        let dc = dummyComp;
                        let c = [];
                        let n = [];

                        let btns = document.getElementsByClassName("buttons")[0];
                        for (var h = 0; h < btns.childElementCount; h++) {
                            if (btns.childNodes[h].className === "active") {
                                n = btns.childNodes[h].textContent;
                            }
                        }

                        dc.forEach((a) => {
                            for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                                if (Object.getOwnPropertyNames(a)[k] === "20" + FY.a.slice(-2)) {
                                    a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                        if (n === "Q1") {
                                            // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                            if (ind === 3 || ind === 4 || ind === 5) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q2") {
                                            if (ind === 6 || ind === 7 || ind === 8) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q3") {
                                            if (ind === 9 || ind === 10 || ind === 11) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q4") {
                                            if (ind === 0 || ind === 1 || ind === 2) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "FY") {
                                            label1.push(b.month);
                                            dataset1.push(b.value);
                                            c.push({
                                                year: Object.getOwnPropertyNames(a)[k],
                                                month: b.month,
                                                value: b.value,
                                            });
                                        }
                                    });
                                } else if (Object.getOwnPropertyNames(a)[k] === "20" + FY.b.slice(-2)) {
                                    a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                        if (n === "Q1") {
                                            // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                            if (ind === 3 || ind === 4 || ind === 5) {
                                                dataset2.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q2") {
                                            if (ind === 6 || ind === 7 || ind === 8) {
                                                dataset2.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q3") {
                                            if (ind === 9 || ind === 10 || ind === 11) {
                                                dataset2.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q4") {
                                            if (ind === 0 || ind === 1 || ind === 2) {
                                                dataset2.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "FY") {
                                            dataset2.push(b.value);
                                            c.push({
                                                year: Object.getOwnPropertyNames(a)[k],
                                                month: b.month,
                                                value: b.value,
                                            });
                                        }
                                    });
                                }
                            }
                        });
                        let data = [
                            {
                                data: dataset1,
                                label: FY.a,
                                backgroundColor: [pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0], pieColor[0]],
                                borderColor: [colorsBR[0], colorsBR[1], colorsBR[2], colorsBR[3], colorsBR[4], colorsBR[5], colorsBR[6], colorsBR[7], colorsBR[8], colorsBR[9], colorsBR[10], colorsBR[11]],
                            },
                            {
                                data: dataset2,
                                label: FY.b,
                                backgroundColor: [pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1], pieColor[1]],
                                borderColor: [colorsBR[0], colorsBR[1], colorsBR[2], colorsBR[3], colorsBR[4], colorsBR[5], colorsBR[6], colorsBR[7], colorsBR[8], colorsBR[9], colorsBR[10], colorsBR[11]],
                            },
                        ];
                        showGroupBarChart(data, "compbarchart", {
                            label1,
                            measure: " t CO2e",
                            display: true,
                        });
                        setComp(c);
                    } else {
                        let dc = dummyComp;
                        let c = [];
                        let n = [];

                        let btns = document.getElementsByClassName("buttons")[0];
                        for (var h = 0; h < btns.childElementCount; h++) {
                            if (btns.childNodes[h].className === "active") {
                                n = btns.childNodes[h].textContent;
                            }
                        }

                        dc.forEach((a) => {
                            for (var k = 0; k < Object.getOwnPropertyNames(a).length; k++) {
                                if (Object.getOwnPropertyNames(a)[k] === "2019") {
                                    a[Object.getOwnPropertyNames(a)[k]].forEach((b, ind) => {
                                        if (n === "Q1") {
                                            // Q1 April to June Q2 Jul to Sep Q3 Oct to Dec Q4 Jan to Mar

                                            if (ind === 3 || ind === 4 || ind === 5) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q2") {
                                            if (ind === 6 || ind === 7 || ind === 8) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q3") {
                                            if (ind === 9 || ind === 10 || ind === 11) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "Q4") {
                                            if (ind === 0 || ind === 1 || ind === 2) {
                                                label1.push(b.month);
                                                dataset1.push(b.value);
                                                c.push({
                                                    year: Object.getOwnPropertyNames(a)[k],
                                                    month: b.month,
                                                    value: b.value,
                                                });
                                            }
                                        } else if (n === "FY") {
                                            label1.push(b.month);
                                            dataset1.push(b.value);
                                            c.push({
                                                year: Object.getOwnPropertyNames(a)[k],
                                                month: b.month,
                                                value: b.value,
                                            });
                                        }
                                    });
                                }
                            }
                        });
                        let data = [
                            {
                                data: dataset1,
                                label: FY.a,
                                backgroundColor: [targetBarColor[0], targetBarColor[1], targetBarColor[2], targetBarColor[3], targetBarColor[4], targetBarColor[5], targetBarColor[6], targetBarColor[7], targetBarColor[8], targetBarColor[9], targetBarColor[10], targetBarColor[11]],
                                borderColor: [targetBarColorBR[0], targetBarColorBR[1], targetBarColorBR[2], targetBarColorBR[3], targetBarColorBR[4], targetBarColorBR[5], targetBarColorBR[6], targetBarColorBR[7], targetBarColorBR[8], targetBarColorBR[9], targetBarColorBR[10], targetBarColorBR[11]],
                            },
                        ];
                        showGroupBarChart(data, "compbarchart", {
                            label1,
                            measure: " t CO2e",
                            display: false,
                        });
                        setComp(c);
                    }
                }
            });
        });
    };
    const onFilterChanged = (obj, val) => {
        let fil = filter;
        fil[obj] = val;
        if (obj === "country") {
            if (val.name === "All") {
                let loc = [
                    {
                        name: "All",
                    },
                ];
                rawcity.forEach((item) => {
                    loc = [
                        ...loc,
                        ...item[Object.keys(item)[0]].filter((k) => {
                            return k.name !== "All";
                        }),
                    ];
                });
                fil["location"] = {
                    name: "All",
                };
                fil["city"] = {
                    name: "All",
                };
                setCity(loc);
                setLocation([]);
            } else {
                let index = rawcity.findIndex((i) => {
                    return Object.keys(i)[0] === val.name;
                });
                if (
                    rawcity[index][val.name].findIndex((i) => {
                        return i.name === "All";
                    }) === -1
                ) {
                    rawcity[index][val.name].unshift({
                        name: "All",
                    });
                }

                setCity(rawcity[index][val.name]);
                fil["location"] = {
                    name: "All",
                };
                fil["city"] = {
                    name: "All",
                };
                setLocation([]);
            }
        }
        if (obj === "city") {
            if (val.name === "All") {
                let loc = [
                    {
                        name: "All",
                    },
                ];
                rawlocation.forEach((item) => {
                    loc = [
                        ...loc,
                        ...item[Object.keys(item)[0]].filter((k) => {
                            return k.name !== "All";
                        }),
                    ];
                });
                fil["location"] = {
                    name: "All",
                };
                setLocation(loc);
            } else {
                let index = rawlocation.findIndex((i) => {
                    return Object.keys(i)[0] === val.name;
                });
                if (
                    rawlocation[index][val.name].findIndex((i) => {
                        return i.name === "All";
                    }) === -1
                ) {
                    rawlocation[index][val.name].unshift({
                        name: "All",
                    });
                }
                setLocation(rawlocation[index][val.name]);
            }
            fil["location"] = {
                name: "All",
            };
        }
        if (obj === "location") {
        }
        if (obj === "year") {
            let locmonth = [
                {
                    name: "All",
                },
            ];
            data.forEach((item) => {
                console.log(item);
                if (item.data[0].year === val.name || val.name === "All") {
                    if (
                        locmonth.findIndex((i) => {
                            return i.name === item.data[0].month;
                        }) === -1
                    ) {
                        locmonth.push({
                            name: item.data[0].month,
                        });
                    }
                }
            });
            setMonth(locmonth);
        }
        setFilter(fil);
        setTimeout(() => {
            renderFilter();
        }, 1000);

        forceUpdate();
    };
    const freezeFilter = () => {
        let stickyElem = document.querySelector(".sticky-div");
        let currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
        window.onscroll = function () {
            /* Check if the current Y offset
            is greater than the position of
            the element */
            if (window.pageYOffset > currStickyPos) {
                stickyElem.style.position = "fixed";
                stickyElem.style.top = "60px";
                stickyElem.style.zIndex = 9;

                stickyElem.style.width = "73.5%";
                stickyElem.style.justifyContent = "center";
            } else {
                stickyElem.style.position = "relative";
                stickyElem.style.top = "initial";
                stickyElem.style.width = "100%";
            }
        };
    };
    const indicatorTemplate = (rowData) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {rowData.indicator === 1 ? (
                    <i
                        style={{
                            transform: "rotate(0deg)",
                            color: "red",
                            fontSize: 20,
                            borderRadius: 12,
                            border: "1px solid black",
                        }}
                        className="pi pi-circle-fill"
                    />
                ) : rowData.indicator === 2 ? (
                    <i
                        style={{
                            transform: "rotate(0deg)",
                            color: "yellow",
                            fontSize: 20,
                            borderRadius: 12,
                            border: "1px solid black",
                        }}
                        className="pi pi-circle-fill"
                    />
                ) : (
                    <i
                        style={{
                            transform: "rotate(0deg)",
                            color: "green",
                            fontSize: 20,
                            borderRadius: 12,
                            border: "1px solid black",
                        }}
                        className="pi pi-circle-fill"
                    />
                )}
            </div>
        );
    };

    return (
        <div>
            <div className="grid">
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3"> Target </span> <div className="text-900 font-medium text-xl"> 152 </div>
                            </div>
                            <div
                                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                }}
                            >
                                <i className="pi pi-shopping-cart text-blue-500 text-xl" />
                            </div>
                        </div>
                        {/* <span className="text-green-500 font-medium">24 new </span> */} <span className="text-500"> of tCo2e </span>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3"> Progress </span> <div className="text-900 font-medium text-xl"> 45 % </div>
                            </div>
                            <div
                                className="flex align-items-center justify-content-center bg-orange-100 border-round"
                                style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                }}
                            >
                                <i className="pi pi-map-marker text-orange-500 text-xl" />
                            </div>
                        </div>
                        {/* <span className="text-green-500 font-medium">%52+ </span> */} <span className="text-500"> of tCo2e </span>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <marquee className="block text-500 font-medium mb-3"> Total Emission in this month </marquee> <div className="text-900 font-medium text-xl"> 706 </div>
                            </div>
                            <div
                                className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                }}
                            >
                                <i className="pi pi-inbox text-cyan-500 text-xl" />
                            </div>
                        </div>
                        {/* <span className="text-green-500 font-medium">520  </span> */}
                        <span className="text-500"> of tCo2e </span>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <marquee className="block text-500 font-medium mb-3"> Total Emissions in current FY </marquee> <div className="text-900 font-medium text-xl"> 7, 425 </div>
                            </div>
                            <div
                                className="flex align-items-center justify-content-center bg-purple-100 border-round"
                                style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                }}
                            >
                                <i className="pi pi-comment text-purple-500 text-xl" />
                            </div>
                        </div>
                        {/* <span className="text-green-500 font-medium">85 </span> */} <span className="text-500"> of tCo2e </span>
                    </div>
                </div>
                {/* "Fugitive Emissions"
                            "Mobile Combustion Emissions" */}
            </div>
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Target & Progress
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <h5> Scope 1 + 2 </h5> <canvas id="targetchart1" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <h5> Scope 3 </h5> <canvas id="targetchart2" />
                        </div>
                    </div>
                </div>
                <DataTable
                    value={products}
                    style={{
                        height: 270,
                    }}
                    header="Process Indicator"
                    showGridlines
                    responsiveLayout="scroll"
                >
                    <Column field="id" header="ID">

                    </Column>
                    <Column field="action" header="Identified Initiatives">

                    </Column>
                    <Column field="status" header="Status">

                    </Column>
                    <Column field="indicator" body={indicatorTemplate} header="Indicator">

                    </Column>
                </DataTable>
            </div>
            <div className="col-12 sticky-div">
                <div className="card">
                    <div
                        style={{
                            marginBottom: 20,
                        }}
                    >
                        <h5> Filter Section </h5>
                    </div>
                    <div className="grid">
                        <div
                            style={{
                                width: "20%",
                            }}
                        >
                            <h6> Country </h6>
                            <Dropdown
                                style={{
                                    width: "90%",
                                }}
                                value={filter.country}
                                onChange={(e) => onFilterChanged("country", e.value)}
                                options={country}
                                optionLabel="name"
                                placeholder="Select"
                            />
                        </div>
                        <div
                            style={{
                                width: "20%",
                            }}
                        >
                            <h6> City </h6>
                            <Dropdown
                                style={{
                                    width: "90%",
                                }}
                                value={filter.city}
                                onChange={(e) => onFilterChanged("city", e.value)}
                                options={city}
                                optionLabel="name"
                                placeholder="Select"
                            />
                        </div>
                        <div
                            style={{
                                width: "20%",
                            }}
                        >
                            <h6> Location </h6>
                            <Dropdown
                                style={{
                                    width: "90%",
                                }}
                                value={filter.location}
                                onChange={(e) => onFilterChanged("location", e.value)}
                                options={location}
                                optionLabel="name"
                                placeholder="Select"
                            />
                        </div>
                        <div
                            style={{
                                width: "20%",
                            }}
                        >
                            <h6> Year </h6>
                            <Dropdown
                                style={{
                                    width: "90%",
                                }}
                                value={filter.year}
                                onChange={(e) => onFilterChanged("year", e.value)}
                                options={year}
                                optionLabel="name"
                                placeholder="Select"
                            />
                        </div>
                        <div
                            style={{
                                width: "20%",
                            }}
                        >
                            <h6> Month </h6>
                            <Dropdown
                                style={{
                                    width: "90%",
                                }}
                                value={filter.month}
                                onChange={(e) => onFilterChanged("month", e.value)}
                                options={month}
                                optionLabel="name"
                                placeholder="Select"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Emission Summary & Comparision
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div
                            className="card"
                            style={{
                                borderRadius: "20px",
                            }}
                        >
                            <div>
                                <div
                                    class="row"
                                    style={{
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    <div
                                        class="buttons"
                                        style={{
                                            borderRadius: "10px",
                                            padding: "10px",
                                        }}
                                    >
                                        <button className="active" id="Q1">
                                            Q1
                                        </button>
                                        <button id="Q2">Q2 </button> <button id="Q3">Q3 </button> <button id="Q4">Q4 </button> <button id="FY">FY </button>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            borderRadius: "10px",
                                            padding: "10px",
                                        }}
                                    >
                                        <h5
                                            style={{
                                                marginBottom: "0px",
                                            }}
                                        >

                                            Select FY1
                                        </h5>
                                        &nbsp; &nbsp;
                                        <select
                                            onChange={(a) => {
                                                setCompByFY(a.target.value, "a");
                                            }}
                                        >
                                            <option> select </option> <option selected={true}> FY19 </option> <option> FY20 </option>
                                        </select>
                                        &nbsp; &nbsp; &nbsp;
                                        <h5
                                            style={{
                                                marginBottom: "0px",
                                                marginTop: "0px",
                                            }}
                                        >

                                            Select FY2
                                        </h5>
                                        & nbsp; & nbsp;
                                        <select
                                            onChange={(a) => {
                                                setCompByFY(a.target.value, "b");
                                            }}
                                        >
                                            <option> select </option> <option> FY19 </option> <option> FY20 </option>
                                        </select>
                                    </div>
                                </div>
                                {/* <HighchartsReact highcharts={Highcharts} ref={compchart} options={comparision} /> */}
                                <div
                                    style={{
                                        marginTop: 20,
                                    }}
                                >
                                    <canvas id="compbarchart" /> {/* <Column height={hcw} {...config} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <h5> Emissions Breakdown </h5> <canvas id="overallscope" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Emission breakup by scope
                </div>
                <div className="grid">
                    <div className="col-4">
                        <div className="card">
                            <h5> Scope 1 Emissions </h5> <canvas id="overallscope1" />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card">
                            <h5> Scope 2 Emissions </h5> <canvas id="overallscope2" />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card">
                            <h5> Scope 3 Emissions </h5> <canvas id="overallscope3" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Scope 1 Emission Breakdown
                </div>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Emission due to Stationary Combustion of fuel
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: 10,
                                    }}
                                >
                                    <text
                                        onClick={() => {
                                            setScope111PO(!scope111PO);
                                            let ref = JSON.parse(JSON.stringify(reference))[0];
                                            let ss = {
                                                GRI: ref["GRI"].splice(0, 1),
                                                CDP: ref["CDP"].splice(0, 1),
                                            };
                                            setUnitShow(true);
                                            setDescription(ss);
                                            forceUpdate();
                                        }}
                                        style={{
                                            textDecoration: "underline",
                                        }}
                                    >

                                        Reference
                                    </text>
                                </div>
                            </div>

                            <canvas id="scope111" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: 10,
                                    }}
                                >
                                    <text
                                        onClick={() => {
                                            setScope111PO(!scope111PO);
                                            let ref = JSON.parse(JSON.stringify(reference))[0];
                                            let ss = {
                                                GRI: ref["GRI"].splice(0, 1),
                                                CDP: ref["CDP"].splice(0, 1),
                                            };
                                            setUnitShow(false);
                                            setDescription(ss);
                                            forceUpdate();
                                        }}
                                        style={{
                                            textDecoration: "underline",
                                        }}
                                    >

                                        Reference
                                    </text>
                                </div>
                            </div>
                            <canvas id="scope112" />
                        </div>
                    </div>
                </div>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Emission due to Fugitive
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <canvas id="scope121" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: 10,
                                    }}
                                >
                                    <text
                                        onClick={() => {
                                            setScope111PO(!scope111PO);
                                            let ref = JSON.parse(JSON.stringify(reference))[0];
                                            let ss = {
                                                GRI: ref["GRI"].splice(0, 1),
                                                CDP: ref["CDP"].splice(0, 1),
                                            };
                                            setUnitShow(false);
                                            setDescription(ss);
                                            forceUpdate();
                                        }}
                                        style={{
                                            textDecoration: "underline",
                                        }}
                                    >

                                        Reference
                                    </text>
                                </div>
                            </div>
                            <canvas id="scope122" />
                        </div>
                    </div>
                </div>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Emission due to Mobile Combustion
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <canvas id="scope131" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: 10,
                                    }}
                                >
                                    <text
                                        onClick={() => {
                                            setScope111PO(!scope111PO);
                                            let ref = JSON.parse(JSON.stringify(reference))[0];
                                            let ss = {
                                                GRI: ref["GRI"].splice(0, 1),
                                                CDP: ref["CDP"].splice(0, 1),
                                            };
                                            setUnitShow(false);
                                            setDescription(ss);
                                            forceUpdate();
                                        }}
                                        style={{
                                            textDecoration: "underline",
                                        }}
                                    >

                                        Reference
                                    </text>
                                </div>
                            </div>
                            <canvas id="scope132" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Scope 2 Emission Breakdown
                </div>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Purchased Electricity(Grid)
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: 10,
                                    }}
                                >
                                    <text
                                        onClick={() => {
                                            setScope111PO(!scope111PO);
                                            let ref = JSON.parse(JSON.stringify(reference))[0];
                                            let ss = {
                                                GRI: ref["GRI"].splice(1, 1),
                                                CDP: ref["CDP"].splice(1, 1),
                                            };
                                            setUnitShow(true);
                                            setDescription(ss);
                                            forceUpdate();
                                        }}
                                        style={{
                                            textDecoration: "underline",
                                        }}
                                    >

                                        Reference
                                    </text>
                                </div>
                            </div>
                            <canvas id="scope211" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: 10,
                                    }}
                                >
                                    <text
                                        onClick={() => {
                                            setScope111PO(!scope111PO);
                                            let ref = JSON.parse(JSON.stringify(reference))[0];
                                            let ss = {
                                                GRI: ref["GRI"].splice(1, 1),
                                                CDP: ref["CDP"].splice(1, 1),
                                            };
                                            setUnitShow(false);
                                            setDescription(ss);
                                            forceUpdate();
                                        }}
                                        style={{
                                            textDecoration: "underline",
                                        }}
                                    >

                                        Reference
                                    </text>
                                </div>
                            </div>
                            <canvas id="scope212" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Scope 3 Emission Breakdown
                </div>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Purchased Goods & Services
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <canvas id="scope311" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <canvas id="scope312" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <canvas id="scope313" />
                        </div>
                    </div>
                </div>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Business Travel
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div
                            className="card"
                            style={{
                                maxHeight: "364",
                            }}
                        >
                            <canvas id="scope321" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <canvas id="scope322" />
                        </div>
                    </div>
                </div>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    Employee Commutte
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <canvas id="scope331" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <canvas id="scope332" />
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                visible={scope111PO}
                style={{
                    width: "30%",
                }}
                header="Reference"
                modal
                onHide={() => {
                    setScope111PO(false);
                }}
            >
                <div>
                    <div className="col-12 lg:col-12">

                        {Object.keys(description).length !== 0 &&
                            Object.keys(description).map((item) => {
                                console.log(description[item]);
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <text> {item} </text>
                                        <>

                                            {description[item][0].map((item2) => {
                                                return (
                                                    <>

                                                        {unitshow === item2.unit && (
                                                            <div
                                                                style={{
                                                                    margin: 10,
                                                                }}
                                                            >
                                                                <text
                                                                    style={{
                                                                        background: "rgb(49, 89, 117)",
                                                                        color: "white",
                                                                        padding: 8,
                                                                        borderRadius: 10,
                                                                        margin: 10,
                                                                    }}
                                                                    data-tip={item2.description}
                                                                >

                                                                    {item2.standard}
                                                                </text>

                                                                <ReactTooltip place="bottom" />
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(ClimateChangeDB, comparisonFn);
