import React from "react";

const Testing = () => {
    let fg_array = [], pgas_array = [], sc_array = []
    let fg = [
        {
            "id": 50,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 3,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-02-02T06:21:53.617Z",
                    "DPA0135": "Voltas 2",
                    "DPA0136": 19,
                    "DPA0138": "10.5",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683008541285D1D.png",
                            "name": "1683008541285D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 291166904784
                },
                {
                    "DPA0135": "Voltas",
                    "DPA0136": 17,
                    "DPA0137": "2023-02-04T00:00:00.000Z",
                    "id": 555372047852,
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683008541285D1D.png",
                            "name": "1683008541285D1D.png",
                            "size": 32765
                        }
                    ],
                    "DPA0138": "100"
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-03-29T14:25:16.723Z",
            "modified_on": "2023-05-02T06:22:26.739Z",
            "data2": "Nothing correct",
            "approved_on": "2023-05-02T06:24:26.008Z",
            "rejected_on": "2023-03-29T14:26:41.223Z",
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 9,
            "approved_by": 11,
            "userProfileId": 17
        },
        {
            "id": 139,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 34,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-01-31T16:00:00.000Z",
                    "DPA0135": "Reception Split A/C",
                    "DPA0136": 18,
                    "DPA0138": "45",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680243930184image_2023-03-30_11-58-07.png",
                    "id": 32073215965
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-03-31T06:25:57.692Z",
            "modified_on": "2023-03-31T08:27:21.481Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 48,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 232,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "04-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-04-14T18:30:00.000Z",
                    "DPA0135": "DUCTLESS, MINI-SPLIT AIR CONDITIONER",
                    "DPA0136": 142,
                    "DPA0138": "12",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680622726090BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 697990679306
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-04T15:40:12.291Z",
            "modified_on": "2023-04-10T04:45:29.800Z",
            "data2": null,
            "approved_on": "2023-06-19T07:45:44.863Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 39,
            "approved_by": 37,
            "userProfileId": 28
        },
        {
            "id": 233,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "05-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-05-16T18:30:00.000Z",
                    "DPA0135": "CENTRAL AIR CONDITIONING",
                    "DPA0136": 46,
                    "DPA0138": "5",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680622938453BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 1606332423299
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-04T15:42:38.049Z",
            "modified_on": "2023-04-10T04:45:58.680Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 234,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "06-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-06-06T18:30:00.000Z",
                    "DPA0135": "CENTRAL AIR CONDITIONING",
                    "DPA0136": 16,
                    "DPA0138": "6",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680623083908BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 1225345508085
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-04T15:44:56.094Z",
            "modified_on": "2023-04-10T04:46:07.448Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 235,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "07-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-07-15T18:30:00.000Z",
                    "DPA0135": "DUCTLESS, MINI-SPLIT AIR CONDITIONER",
                    "DPA0136": 17,
                    "DPA0138": "10",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680623203178BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 407120583742
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-04T15:46:49.046Z",
            "modified_on": "2023-04-10T04:46:28.600Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 236,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "08-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-08-05T18:30:00.000Z",
                    "DPA0135": "DUCTLESS, MINI-SPLIT AIR CONDITIONER",
                    "DPA0136": 142,
                    "DPA0138": "11",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680623444413BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 869584518571
                },
                {
                    "DPA0137": "2021-08-08T18:30:00.000Z",
                    "DPA0135": "CENTRAL AIR CONDITIONING",
                    "DPA0136": 66,
                    "DPA0138": "8",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680623384768BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 1650959069573
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-04T15:50:50.715Z",
            "modified_on": "2023-04-10T04:46:44.175Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 237,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-04-04T15:56:09.662Z",
            "modified_on": "2023-04-10T04:47:44.049Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 238,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "10-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-09-30T18:30:00.000Z",
                    "DPA0135": "CENTRAL AIR CONDITIONING",
                    "DPA0136": 17,
                    "DPA0138": "15",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680626400218BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 1080801288801
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-04T16:40:08.791Z",
            "modified_on": "2023-04-10T04:48:13.850Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 251,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "11-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-04-04T17:57:36.565Z",
            "modified_on": "2023-04-10T04:48:27.137Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 252,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "12-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-12-01T18:30:00.000Z",
                    "DPA0135": "Ductless Mini-Split",
                    "DPA0136": 142,
                    "DPA0138": "8",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680631142308BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 1342104195954
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-04T17:59:07.632Z",
            "modified_on": "2023-04-10T04:48:52.219Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 253,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "01-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-01-04T18:30:00.000Z",
                    "DPA0135": "Central Air Conditioner",
                    "DPA0136": 58,
                    "DPA0138": "10",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680631299083BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 727209302591
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-04T18:01:44.875Z",
            "modified_on": "2023-04-10T04:48:38.559Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 254,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "02-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-02-03T18:30:00.000Z",
                    "DPA0135": "Central Air Conditioner",
                    "DPA0136": 16,
                    "DPA0138": "7",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680631424728BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 217061120382
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-04T18:03:51.013Z",
            "modified_on": "2023-04-10T04:49:12.763Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 255,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-04-04T18:08:53.983Z",
            "modified_on": "2023-04-10T04:49:44.521Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 267,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-04-03T18:30:00.000Z",
                    "DPA0135": "Chiller",
                    "DPA0136": 143,
                    "DPA0138": "10",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680673785356BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 137006036717
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-05T05:49:49.091Z",
            "modified_on": "2023-04-10T04:50:04.808Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 268,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-05-10T18:30:00.000Z",
                    "DPA0135": "Freezer",
                    "DPA0136": 131,
                    "DPA0138": "15",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680673901486BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 1165012765831
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-05T05:51:46.075Z",
            "modified_on": "2023-04-10T04:50:25.800Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 270,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-06-08T18:30:00.000Z",
                    "DPA0135": "Freezer",
                    "DPA0136": 170,
                    "DPA0138": "7",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680674272602BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 1615398461162
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-05T05:57:56.364Z",
            "modified_on": "2023-04-10T04:51:13.436Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 271,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "07-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-04-05T05:59:53.330Z",
            "modified_on": "2023-04-10T04:51:49.912Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 272,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "08-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-08-03T18:30:00.000Z",
                    "DPA0135": "Scroll Compressor Chillers",
                    "DPA0136": 27,
                    "DPA0138": "16",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680674559204BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 809959063986
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-05T06:02:41.963Z",
            "modified_on": "2023-04-10T04:52:08.844Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 273,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-02-07T18:30:00.000Z",
                    "DPA0135": "Split Air- Conditioner",
                    "DPA0136": 58,
                    "DPA0138": "10",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680674615095BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 356783094827
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-05T06:03:53.987Z",
            "modified_on": "2023-04-10T04:53:35.028Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 274,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-01-09T18:30:00.000Z",
                    "DPA0135": "Split Air-Conditioner",
                    "DPA0136": 65,
                    "DPA0138": "5",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680674704865BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 1205603556879
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-05T06:05:09.190Z",
            "modified_on": "2023-04-10T04:53:20.815Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 276,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "12-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-12-05T18:30:00.000Z",
                    "DPA0135": "Air cooled chiller",
                    "DPA0136": 152,
                    "DPA0138": "12",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680674861244BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 1083990615909
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-05T06:08:03.742Z",
            "modified_on": "2023-04-10T04:53:08.408Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 277,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "11-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-11-16T18:30:00.000Z",
                    "DPA0135": "Water cooled chiller",
                    "DPA0136": 112,
                    "DPA0138": "6",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680674948062BIDONES-texto-fondo-blanco-835x445.png",
                    "id": 571411962541
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-05T06:09:10.844Z",
            "modified_on": "2023-04-10T04:52:55.217Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 330,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 34,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-04-12T14:00:57.461Z",
                    "DPA0135": "Central AC",
                    "DPA0136": 17,
                    "DPA0138": "100",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1681308095877image.png",
                            "name": "1681308095877image.png"
                        }
                    ],
                    "id": 1046587031643
                },
                {
                    "DPA0137": "2023-04-06T08:40:20.218Z",
                    "DPA0135": "Central A.C for building",
                    "DPA0136": 17,
                    "DPA0138": "50",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1680770447231ISO-14001-2015-slider.jpg",
                            "name": "1680770447231ISO-14001-2015-slider.jpg"
                        }
                    ],
                    "id": 672556099148
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-06T08:41:04.561Z",
            "modified_on": "2023-04-12T14:02:34.690Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 48,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 334,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-04-10T04:52:24.736Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 335,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "10-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-04-10T04:52:40.168Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 348,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 27,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-03-01T18:30:00.000Z",
                    "DPA0135": "Ductless Air Conditioner",
                    "DPA0136": 142,
                    "DPA0138": "10",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1681208793562Types-Of-Refrigerants.jpg",
                            "name": "1681208793562Types-Of-Refrigerants.jpg"
                        }
                    ],
                    "id": 490749510199
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-11T10:27:22.416Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 39,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 378,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 3,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-01-03T05:29:25.557Z",
                    "DPA0135": "iGeneral",
                    "DPA0136": 146,
                    "DPA0138": "32",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683091837679D1D.png",
                            "name": "1683091837679D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 390965016623
                },
                {
                    "DPA0137": "2023-01-03T05:29:04.582Z",
                    "DPA0135": "Voltas",
                    "DPA0136": 17,
                    "DPA0138": "15",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683091765094D1D.png",
                            "name": "1683091765094D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 947026472096
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-03T05:30:55.688Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-03T05:31:30.672Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 9,
            "approved_by": 11,
            "userProfileId": 17
        },
        {
            "id": 386,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2019"
            ],
            "response": [
                {
                    "DPA0137": "2019-04-09T18:30:00.000Z",
                    "DPA0135": "Blue Star DSA361R1-OA",
                    "DPA0136": 142,
                    "DPA0138": "8.4",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683544410285Fugitive_April 2019.pdf",
                            "name": "1683544410285Fugitive_April 2019.pdf",
                            "size": 160862
                        }
                    ],
                    "id": 259328601030
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-08T11:13:52.208Z",
            "modified_on": "2023-05-10T04:44:28.907Z",
            "data2": null,
            "approved_on": "2023-05-30T07:30:24.549Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 411,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2019"
            ],
            "response": [
                {
                    "DPA0137": "2019-05-14T18:30:00.000Z",
                    "DPA0135": "Blue Star DSA661R1A-O",
                    "DPA0136": 58,
                    "DPA0138": "7.7",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683694907229Fugitive_May 2019.pdf",
                            "name": "1683694907229Fugitive_May 2019.pdf",
                            "size": 159896
                        }
                    ],
                    "id": 896695589131
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T05:02:08.395Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:31:57.158Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 412,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T05:02:47.027Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:32:17.502Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 413,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2019"
            ],
            "response": [
                {
                    "DPA0137": "2019-07-07T18:30:00.000Z",
                    "DPA0135": "Blue Star SC48SFI",
                    "DPA0136": 142,
                    "DPA0138": "5.6",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683695520084Fugitive_July 2019.pdf",
                            "name": "1683695520084Fugitive_July 2019.pdf",
                            "size": 159460
                        }
                    ],
                    "id": 373202861521
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T05:12:14.596Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:32:51.972Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 419,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T06:09:51.456Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:33:10.019Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 420,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2019"
            ],
            "response": [
                {
                    "DPA0137": "2019-09-17T18:30:00.000Z",
                    "DPA0135": "Blue Star DSA1021R1-OA",
                    "DPA0136": 142,
                    "DPA0138": "35.7",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683703477131Fugitive_September 2019.pdf",
                            "name": "1683703477131Fugitive_September 2019.pdf",
                            "size": 159700
                        }
                    ],
                    "id": 751121322379
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T07:24:48.824Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:33:56.864Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 421,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T07:56:47.519Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:34:17.698Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 422,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:00:35.016Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:34:36.886Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 423,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2019"
            ],
            "response": [
                {
                    "DPA0137": "2019-12-02T18:30:00.000Z",
                    "DPA0135": "Blue Star DSA661-KCSF00040",
                    "DPA0136": 142,
                    "DPA0138": "6.6",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683705688633Fugitive_December 2019.pdf",
                            "name": "1683705688633Fugitive_December 2019.pdf",
                            "size": 160586
                        }
                    ],
                    "id": 570661507350
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T08:01:51.007Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:35:19.056Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 424,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:02:19.514Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:35:40.241Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 425,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2020"
            ],
            "response": [
                {
                    "DPA0137": "2020-02-05T18:30:00.000Z",
                    "DPA0135": "Blue Star CNHW24CAFI",
                    "DPA0136": 65,
                    "DPA0138": "2.4",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683705936902Fugitive_February 2020.pdf",
                            "name": "1683705936902Fugitive_February 2020.pdf",
                            "size": 160993
                        }
                    ],
                    "id": 1203056812535
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T08:05:45.829Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:36:21.803Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 426,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:06:18.904Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:36:43.768Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 427,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:12:04.609Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:06:12.680Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 428,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:12:19.908Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:06:35.097Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 429,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:12:34.704Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:06:56.303Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 430,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:18:19.184Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:07:16.217Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 431,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:18:45.849Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:07:36.614Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 432,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:19:39.876Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:07:57.696Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 433,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:20:12.237Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:08:17.537Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 434,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:20:35.261Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:08:36.074Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 435,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:20:55.344Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:08:53.450Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 436,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T08:21:21.809Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:09:40.981Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 437,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T10:35:36.440Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:09:15.721Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 438,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T10:42:14.669Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:10:37.776Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 445,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2020"
            ],
            "response": [
                {
                    "DPA0137": "2020-05-02T18:30:00.000Z",
                    "DPA0135": "Blue Star DSA361R1-OA",
                    "DPA0136": 142,
                    "DPA0138": "7.2",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683719543841Fugitive Emission_May 2020.pdf",
                            "name": "1683719543841Fugitive Emission_May 2020.pdf",
                            "size": 158135
                        }
                    ],
                    "id": 235903985282
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T11:53:13.684Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:38:03.381Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 446,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:07:41.133Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:37:24.934Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 447,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:08:07.181Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:52:04.290Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 448,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:08:43.986Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:38:23.425Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 449,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:09:13.552Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:38:42.528Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 450,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:09:45.489Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:42:15.577Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 451,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:10:49.663Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:42:33.424Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 452,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:11:17.166Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:50:53.490Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 453,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:12:00.424Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:51:22.446Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 454,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:12:54.602Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:52:26.073Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 455,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:13:27.698Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:52:45.027Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 456,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:14:09.662Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:53:11.036Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 457,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T12:15:07.499Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:11:00.118Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 458,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 5,
            "reporting_period": [
                "01-2022"
            ],
            "response": [
                {
                    "DPA0137": "2023-05-11T06:29:23.883Z",
                    "DPA0135": "Split C",
                    "DPA0136": 43,
                    "DPA0138": "20",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683786683774TaxInvoice_2304193.pdf",
                            "name": "1683786683774TaxInvoice_2304193.pdf",
                            "size": 11061
                        }
                    ],
                    "id": 1253846785017
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-11T06:31:28.443Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-19T19:39:20.965Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 9,
            "approved_by": 11,
            "userProfileId": 17
        },
        {
            "id": 522,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:18:22.328Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:11:20.961Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 525,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:22:11.599Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:11:44.100Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 527,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:25:19.452Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:12:03.073Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 529,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:27:38.236Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:45:17.365Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 531,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:29:40.151Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:45:45.677Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 533,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:31:26.515Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:46:06.307Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 537,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:35:11.572Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:46:27.285Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 539,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:37:04.794Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:46:45.329Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 541,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:54:47.310Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:47:08.672Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 543,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:56:41.660Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:47:27.791Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 545,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:58:37.171Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:47:47.657Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 557,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-04-04T18:30:00.000Z",
                    "DPA0135": "Blue Star DSA361R1-OA",
                    "DPA0136": 142,
                    "DPA0138": "3.6",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683869201630Fugitive_April 2021.pdf",
                            "name": "1683869201630Fugitive_April 2021.pdf",
                            "size": 158739
                        }
                    ],
                    "id": 771729129217
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T05:26:59.991Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:53:55.083Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 558,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T05:32:44.450Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:54:19.142Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 562,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-08-09T18:30:00.000Z",
                    "DPA0135": "Blue Star DSA1021R1-OA",
                    "DPA0136": 142,
                    "DPA0138": "10.2",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683870682484Fugitive_August 2021.pdf",
                            "name": "1683870682484Fugitive_August 2021.pdf",
                            "size": 157204
                        }
                    ],
                    "id": 538260553172
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T05:51:40.111Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:55:17.162Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 564,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T05:52:54.214Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:55:34.952Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 566,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T05:55:22.593Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:55:56.713Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 568,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T05:57:04.417Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:56:16.737Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 569,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T05:57:49.467Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:54:44.117Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 571,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T05:59:51.408Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:56:34.748Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 573,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T06:02:53.322Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:57:40.397Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 575,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-02-05T18:30:00.000Z",
                    "DPA0135": "Blue Star CNHW24CAFI",
                    "DPA0136": 65,
                    "DPA0138": "2",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683871571254Fugitive_February 2022.pdf",
                            "name": "1683871571254Fugitive_February 2022.pdf",
                            "size": 160827
                        }
                    ],
                    "id": 1395194937127
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T06:06:28.594Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:57:23.502Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 579,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T06:26:28.512Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:05:35.353Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 590,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T06:53:42.354Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:58:02.547Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 592,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-04-04T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 65,
                    "DPA0138": "15",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683876750272Fugitive_April 2022.pdf",
                            "name": "1683876750272Fugitive_April 2022.pdf",
                            "size": 155383
                        }
                    ],
                    "id": 1641971814850
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T07:33:23.868Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:58:45.394Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 594,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-05-10T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 65,
                    "DPA0138": "15",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683877843259Fugitive_May 2022.pdf",
                            "name": "1683877843259Fugitive_May 2022.pdf",
                            "size": 155381
                        }
                    ],
                    "id": 1153385240140
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T07:50:57.516Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:59:22.213Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 595,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T07:51:33.923Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:59:41.976Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 597,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-07-06T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 142,
                    "DPA0138": "10",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683880719923Fugitive_July 2022.pdf",
                            "name": "1683880719923Fugitive_July 2022.pdf",
                            "size": 154788
                        }
                    ],
                    "id": 1165042664334
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T08:38:46.102Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:00:15.149Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 599,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T09:18:34.390Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:00:36.395Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 600,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T09:18:59.795Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:00:56.832Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 603,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-10-03T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 65,
                    "DPA0138": "15",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683884516094Fugitive_October 2022.pdf",
                            "name": "1683884516094Fugitive_October 2022.pdf",
                            "size": 155380
                        }
                    ],
                    "id": 8579317785
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T09:42:10.471Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:01:33.560Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 606,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 12,
            "reporting_period": [
                "01-2023",
                "02-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-01-31T18:30:00.000Z",
                    "DPA0135": "Voltas Feb",
                    "DPA0136": 27,
                    "DPA0138": "20",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684136602419D1D.png",
                            "name": "1684136602419D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 1624762380579
                },
                {
                    "DPA0137": "2023-01-01T18:30:00.000Z",
                    "DPA0135": "iGeneral",
                    "DPA0136": 18,
                    "DPA0138": "100",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684136575268D1D.png",
                            "name": "1684136575268D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 564606392041
                },
                {
                    "DPA0137": "2022-12-31T18:30:00.000Z",
                    "DPA0135": "Voltas JAN",
                    "DPA0136": 16,
                    "DPA0138": "10",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684136543794D1D.png",
                            "name": "1684136543794D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 195145862678
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-15T07:43:24.528Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-15T07:43:41.063Z",
            "rejected_on": null,
            "frequencycd": 2,
            "reject": 0,
            "submitted_by": 9,
            "approved_by": 11,
            "userProfileId": 17
        },
        {
            "id": 608,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-15T08:58:08.338Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 610,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-15T09:00:34.097Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 612,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:28:55.804Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 614,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:29:22.698Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 616,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:29:50.939Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 618,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:30:21.663Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 620,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:31:02.726Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 622,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:58:33.681Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 624,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:59:08.830Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 626,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:59:46.470Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 628,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:00:28.490Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 630,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:01:59.235Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 632,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:03:52.462Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 634,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:05:01.389Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 635,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:05:35.890Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 637,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:06:08.724Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 639,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:06:49.860Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 641,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:07:36.266Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 643,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:08:14.559Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 645,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:09:09.073Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 647,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:09:52.722Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 649,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:10:40.442Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 651,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:15:39.447Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 653,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:22:11.995Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 655,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:23:01.244Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 657,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:24:02.428Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 706,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:10:20.781Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 710,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:11:46.801Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 714,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:13:33.469Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 719,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:17:14.877Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 722,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:18:16.573Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 726,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:19:42.543Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 730,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:21:04.357Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 734,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:22:09.661Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 738,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:23:19.116Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 742,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:24:33.410Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 746,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:25:59.848Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 750,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:27:21.287Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 754,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:28:55.364Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 758,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:42:51.616Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 762,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:44:23.440Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 766,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:45:45.781Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 771,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:47:07.557Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 774,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:48:08.094Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 778,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:51:48.789Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 782,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:53:04.653Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 786,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:54:15.949Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 792,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:56:30.677Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 856,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:49:13.843Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:12:30.084Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 859,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:50:13.337Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:12:45.888Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 862,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:51:29.306Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:13:01.865Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 865,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:52:24.702Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:13:12.682Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 868,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:53:22.756Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:13:23.703Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 871,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:54:16.966Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:13:35.099Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 874,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:55:18.187Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:13:46.288Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 877,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:56:22.591Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:13:56.062Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 880,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:57:27.454Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:14:10.901Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 883,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:58:29.928Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:14:22.405Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 886,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:59:37.211Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:18:46.820Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 889,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T15:00:42.922Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:19:13.337Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 892,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T15:02:07.006Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:19:24.003Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 895,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:00:10.432Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:19:34.691Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 898,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:12:24.690Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:19:45.207Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 900,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:13:03.194Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:20:09.210Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 905,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2023"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:15:08.158Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:20:20.770Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 908,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2023"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:15:50.518Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:20:31.558Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 911,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2023"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:16:34.546Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:20:43.616Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 914,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:17:22.374Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:20:57.477Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 917,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:18:10.930Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:21:32.568Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 920,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:19:07.790Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:21:44.889Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 923,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:20:00.029Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:22:03.798Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 926,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:20:59.042Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:22:16.039Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 929,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:22:00.562Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:22:26.396Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 932,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:23:00.163Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:22:37.688Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 934,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:48:29.677Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:23:06.280Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 939,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T17:14:39.140Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:23:19.515Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 944,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T17:18:42.502Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:23:31.671Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 946,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T17:20:12.093Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:23:42.832Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 949,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T17:22:12.393Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:24:00.330Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 952,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:14:11.998Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:24:12.226Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 955,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:17:08.221Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:24:23.670Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 957,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:19:19.020Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:24:34.619Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 958,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:19:56.120Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:35:24.147Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 961,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:21:24.065Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:35:38.126Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 964,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:23:09.765Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:35:59.136Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 971,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:26:06.510Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:36:29.492Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 972,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:26:29.404Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:54:28.309Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 976,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:38:51.914Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:55:07.474Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 979,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:40:18.332Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:55:35.872Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 983,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:42:11.807Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:55:52.264Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 984,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:42:36.290Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:56:08.454Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 987,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:44:03.723Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:56:26.633Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 990,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:45:32.188Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:56:44.927Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 993,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:46:56.992Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:57:01.278Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 998,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:50:25.837Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:57:21.110Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1001,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:51:47.933Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-09T21:57:34.522Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1059,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2023"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:46:42.092Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:48:14.955Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1062,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2023"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:47:37.306Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:57:18.019Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1066,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2023"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:49:47.610Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:56:58.961Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1069,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:50:39.251Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:56:37.091Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1072,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:51:33.993Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:56:15.831Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1075,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:52:28.261Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:55:55.908Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1078,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:54:14.810Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:55:35.594Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1081,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:55:20.511Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:55:15.529Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1084,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:56:20.740Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:54:52.316Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1087,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:57:31.649Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:54:26.716Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1090,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:58:41.203Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:54:05.030Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1092,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:59:20.028Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:53:43.122Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1097,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:02:13.283Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:53:17.462Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1100,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:03:28.991Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:52:54.243Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1102,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:05:05.207Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:52:32.806Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1106,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:06:35.188Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:52:08.658Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1109,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:13:59.148Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:51:46.871Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1112,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:15:27.269Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:51:19.598Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1115,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:16:47.927Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:50:44.903Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1140,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:26:21.915Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:49:21.724Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1143,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:27:33.488Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:49:40.876Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1146,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:29:00.352Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:49:59.332Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1148,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:29:58.030Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:50:20.067Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1152,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:32:16.892Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:48:57.043Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1229,
            "dcf": 10,
            "user_type": 1,
            "form_type": 1,
            "site": 42,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-03-02T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 142,
                    "DPA0138": "15",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684345147480Refrigerant.pdf",
                            "name": "1684345147480Refrigerant.pdf",
                            "size": 19721
                        }
                    ],
                    "id": 546770941502
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T17:39:31.944Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-02T07:52:29.432Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1230,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2023"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T17:58:16.595Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:03:32.248Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1231,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2023"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T17:58:47.919Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:03:11.299Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1232,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T17:59:16.653Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:02:40.687Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1233,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T17:59:21.639Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:04:49.250Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1234,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T17:59:53.435Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T08:02:11.961Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1382,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "04-2019"
            ],
            "response": [
                {
                    "DPA0137": "2019-04-16T18:30:00.000Z",
                    "DPA0135": "Freezer",
                    "DPA0136": 18,
                    "DPA0138": "32",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685012994550download.jpg",
                            "name": "1685012994550download.jpg",
                            "size": 8214
                        }
                    ],
                    "id": 641184189815
                },
                {
                    "DPA0137": "2019-04-01T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 16,
                    "DPA0138": "15",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685012947055download.jpg",
                            "name": "1685012947055download.jpg",
                            "size": 8214
                        }
                    ],
                    "id": 407793723194
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T11:09:06.592Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:44:47.320Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1383,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "05-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:09:38.357Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:45:12.323Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1384,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:09:46.052Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:45:28.320Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1385,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "07-2019"
            ],
            "response": [
                {
                    "DPA0137": "2019-06-18T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 17,
                    "DPA0138": "20",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685013086731download.jpg",
                            "name": "1685013086731download.jpg",
                            "size": 8214
                        }
                    ],
                    "id": 833724783590
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T11:10:37.049Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:45:39.679Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1386,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "08-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:10:48.233Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:49:17.409Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1387,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "09-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:11:19.433Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:50:20.941Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1388,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:12:35.955Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:50:32.796Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1389,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "11-2019"
            ],
            "response": [
                {
                    "DPA0137": "2019-03-05T18:30:00.000Z",
                    "DPA0135": "freezer",
                    "DPA0136": 16,
                    "DPA0138": "12",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685013249018download.jpg",
                            "name": "1685013249018download.jpg",
                            "size": 8214
                        }
                    ],
                    "id": 1235454459217
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T11:13:19.388Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:50:56.807Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1390,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "12-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:13:30.064Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:51:11.879Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1391,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "01-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:13:39.047Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:51:27.665Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1392,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "02-2020"
            ],
            "response": [
                {
                    "DPA0137": "2020-01-21T18:30:00.000Z",
                    "DPA0135": "Split AC_Admin",
                    "DPA0136": 16,
                    "DPA0138": "20",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685013313424download.jpg",
                            "name": "1685013313424download.jpg",
                            "size": 8214
                        }
                    ],
                    "id": 267558440737
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T11:14:23.610Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:51:41.823Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1393,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:14:43.650Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:51:52.860Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1394,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:14:51.150Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:52:03.399Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1395,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "05-2020"
            ],
            "response": [
                {
                    "DPA0137": "2019-03-27T18:30:00.000Z",
                    "DPA0135": "Split AC_Office",
                    "DPA0136": 17,
                    "DPA0138": "8",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685013391095download.jpg",
                            "name": "1685013391095download.jpg",
                            "size": 8214
                        }
                    ],
                    "id": 1509821050240
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T11:15:41.845Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:52:15.955Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1396,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T11:15:57.305Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:49:33.173Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1397,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "07-2020"
            ],
            "response": [
                {
                    "DPA0137": "2019-07-23T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 17,
                    "DPA0138": "12",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685013485927download.jpg",
                            "name": "1685013485927download.jpg",
                            "size": 8214
                        }
                    ],
                    "id": 1559774869543
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T11:17:16.356Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:52:24.053Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1439,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-02-28T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 16,
                    "DPA0138": "24",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685036368773download.jpg",
                            "name": "1685036368773download.jpg",
                            "size": 8214
                        }
                    ],
                    "id": 986393670517
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T17:39:41.639Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:14:30.884Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1458,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-02-14T18:30:00.000Z",
                    "DPA0135": "Split AC",
                    "DPA0136": 48,
                    "DPA0138": "34",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685037775958download.jpg",
                            "name": "1685037775958download.jpg",
                            "size": 8214
                        }
                    ],
                    "id": 1249862694047
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T18:02:05.440Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-25T18:14:12.346Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1475,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 34,
            "reporting_period": [
                "05-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-05-16T16:00:00.000Z",
                    "DPA0135": "Chiller",
                    "DPA0136": 30,
                    "DPA0138": "7787",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1687143097724Screenshot 2023-06-17 at 7.46.12 PM.png",
                            "name": "1687143097724Screenshot 2023-06-17 at 7.46.12 PM.png",
                            "size": 8750
                        }
                    ],
                    "id": 1531428169690
                },
                {
                    "DPA0137": "2023-05-16T16:00:00.000Z",
                    "DPA0135": "Chller",
                    "DPA0136": 20,
                    "DPA0138": "100",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1686202556816Carbon Credits etc.jpeg",
                            "name": "1686202556816Carbon Credits etc.jpeg",
                            "size": 165289
                        }
                    ],
                    "id": 13790228327
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-06-08T05:36:22.261Z",
            "modified_on": "2023-06-19T02:52:16.441Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 48,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1476,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 34,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-01-17T16:00:00.000Z",
                    "DPA0135": "Chiller",
                    "DPA0136": 48,
                    "DPA0138": "234",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1687930282746Screenshot 2023-06-26 at 11.24.11 AM.png",
                            "name": "1687930282746Screenshot 2023-06-26 at 11.24.11 AM.png",
                            "size": 88559
                        }
                    ],
                    "id": 779091940307
                },
                {
                    "DPA0137": "2023-01-17T16:00:00.000Z",
                    "DPA0135": "Chller",
                    "DPA0136": 17,
                    "DPA0138": "45",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1687260970482Screenshot 2023-06-17 at 7.46.12 PM.png",
                            "name": "1687260970482Screenshot 2023-06-17 at 7.46.12 PM.png",
                            "size": 8750
                        }
                    ],
                    "id": 1644060148979
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-06-20T11:38:17.586Z",
            "modified_on": "2023-06-28T05:31:51.631Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 48,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1477,
            "dcf": 10,
            "user_type": 1,
            "form_type": 1,
            "site": 42,
            "reporting_period": [
                "05-2023"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-06-23T11:46:24.997Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-02T07:52:41.000Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1478,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-06-23T11:46:47.923Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1479,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 31,
            "reporting_period": [
                "01-2023",
                "02-2023",
                "03-2023",
                "04-2023",
                "05-2023",
                "06-2023"
            ],
            "response": [
                {
                    "DPA0137": "2023-03-08T16:00:00.000Z",
                    "DPA0135": "Chiller",
                    "DPA0136": 28,
                    "DPA0138": "55",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1688831747590Screenshot 2023-07-07 at 7.29.52 PM.png",
                            "name": "1688831747590Screenshot 2023-07-07 at 7.29.52 PM.png",
                            "size": 117837
                        }
                    ],
                    "id": 1210516043966
                },
                {
                    "DPA0137": "2023-05-31T16:00:00.000Z",
                    "DPA0135": "AC",
                    "DPA0136": 56,
                    "DPA0138": "987",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1688370368759Screenshot 2023-07-03 at 1.31.59 AM.png",
                            "name": "1688370368759Screenshot 2023-07-03 at 1.31.59 AM.png",
                            "size": 141708
                        }
                    ],
                    "id": 515865293751
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-07-03T07:46:23.217Z",
            "modified_on": "2023-07-08T15:55:58.022Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 5,
            "reject": 0,
            "submitted_by": 34,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1480,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-07-13T07:35:48.455Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1481,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-07-13T07:36:26.282Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1486,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 31,
            "reporting_period": [
                "01-2022",
                "02-2022",
                "03-2022",
                "04-2022",
                "05-2022",
                "06-2022"
            ],
            "response": [
                {
                    "DPA0137": "2022-03-01T16:00:00.000Z",
                    "DPA0135": "Chiller",
                    "DPA0136": 48,
                    "DPA0138": "478",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1690889629272logo-color.png",
                            "name": "1690889629272logo-color.png",
                            "size": 31967
                        }
                    ],
                    "id": 1177670865279
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-08-01T11:34:10.064Z",
            "modified_on": "2023-08-01T11:34:45.948Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 5,
            "reject": 0,
            "submitted_by": 34,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1492,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 40,
            "reporting_period": [
                "02-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-03-16T16:00:00.000Z",
                    "DPA0135": "Chiller",
                    "DPA0136": 52,
                    "DPA0138": "678",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1692870990526images.png",
                            "name": "1692870990526images.png",
                            "size": 8973
                        }
                    ],
                    "id": 342515226047
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-08-24T09:56:44.799Z",
            "modified_on": "2023-08-24T09:57:03.045Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 45,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1493,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 40,
            "reporting_period": [
                "04-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-08-24T09:58:11.306Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 45,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1521,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "04-2022",
                "05-2022",
                "06-2022",
                "07-2022",
                "08-2022",
                "09-2022",
                "10-2022",
                "11-2022",
                "12-2022",
                "01-2023",
                "02-2023",
                "03-2023"
            ],
            "response": [
                {
                    "DPA0137": "2022-03-31T18:30:00.000Z",
                    "DPA0135": "Spilt AC",
                    "DPA0136": 65,
                    "DPA0138": "45",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/16946014473356271a4a9e0121d09905ee0e1_Electrical-Work-Invoice-Template2-DB.jpeg",
                            "name": "16946014473356271a4a9e0121d09905ee0e1_Electrical-Work-Invoice-Template2-DB.jpeg",
                            "size": 180937
                        }
                    ],
                    "id": 642047792539
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T14:13:05.471Z",
            "modified_on": "2023-09-13T10:47:01.063Z",
            "data2": "check",
            "approved_on": null,
            "rejected_on": "2023-09-13T10:42:21.467Z",
            "frequencycd": 4,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1556,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 40,
            "reporting_period": [
                "03-2021"
            ],
            "response": [
                {
                    "DPA0137": "2021-09-07T18:30:00.000Z",
                    "DPA0135": "Chiller",
                    "DPA0136": 32,
                    "DPA0138": "77",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/16946715071062873008.png",
                            "name": "16946715071062873008.png",
                            "size": 2263
                        }
                    ],
                    "id": 1073713131332
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-14T06:05:38.513Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 45,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1560,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 40,
            "reporting_period": [
                "11-2021"
            ],
            "response": [
                {
                    "DPA0137": "2023-09-03T18:30:00.000Z",
                    "DPA0135": "AC",
                    "DPA0136": 39,
                    "DPA0138": "88",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1695114360879Screenshot 2023-08-16 at 12.52.56 AM.png",
                            "name": "1695114360879Screenshot 2023-08-16 at 12.52.56 AM.png",
                            "size": 477680
                        }
                    ],
                    "id": 320831352367
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-19T09:06:20.577Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 45,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1651,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "03-2019"
            ],
            "response": [
                {
                    "DPA0137": "2023-10-13T05:58:50.303Z",
                    "DPA0135": "as",
                    "DPA0136": 20,
                    "DPA0138": "123",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1697176801225EiSqr virtual background.jpg",
                            "name": "1697176801225EiSqr virtual background.jpg",
                            "size": 31769
                        }
                    ],
                    "id": 964810613379
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-13T06:01:50.616Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1786,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 40,
            "reporting_period": [
                "05-2021"
            ],
            "response": [
                {
                    "DPA0137": "2023-10-31T18:20:40.701Z",
                    "DPA0135": "Chiller",
                    "DPA0136": 16,
                    "DPA0138": "22",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1698776482390data-collecting_6360962.png",
                            "name": "1698776482390data-collecting_6360962.png",
                            "size": 13039
                        }
                    ],
                    "id": 534816637073
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T18:21:25.438Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 45,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 2151,
            "dcf": 10,
            "user_type": 1,
            "form_type": null,
            "site": 22,
            "reporting_period": [
                "04-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-12-01T10:12:24.814Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 36,
            "approved_by": null,
            "userProfileId": 28
        }
    ]
    let pgas = [
        {
            "id": 381,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 3,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0285": "2023-05-07T10:17:21.897Z",
                    "DPA0286": "DELL",
                    "DPA0288": "1",
                    "DPA0289": "345",
                    "DPA0287": 199,
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683454665068D1D.png",
                            "name": "1683454665068D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 1468268454990
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-07T10:17:48.624Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-07T10:18:17.559Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 9,
            "approved_by": 11,
            "userProfileId": 17
        },
        {
            "id": 660,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T05:56:13.232Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 662,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T05:56:51.696Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 664,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T05:57:22.868Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 666,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T05:57:47.088Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 668,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T05:58:13.803Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 670,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T05:58:58.996Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 672,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T05:59:28.119Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 674,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:00:00.493Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 676,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:00:28.788Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 678,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:00:59.047Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 680,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:01:26.875Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 681,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:01:54.954Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 683,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:02:33.474Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 685,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:03:02.437Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 687,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:03:32.129Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 689,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:04:06.474Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 691,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:04:36.370Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 693,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:05:25.608Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 695,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:05:56.745Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 697,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:06:30.257Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 701,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:07:57.661Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 702,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:08:14.177Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 704,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:08:48.852Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 708,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:11:06.941Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 712,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:12:21.405Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 716,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:15:41.593Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 720,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:17:40.861Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 724,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:19:06.807Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 728,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:20:29.199Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 732,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:21:39.324Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 736,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:22:45.261Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 740,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:23:57.946Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 744,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:25:10.609Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 748,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:26:42.839Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 752,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:28:08.393Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 756,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:41:22.295Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 760,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:43:31.256Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 764,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:44:58.480Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 768,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:46:21.193Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 772,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:47:26.661Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 776,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:51:02.705Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 780,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:52:33.696Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 784,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:53:39.481Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 788,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:55:05.669Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 793,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:56:51.381Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 794,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:57:10.553Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 797,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:58:07.604Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 798,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:58:31.433Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 858,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:49:50.331Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 861,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:51:13.674Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 864,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:52:08.799Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 867,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:53:06.049Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 870,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:53:57.585Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 873,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:54:57.898Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 876,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:56:01.567Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 879,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:57:05.583Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 882,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:58:09.823Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 885,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T14:59:16.231Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 888,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T15:00:21.544Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 891,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T15:01:48.889Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 894,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T15:59:51.620Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 897,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:11:52.617Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 901,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:13:22.314Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 903,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:14:41.663Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 906,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:15:20.663Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 909,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:16:08.199Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 912,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:16:51.054Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 915,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:17:36.218Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 918,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:18:34.874Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 921,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:19:25.999Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 924,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:20:20.722Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 927,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:21:24.263Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 930,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:22:21.979Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 935,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:48:50.025Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 936,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T16:49:12.801Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 941,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T17:16:51.903Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 943,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T17:18:07.121Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 947,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T17:20:47.781Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 950,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T17:22:55.472Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 953,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:15:36.376Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 959,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:20:20.625Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 963,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:22:35.661Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 967,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:24:29.251Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 968,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:24:55.487Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 969,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:25:24.942Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 974,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:27:16.797Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 975,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:27:40.576Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 978,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:39:39.847Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 981,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:41:13.885Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 985,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:43:05.702Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 988,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:44:35.186Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 991,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:45:57.628Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 995,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:49:06.516Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 997,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:50:01.360Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1000,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:51:21.054Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1003,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T18:53:10.063Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1029,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:29:37.578Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1031,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:30:32.355Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1033,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:31:09.919Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1035,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:31:44.538Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1037,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:32:16.042Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1039,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:32:49.939Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1041,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:33:31.443Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1043,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:34:56.463Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1045,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:35:31.094Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1047,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:37:10.318Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1049,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:37:40.802Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1051,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:41:34.677Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1053,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:44:19.582Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1055,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:44:55.114Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1057,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:45:32.413Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1060,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:47:00.114Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1063,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:48:07.289Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1067,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:50:05.999Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1070,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:50:57.137Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1073,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:51:52.433Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1076,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:52:47.961Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1079,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:54:38.082Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1082,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:55:40.035Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1085,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:56:47.104Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1089,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:58:17.992Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1091,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T05:58:59.368Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1095,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:01:25.238Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1098,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:02:39.110Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1101,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:03:55.355Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1104,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:05:55.399Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1107,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:13:18.243Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1110,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:14:17.918Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1113,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:15:52.469Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1116,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:17:11.638Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1118,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:18:01.866Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1120,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:18:34.804Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1122,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:19:13.945Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1124,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:19:47.553Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1128,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:21:30.826Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1130,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:22:02.979Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1132,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:22:42.388Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1134,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:23:20.762Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1136,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:23:58.562Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1138,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:24:55.896Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1142,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:27:05.151Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1145,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:28:34.909Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1149,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:30:28.040Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1151,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T06:31:46.191Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1225,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2021"
            ],
            "response": [
                {
                    "DPA0285": "2021-02-21T18:30:00.000Z",
                    "DPA0286": "Mobile Precision 7550",
                    "DPA0288": "1",
                    "DPA0289": "2538.46",
                    "DPA0287": 199,
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684332888875Purchased Goods.pdf",
                            "name": "1684332888875Purchased Goods.pdf",
                            "size": 165929
                        }
                    ],
                    "id": 817509425887
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T14:15:32.487Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1239,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T18:14:23.416Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1240,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T18:14:48.032Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1241,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T18:16:05.663Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1242,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T18:16:37.152Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1243,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T18:17:15.332Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1244,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T18:18:01.237Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1245,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T18:18:38.115Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1270,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:27:36.204Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1271,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:28:01.745Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1272,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:28:28.277Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1273,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:28:58.340Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1274,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:33:11.157Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1275,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:33:35.316Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1276,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:34:05.620Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1277,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:34:27.541Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1278,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:34:48.857Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1279,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:35:24.197Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1280,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:35:48.897Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1281,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T04:36:25.889Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1282,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T06:05:26.098Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1283,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T06:06:02.736Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1284,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T06:07:05.335Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1285,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-18T06:08:39.864Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1315,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 530605558958,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 730311303394,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 579118863445,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 193529906042,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 358888129314,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 326104890995,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1099178615141,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1354104412349,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 451829050798,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 250210702234,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1337698695620,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 15831749518,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1583577551337,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 645837583459,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1630575446387,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 44343395466,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 682204004319,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1669721767212,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 24063902940,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 240040193902,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 937211734335,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1214065584970,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 773945436715,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1142181182100,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBookAir",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1086104756424,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "MacBook Pro",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 159246196175,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2530.031870556509"
                },
                {
                    "DPA0286": "MacBook Pro",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1196438132231,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2530.031870556509"
                },
                {
                    "DPA0286": "Dlink Cable 5 Mtrs patch card",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 591307306802,
                    "DPA0288": "10",
                    "attachment": null,
                    "DPA0289": "42.29096181730304"
                },
                {
                    "DPA0286": "C Type to HDMI",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 324842350796,
                    "DPA0288": "20",
                    "attachment": null,
                    "DPA0289": "398.7433542774287"
                },
                {
                    "DPA0286": "C-TYPE to USB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 623604270090,
                    "DPA0288": "15",
                    "attachment": null,
                    "DPA0289": "194.840502658289"
                },
                {
                    "DPA0286": "Dell Latitude 5400 Laptop Batteries",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1622821198948,
                    "DPA0288": "20",
                    "attachment": null,
                    "DPA0289": "1232.4794586756886"
                },
                {
                    "DPA0286": "Dell Latitude 5410 Laptop Batteries",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1603499066128,
                    "DPA0288": "20",
                    "attachment": null,
                    "DPA0289": "1232.4794586756886"
                },
                {
                    "DPA0286": "MAC Keyboard",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 872103857676,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "172.78878685355244"
                },
                {
                    "DPA0286": "Dlink Patch Cables 1 meter",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 586125269834,
                    "DPA0288": "50",
                    "attachment": null,
                    "DPA0289": "78.54035766070565"
                },
                {
                    "DPA0286": "Dlink Patch Cables 2 meter",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1449513994820,
                    "DPA0288": "50",
                    "attachment": null,
                    "DPA0289": "90.62348960850652"
                },
                {
                    "DPA0286": "Dell E5400 Laptop Battery",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 7854137761,
                    "DPA0288": "3",
                    "attachment": null,
                    "DPA0289": "184.8719188013533"
                },
                {
                    "DPA0286": "Dell E5410 Laptop Battery",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1183933347840,
                    "DPA0288": "3",
                    "attachment": null,
                    "DPA0289": "184.8719188013533"
                },
                {
                    "DPA0286": "Dell Charger",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 545557438093,
                    "DPA0288": "3",
                    "attachment": null,
                    "DPA0289": "32.62445625906235"
                },
                {
                    "DPA0286": "Dell Charger - Type-C Adapter",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 911098575669,
                    "DPA0288": "3",
                    "attachment": null,
                    "DPA0289": "65.2489125181247"
                },
                {
                    "DPA0286": "C Type to HDMI",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 11284859062,
                    "DPA0288": "2",
                    "attachment": null,
                    "DPA0289": "38.66602223296278"
                },
                {
                    "DPA0286": "LAN To USB C-TYPE",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 652848948235,
                    "DPA0288": "2",
                    "attachment": null,
                    "DPA0289": "25.374577090381827"
                },
                {
                    "DPA0286": "C-TYPE to USB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1648368720188,
                    "DPA0288": "2",
                    "attachment": null,
                    "DPA0289": "30.207829869502174"
                },
                {
                    "DPA0286": "LAN To USB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1318684540443,
                    "DPA0288": "2",
                    "attachment": null,
                    "DPA0289": "33.83276945384243"
                },
                {
                    "DPA0286": "Mini DisplayPort to HDMI",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 721607199739,
                    "DPA0288": "2",
                    "attachment": null,
                    "DPA0289": "25.13291445142581"
                },
                {
                    "DPA0286": "Monitor",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1229906883773,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "128.32286128564522"
                },
                {
                    "DPA0286": "Survillence Hard Drive",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1133373819222,
                    "DPA0288": "4",
                    "attachment": null,
                    "DPA0289": "703.2382793620106"
                },
                {
                    "DPA0286": "USB HUB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 516887274094,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "13.895601739971"
                },
                {
                    "DPA0286": "Dell Laptop Chargers C-Type",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1385052683479,
                    "DPA0288": "20",
                    "attachment": null,
                    "DPA0289": "350.41082648622523"
                },
                {
                    "DPA0286": "HDMI Length Cables ( 15 metre )",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 992226760244,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "31.41614306428226"
                },
                {
                    "DPA0286": "HDMI Length Cables ( 3 metre )",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 903327183893,
                    "DPA0288": "20",
                    "attachment": null,
                    "DPA0289": "96.66505558240695"
                },
                {
                    "DPA0286": "Apple TV 4K",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 70283566981,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "137.31766553890768"
                },
                {
                    "DPA0286": "USB HUB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 720528486975,
                    "DPA0288": "3",
                    "attachment": null,
                    "DPA0289": "41.686805219913"
                },
                {
                    "DPA0286": "USB C type to Multiport adapter",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 317296463864,
                    "DPA0288": "4",
                    "attachment": null,
                    "DPA0289": "241.51764137264377"
                },
                {
                    "DPA0286": "Data Card",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 760492645038,
                    "DPA0288": "10",
                    "attachment": null,
                    "DPA0289": "60.29482841952634"
                },
                {
                    "DPA0286": "Pen Drive",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 215082937568,
                    "DPA0288": "2",
                    "attachment": null,
                    "DPA0289": "10.874818753020783"
                },
                {
                    "DPA0286": "LG Monitor",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1278588109582,
                    "DPA0288": "2",
                    "attachment": null,
                    "DPA0289": "248.9125181246979"
                },
                {
                    "DPA0286": "SSD to USB Connector",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1456118700484,
                    "DPA0288": "2",
                    "attachment": null,
                    "DPA0289": "66.45722571290479"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-20T08:04:05.824Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1316,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-20T12:10:41.647Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1322,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 673703955910,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 1384842474566,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 120228207388,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 265980110907,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 1223798296850,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 1298485322990,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 320529361309,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 1485455394364,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 265802405866,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "Pro C640",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 1622011073626,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T18:07:36.007Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1323,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2022"
            ],
            "response": [
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1474318963536,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 170745127367,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 158138893107,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 195267308250,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 436698287450,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1246413806299,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1179073395150,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 504778805306,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 411312993943,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 84102785286,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1106868681259,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 812710951432,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 749362886214,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 982819319120,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1531649064152,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 976006933122,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1535552070630,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1024525459397,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 91267076847,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1313949967099,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 857087637887,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 453290370014,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 12755651821,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 745204592323,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1416947922799,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T18:09:21.012Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1324,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 380253265481,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 715970902484,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 250636756305,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1136927897470,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 954980878328,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1094970123191,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1182139551353,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 994838972420,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 475792765376,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1654347837460,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 209997031817,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 919333136538,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1168335528827,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1482397899696,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1493184301109,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 653299080400,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 415491000109,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 263368993773,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1014697448105,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1324841437694,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1613604868464,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 734137787239,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 741759114359,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 817186541345,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1128850215706,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1547951784734,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 196184472186,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1248810856235,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 118975676240,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 213707108336,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1184147064660,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1376912429066,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1145586718066,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1465282596783,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 384652944601,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 223613544611,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 411665442583,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 14635036376,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1467262905364,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 148169673741,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1161885459955,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1283314182347,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1166969513799,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 989809878882,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 89523061173,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 938309606884,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 586472603649,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1165800121624,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 604396452335,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1418424571772,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 922513242282,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1362889079577,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 229770075248,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1225299893369,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 968218734405,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1472759846252,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 767604912590,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 968842027350,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1221658730953,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 722272570742,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1163789731625,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 713926876756,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 821960989773,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 494410995021,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 156492717593,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1656664177760,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 41977065722,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 917947547673,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1613277387069,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1492326044867,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 665714077284,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 350833540863,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 553403055382,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 838433725296,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1580862988430,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 729240243419,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 972775152479,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 134966967763,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1368237912431,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 994241671771,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1536054040749,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 832453724838,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1378908837219,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1039011277930,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 936915043971,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1145220056104,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 567166445254,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 182878920879,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1674909703007,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1640382598473,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 631044712432,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1196131527800,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1617885597580,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1561744705421,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1678102361401,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1397663325201,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1084833644403,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1485910159323,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 890530788683,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 219522787543,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T18:10:31.198Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1325,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1658135826229,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 913352746750,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 258802332676,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 326758484599,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1403873138173,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1512981520149,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 924683790154,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1327168542146,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 785743808182,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 301755915843,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1365427282755,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 656635596386,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1285161902981,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 655685141758,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 105501079643,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 221103947738,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 518822020920,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1303362878912,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 840848196611,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 278190712075,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1473336031946,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1596884602597,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1604688621864,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 604946404375,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 90096578823,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T18:11:28.462Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1326,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2022"
            ],
            "response": [
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 420857228810,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2862.8133333333335"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 61029051721,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2862.8133333333335"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1639757551141,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 439923718527,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 906571186069,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 125000774438,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1187685422223,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 669286595689,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1433417273895,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 26395226330,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 510638880469,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 476899476103,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1074132144937,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1647449973596,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 206801659510,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 802959484754,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 117251157400,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 615045606294,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 400311093547,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 898592141474,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 22840581921,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 994989787067,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 59873796308,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1104274199067,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1493630581876,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 275619108381,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "750 Supreme",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 724082914917,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "12.666666666666666"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 880491432172,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1316922415786,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 640246028096,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1555441833237,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 250326455897,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 351711727767,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 266082356573,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1004264685645,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 558219249760,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1636134710924,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1332622167348,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 26646985261,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1092060905355,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1391649867295,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 35721502653,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1499849258937,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 708296940022,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 516335860165,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1627028641805,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1119589784647,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 680226808563,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1673563994951,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 304448618463,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 214690420638,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "Mac Mouse",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 90248537022,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "76.4"
                },
                {
                    "DPA0286": "SSD to USB Connector TYPE – C",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1172097330278,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "40"
                },
                {
                    "DPA0286": "HDMI to HDMI Cable-05no's",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1435025744743,
                    "DPA0288": "5",
                    "attachment": null,
                    "DPA0289": "86.66666666666667"
                },
                {
                    "DPA0286": "USB to USB Cable -03no's",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 827646002807,
                    "DPA0288": "3",
                    "attachment": null,
                    "DPA0289": "38"
                },
                {
                    "DPA0286": "Dell E5490 Laptop Battery",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1257503821599,
                    "DPA0288": "15",
                    "attachment": null,
                    "DPA0289": "1015"
                },
                {
                    "DPA0286": "Dell Charger",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 165808063087,
                    "DPA0288": "60",
                    "attachment": null,
                    "DPA0289": "840"
                },
                {
                    "DPA0286": "HDMI TO USB",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 821378575224,
                    "DPA0288": "12",
                    "attachment": null,
                    "DPA0289": "184"
                },
                {
                    "DPA0286": "HDMI TO USB C Type",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 174338300261,
                    "DPA0288": "12",
                    "attachment": null,
                    "DPA0289": "280"
                },
                {
                    "DPA0286": "VGA TO USB",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 244013044116,
                    "DPA0288": "12",
                    "attachment": null,
                    "DPA0289": "180"
                },
                {
                    "DPA0286": "VGA TO USB C Type",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 960467891298,
                    "DPA0288": "12",
                    "attachment": null,
                    "DPA0289": "264"
                },
                {
                    "DPA0286": "LAN To USB",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 305097367257,
                    "DPA0288": "12",
                    "attachment": null,
                    "DPA0289": "228"
                },
                {
                    "DPA0286": "LAN To USB C-TYPE",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 704816440199,
                    "DPA0288": "12",
                    "attachment": null,
                    "DPA0289": "332"
                },
                {
                    "DPA0286": "Graphics card ",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1276817754035,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "65.33333333333333"
                },
                {
                    "DPA0286": "Perpetual",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1555922437928,
                    "DPA0288": "600",
                    "attachment": null,
                    "DPA0289": "4080"
                },
                {
                    "DPA0286": "Desigo",
                    "DPA0287": 199,
                    "DPA0285": "2022-02-28T00:00:00.000Z",
                    "id": 1175831359266,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "7630.493333333333"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T18:55:59.909Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1327,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2022"
            ],
            "response": [
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 257291180501,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1559864236390,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 910554351902,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1399811267173,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 252416847053,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1449720576007,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 287006544540,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 454228822110,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 515919116313,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1323123004265,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1312679989799,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 301470234142,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 213527143005,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 733475067105,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1126228259504,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1451930813226,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 678080633790,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 95885814538,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 495981239868,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1341068881727,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1497493876098,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 308251907320,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 860449328437,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 1497092874066,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2022-01-05T00:00:00.000Z",
                    "id": 182251435360,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "159.26546666666667"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:23:44.255Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1328,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2021"
            ],
            "response": [
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-11-25T00:00:00.000Z",
                    "id": 383401322333,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2440"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:24:53.244Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1329,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2021"
            ],
            "response": [
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1436573971012,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2033.8933333333334"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1563466724949,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2033.8933333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1016203381520,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1596525179737,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2033.8933333333334"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 200959607208,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2033.8933333333334"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 683657387736,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2033.8933333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 483371929679,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 479295910844,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1465308067914,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 375106323069,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 657150837248,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 152452802434,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 347291439136,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1463440277769,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 108285507540,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1521841916179,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1412756674693,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 797008892637,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 803557151107,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 546501749579,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 178484115256,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1076634684502,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 919708324302,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 973551879800,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 493836379228,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1433914703790,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1566598240910,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1547596872880,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1069426008186,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1367518145885,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 51551931212,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 968913487394,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 320610270699,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1466586157599,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1246670186317,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 288135899567,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 952541938136,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1057060475832,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1500218767654,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 506389403014,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1224967446320,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 886163283257,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 304068299340,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 572437051874,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 312534604683,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 452200836923,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 891176462832,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 368602262373,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1441676555813,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 56655085843,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1425859826540,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 609355942932,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1332843124090,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 13413763533,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 181482432189,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1188468551495,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 756228681198,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 143831824168,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1456750752974,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 604013085357,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 248266347692,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1635466597127,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 306992191123,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1514341387882,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 22947700560,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 420566573262,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1462916680288,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 788984104470,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1287890398500,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 959758996056,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 133739472060,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 984052733353,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 525270137206,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1362872502105,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 142018269571,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 526160851917,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1289326181774,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 985107776359,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 783438616434,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 632668819000,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1437998833394,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1482638408198,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1478350367841,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 91638313255,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 661081393215,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 648618971608,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1319654570425,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 727894753186,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 320277762573,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 520697307455,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 250294668533,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1218443936042,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 582320499021,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 618237266155,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 431609103053,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 789925044735,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 178827034966,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 259744546672,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1548191022685,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 955197591255,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 814773993013,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1029967006274,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 881684420503,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1239851336831,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1135752248803,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1159769897546,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 416935010859,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 528285310564,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1358016577733,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1016908425483,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 825281549903,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 96705299942,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1532252881218,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1292024021701,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 758940715449,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1547207063794,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1418069585255,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 41130525156,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1094003703808,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 948989253756,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1541090457913,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 253924755125,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 365641191689,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 926187223455,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 564793696629,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 673175965792,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 98674194644,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 645956444480,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 566273946676,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 68711286812,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 474956091672,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 31300500032,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 271356797192,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 823008727501,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 299558614881,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 16171754000,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 436555515761,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1106908637993,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1322987151491,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 24409088552,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 309549256284,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1545324562241,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 884880258164,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 494713723781,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 820823420100,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 943982960235,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 351947914157,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 755067636286,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 987683536339,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 935234680015,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 999712985447,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1672492196981,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 773480667535,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 482385129064,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-08T00:00:00.000Z",
                    "id": 1670167065396,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1066.4533333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-10-10T00:00:00.000Z",
                    "id": 369786393216,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:25:51.095Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1330,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2021"
            ],
            "response": [
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 688769202867,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1196643819047,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 551483823236,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 343108067518,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1647043205398,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 835668570529,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 255484580446,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1461823250929,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 379156361484,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 40461881848,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 467525260904,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 479759876329,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 456486219046,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1197030140958,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 14637043030,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 690872020102,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1525045602821,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 586956082272,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 516254389043,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 376786295351,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 941129118158,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1590623878353,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 73719760675,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 709395726997,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 124967139354,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 597302302606,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1315625517743,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 148889160218,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 209438753496,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 621901393817,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 801515733287,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 157011911897,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 569697501067,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 855891893206,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 140341736250,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 883144712393,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 465749839055,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1115209823433,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1158812275472,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 356984902786,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1613554540818,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1682277875038,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 227353626733,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1206007935873,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1146841997950,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1003935643578,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1303745970962,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 641405101345,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1133982344457,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 719433022863,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1191743001666,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1430101500244,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1515186032562,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1341606339023,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1661661134174,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 258558763904,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1580384316142,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1158845883469,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 57401894134,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 548059645821,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 128631637386,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 803255329781,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 119670581263,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 113038627436,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1022098237938,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1386813831827,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 54809626304,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1609132064307,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 595589881579,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 847880876179,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 639601152793,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1633506772646,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 65274032547,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 790368206033,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 901500083694,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 532140144232,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1117773282877,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1584770208946,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 796572296662,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 142871214886,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 79923122150,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1160809836924,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 115493408592,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 69485078555,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 816746780013,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 768797253839,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 821717116199,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 500155287341,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1413364079118,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1236471236588,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1567012893728,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 514273616843,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1100584529248,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1014756531810,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1180542390111,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 148176961916,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 894115893548,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1488533910821,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 510158300397,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                },
                {
                    "DPA0286": "Thinkpad L14 Gen 2",
                    "DPA0287": 199,
                    "DPA0285": "2021-09-13T00:00:00.000Z",
                    "id": 1683843819413,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "989.3333333333334"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:26:30.206Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1331,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2021"
            ],
            "response": [
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 711414406849,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 748579880092,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1212509314049,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 171825920613,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 241928152754,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 320055617617,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1098224537596,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 736730943835,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1230080436065,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1088722185253,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1232211903470,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 451178359402,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 76650907883,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1147391860950,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1591535514992,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 779540974333,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 18783726713,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1198958808473,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 58688757921,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1524348956951,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 735255870932,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 745188483448,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 85050948328,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 318466788587,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 458015607431,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 556822620837,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 994050745407,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 24296546861,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 852969904059,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1211271107000,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1270629442251,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1583311809227,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1293588552378,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1161734255755,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 541741799672,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1232176477115,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 953956742401,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1061861390677,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1044175386096,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 924542865416,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 426073831625,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 57645002399,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1534100065326,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1603563556375,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 399959118832,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1082724578913,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 700446630156,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 1114670161227,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 694421685310,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 945752161013,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1432502281521,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 837583115459,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1409505269868,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 219654455797,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1580513810359,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 69104920452,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 770921744988,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 458914578020,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 185562922465,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 916488145588,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 944828758334,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 651393279388,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 115995224598,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 1012504971825,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1071490290220,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1598348973541,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1167144347044,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1617237617091,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 525990128812,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1046602712885,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1150233314024,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1391958317708,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 195214273235,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1611650011500,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 350881371876,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1329695767320,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1258772965095,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 34362455776,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 269829456357,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 777744320230,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1283522697275,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1300117321753,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 514292871959,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 184959627206,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 348818637235,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1450871654886,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 10889884171,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1158163154838,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 754504427120,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 11388760493,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 488331916934,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1298657676001,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1388193657181,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 473377861007,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 173123515704,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 142706104160,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 517211239619,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 985731921820,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 679033353573,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 366292303053,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 356710801993,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1233836642965,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 243380384347,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 122796260346,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 1114889113977,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1121774572751,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 685211513468,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1516225422628,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 979191096578,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 554259952341,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 954822875818,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 546606870546,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 938627266700,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1598419956437,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 494119138999,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 220379102864,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 1223182852364,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 634577432141,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 275895583824,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 698684240281,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 832388177750,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 59335450811,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1656669059924,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1618743223076,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 215281006298,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 80154556954,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 454841257535,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1127926191616,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1458803445959,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 759279489381,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 574292055452,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 740285551386,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 487977946573,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 837662160583,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1621105741462,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 611502559232,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1356813445335,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1267961585517,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 682030263581,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 27965045194,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 327948643201,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1057666624324,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 811838057767,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 260747082928,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 597958374254,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 176731814584,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1362771085058,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1575899475498,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 51672810020,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 18255312786,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 573404169813,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 661442110949,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 348285429232,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1141593213771,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 1517667353640,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1489776904149,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 682846630208,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 1558633458573,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 511994336198,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 893401092567,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1342573938241,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1131179048719,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 912109438934,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1052552602832,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 846719060946,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 161724697695,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 1145083518327,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1244092006843,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 953037526393,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 354157262340,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 208953998243,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 334565206416,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 516860987710,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1283417967659,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 839976208295,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1253450502828,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1439504327050,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1407022621559,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 393428120117,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 564853601637,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 145081316655,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 305164740143,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 991236024866,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1406502882186,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 148411623606,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 209642801109,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1447761241206,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 951323588777,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 710227657968,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1284290075225,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 598013590195,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1478594920484,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 8155251955,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 159319368669,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 3272467002,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 45481960414,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 129050743993,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1454593543969,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1584237508851,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 660025924795,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1318990481909,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1467039515803,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 823520144111,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1537057760134,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1334011448935,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 7787245900,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 647567612921,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 111774267509,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1307209811108,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 957341461733,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 225216878102,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1683895941704,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1248067004940,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1035499848052,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 270848804477,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1023556466621,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 413224010068,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 713224731647,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1163040784619,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1619704367882,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 344200557458,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 841197102304,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 469108746455,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 864485469981,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1293409745915,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 546461771426,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1036696235823,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1297611365023,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1513236251412,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 781627541158,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 773239929045,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1456888950253,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 254629495983,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 230644068156,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1116632987123,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1550376956184,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 1024878423129,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1403893905137,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 5694727697,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 243183764528,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1261921324506,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 123881200443,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1254183659499,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 882962853379,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 410769092693,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 914673025082,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1450123355222,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 922035207515,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 231547670443,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 590425014573,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1202933902989,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 525450703607,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 365699047591,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 909327489491,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1456470491480,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-15T00:00:00.000Z",
                    "id": 729110023373,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1587911242105,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1561689341721,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 658514661363,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 898357465206,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 664318110570,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1125510391471,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 640362571743,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1230779058312,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 695616873194,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 892977905142,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 172997154366,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 426192480376,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1137901283531,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1355300971717,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1622806752867,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 543261527944,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 716317721847,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1267098652734,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 370050272098,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1183364653525,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1315006929126,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 615353206589,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 560284023306,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 626987277199,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1544973826542,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1034041732100,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 119739103691,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1175942767320,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 196163652453,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 693694698941,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1046.6666666666667"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 92593688864,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 1082924698296,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 451643185173,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 328351167707,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 913379131011,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 160615087187,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1186316293673,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 347020993039,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 502505777239,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 75693275405,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 236876189487,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1571953114446,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1192790573924,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1174440214860,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 546696236916,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 700720035870,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 715257614093,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1326217158075,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1512304291135,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1020024788968,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1243305475766,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1219024188471,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 689434661457,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1088372814683,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 248364261732,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 976870645818,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1014051015034,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 570789593375,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 1678517827757,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5420",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-19T00:00:00.000Z",
                    "id": 755728620625,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 16940451738,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 901728255671,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-22T00:00:00.000Z",
                    "id": 262483616875,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 478398612852,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                },
                {
                    "DPA0286": "MacBook Air",
                    "DPA0287": 199,
                    "DPA0285": "2021-07-20T00:00:00.000Z",
                    "id": 201181088729,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "944.9333333333333"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:27:07.502Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1332,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2021"
            ],
            "response": [
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-06-19T00:00:00.000Z",
                    "id": 940450938117,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1033.3333333333333"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:27:54.723Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1333,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2021"
            ],
            "response": [
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 1344937908454,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 706577075645,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 1582928918069,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 234892381850,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 555898905527,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 629955416583,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 356776509713,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 929220395921,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 75832948291,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 897493418248,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 364202288662,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 1435355261500,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 322092415851,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-12T00:00:00.000Z",
                    "id": 468496211468,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 311817267812,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 431673365102,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 562219787734,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 130189949954,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1394488831649,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1601321554349,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1046687957494,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1356699060362,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 976293540170,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 783975370040,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1521381055067,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 145288508422,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 493756211199,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 366381532612,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1204536875838,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1062033925498,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 575091681975,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 350152581176,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 698947384107,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 323318084421,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1374431009140,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1641932603533,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1242226305144,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1642921371072,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1231944316565,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 155728627476,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1311428837009,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1080164024078,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1596794502226,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 862914674876,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 892123622662,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 567096590421,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1582051528316,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 495308335850,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 264604119052,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1592507491013,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 470406259009,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 700482209635,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 404533013282,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-17T00:00:00.000Z",
                    "id": 1264302815612,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 364692659317,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 110266079282,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 1549450168011,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 787575846024,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 720815087828,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 569369293358,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 596365099751,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 689738609648,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 725310417471,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-22T00:00:00.000Z",
                    "id": 583826175460,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 63823959879,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 948442677810,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1359245381081,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 313896336388,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 551139908642,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 540577457904,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1122134504762,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 599823974480,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 387914791376,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 348197595265,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1399990204580,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 837647513941,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 737228680075,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 164935174079,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 551867466918,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1600600790944,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 612732148995,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 508705414610,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1530626354467,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 55658559135,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 222354292994,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 873554473223,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 582657567168,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 887006745903,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 516021779939,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 354978072223,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1199274587370,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1601156521158,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 740205843368,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 352440123340,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1506973638672,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 26529800712,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1633719637563,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1512757341213,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1024536199851,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 707004023254,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1088076897852,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 511587795842,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 43500835939,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 1538612268996,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 258736708919,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-04-28T00:00:00.000Z",
                    "id": 921441731667,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:28:59.677Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1334,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2021"
            ],
            "response": [
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 224705652896,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 222913566175,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 352132197179,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1108604526487,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1426718369798,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 142863855796,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 264499835863,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1461239052874,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 992722090190,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1428457700448,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1380103731526,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 744039375839,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 599235149682,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1110409304902,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 58282267816,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 837567282562,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 174305382907,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 664176779269,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 292122791875,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1554745216822,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1070970993289,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 537997347208,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1244342254893,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 148876244169,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 407762583106,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 20801242228,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1291908130938,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1132731348444,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1660057096421,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 734028009524,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 218565286669,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 196578600263,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1246277418522,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 470624888916,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 784251090882,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1028433067458,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1682983731200,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 388967336877,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 897644792222,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 744775460938,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1611671464989,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 133761766348,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 905520233044,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 898884874674,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 21038175656,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1585981773360,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 559186660045,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 843187141859,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1559311906968,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 394394739510,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 552364103004,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1400602946622,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 410282563668,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 408181371478,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1667300737278,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1294081649007,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1464104646754,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1656336076435,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 990767325439,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1634801834880,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 762386123354,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 46940333487,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1037954106019,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 809758295258,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 782977876606,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1277996650888,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 774506023429,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1323765124635,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 913505239438,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1479193853205,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1049817290925,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1422783560182,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 626590183300,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 646137006215,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1194480719244,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 238282794930,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 512335961608,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1428244805989,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 142180367741,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 23513428560,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1332973902704,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1515652118053,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1167098934408,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 224348126620,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 593638873864,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1570795086490,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 923875093213,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 164416430121,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1453448529372,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 735559799351,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 67276722842,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 294440513296,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 455738607002,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1213907096265,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 688914743549,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1195048934004,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 979455771105,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 912398813448,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 82605243366,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 1530920729600,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-18T00:00:00.000Z",
                    "id": 757807987132,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 505404406397,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 301747968658,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 222965376801,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "JBL EON 610 and Sound Craft Signature 10",
                    "DPA0287": 199,
                    "DPA0285": "2021-03-09T00:00:00.000Z",
                    "id": 1267958106025,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1027.6266666666668"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:30:17.402Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1335,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2020"
            ],
            "response": [
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1267583139775,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1170362118308,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 177152296729,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 899543264643,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 300691145556,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 871676835490,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1234614294930,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1172494683831,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 287482413146,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1098213315732,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 802277346639,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 221860409142,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 148897286645,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 378886439111,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1146348521142,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1005048944574,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1351334305703,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1564862539898,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1217410237984,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 193793672681,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 284674145852,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1515634977891,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 96947404080,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 51867949988,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 65259524677,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1477029530777,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 126938134010,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 443324259835,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 86288133197,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1666818208328,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 403622625143,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1577921732274,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 768054240327,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 305932749072,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 125937934593,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 490206576943,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 894112574742,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 428741854406,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1664600008249,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 126069688880,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 111888730573,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1236699701356,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1117623859877,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 413371763382,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 201150931169,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 359697291191,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1646619880769,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 199130993902,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1026359963053,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 1413939332047,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5410",
                    "DPA0287": 199,
                    "DPA0285": "2020-12-18T00:00:00.000Z",
                    "id": 580733943476,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1013.3333333333334"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:31:30.109Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1336,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2020"
            ],
            "response": [
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 700287858361,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 784693294600,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 283963771053,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 814506868149,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1387204048166,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 53114859245,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 827666022648,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 450129560714,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1005796639109,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 47725389978,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 836623284701,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1524538500447,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1592556392748,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 534375537549,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1545896734839,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 415309564459,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1562855963394,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 756396221089,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 909876439170,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 463405751667,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1271260676476,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1577429808064,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1097116820792,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1415448408724,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1501449637514,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 687352167804,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 556974568216,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 968292135593,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 48012691000,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 698564436281,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 170937069104,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 653928406953,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 367720150476,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 631338289022,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 665770926938,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 715517311071,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 300842298108,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1172160201479,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 766110878427,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 438205091719,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1303483722305,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1179899954321,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 939404836261,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 590121692217,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1453890721829,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1462086447660,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1672658407690,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1334535106485,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 732912665231,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5400",
                    "DPA0287": 199,
                    "DPA0285": "2020-08-20T00:00:00.000Z",
                    "id": 1117251478005,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "981.3333333333334"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:32:30.728Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1337,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2020"
            ],
            "response": [
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 336065256722,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 885587885964,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 169494133457,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 725420860971,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 839793810656,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1199873425934,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1084636054445,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1408816513711,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1418812279616,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 751679478699,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 869766596751,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 917159606461,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 257501177489,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1548110377388,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 786042708934,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1191381769298,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 218116740852,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1062829124686,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1124366548139,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 421503024596,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 11396181974,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 2691544410,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1139553776579,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1441263543932,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 187692539922,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 889966121511,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1049397958133,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 756133852330,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 270638529032,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1429935011550,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1141937863700,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1461447038634,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1394046358228,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 473835198919,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 87212346133,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1516835398264,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 239633698530,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1136667943876,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1363630527564,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 88095542063,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1250893257041,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 607702829800,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1082305082517,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1229574211331,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 235039227248,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1677882519269,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 316357726550,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 100312915761,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 796573770265,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 103757856485,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 748174520039,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 151749488515,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 508029202041,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1074169467257,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1078408388120,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 743933008030,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 754753465465,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 815699312270,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1245818610967,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1349335285114,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1528645722746,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1362734961883,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 200064930532,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1173241327941,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 825055200661,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 610242251612,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 194511163101,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1331838988345,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1051728310827,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 315164108344,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 182360704680,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1115185316714,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 515886948240,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1081041335714,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 526275367129,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1591788861508,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 818848220283,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1647260582691,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 933765342636,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 960225995423,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 398518418484,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 128527833867,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 969235523821,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 405954589015,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 521301464082,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1516807406152,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1216870907373,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 988811323605,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 167325718125,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 483807933273,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 308712827887,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 438565495059,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1283281155959,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 366476720733,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1163617219852,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 1234616267268,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3070",
                    "DPA0287": 199,
                    "DPA0285": "2020-01-21T00:00:00.000Z",
                    "id": 912595454545,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Visual Studio Pro",
                    "DPA0287": 218,
                    "DPA0285": "2020-01-07T00:00:00.000Z",
                    "id": 843460256273,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2400"
                },
                {
                    "DPA0286": "SQL Server 2019",
                    "DPA0287": 218,
                    "DPA0285": "2020-01-07T00:00:00.000Z",
                    "id": 1647661376034,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "739.6144"
                },
                {
                    "DPA0286": "Wet & Dry vacuum cleaner NT 70/2 Me Classic",
                    "DPA0287": 200,
                    "DPA0285": "2020-01-07T00:00:00.000Z",
                    "id": 1434748049034,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1290"
                },
                {
                    "DPA0286": "DSA361R1 ",
                    "DPA0287": 200,
                    "DPA0285": "2020-01-07T00:00:00.000Z",
                    "id": 369397099737,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "573.3333333333334"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:45:01.058Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1338,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2019"
            ],
            "response": [
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 918983912474,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 203049116696,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 280575595023,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1390262649329,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1570636502485,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1460186528946,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1323275174800,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 158963431909,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 855449077124,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 995272078160,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 750303432424,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 351321405984,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1000082347104,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 390334200306,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1649719673035,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 815523579493,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 287686024401,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 565313437412,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 654332988189,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 21766688484,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 88841213205,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1312165814790,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1271074373315,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1182304338484,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-11-25T00:00:00.000Z",
                    "id": 1245668469801,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:46:27.772Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1339,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2019"
            ],
            "response": [
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-09-26T00:00:00.000Z",
                    "id": 617386797943,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:49:39.732Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1340,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2019"
            ],
            "response": [
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 52880217237,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 142963466497,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1006728302055,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 649809703936,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 570678216963,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 496092359713,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1488010732676,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 987091798560,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1198472427441,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 764993624433,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 591458526356,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1552046159066,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1667926918883,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 74679711837,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 126809042821,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 709510770503,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 967390531847,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1671406273801,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1414411577363,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 145666790205,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 147443608388,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1527555108328,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1199671652808,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1464765534119,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1526015582558,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 761457065775,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 669446015299,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 253559776218,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 511342779882,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 395848047578,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1024985077723,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 236025888745,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 395019727843,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1321028384979,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 772871555016,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1458861682889,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1009632241469,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 132246511233,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 439627649889,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1003637490793,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1297818023349,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 931320163828,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1533766149746,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1271698483972,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 224681774463,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1531488633300,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 1225496245831,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-08-20T00:00:00.000Z",
                    "id": 925868620515,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:50:44.090Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1341,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2019"
            ],
            "response": [
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 300076568134,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 1498546734394,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 922406452536,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 378508684792,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 796815689669,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 361824399663,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 986023698433,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 1089272822083,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 1315135720821,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 298982971413,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 378991528693,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 689610873340,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 1191837495036,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 1107955359719,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 178867985516,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 1071273491103,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 1535040240844,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 1237043824478,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 728518772300,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 171640482975,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 239547182132,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 481519360443,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 1166639655092,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 351198268176,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Optiplex 3060",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-01T00:00:00.000Z",
                    "id": 90038265304,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "773.3333333333334"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 555845635327,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 466419716977,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1568119705300,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 963973563211,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 893870811632,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1654933733511,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 231767423697,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1088629721291,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 730155718781,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1232276396402,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1426262623215,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 780341848162,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 859818166538,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1253214066748,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 7882085988,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1586452115588,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 62814944357,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1306210538317,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 572008158309,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 769039765826,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1268616134975,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 714439613676,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1074967691208,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1380579342606,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 1250424079766,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-07-23T00:00:00.000Z",
                    "id": 314962926265,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:51:29.707Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1342,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2019"
            ],
            "response": [
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1172831530351,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 549558587948,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 115638185151,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 837271718558,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1027661933886,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1448201421497,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 435212755921,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1172953228242,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1379537708165,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1599877329650,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1338134649437,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1347500660721,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1660532592920,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1288657044073,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 628375878097,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1264863789675,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 284934791432,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 535531978577,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 417497015813,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 1626703843896,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 384448615322,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Wyse 5070 Thin Client",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-01T00:00:00.000Z",
                    "id": 674464467700,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "412"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-03-20T00:00:00.000Z",
                    "id": 941533847494,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1262602362737,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1207564616676,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1471639775043,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1034024496119,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1177033927569,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1591182523534,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1582565233235,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1012253488179,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 529450508511,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 316562274139,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 73828374844,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 154138849491,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1684037969511,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 204331203436,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1446056642072,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1011900409553,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1410723690934,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 288496263160,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 680096825662,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 949980565319,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1165131789832,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 693215632645,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 26488066290,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1027414666069,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 164375330110,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 319211066817,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1614051061522,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1659562857251,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 124846082219,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 828457113771,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1082916992447,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1277821216209,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 559885943061,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 294836498121,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 95449305569,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1145390669250,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1625629115258,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 598156024559,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 939674887582,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1063222670649,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1117955681992,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1477820923997,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1490169656726,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 844048789296,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 499879169006,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 976155107013,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 507256037591,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 1031504288172,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                },
                {
                    "DPA0286": "Latitude E5490",
                    "DPA0287": 199,
                    "DPA0285": "2019-05-22T00:00:00.000Z",
                    "id": 263053453805,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1045.3333333333333"
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-22T19:52:42.671Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1343,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-22T20:00:02.064Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 52,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1555,
            "dcf": 16,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0286": "LV-SMAC100",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 780627435857,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC101",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 345765546080,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC102",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 42153702810,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC103",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1279285248150,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC79",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 369820034825,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC80",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 42556928479,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC81",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 982051674271,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC82",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 748794181908,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC83",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 478386819213,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC84",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1118797977793,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC85",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1455814352588,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC86",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 64431792362,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC87",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1494380810558,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC88",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 665232217002,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC89",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 374209372995,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC90",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1447363721950,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC91",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1539988671068,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC92",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 368067713614,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC93",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 459293606493,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC94",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1639496940936,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC95",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 1290547827538,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC96",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 364887232978,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC97",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 822130756325,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC98",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 21264736150,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SMAC99",
                    "DPA0287": 199,
                    "DPA0285": "2022-06-07T00:00:00.000Z",
                    "id": 938228085964,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "901.0615711252653"
                },
                {
                    "DPA0286": "LV-SL1420",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 871837350881,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1421",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 100376853262,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1422",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 541208293960,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1423",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1207609600384,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1424",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1000475819681,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1425",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 435591704556,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1426",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 65710339771,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1427",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1269110395375,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1428",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 660783204287,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1429",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1351890424179,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1430",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 141292046078,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1431",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 826694962231,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1432",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 778633712160,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1433",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 110904339148,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1434",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 561567161844,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1435",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1427290125495,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1436",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 764197135754,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1437",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1192844992439,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1438",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 880033289781,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1439",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 131935028896,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1440",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1411910593089,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1441",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1641498427334,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1442",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1017931797392,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1443",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 484271417842,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1444",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 154219184630,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1445",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 400645151621,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1446",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 744972552671,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1447",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 716836004191,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1448",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1056270198390,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1449",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1221908215187,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1450",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 292117847497,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1451",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 249027171857,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1452",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1689987804829,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1453",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1271571650970,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1454",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 641039802425,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1455",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1097703906311,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1456",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1414276175275,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1457",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 891674832606,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1458",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1191878636987,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1459",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1076525019555,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1460",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 325785117824,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1461",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1040059818911,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1462",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 111626247803,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1463",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1625594318888,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1464",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 953234050698,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1465",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1577974542934,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1465",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 218123085438,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1466",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1656654554843,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1467",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 542781010780,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1468",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 617911969742,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1469",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 10535789086,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1470",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 18715989701,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1471",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1033207494583,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1472",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1194325322881,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1473",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1162988353665,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1474",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 118647466080,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1475",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 896874767982,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1476",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1565668021492,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1477",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1546531971801,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1478",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 588686306248,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1479",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 456410182711,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1480",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 283584654615,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1481",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 38977680084,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1482",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1553829501585,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1483",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 625659276135,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1484",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 143978908709,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1485",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1500886804273,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1486",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 166623401825,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1487",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 922838713325,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1488",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 976076921374,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1489",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 498572189302,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1490",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 683477929755,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1491",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1063786588159,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1492",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1197553968815,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1493",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 317753395793,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1494",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 298540935415,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1495",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 344884083648,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1496",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 432570989922,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1497",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1001610575625,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1498",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 208073401578,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1499",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 230584080354,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1500",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1286030349647,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1501",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 148933892795,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1502",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 337353932933,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1503",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 730718474090,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1504",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1547447711768,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1505",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 572008854145,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1506",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 413436545870,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1507",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 921242433122,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1508",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 516362780130,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1509",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1398391076968,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1510",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 375236679876,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1511",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1108318990571,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1512",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 623675780020,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1513",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1602966480287,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1514",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 365930551704,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1515",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 499184012192,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1516",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 505367990826,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1517",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 1050839490127,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SL1518",
                    "DPA0287": 199,
                    "DPA0285": "2022-07-20T00:00:00.000Z",
                    "id": 261149018675,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1024.8960840078757"
                },
                {
                    "DPA0286": "LV-SMAC104",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 958237360296,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC105",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1507738493388,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC106",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 735997680407,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC107",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 579198933346,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC108",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1255868084247,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC109",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1559747437821,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC110",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1145021728581,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC111",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1693166927029,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC112",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 602823567551,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC113",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1434368097272,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC114",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 658531477845,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC115",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1685043546690,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC116",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 633956735226,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC117",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 420388338899,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC118",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1324593558270,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC119",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 480917809138,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC120",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 447196056937,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC121",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 675143969805,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC122",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 252850713413,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC123",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1224863034918,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC124",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 49695032293,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC125",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1386520995289,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC126",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 855180465666,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC127",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1374302914147,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-SMAC128",
                    "DPA0287": 199,
                    "DPA0285": "2022-09-16T00:00:00.000Z",
                    "id": 1277383646293,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "946.7084639498432"
                },
                {
                    "DPA0286": "LV-GC003",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 467392814274,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-GC006",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 1246712963006,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-GC007",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 579878233575,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-GC008",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 976111788668,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-GC009",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 1058392465026,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-GC010",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 518698613951,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-GC011",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 1520660656689,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-GC012",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 341165303515,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-GC013",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 845629010893,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-GC014",
                    "DPA0287": 199,
                    "DPA0285": "2022-10-20T00:00:00.000Z",
                    "id": 987866801592,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "821.6529724504591"
                },
                {
                    "DPA0286": "LV-SMAC129",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 553452845878,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC130",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1598403522477,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC131",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 292786318813,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC132",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1496813529326,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC133",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 266602437829,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC134",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1562110895804,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC135",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1473270977371,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC136",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 253275312396,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC137",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1110326010136,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC138",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1206971337153,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC139",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1187614785757,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC140",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 931057267338,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC142",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1141523720414,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC143",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 320896898114,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC144",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1529678428010,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC145",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 927240017210,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC146",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 588337324227,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC147",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 289544583113,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC148",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1668099200981,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC149",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 988669420462,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC150",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 577956742549,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC151",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 973205908207,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC152",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1684841547802,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC153",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 480296991989,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC141",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 571061105995,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1246.911007599902"
                },
                {
                    "DPA0286": "LV-SMAC155",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 855162212292,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2530.031870556509"
                },
                {
                    "DPA0286": "LV-SMAC154",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 930869995554,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "2530.031870556509"
                },
                {
                    "DPA0286": "Dlink Cable 5 Mtrs patch card",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 343958193920,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "42.29096181730304"
                },
                {
                    "DPA0286": "C Type to HDMI",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 565804022099,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "398.7433542774287"
                },
                {
                    "DPA0286": "C-TYPE to USB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 454451668839,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "194.840502658289"
                },
                {
                    "DPA0286": "Dell Latitude 5400 Laptop Batteries",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 586051903333,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1232.4794586756886"
                },
                {
                    "DPA0286": "Dell Latitude 5410 Laptop Batteries",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 433474803520,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "1232.4794586756886"
                },
                {
                    "DPA0286": "MAC Keyboard",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 491444666907,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "172.78878685355244"
                },
                {
                    "DPA0286": "Dlink Patch Cables 1 meter",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1228227364826,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "78.54035766070565"
                },
                {
                    "DPA0286": "Dlink Patch Cables 2 meter",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 21867006892,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "90.62348960850652"
                },
                {
                    "DPA0286": "Dell E5400 Laptop Battery",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1068049919235,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "184.8719188013533"
                },
                {
                    "DPA0286": "Dell E5410 Laptop Battery",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1096021847635,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "184.8719188013533"
                },
                {
                    "DPA0286": "Dell Charger",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 951429630375,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "32.62445625906235"
                },
                {
                    "DPA0286": "Dell Charger - Type-C Adapter",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1617089431399,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "65.2489125181247"
                },
                {
                    "DPA0286": "C Type to HDMI",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1159169101590,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "38.66602223296278"
                },
                {
                    "DPA0286": "LAN To USB C-TYPE",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1442606134397,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "25.374577090381827"
                },
                {
                    "DPA0286": "C-TYPE to USB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 258714924514,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "30.207829869502174"
                },
                {
                    "DPA0286": "LAN To USB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 151313672415,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "33.83276945384243"
                },
                {
                    "DPA0286": "Mini DisplayPort to HDMI",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 666631359749,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "25.13291445142581"
                },
                {
                    "DPA0286": "Monitor",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1537359552846,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "128.32286128564522"
                },
                {
                    "DPA0286": "Survillence Hard Drive",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 384418798709,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "703.2382793620106"
                },
                {
                    "DPA0286": "USB HUB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 907545865838,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "13.895601739971"
                },
                {
                    "DPA0286": "Dell Laptop Chargers C-Type",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 805320188946,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "350.41082648622523"
                },
                {
                    "DPA0286": "HDMI Length Cables ( 15 metre )",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 856815553645,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "31.41614306428226"
                },
                {
                    "DPA0286": "HDMI Length Cables ( 3 metre )",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 251777550944,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "96.66505558240695"
                },
                {
                    "DPA0286": "Apple TV 4K",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 488501413926,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "137.31766553890768"
                },
                {
                    "DPA0286": "USB HUB",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 240873890595,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "41.686805219913"
                },
                {
                    "DPA0286": "USB C type to Multiport adapter",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 157128976262,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "241.51764137264377"
                },
                {
                    "DPA0286": "Data Card",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 741061296956,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "60.29482841952634"
                },
                {
                    "DPA0286": "Pen Drive",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 1503494082751,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "10.874818753020783"
                },
                {
                    "DPA0286": "LG Monitor",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 525244464874,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "248.9125181246979"
                },
                {
                    "DPA0286": "SSD to USB Connector",
                    "DPA0287": 199,
                    "DPA0285": "2023-01-11T00:00:00.000Z",
                    "id": 704281254705,
                    "DPA0288": "1",
                    "attachment": null,
                    "DPA0289": "66.45722571290479"
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-13T12:24:36.415Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T12:25:52.963Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        }
    ]
    let sc = [
        {
            "id": 140,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 3,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 9,
                    "DPA0132": 34,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1680686046283blur_bg.jpg",
                            "name": "1680686046283blur_bg.jpg",
                            "size": 1250
                        }
                    ],
                    "id": 811422052095
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-01T15:11:35.915Z",
            "modified_on": "2023-04-12T16:02:16.672Z",
            "data2": "NO remarks",
            "approved_on": "2023-04-25T03:16:38.118Z",
            "rejected_on": "2023-04-12T16:01:56.159Z",
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 9,
            "approved_by": 11,
            "userProfileId": 17
        },
        {
            "id": 141,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 34,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 5,
                    "DPA0131": 26,
                    "DPA0132": 104,
                    "DPA0133": "3.434",
                    "DPA0134": "343",
                    "DPA0336": "45",
                    "attachment": "https://eisqr-api.acuizen.com/docs/1680408935657image.png",
                    "id": 1394530149824
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-04-02T04:22:24.887Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 48,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 380,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 3,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 11,
                    "DPA0132": 43,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "20",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683125497307D1D.png",
                            "name": "1683125497307D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 530002865267
                },
                {
                    "DPA0130": 7,
                    "DPA0131": 45,
                    "DPA0132": 162,
                    "DPA0133": "20",
                    "DPA0134": "30",
                    "DPA0336": "10",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683125480607D1D.png",
                            "name": "1683125480607D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 1174209378818
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-03T14:51:39.648Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-03T14:52:01.722Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 9,
            "approved_by": 11,
            "userProfileId": 17
        },
        {
            "id": 382,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "266.29",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683529769539Screenshot 2023-05-08 123851.jpg",
                            "name": "1683529769539Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 335373057700
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-08T07:09:44.756Z",
            "modified_on": "2023-05-09T06:25:23.771Z",
            "data2": null,
            "approved_on": "2023-05-30T06:14:06.156Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 383,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "82.60",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683529960327Screenshot 2023-05-08 124226.jpg",
                            "name": "1683529960327Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 771638440737
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-08T07:12:46.281Z",
            "modified_on": "2023-05-09T06:59:06.042Z",
            "data2": null,
            "approved_on": "2023-05-30T06:53:04.606Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 384,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1082.39",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683530142095Screenshot 2023-05-08 123851.jpg",
                            "name": "1683530142095Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 1589069296305
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-08T07:15:45.390Z",
            "modified_on": "2023-05-09T06:42:10.784Z",
            "data2": null,
            "approved_on": "2023-05-30T06:15:00.608Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 385,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "371.63",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683530183716Screenshot 2023-05-08 124226.jpg",
                            "name": "1683530183716Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 500846096916
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-08T07:16:30.461Z",
            "modified_on": "2023-05-09T06:59:30.974Z",
            "data2": null,
            "approved_on": "2023-05-30T06:53:31.590Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 387,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1268.09",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683544574296Screenshot 2023-05-08 123851.jpg",
                            "name": "1683544574296Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 664981723912
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-08T11:16:19.373Z",
            "modified_on": "2023-05-09T06:42:46.645Z",
            "data2": null,
            "approved_on": "2023-05-30T06:15:40.374Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 390,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-09T06:43:32.885Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:16:19.154Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 391,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "35.75",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683615085636Screenshot 2023-05-08 123851.jpg",
                            "name": "1683615085636Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 508547167409
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-09T06:51:38.470Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:17:16.148Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 392,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "23.07",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683615137039Screenshot 2023-05-08 123851.jpg",
                            "name": "1683615137039Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 622007813797
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-09T06:52:30.030Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:17:51.416Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 393,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "350.42",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683615201029Screenshot 2023-05-08 123851.jpg",
                            "name": "1683615201029Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 193824352336
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-09T06:53:49.490Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:18:22.287Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 394,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "155.74",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683615268395Screenshot 2023-05-08 123851.jpg",
                            "name": "1683615268395Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 1227024107712
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-09T06:54:37.747Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:19:17.799Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 395,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "124.28",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683615327820Screenshot 2023-05-08 123851.jpg",
                            "name": "1683615327820Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 324224670728
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-09T06:55:40.974Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:19:49.537Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 396,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "228.78",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683615386252Screenshot 2023-05-08 123851.jpg",
                            "name": "1683615386252Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 701241514560
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-09T06:56:34.372Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:20:55.946Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 397,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "988.26",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683615425084Screenshot 2023-05-08 123851.jpg",
                            "name": "1683615425084Screenshot 2023-05-08 123851.jpg",
                            "size": 25037
                        }
                    ],
                    "id": 420847384408
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-09T06:57:21.509Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:21:56.032Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 398,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-09T06:58:05.093Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:22:14.861Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 399,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "417.22",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683615648775Screenshot 2023-05-08 124226.jpg",
                            "name": "1683615648775Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 264129319719
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-09T07:01:01.776Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:53:53.102Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 400,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2019"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T02:49:41.277Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:54:09.311Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 401,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "11.28",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683687016789Screenshot 2023-05-08 124226.jpg",
                            "name": "1683687016789Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 853470524654
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T02:50:33.215Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:54:31.944Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 402,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7.42",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683687080799Screenshot 2023-05-08 124226.jpg",
                            "name": "1683687080799Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 1339467703410
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T02:51:25.841Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:54:59.993Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 403,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "114.81",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683687120144Screenshot 2023-05-08 124226.jpg",
                            "name": "1683687120144Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 485172146027
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T02:52:16.157Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:55:22.173Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 404,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "45.68",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683687188313Screenshot 2023-05-08 124226.jpg",
                            "name": "1683687188313Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 84832898649
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T02:53:14.910Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:55:46.041Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 405,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "40.36",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683687237728Screenshot 2023-05-08 124226.jpg",
                            "name": "1683687237728Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 283238219180
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T02:54:07.724Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:56:07.959Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 406,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "81.97",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683687295303Screenshot 2023-05-08 124226.jpg",
                            "name": "1683687295303Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 409035940190
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T02:55:02.445Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:56:32.194Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 407,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "387.15",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683687334688Screenshot 2023-05-08 124226.jpg",
                            "name": "1683687334688Screenshot 2023-05-08 124226.jpg",
                            "size": 12032
                        }
                    ],
                    "id": 718634781995
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T02:55:48.661Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:57:12.404Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 408,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T02:56:37.975Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:57:28.299Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 439,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-10T10:56:11.519Z",
            "modified_on": "2023-05-11T18:25:00.327Z",
            "data2": null,
            "approved_on": "2023-05-30T06:24:10.625Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 440,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "115.68",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683718452813Oct-2020.pdf",
                            "name": "1683718452813Oct-2020.pdf",
                            "size": 173100
                        }
                    ],
                    "id": 692621216521
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T11:26:59.958Z",
            "modified_on": "2023-05-11T18:39:51.552Z",
            "data2": null,
            "approved_on": "2023-05-30T06:31:14.755Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 441,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "87.93",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683718082313SEZ I Nov-2020.pdf",
                            "name": "1683718082313SEZ I Nov-2020.pdf",
                            "size": 401704
                        }
                    ],
                    "id": 122577646780
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T11:28:08.362Z",
            "modified_on": "2023-05-22T11:27:27.816Z",
            "data2": null,
            "approved_on": "2023-11-21T09:56:39.712Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 442,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "6.51",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683718199002December-2020.pdf",
                            "name": "1683718199002December-2020.pdf",
                            "size": 71005
                        }
                    ],
                    "id": 715528109960
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T11:31:10.682Z",
            "modified_on": "2023-05-11T19:01:47.878Z",
            "data2": null,
            "approved_on": "2023-05-30T06:32:18.104Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 443,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "426.41",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683718627741February-2021.pdf",
                            "name": "1683718627741February-2021.pdf",
                            "size": 71977
                        }
                    ],
                    "id": 31430451406
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T11:37:13.367Z",
            "modified_on": "2023-05-11T19:08:36.436Z",
            "data2": null,
            "approved_on": "2023-05-30T06:33:15.429Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 444,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "37.16",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683719246910March-2021.pdf",
                            "name": "1683719246910March-2021.pdf",
                            "size": 72601
                        }
                    ],
                    "id": 480113266984
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-10T11:47:30.527Z",
            "modified_on": "2023-05-11T19:16:16.057Z",
            "data2": null,
            "approved_on": "2023-05-30T06:35:30.763Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 459,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T06:38:15.012Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:43:53.321Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 460,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "96.28096014",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683787394590May 2022.pdf",
                            "name": "1683787394590May 2022.pdf",
                            "size": 69926
                        }
                    ],
                    "id": 1056810269173
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-11T06:43:28.267Z",
            "modified_on": "2023-05-12T07:35:05.194Z",
            "data2": null,
            "approved_on": "2023-05-30T06:44:49.356Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 499,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T16:01:01.977Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:58:03.931Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 500,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T16:01:34.545Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:02:12.753Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 504,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T18:20:14.188Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:23:23.757Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 505,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T18:20:46.755Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:23:46.104Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 508,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "23.53",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683829730396July-2020.pdf",
                            "name": "1683829730396July-2020.pdf",
                            "size": 173217
                        }
                    ],
                    "id": 528440182608
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-11T18:29:18.066Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:24:49.419Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 511,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T18:34:12.564Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:25:12.052Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 513,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T18:37:01.538Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:30:45.545Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 516,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "160.51",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683830548546November-2020.pdf",
                            "name": "1683830548546November-2020.pdf",
                            "size": 70569
                        }
                    ],
                    "id": 1593203146142
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-11T18:42:41.982Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:31:51.044Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 519,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:06:10.769Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:32:45.468Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 524,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:21:33.993Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:02:27.558Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 535,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:33:39.889Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:02:58.090Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 536,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-11T19:34:28.191Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:03:23.289Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 555,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "75.04",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683868855634April-21.pdf",
                            "name": "1683868855634April-21.pdf",
                            "size": 71410
                        }
                    ],
                    "id": 733713778527
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T05:21:32.977Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:36:22.130Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 556,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T05:21:58.566Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:36:44.812Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 559,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T05:40:08.296Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:37:03.250Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 560,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "572.08",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683870076738July-21.pdf",
                            "name": "1683870076738July-21.pdf",
                            "size": 68376
                        }
                    ],
                    "id": 619527306863
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T05:47:16.752Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:37:42.071Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 561,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "532.05",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683870480752August-21.pdf",
                            "name": "1683870480752August-21.pdf",
                            "size": 70034
                        }
                    ],
                    "id": 693879161130
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T05:48:27.147Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:38:15.479Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 563,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T05:52:12.464Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:39:01.646Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 565,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "38.82",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683870840930October-21.pdf",
                            "name": "1683870840930October-21.pdf",
                            "size": 70751
                        }
                    ],
                    "id": 947901357417
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T05:54:11.336Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:39:35.878Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 567,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "27.19",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683870971549November-21.pdf",
                            "name": "1683870971549November-21.pdf",
                            "size": 71498
                        }
                    ],
                    "id": 662964668228
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T05:56:19.844Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:40:06.522Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 570,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "389.61",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683871115154December-21.pdf",
                            "name": "1683871115154December-21.pdf",
                            "size": 72590
                        }
                    ],
                    "id": 941367659403
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T05:58:40.306Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:40:37.520Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 572,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "62.72",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683871258110January-22.pdf",
                            "name": "1683871258110January-22.pdf",
                            "size": 70820
                        }
                    ],
                    "id": 1049333431735
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T06:01:08.200Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:41:04.287Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 574,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T06:03:50.603Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:41:18.403Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 589,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "03-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "598.91",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683874336998March-22.pdf",
                            "name": "1683874336998March-22.pdf",
                            "size": 70710
                        }
                    ],
                    "id": 1135059263513
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T06:52:51.020Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:42:08.356Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 593,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "122.217934",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683877069208June 2022.pdf",
                            "name": "1683877069208June 2022.pdf",
                            "size": 71984
                        }
                    ],
                    "id": 1635708786152
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T07:38:06.918Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:47:25.579Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 596,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "299.1490019",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683880574084July 2022.pdf",
                            "name": "1683880574084July 2022.pdf",
                            "size": 66660
                        }
                    ],
                    "id": 1623906661337
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T08:36:23.714Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:47:54.387Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 598,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "08-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "58.96904291",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683880814757Aug 2022.pdf",
                            "name": "1683880814757Aug 2022.pdf",
                            "size": 72280
                        }
                    ],
                    "id": 515205044052
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T08:40:26.755Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:48:17.265Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 601,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-12T09:19:34.946Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:50:11.429Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 602,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "5435.115137",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1683884298973October 2022.pdf",
                            "name": "1683884298973October 2022.pdf",
                            "size": 70400
                        }
                    ],
                    "id": 269499107327
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-12T09:38:38.088Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:50:35.986Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 604,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-15T06:13:38.986Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:50:51.315Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 605,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "12-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "97.30279687",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684131306674December 2022.pdf",
                            "name": "1684131306674December 2022.pdf",
                            "size": 70500
                        }
                    ],
                    "id": 297257612340
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-15T06:15:37.129Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:51:15.240Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 607,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-15T08:57:37.878Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 609,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-15T09:00:16.714Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 611,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:28:32.791Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 613,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:29:09.327Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 615,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:29:37.894Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 617,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:30:05.755Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 619,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:30:38.039Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 621,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:31:24.136Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 623,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2019"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:58:51.677Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 625,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T03:59:25.318Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 627,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:00:06.066Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 629,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:01:08.618Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 631,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:03:04.509Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 633,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:04:28.538Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 636,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:05:53.706Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 638,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:06:27.644Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 640,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2023"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:07:11.963Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 642,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:07:55.798Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 644,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:08:43.111Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 646,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:09:30.298Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 648,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:10:14.410Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 650,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:15:10.261Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 652,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:16:04.415Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 654,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:22:36.663Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 656,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:23:32.264Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 658,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T04:24:27.392Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 705,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:09:06.677Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 709,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:11:31.105Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 713,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:13:16.505Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 717,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:16:01.697Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 721,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:17:58.255Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 725,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:19:23.290Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 729,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2020"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:20:47.049Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 733,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:21:53.940Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 737,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:23:02.113Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 741,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:24:16.130Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 745,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "04-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:25:41.420Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 749,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:27:03.091Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 753,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:28:30.454Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 757,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "07-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:41:41.471Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 761,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "08-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:44:06.584Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 765,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:45:27.112Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 769,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "10-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:46:38.413Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 773,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "11-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:47:45.320Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 777,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "12-2021"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:51:26.853Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 781,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "01-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:52:50.005Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 785,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "02-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:53:58.101Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 789,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 45,
            "reporting_period": [
                "03-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-16T06:55:28.137Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": null,
            "submitted_by": 53,
            "approved_by": null,
            "userProfileId": 51
        },
        {
            "id": 1004,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "9.71305827",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684263618825Signature Dg - April'22.pdf",
                            "name": "1684263618825Signature Dg - April'22.pdf",
                            "size": 170820
                        }
                    ],
                    "id": 1148424210718
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-16T19:05:15.643Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:00:31.021Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1016,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10.95",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684291674427Signature Dg - May'22.pdf",
                            "name": "1684291674427Signature Dg - May'22.pdf",
                            "size": 347421
                        }
                    ],
                    "id": 1018950488543
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:49:21.131Z",
            "modified_on": "2023-05-17T06:52:44.093Z",
            "data2": null,
            "approved_on": "2023-05-31T11:00:46.847Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1017,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "14.92",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684291797777Signature Dg - June'22.pdf",
                            "name": "1684291797777Signature Dg - June'22.pdf",
                            "size": 160461
                        }
                    ],
                    "id": 952047233331
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:50:02.392Z",
            "modified_on": "2023-05-17T06:53:27.727Z",
            "data2": null,
            "approved_on": "2023-05-31T11:01:10.796Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1018,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "5.32",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684291833046Signature DG - Jul'22.pdf",
                            "name": "1684291833046Signature DG - Jul'22.pdf",
                            "size": 170484
                        }
                    ],
                    "id": 1173613140396
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:50:35.938Z",
            "modified_on": "2023-05-17T06:53:58.272Z",
            "data2": null,
            "approved_on": "2023-05-31T11:01:40.038Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1019,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "12.43",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684291886567Signature DG - Aug'22.pdf",
                            "name": "1684291886567Signature DG - Aug'22.pdf",
                            "size": 171020
                        }
                    ],
                    "id": 576664766236
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:51:28.316Z",
            "modified_on": "2023-05-17T06:54:25.723Z",
            "data2": null,
            "approved_on": "2023-05-31T11:02:38.150Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1020,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "29.27",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684291920367Signature DG - Sept'22.pdf",
                            "name": "1684291920367Signature DG - Sept'22.pdf",
                            "size": 380146
                        }
                    ],
                    "id": 1532485414048
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:52:02.985Z",
            "modified_on": "2023-05-17T06:54:58.363Z",
            "data2": null,
            "approved_on": "2023-05-31T11:02:53.981Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1021,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4.61",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684291950738Signature DG - Oct'22.pdf",
                            "name": "1684291950738Signature DG - Oct'22.pdf",
                            "size": 468077
                        }
                    ],
                    "id": 361565879764
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:52:33.762Z",
            "modified_on": "2023-05-17T06:55:24.803Z",
            "data2": null,
            "approved_on": "2023-05-31T11:03:13.384Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1022,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "11.09",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684291988117Signature DG Nov'22.pdf",
                            "name": "1684291988117Signature DG Nov'22.pdf",
                            "size": 511098
                        }
                    ],
                    "id": 384263184325
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:53:11.649Z",
            "modified_on": "2023-05-17T06:55:50.319Z",
            "data2": null,
            "approved_on": "2023-05-31T11:03:34.105Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1023,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "5.96",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684292076637Signature DG Dec'22.pdf",
                            "name": "1684292076637Signature DG Dec'22.pdf",
                            "size": 408027
                        }
                    ],
                    "id": 134129261929
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:54:39.552Z",
            "modified_on": "2023-05-17T06:56:23.292Z",
            "data2": null,
            "approved_on": "2023-05-31T11:03:47.657Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1024,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "6.59",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684292115327Signature DG Jan'23.pdf",
                            "name": "1684292115327Signature DG Jan'23.pdf",
                            "size": 428752
                        }
                    ],
                    "id": 69792181777
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:55:21.495Z",
            "modified_on": "2023-05-17T06:56:50.832Z",
            "data2": null,
            "approved_on": "2023-05-31T11:04:04.837Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1025,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "5.94",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684292145547Signature DG Feb'23.pdf",
                            "name": "1684292145547Signature DG Feb'23.pdf",
                            "size": 547951
                        }
                    ],
                    "id": 1029540361281
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:55:48.144Z",
            "modified_on": "2023-05-17T06:57:24.989Z",
            "data2": null,
            "approved_on": "2023-05-31T11:04:21.730Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1026,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7.58",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684292190775Tenant unit rate work sheet  - March'23.pdf",
                            "name": "1684292190775Tenant unit rate work sheet  - March'23.pdf",
                            "size": 71165
                        }
                    ],
                    "id": 1123063981194
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T02:56:34.753Z",
            "modified_on": "2023-05-17T06:57:56.347Z",
            "data2": null,
            "approved_on": "2023-05-31T11:04:54.372Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1125,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 10,
            "reporting_period": [
                "01-2023",
                "02-2023",
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 1,
                    "DPA0131": 1,
                    "DPA0132": 1,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "12",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684304388610D1D.png",
                            "name": "1684304388610D1D.png",
                            "size": 32765
                        }
                    ],
                    "id": 454223103235
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T06:19:57.434Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-17T06:20:16.424Z",
            "rejected_on": null,
            "frequencycd": 3,
            "reject": 0,
            "submitted_by": 9,
            "approved_by": 11,
            "userProfileId": 17
        },
        {
            "id": 1153,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3.85",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684306947198DG.pdf",
                            "name": "1684306947198DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 567545299989
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:03:44.095Z",
            "modified_on": "2023-05-17T07:20:46.278Z",
            "data2": null,
            "approved_on": "2023-05-31T11:05:09.000Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1154,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3.85",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684307044268DG.pdf",
                            "name": "1684307044268DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 1118295474546
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:04:10.419Z",
            "modified_on": "2023-05-17T07:21:01.549Z",
            "data2": null,
            "approved_on": "2023-05-31T11:05:22.770Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1155,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3.85",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684307071999DG.pdf",
                            "name": "1684307071999DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 1568789420917
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:04:34.416Z",
            "modified_on": "2023-05-17T07:21:11.756Z",
            "data2": null,
            "approved_on": "2023-05-31T11:05:35.316Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1156,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3.85",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684307099152DG.pdf",
                            "name": "1684307099152DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 263950238708
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:05:01.470Z",
            "modified_on": "2023-05-17T07:21:21.341Z",
            "data2": null,
            "approved_on": "2023-05-31T11:05:48.277Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1157,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3.85",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684307140351DG.pdf",
                            "name": "1684307140351DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 1544611289350
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:05:43.558Z",
            "modified_on": "2023-05-17T07:21:46.643Z",
            "data2": null,
            "approved_on": "2023-05-31T11:06:00.987Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1158,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3.85",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684307169671DG.pdf",
                            "name": "1684307169671DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 749134329596
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:06:15.331Z",
            "modified_on": "2023-05-17T07:22:14.441Z",
            "data2": null,
            "approved_on": "2023-05-31T11:06:14.950Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1159,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3.85",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684307201940DG.pdf",
                            "name": "1684307201940DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 757949403598
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:06:44.986Z",
            "modified_on": "2023-05-17T07:22:24.661Z",
            "data2": null,
            "approved_on": "2023-05-31T11:06:27.663Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1160,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3.85",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684307229169DG.pdf",
                            "name": "1684307229169DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 885800953827
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:07:13.263Z",
            "modified_on": "2023-05-17T07:22:38.917Z",
            "data2": null,
            "approved_on": "2023-05-31T11:06:40.091Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1161,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2019"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684307262840DG.pdf",
                            "name": "1684307262840DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 558735504921
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:19:53.013Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:06:55.391Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1162,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "5.57",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684308186358DG.pdf",
                            "name": "1684308186358DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 1151577762590
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:23:17.046Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:07:13.216Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1163,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4.70",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684308221491DG.pdf",
                            "name": "1684308221491DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 114994882300
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:23:50.265Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:07:26.263Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1164,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4.77",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684308256857DG.pdf",
                            "name": "1684308256857DG.pdf",
                            "size": 26037
                        }
                    ],
                    "id": 1405370019815
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:24:22.533Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:07:38.566Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1177,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2.55",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684309926830DG.pdf",
                            "name": "1684309926830DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 1130755365429
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:52:12.451Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:07:55.217Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1178,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1.26",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684309952699DG.pdf",
                            "name": "1684309952699DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 548568028727
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:52:37.134Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:08:08.385Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1179,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684309976638DG.pdf",
                            "name": "1684309976638DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 855906657551
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:53:31.639Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:08:23.960Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1180,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4.46",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684310032779DG.pdf",
                            "name": "1684310032779DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 1062543388402
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:53:56.917Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:08:36.860Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1181,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1.15",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684310060672DG.pdf",
                            "name": "1684310060672DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 235149432277
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:54:31.425Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:08:50.846Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1182,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684310096932DG.pdf",
                            "name": "1684310096932DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 1224329715192
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:55:03.874Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:09:04.506Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1183,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684310138117DG.pdf",
                            "name": "1684310138117DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 1389320272401
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:55:51.500Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:09:20.462Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1184,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4.71",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684310175218DG.pdf",
                            "name": "1684310175218DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 1014170507925
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:56:20.442Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:09:38.459Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1185,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2.11",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684310203978DG.pdf",
                            "name": "1684310203978DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 1266948685707
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:56:47.683Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:09:53.899Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1186,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1.03",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684310230531DG.pdf",
                            "name": "1684310230531DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 478532110768
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:57:20.807Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:10:09.173Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1187,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4.11",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684310267039DG.pdf",
                            "name": "1684310267039DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 81720523395
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:57:51.782Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:10:26.121Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1188,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684310295937DG.pdf",
                            "name": "1684310295937DG.pdf",
                            "size": 23805
                        }
                    ],
                    "id": 1103113571998
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T07:58:21.043Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:10:44.782Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1201,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "04-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311120419DG.pdf",
                            "name": "1684311120419DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 804807762841
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:12:22.422Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:11:12.309Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1202,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "05-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2.09",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311179175DG.pdf",
                            "name": "1684311179175DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 831506244792
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:13:04.597Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:11:29.241Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1203,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "06-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1.84",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311224644DG.pdf",
                            "name": "1684311224644DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 1675341118570
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:13:52.912Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:13:01.987Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1204,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "07-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2.67",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311258456DG.pdf",
                            "name": "1684311258456DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 694958062352
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:14:24.267Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-31T11:13:55.160Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1205,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "09-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "9.71",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311296271DG.pdf",
                            "name": "1684311296271DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 422354949280
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:14:59.061Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:10:39.327Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1206,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "08-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311324387DG.pdf",
                            "name": "1684311324387DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 134721787409
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:15:44.480Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:10:49.961Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1207,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "10-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "5.96",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311374243DG.pdf",
                            "name": "1684311374243DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 727903652676
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:16:18.173Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:11:00.307Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1208,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "11-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "5.68",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311405720DG.pdf",
                            "name": "1684311405720DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 733333726583
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:16:51.812Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:11:36.649Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1209,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "12-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2.53",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311438960DG.pdf",
                            "name": "1684311438960DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 1534439618959
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:17:24.710Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:11:46.910Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1210,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "01-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2.77",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311478772DG.pdf",
                            "name": "1684311478772DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 1031600233701
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:18:03.795Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:11:57.366Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1211,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "02-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4.82",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311546612DG.pdf",
                            "name": "1684311546612DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 843479736339
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:19:13.932Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:12:09.785Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1212,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 44,
            "reporting_period": [
                "03-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2.80",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684311581083DG.pdf",
                            "name": "1684311581083DG.pdf",
                            "size": 20392
                        }
                    ],
                    "id": 16471996258
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T08:19:50.216Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-06-02T07:12:19.718Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 53,
            "approved_by": 52,
            "userProfileId": 51
        },
        {
            "id": 1227,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-17T17:07:07.764Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:20:46.152Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1228,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "649.197",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684344599486Tenant unit rate work sheet  -Feb 23.pdf",
                            "name": "1684344599486Tenant unit rate work sheet  -Feb 23.pdf",
                            "size": 70209
                        }
                    ],
                    "id": 1074698779395
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-17T17:30:43.877Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:52:14.567Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1286,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "51.70",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684393731779Oct-2020.pdf",
                            "name": "1684393731779Oct-2020.pdf",
                            "size": 173100
                        }
                    ],
                    "id": 1056635428382
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-18T07:08:59.264Z",
            "modified_on": "2023-05-22T11:26:25.248Z",
            "data2": null,
            "approved_on": "2023-05-30T07:03:58.903Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1288,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 34,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 10,
                    "DPA0132": 39,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "500",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684415426864kisspng-computer-icons-red-flag-clip-art-5b37b1dfb10776.8613344015303766717251.png",
                            "name": "1684415426864kisspng-computer-icons-red-flag-clip-art-5b37b1dfb10776.8613344015303766717251.png",
                            "size": 7778
                        }
                    ],
                    "id": 943019663562
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-18T13:10:35.252Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 48,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1319,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "35.80788502",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1684666444753May 2022.pdf",
                            "name": "1684666444753May 2022.pdf",
                            "size": 69926
                        }
                    ],
                    "id": 1074692484399
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-21T10:54:09.849Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:21:09.500Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1412,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "45.49036225",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685024214071June 2022.pdf",
                            "name": "1685024214071June 2022.pdf",
                            "size": 71984
                        }
                    ],
                    "id": 1085204484754
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T14:17:07.665Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:21:36.232Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1413,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "114.6567194",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685024284300July 2022.pdf",
                            "name": "1685024284300July 2022.pdf",
                            "size": 66660
                        }
                    ],
                    "id": 680815075877
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T14:18:14.919Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:22:05.590Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1414,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "23.05305737",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685024346696Aug 2022.pdf",
                            "name": "1685024346696Aug 2022.pdf",
                            "size": 72280
                        }
                    ],
                    "id": 694879756814
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T14:19:16.511Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:22:27.784Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1415,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T14:20:02.451Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:22:46.679Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1416,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2263.092903",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685024753468October 2022.pdf",
                            "name": "1685024753468October 2022.pdf",
                            "size": 70400
                        }
                    ],
                    "id": 1150487473233
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T14:26:24.048Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:23:14.511Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1417,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T14:32:25.990Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:24:50.850Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1418,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "11.40435446",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685025272853January 2023.pdf",
                            "name": "1685025272853January 2023.pdf",
                            "size": 71187
                        }
                    ],
                    "id": 346423013317
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T14:35:17.370Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:26:04.390Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1419,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "193.9233031",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685026771797February 2023.pdf",
                            "name": "1685026771797February 2023.pdf",
                            "size": 70209
                        }
                    ],
                    "id": 491561153634
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T14:59:43.264Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:28:38.643Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1420,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "33.50",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685027023551April-21.pdf",
                            "name": "1685027023551April-21.pdf",
                            "size": 71410
                        }
                    ],
                    "id": 37232277376
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:04:40.577Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:12:16.302Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1421,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "05-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T15:05:12.403Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:12:59.191Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1422,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "06-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T15:05:39.272Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:13:18.777Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1423,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "179.89",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685027187148July-21.pdf",
                            "name": "1685027187148July-21.pdf",
                            "size": 68376
                        }
                    ],
                    "id": 511718430363
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:06:50.135Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:13:46.365Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1424,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "08-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "172.53",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685027251762August-21.pdf",
                            "name": "1685027251762August-21.pdf",
                            "size": 70034
                        }
                    ],
                    "id": 1625297819315
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:07:41.679Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:14:15.602Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1425,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "09-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T15:08:16.435Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:16:46.179Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1426,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "10-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10.56",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685027353487October-21.pdf",
                            "name": "1685027353487October-21.pdf",
                            "size": 70751
                        }
                    ],
                    "id": 1010456206187
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:09:50.816Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:17:11.005Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1427,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "11-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "9.87",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685027474408November-21.pdf",
                            "name": "1685027474408November-21.pdf",
                            "size": 71498
                        }
                    ],
                    "id": 693677359722
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:11:28.279Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:17:37.835Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1428,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "133.65",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685027722241December-21.pdf",
                            "name": "1685027722241December-21.pdf",
                            "size": 72590
                        }
                    ],
                    "id": 1427298616870
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:15:31.862Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:18:12.370Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1429,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "27.69",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685027782804January-22.pdf",
                            "name": "1685027782804January-22.pdf",
                            "size": 70820
                        }
                    ],
                    "id": 1488589014336
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:16:36.937Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:18:43.900Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1430,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2022"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T15:17:08.962Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:19:00.801Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1431,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "177.37",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685027885789March-22.pdf",
                            "name": "1685027885789March-22.pdf",
                            "size": 70710
                        }
                    ],
                    "id": 325481295694
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:18:19.999Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:20:17.192Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1432,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "07-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10.45",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685028300019July-2020.pdf",
                            "name": "1685028300019July-2020.pdf",
                            "size": 173217
                        }
                    ],
                    "id": 1039116066183
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:25:24.589Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:05:35.425Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1433,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3.35",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685028493434December-2020.pdf",
                            "name": "1685028493434December-2020.pdf",
                            "size": 71005
                        }
                    ],
                    "id": 958748332699
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:28:24.814Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:08:45.763Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1434,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "01-2021"
            ],
            "response": [],
            "type": 3,
            "edit": 1,
            "data1": [],
            "created_on": "2023-05-25T15:29:14.025Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:09:22.815Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1435,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "02-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "292.64",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685028606011February-2021.pdf",
                            "name": "1685028606011February-2021.pdf",
                            "size": 71977
                        }
                    ],
                    "id": 1110055196349
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:30:16.962Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:10:54.685Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1436,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "03-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "33.75",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685028730164March-2021.pdf",
                            "name": "1685028730164March-2021.pdf",
                            "size": 72601
                        }
                    ],
                    "id": 440244231172
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:32:14.930Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:11:28.297Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1437,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "12-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "34.8006",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685029102909December 2022.pdf",
                            "name": "1685029102909December 2022.pdf",
                            "size": 70500
                        }
                    ],
                    "id": 1561066554676
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T15:38:42.286Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T07:25:21.329Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1457,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "34.32896067",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685037655980January 2023.pdf",
                            "name": "1685037655980January 2023.pdf",
                            "size": 71187
                        }
                    ],
                    "id": 1059381954535
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-25T18:01:44.934Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-05-30T06:51:46.397Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1472,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 42,
            "reporting_period": [
                "04-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685532410459Tenant unit rate work sheet  -Apr 23.pdf",
                            "name": "1685532410459Tenant unit rate work sheet  -Apr 23.pdf",
                            "size": 72631
                        }
                    ],
                    "id": 1097788341158
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-31T11:27:16.016Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-11-21T09:16:53.996Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1473,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 43,
            "reporting_period": [
                "04-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685532512005Tenant unit rate work sheet  -Apr 23.pdf",
                            "name": "1685532512005Tenant unit rate work sheet  -Apr 23.pdf",
                            "size": 72631
                        }
                    ],
                    "id": 985123513030
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-05-31T11:28:40.272Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-11-21T09:58:37.438Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 52,
            "approved_by": 53,
            "userProfileId": 51
        },
        {
            "id": 1474,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 34,
            "reporting_period": [
                "05-2023"
            ],
            "response": [
                {
                    "DPA0130": 1,
                    "DPA0131": 3,
                    "DPA0132": 11,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "57",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1687876614745Screenshot 2023-06-26 at 11.24.11 AM.png",
                            "name": "1687876614745Screenshot 2023-06-26 at 11.24.11 AM.png",
                            "size": 88559
                        }
                    ],
                    "id": 455523252333
                },
                {
                    "DPA0130": 5,
                    "DPA0131": 28,
                    "DPA0132": 110,
                    "DPA0133": "234",
                    "DPA0134": "234",
                    "DPA0336": "776",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1687329109537Screenshot 2023-06-17 at 7.46.12 PM.png",
                            "name": "1687329109537Screenshot 2023-06-17 at 7.46.12 PM.png",
                            "size": 8750
                        }
                    ],
                    "id": 1564272405953
                },
                {
                    "DPA0130": 4,
                    "DPA0131": 10,
                    "DPA0132": 40,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "768687",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1685703465492sensor.png",
                            "name": "1685703465492sensor.png",
                            "size": 26281
                        }
                    ],
                    "id": 1290390713248
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-06-02T10:58:16.714Z",
            "modified_on": "2023-06-27T14:37:02.333Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 48,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1503,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694524737837lluejnie.png",
                            "name": "1694524737837lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 1305177935771
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:41:28.774Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-12T14:14:53.147Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1504,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "96.28",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526222295lluejnie.png",
                            "name": "1694526222295lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 381422939828
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:43:46.235Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-12T14:15:00.152Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1505,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "122.21",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526261291lluejnie.png",
                            "name": "1694526261291lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 355158165540
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:44:24.523Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-12T14:15:06.748Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1506,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "299.14",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526285803lluejnie.png",
                            "name": "1694526285803lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 1529816622561
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:47:37.046Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-12T14:15:13.873Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1507,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "08-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "58.96",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526487902lluejnie.png",
                            "name": "1694526487902lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 1008321916854
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:48:11.678Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-12T14:15:19.270Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1508,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "09-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526514052lluejnie.png",
                            "name": "1694526514052lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 295676919455
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:48:37.171Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:28:32.967Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1509,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "5435.11",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526541450lluejnie.png",
                            "name": "1694526541450lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 616127443925
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:49:04.049Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:28:42.332Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1510,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "11-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526562496lluejnie.png",
                            "name": "1694526562496lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 1074484880037
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:49:42.911Z",
            "modified_on": "2023-09-13T10:41:36.703Z",
            "data2": "kkk",
            "approved_on": "2023-09-13T10:42:14.359Z",
            "rejected_on": "2023-09-13T07:18:27.361Z",
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1511,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "12-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "34.32",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526604007lluejnie.png",
                            "name": "1694526604007lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 528557881645
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:50:06.540Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:28:22.754Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1512,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "34.32",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526826211lluejnie.png",
                            "name": "1694526826211lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 1285247107341
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:53:49.252Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:28:52.111Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1513,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "649.13",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526851368lluejnie.png",
                            "name": "1694526851368lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 264966606033
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:54:13.937Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:29:01.337Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1514,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "735",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694526897316lluejnie.png",
                            "name": "1694526897316lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 1677559091621
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T13:55:00.276Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:29:16.971Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1515,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "04-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694527334053lluejnie.png",
                            "name": "1694527334053lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 1019166461453
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T14:02:17.845Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:30:00.541Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1516,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "05-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "35.80",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694527364565lluejnie.png",
                            "name": "1694527364565lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 330613857143
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T14:02:49.519Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:29:09.281Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1517,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "06-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "45.49",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694527402311lluejnie.png",
                            "name": "1694527402311lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 656066970058
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T14:03:24.576Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:30:06.139Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1518,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "07-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "114.65",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694527478253lluejnie.png",
                            "name": "1694527478253lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 1242214136652
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T14:04:42.149Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:30:11.634Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1519,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "09-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694527524801lluejnie.png",
                            "name": "1694527524801lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 125930475574
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T14:05:26.691Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": null,
            "userProfileId": 65
        },
        {
            "id": 1520,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 50,
            "reporting_period": [
                "08-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "23.05",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694527606500lluejnie.png",
                            "name": "1694527606500lluejnie.png",
                            "size": 67001
                        }
                    ],
                    "id": 1478320184823
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-12T14:06:48.863Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-13T10:30:17.206Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 70,
            "approved_by": 71,
            "userProfileId": 65
        },
        {
            "id": 1553,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 29,
            "reporting_period": [
                "01-2022",
                "02-2022",
                "03-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 51,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694597383305Screenshot (4).png",
                            "name": "1694597383305Screenshot (4).png",
                            "size": 294900
                        }
                    ],
                    "id": 629929221993
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-13T09:27:13.222Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-21T01:31:23.222Z",
            "rejected_on": null,
            "frequencycd": 3,
            "reject": 0,
            "submitted_by": 46,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1554,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 37,
            "reporting_period": [
                "12-2020",
                "01-2021",
                "02-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 10,
                    "DPA0132": 39,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1121",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694597514194Screenshot (4).png",
                            "name": "1694597514194Screenshot (4).png",
                            "size": 294900
                        }
                    ],
                    "id": 1411655261205
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-13T09:28:59.018Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 3,
            "reject": 0,
            "submitted_by": 35,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1557,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 33,
            "reporting_period": [
                "01-2022",
                "02-2022",
                "03-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 12,
                    "DPA0132": 47,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2000",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1694680299287WhatsApp Image 2023-08-08 at 15.24.14.jpeg",
                            "name": "1694680299287WhatsApp Image 2023-08-08 at 15.24.14.jpeg",
                            "size": 196640
                        }
                    ],
                    "id": 67630406742
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-14T08:30:10.524Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 3,
            "reject": 0,
            "submitted_by": 46,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1565,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 58,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 1,
                    "DPA0131": 1,
                    "DPA0132": 2,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "5",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1695194654827pace logo export.svg",
                            "name": "1695194654827pace logo export.svg",
                            "size": 4111
                        }
                    ],
                    "id": 1410387041831
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-20T07:24:28.559Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-24T10:14:21.351Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 81,
            "approved_by": 82,
            "userProfileId": 80
        },
        {
            "id": 1604,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 41,
            "reporting_period": [
                "01-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 51,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "234",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1695272065474WhatsApp Image 2023-08-08 at 15.24.14.jpeg",
                            "name": "1695272065474WhatsApp Image 2023-08-08 at 15.24.14.jpeg",
                            "size": 196640
                        }
                    ],
                    "id": 1425534337967
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-21T04:51:22.860Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-21T04:52:49.555Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 36,
            "approved_by": 34,
            "userProfileId": 28
        },
        {
            "id": 1605,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 61,
            "reporting_period": [
                "04-2022",
                "05-2022",
                "06-2022",
                "07-2022",
                "08-2022",
                "09-2022",
                "10-2022",
                "11-2022",
                "12-2022",
                "01-2023",
                "02-2023",
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 51,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3431",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1695369800406TI_Macho Stationary Combustion.pdf",
                            "name": "1695369800406TI_Macho Stationary Combustion.pdf",
                            "size": 673183
                        }
                    ],
                    "id": 1498502572705
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-22T08:03:42.710Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-09-22T08:14:55.386Z",
            "rejected_on": null,
            "frequencycd": 4,
            "reject": 0,
            "submitted_by": 84,
            "approved_by": 85,
            "userProfileId": 83
        },
        {
            "id": 1607,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 59,
            "reporting_period": [
                "04-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 10,
                    "DPA0132": 38,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1695550073016pace logo export.svg",
                            "name": "1695550073016pace logo export.svg",
                            "size": 4111
                        }
                    ],
                    "id": 598527773001
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-24T10:08:24.517Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 81,
            "approved_by": null,
            "userProfileId": 80
        },
        {
            "id": 1627,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 36,
            "reporting_period": [
                "12-2021",
                "01-2022",
                "02-2022",
                "03-2022",
                "04-2022",
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 1,
                    "DPA0131": 4,
                    "DPA0132": 14,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2346",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1695975925733WhatsApp Image 2023-09-13 at 14.25.30.jpeg",
                            "name": "1695975925733WhatsApp Image 2023-09-13 at 14.25.30.jpeg",
                            "size": 196640
                        }
                    ],
                    "id": 933582566542
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-09-29T08:23:18.796Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 5,
            "reject": 0,
            "submitted_by": 34,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1633,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "04-2022",
                "05-2022",
                "06-2022",
                "07-2022",
                "08-2022",
                "09-2022",
                "10-2022",
                "11-2022",
                "12-2022",
                "01-2023",
                "02-2023",
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 51,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2141",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1696926640707Book1.pdf",
                            "name": "1696926640707Book1.pdf",
                            "size": 101577
                        }
                    ],
                    "id": 1691525051852
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-10T08:30:54.984Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-10-31T11:46:37.659Z",
            "rejected_on": null,
            "frequencycd": 4,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1634,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 63,
            "reporting_period": [
                "04-2022",
                "05-2022",
                "06-2022",
                "07-2022",
                "08-2022",
                "09-2022",
                "10-2022",
                "11-2022",
                "12-2022",
                "01-2023",
                "02-2023",
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 51,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "6205",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1696926827895Book21.pdf",
                            "name": "1696926827895Book21.pdf",
                            "size": 96681
                        }
                    ],
                    "id": 309655630770
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-10T08:33:51.252Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-10-31T11:45:45.841Z",
            "rejected_on": null,
            "frequencycd": 4,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1644,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 29,
            "reporting_period": [
                "07-2023",
                "08-2023",
                "09-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 50,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1500",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1696934734100image001.png",
                            "name": "1696934734100image001.png",
                            "size": 112520
                        }
                    ],
                    "id": 1326376215659
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-10T10:43:20.790Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 3,
            "reject": 0,
            "submitted_by": 46,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1645,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 33,
            "reporting_period": [
                "10-2022",
                "11-2022",
                "12-2022"
            ],
            "response": [],
            "type": 1,
            "edit": 1,
            "data1": [],
            "created_on": "2023-10-10T11:03:13.200Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 3,
            "reject": null,
            "submitted_by": 46,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1652,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 37,
            "reporting_period": [
                "06-2021",
                "07-2021",
                "08-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 12,
                    "DPA0132": 47,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "34",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1697628758450BRSR_Report.pdf",
                            "name": "1697628758450BRSR_Report.pdf",
                            "size": 187259
                        }
                    ],
                    "id": 1539522906498
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-18T11:32:45.090Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 3,
            "reject": 0,
            "submitted_by": 35,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1688,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1083",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873403097107.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873403097107.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 1698755879657
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:35:09.128Z",
            "modified_on": "2023-10-31T10:52:20.026Z",
            "data2": null,
            "approved_on": "2023-10-31T11:46:49.701Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1689,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "604",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873415540607.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873415540607.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 1592609989700
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:35:59.154Z",
            "modified_on": "2023-10-31T10:52:26.409Z",
            "data2": null,
            "approved_on": "2023-10-31T11:47:27.109Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1690,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "123",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873422070107.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873422070107.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 1639601938553
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:37:04.250Z",
            "modified_on": "2023-10-31T10:52:37.798Z",
            "data2": null,
            "approved_on": "2023-10-31T11:47:34.463Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1691,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2344",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873435056207.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873435056207.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 753478362184
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:39:23.083Z",
            "modified_on": "2023-10-31T10:52:45.599Z",
            "data2": null,
            "approved_on": "2023-10-31T11:47:43.367Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1692,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "08-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "882",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873438882407.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873438882407.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 1184165293649
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:39:51.916Z",
            "modified_on": "2023-10-31T10:52:52.714Z",
            "data2": null,
            "approved_on": "2023-10-31T11:47:54.260Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1693,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "09-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "131",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873442087107.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873442087107.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 530384603958
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:40:23.415Z",
            "modified_on": "2023-10-31T10:53:00.056Z",
            "data2": null,
            "approved_on": "2023-10-31T11:48:01.931Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1694,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "462",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873445231407.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873445231407.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 201121657891
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:40:54.809Z",
            "modified_on": "2023-10-31T10:53:32.987Z",
            "data2": null,
            "approved_on": "2023-10-31T11:48:11.616Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1695,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "11-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "413",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873448127407.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873448127407.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 1141726950894
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:41:25.716Z",
            "modified_on": "2023-10-31T10:54:04.491Z",
            "data2": null,
            "approved_on": "2023-10-31T11:48:19.646Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1696,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "12-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "38",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873451963307.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873451963307.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 858753319521
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:42:03.922Z",
            "modified_on": "2023-10-31T10:54:13.279Z",
            "data2": null,
            "approved_on": "2023-10-31T11:48:29.164Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1697,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "56",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873455305907.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873455305907.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 58095213235
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:42:35.543Z",
            "modified_on": "2023-10-31T10:54:22.455Z",
            "data2": null,
            "approved_on": "2023-10-31T11:48:40.593Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1698,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "22",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169873457983007.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169873457983007.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 24521486610
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T06:43:02.098Z",
            "modified_on": "2023-10-31T10:55:16.680Z",
            "data2": null,
            "approved_on": "2023-10-31T11:48:48.509Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1777,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 63,
            "reporting_period": [
                "06-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "443",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169874994941207.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169874994941207.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 443737140514
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T10:59:11.616Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-10-31T11:45:57.774Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1779,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 64,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "47",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169875017769407.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169875017769407.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 494415319835
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T11:03:00.750Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-10-31T11:48:56.621Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1783,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 63,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "152",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169875082423707.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169875082423707.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 1648996662548
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T11:13:52.317Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-10-31T11:46:05.678Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1784,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 63,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "218.51",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/169875105694107.09.23 ESG supportings- Admin  Facilties.pdf",
                            "name": "169875105694107.09.23 ESG supportings- Admin  Facilties.pdf",
                            "size": 121687
                        }
                    ],
                    "id": 968477251139
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-10-31T11:17:39.868Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-10-31T11:46:25.609Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 87,
            "approved_by": 88,
            "userProfileId": 86
        },
        {
            "id": 1787,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 37,
            "reporting_period": [
                "03-2021",
                "04-2021",
                "05-2021"
            ],
            "response": [
                {
                    "DPA0130": 1,
                    "DPA0131": 4,
                    "DPA0132": 14,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "24",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1698815936771CarbonFootPrint.pdf",
                            "name": "1698815936771CarbonFootPrint.pdf",
                            "size": 278117
                        }
                    ],
                    "id": 1549029609818
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-01T05:19:01.558Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 3,
            "reject": 0,
            "submitted_by": 35,
            "approved_by": null,
            "userProfileId": 28
        },
        {
            "id": 1789,
            "dcf": 11,
            "user_type": 0,
            "form_type": null,
            "site": 3,
            "reporting_period": [
                "10-2023"
            ],
            "response": [
                {
                    "DPA0130": 1,
                    "DPA0131": 1,
                    "DPA0132": 1,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1700117251215Response by Role (1).png",
                            "name": "1700117251215Response by Role (1).png",
                            "size": 35255
                        }
                    ],
                    "id": 129887461176
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-16T06:47:52.152Z",
            "modified_on": "2023-11-16T06:49:13.185Z",
            "data2": null,
            "approved_on": "2023-11-16T07:02:47.718Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 17,
            "approved_by": 11,
            "userProfileId": 17
        },
        {
            "id": 1790,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 5,
            "reporting_period": [
                "08-2023"
            ],
            "response": [
                {
                    "DPA0130": 1,
                    "DPA0131": 2,
                    "DPA0132": 6,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "397",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1700118198344JURONG PORT_Contractor Safety Culture Assessment 2023.png",
                            "name": "1700118198344JURONG PORT_Contractor Safety Culture Assessment 2023.png",
                            "size": 255292
                        }
                    ],
                    "id": 540499716262
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-16T07:03:23.709Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 11,
            "approved_by": null,
            "userProfileId": 17
        },
        {
            "id": 1791,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 56,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 1,
                    "DPA0131": 1,
                    "DPA0132": 1,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1700124476394Service Design.pdf",
                            "name": "1700124476394Service Design.pdf",
                            "size": 96066
                        }
                    ],
                    "id": 92082635149
                }
            ],
            "type": 1,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-16T08:48:19.035Z",
            "modified_on": null,
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 81,
            "approved_by": null,
            "userProfileId": 80
        },
        {
            "id": 1792,
            "dcf": 11,
            "user_type": 1,
            "form_type": null,
            "site": 3,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 1,
                    "DPA0131": 2,
                    "DPA0132": 7,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1700287403315image_2023-09-01_11-15-12.png",
                            "name": "1700287403315image_2023-09-01_11-15-12.png",
                            "size": 726703
                        }
                    ],
                    "id": 1414402754804
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-18T06:03:35.334Z",
            "modified_on": "2023-11-18T09:08:10.043Z",
            "data2": null,
            "approved_on": "2023-11-18T09:08:51.038Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 11,
            "approved_by": 9,
            "userProfileId": 17
        },
        {
            "id": 1816,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "04-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "12190",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159022301Sustainable Final report- U1.pdf",
                            "name": "1701159022301Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 838783972378
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:00:34.999Z",
            "modified_on": "2023-11-28T08:10:23.732Z",
            "data2": null,
            "approved_on": "2023-12-06T08:06:12.430Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1817,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "05-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "20901",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701158873087Sustainable Final report- U1.pdf",
                            "name": "1701158873087Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1056908849380
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:08:00.868Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:07:57.154Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1818,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "06-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "17505",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159087704Sustainable Final report- U1.pdf",
                            "name": "1701159087704Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1343986298634
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:11:29.832Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:08:05.439Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1819,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "07-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "25385",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159225684Sustainable Final report- U1.pdf",
                            "name": "1701159225684Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 754888493791
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:13:47.995Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:08:13.101Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1820,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "08-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "13956",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159406857Sustainable Final report- U1.pdf",
                            "name": "1701159406857Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 967554198956
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:16:48.801Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:08:20.337Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1821,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "09-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "54520",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159440174Sustainable Final report- U1.pdf",
                            "name": "1701159440174Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 138027776530
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:17:21.967Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:08:28.687Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1822,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "10-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "17678",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159476883Sustainable Final report- U1.pdf",
                            "name": "1701159476883Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 641149767442
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:18:03.200Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:08:36.202Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1823,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "11-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "6489",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159512736Sustainable Final report- U1.pdf",
                            "name": "1701159512736Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 19142930629
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:18:35.431Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:09:09.432Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1824,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "12-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "15290",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159616906Sustainable Final report- U1.pdf",
                            "name": "1701159616906Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 242969926496
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:20:18.994Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:09:18.456Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1825,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "01-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "20449",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159689528Sustainable Final report- U1.pdf",
                            "name": "1701159689528Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 162966565858
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:21:31.337Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:09:32.650Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1826,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "02-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "15456",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159725923Sustainable Final report- U1.pdf",
                            "name": "1701159725923Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1269817611523
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:24:21.336Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:09:41.104Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1827,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "03-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "9480",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701159917925Sustainable Final report- U1.pdf",
                            "name": "1701159917925Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 267086782660
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:25:20.823Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:09:54.141Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1828,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "04-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "19807",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160034661Sustainable Final report- U1.pdf",
                            "name": "1701160034661Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 672263877298
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:27:55.996Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:10:02.556Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1829,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "05-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "26192",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160163345Sustainable Final report- U1.pdf",
                            "name": "1701160163345Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1242336971902
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:29:27.544Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:11:08.955Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1830,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "06-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "24207",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160229018Sustainable Final report- U1.pdf",
                            "name": "1701160229018Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 631206196761
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:30:33.130Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:11:17.379Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1831,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "07-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7250",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160269668Sustainable Final report- U1.pdf",
                            "name": "1701160269668Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1297043712662
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:31:13.419Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:11:24.684Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1832,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "08-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "12180",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160310538Sustainable Final report- U1.pdf",
                            "name": "1701160310538Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 868509407554
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:31:54.274Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:11:32.263Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1833,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "09-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "14862",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160350312Sustainable Final report- U1.pdf",
                            "name": "1701160350312Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1615706666939
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:32:32.683Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:11:39.647Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1834,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "10-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10089",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160442289Sustainable Final report- U1.pdf",
                            "name": "1701160442289Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1409801715766
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:34:05.765Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:11:47.358Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1835,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "11-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7438",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160502467Sustainable Final report- U1.pdf",
                            "name": "1701160502467Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1099777282292
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:35:05.410Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:11:55.937Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1836,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "12-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2626",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160585861Sustainable Final report- U1.pdf",
                            "name": "1701160585861Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1016390307579
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:36:29.550Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:12:03.361Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1837,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "01-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10827",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160623460Sustainable Final report- U1.pdf",
                            "name": "1701160623460Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1095646578208
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:37:04.839Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:12:10.092Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1838,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "02-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "16046",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160655418Sustainable Final report- U1.pdf",
                            "name": "1701160655418Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 351448968570
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:37:37.345Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:12:17.957Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1839,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "03-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "38328",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160695666Sustainable Final report- U1.pdf",
                            "name": "1701160695666Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1189324163615
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:38:19.297Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:12:25.677Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1840,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "35765",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160787525Sustainable Final report- U1.pdf",
                            "name": "1701160787525Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 167904373713
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:39:53.023Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:12:32.912Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1841,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "11125",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160869227Sustainable Final report- U1.pdf",
                            "name": "1701160869227Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 16179883860
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:41:11.095Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:12:39.917Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1842,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "33291",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160907246Sustainable Final report- U1.pdf",
                            "name": "1701160907246Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 213937071654
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:41:49.649Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:12:47.662Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1843,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "13756",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160938415Sustainable Final report- U1.pdf",
                            "name": "1701160938415Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 734615819278
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:42:23.473Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:12:54.375Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1844,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "08-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4085",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701160972757Sustainable Final report- U1.pdf",
                            "name": "1701160972757Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 609852859421
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:42:55.459Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:13:00.221Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1845,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "09-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "26100",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161016894Sustainable Final report- U1.pdf",
                            "name": "1701161016894Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 793513769532
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:43:39.749Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:13:14.633Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1846,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4389",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161057213Sustainable Final report- U1.pdf",
                            "name": "1701161057213Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 350139191426
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:44:19.650Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:13:22.366Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1847,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "11-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "9392",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161089088Sustainable Final report- U1.pdf",
                            "name": "1701161089088Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 126302156998
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:44:50.287Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:14:08.068Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1848,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "12-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "12432",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161150619Sustainable Final report- U1.pdf",
                            "name": "1701161150619Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 940345811852
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:45:55.265Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:14:16.612Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1849,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7066",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161195745Sustainable Final report- U1.pdf",
                            "name": "1701161195745Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1621374404026
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:46:38.665Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:14:24.501Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1850,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "15165",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161267144Sustainable Final report- U1.pdf",
                            "name": "1701161267144Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1220815678014
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:47:49.498Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:14:36.391Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1851,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 68,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "22374",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161316718Sustainable Final report- U1.pdf",
                            "name": "1701161316718Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1084801954915
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:48:38.962Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:14:43.969Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 91,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1852,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "04-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3997",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161825336Sustainable Final report U2.pdf",
                            "name": "1701161825336Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1087726668957
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:57:07.287Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:29:38.379Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1853,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "05-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "24021",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161874492Sustainable Final report U2.pdf",
                            "name": "1701161874492Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 860046203059
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:57:56.171Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:29:45.465Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1854,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "06-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "37307",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161910470Sustainable Final report U2.pdf",
                            "name": "1701161910470Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 625219377069
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:58:32.647Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:29:52.282Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1855,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "07-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "27720",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701161956824Sustainable Final report U2.pdf",
                            "name": "1701161956824Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 477195803468
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T08:59:21.134Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:29:59.462Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1856,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "08-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "32497",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162025885Sustainable Final report U2.pdf",
                            "name": "1701162025885Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1157938029524
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:00:28.841Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:30:06.942Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1857,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "09-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "66502",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162059460Sustainable Final report U2.pdf",
                            "name": "1701162059460Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1218669218835
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:01:01.652Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:33:03.326Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1858,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "10-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "25049",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162291863Sustainable Final report U2.pdf",
                            "name": "1701162291863Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1694414950440
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:04:54.331Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:33:09.818Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1859,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "11-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4889",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162325262Sustainable Final report U2.pdf",
                            "name": "1701162325262Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 558083327034
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:05:27.118Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:33:16.179Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1860,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "12-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "9198",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162391114Sustainable Final report U2.pdf",
                            "name": "1701162391114Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1162906202290
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:06:32.970Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:33:22.991Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1861,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "01-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "9816",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162427696Sustainable Final report U2.pdf",
                            "name": "1701162427696Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 216564973824
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:07:10.315Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:33:29.818Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1862,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "02-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "8784",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162458692Sustainable Final report U2.pdf",
                            "name": "1701162458692Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 793352389771
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:07:40.619Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:33:58.636Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1863,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "03-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "29031",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162494001Sustainable Final report U2.pdf",
                            "name": "1701162494001Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 996869535756
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:08:17.451Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:34:06.188Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1864,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "04-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "19169",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162635790Sustainable Final report U2.pdf",
                            "name": "1701162635790Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1600372154452
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:10:37.629Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:34:13.683Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1865,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "05-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "65240",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162685200Sustainable Final report U2.pdf",
                            "name": "1701162685200Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1332434485525
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:11:28.764Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:34:20.821Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1866,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "06-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "32767",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162739417Sustainable Final report U2.pdf",
                            "name": "1701162739417Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 913042865858
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:12:23.319Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:34:49.562Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1867,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "07-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "57089",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162775932Sustainable Final report U2.pdf",
                            "name": "1701162775932Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1628384203800
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:12:57.583Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:34:57.240Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1868,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "08-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "112976",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162824129Sustainable Final report U2.pdf",
                            "name": "1701162824129Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1595753862487
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:13:46.199Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:35:03.941Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1869,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "09-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "43011",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162907857Sustainable Final report U2.pdf",
                            "name": "1701162907857Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 72081346659
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:15:10.156Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:35:10.081Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1870,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "10-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "26093",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701162998465Sustainable Final report U2.pdf",
                            "name": "1701162998465Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1185422490895
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:16:40.082Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:35:16.473Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1871,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "11-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "20135",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701163083180Sustainable Final report U2.pdf",
                            "name": "1701163083180Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1642712760650
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:18:07.421Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:35:23.790Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1872,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "12-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "24588",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701163149909Sustainable Final report U2.pdf",
                            "name": "1701163149909Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1598251134576
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:19:15.487Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:35:30.350Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1873,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "01-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "31080",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701163256602Sustainable Final report U2.pdf",
                            "name": "1701163256602Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 986745591425
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:20:58.924Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:35:36.320Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1874,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "02-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3484",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701163380765Sustainable Final report U2.pdf",
                            "name": "1701163380765Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 53149994978
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:23:03.417Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:35:43.058Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1875,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "03-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3020",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701163413799Sustainable Final report U2.pdf",
                            "name": "1701163413799Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 309908641345
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:23:35.902Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:35:49.689Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1876,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "28595",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701167379625Sustainable Final report U2.pdf",
                            "name": "1701167379625Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1402822947336
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:24:11.632Z",
            "modified_on": "2023-11-28T10:29:41.612Z",
            "data2": null,
            "approved_on": "2023-12-06T08:35:57.756Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1877,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "37022",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701163477274Sustainable Final report U2.pdf",
                            "name": "1701163477274Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 151069255772
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:24:39.270Z",
            "modified_on": "2023-11-28T10:30:06.621Z",
            "data2": null,
            "approved_on": "2023-12-06T08:36:04.823Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1878,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "26023",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701163504923Sustainable Final report U2.pdf",
                            "name": "1701163504923Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1471982533898
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T09:25:06.091Z",
            "modified_on": "2023-11-28T10:30:35.335Z",
            "data2": null,
            "approved_on": "2023-12-06T08:36:13.010Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1879,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "39444",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701165790601Sustainable Final report U2.pdf",
                            "name": "1701165790601Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1377740056946
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:03:13.288Z",
            "modified_on": "2023-11-28T10:31:12.413Z",
            "data2": null,
            "approved_on": "2023-12-06T08:36:19.612Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1880,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "08-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "28939",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701165819690Sustainable Final report U2.pdf",
                            "name": "1701165819690Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1032948561434
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:03:40.765Z",
            "modified_on": "2023-11-28T10:31:53.873Z",
            "data2": null,
            "approved_on": "2023-12-06T08:36:26.450Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1881,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "09-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "6876",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701165849274Sustainable Final report U2.pdf",
                            "name": "1701165849274Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1470895283162
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:04:10.719Z",
            "modified_on": "2023-11-28T10:32:14.298Z",
            "data2": null,
            "approved_on": "2023-12-06T08:36:33.575Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1882,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7127",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701165880980Sustainable Final report U2.pdf",
                            "name": "1701165880980Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 918654012885
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:04:42.491Z",
            "modified_on": "2023-11-28T10:32:35.740Z",
            "data2": null,
            "approved_on": "2023-12-06T08:36:40.207Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1883,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "11-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "20263",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701165905492Sustainable Final report U2.pdf",
                            "name": "1701165905492Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1289808218742
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:05:07.594Z",
            "modified_on": "2023-11-28T10:33:07.237Z",
            "data2": null,
            "approved_on": "2023-12-06T08:37:36.841Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1884,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "12-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "16825",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701165941747Sustainable Final report U2.pdf",
                            "name": "1701165941747Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 443004258675
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:05:45.100Z",
            "modified_on": "2023-11-28T10:33:38.395Z",
            "data2": null,
            "approved_on": "2023-12-06T08:37:44.566Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1885,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "3188",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701165979122Sustainable Final report U2.pdf",
                            "name": "1701165979122Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1692984006373
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:06:20.796Z",
            "modified_on": "2023-11-28T10:33:52.979Z",
            "data2": null,
            "approved_on": "2023-12-06T08:37:51.300Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1886,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "1955",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166014919Sustainable Final report U2.pdf",
                            "name": "1701166014919Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1669193592620
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:07:07.698Z",
            "modified_on": "2023-11-28T10:34:09.498Z",
            "data2": null,
            "approved_on": "2023-12-06T08:37:58.301Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1887,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "6503",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166056232Sustainable Final report U2.pdf",
                            "name": "1701166056232Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1675424447595
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:07:38.232Z",
            "modified_on": "2023-11-28T10:34:26.790Z",
            "data2": null,
            "approved_on": "2023-12-06T08:38:04.713Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": 91,
            "userProfileId": 90
        },
        {
            "id": 1888,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "04-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "28595",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166121052Sustainable Final report U2.pdf",
                            "name": "1701166121052Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 93356480598
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:08:41.986Z",
            "modified_on": "2023-11-28T10:22:29.886Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": null,
            "userProfileId": 90
        },
        {
            "id": 1889,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "05-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "37022",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166150543Sustainable Final report U2.pdf",
                            "name": "1701166150543Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1320847296711
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:09:12.944Z",
            "modified_on": "2023-11-28T10:26:21.621Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": null,
            "userProfileId": 90
        },
        {
            "id": 1890,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "06-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "26023",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166189181Sustainable Final report U2.pdf",
                            "name": "1701166189181Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1555557579529
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:09:52.697Z",
            "modified_on": "2023-11-28T10:26:24.917Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": null,
            "userProfileId": 90
        },
        {
            "id": 1891,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "07-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "39444",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166228887Sustainable Final report U2.pdf",
                            "name": "1701166228887Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 421413299823
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:10:29.864Z",
            "modified_on": "2023-11-28T10:26:27.701Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": null,
            "userProfileId": 90
        },
        {
            "id": 1892,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "08-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "28939",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166264039Sustainable Final report U2.pdf",
                            "name": "1701166264039Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 169687521528
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:11:05.480Z",
            "modified_on": "2023-11-28T10:26:31.333Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": null,
            "userProfileId": 90
        },
        {
            "id": 1893,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "09-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "6876",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166289362Sustainable Final report U2.pdf",
                            "name": "1701166289362Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 118192974986
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:11:31.626Z",
            "modified_on": "2023-11-28T10:27:39.332Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": null,
            "userProfileId": 90
        },
        {
            "id": 1894,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "10-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7127",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166317862Sustainable Final report U2.pdf",
                            "name": "1701166317862Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 1163682009950
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:12:02.352Z",
            "modified_on": "2023-11-28T10:27:54.608Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": null,
            "userProfileId": 90
        },
        {
            "id": 1895,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 69,
            "reporting_period": [
                "11-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "20263",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701166370946Sustainable Final report U2.pdf",
                            "name": "1701166370946Sustainable Final report U2.pdf",
                            "size": 194231
                        }
                    ],
                    "id": 265543078600
                }
            ],
            "type": 0,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:12:51.958Z",
            "modified_on": "2023-11-28T10:12:58.314Z",
            "data2": null,
            "approved_on": null,
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 92,
            "approved_by": null,
            "userProfileId": 90
        },
        {
            "id": 1896,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "04-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "35765",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701168775585Sustainable Final report- U1.pdf",
                            "name": "1701168775585Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 623068276489
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:50:05.984Z",
            "modified_on": "2023-11-28T10:52:57.811Z",
            "data2": null,
            "approved_on": "2023-12-06T08:14:51.454Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1897,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "05-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "11125",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701168798523Sustainable Final report- U1.pdf",
                            "name": "1701168798523Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1540066077127
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:53:19.376Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:15:03.466Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1898,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "06-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "33291",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701168823958Sustainable Final report- U1.pdf",
                            "name": "1701168823958Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1352145086740
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:53:47.165Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:15:12.511Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1899,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "07-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "13756",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701168849582Sustainable Final report- U1.pdf",
                            "name": "1701168849582Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 805675034344
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:54:10.256Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:15:19.745Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1900,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "08-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4085",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701168917461Sustainable Final report- U1.pdf",
                            "name": "1701168917461Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1679714394543
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:55:19.023Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:15:54.411Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1901,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "09-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "26100",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701168937587Sustainable Final report- U1.pdf",
                            "name": "1701168937587Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1354121930105
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:55:40.369Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:16:01.115Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1902,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "10-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "4389",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701168961028Sustainable Final report- U1.pdf",
                            "name": "1701168961028Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1178985165943
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:56:02.434Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:16:07.695Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1903,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "11-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "9392",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701168996366Sustainable Final report- U1.pdf",
                            "name": "1701168996366Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1064883918901
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:56:40.050Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:16:14.712Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1904,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "12-2020"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "12432",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169033526Sustainable Final report- U1.pdf",
                            "name": "1701169033526Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 919918110584
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:57:15.986Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:16:35.289Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1905,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "01-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7066",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169059219Sustainable Final report- U1.pdf",
                            "name": "1701169059219Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1313818483143
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:57:40.789Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:16:44.588Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1906,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "02-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "15165",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169083909Sustainable Final report- U1.pdf",
                            "name": "1701169083909Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 523440085702
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:58:05.509Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:17:02.359Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1907,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "03-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "22374",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169107510Sustainable Final report- U1.pdf",
                            "name": "1701169107510Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 995885495899
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:58:28.497Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:17:18.216Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1908,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "04-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "19807",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169149406Sustainable Final report- U1.pdf",
                            "name": "1701169149406Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 313072119887
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:59:09.954Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:17:24.955Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1909,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "05-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "26192",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169172545Sustainable Final report- U1.pdf",
                            "name": "1701169172545Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 100674484748
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:59:33.396Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:17:31.435Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1910,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "06-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "24207",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169195435Sustainable Final report- U1.pdf",
                            "name": "1701169195435Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 231800092327
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T10:59:56.610Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:17:48.839Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1911,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "07-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7250",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169221206Sustainable Final report- U1.pdf",
                            "name": "1701169221206Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1575953123826
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:00:22.642Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:17:56.727Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1912,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "08-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "12180",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169248734Sustainable Final report- U1.pdf",
                            "name": "1701169248734Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 985258121898
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:00:50.876Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:18:04.128Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1913,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "09-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "14862",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169270700Sustainable Final report- U1.pdf",
                            "name": "1701169270700Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1194796966809
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:01:12.910Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:18:13.542Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1914,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "10-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10089",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169293660Sustainable Final report- U1.pdf",
                            "name": "1701169293660Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1133637598925
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:01:35.530Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:18:24.601Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1915,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "11-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "7438",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169326926Sustainable Final report- U1.pdf",
                            "name": "1701169326926Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 528374263357
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:02:09.488Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:18:31.275Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1916,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "12-2021"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "2626",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169353369Sustainable Final report- U1.pdf",
                            "name": "1701169353369Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 485064492088
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:02:35.359Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:18:37.821Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1917,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "01-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "10827",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169432630Sustainable Final report- U1.pdf",
                            "name": "1701169432630Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 463123362281
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:03:53.462Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:18:44.496Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1918,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "02-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "16046",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169457410Sustainable Final report- U1.pdf",
                            "name": "1701169457410Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 317602203395
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:04:20.372Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:18:51.210Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1919,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "03-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "38328",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169482801Sustainable Final report- U1.pdf",
                            "name": "1701169482801Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 305893764817
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:04:43.932Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:18:58.931Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1920,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "04-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "12190",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169656962Sustainable Final report- U1.pdf",
                            "name": "1701169656962Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 188321266843
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:07:37.930Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:19:09.275Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1921,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "05-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "20901",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169680830Sustainable Final report- U1.pdf",
                            "name": "1701169680830Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 282040859909
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:08:02.065Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:19:55.061Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1922,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "06-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "17505",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169703475Sustainable Final report- U1.pdf",
                            "name": "1701169703475Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 974674768081
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:08:24.032Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:20:33.583Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1923,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "07-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "25385",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169803522Sustainable Final report- U1.pdf",
                            "name": "1701169803522Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 571796690497
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:10:04.994Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:20:40.092Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1924,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "08-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "13956",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169828773Sustainable Final report- U1.pdf",
                            "name": "1701169828773Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1566609460874
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:10:30.234Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:20:47.836Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1925,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "09-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "54520",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169852468Sustainable Final report- U1.pdf",
                            "name": "1701169852468Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1556679173753
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:10:53.203Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:21:02.016Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1926,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "10-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "17678",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169906766Sustainable Final report- U1.pdf",
                            "name": "1701169906766Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 494123996920
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:11:47.820Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:21:09.654Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1927,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "11-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "6489",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169931979Sustainable Final report- U1.pdf",
                            "name": "1701169931979Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 283839193674
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:12:12.634Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:21:16.771Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1928,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "12-2022"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "15290",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169960287Sustainable Final report- U1.pdf",
                            "name": "1701169960287Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 870319558447
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:12:41.373Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:21:26.005Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1929,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "01-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "20449",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701169985458Sustainable Final report- U1.pdf",
                            "name": "1701169985458Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 796197590765
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:13:06.760Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:21:34.242Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1930,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "02-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "15456",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701170020971Sustainable Final report- U1.pdf",
                            "name": "1701170020971Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1676624623184
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:13:43.923Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:21:40.673Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        },
        {
            "id": 1931,
            "dcf": 11,
            "user_type": 1,
            "form_type": 1,
            "site": 70,
            "reporting_period": [
                "03-2023"
            ],
            "response": [
                {
                    "DPA0130": 4,
                    "DPA0131": 13,
                    "DPA0132": 52,
                    "DPA0133": "",
                    "DPA0134": "",
                    "DPA0336": "9480",
                    "attachment": [
                        {
                            "url": "https://eisqr-api.acuizen.com/docs/1701170053511Sustainable Final report- U1.pdf",
                            "name": "1701170053511Sustainable Final report- U1.pdf",
                            "size": 189723
                        }
                    ],
                    "id": 1255435457272
                }
            ],
            "type": 3,
            "edit": 0,
            "data1": [],
            "created_on": "2023-11-28T11:14:14.907Z",
            "modified_on": null,
            "data2": null,
            "approved_on": "2023-12-06T08:21:46.627Z",
            "rejected_on": null,
            "frequencycd": 1,
            "reject": 0,
            "submitted_by": 93,
            "approved_by": 92,
            "userProfileId": 90
        }
    ]
    const checkResponse = (i_, res) => {
        if (res.length === 0) {
            return true
        } else if (res.length !== 0) {
            res.forEach((i) => {
                if (typeof i.DPA0136 === 'number') {
                    if (!fg_array.includes(i.DPA0136)) {
                        fg_array.push(i.DPA0136)
                    }
                } else {

                    return false
                }
            })
        }

    }
    const checkResponse2 = (i_, res) => {
        if (res.length === 0) {
            return true
        } else if (res.length !== 0) {
            res.forEach((i) => {
                if (typeof i.DPA0287 === 'number') {
                    if (!pgas_array.includes(i.DPA0287)) {
                        pgas_array.push(i.DPA0287)
                    }
                } else {
                    console.log(i_.id)
                    return false
                }
            })
        }
  console.log(pgas_array)
    }
    const checkResponse3 = (i_, res) => {
        if (res.length === 0) {
            return true
        } else if (res.length !== 0) {
            res.forEach((i) => {
                if (typeof i.DPA0130 === 'number' && typeof i.DPA0131 === 'number' && typeof i.DPA0132 === 'number') {

                    sc_array.push({ one: i.DPA0130, two: i.DPA0131, three: i.DPA0132 })

                } else {
                    console.log(i_.id)
                    return false
                }
            })
        }
        console.log(sc_array)
    }
    const groupArrayObject = (array, obj) => {
        return array.reduce((group, arr) => {

            let key = arr[obj];

            group[key] = group[key] ?? [];

            group[key].push(arr);

            return group;

        },

            {})
    }
    const scDPReport =(id)=>{
        let loc3 =[
            {
                "id": 5,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 811422052095,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 140,
                "userProfileId": 17
            },
            {
                "id": 34,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 1174209378818,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 7,
                "type": "number",
                "submitId": 380,
                "userProfileId": 17
            },
            {
                "id": 35,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 530002865267,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 380,
                "userProfileId": 17
            },
            {
                "id": 89,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 10,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 454223103235,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 1,
                "type": "number",
                "submitId": 1125,
                "userProfileId": 17
            },
            {
                "id": 3405,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 335373057700,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 382,
                "userProfileId": 51
            },
            {
                "id": 3411,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1589069296305,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 384,
                "userProfileId": 51
            },
            {
                "id": 3417,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 664981723912,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 387,
                "userProfileId": 51
            },
            {
                "id": 3423,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 508547167409,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 391,
                "userProfileId": 51
            },
            {
                "id": 3429,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 622007813797,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 392,
                "userProfileId": 51
            },
            {
                "id": 3435,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 193824352336,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 393,
                "userProfileId": 51
            },
            {
                "id": 3441,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1227024107712,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 394,
                "userProfileId": 51
            },
            {
                "id": 3447,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 324224670728,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 395,
                "userProfileId": 51
            },
            {
                "id": 3453,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 701241514560,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 396,
                "userProfileId": 51
            },
            {
                "id": 3459,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 420847384408,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 397,
                "userProfileId": 51
            },
            {
                "id": 3465,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 528440182608,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 508,
                "userProfileId": 51
            },
            {
                "id": 3471,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 692621216521,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 440,
                "userProfileId": 51
            },
            {
                "id": 3477,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1593203146142,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 516,
                "userProfileId": 51
            },
            {
                "id": 3483,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 715528109960,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 442,
                "userProfileId": 51
            },
            {
                "id": 3489,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 31430451406,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 443,
                "userProfileId": 51
            },
            {
                "id": 3495,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 480113266984,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 444,
                "userProfileId": 51
            },
            {
                "id": 3501,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 733713778527,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 555,
                "userProfileId": 51
            },
            {
                "id": 3507,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 619527306863,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 560,
                "userProfileId": 51
            },
            {
                "id": 3513,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 693879161130,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 561,
                "userProfileId": 51
            },
            {
                "id": 3519,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 947901357417,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 565,
                "userProfileId": 51
            },
            {
                "id": 3525,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 662964668228,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 567,
                "userProfileId": 51
            },
            {
                "id": 3531,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 941367659403,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 570,
                "userProfileId": 51
            },
            {
                "id": 3537,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1049333431735,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 572,
                "userProfileId": 51
            },
            {
                "id": 3543,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1135059263513,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 589,
                "userProfileId": 51
            },
            {
                "id": 3549,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1056810269173,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 460,
                "userProfileId": 51
            },
            {
                "id": 3555,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1635708786152,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 593,
                "userProfileId": 51
            },
            {
                "id": 3561,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1623906661337,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 596,
                "userProfileId": 51
            },
            {
                "id": 3567,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 515205044052,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 598,
                "userProfileId": 51
            },
            {
                "id": 3573,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 269499107327,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 602,
                "userProfileId": 51
            },
            {
                "id": 3579,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 297257612340,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 605,
                "userProfileId": 51
            },
            {
                "id": 3585,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1059381954535,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1457,
                "userProfileId": 51
            },
            {
                "id": 3591,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1074698779395,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1228,
                "userProfileId": 51
            },
            {
                "id": 3597,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 771638440737,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 383,
                "userProfileId": 51
            },
            {
                "id": 3603,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 500846096916,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 385,
                "userProfileId": 51
            },
            {
                "id": 3609,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 264129319719,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 399,
                "userProfileId": 51
            },
            {
                "id": 3615,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 853470524654,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 401,
                "userProfileId": 51
            },
            {
                "id": 3621,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1339467703410,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 402,
                "userProfileId": 51
            },
            {
                "id": 3627,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 485172146027,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 403,
                "userProfileId": 51
            },
            {
                "id": 3633,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 84832898649,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 404,
                "userProfileId": 51
            },
            {
                "id": 3639,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 283238219180,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 405,
                "userProfileId": 51
            },
            {
                "id": 3645,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 409035940190,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 406,
                "userProfileId": 51
            },
            {
                "id": 3651,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 718634781995,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 407,
                "userProfileId": 51
            },
            {
                "id": 3657,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1056635428382,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1286,
                "userProfileId": 51
            },
            {
                "id": 3663,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1039116066183,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1432,
                "userProfileId": 51
            },
            {
                "id": 3669,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 958748332699,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1433,
                "userProfileId": 51
            },
            {
                "id": 3675,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1110055196349,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1435,
                "userProfileId": 51
            },
            {
                "id": 3681,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 440244231172,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1436,
                "userProfileId": 51
            },
            {
                "id": 3687,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 37232277376,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1420,
                "userProfileId": 51
            },
            {
                "id": 3693,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 511718430363,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1423,
                "userProfileId": 51
            },
            {
                "id": 3699,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1625297819315,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1424,
                "userProfileId": 51
            },
            {
                "id": 3705,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1010456206187,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1426,
                "userProfileId": 51
            },
            {
                "id": 3711,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 693677359722,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1427,
                "userProfileId": 51
            },
            {
                "id": 3717,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1427298616870,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1428,
                "userProfileId": 51
            },
            {
                "id": 3723,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1488589014336,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1429,
                "userProfileId": 51
            },
            {
                "id": 3729,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 325481295694,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1431,
                "userProfileId": 51
            },
            {
                "id": 3735,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1074692484399,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1319,
                "userProfileId": 51
            },
            {
                "id": 3741,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1085204484754,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1412,
                "userProfileId": 51
            },
            {
                "id": 3747,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 680815075877,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1413,
                "userProfileId": 51
            },
            {
                "id": 3753,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 694879756814,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1414,
                "userProfileId": 51
            },
            {
                "id": 3759,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1150487473233,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1416,
                "userProfileId": 51
            },
            {
                "id": 3765,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1561066554676,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1437,
                "userProfileId": 51
            },
            {
                "id": 3771,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 346423013317,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1418,
                "userProfileId": 51
            },
            {
                "id": 3777,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2023"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 491561153634,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1419,
                "userProfileId": 51
            },
            {
                "id": 4007,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1148424210718,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1004,
                "userProfileId": 51
            },
            {
                "id": 4013,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1018950488543,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1016,
                "userProfileId": 51
            },
            {
                "id": 4019,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 952047233331,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1017,
                "userProfileId": 51
            },
            {
                "id": 4025,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1173613140396,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1018,
                "userProfileId": 51
            },
            {
                "id": 4031,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 576664766236,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1019,
                "userProfileId": 51
            },
            {
                "id": 4037,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1532485414048,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1020,
                "userProfileId": 51
            },
            {
                "id": 4043,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 361565879764,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1021,
                "userProfileId": 51
            },
            {
                "id": 4049,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 384263184325,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1022,
                "userProfileId": 51
            },
            {
                "id": 4055,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 134129261929,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1023,
                "userProfileId": 51
            },
            {
                "id": 4061,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 69792181777,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1024,
                "userProfileId": 51
            },
            {
                "id": 4067,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2023"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1029540361281,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1025,
                "userProfileId": 51
            },
            {
                "id": 4073,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2023"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1123063981194,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1026,
                "userProfileId": 51
            },
            {
                "id": 4079,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 567545299989,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1153,
                "userProfileId": 51
            },
            {
                "id": 4085,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1118295474546,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1154,
                "userProfileId": 51
            },
            {
                "id": 4091,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1568789420917,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1155,
                "userProfileId": 51
            },
            {
                "id": 4097,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 263950238708,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1156,
                "userProfileId": 51
            },
            {
                "id": 4103,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1544611289350,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1157,
                "userProfileId": 51
            },
            {
                "id": 4109,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 749134329596,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1158,
                "userProfileId": 51
            },
            {
                "id": 4115,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 757949403598,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1159,
                "userProfileId": 51
            },
            {
                "id": 4121,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 885800953827,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1160,
                "userProfileId": 51
            },
            {
                "id": 4127,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 558735504921,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1161,
                "userProfileId": 51
            },
            {
                "id": 4133,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1151577762590,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1162,
                "userProfileId": 51
            },
            {
                "id": 4139,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 114994882300,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1163,
                "userProfileId": 51
            },
            {
                "id": 4145,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1405370019815,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1164,
                "userProfileId": 51
            },
            {
                "id": 4151,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1130755365429,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1177,
                "userProfileId": 51
            },
            {
                "id": 4157,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 548568028727,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1178,
                "userProfileId": 51
            },
            {
                "id": 4163,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 855906657551,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1179,
                "userProfileId": 51
            },
            {
                "id": 4169,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1062543388402,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1180,
                "userProfileId": 51
            },
            {
                "id": 4175,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 235149432277,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1181,
                "userProfileId": 51
            },
            {
                "id": 4181,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1224329715192,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1182,
                "userProfileId": 51
            },
            {
                "id": 4187,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1389320272401,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1183,
                "userProfileId": 51
            },
            {
                "id": 4193,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1014170507925,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1184,
                "userProfileId": 51
            },
            {
                "id": 4199,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1266948685707,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1185,
                "userProfileId": 51
            },
            {
                "id": 4205,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 478532110768,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1186,
                "userProfileId": 51
            },
            {
                "id": 4211,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 81720523395,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1187,
                "userProfileId": 51
            },
            {
                "id": 4217,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1103113571998,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1188,
                "userProfileId": 51
            },
            {
                "id": 4223,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 804807762841,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1201,
                "userProfileId": 51
            },
            {
                "id": 4229,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 831506244792,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1202,
                "userProfileId": 51
            },
            {
                "id": 4235,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1675341118570,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1203,
                "userProfileId": 51
            },
            {
                "id": 4241,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 694958062352,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1204,
                "userProfileId": 51
            },
            {
                "id": 4247,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 422354949280,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1205,
                "userProfileId": 51
            },
            {
                "id": 4253,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 134721787409,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1206,
                "userProfileId": 51
            },
            {
                "id": 4259,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 727903652676,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1207,
                "userProfileId": 51
            },
            {
                "id": 4265,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 733333726583,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1208,
                "userProfileId": 51
            },
            {
                "id": 4271,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1534439618959,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1209,
                "userProfileId": 51
            },
            {
                "id": 4277,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1031600233701,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1210,
                "userProfileId": 51
            },
            {
                "id": 4283,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 843479736339,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1211,
                "userProfileId": 51
            },
            {
                "id": 4289,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 16471996258,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1212,
                "userProfileId": 51
            },
            {
                "id": 5416,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1305177935771,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1503,
                "userProfileId": 65
            },
            {
                "id": 5422,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 381422939828,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1504,
                "userProfileId": 65
            },
            {
                "id": 5428,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 355158165540,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1505,
                "userProfileId": 65
            },
            {
                "id": 5434,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1529816622561,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1506,
                "userProfileId": 65
            },
            {
                "id": 5440,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1008321916854,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1507,
                "userProfileId": 65
            },
            {
                "id": 5554,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 528557881645,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1511,
                "userProfileId": 65
            },
            {
                "id": 5560,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 295676919455,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1508,
                "userProfileId": 65
            },
            {
                "id": 5566,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 616127443925,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1509,
                "userProfileId": 65
            },
            {
                "id": 5572,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1285247107341,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1512,
                "userProfileId": 65
            },
            {
                "id": 5578,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 264966606033,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1513,
                "userProfileId": 65
            },
            {
                "id": 5584,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 330613857143,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1516,
                "userProfileId": 65
            },
            {
                "id": 5590,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1677559091621,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1514,
                "userProfileId": 65
            },
            {
                "id": 5596,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1019166461453,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1515,
                "userProfileId": 65
            },
            {
                "id": 5602,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 656066970058,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1517,
                "userProfileId": 65
            },
            {
                "id": 5608,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1242214136652,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1518,
                "userProfileId": 65
            },
            {
                "id": 5614,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1478320184823,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1520,
                "userProfileId": 65
            },
            {
                "id": 5684,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1074484880037,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1510,
                "userProfileId": 65
            },
            {
                "id": 6998,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2022",
                    "02-2022",
                    "03-2022"
                ],
                "site": 29,
                "clientId": 28,
                "reporter_id": 46,
                "reviewer_id": 34,
                "form_id": 629929221993,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1553,
                "userProfileId": 28
            },
            {
                "id": 7108,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2021"
                ],
                "site": 41,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 1425534337967,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1604,
                "userProfileId": 28
            },
            {
                "id": 7114,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022",
                    "05-2022",
                    "06-2022",
                    "07-2022",
                    "08-2022",
                    "09-2022",
                    "10-2022",
                    "11-2022",
                    "12-2022",
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 61,
                "clientId": 83,
                "reporter_id": 84,
                "reviewer_id": 85,
                "form_id": 1498502572705,
                "form_type": 1,
                "year": [
                    "2022",
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1605,
                "userProfileId": 83
            },
            {
                "id": 7131,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2023"
                ],
                "site": 58,
                "clientId": 80,
                "reporter_id": 81,
                "reviewer_id": 82,
                "form_id": 1410387041831,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 1,
                "type": "number",
                "submitId": 1565,
                "userProfileId": 80
            },
            {
                "id": 8065,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022",
                    "05-2022",
                    "06-2022",
                    "07-2022",
                    "08-2022",
                    "09-2022",
                    "10-2022",
                    "11-2022",
                    "12-2022",
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 309655630770,
                "form_type": 1,
                "year": [
                    "2022",
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1634,
                "userProfileId": 86
            },
            {
                "id": 8071,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2023"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 443737140514,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1777,
                "userProfileId": 86
            },
            {
                "id": 8077,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1648996662548,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1783,
                "userProfileId": 86
            },
            {
                "id": 8083,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2022"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 968477251139,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1784,
                "userProfileId": 86
            },
            {
                "id": 8089,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022",
                    "05-2022",
                    "06-2022",
                    "07-2022",
                    "08-2022",
                    "09-2022",
                    "10-2022",
                    "11-2022",
                    "12-2022",
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1691525051852,
                "form_type": 1,
                "year": [
                    "2022",
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1633,
                "userProfileId": 86
            },
            {
                "id": 8095,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1698755879657,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1688,
                "userProfileId": 86
            },
            {
                "id": 8101,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1592609989700,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1689,
                "userProfileId": 86
            },
            {
                "id": 8107,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1639601938553,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1690,
                "userProfileId": 86
            },
            {
                "id": 8113,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 753478362184,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1691,
                "userProfileId": 86
            },
            {
                "id": 8119,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1184165293649,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1692,
                "userProfileId": 86
            },
            {
                "id": 8125,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 530384603958,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1693,
                "userProfileId": 86
            },
            {
                "id": 8131,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 201121657891,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1694,
                "userProfileId": 86
            },
            {
                "id": 8137,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1141726950894,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1695,
                "userProfileId": 86
            },
            {
                "id": 8143,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 858753319521,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1696,
                "userProfileId": 86
            },
            {
                "id": 8149,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 58095213235,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1697,
                "userProfileId": 86
            },
            {
                "id": 8155,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 24521486610,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1698,
                "userProfileId": 86
            },
            {
                "id": 8161,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 494415319835,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1779,
                "userProfileId": 86
            },
            {
                "id": 8325,
                "user_type": "0",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 17,
                "reviewer_id": 11,
                "form_id": 129887461176,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 1,
                "type": "number",
                "submitId": 1789,
                "userProfileId": 17
            },
            {
                "id": 8331,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 11,
                "reviewer_id": 9,
                "form_id": 1414402754804,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 1,
                "type": "number",
                "submitId": 1792,
                "userProfileId": 17
            },
            {
                "id": 8352,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1097788341158,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1472,
                "userProfileId": 51
            },
            {
                "id": 8358,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 122577646780,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 441,
                "userProfileId": 51
            },
            {
                "id": 8364,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2023"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 985123513030,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1473,
                "userProfileId": 51
            },
            {
                "id": 8374,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 838783972378,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1816,
                "userProfileId": 90
            },
            {
                "id": 8380,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1056908849380,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1817,
                "userProfileId": 90
            },
            {
                "id": 8386,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1343986298634,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1818,
                "userProfileId": 90
            },
            {
                "id": 8392,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 754888493791,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1819,
                "userProfileId": 90
            },
            {
                "id": 8398,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 967554198956,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1820,
                "userProfileId": 90
            },
            {
                "id": 8404,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 138027776530,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1821,
                "userProfileId": 90
            },
            {
                "id": 8410,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 641149767442,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1822,
                "userProfileId": 90
            },
            {
                "id": 8416,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 19142930629,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1823,
                "userProfileId": 90
            },
            {
                "id": 8422,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 242969926496,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1824,
                "userProfileId": 90
            },
            {
                "id": 8428,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 162966565858,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1825,
                "userProfileId": 90
            },
            {
                "id": 8434,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1269817611523,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1826,
                "userProfileId": 90
            },
            {
                "id": 8440,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 267086782660,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1827,
                "userProfileId": 90
            },
            {
                "id": 8446,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 672263877298,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1828,
                "userProfileId": 90
            },
            {
                "id": 8452,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1242336971902,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1829,
                "userProfileId": 90
            },
            {
                "id": 8458,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 631206196761,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1830,
                "userProfileId": 90
            },
            {
                "id": 8464,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1297043712662,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1831,
                "userProfileId": 90
            },
            {
                "id": 8470,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 868509407554,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1832,
                "userProfileId": 90
            },
            {
                "id": 8476,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1615706666939,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1833,
                "userProfileId": 90
            },
            {
                "id": 8482,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1409801715766,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1834,
                "userProfileId": 90
            },
            {
                "id": 8488,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1099777282292,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1835,
                "userProfileId": 90
            },
            {
                "id": 8494,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1016390307579,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1836,
                "userProfileId": 90
            },
            {
                "id": 8500,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1095646578208,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1837,
                "userProfileId": 90
            },
            {
                "id": 8506,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 351448968570,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1838,
                "userProfileId": 90
            },
            {
                "id": 8512,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1189324163615,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1839,
                "userProfileId": 90
            },
            {
                "id": 8518,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 167904373713,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1840,
                "userProfileId": 90
            },
            {
                "id": 8524,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 16179883860,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1841,
                "userProfileId": 90
            },
            {
                "id": 8530,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 213937071654,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1842,
                "userProfileId": 90
            },
            {
                "id": 8536,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 734615819278,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1843,
                "userProfileId": 90
            },
            {
                "id": 8542,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 609852859421,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1844,
                "userProfileId": 90
            },
            {
                "id": 8548,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 793513769532,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1845,
                "userProfileId": 90
            },
            {
                "id": 8554,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 350139191426,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1846,
                "userProfileId": 90
            },
            {
                "id": 8560,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 126302156998,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1847,
                "userProfileId": 90
            },
            {
                "id": 8566,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 940345811852,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1848,
                "userProfileId": 90
            },
            {
                "id": 8572,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1621374404026,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1849,
                "userProfileId": 90
            },
            {
                "id": 8578,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2023"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1220815678014,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1850,
                "userProfileId": 90
            },
            {
                "id": 8584,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2023"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1084801954915,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1851,
                "userProfileId": 90
            },
            {
                "id": 8590,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 623068276489,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1896,
                "userProfileId": 90
            },
            {
                "id": 8596,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1540066077127,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1897,
                "userProfileId": 90
            },
            {
                "id": 8602,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1352145086740,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1898,
                "userProfileId": 90
            },
            {
                "id": 8608,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 805675034344,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1899,
                "userProfileId": 90
            },
            {
                "id": 8614,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1679714394543,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1900,
                "userProfileId": 90
            },
            {
                "id": 8620,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1354121930105,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1901,
                "userProfileId": 90
            },
            {
                "id": 8626,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1178985165943,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1902,
                "userProfileId": 90
            },
            {
                "id": 8632,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1064883918901,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1903,
                "userProfileId": 90
            },
            {
                "id": 8638,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 919918110584,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1904,
                "userProfileId": 90
            },
            {
                "id": 8644,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1313818483143,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1905,
                "userProfileId": 90
            },
            {
                "id": 8650,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 523440085702,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1906,
                "userProfileId": 90
            },
            {
                "id": 8656,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 995885495899,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1907,
                "userProfileId": 90
            },
            {
                "id": 8662,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 313072119887,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1908,
                "userProfileId": 90
            },
            {
                "id": 8668,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 100674484748,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1909,
                "userProfileId": 90
            },
            {
                "id": 8674,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 231800092327,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1910,
                "userProfileId": 90
            },
            {
                "id": 8680,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1575953123826,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1911,
                "userProfileId": 90
            },
            {
                "id": 8686,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 985258121898,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1912,
                "userProfileId": 90
            },
            {
                "id": 8692,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1194796966809,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1913,
                "userProfileId": 90
            },
            {
                "id": 8698,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1133637598925,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1914,
                "userProfileId": 90
            },
            {
                "id": 8704,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 528374263357,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1915,
                "userProfileId": 90
            },
            {
                "id": 8710,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 485064492088,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1916,
                "userProfileId": 90
            },
            {
                "id": 8716,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 463123362281,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1917,
                "userProfileId": 90
            },
            {
                "id": 8722,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 317602203395,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1918,
                "userProfileId": 90
            },
            {
                "id": 8728,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 305893764817,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1919,
                "userProfileId": 90
            },
            {
                "id": 8734,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 188321266843,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1920,
                "userProfileId": 90
            },
            {
                "id": 8740,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 282040859909,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1921,
                "userProfileId": 90
            },
            {
                "id": 8746,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 974674768081,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1922,
                "userProfileId": 90
            },
            {
                "id": 8752,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 571796690497,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1923,
                "userProfileId": 90
            },
            {
                "id": 8758,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1566609460874,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1924,
                "userProfileId": 90
            },
            {
                "id": 8764,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1556679173753,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1925,
                "userProfileId": 90
            },
            {
                "id": 8770,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 494123996920,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1926,
                "userProfileId": 90
            },
            {
                "id": 8776,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 283839193674,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1927,
                "userProfileId": 90
            },
            {
                "id": 8782,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 870319558447,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1928,
                "userProfileId": 90
            },
            {
                "id": 8788,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 796197590765,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1929,
                "userProfileId": 90
            },
            {
                "id": 8794,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2023"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1676624623184,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1930,
                "userProfileId": 90
            },
            {
                "id": 8800,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2023"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1255435457272,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1931,
                "userProfileId": 90
            },
            {
                "id": 8950,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1087726668957,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1852,
                "userProfileId": 90
            },
            {
                "id": 8956,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 860046203059,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1853,
                "userProfileId": 90
            },
            {
                "id": 8962,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 625219377069,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1854,
                "userProfileId": 90
            },
            {
                "id": 8968,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 477195803468,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1855,
                "userProfileId": 90
            },
            {
                "id": 8974,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1157938029524,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1856,
                "userProfileId": 90
            },
            {
                "id": 8980,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1218669218835,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1857,
                "userProfileId": 90
            },
            {
                "id": 8986,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1694414950440,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1858,
                "userProfileId": 90
            },
            {
                "id": 8992,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 558083327034,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1859,
                "userProfileId": 90
            },
            {
                "id": 8998,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1162906202290,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1860,
                "userProfileId": 90
            },
            {
                "id": 9004,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 216564973824,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1861,
                "userProfileId": 90
            },
            {
                "id": 9010,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 793352389771,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1862,
                "userProfileId": 90
            },
            {
                "id": 9016,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 996869535756,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1863,
                "userProfileId": 90
            },
            {
                "id": 9022,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1600372154452,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1864,
                "userProfileId": 90
            },
            {
                "id": 9028,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1332434485525,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1865,
                "userProfileId": 90
            },
            {
                "id": 9034,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 913042865858,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1866,
                "userProfileId": 90
            },
            {
                "id": 9040,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1628384203800,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1867,
                "userProfileId": 90
            },
            {
                "id": 9046,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1595753862487,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1868,
                "userProfileId": 90
            },
            {
                "id": 9052,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 72081346659,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1869,
                "userProfileId": 90
            },
            {
                "id": 9058,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1185422490895,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1870,
                "userProfileId": 90
            },
            {
                "id": 9064,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1642712760650,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1871,
                "userProfileId": 90
            },
            {
                "id": 9070,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1598251134576,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1872,
                "userProfileId": 90
            },
            {
                "id": 9076,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 986745591425,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1873,
                "userProfileId": 90
            },
            {
                "id": 9082,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 53149994978,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1874,
                "userProfileId": 90
            },
            {
                "id": 9088,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 309908641345,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1875,
                "userProfileId": 90
            },
            {
                "id": 9094,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "04-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1402822947336,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1876,
                "userProfileId": 90
            },
            {
                "id": 9100,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "05-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 151069255772,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1877,
                "userProfileId": 90
            },
            {
                "id": 9106,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "06-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1471982533898,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1878,
                "userProfileId": 90
            },
            {
                "id": 9112,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "07-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1377740056946,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1879,
                "userProfileId": 90
            },
            {
                "id": 9118,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "08-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1032948561434,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1880,
                "userProfileId": 90
            },
            {
                "id": 9124,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "09-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1470895283162,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1881,
                "userProfileId": 90
            },
            {
                "id": 9130,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "10-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 918654012885,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1882,
                "userProfileId": 90
            },
            {
                "id": 9136,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "11-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1289808218742,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1883,
                "userProfileId": 90
            },
            {
                "id": 9142,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "12-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 443004258675,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1884,
                "userProfileId": 90
            },
            {
                "id": 9148,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "01-2023"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1692984006373,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1885,
                "userProfileId": 90
            },
            {
                "id": 9154,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "02-2023"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1669193592620,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1886,
                "userProfileId": 90
            },
            {
                "id": 9160,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0130",
                "rp": [
                    "03-2023"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1675424447595,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 4,
                "type": "number",
                "submitId": 1887,
                "userProfileId": 90
            }
        ]
        let loc2 =[
            {
                "id": 6,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 811422052095,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 9,
                "type": "number",
                "submitId": 140,
                "userProfileId": 17
            },
            {
                "id": 36,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 530002865267,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 11,
                "type": "number",
                "submitId": 380,
                "userProfileId": 17
            },
            {
                "id": 37,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 1174209378818,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 45,
                "type": "number",
                "submitId": 380,
                "userProfileId": 17
            },
            {
                "id": 90,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 10,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 454223103235,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 1,
                "type": "number",
                "submitId": 1125,
                "userProfileId": 17
            },
            {
                "id": 3406,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 335373057700,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 382,
                "userProfileId": 51
            },
            {
                "id": 3412,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1589069296305,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 384,
                "userProfileId": 51
            },
            {
                "id": 3418,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 664981723912,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 387,
                "userProfileId": 51
            },
            {
                "id": 3424,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 508547167409,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 391,
                "userProfileId": 51
            },
            {
                "id": 3430,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 622007813797,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 392,
                "userProfileId": 51
            },
            {
                "id": 3436,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 193824352336,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 393,
                "userProfileId": 51
            },
            {
                "id": 3442,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1227024107712,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 394,
                "userProfileId": 51
            },
            {
                "id": 3448,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 324224670728,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 395,
                "userProfileId": 51
            },
            {
                "id": 3454,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 701241514560,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 396,
                "userProfileId": 51
            },
            {
                "id": 3460,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 420847384408,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 397,
                "userProfileId": 51
            },
            {
                "id": 3466,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 528440182608,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 508,
                "userProfileId": 51
            },
            {
                "id": 3472,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 692621216521,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 440,
                "userProfileId": 51
            },
            {
                "id": 3478,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1593203146142,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 516,
                "userProfileId": 51
            },
            {
                "id": 3484,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 715528109960,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 442,
                "userProfileId": 51
            },
            {
                "id": 3490,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 31430451406,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 443,
                "userProfileId": 51
            },
            {
                "id": 3496,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 480113266984,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 444,
                "userProfileId": 51
            },
            {
                "id": 3502,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 733713778527,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 555,
                "userProfileId": 51
            },
            {
                "id": 3508,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 619527306863,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 560,
                "userProfileId": 51
            },
            {
                "id": 3514,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 693879161130,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 561,
                "userProfileId": 51
            },
            {
                "id": 3520,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 947901357417,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 565,
                "userProfileId": 51
            },
            {
                "id": 3526,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 662964668228,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 567,
                "userProfileId": 51
            },
            {
                "id": 3532,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 941367659403,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 570,
                "userProfileId": 51
            },
            {
                "id": 3538,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1049333431735,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 572,
                "userProfileId": 51
            },
            {
                "id": 3544,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1135059263513,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 589,
                "userProfileId": 51
            },
            {
                "id": 3550,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1056810269173,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 460,
                "userProfileId": 51
            },
            {
                "id": 3556,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1635708786152,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 593,
                "userProfileId": 51
            },
            {
                "id": 3562,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1623906661337,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 596,
                "userProfileId": 51
            },
            {
                "id": 3568,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 515205044052,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 598,
                "userProfileId": 51
            },
            {
                "id": 3574,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 269499107327,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 602,
                "userProfileId": 51
            },
            {
                "id": 3580,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 297257612340,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 605,
                "userProfileId": 51
            },
            {
                "id": 3586,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1059381954535,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1457,
                "userProfileId": 51
            },
            {
                "id": 3592,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1074698779395,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1228,
                "userProfileId": 51
            },
            {
                "id": 3598,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 771638440737,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 383,
                "userProfileId": 51
            },
            {
                "id": 3604,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 500846096916,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 385,
                "userProfileId": 51
            },
            {
                "id": 3610,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 264129319719,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 399,
                "userProfileId": 51
            },
            {
                "id": 3616,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 853470524654,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 401,
                "userProfileId": 51
            },
            {
                "id": 3622,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1339467703410,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 402,
                "userProfileId": 51
            },
            {
                "id": 3628,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 485172146027,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 403,
                "userProfileId": 51
            },
            {
                "id": 3634,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 84832898649,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 404,
                "userProfileId": 51
            },
            {
                "id": 3640,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 283238219180,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 405,
                "userProfileId": 51
            },
            {
                "id": 3646,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 409035940190,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 406,
                "userProfileId": 51
            },
            {
                "id": 3652,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 718634781995,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 407,
                "userProfileId": 51
            },
            {
                "id": 3658,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1056635428382,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1286,
                "userProfileId": 51
            },
            {
                "id": 3664,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1039116066183,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1432,
                "userProfileId": 51
            },
            {
                "id": 3670,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 958748332699,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1433,
                "userProfileId": 51
            },
            {
                "id": 3676,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1110055196349,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1435,
                "userProfileId": 51
            },
            {
                "id": 3682,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 440244231172,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1436,
                "userProfileId": 51
            },
            {
                "id": 3688,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 37232277376,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1420,
                "userProfileId": 51
            },
            {
                "id": 3694,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 511718430363,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1423,
                "userProfileId": 51
            },
            {
                "id": 3700,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1625297819315,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1424,
                "userProfileId": 51
            },
            {
                "id": 3706,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1010456206187,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1426,
                "userProfileId": 51
            },
            {
                "id": 3712,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 693677359722,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1427,
                "userProfileId": 51
            },
            {
                "id": 3718,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1427298616870,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1428,
                "userProfileId": 51
            },
            {
                "id": 3724,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1488589014336,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1429,
                "userProfileId": 51
            },
            {
                "id": 3730,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 325481295694,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1431,
                "userProfileId": 51
            },
            {
                "id": 3736,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1074692484399,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1319,
                "userProfileId": 51
            },
            {
                "id": 3742,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1085204484754,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1412,
                "userProfileId": 51
            },
            {
                "id": 3748,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 680815075877,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1413,
                "userProfileId": 51
            },
            {
                "id": 3754,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 694879756814,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1414,
                "userProfileId": 51
            },
            {
                "id": 3760,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1150487473233,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1416,
                "userProfileId": 51
            },
            {
                "id": 3766,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1561066554676,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1437,
                "userProfileId": 51
            },
            {
                "id": 3772,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 346423013317,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1418,
                "userProfileId": 51
            },
            {
                "id": 3778,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2023"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 491561153634,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1419,
                "userProfileId": 51
            },
            {
                "id": 4008,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1148424210718,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1004,
                "userProfileId": 51
            },
            {
                "id": 4014,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1018950488543,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1016,
                "userProfileId": 51
            },
            {
                "id": 4020,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 952047233331,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1017,
                "userProfileId": 51
            },
            {
                "id": 4026,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1173613140396,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1018,
                "userProfileId": 51
            },
            {
                "id": 4032,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 576664766236,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1019,
                "userProfileId": 51
            },
            {
                "id": 4038,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1532485414048,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1020,
                "userProfileId": 51
            },
            {
                "id": 4044,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 361565879764,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1021,
                "userProfileId": 51
            },
            {
                "id": 4050,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 384263184325,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1022,
                "userProfileId": 51
            },
            {
                "id": 4056,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 134129261929,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1023,
                "userProfileId": 51
            },
            {
                "id": 4062,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 69792181777,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1024,
                "userProfileId": 51
            },
            {
                "id": 4068,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2023"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1029540361281,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1025,
                "userProfileId": 51
            },
            {
                "id": 4074,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2023"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1123063981194,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1026,
                "userProfileId": 51
            },
            {
                "id": 4080,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 567545299989,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1153,
                "userProfileId": 51
            },
            {
                "id": 4086,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1118295474546,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1154,
                "userProfileId": 51
            },
            {
                "id": 4092,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1568789420917,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1155,
                "userProfileId": 51
            },
            {
                "id": 4098,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 263950238708,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1156,
                "userProfileId": 51
            },
            {
                "id": 4104,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1544611289350,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1157,
                "userProfileId": 51
            },
            {
                "id": 4110,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 749134329596,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1158,
                "userProfileId": 51
            },
            {
                "id": 4116,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 757949403598,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1159,
                "userProfileId": 51
            },
            {
                "id": 4122,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 885800953827,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1160,
                "userProfileId": 51
            },
            {
                "id": 4128,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 558735504921,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1161,
                "userProfileId": 51
            },
            {
                "id": 4134,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1151577762590,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1162,
                "userProfileId": 51
            },
            {
                "id": 4140,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 114994882300,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1163,
                "userProfileId": 51
            },
            {
                "id": 4146,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1405370019815,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1164,
                "userProfileId": 51
            },
            {
                "id": 4152,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1130755365429,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1177,
                "userProfileId": 51
            },
            {
                "id": 4158,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 548568028727,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1178,
                "userProfileId": 51
            },
            {
                "id": 4164,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 855906657551,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1179,
                "userProfileId": 51
            },
            {
                "id": 4170,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1062543388402,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1180,
                "userProfileId": 51
            },
            {
                "id": 4176,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 235149432277,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1181,
                "userProfileId": 51
            },
            {
                "id": 4182,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1224329715192,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1182,
                "userProfileId": 51
            },
            {
                "id": 4188,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1389320272401,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1183,
                "userProfileId": 51
            },
            {
                "id": 4194,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1014170507925,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1184,
                "userProfileId": 51
            },
            {
                "id": 4200,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1266948685707,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1185,
                "userProfileId": 51
            },
            {
                "id": 4206,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 478532110768,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1186,
                "userProfileId": 51
            },
            {
                "id": 4212,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 81720523395,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1187,
                "userProfileId": 51
            },
            {
                "id": 4218,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1103113571998,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1188,
                "userProfileId": 51
            },
            {
                "id": 4224,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 804807762841,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1201,
                "userProfileId": 51
            },
            {
                "id": 4230,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 831506244792,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1202,
                "userProfileId": 51
            },
            {
                "id": 4236,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1675341118570,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1203,
                "userProfileId": 51
            },
            {
                "id": 4242,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 694958062352,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1204,
                "userProfileId": 51
            },
            {
                "id": 4248,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 422354949280,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1205,
                "userProfileId": 51
            },
            {
                "id": 4254,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 134721787409,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1206,
                "userProfileId": 51
            },
            {
                "id": 4260,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 727903652676,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1207,
                "userProfileId": 51
            },
            {
                "id": 4266,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 733333726583,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1208,
                "userProfileId": 51
            },
            {
                "id": 4272,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1534439618959,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1209,
                "userProfileId": 51
            },
            {
                "id": 4278,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1031600233701,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1210,
                "userProfileId": 51
            },
            {
                "id": 4284,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 843479736339,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1211,
                "userProfileId": 51
            },
            {
                "id": 4290,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 16471996258,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1212,
                "userProfileId": 51
            },
            {
                "id": 5417,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1305177935771,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1503,
                "userProfileId": 65
            },
            {
                "id": 5423,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 381422939828,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1504,
                "userProfileId": 65
            },
            {
                "id": 5429,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 355158165540,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1505,
                "userProfileId": 65
            },
            {
                "id": 5435,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1529816622561,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1506,
                "userProfileId": 65
            },
            {
                "id": 5441,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1008321916854,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1507,
                "userProfileId": 65
            },
            {
                "id": 5555,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 528557881645,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1511,
                "userProfileId": 65
            },
            {
                "id": 5561,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 295676919455,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1508,
                "userProfileId": 65
            },
            {
                "id": 5567,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 616127443925,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1509,
                "userProfileId": 65
            },
            {
                "id": 5573,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1285247107341,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1512,
                "userProfileId": 65
            },
            {
                "id": 5579,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 264966606033,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1513,
                "userProfileId": 65
            },
            {
                "id": 5585,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 330613857143,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1516,
                "userProfileId": 65
            },
            {
                "id": 5591,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1677559091621,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1514,
                "userProfileId": 65
            },
            {
                "id": 5597,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1019166461453,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1515,
                "userProfileId": 65
            },
            {
                "id": 5603,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 656066970058,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1517,
                "userProfileId": 65
            },
            {
                "id": 5609,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1242214136652,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1518,
                "userProfileId": 65
            },
            {
                "id": 5615,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1478320184823,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1520,
                "userProfileId": 65
            },
            {
                "id": 5685,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1074484880037,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1510,
                "userProfileId": 65
            },
            {
                "id": 6999,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2022",
                    "02-2022",
                    "03-2022"
                ],
                "site": 29,
                "clientId": 28,
                "reporter_id": 46,
                "reviewer_id": 34,
                "form_id": 629929221993,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1553,
                "userProfileId": 28
            },
            {
                "id": 7109,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2021"
                ],
                "site": 41,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 1425534337967,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1604,
                "userProfileId": 28
            },
            {
                "id": 7115,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022",
                    "05-2022",
                    "06-2022",
                    "07-2022",
                    "08-2022",
                    "09-2022",
                    "10-2022",
                    "11-2022",
                    "12-2022",
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 61,
                "clientId": 83,
                "reporter_id": 84,
                "reviewer_id": 85,
                "form_id": 1498502572705,
                "form_type": 1,
                "year": [
                    "2022",
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1605,
                "userProfileId": 83
            },
            {
                "id": 7132,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2023"
                ],
                "site": 58,
                "clientId": 80,
                "reporter_id": 81,
                "reviewer_id": 82,
                "form_id": 1410387041831,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 1,
                "type": "number",
                "submitId": 1565,
                "userProfileId": 80
            },
            {
                "id": 8066,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022",
                    "05-2022",
                    "06-2022",
                    "07-2022",
                    "08-2022",
                    "09-2022",
                    "10-2022",
                    "11-2022",
                    "12-2022",
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 309655630770,
                "form_type": 1,
                "year": [
                    "2022",
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1634,
                "userProfileId": 86
            },
            {
                "id": 8072,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2023"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 443737140514,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1777,
                "userProfileId": 86
            },
            {
                "id": 8078,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1648996662548,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1783,
                "userProfileId": 86
            },
            {
                "id": 8084,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2022"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 968477251139,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1784,
                "userProfileId": 86
            },
            {
                "id": 8090,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022",
                    "05-2022",
                    "06-2022",
                    "07-2022",
                    "08-2022",
                    "09-2022",
                    "10-2022",
                    "11-2022",
                    "12-2022",
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1691525051852,
                "form_type": 1,
                "year": [
                    "2022",
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1633,
                "userProfileId": 86
            },
            {
                "id": 8096,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1698755879657,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1688,
                "userProfileId": 86
            },
            {
                "id": 8102,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1592609989700,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1689,
                "userProfileId": 86
            },
            {
                "id": 8108,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1639601938553,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1690,
                "userProfileId": 86
            },
            {
                "id": 8114,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 753478362184,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1691,
                "userProfileId": 86
            },
            {
                "id": 8120,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1184165293649,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1692,
                "userProfileId": 86
            },
            {
                "id": 8126,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 530384603958,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1693,
                "userProfileId": 86
            },
            {
                "id": 8132,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 201121657891,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1694,
                "userProfileId": 86
            },
            {
                "id": 8138,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1141726950894,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1695,
                "userProfileId": 86
            },
            {
                "id": 8144,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 858753319521,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1696,
                "userProfileId": 86
            },
            {
                "id": 8150,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 58095213235,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1697,
                "userProfileId": 86
            },
            {
                "id": 8156,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 24521486610,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1698,
                "userProfileId": 86
            },
            {
                "id": 8162,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 494415319835,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1779,
                "userProfileId": 86
            },
            {
                "id": 8326,
                "user_type": "0",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 17,
                "reviewer_id": 11,
                "form_id": 129887461176,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 1,
                "type": "number",
                "submitId": 1789,
                "userProfileId": 17
            },
            {
                "id": 8332,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 11,
                "reviewer_id": 9,
                "form_id": 1414402754804,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 2,
                "type": "number",
                "submitId": 1792,
                "userProfileId": 17
            },
            {
                "id": 8353,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1097788341158,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1472,
                "userProfileId": 51
            },
            {
                "id": 8359,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 122577646780,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 441,
                "userProfileId": 51
            },
            {
                "id": 8365,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2023"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 985123513030,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1473,
                "userProfileId": 51
            },
            {
                "id": 8375,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 838783972378,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1816,
                "userProfileId": 90
            },
            {
                "id": 8381,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1056908849380,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1817,
                "userProfileId": 90
            },
            {
                "id": 8387,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1343986298634,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1818,
                "userProfileId": 90
            },
            {
                "id": 8393,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 754888493791,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1819,
                "userProfileId": 90
            },
            {
                "id": 8399,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 967554198956,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1820,
                "userProfileId": 90
            },
            {
                "id": 8405,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 138027776530,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1821,
                "userProfileId": 90
            },
            {
                "id": 8411,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 641149767442,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1822,
                "userProfileId": 90
            },
            {
                "id": 8417,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 19142930629,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1823,
                "userProfileId": 90
            },
            {
                "id": 8423,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 242969926496,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1824,
                "userProfileId": 90
            },
            {
                "id": 8429,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 162966565858,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1825,
                "userProfileId": 90
            },
            {
                "id": 8435,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1269817611523,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1826,
                "userProfileId": 90
            },
            {
                "id": 8441,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 267086782660,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1827,
                "userProfileId": 90
            },
            {
                "id": 8447,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 672263877298,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1828,
                "userProfileId": 90
            },
            {
                "id": 8453,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1242336971902,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1829,
                "userProfileId": 90
            },
            {
                "id": 8459,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 631206196761,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1830,
                "userProfileId": 90
            },
            {
                "id": 8465,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1297043712662,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1831,
                "userProfileId": 90
            },
            {
                "id": 8471,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 868509407554,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1832,
                "userProfileId": 90
            },
            {
                "id": 8477,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1615706666939,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1833,
                "userProfileId": 90
            },
            {
                "id": 8483,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1409801715766,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1834,
                "userProfileId": 90
            },
            {
                "id": 8489,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1099777282292,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1835,
                "userProfileId": 90
            },
            {
                "id": 8495,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1016390307579,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1836,
                "userProfileId": 90
            },
            {
                "id": 8501,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1095646578208,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1837,
                "userProfileId": 90
            },
            {
                "id": 8507,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 351448968570,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1838,
                "userProfileId": 90
            },
            {
                "id": 8513,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1189324163615,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1839,
                "userProfileId": 90
            },
            {
                "id": 8519,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 167904373713,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1840,
                "userProfileId": 90
            },
            {
                "id": 8525,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 16179883860,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1841,
                "userProfileId": 90
            },
            {
                "id": 8531,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 213937071654,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1842,
                "userProfileId": 90
            },
            {
                "id": 8537,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 734615819278,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1843,
                "userProfileId": 90
            },
            {
                "id": 8543,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 609852859421,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1844,
                "userProfileId": 90
            },
            {
                "id": 8549,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 793513769532,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1845,
                "userProfileId": 90
            },
            {
                "id": 8555,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 350139191426,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1846,
                "userProfileId": 90
            },
            {
                "id": 8561,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 126302156998,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1847,
                "userProfileId": 90
            },
            {
                "id": 8567,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 940345811852,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1848,
                "userProfileId": 90
            },
            {
                "id": 8573,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1621374404026,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1849,
                "userProfileId": 90
            },
            {
                "id": 8579,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2023"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1220815678014,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1850,
                "userProfileId": 90
            },
            {
                "id": 8585,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2023"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1084801954915,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1851,
                "userProfileId": 90
            },
            {
                "id": 8591,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 623068276489,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1896,
                "userProfileId": 90
            },
            {
                "id": 8597,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1540066077127,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1897,
                "userProfileId": 90
            },
            {
                "id": 8603,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1352145086740,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1898,
                "userProfileId": 90
            },
            {
                "id": 8609,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 805675034344,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1899,
                "userProfileId": 90
            },
            {
                "id": 8615,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1679714394543,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1900,
                "userProfileId": 90
            },
            {
                "id": 8621,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1354121930105,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1901,
                "userProfileId": 90
            },
            {
                "id": 8627,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1178985165943,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1902,
                "userProfileId": 90
            },
            {
                "id": 8633,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1064883918901,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1903,
                "userProfileId": 90
            },
            {
                "id": 8639,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 919918110584,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1904,
                "userProfileId": 90
            },
            {
                "id": 8645,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1313818483143,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1905,
                "userProfileId": 90
            },
            {
                "id": 8651,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 523440085702,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1906,
                "userProfileId": 90
            },
            {
                "id": 8657,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 995885495899,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1907,
                "userProfileId": 90
            },
            {
                "id": 8663,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 313072119887,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1908,
                "userProfileId": 90
            },
            {
                "id": 8669,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 100674484748,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1909,
                "userProfileId": 90
            },
            {
                "id": 8675,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 231800092327,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1910,
                "userProfileId": 90
            },
            {
                "id": 8681,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1575953123826,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1911,
                "userProfileId": 90
            },
            {
                "id": 8687,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 985258121898,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1912,
                "userProfileId": 90
            },
            {
                "id": 8693,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1194796966809,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1913,
                "userProfileId": 90
            },
            {
                "id": 8699,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1133637598925,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1914,
                "userProfileId": 90
            },
            {
                "id": 8705,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 528374263357,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1915,
                "userProfileId": 90
            },
            {
                "id": 8711,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 485064492088,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1916,
                "userProfileId": 90
            },
            {
                "id": 8717,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 463123362281,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1917,
                "userProfileId": 90
            },
            {
                "id": 8723,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 317602203395,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1918,
                "userProfileId": 90
            },
            {
                "id": 8729,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 305893764817,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1919,
                "userProfileId": 90
            },
            {
                "id": 8735,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 188321266843,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1920,
                "userProfileId": 90
            },
            {
                "id": 8741,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 282040859909,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1921,
                "userProfileId": 90
            },
            {
                "id": 8747,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 974674768081,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1922,
                "userProfileId": 90
            },
            {
                "id": 8753,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 571796690497,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1923,
                "userProfileId": 90
            },
            {
                "id": 8759,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1566609460874,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1924,
                "userProfileId": 90
            },
            {
                "id": 8765,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1556679173753,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1925,
                "userProfileId": 90
            },
            {
                "id": 8771,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 494123996920,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1926,
                "userProfileId": 90
            },
            {
                "id": 8777,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 283839193674,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1927,
                "userProfileId": 90
            },
            {
                "id": 8783,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 870319558447,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1928,
                "userProfileId": 90
            },
            {
                "id": 8789,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 796197590765,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1929,
                "userProfileId": 90
            },
            {
                "id": 8795,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2023"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1676624623184,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1930,
                "userProfileId": 90
            },
            {
                "id": 8801,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2023"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1255435457272,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1931,
                "userProfileId": 90
            },
            {
                "id": 8951,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1087726668957,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1852,
                "userProfileId": 90
            },
            {
                "id": 8957,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 860046203059,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1853,
                "userProfileId": 90
            },
            {
                "id": 8963,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 625219377069,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1854,
                "userProfileId": 90
            },
            {
                "id": 8969,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 477195803468,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1855,
                "userProfileId": 90
            },
            {
                "id": 8975,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1157938029524,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1856,
                "userProfileId": 90
            },
            {
                "id": 8981,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1218669218835,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1857,
                "userProfileId": 90
            },
            {
                "id": 8987,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1694414950440,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1858,
                "userProfileId": 90
            },
            {
                "id": 8993,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 558083327034,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1859,
                "userProfileId": 90
            },
            {
                "id": 8999,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1162906202290,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1860,
                "userProfileId": 90
            },
            {
                "id": 9005,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 216564973824,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1861,
                "userProfileId": 90
            },
            {
                "id": 9011,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 793352389771,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1862,
                "userProfileId": 90
            },
            {
                "id": 9017,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 996869535756,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1863,
                "userProfileId": 90
            },
            {
                "id": 9023,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1600372154452,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1864,
                "userProfileId": 90
            },
            {
                "id": 9029,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1332434485525,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1865,
                "userProfileId": 90
            },
            {
                "id": 9035,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 913042865858,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1866,
                "userProfileId": 90
            },
            {
                "id": 9041,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1628384203800,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1867,
                "userProfileId": 90
            },
            {
                "id": 9047,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1595753862487,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1868,
                "userProfileId": 90
            },
            {
                "id": 9053,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 72081346659,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1869,
                "userProfileId": 90
            },
            {
                "id": 9059,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1185422490895,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1870,
                "userProfileId": 90
            },
            {
                "id": 9065,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1642712760650,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1871,
                "userProfileId": 90
            },
            {
                "id": 9071,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1598251134576,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1872,
                "userProfileId": 90
            },
            {
                "id": 9077,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 986745591425,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1873,
                "userProfileId": 90
            },
            {
                "id": 9083,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 53149994978,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1874,
                "userProfileId": 90
            },
            {
                "id": 9089,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 309908641345,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1875,
                "userProfileId": 90
            },
            {
                "id": 9095,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "04-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1402822947336,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1876,
                "userProfileId": 90
            },
            {
                "id": 9101,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "05-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 151069255772,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1877,
                "userProfileId": 90
            },
            {
                "id": 9107,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "06-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1471982533898,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1878,
                "userProfileId": 90
            },
            {
                "id": 9113,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "07-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1377740056946,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1879,
                "userProfileId": 90
            },
            {
                "id": 9119,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "08-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1032948561434,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1880,
                "userProfileId": 90
            },
            {
                "id": 9125,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "09-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1470895283162,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1881,
                "userProfileId": 90
            },
            {
                "id": 9131,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "10-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 918654012885,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1882,
                "userProfileId": 90
            },
            {
                "id": 9137,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "11-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1289808218742,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1883,
                "userProfileId": 90
            },
            {
                "id": 9143,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "12-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 443004258675,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1884,
                "userProfileId": 90
            },
            {
                "id": 9149,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "01-2023"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1692984006373,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1885,
                "userProfileId": 90
            },
            {
                "id": 9155,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "02-2023"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1669193592620,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1886,
                "userProfileId": 90
            },
            {
                "id": 9161,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0131",
                "rp": [
                    "03-2023"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1675424447595,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 13,
                "type": "number",
                "submitId": 1887,
                "userProfileId": 90
            }
        ]
        let loc1 = [
            {
                "id": 7,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 811422052095,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 34,
                "type": "number",
                "submitId": 140,
                "userProfileId": 17
            },
            {
                "id": 38,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 530002865267,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 43,
                "type": "number",
                "submitId": 380,
                "userProfileId": 17
            },
            {
                "id": 39,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 1174209378818,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 162,
                "type": "number",
                "submitId": 380,
                "userProfileId": 17
            },
            {
                "id": 91,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 10,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 454223103235,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 1,
                "type": "number",
                "submitId": 1125,
                "userProfileId": 17
            },
            {
                "id": 3407,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 335373057700,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 382,
                "userProfileId": 51
            },
            {
                "id": 3413,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1589069296305,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 384,
                "userProfileId": 51
            },
            {
                "id": 3419,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 664981723912,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 387,
                "userProfileId": 51
            },
            {
                "id": 3425,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 508547167409,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 391,
                "userProfileId": 51
            },
            {
                "id": 3431,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 622007813797,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 392,
                "userProfileId": 51
            },
            {
                "id": 3437,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 193824352336,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 393,
                "userProfileId": 51
            },
            {
                "id": 3443,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1227024107712,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 394,
                "userProfileId": 51
            },
            {
                "id": 3449,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 324224670728,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 395,
                "userProfileId": 51
            },
            {
                "id": 3455,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 701241514560,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 396,
                "userProfileId": 51
            },
            {
                "id": 3461,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 420847384408,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 397,
                "userProfileId": 51
            },
            {
                "id": 3467,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 528440182608,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 508,
                "userProfileId": 51
            },
            {
                "id": 3473,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 692621216521,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 440,
                "userProfileId": 51
            },
            {
                "id": 3479,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1593203146142,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 516,
                "userProfileId": 51
            },
            {
                "id": 3485,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 715528109960,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 442,
                "userProfileId": 51
            },
            {
                "id": 3491,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 31430451406,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 443,
                "userProfileId": 51
            },
            {
                "id": 3497,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 480113266984,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 444,
                "userProfileId": 51
            },
            {
                "id": 3503,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 733713778527,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 555,
                "userProfileId": 51
            },
            {
                "id": 3509,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 619527306863,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 560,
                "userProfileId": 51
            },
            {
                "id": 3515,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 693879161130,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 561,
                "userProfileId": 51
            },
            {
                "id": 3521,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 947901357417,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 565,
                "userProfileId": 51
            },
            {
                "id": 3527,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 662964668228,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 567,
                "userProfileId": 51
            },
            {
                "id": 3533,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 941367659403,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 570,
                "userProfileId": 51
            },
            {
                "id": 3539,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1049333431735,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 572,
                "userProfileId": 51
            },
            {
                "id": 3545,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1135059263513,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 589,
                "userProfileId": 51
            },
            {
                "id": 3551,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1056810269173,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 460,
                "userProfileId": 51
            },
            {
                "id": 3557,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1635708786152,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 593,
                "userProfileId": 51
            },
            {
                "id": 3563,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1623906661337,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 596,
                "userProfileId": 51
            },
            {
                "id": 3569,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 515205044052,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 598,
                "userProfileId": 51
            },
            {
                "id": 3575,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 269499107327,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 602,
                "userProfileId": 51
            },
            {
                "id": 3581,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 297257612340,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 605,
                "userProfileId": 51
            },
            {
                "id": 3587,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1059381954535,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1457,
                "userProfileId": 51
            },
            {
                "id": 3593,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1074698779395,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1228,
                "userProfileId": 51
            },
            {
                "id": 3599,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 771638440737,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 383,
                "userProfileId": 51
            },
            {
                "id": 3605,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 500846096916,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 385,
                "userProfileId": 51
            },
            {
                "id": 3611,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 264129319719,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 399,
                "userProfileId": 51
            },
            {
                "id": 3617,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 853470524654,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 401,
                "userProfileId": 51
            },
            {
                "id": 3623,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1339467703410,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 402,
                "userProfileId": 51
            },
            {
                "id": 3629,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 485172146027,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 403,
                "userProfileId": 51
            },
            {
                "id": 3635,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 84832898649,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 404,
                "userProfileId": 51
            },
            {
                "id": 3641,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2019"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 283238219180,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 405,
                "userProfileId": 51
            },
            {
                "id": 3647,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 409035940190,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 406,
                "userProfileId": 51
            },
            {
                "id": 3653,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 718634781995,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 407,
                "userProfileId": 51
            },
            {
                "id": 3659,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1056635428382,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1286,
                "userProfileId": 51
            },
            {
                "id": 3665,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1039116066183,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1432,
                "userProfileId": 51
            },
            {
                "id": 3671,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 958748332699,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1433,
                "userProfileId": 51
            },
            {
                "id": 3677,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1110055196349,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1435,
                "userProfileId": 51
            },
            {
                "id": 3683,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 440244231172,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1436,
                "userProfileId": 51
            },
            {
                "id": 3689,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 37232277376,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1420,
                "userProfileId": 51
            },
            {
                "id": 3695,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 511718430363,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1423,
                "userProfileId": 51
            },
            {
                "id": 3701,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1625297819315,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1424,
                "userProfileId": 51
            },
            {
                "id": 3707,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1010456206187,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1426,
                "userProfileId": 51
            },
            {
                "id": 3713,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 693677359722,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1427,
                "userProfileId": 51
            },
            {
                "id": 3719,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2021"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1427298616870,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1428,
                "userProfileId": 51
            },
            {
                "id": 3725,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1488589014336,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1429,
                "userProfileId": 51
            },
            {
                "id": 3731,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 325481295694,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1431,
                "userProfileId": 51
            },
            {
                "id": 3737,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1074692484399,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1319,
                "userProfileId": 51
            },
            {
                "id": 3743,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1085204484754,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1412,
                "userProfileId": 51
            },
            {
                "id": 3749,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 680815075877,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1413,
                "userProfileId": 51
            },
            {
                "id": 3755,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 694879756814,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1414,
                "userProfileId": 51
            },
            {
                "id": 3761,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1150487473233,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1416,
                "userProfileId": 51
            },
            {
                "id": 3767,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2022"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1561066554676,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1437,
                "userProfileId": 51
            },
            {
                "id": 3773,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 346423013317,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1418,
                "userProfileId": 51
            },
            {
                "id": 3779,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2023"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 491561153634,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1419,
                "userProfileId": 51
            },
            {
                "id": 4009,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1148424210718,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1004,
                "userProfileId": 51
            },
            {
                "id": 4015,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1018950488543,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1016,
                "userProfileId": 51
            },
            {
                "id": 4021,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 952047233331,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1017,
                "userProfileId": 51
            },
            {
                "id": 4027,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1173613140396,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1018,
                "userProfileId": 51
            },
            {
                "id": 4033,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 576664766236,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1019,
                "userProfileId": 51
            },
            {
                "id": 4039,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1532485414048,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1020,
                "userProfileId": 51
            },
            {
                "id": 4045,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 361565879764,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1021,
                "userProfileId": 51
            },
            {
                "id": 4051,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 384263184325,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1022,
                "userProfileId": 51
            },
            {
                "id": 4057,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 134129261929,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1023,
                "userProfileId": 51
            },
            {
                "id": 4063,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 69792181777,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1024,
                "userProfileId": 51
            },
            {
                "id": 4069,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2023"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1029540361281,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1025,
                "userProfileId": 51
            },
            {
                "id": 4075,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2023"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1123063981194,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1026,
                "userProfileId": 51
            },
            {
                "id": 4081,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 567545299989,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1153,
                "userProfileId": 51
            },
            {
                "id": 4087,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1118295474546,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1154,
                "userProfileId": 51
            },
            {
                "id": 4093,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1568789420917,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1155,
                "userProfileId": 51
            },
            {
                "id": 4099,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 263950238708,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1156,
                "userProfileId": 51
            },
            {
                "id": 4105,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1544611289350,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1157,
                "userProfileId": 51
            },
            {
                "id": 4111,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 749134329596,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1158,
                "userProfileId": 51
            },
            {
                "id": 4117,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 757949403598,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1159,
                "userProfileId": 51
            },
            {
                "id": 4123,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 885800953827,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1160,
                "userProfileId": 51
            },
            {
                "id": 4129,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2019"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 558735504921,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1161,
                "userProfileId": 51
            },
            {
                "id": 4135,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1151577762590,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1162,
                "userProfileId": 51
            },
            {
                "id": 4141,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 114994882300,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1163,
                "userProfileId": 51
            },
            {
                "id": 4147,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1405370019815,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1164,
                "userProfileId": 51
            },
            {
                "id": 4153,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1130755365429,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1177,
                "userProfileId": 51
            },
            {
                "id": 4159,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 548568028727,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1178,
                "userProfileId": 51
            },
            {
                "id": 4165,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 855906657551,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1179,
                "userProfileId": 51
            },
            {
                "id": 4171,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1062543388402,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1180,
                "userProfileId": 51
            },
            {
                "id": 4177,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 235149432277,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1181,
                "userProfileId": 51
            },
            {
                "id": 4183,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1224329715192,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1182,
                "userProfileId": 51
            },
            {
                "id": 4189,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1389320272401,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1183,
                "userProfileId": 51
            },
            {
                "id": 4195,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1014170507925,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1184,
                "userProfileId": 51
            },
            {
                "id": 4201,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2020"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1266948685707,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1185,
                "userProfileId": 51
            },
            {
                "id": 4207,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 478532110768,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1186,
                "userProfileId": 51
            },
            {
                "id": 4213,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 81720523395,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1187,
                "userProfileId": 51
            },
            {
                "id": 4219,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1103113571998,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1188,
                "userProfileId": 51
            },
            {
                "id": 4225,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 804807762841,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1201,
                "userProfileId": 51
            },
            {
                "id": 4231,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 831506244792,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1202,
                "userProfileId": 51
            },
            {
                "id": 4237,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1675341118570,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1203,
                "userProfileId": 51
            },
            {
                "id": 4243,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 694958062352,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1204,
                "userProfileId": 51
            },
            {
                "id": 4249,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 422354949280,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1205,
                "userProfileId": 51
            },
            {
                "id": 4255,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 134721787409,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1206,
                "userProfileId": 51
            },
            {
                "id": 4261,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 727903652676,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1207,
                "userProfileId": 51
            },
            {
                "id": 4267,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 733333726583,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1208,
                "userProfileId": 51
            },
            {
                "id": 4273,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2021"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1534439618959,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1209,
                "userProfileId": 51
            },
            {
                "id": 4279,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 1031600233701,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1210,
                "userProfileId": 51
            },
            {
                "id": 4285,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 843479736339,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1211,
                "userProfileId": 51
            },
            {
                "id": 4291,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2022"
                ],
                "site": 44,
                "clientId": 51,
                "reporter_id": 53,
                "reviewer_id": 52,
                "form_id": 16471996258,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1212,
                "userProfileId": 51
            },
            {
                "id": 5418,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1305177935771,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1503,
                "userProfileId": 65
            },
            {
                "id": 5424,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 381422939828,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1504,
                "userProfileId": 65
            },
            {
                "id": 5430,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 355158165540,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1505,
                "userProfileId": 65
            },
            {
                "id": 5436,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1529816622561,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1506,
                "userProfileId": 65
            },
            {
                "id": 5442,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1008321916854,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1507,
                "userProfileId": 65
            },
            {
                "id": 5556,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 528557881645,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1511,
                "userProfileId": 65
            },
            {
                "id": 5562,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 295676919455,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1508,
                "userProfileId": 65
            },
            {
                "id": 5568,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 616127443925,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1509,
                "userProfileId": 65
            },
            {
                "id": 5574,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1285247107341,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1512,
                "userProfileId": 65
            },
            {
                "id": 5580,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 264966606033,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1513,
                "userProfileId": 65
            },
            {
                "id": 5586,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 330613857143,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1516,
                "userProfileId": 65
            },
            {
                "id": 5592,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1677559091621,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1514,
                "userProfileId": 65
            },
            {
                "id": 5598,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1019166461453,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1515,
                "userProfileId": 65
            },
            {
                "id": 5604,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 656066970058,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1517,
                "userProfileId": 65
            },
            {
                "id": 5610,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1242214136652,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1518,
                "userProfileId": 65
            },
            {
                "id": 5616,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2023"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1478320184823,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1520,
                "userProfileId": 65
            },
            {
                "id": 5686,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1074484880037,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1510,
                "userProfileId": 65
            },
            {
                "id": 7000,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2022",
                    "02-2022",
                    "03-2022"
                ],
                "site": 29,
                "clientId": 28,
                "reporter_id": 46,
                "reviewer_id": 34,
                "form_id": 629929221993,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 51,
                "type": "number",
                "submitId": 1553,
                "userProfileId": 28
            },
            {
                "id": 7110,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2021"
                ],
                "site": 41,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 1425534337967,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 51,
                "type": "number",
                "submitId": 1604,
                "userProfileId": 28
            },
            {
                "id": 7116,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022",
                    "05-2022",
                    "06-2022",
                    "07-2022",
                    "08-2022",
                    "09-2022",
                    "10-2022",
                    "11-2022",
                    "12-2022",
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 61,
                "clientId": 83,
                "reporter_id": 84,
                "reviewer_id": 85,
                "form_id": 1498502572705,
                "form_type": 1,
                "year": [
                    "2022",
                    "2023"
                ],
                "value": 51,
                "type": "number",
                "submitId": 1605,
                "userProfileId": 83
            },
            {
                "id": 7133,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2023"
                ],
                "site": 58,
                "clientId": 80,
                "reporter_id": 81,
                "reviewer_id": 82,
                "form_id": 1410387041831,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 2,
                "type": "number",
                "submitId": 1565,
                "userProfileId": 80
            },
            {
                "id": 8067,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022",
                    "05-2022",
                    "06-2022",
                    "07-2022",
                    "08-2022",
                    "09-2022",
                    "10-2022",
                    "11-2022",
                    "12-2022",
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 309655630770,
                "form_type": 1,
                "year": [
                    "2022",
                    "2023"
                ],
                "value": 51,
                "type": "number",
                "submitId": 1634,
                "userProfileId": 86
            },
            {
                "id": 8073,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2023"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 443737140514,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1777,
                "userProfileId": 86
            },
            {
                "id": 8079,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1648996662548,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1783,
                "userProfileId": 86
            },
            {
                "id": 8085,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2022"
                ],
                "site": 63,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 968477251139,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1784,
                "userProfileId": 86
            },
            {
                "id": 8091,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022",
                    "05-2022",
                    "06-2022",
                    "07-2022",
                    "08-2022",
                    "09-2022",
                    "10-2022",
                    "11-2022",
                    "12-2022",
                    "01-2023",
                    "02-2023",
                    "03-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1691525051852,
                "form_type": 1,
                "year": [
                    "2022",
                    "2023"
                ],
                "value": 51,
                "type": "number",
                "submitId": 1633,
                "userProfileId": 86
            },
            {
                "id": 8097,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1698755879657,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1688,
                "userProfileId": 86
            },
            {
                "id": 8103,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1592609989700,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1689,
                "userProfileId": 86
            },
            {
                "id": 8109,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1639601938553,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1690,
                "userProfileId": 86
            },
            {
                "id": 8115,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 753478362184,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1691,
                "userProfileId": 86
            },
            {
                "id": 8121,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1184165293649,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1692,
                "userProfileId": 86
            },
            {
                "id": 8127,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 530384603958,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1693,
                "userProfileId": 86
            },
            {
                "id": 8133,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 201121657891,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1694,
                "userProfileId": 86
            },
            {
                "id": 8139,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 1141726950894,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1695,
                "userProfileId": 86
            },
            {
                "id": 8145,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2022"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 858753319521,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1696,
                "userProfileId": 86
            },
            {
                "id": 8151,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 58095213235,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1697,
                "userProfileId": 86
            },
            {
                "id": 8157,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 24521486610,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1698,
                "userProfileId": 86
            },
            {
                "id": 8163,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2023"
                ],
                "site": 64,
                "clientId": 86,
                "reporter_id": 87,
                "reviewer_id": 88,
                "form_id": 494415319835,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1779,
                "userProfileId": 86
            },
            {
                "id": 8327,
                "user_type": "0",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 17,
                "reviewer_id": 11,
                "form_id": 129887461176,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 1,
                "type": "number",
                "submitId": 1789,
                "userProfileId": 17
            },
            {
                "id": 8333,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 11,
                "reviewer_id": 9,
                "form_id": 1414402754804,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 7,
                "type": "number",
                "submitId": 1792,
                "userProfileId": 17
            },
            {
                "id": 8354,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1097788341158,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1472,
                "userProfileId": 51
            },
            {
                "id": 8360,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2020"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 122577646780,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 441,
                "userProfileId": 51
            },
            {
                "id": 8366,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2023"
                ],
                "site": 43,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 985123513030,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1473,
                "userProfileId": 51
            },
            {
                "id": 8376,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 838783972378,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1816,
                "userProfileId": 90
            },
            {
                "id": 8382,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1056908849380,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1817,
                "userProfileId": 90
            },
            {
                "id": 8388,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1343986298634,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1818,
                "userProfileId": 90
            },
            {
                "id": 8394,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 754888493791,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1819,
                "userProfileId": 90
            },
            {
                "id": 8400,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 967554198956,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1820,
                "userProfileId": 90
            },
            {
                "id": 8406,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 138027776530,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1821,
                "userProfileId": 90
            },
            {
                "id": 8412,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 641149767442,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1822,
                "userProfileId": 90
            },
            {
                "id": 8418,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 19142930629,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1823,
                "userProfileId": 90
            },
            {
                "id": 8424,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2020"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 242969926496,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1824,
                "userProfileId": 90
            },
            {
                "id": 8430,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 162966565858,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1825,
                "userProfileId": 90
            },
            {
                "id": 8436,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1269817611523,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1826,
                "userProfileId": 90
            },
            {
                "id": 8442,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 267086782660,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1827,
                "userProfileId": 90
            },
            {
                "id": 8448,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 672263877298,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1828,
                "userProfileId": 90
            },
            {
                "id": 8454,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1242336971902,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1829,
                "userProfileId": 90
            },
            {
                "id": 8460,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 631206196761,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1830,
                "userProfileId": 90
            },
            {
                "id": 8466,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1297043712662,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1831,
                "userProfileId": 90
            },
            {
                "id": 8472,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 868509407554,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1832,
                "userProfileId": 90
            },
            {
                "id": 8478,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1615706666939,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1833,
                "userProfileId": 90
            },
            {
                "id": 8484,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1409801715766,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1834,
                "userProfileId": 90
            },
            {
                "id": 8490,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1099777282292,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1835,
                "userProfileId": 90
            },
            {
                "id": 8496,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2021"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1016390307579,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1836,
                "userProfileId": 90
            },
            {
                "id": 8502,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1095646578208,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1837,
                "userProfileId": 90
            },
            {
                "id": 8508,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 351448968570,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1838,
                "userProfileId": 90
            },
            {
                "id": 8514,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1189324163615,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1839,
                "userProfileId": 90
            },
            {
                "id": 8520,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 167904373713,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1840,
                "userProfileId": 90
            },
            {
                "id": 8526,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 16179883860,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1841,
                "userProfileId": 90
            },
            {
                "id": 8532,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 213937071654,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1842,
                "userProfileId": 90
            },
            {
                "id": 8538,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 734615819278,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1843,
                "userProfileId": 90
            },
            {
                "id": 8544,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 609852859421,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1844,
                "userProfileId": 90
            },
            {
                "id": 8550,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 793513769532,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1845,
                "userProfileId": 90
            },
            {
                "id": 8556,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 350139191426,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1846,
                "userProfileId": 90
            },
            {
                "id": 8562,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 126302156998,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1847,
                "userProfileId": 90
            },
            {
                "id": 8568,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2022"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 940345811852,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1848,
                "userProfileId": 90
            },
            {
                "id": 8574,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1621374404026,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1849,
                "userProfileId": 90
            },
            {
                "id": 8580,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2023"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1220815678014,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1850,
                "userProfileId": 90
            },
            {
                "id": 8586,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2023"
                ],
                "site": 68,
                "clientId": 90,
                "reporter_id": 91,
                "reviewer_id": 92,
                "form_id": 1084801954915,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1851,
                "userProfileId": 90
            },
            {
                "id": 8592,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 623068276489,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1896,
                "userProfileId": 90
            },
            {
                "id": 8598,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1540066077127,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1897,
                "userProfileId": 90
            },
            {
                "id": 8604,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1352145086740,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1898,
                "userProfileId": 90
            },
            {
                "id": 8610,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 805675034344,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1899,
                "userProfileId": 90
            },
            {
                "id": 8616,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1679714394543,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1900,
                "userProfileId": 90
            },
            {
                "id": 8622,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1354121930105,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1901,
                "userProfileId": 90
            },
            {
                "id": 8628,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1178985165943,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1902,
                "userProfileId": 90
            },
            {
                "id": 8634,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1064883918901,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1903,
                "userProfileId": 90
            },
            {
                "id": 8640,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2020"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 919918110584,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1904,
                "userProfileId": 90
            },
            {
                "id": 8646,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1313818483143,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1905,
                "userProfileId": 90
            },
            {
                "id": 8652,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 523440085702,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1906,
                "userProfileId": 90
            },
            {
                "id": 8658,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 995885495899,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1907,
                "userProfileId": 90
            },
            {
                "id": 8664,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 313072119887,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1908,
                "userProfileId": 90
            },
            {
                "id": 8670,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 100674484748,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1909,
                "userProfileId": 90
            },
            {
                "id": 8676,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 231800092327,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1910,
                "userProfileId": 90
            },
            {
                "id": 8682,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1575953123826,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1911,
                "userProfileId": 90
            },
            {
                "id": 8688,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 985258121898,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1912,
                "userProfileId": 90
            },
            {
                "id": 8694,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1194796966809,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1913,
                "userProfileId": 90
            },
            {
                "id": 8700,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1133637598925,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1914,
                "userProfileId": 90
            },
            {
                "id": 8706,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 528374263357,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1915,
                "userProfileId": 90
            },
            {
                "id": 8712,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2021"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 485064492088,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1916,
                "userProfileId": 90
            },
            {
                "id": 8718,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 463123362281,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1917,
                "userProfileId": 90
            },
            {
                "id": 8724,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 317602203395,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1918,
                "userProfileId": 90
            },
            {
                "id": 8730,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 305893764817,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1919,
                "userProfileId": 90
            },
            {
                "id": 8736,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 188321266843,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1920,
                "userProfileId": 90
            },
            {
                "id": 8742,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 282040859909,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1921,
                "userProfileId": 90
            },
            {
                "id": 8748,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 974674768081,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1922,
                "userProfileId": 90
            },
            {
                "id": 8754,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 571796690497,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1923,
                "userProfileId": 90
            },
            {
                "id": 8760,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1566609460874,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1924,
                "userProfileId": 90
            },
            {
                "id": 8766,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1556679173753,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1925,
                "userProfileId": 90
            },
            {
                "id": 8772,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 494123996920,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1926,
                "userProfileId": 90
            },
            {
                "id": 8778,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 283839193674,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1927,
                "userProfileId": 90
            },
            {
                "id": 8784,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2022"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 870319558447,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1928,
                "userProfileId": 90
            },
            {
                "id": 8790,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 796197590765,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1929,
                "userProfileId": 90
            },
            {
                "id": 8796,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2023"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1676624623184,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1930,
                "userProfileId": 90
            },
            {
                "id": 8802,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2023"
                ],
                "site": 70,
                "clientId": 90,
                "reporter_id": 93,
                "reviewer_id": 92,
                "form_id": 1255435457272,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1931,
                "userProfileId": 90
            },
            {
                "id": 8952,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1087726668957,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1852,
                "userProfileId": 90
            },
            {
                "id": 8958,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 860046203059,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1853,
                "userProfileId": 90
            },
            {
                "id": 8964,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 625219377069,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1854,
                "userProfileId": 90
            },
            {
                "id": 8970,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 477195803468,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1855,
                "userProfileId": 90
            },
            {
                "id": 8976,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1157938029524,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1856,
                "userProfileId": 90
            },
            {
                "id": 8982,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1218669218835,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1857,
                "userProfileId": 90
            },
            {
                "id": 8988,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1694414950440,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1858,
                "userProfileId": 90
            },
            {
                "id": 8994,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 558083327034,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1859,
                "userProfileId": 90
            },
            {
                "id": 9000,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2020"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1162906202290,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1860,
                "userProfileId": 90
            },
            {
                "id": 9006,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 216564973824,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1861,
                "userProfileId": 90
            },
            {
                "id": 9012,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 793352389771,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1862,
                "userProfileId": 90
            },
            {
                "id": 9018,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 996869535756,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1863,
                "userProfileId": 90
            },
            {
                "id": 9024,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1600372154452,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1864,
                "userProfileId": 90
            },
            {
                "id": 9030,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1332434485525,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1865,
                "userProfileId": 90
            },
            {
                "id": 9036,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 913042865858,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1866,
                "userProfileId": 90
            },
            {
                "id": 9042,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1628384203800,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1867,
                "userProfileId": 90
            },
            {
                "id": 9048,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1595753862487,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1868,
                "userProfileId": 90
            },
            {
                "id": 9054,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 72081346659,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1869,
                "userProfileId": 90
            },
            {
                "id": 9060,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1185422490895,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1870,
                "userProfileId": 90
            },
            {
                "id": 9066,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1642712760650,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1871,
                "userProfileId": 90
            },
            {
                "id": 9072,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2021"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1598251134576,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1872,
                "userProfileId": 90
            },
            {
                "id": 9078,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 986745591425,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1873,
                "userProfileId": 90
            },
            {
                "id": 9084,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 53149994978,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1874,
                "userProfileId": 90
            },
            {
                "id": 9090,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 309908641345,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1875,
                "userProfileId": 90
            },
            {
                "id": 9096,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "04-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1402822947336,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1876,
                "userProfileId": 90
            },
            {
                "id": 9102,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "05-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 151069255772,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1877,
                "userProfileId": 90
            },
            {
                "id": 9108,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "06-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1471982533898,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1878,
                "userProfileId": 90
            },
            {
                "id": 9114,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "07-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1377740056946,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1879,
                "userProfileId": 90
            },
            {
                "id": 9120,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "08-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1032948561434,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1880,
                "userProfileId": 90
            },
            {
                "id": 9126,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "09-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1470895283162,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1881,
                "userProfileId": 90
            },
            {
                "id": 9132,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "10-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 918654012885,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1882,
                "userProfileId": 90
            },
            {
                "id": 9138,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "11-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1289808218742,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1883,
                "userProfileId": 90
            },
            {
                "id": 9144,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "12-2022"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 443004258675,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1884,
                "userProfileId": 90
            },
            {
                "id": 9150,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "01-2023"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1692984006373,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1885,
                "userProfileId": 90
            },
            {
                "id": 9156,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "02-2023"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1669193592620,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1886,
                "userProfileId": 90
            },
            {
                "id": 9162,
                "user_type": "1",
                "dcfId": 11,
                "dpId": "DPA0132",
                "rp": [
                    "03-2023"
                ],
                "site": 69,
                "clientId": 90,
                "reporter_id": 92,
                "reviewer_id": 91,
                "form_id": 1675424447595,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 52,
                "type": "number",
                "submitId": 1887,
                "userProfileId": 90
            }
        ]
let loc = [...loc3,...loc2,...loc1]
let array =[]
      let data_ = groupArrayObject(loc,'form_id')
      Object.values(data_).forEach((i)=>{
        let obj = {}
        i.forEach((j)=>{
            obj[j.dpId] = j.value
        })
array.push(obj)
      })
      console.log(array)
    }
    const fgReport = ()=>{
        let loc = [
            {
                "id": 20,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "02-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 555372047852,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 17,
                "type": "number",
                "submitId": 50,
                "userProfileId": 17
            },
            {
                "id": 23,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "02-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 291166904784,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 19,
                "type": "number",
                "submitId": 50,
                "userProfileId": 17
            },
            {
                "id": 30,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "01-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 390965016623,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 146,
                "type": "number",
                "submitId": 378,
                "userProfileId": 17
            },
            {
                "id": 31,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "01-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 947026472096,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 17,
                "type": "number",
                "submitId": 378,
                "userProfileId": 17
            },
            {
                "id": 83,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "01-2023",
                    "02-2023"
                ],
                "site": 12,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 1624762380579,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 27,
                "type": "number",
                "submitId": 606,
                "userProfileId": 17
            },
            {
                "id": 84,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "01-2023",
                    "02-2023"
                ],
                "site": 12,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 564606392041,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 18,
                "type": "number",
                "submitId": 606,
                "userProfileId": 17
            },
            {
                "id": 85,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "01-2023",
                    "02-2023"
                ],
                "site": 12,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 195145862678,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 16,
                "type": "number",
                "submitId": 606,
                "userProfileId": 17
            },
            {
                "id": 541,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "02-2023"
                ],
                "site": 22,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 1249862694047,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 48,
                "type": "number",
                "submitId": 1458,
                "userProfileId": 28
            },
            {
                "id": 545,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "03-2023"
                ],
                "site": 22,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 986393670517,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 16,
                "type": "number",
                "submitId": 1439,
                "userProfileId": 28
            },
            {
                "id": 3351,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "04-2019"
                ],
                "site": 22,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 407793723194,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 16,
                "type": "number",
                "submitId": 1382,
                "userProfileId": 28
            },
            {
                "id": 3352,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "04-2019"
                ],
                "site": 22,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 641184189815,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 18,
                "type": "number",
                "submitId": 1382,
                "userProfileId": 28
            },
            {
                "id": 3357,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "07-2019"
                ],
                "site": 22,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 833724783590,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 17,
                "type": "number",
                "submitId": 1385,
                "userProfileId": 28
            },
            {
                "id": 3361,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "11-2019"
                ],
                "site": 22,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 1235454459217,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 16,
                "type": "number",
                "submitId": 1389,
                "userProfileId": 28
            },
            {
                "id": 3365,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "02-2020"
                ],
                "site": 22,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 267558440737,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 16,
                "type": "number",
                "submitId": 1392,
                "userProfileId": 28
            },
            {
                "id": 3369,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "05-2020"
                ],
                "site": 22,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 1509821050240,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 17,
                "type": "number",
                "submitId": 1395,
                "userProfileId": 28
            },
            {
                "id": 3373,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "07-2020"
                ],
                "site": 22,
                "clientId": 28,
                "reporter_id": 36,
                "reviewer_id": 34,
                "form_id": 1559774869543,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 17,
                "type": "number",
                "submitId": 1397,
                "userProfileId": 28
            },
            {
                "id": 3785,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "04-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 259328601030,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 142,
                "type": "number",
                "submitId": 386,
                "userProfileId": 51
            },
            {
                "id": 3789,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "05-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 896695589131,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 58,
                "type": "number",
                "submitId": 411,
                "userProfileId": 51
            },
            {
                "id": 3793,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "07-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 373202861521,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 142,
                "type": "number",
                "submitId": 413,
                "userProfileId": 51
            },
            {
                "id": 3797,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "09-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 751121322379,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 142,
                "type": "number",
                "submitId": 420,
                "userProfileId": 51
            },
            {
                "id": 3801,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "12-2019"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 570661507350,
                "form_type": 1,
                "year": [
                    "2019"
                ],
                "value": 142,
                "type": "number",
                "submitId": 423,
                "userProfileId": 51
            },
            {
                "id": 3805,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "02-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1203056812535,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 65,
                "type": "number",
                "submitId": 425,
                "userProfileId": 51
            },
            {
                "id": 3809,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "05-2020"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 235903985282,
                "form_type": 1,
                "year": [
                    "2020"
                ],
                "value": 142,
                "type": "number",
                "submitId": 445,
                "userProfileId": 51
            },
            {
                "id": 3813,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "04-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 771729129217,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 142,
                "type": "number",
                "submitId": 557,
                "userProfileId": 51
            },
            {
                "id": 3817,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "08-2021"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 538260553172,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 142,
                "type": "number",
                "submitId": 562,
                "userProfileId": 51
            },
            {
                "id": 3821,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "02-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1395194937127,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 65,
                "type": "number",
                "submitId": 575,
                "userProfileId": 51
            },
            {
                "id": 3825,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "04-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1641971814850,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 65,
                "type": "number",
                "submitId": 592,
                "userProfileId": 51
            },
            {
                "id": 3829,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "05-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1153385240140,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 65,
                "type": "number",
                "submitId": 594,
                "userProfileId": 51
            },
            {
                "id": 3833,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "07-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 1165042664334,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 142,
                "type": "number",
                "submitId": 597,
                "userProfileId": 51
            },
            {
                "id": 3837,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "10-2022"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 8579317785,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 65,
                "type": "number",
                "submitId": 603,
                "userProfileId": 51
            },
            {
                "id": 4927,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "04-2021"
                ],
                "site": 27,
                "clientId": 28,
                "reporter_id": 39,
                "reviewer_id": 37,
                "form_id": 697990679306,
                "form_type": 1,
                "year": [
                    "2021"
                ],
                "value": 142,
                "type": "number",
                "submitId": 232,
                "userProfileId": 28
            },
            {
                "id": 6797,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "01-2022"
                ],
                "site": 5,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 1253846785017,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 43,
                "type": "number",
                "submitId": 458,
                "userProfileId": 17
            },
            {
                "id": 8372,
                "user_type": "1",
                "dcfId": 10,
                "dpId": "DPA0136",
                "rp": [
                    "03-2023"
                ],
                "site": 42,
                "clientId": 51,
                "reporter_id": 52,
                "reviewer_id": 53,
                "form_id": 546770941502,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 142,
                "type": "number",
                "submitId": 1229,
                "userProfileId": 51
            }
        ]
        let arr =[]
        loc.forEach((i)=>{
if(!arr.includes(i.value)){
    arr.push(i.value)
}
        })
        console.log(arr)
    }
    const pgasReport = ()=>{
        let loc = [
            {
                "id": 57,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "01-2023"
                ],
                "site": 3,
                "clientId": 17,
                "reporter_id": 9,
                "reviewer_id": 11,
                "form_id": 1468268454990,
                "form_type": 1,
                "year": [
                    "2023"
                ],
                "value": 199,
                "type": "number",
                "submitId": 381,
                "userProfileId": 17
            },
            {
                "id": 5907,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 345765546080,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5908,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 780627435857,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5909,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 42153702810,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5910,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1279285248150,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5911,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 369820034825,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5912,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 42556928479,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5913,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 982051674271,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5914,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 748794181908,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5915,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 478386819213,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5916,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1118797977793,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5917,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1455814352588,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5918,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 64431792362,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5919,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1494380810558,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5920,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 374209372995,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5921,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 665232217002,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5922,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1447363721950,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5923,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1539988671068,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5924,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 368067713614,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5925,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1639496940936,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5926,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 364887232978,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5927,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 459293606493,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5928,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1290547827538,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5929,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 822130756325,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5930,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 21264736150,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5931,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 938228085964,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5932,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 871837350881,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5933,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 100376853262,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5934,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 541208293960,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5935,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1000475819681,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5936,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 435591704556,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5937,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1207609600384,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5938,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 65710339771,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5939,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1269110395375,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5940,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1351890424179,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5941,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 660783204287,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5942,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 141292046078,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5943,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 826694962231,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5944,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 778633712160,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5945,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 110904339148,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5946,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 561567161844,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5947,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1427290125495,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5948,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 764197135754,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5949,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 880033289781,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5950,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1192844992439,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5951,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 131935028896,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5952,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1411910593089,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5953,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1641498427334,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5954,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1017931797392,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5955,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 484271417842,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5956,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 400645151621,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5957,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 744972552671,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5958,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 154219184630,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5959,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 716836004191,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5960,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1056270198390,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5961,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1221908215187,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5962,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 292117847497,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5963,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 249027171857,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5964,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1689987804829,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5965,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 641039802425,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5966,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1097703906311,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5967,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1271571650970,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5968,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1414276175275,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5969,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 891674832606,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5970,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1191878636987,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5971,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 325785117824,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5972,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1076525019555,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5973,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1625594318888,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5974,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 111626247803,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5975,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1040059818911,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5976,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 953234050698,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5977,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 218123085438,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5978,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1656654554843,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5979,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1577974542934,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5980,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 542781010780,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5981,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 617911969742,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5982,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 10535789086,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5983,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1033207494583,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5984,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 18715989701,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5985,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1162988353665,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5986,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1194325322881,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5987,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 896874767982,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5988,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 118647466080,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5989,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 588686306248,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5990,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 456410182711,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5991,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1565668021492,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5992,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1546531971801,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5993,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 283584654615,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5994,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 38977680084,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5995,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1553829501585,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5996,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 625659276135,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5997,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 166623401825,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5998,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 143978908709,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 5999,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 922838713325,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6000,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1500886804273,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6001,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 976076921374,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6002,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 498572189302,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6003,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 683477929755,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6004,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 317753395793,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6005,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1063786588159,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6006,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1197553968815,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6007,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 298540935415,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6008,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 344884083648,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6009,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1001610575625,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6010,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 432570989922,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6011,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 208073401578,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6012,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 230584080354,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6013,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1286030349647,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6014,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 148933892795,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6015,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 337353932933,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6016,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1547447711768,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6017,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 730718474090,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6018,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 572008854145,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6019,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 921242433122,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6020,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 413436545870,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6021,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 516362780130,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6022,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1398391076968,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6023,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 623675780020,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6024,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1108318990571,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6025,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 375236679876,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6026,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1602966480287,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6027,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 499184012192,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6028,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 505367990826,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6029,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1050839490127,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6030,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 365930551704,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6031,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 261149018675,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6032,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1507738493388,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6033,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 958237360296,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6034,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 735997680407,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6035,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 579198933346,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6036,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1255868084247,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6037,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1559747437821,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6038,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1145021728581,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6039,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1693166927029,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6040,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 602823567551,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6041,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1434368097272,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6042,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 658531477845,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6043,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1685043546690,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6044,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 420388338899,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6045,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1324593558270,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6046,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 633956735226,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6047,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 480917809138,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6048,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 447196056937,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6049,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 252850713413,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6050,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 49695032293,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6051,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1386520995289,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6052,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1224863034918,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6053,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 675143969805,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6054,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 855180465666,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6055,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1374302914147,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6056,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1277383646293,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6057,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1246712963006,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6058,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 467392814274,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6059,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 579878233575,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6060,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 976111788668,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6061,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1058392465026,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6062,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 518698613951,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6063,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1520660656689,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6064,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 845629010893,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6065,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 987866801592,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6066,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 553452845878,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6067,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1598403522477,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6068,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 292786318813,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6069,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 341165303515,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6070,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 266602437829,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6071,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1496813529326,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6072,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1473270977371,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6073,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1562110895804,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6074,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1110326010136,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6075,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 253275312396,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6076,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1206971337153,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6077,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1187614785757,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6078,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 931057267338,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6079,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 320896898114,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6080,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1141523720414,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6081,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1529678428010,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6082,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 927240017210,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6083,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 588337324227,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6084,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 289544583113,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6085,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1668099200981,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6086,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 988669420462,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6087,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 577956742549,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6088,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 973205908207,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6089,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1684841547802,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6090,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 480296991989,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6091,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 571061105995,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6092,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 855162212292,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6093,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 343958193920,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6094,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 565804022099,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6095,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 930869995554,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6096,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 586051903333,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6097,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 454451668839,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6098,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 21867006892,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6099,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 491444666907,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6100,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1228227364826,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6101,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 433474803520,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6102,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1096021847635,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6103,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1068049919235,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6104,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1617089431399,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6105,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1159169101590,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6106,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1442606134397,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6107,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 951429630375,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6108,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 258714924514,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6109,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 151313672415,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6110,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 666631359749,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6111,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1537359552846,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6112,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 384418798709,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6113,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 907545865838,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6114,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 805320188946,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6115,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 856815553645,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6116,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 251777550944,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6117,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 240873890595,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6118,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 488501413926,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6119,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 157128976262,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6120,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 741061296956,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6121,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 1503494082751,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6122,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 525244464874,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            },
            {
                "id": 6123,
                "user_type": "1",
                "dcfId": 16,
                "dpId": "DPA0287",
                "rp": [
                    "04-2022"
                ],
                "site": 50,
                "clientId": 65,
                "reporter_id": 70,
                "reviewer_id": 71,
                "form_id": 704281254705,
                "form_type": 1,
                "year": [
                    "2022"
                ],
                "value": 199,
                "type": "number",
                "submitId": 1555,
                "userProfileId": 65
            }
        ]
        let arr =[]
        loc.forEach((i)=>{
            if(!arr.includes(i.value)){
                arr.push(i.value)
            }
                    })
        console.log(arr,loc.length,loc.filter((i)=>{return typeof i.value === 'number'}).length)
    }
    return (
        <>
            {/* <div>{fg.length} - {fg.filter(i => (i.response.length === 0 || checkResponse(i, i.response))).length} </div>*/}
            <div>{pgas.length} - {pgas.filter(i => (i.response.length === 0 || checkResponse2(i, i.response))).length} </div> 
            {/* <div>{sc.length} - {sc.filter(i => (i.response.length === 0 || checkResponse3(i, i.response))).length} </div>  */}
            {/* {scDPReport('DPA0132')} */}
            {/* {fgReport()}
            {pgasReport()} */}
       
        </>

    )
}
export default Testing