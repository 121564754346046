const pgas = [
    {
        "scope": "Purchased goods and services",
        "id": 645155.0291820228,
        "products": "Food and beverage and tobacco products",
        "co2": 0.336,
        "ch4": 0.008,
        "n2o": 0.001,
        "other_ghg": 0.007,
        "total": 0.352
    },
    {
        "scope": "Purchased goods and services",
        "id": 720261.3924658254,
        "products": "Textile mills and textile product mills",
        "co2": 0.322,
        "ch4": 0.002,
        "n2o": 0,
        "other_ghg": 0.005,
        "total": 0.329
    },
    {
        "scope": "Purchased goods and services",
        "id": 995739.2196662402,
        "products": "Apparel and leather and allied products",
        "co2": 0.383,
        "ch4": 0.002,
        "n2o": 0,
        "other_ghg": 0.002,
        "total": 0.387
    },
    {
        "scope": "Purchased goods and services",
        "id": 154892.65985883676,
        "products": "Wood products",
        "co2": 0.218,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.001,
        "total": 0.22
    },
    {
        "scope": "Purchased goods and services",
        "id": 935985.4286744045,
        "products": "Paper products",
        "co2": 0.366,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.005,
        "total": 0.372
    },
    {
        "scope": "Purchased goods and services",
        "id": 191805.09522685836,
        "products": "Printing and related support activities",
        "co2": 0.23,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.004,
        "total": 0.235
    },
    {
        "scope": "Purchased goods and services",
        "id": 732620.6648759356,
        "products": "Petroleum and coal products",
        "co2": 0.773,
        "ch4": 0.018,
        "n2o": 0,
        "other_ghg": 0.005,
        "total": 0.796
    },
    {
        "scope": "Purchased goods and services",
        "id": 631781.3544171518,
        "products": "Chemical products",
        "co2": 0.295,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.01,
        "total": 0.306
    },
    {
        "scope": "Purchased goods and services",
        "id": 684095.4131492897,
        "products": "Plastics and rubber products",
        "co2": 0.173,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.011,
        "total": 0.185
    },
    {
        "scope": "Purchased goods and services",
        "id": 978672.8006113323,
        "products": "Nonmetallic mineral products",
        "co2": 0.538,
        "ch4": 0.002,
        "n2o": 0,
        "other_ghg": 0.004,
        "total": 0.544
    },
    {
        "scope": "Purchased goods and services",
        "id": 130684.50165538193,
        "products": "Primary metals",
        "co2": 0.06,
        "ch4": 0,
        "n2o": 0,
        "other_ghg": 0.021,
        "total": 0.081
    },
    {
        "scope": "Purchased goods and services",
        "id": 856074.6619912472,
        "products": "Fabricated metal products",
        "co2": 0.239,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.008,
        "total": 0.248
    },
    {
        "scope": "Purchased goods and services",
        "id": 803849.9839372807,
        "products": "Machinery",
        "co2": 0.185,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.043,
        "total": 0.229
    },
    {
        "scope": "Purchased goods and services",
        "id": 258004.00228908716,
        "products": "Computer and electronic products",
        "co2": 0.064,
        "ch4": 0,
        "n2o": 0,
        "other_ghg": 0.015,
        "total": 0.079
    },
    {
        "scope": "Purchased goods and services",
        "id": 947696.1441913688,
        "products": "Electrical equipment, appliances, and components",
        "co2": 0.232,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.011,
        "total": 0.244
    },
    {
        "scope": "Purchased goods and services",
        "id": 226321.9973196879,
        "products": "Motor vehicles, bodies and trailers, and parts",
        "co2": 0.188,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.019,
        "total": 0.208
    },
    {
        "scope": "Purchased goods and services",
        "id": 674123.0443749545,
        "products": "Other transportation equipment",
        "co2": 0.09,
        "ch4": 0,
        "n2o": 0,
        "other_ghg": 0.005,
        "total": 0.095
    },
    {
        "scope": "Purchased goods and services",
        "id": 579885.9732840557,
        "products": "Furniture and related products",
        "co2": 0.246,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.021,
        "total": 0.268
    },
    {
        "scope": "Purchased goods and services",
        "id": 130647.9251095972,
        "products": "Miscellaneous manufacturing",
        "co2": 0.227,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.003,
        "total": 0.231
    },
    {
        "scope": "Purchased goods and services",
        "id": 787170.3883804101,
        "products": "Wholesale trade",
        "co2": 0.113,
        "ch4": 0.001,
        "n2o": 0,
        "other_ghg": 0.003,
        "total": 0.117
    }
]
export default pgas