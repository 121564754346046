import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../components/constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { BP2LQ3, BP2LQ5, BP8EQ2, Business_Travel, Electricity, Fugitive, GR2LQ1, GR2LQ2, GR2LQ3, HotelStay, Mobile_Combustion, Purchase_Goods_Services, Stationary_Combustion } from "./hardcodedforms";
import { hardcoded } from "./constants/hardcodedid";
import APIServices from "../service/APIService";

window.jQuery = $;
window.$ = $;

const DCFInputEntryApproval = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [data, setData] = useState([])
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const { id } = useParams();
    const params = useLocation()

    // const hardcodeddcf = ['10', '11', '36', '15', '16', '188', '195', '196']
    useEffect(async () => {
        console.log(params.state)

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let site_url = API.LocationOne_UP(selector.clientId) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;

        APIServices.get(API.DCF_Edit(id)).then((res) => {

            res.data.data1 = params.state.response
            setData(res.data)


        })


        APIServices.get(site_url).then((res) => {
            let site_list = []
            res.data.forEach((country) => {
                if (country.locationTwos !== undefined) {
                    country.locationTwos.forEach((city) => {
                        if (city.locationThrees !== undefined) {
                            city.locationThrees.forEach((site) => {

                                site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                            })
                        }
                    })
                }
            })
            console.log(site_list, params.state.site)
            setSiteList(site_list)
        })

    }, [selector]);

    const getRP = (months) => {
        console.log(months)
        if (months.includes('to')) {

            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val) => {
        item.value = val;
        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ flexDirection: 'column', marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    {item.values.map((cb, cbind) => {
                        return (
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox disabled inputId={"cb" + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                <label htmlFor={"cb" + cbind} className="ml-2">{cb.label}</label>
                            </div>
                        )
                    })

                    }


                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar style={{ margin: 10 }} disabled value={item.value !== null ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <InputText keyfilter="num" disabled style={{ margin: 10 }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                    <label style={{ margin: 10 }}>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    {item.values.map((cb, cbind) => {
                        return (
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <RadioButton disabled inputId={"cb" + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                <label htmlFor={"cb" + cbind} className="ml-2">{cb.label}</label>
                            </div>
                        )
                    })

                    }


                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ flexDirection: 'column', marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Dropdown disabled options={item.values} style={{ width: '25%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <InputText disabled style={{ margin: 10 }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div style={{ width: '100%', resize: 'none', margin: 10 }} className='ql-editor' dangerouslySetInnerHTML={{ __html: item.value }} />


                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-12" style={{
                        border: '1px solid #6366F1',
                        borderRadius: '10px'
                    }}>

                        {item.value !== undefined && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: '300px',
                                overflow: 'scroll'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {
                                        console.log(file)
                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 10
                                                }} >
                                                    <div className="flex align-items-center" style={{ width: '50%' }}>
                                                        {file.extension === '.pdf' ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '25%' }} severity="warning" className="px-3 py-2" />

                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }

            else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'file' && item.required) {

                if (item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            }


        })

        return data_
    }
    const getYear = (mmyyyy) => {
        let year = []
        mmyyyy.forEach((i) => {
            if (year.findIndex((j) => { return j === i.split('-')[1] }) === -1) {
                year.push(i.split('-')[1])
            }
        })
        return year
    }
    const getValueByType = (type, obj) => {
        if (type === 'checkbox-group' || type === 'radio-group') {
            if (type === 'checkbox-group') {
                let val = obj.values.filter((i) => { return i.selected === true })
                if (val.length === 0) {
                    return null
                } else {
                    return obj.values.filter((i) => { return i.selected === true }).map((i)=>  i.value)
                }
            } else if (type === 'radio-group') {
                let val = obj.values.filter((i) => { return i.selected === true })
                if (val.length === 0) {
                    return null
                } else {
                    return obj.values.filter((i) => { return i.selected === true }).map((i)=>  i.value)
                }
            }
        } else {
            return obj.value
        }
    }
    function startsWithDP(inputString) {
        return inputString.trim().startsWith('DP');
      }
    const getResponse = (data, reporter) => {


        let data_ = []

        data.response.forEach(async (k) => {
            if (k.type !== 'paragraph' && k.type !== 'file' && startsWithDP(k.name)) {

                try {

                    const response = await APIServices.post(API.DP_report_UP(selector.clientId), { user_type: params.state.user_type.toString(), form_id: params.state.id, form_type: 0, rp: data.reporting_period, reporter_id: reporter, reviewer_id: selector.id, dpId: k.name.trim(), dcfId: data.dcf, site: data.site, clientId: selector.clientId, value: getValueByType(k.type, k), type: k.type, submitId: params.state.id, year: getYear(data.reporting_period) });
                    console.log(`Response for data set ${params.state.id}:`, response.data);

                } catch (error) {
                    alert('Error : 420, Found an issue, close this window stop approving data.please inform your team not to approve and inform Eisqr team please')
                    console.error(`Error for data set ${params.state.id}:`, error);
                }
            }
        })
        return data_

    }
    const getResponse_ = (data, reporter) => {


        let data_ = []
        data.response.forEach(async (k) => {
            for (const obj in k) {

                if (obj !== 'attachment' && obj !== 'id') {

                    try {
                        const response = await APIServices.post(API.DP_report_UP(selector.clientId), { user_type: params.state.user_type.toString(), form_id: k['id'], form_type: 1, rp: data.reporting_period, reporter_id: reporter, reviewer_id: selector.id, dpId: obj, dcfId: data.dcf, site: data.site, clientId: selector.clientId, value: k[obj], type: typeof k[obj], submitId: params.state.id, year: getYear(data.reporting_period) });
                        console.log(`Response for data set ${params.state.id}:`, response.data);
                    } catch (error) {
                        alert('Error : 420, Found an issue, close this window stop approving data.please inform your team not to approve and inform Eisqr team please')
                        console.error(`Error for data set ${params.state.id}:`, error);
                    }

                }
            }
        })
        console.log(data_)
        return data_

    }
    const saveDCF = () => {
        let newObj = {}
        if (checkResponse() || params.state.edit === 1) {
            Swal.fire({
                title: 'Please Wait !',
                html: 'Approving data ...',// add html attribute if you want or remove
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            });
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = params.state.reporting_period
            newObj['response'] = data.data1
            // newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 3
            newObj['reject'] = 0
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['approved_by'] = selector.id
            newObj['approved_on'] = moment.utc()
            newObj['form_type'] = 1

            if (params.state.id !== undefined) {
                console.log(newObj)
                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                  getResponse(newObj, params.state.submitted_by)
                    Swal.close()
                    Swal.fire({
                        title: "Data Approved Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.close()
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const saveDCF_ = () => {
        let newObj = {}
        if (data.data1.length !== 0 || params.state.edit === 1) {
            Swal.fire({
                title: 'Please Wait !',
                html: 'Approving data ...',// add html attribute if you want or remove
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            });
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = params.state.reporting_period
            newObj['response'] = data.data1
            // newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 3
            newObj['reject'] = 0
            newObj['data1'] = []
  
            newObj['frequencycd'] = params.state.frequency
            newObj['approved_by'] = selector.id
            newObj['approved_on'] = moment.utc()
            newObj['form_type'] = 1
            if (params.state.id !== undefined) {

                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    getResponse_(newObj, params.state.submitted_by)
                    Swal.close()
                    Swal.fire({
                        title: "Data Approved Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.close()
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const draftDCF_ = async () => {
        let newObj = {}

        if (data.data1.length !== 0 || params.state.edit === 1) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = params.state.reporting_period
            newObj['response'] = data.data1
            // newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['form_type'] = 1
            newObj['reject'] = 1
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['approved_by'] = selector.id
            newObj['rejected_on'] = moment.utc()
            const { value: remarks } = await Swal.fire({
                title: 'Form Rejection',
                input: 'text',
                inputLabel: 'Remarks',
                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'remarks mandatory'
                    }
                }
            })

            if (remarks) {

                if (params.state.id !== undefined) {
                    newObj['data2'] = remarks
                    APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                        Swal.fire({
                            title: "Submission Returned Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })

                }
            }


        }
        forceUpdate()

    }
    const draftDCF = async () => {
        let newObj = {}
        console.log(checkResponse(), params.state.edit)
        if (checkResponse() || params.state.edit === 1) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = params.state.reporting_period
            newObj['response'] = data.data1
            // newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['form_type'] = 1
            newObj['reject'] = 1
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['approved_by'] = selector.id
            newObj['rejected_on'] = moment.utc()
            const { value: remarks } = await Swal.fire({
                title: 'Form Rejection',
                input: 'text',
                inputLabel: 'Remarks',
                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'remarks mandatory'
                    }
                }
            })

            if (remarks) {

                if (params.state.id !== undefined) {
                    newObj['data2'] = remarks
                    APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                        Swal.fire({
                            title: "Submission Returned Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })

                }
            }


        }
        forceUpdate()

    }

    const checkHardcoded = () => {
        
        if ( hardcoded.dcf.includes(id)) {
            return true
        } else {
            return false
        }

    }
    const getRPMonth = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {

                return moment(item[0], 'MM-YYYY').format('MMM-YYYY') + ' to ' + moment(item[item.length - 1], 'MM-YYYY').format('MMM-YYYY')
            } else {
                return moment(item[0], 'MM-YYYY').format('MMM-YYYY')
            }
        }
    }
    return (
        <div className="grid" style={{ margin: 20, marginTop: 90 }}>
            <div className="col-12">
                {(selector.id !== undefined && data.length !== 0) ?
                    <div className="card">

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <h4>{data.title}</h4>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>    <span style={{ fontWeight: 'bold' }}>Reporting Period :&nbsp;</span> {getRPMonth(params.state.reporting_period)}</div>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>        <span style={{ fontWeight: 'bold' }}>Site :&nbsp;</span> {params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name}  </div>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>    <span style={{ fontWeight: 'bold' }}>Submitted on :&nbsp;</span> {moment(params.state.modified_on === null ? params.state.created_on : params.state.modified_on).local().format('DD MMM YYYY, hh:mm A')}  </div>

                                <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>          <span style={{ fontWeight: 'bold' }}>Version :&nbsp;</span> {moment(data.updated).local().format('DD MMM YYYY, hh:mm A')}  </div>
                            </div>

                        </div>
                        {data.comments.trim().length !== 0 &&
                            <div className="p-card" style={{ padding: 10, marginBottom: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>Note :&nbsp;</span> {data.comments}
                            </div>}
                        {data.data1.length !== 0 && !checkHardcoded() ?
                            <div>
                                <div>
                                    {data.data1.map((item, index) => {

                                        return renderItems(item, index)
                                    })

                                    }
                                </div>
                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                    <Button label='return for correction' onClick={() => { draftDCF() }} ></Button>
                                    <Button label='Approve' onClick={() => { saveDCF() }}></Button>

                                </div>


                            </div>
                            : id === '10' ?
                                <div>
                                    <Fugitive data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                        <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                        <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                    </div>
                                </div>
                                : id === '11' ?
                                    <div>
                                        <Stationary_Combustion data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                            <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                            <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                        </div>
                                    </div>
                                    : id === '36' ?
                                        <div>
                                            <Business_Travel data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
                                                <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                            </div>
                                        </div>
                                        : id === '15' ?
                                            <div>
                                                <Mobile_Combustion data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                    <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                    <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                                </div>
                                            </div>
                                            : id === '16' ?
                                                <div>
                                                    <Purchase_Goods_Services data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                    <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                        <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                        <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                        <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                                    </div>
                                                </div>
                                                : id === '188' ?
                                                    <div>
                                                        <BP8EQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                            <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                            <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                            <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                                        </div>
                                                    </div>
                                                    : id === '195' ?
                                                        <div>
                                                            <BP2LQ3 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                            <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                                <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                                            </div>
                                                        </div>
                                                        : id === '196' ?
                                                            <div>
                                                                <BP2LQ5 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                    <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                                    <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                                                </div>
                                                            </div>
                                                            :
                                                             id === '245' ?
                                                            <div>
                                                                <GR2LQ1 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                    <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                                    <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                                                </div>
                                                            </div>
                                                            : id === '246' ?
                                                            <div>
                                                                <GR2LQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                    <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                                    <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                                                </div>
                                                            </div>
                                                            : id === '247' ?
                                                            <div>
                                                                <GR2LQ3 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                    <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                                    <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                                                </div>
                                                            </div>
                                                              : id === '254' ?
                                                              <div>
                                                                  <HotelStay data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                  <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                      <Button label='Close' onClick={() => { navigate.goBack() }}></Button>
  
                                                                      <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                                      <Button label='Approve' onClick={() => { saveDCF_() }}></Button>
  
                                                                  </div>
                                                              </div>
                                                            : id === '257' ?
                                                            <div>
                                                                <Electricity data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                                                    <Button label='Close' onClick={() => { navigate.goBack() }}></Button>

                                                                    <Button label='return for correction' onClick={() => { draftDCF_() }} ></Button>
                                                                    <Button label='Approve' onClick={() => { saveDCF_() }}></Button>

                                                                </div>
                                                            </div>
                                                          :
                                                            'Contact admin'
                        }




                    </div>
                    :
                    <div className="col-12 card">Form not found</div>
                    // <div className="col-12 card">You have no rights to access this page </div>

                }
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DCFInputEntryApproval, comparisonFn);
